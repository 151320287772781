/**
 * @copyright Sister Software. All rights reserved.
 * @author Teffen Ellis, et al.
 * @license
 * See LICENSE file in the project root for full license information.
 */
/**
 * The default vocabulary defined for GPT-3.
 *
 * Pairs are described as a prefix and a suffix.
 * @internal
 * @ignore
 */
export const DEFAULT_VOCAB = [
    'Ġ',
    't',
    'Ġ',
    'a',
    'h',
    'e',
    'i',
    'n',
    'r',
    'e',
    'o',
    'n',
    'Ġt',
    'he',
    'e',
    'r',
    'Ġ',
    's',
    'a',
    't',
    'Ġ',
    'w',
    'Ġ',
    'o',
    'e',
    'n',
    'Ġ',
    'c',
    'i',
    't',
    'i',
    's',
    'a',
    'n',
    'o',
    'r',
    'e',
    's',
    'Ġ',
    'b',
    'e',
    'd',
    'Ġ',
    'f',
    'in',
    'g',
    'Ġ',
    'p',
    'o',
    'u',
    'Ġa',
    'n',
    'a',
    'l',
    'a',
    'r',
    'Ġt',
    'o',
    'Ġ',
    'm',
    'Ġo',
    'f',
    'Ġ',
    'in',
    'Ġ',
    'd',
    'Ġ',
    'h',
    'Ġan',
    'd',
    'i',
    'c',
    'a',
    's',
    'l',
    'e',
    'Ġt',
    'h',
    'i',
    'on',
    'o',
    'm',
    'l',
    'l',
    'en',
    't',
    'Ġ',
    'n',
    'Ġ',
    'l',
    's',
    't',
    'Ġ',
    're',
    'v',
    'e',
    'Ġ',
    'e',
    'r',
    'o',
    'l',
    'y',
    'Ġb',
    'e',
    'Ġ',
    'g',
    'Ġ',
    'T',
    'c',
    't',
    'Ġ',
    'S',
    'i',
    'd',
    'o',
    't',
    'Ġ',
    'I',
    'u',
    't',
    'e',
    't',
    'Ġ',
    'A',
    'Ġ',
    'is',
    'Ġ',
    'on',
    'i',
    'm',
    'a',
    'm',
    'o',
    'w',
    'a',
    'y',
    'a',
    'd',
    's',
    'e',
    'Ġth',
    'at',
    'Ġ',
    'C',
    'i',
    'g',
    'Ġf',
    'or',
    'a',
    'c',
    'Ġ',
    'y',
    'v',
    'er',
    'u',
    'r',
    'Ġ',
    'u',
    'l',
    'd',
    'Ġs',
    't',
    'Ġ',
    'M',
    "'",
    's',
    'Ġ',
    'he',
    'Ġ',
    'it',
    'at',
    'ion',
    'it',
    'h',
    'i',
    'r',
    'c',
    'e',
    'Ġy',
    'ou',
    'i',
    'l',
    'Ġ',
    'B',
    'Ġw',
    'h',
    'o',
    'l',
    'Ġ',
    'P',
    'Ġw',
    'ith',
    'Ġ',
    '1',
    't',
    'er',
    'c',
    'h',
    'Ġa',
    's',
    'Ġw',
    'e',
    'Ġ',
    '(',
    'n',
    'd',
    'i',
    'll',
    'Ġ',
    'D',
    'i',
    'f',
    'Ġ',
    '2',
    'a',
    'g',
    'er',
    's',
    'k',
    'e',
    'Ġ',
    '"',
    'Ġ',
    'H',
    'e',
    'm',
    'Ġc',
    'on',
    'Ġ',
    'W',
    'Ġ',
    'R',
    'he',
    'r',
    'Ġw',
    'as',
    'Ġ',
    'r',
    'o',
    'd',
    'Ġ',
    'F',
    'u',
    'l',
    'at',
    'e',
    'Ġa',
    't',
    'r',
    'i',
    'p',
    'p',
    'o',
    're',
    'ĠT',
    'he',
    'Ġs',
    'e',
    'u',
    's',
    'Ġp',
    'ro',
    'Ġh',
    'a',
    'u',
    'm',
    'Ġa',
    're',
    'Ġd',
    'e',
    'a',
    'in',
    'an',
    'd',
    'Ġo',
    'r',
    'ig',
    'h',
    'es',
    't',
    'is',
    't',
    'a',
    'b',
    'r',
    'om',
    'Ġ',
    'N',
    't',
    'h',
    'Ġc',
    'om',
    'Ġ',
    'G',
    'u',
    'n',
    'o',
    'p',
    '0',
    '0',
    'Ġ',
    'L',
    'Ġn',
    'ot',
    'es',
    's',
    'Ġe',
    'x',
    'Ġ',
    'v',
    're',
    's',
    'Ġ',
    'E',
    'e',
    'w',
    'it',
    'y',
    'an',
    't',
    'Ġb',
    'y',
    'e',
    'l',
    'o',
    's',
    'or',
    't',
    'o',
    'c',
    'q',
    'u',
    'Ġf',
    'rom',
    'Ġha',
    've',
    'Ġs',
    'u',
    'i',
    've',
    'ou',
    'ld',
    'Ġs',
    'h',
    'Ġth',
    'is',
    'n',
    't',
    'r',
    'a',
    'p',
    'e',
    'igh',
    't',
    'ar',
    't',
    'm',
    'ent',
    'Ġa',
    'l',
    'u',
    'st',
    'en',
    'd',
    '-',
    '-',
    'al',
    'l',
    'Ġ',
    'O',
    'ac',
    'k',
    'Ġc',
    'h',
    'Ġ',
    'le',
    'i',
    'es',
    're',
    'd',
    'ar',
    'd',
    'â',
    'Ģ',
    'ou',
    't',
    'Ġ',
    'J',
    'Ġa',
    'b',
    'e',
    'ar',
    'i',
    'v',
    'al',
    'ly',
    'ou',
    'r',
    'o',
    'st',
    'g',
    'h',
    'p',
    't',
    'Ġp',
    'l',
    'as',
    't',
    'Ġc',
    'an',
    'a',
    'k',
    'om',
    'e',
    'u',
    'd',
    'T',
    'he',
    'Ġh',
    'is',
    'Ġd',
    'o',
    'Ġg',
    'o',
    'Ġh',
    'as',
    'g',
    'e',
    "'",
    't',
    'Ġ',
    'U',
    'r',
    'ou',
    'Ġs',
    'a',
    'Ġ',
    'j',
    'Ġb',
    'ut',
    'Ġw',
    'or',
    'Ġa',
    'll',
    'e',
    'ct',
    'Ġ',
    'k',
    'am',
    'e',
    'Ġw',
    'ill',
    'o',
    'k',
    'Ġw',
    'he',
    'Ġthe',
    'y',
    'id',
    'e',
    '0',
    '1',
    'f',
    'f',
    'ic',
    'h',
    'p',
    'l',
    't',
    'her',
    'Ġt',
    'r',
    '.',
    '.',
    'Ġin',
    't',
    'i',
    'e',
    'u',
    're',
    'ag',
    'e',
    'Ġn',
    'e',
    'i',
    'al',
    'a',
    'p',
    'in',
    'e',
    'ic',
    'e',
    'Ġm',
    'e',
    'Ġo',
    'ut',
    'an',
    's',
    'on',
    'e',
    'on',
    'g',
    'ion',
    's',
    'Ġwh',
    'o',
    'Ġ',
    'K',
    'Ġu',
    'p',
    'Ġthe',
    'ir',
    'Ġa',
    'd',
    'Ġ',
    '3',
    'Ġu',
    's',
    'at',
    'ed',
    'ou',
    's',
    'Ġm',
    'ore',
    'u',
    'e',
    'o',
    'g',
    'ĠS',
    't',
    'in',
    'd',
    'i',
    'ke',
    'Ġs',
    'o',
    'im',
    'e',
    'p',
    'er',
    '.',
    '"',
    'b',
    'er',
    'i',
    'z',
    'a',
    'ct',
    'Ġon',
    'e',
    'Ġsa',
    'id',
    'Ġ',
    '-',
    'a',
    're',
    'Ġyou',
    'r',
    'c',
    'c',
    'ĠT',
    'h',
    'Ġc',
    'l',
    'e',
    'p',
    'a',
    'ke',
    'ab',
    'le',
    'i',
    'p',
    'Ġcon',
    't',
    'Ġwh',
    'ich',
    'i',
    'a',
    'Ġ',
    'im',
    'Ġab',
    'out',
    'Ġwe',
    're',
    'ver',
    'y',
    'u',
    'b',
    'Ġh',
    'ad',
    'Ġ',
    'en',
    'Ġcom',
    'p',
    ',',
    '"',
    'ĠI',
    'n',
    'Ġu',
    'n',
    'Ġa',
    'g',
    'i',
    're',
    'ac',
    'e',
    'a',
    'u',
    'ar',
    'y',
    'Ġw',
    'ould',
    'as',
    's',
    'r',
    'y',
    'Ġ',
    'âĢ',
    'c',
    'l',
    'o',
    'ok',
    'e',
    're',
    's',
    'o',
    'Ġ',
    'V',
    'ig',
    'n',
    'i',
    'b',
    'Ġof',
    'f',
    'Ġt',
    'e',
    'v',
    'en',
    'Ġ',
    'Y',
    'i',
    'le',
    'o',
    'se',
    'it',
    'e',
    'or',
    'm',
    'Ġ2',
    '01',
    'Ġre',
    's',
    'Ġm',
    'an',
    'Ġp',
    'er',
    'Ġo',
    'ther',
    'or',
    'd',
    'ul',
    't',
    'Ġbe',
    'en',
    'Ġl',
    'ike',
    'as',
    'e',
    'an',
    'ce',
    'k',
    's',
    'ay',
    's',
    'ow',
    'n',
    'en',
    'ce',
    'Ġd',
    'is',
    'ct',
    'ion',
    'Ġan',
    'y',
    'Ġa',
    'pp',
    'Ġs',
    'p',
    'in',
    't',
    'res',
    's',
    'ation',
    's',
    'a',
    'il',
    'Ġ',
    '4',
    'ic',
    'al',
    'Ġthe',
    'm',
    'Ġhe',
    'r',
    'ou',
    'nt',
    'ĠC',
    'h',
    'Ġa',
    'r',
    'Ġ',
    'if',
    'Ġthe',
    're',
    'Ġp',
    'e',
    'Ġy',
    'ear',
    'a',
    'v',
    'Ġm',
    'y',
    'Ġs',
    'ome',
    'Ġwhe',
    'n',
    'ou',
    'gh',
    'ac',
    'h',
    'Ġth',
    'an',
    'r',
    'u',
    'on',
    'd',
    'ic',
    'k',
    'Ġo',
    'ver',
    've',
    'l',
    'Ġ',
    'qu',
    'Ċ',
    'Ċ',
    'Ġs',
    'c',
    're',
    'at',
    're',
    'e',
    'ĠI',
    't',
    'ou',
    'nd',
    'p',
    'ort',
    'Ġal',
    'so',
    'Ġp',
    'art',
    'f',
    'ter',
    'Ġk',
    'n',
    'Ġbe',
    'c',
    'Ġt',
    'ime',
    'en',
    's',
    'Ġ',
    '5',
    'op',
    'le',
    'Ġwh',
    'at',
    'Ġn',
    'o',
    'd',
    'u',
    'm',
    'er',
    'an',
    'g',
    'Ġn',
    'ew',
    '--',
    '--',
    'Ġg',
    'et',
    'or',
    'y',
    'it',
    'ion',
    'ing',
    's',
    'Ġj',
    'ust',
    'Ġint',
    'o',
    'Ġ',
    '0',
    'ent',
    's',
    'o',
    've',
    't',
    'e',
    'Ġpe',
    'ople',
    'Ġp',
    're',
    'Ġit',
    's',
    'Ġre',
    'c',
    'Ġt',
    'w',
    'i',
    'an',
    'ir',
    'st',
    'ar',
    'k',
    'or',
    's',
    'Ġwor',
    'k',
    'ad',
    'e',
    'o',
    'b',
    'Ġs',
    'he',
    'Ġo',
    'ur',
    'w',
    'n',
    'in',
    'k',
    'l',
    'ic',
    'Ġ1',
    '9',
    'ĠH',
    'e',
    'is',
    'h',
    'nd',
    'er',
    'au',
    'se',
    'Ġh',
    'im',
    'on',
    's',
    'Ġ',
    '[',
    'Ġ',
    'ro',
    'f',
    'orm',
    'i',
    'ld',
    'at',
    'es',
    'ver',
    's',
    'Ġon',
    'ly',
    'o',
    'll',
    'Ġs',
    'pe',
    'c',
    'k',
    'e',
    'll',
    'am',
    'p',
    'Ġa',
    'cc',
    'Ġb',
    'l',
    'i',
    'ous',
    'ur',
    'n',
    'f',
    't',
    'o',
    'od',
    'Ġh',
    'ow',
    'he',
    'd',
    'Ġ',
    "'",
    'Ġa',
    'fter',
    'a',
    'w',
    'Ġat',
    't',
    'o',
    'v',
    'n',
    'e',
    'Ġpl',
    'ay',
    'er',
    'v',
    'ic',
    't',
    'Ġc',
    'ould',
    'it',
    't',
    'Ġa',
    'm',
    'Ġf',
    'irst',
    'Ġ',
    '6',
    'Ġa',
    'ct',
    'Ġ',
    '$',
    'e',
    'c',
    'h',
    'ing',
    'u',
    'al',
    'u',
    'll',
    'Ġcom',
    'm',
    'o',
    'y',
    'o',
    'ld',
    'c',
    'es',
    'at',
    'er',
    'Ġf',
    'e',
    'Ġbe',
    't',
    'w',
    'e',
    'if',
    'f',
    'Ġtw',
    'o',
    'oc',
    'k',
    'Ġb',
    'ack',
    ')',
    '.',
    'id',
    'ent',
    'Ġu',
    'nder',
    'rou',
    'gh',
    'se',
    'l',
    'x',
    't',
    'Ġm',
    'ay',
    'rou',
    'nd',
    'Ġp',
    'o',
    'p',
    'h',
    'is',
    's',
    'Ġd',
    'es',
    'Ġm',
    'ost',
    'Ġd',
    'id',
    'Ġad',
    'd',
    'j',
    'ect',
    'Ġin',
    'c',
    'f',
    'ore',
    'Ġp',
    'ol',
    'on',
    't',
    'Ġag',
    'ain',
    'cl',
    'ud',
    'ter',
    'n',
    'Ġkn',
    'ow',
    'Ġne',
    'ed',
    'Ġcon',
    's',
    'Ġc',
    'o',
    'Ġ',
    '.',
    'Ġw',
    'ant',
    'Ġse',
    'e',
    'Ġ',
    '7',
    'n',
    'ing',
    'i',
    'ew',
    'ĠTh',
    'is',
    'c',
    'ed',
    'Ġe',
    'ven',
    'Ġin',
    'd',
    't',
    'y',
    'ĠW',
    'e',
    'at',
    'h',
    'Ġthe',
    'se',
    'Ġp',
    'r',
    'Ġu',
    'se',
    'Ġbec',
    'ause',
    'Ġf',
    'l',
    'n',
    'g',
    'Ġn',
    'ow',
    'ĠâĢ',
    'ĵ',
    'c',
    'om',
    'is',
    'e',
    'Ġm',
    'ake',
    'Ġthe',
    'n',
    'ow',
    'er',
    'Ġe',
    'very',
    'ĠU',
    'n',
    'Ġse',
    'c',
    'os',
    's',
    'u',
    'ch',
    'Ġe',
    'm',
    'Ġ',
    '=',
    'ĠR',
    'e',
    'i',
    'ed',
    'r',
    'it',
    'Ġin',
    'v',
    'le',
    'ct',
    'Ġsu',
    'pp',
    'at',
    'ing',
    'Ġl',
    'ook',
    'm',
    'an',
    'pe',
    'ct',
    'Ġ',
    '8',
    'ro',
    'w',
    'Ġb',
    'u',
    'Ġwhe',
    're',
    'if',
    'ic',
    'Ġyear',
    's',
    'i',
    'ly',
    'Ġd',
    'iff',
    'Ġsh',
    'ould',
    'Ġre',
    'm',
    'T',
    'h',
    'I',
    'n',
    'Ġe',
    'v',
    'd',
    'ay',
    "'",
    're',
    'ri',
    'b',
    'Ġre',
    'l',
    's',
    's',
    'Ġde',
    'f',
    'Ġr',
    'ight',
    'Ġs',
    'y',
    ')',
    ',',
    'l',
    'es',
    '00',
    '0',
    'he',
    'n',
    'Ġth',
    'rough',
    'ĠT',
    'r',
    '_',
    '_',
    'Ġw',
    'ay',
    'Ġd',
    'on',
    'Ġ',
    ',',
    'Ġ1',
    '0',
    'as',
    'ed',
    'Ġas',
    's',
    'ub',
    'lic',
    'Ġre',
    'g',
    'ĠA',
    'nd',
    'i',
    'x',
    'Ġ',
    'very',
    'Ġin',
    'clud',
    'ot',
    'her',
    'Ġim',
    'p',
    'ot',
    'h',
    'Ġsu',
    'b',
    'ĠâĢ',
    'Ķ',
    'Ġbe',
    'ing',
    'ar',
    'g',
    'ĠW',
    'h',
    '=',
    '=',
    'ib',
    'le',
    'Ġdo',
    'es',
    'an',
    'ge',
    'r',
    'am',
    'Ġ',
    '9',
    'er',
    't',
    'p',
    's',
    'it',
    'ed',
    'ation',
    'al',
    'Ġb',
    'r',
    'Ġd',
    'own',
    'Ġman',
    'y',
    'ak',
    'ing',
    'Ġc',
    'all',
    'ur',
    'ing',
    'it',
    'ies',
    'Ġp',
    'h',
    'ic',
    's',
    'al',
    's',
    'Ġde',
    'c',
    'at',
    'ive',
    'en',
    'er',
    'Ġbe',
    'fore',
    'il',
    'ity',
    'Ġwe',
    'll',
    'Ġm',
    'uch',
    'ers',
    'on',
    'Ġth',
    'ose',
    'Ġsu',
    'ch',
    'Ġ',
    'ke',
    'Ġ',
    'end',
    'ĠB',
    'ut',
    'as',
    'on',
    't',
    'ing',
    'Ġl',
    'ong',
    'e',
    'f',
    'Ġth',
    'ink',
    'y',
    's',
    'Ġbe',
    'l',
    'Ġs',
    'm',
    'it',
    's',
    'a',
    'x',
    'Ġo',
    'wn',
    'Ġpro',
    'v',
    'Ġs',
    'et',
    'if',
    'e',
    'ment',
    's',
    'b',
    'le',
    'w',
    'ard',
    'Ġsh',
    'ow',
    'Ġp',
    'res',
    'm',
    's',
    'om',
    'et',
    'Ġo',
    'b',
    'Ġs',
    'ay',
    'ĠS',
    'h',
    't',
    's',
    'f',
    'ul',
    'Ġe',
    'ff',
    'Ġg',
    'u',
    'Ġin',
    'st',
    'u',
    'nd',
    're',
    'n',
    'c',
    'ess',
    'Ġ',
    'ent',
    'ĠY',
    'ou',
    'Ġgo',
    'od',
    'Ġst',
    'art',
    'in',
    'ce',
    'Ġm',
    'ade',
    't',
    't',
    'st',
    'em',
    'ol',
    'og',
    'u',
    'p',
    'Ġ',
    '|',
    'um',
    'p',
    'Ġhe',
    'l',
    'ver',
    'n',
    'ul',
    'ar',
    'u',
    'ally',
    'Ġa',
    'c',
    'Ġm',
    'on',
    'Ġl',
    'ast',
    'Ġ2',
    '00',
    '1',
    '0',
    'Ġst',
    'ud',
    'u',
    'res',
    'ĠA',
    'r',
    'sel',
    'f',
    'ar',
    's',
    'mer',
    'ic',
    'u',
    'es',
    'c',
    'y',
    'Ġm',
    'in',
    'oll',
    'ow',
    'Ġc',
    'ol',
    'i',
    'o',
    'Ġm',
    'od',
    'Ġc',
    'ount',
    'ĠC',
    'om',
    'he',
    's',
    'Ġf',
    'in',
    'a',
    'ir',
    'i',
    'er',
    'âĢ',
    'Ķ',
    're',
    'ad',
    'an',
    'k',
    'at',
    'ch',
    'e',
    'ver',
    'Ġst',
    'r',
    'Ġpo',
    'int',
    'or',
    'k',
    'ĠN',
    'ew',
    'Ġs',
    'ur',
    'o',
    'ol',
    'al',
    'k',
    'em',
    'ent',
    'Ġus',
    'ed',
    'ra',
    'ct',
    'we',
    'en',
    'Ġs',
    'ame',
    'ou',
    'n',
    'ĠA',
    'l',
    'c',
    'i',
    'Ġdiff',
    'ere',
    'Ġwh',
    'ile',
    '----',
    '----',
    'Ġg',
    'ame',
    'ce',
    'pt',
    'Ġs',
    'im',
    '..',
    '.',
    'Ġin',
    'ter',
    'e',
    'k',
    'Ġre',
    'port',
    'Ġpro',
    'du',
    'Ġst',
    'ill',
    'l',
    'ed',
    'a',
    'h',
    'Ġhe',
    're',
    'Ġwor',
    'ld',
    'Ġth',
    'ough',
    'Ġn',
    'um',
    'ar',
    'ch',
    'im',
    'es',
    'al',
    'e',
    'ĠS',
    'e',
    'ĠI',
    'f',
    '/',
    '/',
    'ĠL',
    'e',
    'Ġre',
    't',
    'Ġre',
    'f',
    'Ġtr',
    'ans',
    'n',
    'er',
    'ut',
    'ion',
    'ter',
    's',
    'Ġt',
    'ake',
    'ĠC',
    'l',
    'Ġcon',
    'f',
    'w',
    'ay',
    'a',
    've',
    'Ġgo',
    'ing',
    'Ġs',
    'l',
    'u',
    'g',
    'ĠA',
    'meric',
    'Ġspe',
    'c',
    'Ġh',
    'and',
    'Ġbet',
    'ween',
    'ist',
    's',
    'ĠD',
    'e',
    'o',
    'ot',
    'I',
    't',
    'Ġe',
    'ar',
    'Ġagain',
    'st',
    'Ġh',
    'igh',
    'g',
    'an',
    'a',
    'z',
    'at',
    'her',
    'Ġex',
    'p',
    'Ġo',
    'p',
    'Ġin',
    's',
    'Ġg',
    'r',
    'Ġhel',
    'p',
    'Ġre',
    'qu',
    'et',
    's',
    'in',
    's',
    'ĠP',
    'ro',
    'is',
    'm',
    'Ġf',
    'ound',
    'l',
    'and',
    'at',
    'a',
    'us',
    's',
    'am',
    'es',
    'Ġp',
    'erson',
    'Ġg',
    'reat',
    'p',
    'r',
    'Ġs',
    'ign',
    'ĠA',
    'n',
    "'",
    've',
    'Ġs',
    'omet',
    'Ġs',
    'er',
    'h',
    'ip',
    'Ġr',
    'un',
    'Ġ',
    ':',
    'Ġt',
    'er',
    'ire',
    'ct',
    'Ġf',
    'ollow',
    'Ġd',
    'et',
    'ic',
    'es',
    'Ġf',
    'ind',
    '1',
    '2',
    'Ġm',
    'em',
    'Ġc',
    'r',
    'e',
    'red',
    'e',
    'x',
    'Ġex',
    't',
    'ut',
    'h',
    'en',
    'se',
    'c',
    'o',
    'Ġte',
    'am',
    'v',
    'ing',
    'ou',
    'se',
    'as',
    'h',
    'at',
    't',
    'v',
    'ed',
    'Ġsy',
    'stem',
    'ĠA',
    's',
    'd',
    'er',
    'iv',
    'es',
    'm',
    'in',
    'Ġle',
    'ad',
    'ĠB',
    'l',
    'c',
    'ent',
    'Ġa',
    'round',
    'Ġgo',
    'vern',
    'Ġc',
    'ur',
    'vel',
    'op',
    'an',
    'y',
    'Ġc',
    'our',
    'al',
    'th',
    'ag',
    'es',
    'iz',
    'e',
    'Ġc',
    'ar',
    'od',
    'e',
    'Ġl',
    'aw',
    'Ġre',
    'ad',
    "'",
    'm',
    'c',
    'on',
    'Ġre',
    'al',
    'Ġsupp',
    'ort',
    'Ġ1',
    '2',
    '..',
    '..',
    'Ġre',
    'ally',
    'n',
    'ess',
    'Ġf',
    'act',
    'Ġd',
    'ay',
    'Ġb',
    'oth',
    'y',
    'ing',
    'Ġs',
    'erv',
    'ĠF',
    'or',
    'Ġth',
    'ree',
    'Ġw',
    'om',
    'Ġm',
    'ed',
    'od',
    'y',
    'ĠThe',
    'y',
    '5',
    '0',
    'Ġex',
    'per',
    't',
    'on',
    'Ġe',
    'ach',
    'ak',
    'es',
    'Ġc',
    'he',
    'Ġc',
    're',
    'in',
    'es',
    'Ġre',
    'p',
    '1',
    '9',
    'g',
    'g',
    'ill',
    'ion',
    'Ġg',
    'rou',
    'ut',
    'e',
    'i',
    'k',
    'W',
    'e',
    'g',
    'et',
    'E',
    'R',
    'Ġm',
    'et',
    'Ġs',
    'ays',
    'o',
    'x',
    'Ġd',
    'uring',
    'er',
    'n',
    'iz',
    'ed',
    'a',
    'red',
    'Ġf',
    'am',
    'ic',
    'ally',
    'Ġha',
    'pp',
    'ĠI',
    's',
    'Ġch',
    'ar',
    'm',
    'ed',
    'v',
    'ent',
    'Ġg',
    'ener',
    'i',
    'ent',
    'p',
    'le',
    'i',
    'et',
    're',
    'nt',
    '1',
    '1',
    'v',
    'es',
    'pt',
    'ion',
    'Ġ2',
    '0',
    'form',
    'ation',
    'Ġc',
    'or',
    'Ġoff',
    'ic',
    'ie',
    'ld',
    'Ġto',
    'o',
    'is',
    'ion',
    'Ġin',
    'f',
    'Ġ',
    'Z',
    't',
    'he',
    'o',
    'ad',
    'Ġp',
    'ublic',
    'Ġpro',
    'g',
    'r',
    'ic',
    '*',
    '*',
    'Ġw',
    'ar',
    'Ġp',
    'ower',
    'v',
    'iew',
    'Ġf',
    'ew',
    'Ġl',
    'oc',
    'Ġdiffere',
    'nt',
    'Ġst',
    'ate',
    'Ġhe',
    'ad',
    "'",
    'll',
    'Ġp',
    'oss',
    'Ġst',
    'at',
    're',
    't',
    'ant',
    's',
    'Ġv',
    'al',
    'Ġis',
    's',
    'Ġc',
    'le',
    'i',
    'vers',
    'an',
    'c',
    'Ġex',
    'pl',
    'Ġan',
    'other',
    'Ġ',
    'Q',
    'Ġa',
    'v',
    'th',
    'ing',
    'n',
    'ce',
    'W',
    'h',
    'Ġch',
    'ild',
    'Ġs',
    'ince',
    'i',
    'red',
    'l',
    'ess',
    'Ġl',
    'ife',
    'Ġde',
    'velop',
    'itt',
    'le',
    'Ġde',
    'p',
    'Ġp',
    'ass',
    'ã',
    'ĥ',
    'Ġt',
    'urn',
    'or',
    'n',
    'Th',
    'is',
    'b',
    'ers',
    'ro',
    'ss',
    'ĠA',
    'd',
    'Ġf',
    'r',
    'Ġres',
    'p',
    'Ġsec',
    'ond',
    'o',
    'h',
    'Ġ',
    '/',
    'Ġdis',
    'c',
    'Ġ',
    '&',
    'Ġsomet',
    'hing',
    'Ġcomp',
    'le',
    'Ġ',
    'ed',
    'Ġf',
    'il',
    'Ġmon',
    'th',
    'a',
    'j',
    'u',
    'c',
    'Ġgovern',
    'ment',
    'Ġwith',
    'out',
    'Ġle',
    'g',
    'Ġd',
    'ist',
    'Ġp',
    'ut',
    'Ġqu',
    'est',
    'an',
    'n',
    'Ġpro',
    't',
    '2',
    '0',
    'Ġne',
    'ver',
    'i',
    'ence',
    'Ġle',
    'vel',
    'Ġar',
    't',
    'Ġth',
    'ings',
    'Ġm',
    'ight',
    'Ġeff',
    'ect',
    'Ġcont',
    'ro',
    'Ġc',
    'ent',
    'Ġ1',
    '8',
    'Ġall',
    'ow',
    'Ġbel',
    'ie',
    'ch',
    'ool',
    'ot',
    't',
    'Ġinc',
    're',
    'Ġfe',
    'el',
    'Ġres',
    'ult',
    'Ġl',
    'ot',
    'Ġf',
    'un',
    'ot',
    'e',
    'Ġt',
    'y',
    'ere',
    'st',
    'Ġcont',
    'in',
    'Ġus',
    'ing',
    'Ġb',
    'ig',
    '2',
    '01',
    'Ġas',
    'k',
    'Ġb',
    'est',
    'Ġ',
    ')',
    'I',
    'N',
    'Ġo',
    'pp',
    '3',
    '0',
    'Ġnum',
    'ber',
    'in',
    'ess',
    'S',
    't',
    'le',
    'ase',
    'Ġc',
    'a',
    'Ġm',
    'ust',
    'Ġd',
    'irect',
    'Ġg',
    'l',
    'Ġ',
    '<',
    'Ġop',
    'en',
    'Ġp',
    'ost',
    'Ġcom',
    'e',
    'Ġse',
    'em',
    'ord',
    'ing',
    'Ġwe',
    'ek',
    'ate',
    'ly',
    'it',
    'al',
    'Ġe',
    'l',
    'ri',
    'end',
    'Ġf',
    'ar',
    'Ġt',
    'ra',
    'in',
    'al',
    'Ġp',
    'ri',
    'ĠU',
    'S',
    'Ġpl',
    'ace',
    'Ġfor',
    'm',
    'Ġto',
    'ld',
    '"',
    ':',
    'ain',
    's',
    'at',
    'ure',
    'ĠTr',
    'ump',
    'Ġst',
    'and',
    'Ġ',
    '#',
    'id',
    'er',
    'ĠF',
    'r',
    'Ġne',
    'xt',
    'Ġs',
    'oc',
    'Ġp',
    'ur',
    'Ġle',
    't',
    'Ġl',
    'ittle',
    'Ġh',
    'um',
    'Ġ',
    'i',
    'r',
    'on',
    '1',
    '5',
    'Ġ1',
    '5',
    'Ġcomm',
    'un',
    'Ġm',
    'ark',
    'ĠThe',
    're',
    'Ġw',
    'r',
    'ĠTh',
    'at',
    'Ġin',
    'formation',
    'w',
    'ays',
    'Ġb',
    'us',
    'a',
    'pp',
    'Ġinv',
    'est',
    'm',
    'e',
    'Ġh',
    'ard',
    'ain',
    'ed',
    'e',
    'ad',
    'Ġim',
    'port',
    'Ġapp',
    'ro',
    'Ġt',
    'est',
    'Ġt',
    'ri',
    'Ġre',
    'st',
    'os',
    'ed',
    'Ġf',
    'ull',
    'Ġc',
    'are',
    'ĠS',
    'p',
    'Ġc',
    'ase',
    'O',
    'N',
    'Ġs',
    'k',
    'Ġl',
    'ess',
    'Ġ',
    '+',
    'Ġpart',
    'ic',
    'ĠP',
    'l',
    'ab',
    'ly',
    'u',
    'ck',
    'is',
    'hed',
    'ch',
    'n',
    'b',
    'e',
    'Ġl',
    'ist',
    'at',
    'or',
    'Ġto',
    'p',
    'Ġad',
    'v',
    'ĠB',
    'e',
    'ru',
    'ct',
    'Ġd',
    'em',
    'r',
    'ation',
    'l',
    'ing',
    'g',
    'y',
    're',
    'en',
    'g',
    'er',
    'Ġh',
    'ome',
    'Ġle',
    'ft',
    'Ġbet',
    'ter',
    'Ġd',
    'ata',
    'Ġ1',
    '1',
    'Ġatt',
    'ack',
    'Ġpro',
    'ble',
    'l',
    'ine',
    'ard',
    's',
    'Ġbe',
    'h',
    'r',
    'al',
    'ĠH',
    'ow',
    'ĠS',
    'he',
    'ar',
    'ge',
    'Ġ',
    '--',
    ':',
    '//',
    'Ġb',
    'ro',
    'ĠP',
    'h',
    'at',
    's',
    'Ġbu',
    'ild',
    'w',
    'w',
    'id',
    'ed',
    'a',
    'im',
    'as',
    'es',
    'en',
    'cy',
    'Ġm',
    'ain',
    'in',
    'ed',
    'Ġinclud',
    'ing',
    'Ġ',
    '{',
    'Ġg',
    'ot',
    'Ġint',
    'erest',
    'Ġke',
    'ep',
    'Ġ',
    'X',
    'Ġe',
    'as',
    'ain',
    'ing',
    'Ġcl',
    'ass',
    'âĢ',
    '¦',
    'ĠN',
    'o',
    'Ġv',
    'ar',
    'Ġsm',
    'all',
    'amp',
    'le',
    'A',
    'T',
    'Ġ',
    'ide',
    'ĠS',
    'o',
    'Ġre',
    'ce',
    'Ġpol',
    'it',
    'Ġm',
    'ov',
    'Ġpl',
    'an',
    'Ġper',
    'cent',
    'iv',
    'ing',
    'Ġc',
    'amp',
    'Ġp',
    'ay',
    '1',
    '4',
    's',
    'c',
    'is',
    'ed',
    'Ġu',
    'nt',
    'one',
    'y',
    'pl',
    'oy',
    '==',
    '==',
    'Ġdid',
    'n',
    'ĠI',
    'nd',
    'el',
    's',
    'ert',
    'ain',
    'Ġp',
    'os',
    '__',
    '__',
    'i',
    'ver',
    'Ġpro',
    'cess',
    'Ġprog',
    'ram',
    'if',
    'ied',
    'ĠR',
    'ep',
    '1',
    '6',
    'u',
    'ro',
    'olog',
    'y',
    'at',
    'ter',
    'in',
    'a',
    'Ġn',
    'ame',
    'ĠA',
    'll',
    'Ġf',
    'our',
    'Ġret',
    'urn',
    'v',
    'ious',
    'b',
    's',
    'Ġcall',
    'ed',
    'Ġm',
    'ove',
    'ĠS',
    'c',
    'ir',
    'd',
    'Ġgrou',
    'p',
    'Ġb',
    're',
    'Ġm',
    'en',
    'Ġc',
    'ap',
    't',
    'en',
    'e',
    'e',
    'Ġd',
    'ri',
    'le',
    'g',
    'he',
    're',
    'uth',
    'or',
    'Ġp',
    'at',
    'Ġcur',
    'rent',
    'id',
    'es',
    'Ġp',
    'op',
    't',
    'o',
    'ent',
    'ion',
    'Ġal',
    'ways',
    'Ġm',
    'il',
    'Ġwom',
    'en',
    'Ġ1',
    '6',
    'Ġo',
    'ld',
    'iv',
    'en',
    'ra',
    'ph',
    'ĠO',
    'r',
    'r',
    'or',
    'ent',
    'ly',
    'Ġn',
    'ear',
    'ĠE',
    'x',
    're',
    'am',
    's',
    'h',
    'Ġ1',
    '4',
    'Ġf',
    'ree',
    'iss',
    'ion',
    'st',
    'and',
    'ĠC',
    'on',
    'al',
    'ity',
    'us',
    'ed',
    '1',
    '3',
    'Ġdes',
    'ign',
    'Ġch',
    'ange',
    'Ġch',
    'ang',
    'Ġb',
    'o',
    'Ġv',
    'is',
    'em',
    'ber',
    'Ġb',
    'ook',
    'read',
    'y',
    'Ġk',
    'ill',
    '2',
    '5',
    'pp',
    'ed',
    'Ġa',
    'way',
    'Ġab',
    'le',
    'Ġcount',
    'ry',
    'Ġcon',
    'st',
    'ar',
    'n',
    'Ġor',
    'der',
    'A',
    'R',
    'i',
    'or',
    'i',
    'um',
    'or',
    'th',
    '1',
    '8',
    'ail',
    'able',
    'Ġs',
    'w',
    'Ġm',
    'illion',
    'Ġ1',
    '3',
    'at',
    'ic',
    't',
    'ed',
    'ĠG',
    'o',
    'Ġo',
    'per',
    'en',
    'g',
    'Ġth',
    'ing',
    'aj',
    'or',
    'con',
    'om',
    'ĠCom',
    'm',
    'Ġwh',
    'y',
    'u',
    'red',
    'ur',
    'al',
    'Ġs',
    'chool',
    'b',
    'y',
    'ĠM',
    'ar',
    'Ġa',
    'ff',
    'Ġd',
    'ays',
    'Ġan',
    'n',
    'us',
    'h',
    'an',
    'e',
    'I',
    'f',
    'e',
    'g',
    'Ġpro',
    'f',
    'Ġhe',
    'alth',
    'ou',
    'th',
    'B',
    'ut',
    'ion',
    'al',
    '.',
    ',',
    'Ġs',
    'ol',
    'Ġal',
    'ready',
    'Ġ3',
    '0',
    'Ġchar',
    'act',
    'H',
    'e',
    'Ġf',
    'riend',
    'E',
    'S',
    'i',
    'ans',
    'ic',
    'le',
    "'",
    'd',
    'ĠO',
    'n',
    'Ġle',
    'ast',
    'Ġp',
    'rom',
    'Ġd',
    'r',
    'Ġh',
    'ist',
    'it',
    'her',
    'Ġ',
    'est',
    'i',
    'qu',
    '1',
    '7',
    's',
    'on',
    'Ġte',
    'll',
    'Ġt',
    'alk',
    'oh',
    'n',
    'o',
    'int',
    'le',
    'ction',
    'A',
    'N',
    'Ġunt',
    'il',
    'au',
    'gh',
    'Ġl',
    'ater',
    'Ġ',
    've',
    'Ġv',
    'iew',
    'end',
    'ing',
    'iv',
    'ed',
    'Ġwor',
    'd',
    'w',
    'are',
    'Ġc',
    'ost',
    'Ġen',
    'ough',
    'Ġg',
    'ive',
    'ĠUn',
    'ited',
    'Ġte',
    'chn',
    'are',
    'nt',
    'O',
    'R',
    'Ġp',
    'ar',
    'ĠD',
    'r',
    'Ġ201',
    '6',
    'r',
    'ist',
    'er',
    'ing',
    'Ġ',
    'Â',
    'Ġl',
    'arge',
    's',
    'ide',
    'ac',
    'y',
    'cc',
    'ess',
    'Ġw',
    'in',
    'Ġimport',
    'ant',
    'Ġ19',
    '9',
    'Ġdoes',
    'n',
    'Ġ1',
    '7',
    'Ġbus',
    'iness',
    'Ġcle',
    'ar',
    'Ġre',
    'se',
    '"',
    ',',
    'ur',
    'y',
    'Ġe',
    'qu',
    'as',
    'ter',
    'al',
    'f',
    'ĠAmeric',
    'an',
    'n',
    'ect',
    'Ġex',
    'pect',
    'ivers',
    'ity',
    'Ġo',
    'cc',
    'ĠF',
    'l',
    'Ġk',
    'ind',
    'Ġme',
    'an',
    'Ġp',
    'ast',
    'Ġde',
    'v',
    'Ġb',
    'as',
    'le',
    't',
    'ra',
    'ft',
    'Ġor',
    'gan',
    'Ġde',
    'l',
    'Ġper',
    'form',
    'Ġst',
    'ory',
    'Ġse',
    'ason',
    'ĠC',
    'ol',
    'Ġcl',
    'aim',
    'Ġc',
    'ame',
    'Ġwith',
    'in',
    'Ġl',
    'ine',
    'Ġpro',
    'ject',
    'ĠA',
    't',
    'Ġcontro',
    'l',
    'end',
    'ed',
    'ĠS',
    'y',
    'Ġa',
    'ir',
    'iz',
    'ation',
    'Ġ',
    '*',
    'le',
    'y',
    'Ġm',
    'oney',
    'id',
    'd',
    'Y',
    'ou',
    'f',
    'or',
    'Ġfam',
    'ily',
    'Ġm',
    'aking',
    'Ġb',
    'it',
    'Ġpol',
    'ice',
    'Ġhapp',
    'en',
    'Ġ',
    'vers',
    'on',
    'y',
    'u',
    'ff',
    'ĠW',
    'hen',
    'Ġs',
    'it',
    'ide',
    'o',
    'l',
    'f',
    'is',
    'on',
    'Ġsu',
    're',
    'g',
    'in',
    'Ġapp',
    'ear',
    'Ġl',
    'ight',
    'Ġ',
    'es',
    'o',
    'f',
    'Ġw',
    'ater',
    'Ġt',
    'imes',
    'n',
    'ot',
    'Ġg',
    'row',
    'Ġcomp',
    'any',
    'ĠT',
    'e',
    'ow',
    's',
    'Ġm',
    'ar',
    'our',
    'ce',
    'i',
    'ol',
    'ar',
    'm',
    'b',
    'r',
    'Ġex',
    'ample',
    'Ġcon',
    'c',
    'Ġf',
    'ore',
    'ĠT',
    'o',
    'p',
    'ro',
    'E',
    'N',
    'ri',
    'es',
    'Ġ2',
    '5',
    'ĠC',
    'an',
    'ne',
    'y',
    'Ġact',
    'ually',
    'Ġe',
    'ver',
    'ur',
    'ity',
    'ak',
    'en',
    'ap',
    's',
    'Ġt',
    'ax',
    'Ġm',
    'ajor',
    'am',
    'a',
    'Ġof',
    'ten',
    'er',
    'al',
    'Ġhum',
    'an',
    'Ġj',
    'ob',
    'is',
    'ter',
    'Ġav',
    'ailable',
    'oc',
    'r',
    'en',
    'n',
    'a',
    'id',
    'iv',
    'id',
    'Ġrec',
    'ord',
    '?',
    '"',
    'Ġs',
    'ing',
    'ĠA',
    'm',
    'id',
    'ence',
    'Ġnew',
    's',
    'st',
    'er',
    'Ġe',
    'conom',
    'Ġfollow',
    'ing',
    'ĠB',
    'r',
    'is',
    'ing',
    'Ġh',
    'our',
    'm',
    'ost',
    'um',
    'ent',
    'Ġse',
    'x',
    'Ġdes',
    'c',
    'Ġbec',
    'ome',
    'ĠE',
    'd',
    'Ġto',
    'ok',
    'Ġha',
    'ving',
    'Ġprodu',
    'ct',
    'a',
    'ult',
    'A',
    's',
    'ar',
    'ing',
    'Ġme',
    'ans',
    'Ġh',
    'op',
    'un',
    'e',
    'Ġch',
    'o',
    'Ġc',
    'ertain',
    'Ġn',
    'on',
    'Ġde',
    'al',
    '2',
    '4',
    'le',
    'ment',
    'oc',
    'i',
    'en',
    'e',
    'Ġs',
    'ide',
    'ĠP',
    'r',
    'ĠM',
    'ay',
    'Ġre',
    'ason',
    'u',
    'ed',
    'c',
    'hed',
    'ul',
    'ation',
    'Ġe',
    'lect',
    'Ġoffic',
    'ial',
    'Ġposs',
    'ible',
    'Ġh',
    'old',
    'and',
    's',
    'ot',
    's',
    'Ġc',
    'ity',
    'or',
    'ies',
    'Ġse',
    'ver',
    'Ġchild',
    'ren',
    'Ġon',
    'ce',
    'Ġact',
    'iv',
    'l',
    'er',
    'Ġn',
    'ight',
    'it',
    'ions',
    'ĠJ',
    'ohn',
    'a',
    'pe',
    'pl',
    'ay',
    'Ġd',
    'one',
    'Ġl',
    'im',
    'Ġwork',
    'ing',
    'ĠP',
    'res',
    'or',
    'ld',
    'e',
    'b',
    'ĠC',
    'o',
    'Ġb',
    'ody',
    'ail',
    's',
    'ut',
    'es',
    'ĠM',
    'r',
    'Ġwhe',
    'ther',
    'Ġa',
    'uthor',
    'ro',
    'p',
    'Ġpro',
    'per',
    'Ġse',
    'en',
    ')',
    ';',
    'Ġf',
    'ac',
    'ĠS',
    'u',
    'Ġcon',
    'd',
    'it',
    'ing',
    'Ġcour',
    'se',
    'Ġ',
    '}',
    '--------',
    '--------',
    'a',
    'ign',
    'Ġev',
    'ent',
    'Ġen',
    'g',
    'Ġp',
    'ot',
    'Ġin',
    'tern',
    'i',
    'am',
    'Ġsh',
    'ort',
    'em',
    'pt',
    'ã',
    'Ĥ',
    'ĠG',
    'od',
    'il',
    'ar',
    '8',
    '0',
    'Ġor',
    'ig',
    'I',
    'S',
    'our',
    'n',
    'ab',
    'ility',
    'it',
    'ive',
    'Ġd',
    'am',
    'Ġ1',
    '00',
    'Ġp',
    'ress',
    'Ġdo',
    'ing',
    'Ġprot',
    'ect',
    'r',
    'ing',
    'Ġthough',
    't',
    'Ġquest',
    'ion',
    're',
    'w',
    'ĠW',
    'ar',
    'Ġsever',
    'al',
    'ĠSt',
    'ate',
    'Ġg',
    'iven',
    'Ġf',
    'und',
    'ĠT',
    'w',
    'Ġw',
    'ent',
    'an',
    'ces',
    'w',
    'ork',
    'p',
    'or',
    'm',
    'y',
    '4',
    '0',
    'Ġar',
    'g',
    'art',
    'ment',
    'ust',
    'om',
    'Ġpol',
    'ic',
    'Ġme',
    'et',
    'Ġc',
    'reat',
    '2',
    '2',
    'ĠSt',
    'ates',
    'Ġg',
    'ames',
    'ra',
    'w',
    'ut',
    'ure',
    'Ġunder',
    'stand',
    'ur',
    's',
    'ĠO',
    'b',
    'l',
    'ish',
    's',
    'y',
    'Ġm',
    'akes',
    'Ġw',
    'on',
    'ag',
    'on',
    'Ġh',
    'tt',
    'Ġl',
    'ove',
    'ent',
    'ial',
    'Ġcomple',
    'te',
    'p',
    'ar',
    'ĠI',
    'm',
    'A',
    'L',
    'Ġacc',
    'ount',
    'Â',
    'ł',
    'ore',
    'd',
    'ver',
    't',
    'Ġ',
    'ident',
    'Ġ201',
    '5',
    'Ġother',
    's',
    'ĠM',
    'in',
    'i',
    'ber',
    'ver',
    'age',
    'The',
    're',
    'ition',
    'al',
    'd',
    'd',
    'Ġpro',
    'b',
    'Ġyou',
    'ng',
    'Ġal',
    'ong',
    'Ġacc',
    'ording',
    'Ġy',
    'et',
    'Ġmem',
    'bers',
    'ĠWh',
    'at',
    'o',
    'id',
    'ĠM',
    'an',
    'A',
    'nd',
    'Ġam',
    'ong',
    'a',
    'i',
    'Ġem',
    'ploy',
    'ĠR',
    'es',
    'Ġ',
    '>',
    'Ġinv',
    'ol',
    'Ġl',
    'ow',
    'a',
    'f',
    'ĠC',
    'ar',
    'Ġh',
    'ig',
    'ĠO',
    'ne',
    'ĠS',
    'ec',
    'in',
    'ation',
    'Ġlike',
    'ly',
    'Ġan',
    't',
    'ag',
    'ed',
    'ĠR',
    'uss',
    'Ġb',
    'en',
    'Ġre',
    'le',
    'F',
    'or',
    'b',
    'ack',
    'ĠN',
    'ot',
    'Ġpres',
    'ident',
    'b',
    'all',
    'Ġacc',
    'ess',
    'ivid',
    'ual',
    'ĠD',
    'em',
    'ĠE',
    'uro',
    '6',
    '0',
    'Ġkn',
    'own',
    'ir',
    'l',
    'ĠG',
    'r',
    'Ġear',
    'ly',
    'u',
    'se',
    'iet',
    'y',
    'âĢ',
    'ĵ',
    'Ġf',
    'ight',
    'Ġs',
    'ent',
    'Ġto',
    'day',
    'Ġmark',
    'et',
    '"',
    '.',
    'Ġb',
    'ased',
    'Ġstr',
    'ong',
    'ur',
    'ther',
    'Ġde',
    'b',
    'm',
    'ber',
    'Ġproble',
    'm',
    'Ġde',
    'ath',
    'Ġsoc',
    'ial',
    'im',
    'ate',
    'A',
    'S',
    'ort',
    'un',
    'Ġcamp',
    'aign',
    'er',
    'y',
    'C',
    'h',
    'Ġe',
    'y',
    'i',
    'ally',
    'Ġm',
    'us',
    'w',
    'h',
    'p',
    'os',
    'Ġ',
    'er',
    'Ġsa',
    'f',
    'Ġmonth',
    's',
    'ir',
    'on',
    'Ġv',
    'iol',
    'Ġf',
    'ive',
    'Ġst',
    're',
    'Ġplay',
    'ers',
    'in',
    'c',
    'al',
    'd',
    'y',
    'ear',
    'a',
    'un',
    'Ġsu',
    'ccess',
    'Ġpres',
    'ent',
    'ere',
    'nce',
    'Ġ201',
    '4',
    'Ġsu',
    'gg',
    'Ġpartic',
    'ular',
    'Ġtr',
    'y',
    'Ġsugg',
    'est',
    'ĠCh',
    'rist',
    'on',
    'es',
    'Ġpri',
    'v',
    '2',
    '3',
    'Ġc',
    'rit',
    'Ġl',
    'and',
    'Ġloc',
    'al',
    'if',
    'y',
    '2',
    '9',
    'Ġa',
    'ut',
    'E',
    'D',
    'ĠG',
    'u',
    'Ġm',
    'ult',
    'Ġpolit',
    'ical',
    'Ġask',
    'ed',
    'Ġfor',
    'mer',
    'it',
    'ter',
    'ri',
    'pt',
    'Ġcl',
    'ose',
    'Ġp',
    'ract',
    'ĠY',
    'ork',
    'Ġget',
    'ting',
    'Ġac',
    'ross',
    'Ġcom',
    'b',
    'Ġbelie',
    've',
    'Ġ',
    'z',
    'Ġto',
    'get',
    'Ġtoget',
    'her',
    'ĠC',
    'ent',
    'ir',
    'c',
    'Ġind',
    'ividual',
    'ĠM',
    'c',
    '2',
    '7',
    'is',
    'k',
    'ĠE',
    'ng',
    'Ġf',
    'ace',
    'Ġ2',
    '4',
    'Ġval',
    'ue',
    'Ġare',
    'a',
    'e',
    'v',
    'Ġw',
    'rit',
    'ĠPres',
    'ident',
    'Ġv',
    'ot',
    'Ġke',
    'y',
    'Ġm',
    'om',
    'p',
    'ut',
    'Ġany',
    'thing',
    'Ġexper',
    'ience',
    'att',
    'le',
    'Ġm',
    'ind',
    'a',
    'ff',
    'om',
    'm',
    'Ġf',
    'uture',
    'g',
    'ed',
    'Ġc',
    'ut',
    'Ġto',
    't',
    'it',
    'ch',
    'Ġv',
    'ideo',
    'Ġinvest',
    'ig',
    'Ġn',
    'et',
    'ĠM',
    'y',
    'r',
    'ict',
    'i',
    'en',
    '.',
    ')',
    'Ġimp',
    'ro',
    'th',
    'ough',
    'ward',
    's',
    'Ġcon',
    'nect',
    'ĠM',
    'ed',
    'sel',
    'ves',
    'ens',
    'ive',
    'm',
    'b',
    'o',
    'ber',
    'at',
    'ors',
    'A',
    'n',
    'Ġ5',
    '0',
    'Ġre',
    'du',
    'res',
    'ent',
    'Ġab',
    'ove',
    'Ġf',
    're',
    'ĠEuro',
    'pe',
    's',
    'w',
    'Ġam',
    'ount',
    'ĠA',
    'pp',
    'Ġe',
    'ither',
    'Ġmil',
    'it',
    'Ġan',
    'al',
    'Ġf',
    'ail',
    'ĠE',
    'n',
    'al',
    'es',
    'Ġspec',
    'ial',
    'Ġbl',
    'ack',
    'I',
    'T',
    'c',
    'her',
    'Ġlook',
    'ing',
    'Ġf',
    'ire',
    'y',
    'n',
    'Ġal',
    'most',
    'o',
    'on',
    'Ġstud',
    'y',
    'Ġm',
    'iss',
    'c',
    'hes',
    'ro',
    'wn',
    'Ġt',
    're',
    'Ġcommun',
    'ity',
    'Ġmed',
    'ia',
    'Ġf',
    'ood',
    'Ġcom',
    'es',
    'ĠUn',
    'iversity',
    'Ġsing',
    'le',
    'Wh',
    'at',
    'u',
    'ly',
    'Ġh',
    'alf',
    'ag',
    'ue',
    'h',
    'od',
    'ĠRep',
    'ublic',
    'Ġstart',
    'ed',
    'Ġqu',
    'ick',
    'ot',
    'o',
    'b',
    'ook',
    'Ġiss',
    'ue',
    'it',
    'or',
    'Ġel',
    'se',
    'Ġcons',
    'ider',
    '2',
    '6',
    'ro',
    'du',
    'Ġt',
    'aken',
    '2',
    '8',
    '9',
    '9',
    'ĠW',
    'ith',
    'Ġtr',
    'ue',
    'Ġw',
    'a',
    'Ġtr',
    'ad',
    'Ġag',
    'o',
    'Ġm',
    'ess',
    'ie',
    'f',
    'Ġadd',
    'ed',
    'o',
    'ke',
    'Ġb',
    'ad',
    'Ġf',
    'av',
    '3',
    '3',
    'Ġsim',
    'ilar',
    'as',
    'k',
    'ĠD',
    'on',
    'Ġcharact',
    'er',
    'ort',
    's',
    'ĠH',
    'ouse',
    'Ġreport',
    'ed',
    'Ġty',
    'pe',
    'v',
    'al',
    'i',
    'od',
    'ĠHow',
    'ever',
    'Ġt',
    'arg',
    'Ġent',
    'ire',
    'pp',
    'ing',
    'Ġhist',
    'ory',
    'Ġl',
    'ive',
    'ff',
    'ic',
    '....',
    '....',
    'ed',
    'eral',
    'Ġtr',
    'ying',
    'Ġdisc',
    'uss',
    'ĠH',
    'ar',
    'ac',
    'es',
    'l',
    'ished',
    'Ġse',
    'lf',
    'os',
    'p',
    're',
    'st',
    'Ġro',
    'om',
    'el',
    't',
    'Ġf',
    'all',
    'ol',
    'ution',
    'Ġe',
    't',
    'Ġ',
    'x',
    'Ġis',
    'n',
    'Ġide',
    'a',
    'b',
    'o',
    'Ġs',
    'ound',
    'ĠD',
    'ep',
    'Ġsome',
    'one',
    'ci',
    'ally',
    'ull',
    'y',
    'Ġf',
    'oc',
    'Ġob',
    'ject',
    'if',
    't',
    'ap',
    'er',
    'Ġplay',
    'er',
    'Ġr',
    'ather',
    'Ġserv',
    'ice',
    'as',
    'hing',
    'ĠD',
    'o',
    'ĠP',
    'art',
    'ru',
    'g',
    'm',
    'on',
    'p',
    'ly',
    'Ġm',
    'or',
    'Ġnot',
    'hing',
    'Ġprov',
    'ide',
    'I',
    'C',
    'un',
    'g',
    'Ġpart',
    'y',
    'Ġex',
    'ist',
    'Ġm',
    'ag',
    '7',
    '0',
    'Ġr',
    'ul',
    'Ġh',
    'ouse',
    'Ġbeh',
    'ind',
    'Ġhow',
    'ever',
    'ĠW',
    'orld',
    'Ġs',
    'um',
    'Ġapp',
    'lic',
    'Ġ',
    ';',
    'Ġfun',
    'ction',
    'g',
    'r',
    'ĠP',
    'ol',
    'Ġfr',
    'ont',
    '2',
    '00',
    'Ġser',
    'ies',
    'Ġt',
    'em',
    'Ġty',
    'p',
    'ill',
    's',
    'Ġo',
    'pt',
    'Ġpoint',
    's',
    'Ġbel',
    'ow',
    'itt',
    'ed',
    'Ġspec',
    'ific',
    'Ġ201',
    '7',
    'um',
    'b',
    'Ġr',
    'a',
    'Ġpre',
    'vious',
    'Ġpre',
    't',
    're',
    'me',
    'Ġc',
    'ustom',
    'Ġcour',
    't',
    'ĠM',
    'e',
    'Ġre',
    'pl',
    'Ġwho',
    'le',
    'g',
    'o',
    'c',
    'er',
    'Ġt',
    'reat',
    'ĠA',
    'ct',
    'Ġprob',
    'ably',
    'Ġle',
    'arn',
    'end',
    'er',
    'ĠA',
    'ss',
    'Ġvers',
    'ion',
    'n',
    'ow',
    'Ġche',
    'ck',
    'ĠC',
    'al',
    'R',
    'E',
    'min',
    'ist',
    'O',
    'n',
    'our',
    'ces',
    'Ġben',
    'ef',
    'Ġd',
    'oc',
    'Ġdet',
    'er',
    'Ġen',
    'c',
    'Ġsu',
    'per',
    'Ġadd',
    'ress',
    'Ġv',
    'ict',
    'Ġ201',
    '3',
    'Ġme',
    'as',
    't',
    'r',
    'Ġf',
    'ield',
    'W',
    'hen',
    'Ġsign',
    'ific',
    'u',
    'ge',
    'Ġfe',
    'at',
    'Ġcomm',
    'on',
    'l',
    'oad',
    'Ġbe',
    'gin',
    'Ġbr',
    'ing',
    'Ġa',
    'ction',
    'er',
    'man',
    'Ġdesc',
    'rib',
    'Ġind',
    'ust',
    'Ġwant',
    'ed',
    'ri',
    'ed',
    'm',
    'ing',
    'Ġatt',
    'empt',
    '4',
    '5',
    'f',
    'er',
    'Ġd',
    'ue',
    'ress',
    'ion',
    '#',
    '#',
    'Ġsh',
    'all',
    'Ġs',
    'ix',
    'o',
    'o',
    'Ġst',
    'ep',
    'Ġp',
    'ub',
    'Ġhim',
    'self',
    'Ġ2',
    '3',
    'Ġc',
    'op',
    'Ġd',
    'est',
    'Ġst',
    'op',
    'A',
    'C',
    'ib',
    'ility',
    'Ġl',
    'ab',
    'ic',
    'ult',
    'Ġhour',
    's',
    'Ġcre',
    'ate',
    'Ġf',
    'urther',
    'ĠAmeric',
    'a',
    'ĠC',
    'ity',
    'Ġd',
    'ou',
    'he',
    'ad',
    'S',
    'T',
    'ĠN',
    'orth',
    'c',
    'ing',
    'Ġn',
    'ational',
    'u',
    'le',
    'ĠIn',
    'st',
    'Ġt',
    'aking',
    'ĠQ',
    'u',
    'ir',
    't',
    'Ġre',
    'd',
    'Ġrese',
    'arch',
    'v',
    'iron',
    'ĠG',
    'e',
    'Ġbre',
    'ak',
    'an',
    'a',
    'Ġsp',
    'ace',
    'ater',
    'ial',
    'Ġrec',
    'ent',
    'ĠA',
    'b',
    'Ġgener',
    'al',
    'Ġh',
    'it',
    'Ġper',
    'iod',
    'Ġevery',
    'thing',
    'ive',
    'ly',
    'Ġph',
    'ys',
    'Ġsay',
    'ing',
    'an',
    'ks',
    'Ġc',
    'ou',
    'Ġc',
    'ult',
    'ac',
    'ed',
    'e',
    'al',
    'u',
    'ation',
    'Ġc',
    'oun',
    'l',
    'u',
    'Ġinclud',
    'e',
    'Ġpos',
    'ition',
    'ĠA',
    'fter',
    'ĠCan',
    'ad',
    'ĠE',
    'm',
    'Ġim',
    'm',
    'ĠR',
    'ed',
    'Ġp',
    'ick',
    'Ġcom',
    'pl',
    'Ġm',
    'atter',
    're',
    'g',
    'e',
    'xt',
    'ang',
    'u',
    'is',
    'c',
    'o',
    'le',
    'a',
    'ut',
    'Ġcomp',
    'et',
    'e',
    'ed',
    'f',
    'ect',
    'Ġ2',
    '1',
    'ĠS',
    'en',
    'ĠThe',
    'se',
    'as',
    'ing',
    'Ġcan',
    'not',
    'Ġin',
    'it',
    'Ġrel',
    'ations',
    'ac',
    'hed',
    'Ġb',
    'ar',
    'Ġ4',
    '0',
    'ĠT',
    'H',
    'Ġ201',
    '2',
    'Ġv',
    'ol',
    'Ġg',
    'round',
    'Ġsec',
    'urity',
    'Ġup',
    'd',
    'il',
    't',
    '3',
    '5',
    'Ġconc',
    'ern',
    'ĠJ',
    'ust',
    'Ġwh',
    'ite',
    'Ġseem',
    's',
    'ĠH',
    'er',
    'pe',
    'cially',
    'i',
    'ents',
    'Ġann',
    'oun',
    'Ġf',
    'ig',
    'ight',
    's',
    'Ġst',
    'ri',
    'l',
    'ike',
    'id',
    's',
    'Ġs',
    'us',
    'Ġw',
    'atch',
    'Ġ',
    'â',
    'Ġw',
    'ind',
    'ĠC',
    'ont',
    'Ġit',
    'self',
    'Ġm',
    'ass',
    'A',
    'l',
    'y',
    'le',
    'iqu',
    'e',
    'ĠN',
    'ational',
    'Ġab',
    's',
    'Ġp',
    'ack',
    'Ġout',
    'side',
    'Ġan',
    'im',
    'Ġp',
    'ain',
    'et',
    'er',
    'Ġman',
    'ag',
    'du',
    'ct',
    'og',
    'n',
    'Ġ',
    ']',
    'ĠSe',
    'pt',
    'se',
    'c',
    'o',
    'ff',
    'ĠJ',
    'an',
    'Ġf',
    'oot',
    'ad',
    'es',
    'Ġth',
    'ird',
    'Ġm',
    'ot',
    'Ġev',
    'idence',
    'int',
    'on',
    'Ġth',
    'reat',
    'a',
    'pt',
    'pl',
    'es',
    'c',
    'le',
    'Ġl',
    'o',
    'Ġde',
    'cl',
    'Ġit',
    'em',
    'med',
    'i',
    'Ġrep',
    'resent',
    'om',
    'b',
    'am',
    'er',
    'Ġsignific',
    'ant',
    'og',
    'raph',
    's',
    'u',
    'Ġc',
    'al',
    'i',
    'res',
    '00',
    '00',
    'I',
    'D',
    'A',
    'M',
    'Ġsim',
    'ply',
    'Ġlong',
    'er',
    'Ġf',
    'ile',
    'O',
    'T',
    'c',
    'he',
    'S',
    'o',
    'ate',
    'g',
    'or',
    'g',
    'ĠH',
    'is',
    'Ġen',
    'er',
    'Ġd',
    'om',
    'Ġup',
    'on',
    'il',
    'i',
    '":',
    '"',
    'Ġthem',
    'selves',
    'Ġcom',
    'ing',
    'Ġqu',
    'ite',
    'Ġdiff',
    'icult',
    'ĠB',
    'ar',
    'il',
    'ities',
    're',
    'l',
    'end',
    's',
    'c',
    'ial',
    '6',
    '4',
    'Ġwom',
    'an',
    'ra',
    'p',
    'y',
    'r',
    'Ġne',
    'cess',
    'ip',
    's',
    'Ġte',
    'xt',
    'Ġrequ',
    'ire',
    'Ġmilit',
    'ary',
    'Ġre',
    'view',
    'Ġresp',
    'ons',
    '7',
    '5',
    'Ġsub',
    'ject',
    'Ġinst',
    'ead',
    'Ġiss',
    'ues',
    'Ġg',
    'en',
    '"',
    ',"',
    'Ġmin',
    'utes',
    'Ġwe',
    'ap',
    'r',
    'ay',
    'am',
    'ed',
    't',
    'ime',
    'b',
    'l',
    'H',
    'ow',
    'Ġc',
    'ode',
    'ĠS',
    'm',
    'Ġhig',
    'her',
    'ĠSt',
    'e',
    'r',
    'is',
    'Ġp',
    'age',
    'Ġstud',
    'ents',
    'ĠIn',
    'tern',
    'Ġmet',
    'hod',
    'ĠA',
    'ug',
    'ĠP',
    'er',
    'ĠA',
    'g',
    'Ġpolic',
    'y',
    'ĠS',
    'w',
    'Ġex',
    'ec',
    'Ġac',
    'cept',
    'um',
    'e',
    'rib',
    'ut',
    'Ġword',
    's',
    'Ġfin',
    'al',
    'Ġchang',
    'es',
    'ĠDem',
    'ocr',
    'Ġfriend',
    's',
    'Ġres',
    'pect',
    'Ġe',
    'p',
    'Ġcomp',
    'an',
    'iv',
    'il',
    'Ġdam',
    'age',
    '**',
    '**',
    'og',
    'le',
    'viron',
    'ment',
    'Ġne',
    'g',
    'ent',
    'al',
    'Ġa',
    'p',
    'Ġtot',
    'al',
    'iv',
    'al',
    '!',
    '"',
    'l',
    'im',
    'Ġneed',
    's',
    'Ġag',
    're',
    'Ġdevelop',
    'ment',
    'Ġa',
    'ge',
    'ip',
    'le',
    '2',
    '1',
    'Ġresult',
    's',
    'ĠA',
    'f',
    'S',
    'h',
    'Ġg',
    'un',
    'ĠOb',
    'ama',
    'ro',
    'll',
    'Ġ',
    '@',
    'Ġright',
    's',
    'ĠB',
    'rit',
    'Ġrun',
    'ning',
    'Ġwas',
    'n',
    'Ġp',
    'ort',
    'Ġr',
    'ate',
    'Ġpret',
    'ty',
    'Ġtarg',
    'et',
    'Ġsa',
    'w',
    'Ġc',
    'irc',
    'Ġwor',
    'ks',
    'ic',
    'ro',
    'al',
    't',
    'o',
    'ver',
    'ww',
    'w',
    'Th',
    'at',
    'l',
    'ier',
    'Ġevery',
    'one',
    'ud',
    'e',
    'Ġp',
    'ie',
    'idd',
    'le',
    'ra',
    'el',
    'Ġr',
    'ad',
    'Ġbl',
    'ock',
    'Ġw',
    'alk',
    'T',
    'o',
    'ã',
    'ģ',
    'n',
    'es',
    'ĠA',
    'ust',
    'a',
    'ul',
    'ro',
    'te',
    'ĠS',
    'outh',
    'ess',
    'ion',
    'op',
    'h',
    'Ġshow',
    's',
    'Ġs',
    'ite',
    'Ġj',
    'o',
    'Ġr',
    'isk',
    'cl',
    'us',
    'l',
    't',
    'Ġin',
    'j',
    'id',
    'ing',
    'ĠS',
    'pe',
    'Ġch',
    'all',
    'ir',
    'm',
    'Ġ2',
    '2',
    'itt',
    'ing',
    'st',
    'r',
    'Ġh',
    'y',
    'L',
    'E',
    'ke',
    'y',
    'Ġbe',
    'gan',
    'at',
    'ur',
    'ashing',
    'ton',
    'l',
    'am',
    'ĠD',
    'av',
    'b',
    'it',
    'Ġs',
    'ize',
    'ĠP',
    'ar',
    '3',
    '8',
    'ourn',
    'al',
    'f',
    'ace',
    'Ġdec',
    'ision',
    'Ġl',
    'arg',
    'Ġj',
    'ud',
    're',
    'ct',
    'Ġcontin',
    'ue',
    'ĠO',
    'ct',
    'ove',
    'red',
    'ĠI',
    'nt',
    '====',
    '====',
    'Ġp',
    'arent',
    'ĠW',
    'ill',
    'Ġeas',
    'y',
    'Ġd',
    'rug',
    'ang',
    'er',
    'Ġs',
    'ense',
    'Ġd',
    'i',
    'id',
    'ay',
    'Ġener',
    'gy',
    'ist',
    'ic',
    'Ġass',
    'oci',
    'ar',
    'ter',
    'ob',
    'al',
    'e',
    'ks',
    'ĠE',
    'l',
    'ur',
    'ch',
    'Ġg',
    'irl',
    'o',
    'e',
    'it',
    'le',
    'Ġ2',
    '8',
    'ĠC',
    'he',
    'Ġrequ',
    'est',
    'Ġso',
    'on',
    'Ġh',
    'ost',
    'k',
    'y',
    'Ġst',
    'ates',
    'om',
    'es',
    'Ġm',
    'aterial',
    'le',
    'x',
    'Ġmom',
    'ent',
    'Ġan',
    'sw',
    'on',
    'se',
    'Ġes',
    'pecially',
    'Ġn',
    'orm',
    'Ġserv',
    'ices',
    'p',
    'ite',
    'r',
    'an',
    'Ġro',
    'le',
    '4',
    '4',
    ')',
    ':',
    'Ġc',
    'red',
    'C',
    'l',
    '____',
    '____',
    'Ġm',
    'at',
    'Ġl',
    'og',
    'ĠCl',
    'inton',
    'O',
    'U',
    'Ġoff',
    'ice',
    'Ġ2',
    '6',
    'Ġch',
    'arg',
    'Ġtr',
    'ack',
    'm',
    'a',
    'Ġhe',
    'art',
    'Ġb',
    'all',
    'Ġperson',
    'al',
    'Ġbuild',
    'ing',
    'n',
    'a',
    's',
    'et',
    'b',
    'ody',
    'ĠBl',
    'ack',
    'Ġincre',
    'ase',
    'itt',
    'en',
    'Ġneed',
    'ed',
    '3',
    '6',
    '3',
    '2',
    '=',
    '"',
    'Ġl',
    'ost',
    'Ġbec',
    'ame',
    'Ġgrou',
    'ps',
    'ĠM',
    'us',
    'Ġw',
    'rote',
    'ĠP',
    'e',
    'Ġpro',
    'p',
    'j',
    'oy',
    'Ã',
    '©',
    'ĠWh',
    'ite',
    'Ġde',
    'ad',
    '.',
    "'",
    'Ġhtt',
    'p',
    'Ġwe',
    'bs',
    'O',
    'S',
    'Ġins',
    'ide',
    'Ġwr',
    'ong',
    'Ġstat',
    'ement',
    'Ġ',
    '...',
    'y',
    'l',
    'Ġfil',
    'm',
    'Ġmus',
    'ic',
    'Ġsh',
    'are',
    'ific',
    'ation',
    'Ġre',
    'lease',
    'Ġfor',
    'ward',
    'Ġst',
    'ay',
    'Ġcomp',
    'ut',
    'it',
    'te',
    's',
    'er',
    'Ġorig',
    'inal',
    'Ġc',
    'ard',
    'Ġc',
    'and',
    'Ġd',
    'iv',
    'at',
    'ural',
    'Ġfav',
    'or',
    'O',
    'M',
    'Ġc',
    'ases',
    'us',
    'es',
    'Ġse',
    'ction',
    'Ġle',
    'ave',
    'g',
    'ing',
    'ov',
    'ed',
    'ĠW',
    'ashington',
    '3',
    '9',
    'ĠG',
    'l',
    'Ġrequ',
    'ired',
    'act',
    'ion',
    'ap',
    'an',
    'o',
    'or',
    'it',
    'er',
    'ĠK',
    'ing',
    'Ġcount',
    'ries',
    'ĠG',
    'erman',
    'll',
    'ing',
    'Ġ2',
    '7',
    '3',
    '4',
    'Ġquest',
    'ions',
    'Ġpr',
    'im',
    'Ġc',
    'ell',
    'Ġsh',
    'oot',
    'Ġany',
    'one',
    'ĠW',
    'est',
    'Ġaff',
    'ect',
    'ep',
    'end',
    'Ġon',
    'line',
    'ĠIs',
    'rael',
    'ĠSept',
    'ember',
    'Ġab',
    'ility',
    'Ġcont',
    'ent',
    'is',
    'es',
    'Ġre',
    've',
    'Ġl',
    'aun',
    'Ġind',
    'ic',
    'Ġfor',
    'ce',
    'c',
    'ast',
    'Ġso',
    'ld',
    'av',
    'ing',
    'f',
    'l',
    'Ġso',
    'ft',
    'Ġcompan',
    'ies',
    'ce',
    'ed',
    'Ġart',
    'icle',
    'Ġa',
    'ud',
    'Ġre',
    'v',
    'Ġed',
    'uc',
    'Ġplay',
    'ing',
    '0',
    '5',
    'Ġhe',
    'ld',
    'ct',
    'or',
    'Ġrele',
    'ased',
    'Ġf',
    'ederal',
    '3',
    '7',
    'Ġad',
    'minist',
    'Ġinter',
    'view',
    'Ġinst',
    'all',
    'Ġrece',
    'ived',
    'Ġs',
    'ource',
    'u',
    'k',
    'P',
    'h',
    'Ġser',
    'ious',
    'Ġcre',
    'ated',
    'Ġc',
    'ause',
    'Ġim',
    'medi',
    'Ġdef',
    'in',
    'u',
    'el',
    'ĠDep',
    'artment',
    'ct',
    'ions',
    'ĠC',
    'our',
    'ĠN',
    'ow',
    'z',
    'e',
    'it',
    'es',
    'it',
    'ution',
    'Ġl',
    'ate',
    'Ġspe',
    'ak',
    'n',
    'ers',
    'Ġleg',
    'al',
    'ar',
    'i',
    'ĠC',
    'or',
    'Ġwe',
    'eks',
    'Ġmod',
    'el',
    'Ġp',
    'red',
    'Ġex',
    'act',
    'B',
    'C',
    'ĠB',
    'y',
    'IN',
    'G',
    'os',
    'ing',
    'Ġt',
    'akes',
    'Ġreg',
    'ard',
    'Ġopp',
    'ortun',
    'Ġpr',
    'ice',
    'Ġ19',
    '8',
    'ĠA',
    'pr',
    'f',
    'ully',
    'Ġor',
    'd',
    'Ġproble',
    'ms',
    'ru',
    'ction',
    'h',
    'am',
    'ĠC',
    'ount',
    'le',
    'ge',
    'Ġlead',
    'ers',
    'E',
    'T',
    'le',
    'v',
    'Ġde',
    'ep',
    'olog',
    'ical',
    'es',
    'e',
    'h',
    'aps',
    'ĠS',
    'ome',
    'Ġp',
    'ers',
    'Ġcont',
    'ract',
    'Ġrelations',
    'hip',
    's',
    'p',
    'ou',
    'd',
    'Ġb',
    'ase',
    '4',
    '8',
    'm',
    'it',
    'A',
    'd',
    'anc',
    'ial',
    'Ġcons',
    'um',
    'Ġpot',
    'ential',
    'Ġl',
    'angu',
    're',
    'm',
    'et',
    'h',
    'Ġrel',
    'ig',
    'ress',
    'ed',
    '6',
    '6',
    'Ġl',
    'ink',
    'Ġl',
    'ower',
    'ay',
    'er',
    'ĠJ',
    'une',
    'Ġf',
    'em',
    'un',
    't',
    'er',
    'c',
    'ur',
    'd',
    'Ġcont',
    'act',
    'Ġ',
    'ill',
    'Ġm',
    'other',
    'Ġest',
    'ab',
    'h',
    'tt',
    'ĠM',
    'arch',
    'ĠB',
    'ro',
    'ĠCh',
    'ina',
    'Ġ2',
    '9',
    'Ġs',
    'qu',
    'Ġprov',
    'ided',
    'Ġa',
    'verage',
    'as',
    'ons',
    'Ġ201',
    '1',
    'Ġex',
    'am',
    'l',
    'in',
    '5',
    '5',
    'n',
    'ed',
    'Ġper',
    'fect',
    'Ġt',
    'ou',
    'al',
    'se',
    'u',
    'x',
    'Ġbu',
    'y',
    'Ġsh',
    'ot',
    'Ġcol',
    'lect',
    'Ġph',
    'ot',
    'Ġplay',
    'ed',
    'Ġsur',
    'pr',
    'Ġofficial',
    's',
    'Ġsim',
    'ple',
    'av',
    'y',
    'Ġindust',
    'ry',
    'Ġhand',
    's',
    'g',
    'round',
    'Ġp',
    'ull',
    'Ġr',
    'ound',
    'Ġus',
    'er',
    'Ġr',
    'ange',
    'u',
    'ary',
    'Ġpriv',
    'ate',
    'op',
    's',
    'e',
    'es',
    'Ġw',
    'ays',
    'ĠM',
    'ich',
    'Ġve',
    'h',
    'Ġex',
    'cept',
    'Ġter',
    'ms',
    'im',
    'um',
    'pp',
    'er',
    'I',
    'ON',
    'ore',
    's',
    'ĠDr',
    'agon',
    'ou',
    'l',
    'Ġd',
    'en',
    'Ġperform',
    'ance',
    'Ġb',
    'ill',
    'c',
    'il',
    '4',
    '7',
    'Ġen',
    'vironment',
    'Ġex',
    'c',
    'ad',
    'd',
    'Ġwor',
    'th',
    'Ġp',
    'ict',
    'Ġch',
    'ance',
    'Ġ201',
    '8',
    'b',
    'or',
    'Ġspe',
    'ed',
    'ict',
    'ion',
    'Ġal',
    'leg',
    'ĠJ',
    'apan',
    'at',
    'ory',
    're',
    'et',
    'Ġm',
    'atch',
    'ĠI',
    'I',
    'Ġst',
    'ru',
    'ord',
    'er',
    'Ġst',
    'e',
    'Ġl',
    'iving',
    'Ġst',
    'ruct',
    'in',
    'o',
    'Ġse',
    'par',
    'her',
    'n',
    'Ġresp',
    'onse',
    'Ġen',
    'joy',
    'Ġv',
    'ia',
    'A',
    'D',
    'um',
    'ents',
    'ace',
    'book',
    'Ġmem',
    'ber',
    'ib',
    'r',
    'iz',
    'ing',
    'Ġto',
    'ol',
    'ĠM',
    'on',
    'ĠWh',
    'ile',
    'h',
    'ood',
    'ĠA',
    'ng',
    'ĠD',
    'ef',
    'Ġoff',
    'er',
    'T',
    'r',
    'a',
    'ur',
    'Ġturn',
    'ed',
    'ĠJ',
    'uly',
    'd',
    'own',
    'an',
    'ced',
    'Ġrec',
    'ently',
    'ĠE',
    'ar',
    'Ġc',
    'e',
    'ĠSt',
    'ar',
    'ĠC',
    'ong',
    'rough',
    't',
    'Ġbl',
    'ood',
    'Ġhop',
    'e',
    'Ġcom',
    'ment',
    'ain',
    't',
    'Ġar',
    'ri',
    'il',
    'es',
    'Ġpartic',
    'ip',
    'ough',
    't',
    'ri',
    'ption',
    '0',
    '8',
    '4',
    '9',
    'Ġg',
    'ave',
    'Ġse',
    'lect',
    'Ġkill',
    'ed',
    'sy',
    'ch',
    'Ġgo',
    'es',
    'i',
    'j',
    'Ġc',
    'oll',
    'Ġimp',
    'act',
    'at',
    'ives',
    'ĠS',
    'er',
    '0',
    '9',
    'ĠAug',
    'ust',
    'Ġb',
    'oy',
    'd',
    'e',
    'ĠD',
    'es',
    'Ġf',
    'elt',
    'U',
    'S',
    'Ġexpect',
    'ed',
    'Ġim',
    'age',
    'ĠM',
    'ark',
    'cc',
    'ording',
    'o',
    'ice',
    'E',
    'C',
    'ĠM',
    'ag',
    'en',
    'ed',
    'h',
    'old',
    'ĠP',
    'ost',
    'Ġpre',
    'vent',
    'N',
    'o',
    'Ġinvol',
    'ved',
    'Ġey',
    'es',
    'Ġquick',
    'ly',
    'A',
    't',
    'un',
    'k',
    'Ġbeh',
    'av',
    'Ġ',
    'ur',
    'Ġl',
    'ed',
    'c',
    'ome',
    'e',
    'y',
    'Ġcand',
    'id',
    'Ġear',
    'lier',
    'Ġfoc',
    'us',
    'et',
    'y',
    'P',
    'ro',
    'led',
    'ge',
    'ix',
    'ed',
    'ill',
    'ed',
    'Ġpop',
    'ular',
    'A',
    'P',
    'Ġset',
    't',
    'l',
    'ight',
    'Ġvar',
    'ious',
    'in',
    'ks',
    'Ġlevel',
    's',
    'Ġro',
    'ad',
    'ell',
    'ig',
    'ab',
    'les',
    'he',
    'l',
    'itte',
    'e',
    'ĠG',
    'ener',
    'y',
    'pe',
    'Ġhe',
    'ard',
    'ic',
    'les',
    'Ġm',
    'is',
    'Ġus',
    'ers',
    'ĠS',
    'an',
    'Ġimpro',
    've',
    'Ġf',
    'ather',
    'Ġse',
    'arch',
    'The',
    'y',
    'v',
    'il',
    'Ġprof',
    'ess',
    'Ġkn',
    'ew',
    'Ġl',
    'oss',
    'Ġev',
    'ents',
    '6',
    '5',
    'Ġb',
    'illion',
    '0',
    '7',
    '0',
    '2',
    'ĠNew',
    's',
    'ĠA',
    'M',
    'Ġco',
    'ver',
    'w',
    'here',
    'ens',
    'ion',
    'Ġb',
    'ott',
    'Ġare',
    'as',
    'en',
    'ces',
    'op',
    'e',
    'ĠTw',
    'itter',
    'a',
    'el',
    'Ġget',
    's',
    'ĠGo',
    'ogle',
    'Ġs',
    'n',
    'i',
    'ant',
    'Ġv',
    'ote',
    'Ġnear',
    'ly',
    'Ġinclud',
    'ed',
    'Ġrec',
    'ogn',
    'z',
    'z',
    'm',
    'm',
    'al',
    'ed',
    'Ġhappen',
    'ed',
    '0',
    '4',
    'Ġh',
    'ot',
    'Ġwho',
    'se',
    'Ġc',
    'ivil',
    'Ġsu',
    'ff',
    'o',
    'es',
    'it',
    'iz',
    'ĠSy',
    'ri',
    'Ġresp',
    'ond',
    'Ġh',
    'on',
    'Ġfeat',
    'ures',
    'Ġeconom',
    'ic',
    'ĠApr',
    'il',
    'r',
    'im',
    'Ġtechn',
    'ology',
    'Ġo',
    'ption',
    'ag',
    'ing',
    'Ġpur',
    'ch',
    'R',
    'e',
    'Ġl',
    'at',
    'ch',
    'ie',
    'is',
    'l',
    'Ġrec',
    'omm',
    'u',
    'f',
    'Ġtr',
    'aining',
    'Ġeffect',
    's',
    'Ġf',
    'ast',
    'Ġ201',
    '0',
    'Ġocc',
    'ur',
    'Ġwebs',
    'ite',
    'Ġem',
    'ail',
    'Ġs',
    'ens',
    'e',
    'ch',
    'Ġo',
    'il',
    'Ġinf',
    'lu',
    'Ġcurrent',
    'ly',
    'ĠS',
    'ch',
    'ĠAd',
    'd',
    'Ġgo',
    'al',
    'Ġsc',
    'ient',
    'Ġcon',
    'v',
    '1',
    '00',
    'em',
    'y',
    'Ġdec',
    'ided',
    'Ġtra',
    'vel',
    'Ġm',
    'ention',
    'L',
    'L',
    '0',
    '3',
    'Ġe',
    'lection',
    'Ġph',
    'one',
    'Ġlook',
    's',
    'Ġsit',
    'uation',
    'Ġc',
    'y',
    'Ġh',
    'or',
    'b',
    'ed',
    'ĠCour',
    't',
    'a',
    'ily',
    'av',
    'es',
    'Ġqu',
    'ality',
    'ĠCom',
    'p',
    'w',
    'ise',
    'Ġt',
    'able',
    'Ġst',
    'aff',
    'ĠW',
    'ind',
    'et',
    't',
    'Ġtri',
    'ed',
    'ide',
    'red',
    'Ġadd',
    'ition',
    'Ġb',
    'ox',
    'Ġl',
    'ack',
    'ar',
    'ily',
    'Ġw',
    'ide',
    'Ġm',
    'id',
    'Ġbo',
    'ard',
    'ys',
    'is',
    'Ġant',
    'i',
    'h',
    'a',
    'Ġd',
    'ig',
    'en',
    'ing',
    'Ġd',
    'ro',
    'C',
    'on',
    '6',
    '8',
    'Ġsl',
    'ow',
    'b',
    'ased',
    'se',
    'qu',
    'Ġp',
    'ath',
    'E',
    'x',
    'ak',
    'er',
    'Ġwork',
    'ed',
    'Ġp',
    'en',
    'Ġeng',
    'ine',
    'Ġlook',
    'ed',
    'ĠSu',
    'per',
    'ĠS',
    'erv',
    'Ġvict',
    'im',
    'U',
    'n',
    'Ġproper',
    'ty',
    'Ġint',
    'rodu',
    'Ġexec',
    'ut',
    'ĠP',
    'M',
    'L',
    'e',
    'Ġcol',
    'or',
    'ĠM',
    'ore',
    'Ġ6',
    '0',
    'Ġnet',
    'work',
    'Ġd',
    'ate',
    'c',
    'ul',
    'id',
    'ge',
    'Ġext',
    'ra',
    '3',
    '1',
    'Ġs',
    'le',
    '6',
    '7',
    'Ġw',
    'ond',
    'Ġreport',
    's',
    'j',
    'ust',
    'ĠAust',
    'ral',
    'Ġcap',
    'ital',
    'Ġen',
    's',
    'Ġcomm',
    'and',
    'Ġallow',
    'ed',
    'Ġpre',
    'p',
    'Ġca',
    'pt',
    'h',
    'ib',
    'Ġnum',
    'bers',
    'ch',
    'an',
    'Ġf',
    'air',
    'm',
    'p',
    'om',
    's',
    'Ġre',
    'ach',
    'W',
    'ith',
    't',
    'ain',
    'Ġbro',
    'ad',
    'Ġcou',
    'ple',
    'ec',
    'ause',
    'ly',
    'ing',
    'ĠF',
    'eb',
    'Ġsc',
    'reen',
    'Ġl',
    'ives',
    'Ġpri',
    'or',
    'ĠCong',
    'ress',
    'A',
    'r',
    'Ġappro',
    'ach',
    'Ġe',
    'mer',
    'ar',
    'ies',
    'ĠD',
    'is',
    's',
    'erv',
    'ĠN',
    'e',
    'Ġbu',
    'ilt',
    'c',
    'ies',
    'Ġre',
    'pe',
    'Ġrul',
    'es',
    'for',
    'ce',
    'ĠP',
    'al',
    'Ġfin',
    'ancial',
    'Ġcons',
    'idered',
    'ĠCh',
    'ar',
    'n',
    'ces',
    'ĠI',
    'S',
    'Ġb',
    'rought',
    'Ġb',
    'i',
    'i',
    'ers',
    'ĠS',
    'im',
    'O',
    'P',
    'Ġproduct',
    's',
    'Ġvis',
    'it',
    'Ġdoc',
    'ument',
    'Ġcon',
    'duct',
    'Ġcomplete',
    'ly',
    'in',
    'ing',
    'ĠCal',
    'if',
    'ib',
    'ly',
    'Ġwr',
    'itten',
    'ĠT',
    'V',
    'em',
    'ents',
    'Ġd',
    'raw',
    'O',
    'ne',
    'Ġpub',
    'lished',
    'Ġsec',
    'ret',
    'r',
    'ain',
    'he',
    't',
    'ĠF',
    'acebook',
    'ond',
    'ay',
    'ĠU',
    'p',
    'Ġsex',
    'ual',
    'Ġth',
    'ous',
    'ĠP',
    'at',
    'Ġ',
    'ess',
    'Ġstand',
    'ard',
    'Ġar',
    'm',
    'g',
    'es',
    'ect',
    'ion',
    'Ġf',
    'ell',
    'Ġfore',
    'ign',
    'an',
    'i',
    'ĠFr',
    'iday',
    'Ġreg',
    'ular',
    'in',
    'ary',
    'Ġincre',
    'ased',
    'Ġus',
    'ually',
    'Ġdem',
    'on',
    'Ġd',
    'ark',
    'Ġadd',
    'itional',
    'ro',
    'l',
    'ĠO',
    'f',
    'Ġprodu',
    'ction',
    '!',
    '!',
    'und',
    'red',
    'Ġintern',
    'ational',
    'id',
    'ents',
    'ĠF',
    'ree',
    'rou',
    'p',
    'Ġr',
    'ace',
    'Ġm',
    'ach',
    'Ġh',
    'uge',
    'A',
    'll',
    'le',
    'ar',
    'ove',
    'mber',
    'Ġto',
    'wn',
    'Ġatt',
    'ention',
    'ĠO',
    'ff',
    'y',
    'ond',
    'ĠThe',
    'n',
    'f',
    'ield',
    'Ġter',
    'ror',
    'ra',
    'z',
    'ĠB',
    'o',
    'Ġmeet',
    'ing',
    'ĠP',
    'ark',
    'Ġar',
    'rest',
    'Ġf',
    'ear',
    'Ġa',
    'w',
    'ĠV',
    'al',
    'or',
    'ing',
    "'",
    ',',
    'Ġext',
    'reme',
    'ar',
    'r',
    'Ġwork',
    'ers',
    'A',
    'fter',
    'Ġ3',
    '1',
    'n',
    'et',
    'am',
    'ent',
    'Ġdirect',
    'ly',
    'Ġpop',
    'ulation',
    'ub',
    'e',
    'ĠOct',
    'ober',
    'ĠI',
    'N',
    'ĠJan',
    'uary',
    '5',
    '9',
    'ĠDav',
    'id',
    'Ġc',
    'ross',
    'ce',
    'mber',
    'ĠF',
    'irst',
    'Ġmess',
    'age',
    'ir',
    'it',
    'Ġn',
    'ation',
    'Ġp',
    'oll',
    'is',
    'ions',
    'Ġansw',
    'er',
    'n',
    'y',
    'is',
    'ode',
    'Ġcar',
    'ry',
    'ĠRuss',
    'ia',
    'Ġhe',
    'ar',
    'eng',
    'th',
    'ro',
    'y',
    'Ġn',
    'atural',
    'in',
    'ally',
    'Ġdo',
    'g',
    'm',
    'itted',
    'Ġtr',
    'ade',
    'Ġsub',
    'st',
    'Ġmult',
    'iple',
    'ĠAf',
    'ric',
    'Ġf',
    'ans',
    'Ġs',
    'ort',
    'Ġgl',
    'obal',
    'ic',
    'ation',
    'ĠW',
    'ed',
    'ar',
    'a',
    'Ġa',
    'chie',
    'Ġlangu',
    'age',
    've',
    'y',
    'Ġt',
    'al',
    'Ġnecess',
    'ary',
    'Ġdet',
    'ails',
    'Ġs',
    'en',
    'ĠS',
    'und',
    'ĠRe',
    'g',
    'ĠR',
    'ec',
    '0',
    '6',
    'Ġs',
    'il',
    'ress',
    'ive',
    'Ġmed',
    'ical',
    'un',
    'ch',
    'orn',
    'ia',
    'Ġu',
    'nd',
    'f',
    'ort',
    'oc',
    'ks',
    'ĠM',
    'onday',
    'ues',
    'day',
    'c',
    'raft',
    '7',
    '7',
    'ur',
    't',
    'Ġ',
    'ver',
    'ĠH',
    'ill',
    'Ġrece',
    'ive',
    'Ġmor',
    'ning',
    'es',
    'tern',
    'Ġb',
    'ank',
    'Ġs',
    'at',
    'ir',
    'th',
    'ĠH',
    'igh',
    'Ġdev',
    'ice',
    'ĠTH',
    'E',
    'ĠCent',
    'er',
    'Ġsaf',
    'e',
    'Ġp',
    'le',
    'ĠCanad',
    'a',
    'Ġsystem',
    's',
    'Ġass',
    'ist',
    'Ġsur',
    'v',
    'Ġb',
    'attle',
    'ĠS',
    'oc',
    'vert',
    'is',
    'S',
    'he',
    'Ġp',
    'aper',
    'Ġgrow',
    'th',
    'Ġc',
    'ast',
    'S',
    'c',
    'Ġpl',
    'ans',
    'll',
    'ed',
    'Ġpart',
    's',
    'Ġw',
    'all',
    'Ġmove',
    'ment',
    'Ġpract',
    'ice',
    'im',
    'ately',
    'Ġdis',
    'play',
    'Ġsomet',
    'imes',
    'om',
    'p',
    'ĠP',
    'aul',
    'ĠY',
    'es',
    'k',
    'ing',
    '5',
    '8',
    'o',
    'ly',
    'Ġs',
    'on',
    'Ġav',
    'oid',
    'ok',
    'es',
    'ĠJ',
    'ew',
    'Ġto',
    'wards',
    'as',
    'c',
    'Ġ',
    '//',
    'ĠK',
    'ore',
    'Ġtalk',
    'ing',
    'Ġcor',
    'rect',
    'Ġsp',
    'ent',
    'ic',
    'ks',
    'i',
    'able',
    'e',
    'ared',
    'Ġter',
    'm',
    'Ġwant',
    's',
    'om',
    'ing',
    'Ġ',
    'ut',
    'Ġdou',
    'b',
    'Ġfor',
    'ces',
    'Ġp',
    'lease',
    '6',
    '9',
    'ĠN',
    'ovember',
    'at',
    'form',
    'ond',
    'on',
    'Ġon',
    'es',
    'Ġimmedi',
    'ately',
    'ĠRuss',
    'ian',
    'ĠM',
    'et',
    'Ġde',
    'g',
    'Ġparent',
    's',
    'C',
    'H',
    'ĠAmeric',
    'ans',
    'al',
    'y',
    'ĠM',
    'od',
    'Ġsh',
    'own',
    'Ġcond',
    'itions',
    'Ġst',
    'uff',
    'Ġre',
    'b',
    'ĠY',
    'our',
    'Ġinclud',
    'es',
    'n',
    'own',
    'ĠS',
    'am',
    'Ġexper',
    'ien',
    'm',
    'ission',
    'ĠE',
    'ven',
    'augh',
    't',
    'Ġannoun',
    'ced',
    'ĠRepublic',
    'an',
    'Ġdeter',
    'min',
    'Ġdescrib',
    'ed',
    'ĠCount',
    'y',
    '(',
    ')',
    'Ġdo',
    'or',
    'Ġchang',
    'ed',
    'Ġne',
    'igh',
    'ĠH',
    'ere',
    'Ġcle',
    'an',
    'Ġp',
    'an',
    'ĠDe',
    'cember',
    'ĠEurope',
    'an',
    'ir',
    'ing',
    'ap',
    'ter',
    'Ġcl',
    'ub',
    'ĠT',
    'uesday',
    'Ġp',
    'aid',
    'ĠN',
    'et',
    'Ġattack',
    's',
    'Ġcharact',
    'ers',
    'Ġal',
    'one',
    'Ġdirect',
    'or',
    'd',
    'om',
    'Ġ3',
    '5',
    'Ġl',
    'oad',
    'Ġr',
    'out',
    'ĠCalif',
    'ornia',
    'Ġfin',
    'ally',
    'Ġr',
    'ac',
    'Ġcont',
    'r',
    'Ġexact',
    'ly',
    'res',
    'h',
    'p',
    'ri',
    'ĠIs',
    'lam',
    'Ġn',
    'ature',
    'Ġcare',
    'er',
    'Ġlat',
    'est',
    'Ġcon',
    'vers',
    'ĠS',
    'l',
    'p',
    'ose',
    'ci',
    'ent',
    'ĠIn',
    'c',
    'iv',
    'ity',
    '8',
    '8',
    'ĠA',
    'tt',
    'ĠM',
    'or',
    'nes',
    'day',
    'Ġwe',
    'ight',
    'k',
    'en',
    'Ġnot',
    'e',
    'Ġteam',
    's',
    'Ġ',
    '\\',
    'air',
    's',
    'ĠG',
    'reen',
    'Ġh',
    'undred',
    'on',
    'ent',
    'Ġstre',
    'ng',
    'Ġcons',
    'ist',
    'ic',
    'ated',
    'Ġreg',
    'ul',
    'Ġl',
    'ic',
    'ast',
    'ic',
    'Ġt',
    'en',
    'urs',
    'day',
    'ellig',
    'ence',
    'ous',
    'ly',
    'ĠU',
    'K',
    'B',
    'I',
    'Ġcost',
    's',
    'Ġind',
    'epend',
    'ĠA',
    'P',
    'Ġnorm',
    'al',
    'Ġh',
    'om',
    'Ġob',
    'vious',
    'Ġs',
    'we',
    'Ġst',
    'ar',
    'Ġread',
    'y',
    'ac',
    'her',
    'Ġimp',
    'lement',
    'g',
    'est',
    'Ġs',
    'ong',
    'ĠG',
    'et',
    'ĠL',
    'ab',
    'Ġinterest',
    'ing',
    'us',
    'ing',
    'Ġg',
    'iving',
    'ĠSund',
    'ay',
    'Ġet',
    'c',
    'Ġm',
    'iddle',
    'Ġrem',
    'ember',
    'r',
    'ight',
    'os',
    'ition',
    'ut',
    'ions',
    'Ġm',
    'ax',
    '4',
    '6',
    'Ġyour',
    'self',
    'Ġdem',
    'and',
    'Ġtreat',
    'ment',
    'Ġd',
    'anger',
    'ĠC',
    'ons',
    'Ġgu',
    'y',
    'ĠBrit',
    'ish',
    'Ġphys',
    'ical',
    'Ġrel',
    'ated',
    'Ġrem',
    'ain',
    'Ġcould',
    'n',
    'Ġref',
    'er',
    'Ġc',
    'itiz',
    'b',
    'ox',
    'EN',
    'T',
    'bo',
    'ard',
    'Ġin',
    'n',
    'I',
    'G',
    'er',
    'o',
    'ĠSt',
    'reet',
    'osp',
    'ital',
    'ren',
    'ch',
    'cher',
    's',
    'Ġst',
    'ra',
    'O',
    'L',
    'ag',
    'er',
    'ĠA',
    'N',
    'Ġeas',
    'ily',
    'I',
    'A',
    'en',
    'ge',
    'in',
    'y',
    'Ġcl',
    'os',
    'ock',
    'ed',
    'Ġus',
    'es',
    'ĠC',
    'oun',
    'I',
    'm',
    'u',
    'ild',
    '?',
    '?',
    'm',
    'ore',
    'Ġan',
    'g',
    'Ġwr',
    'ite',
    'ol',
    'ute',
    '5',
    '7',
    'Ġlead',
    'er',
    'Ġread',
    'ing',
    '<',
    '/',
    'Ġaut',
    'om',
    'est',
    's',
    '4',
    '3',
    'Ġleg',
    'isl',
    'ĠG',
    'old',
    'Ġdesign',
    'ed',
    'ĠS',
    'T',
    'ĠLe',
    'g',
    'a',
    'res',
    'Ġbe',
    'aut',
    'ĠT',
    'ex',
    'Ġappear',
    's',
    'Ġstru',
    'gg',
    'ĠR',
    'om',
    'Ġ',
    '00',
    'Ġcho',
    'ice',
    'Ġparticular',
    'ly',
    'ĠF',
    'rom',
    'op',
    'er',
    'ĠL',
    'ondon',
    'ann',
    'ed',
    'Ġallow',
    's',
    'ob',
    'ile',
    'Ġdiffere',
    'nce',
    'âĢ',
    '¢',
    'ĠV',
    'iew',
    'ĠWed',
    'nesday',
    'Ġal',
    'though',
    'Ġrel',
    'ative',
    'Ġapplic',
    'ation',
    'ate',
    'ver',
    'Ġare',
    'n',
    'Ġmy',
    'self',
    'Ġim',
    'ag',
    'Ġdis',
    'e',
    'Ġsoc',
    'iety',
    'Ġfre',
    'qu',
    'ĠEng',
    'lish',
    'Ġpo',
    'or',
    'ĠD',
    'ay',
    'Ġwrit',
    'ing',
    'Ġse',
    'ven',
    'Ġstart',
    'ing',
    'Ġb',
    'ud',
    'Ġpr',
    'int',
    'ĠTr',
    'ans',
    'uf',
    'act',
    'ĠSt',
    'ud',
    'n',
    'ew',
    'Ġcr',
    'im',
    'Ġg',
    'ives',
    'Ġco',
    'ol',
    'a',
    'e',
    'i',
    'ance',
    'ĠGener',
    'al',
    'Ġthink',
    'ing',
    'Ġsa',
    've',
    'Ġlim',
    'ited',
    'ĠPart',
    'y',
    'Ġmean',
    'ing',
    'p',
    'en',
    'ow',
    'ers',
    'ĠJ',
    'ack',
    'E',
    'M',
    'Ġn',
    'ice',
    'ru',
    'pt',
    'Ġg',
    'as',
    'Ġe',
    'ight',
    'Ġfe',
    'et',
    'Ġeff',
    'ort',
    'Ġ',
    'ign',
    'ic',
    'it',
    'B',
    'l',
    'co',
    'in',
    'Ġop',
    'in',
    'Ġbr',
    'ain',
    'Wh',
    'ile',
    'he',
    'st',
    'ĠTh',
    'ursday',
    'Ġwould',
    'n',
    'augh',
    'ter',
    'Ġtou',
    'ch',
    'le',
    'ments',
    'Ġstud',
    'ies',
    'Ġcent',
    'er',
    'c',
    'ont',
    'or',
    'ge',
    'Ġcomput',
    'er',
    'Ġinvestig',
    'ation',
    'P',
    'l',
    'or',
    'ks',
    'Ġ200',
    '8',
    'Ġincre',
    'asing',
    'Ġst',
    'ore',
    'Ġcom',
    'ments',
    'Ġb',
    'al',
    'm',
    'en',
    'Ġdo',
    'll',
    'Ġl',
    'iber',
    'Ġw',
    'ife',
    'Ġlaw',
    's',
    'atur',
    'day',
    'it',
    'ness',
    'Ġmod',
    'ern',
    'ĠS',
    'k',
    'Ġadminist',
    'ration',
    'Ġopportun',
    'ity',
    'Ġs',
    'al',
    'Ġpower',
    'ful',
    'M',
    'y',
    'Ġclaim',
    's',
    'ĠEar',
    'th',
    'ord',
    's',
    'Ġt',
    'itle',
    'Ġes',
    'c',
    'n',
    'ame',
    'N',
    'ot',
    'om',
    'en',
    'Ġbe',
    'yond',
    'Ġc',
    'amer',
    'Ġse',
    'll',
    'it',
    'ute',
    'ear',
    'ch',
    'Ġapp',
    'l',
    'im',
    'ent',
    '4',
    '2',
    'ĠAr',
    't',
    'Ġun',
    'f',
    'Ġviol',
    'ence',
    'ur',
    'g',
    'ĠE',
    'ast',
    'Ġcomp',
    'ared',
    'Ġopt',
    'ions',
    'Ġthrough',
    'out',
    'Ġv',
    's',
    'ig',
    'r',
    '.',
    '[',
    'ac',
    'hes',
    '7',
    '8',
    'Ġfil',
    'es',
    'F',
    'L',
    'E',
    'L',
    'ar',
    'ian',
    'ĠJ',
    'ames',
    'ĠA',
    'ir',
    'an',
    'ch',
    'Ġdet',
    'ail',
    'Ġpie',
    'ce',
    'P',
    'S',
    'Ġn',
    'amed',
    'Ġeduc',
    'ation',
    'Ġdri',
    've',
    'Ġitem',
    's',
    'Ġstud',
    'ent',
    'ic',
    'ed',
    ':',
    ':',
    'ic',
    'o',
    'Ġth',
    'row',
    'Ġsc',
    'ene',
    'Ġcomple',
    'x',
    'Ġ200',
    '9',
    'Ġpre',
    'c',
    'ĠB',
    're',
    '7',
    '9',
    'Ġcon',
    'cept',
    'Ġstat',
    'us',
    'am',
    'ing',
    'Ġd',
    'ied',
    'Ġknow',
    'ledge',
    'Ġbegin',
    'ning',
    'O',
    'D',
    'ru',
    'ary',
    'Ġcertain',
    'ly',
    'Ġgu',
    'ys',
    'Ġsl',
    'ight',
    'in',
    'n',
    'ound',
    's',
    'Ġf',
    'ine',
    'Ġf',
    'at',
    'ic',
    'ations',
    'Ġper',
    'haps',
    'ĠA',
    'nt',
    'Ġinc',
    'ome',
    'Ġhtt',
    'ps',
    'Ġmajor',
    'ity',
    'port',
    's',
    'st',
    'on',
    'Ġgreat',
    'er',
    'Ġfe',
    'ed',
    'ent',
    'ially',
    'Ġsaf',
    'ety',
    'Ġun',
    'ique',
    'and',
    'om',
    'Ġg',
    'one',
    'Ġshow',
    'ed',
    'Ġhist',
    'or',
    'Ġcoun',
    'ter',
    'i',
    'us',
    'id',
    'a',
    'Ġlead',
    'ing',
    'i',
    'pe',
    'Ġs',
    'end',
    'ĠDon',
    'ald',
    'er',
    've',
    'Ġdef',
    'ense',
    'ines',
    'e',
    'Ġy',
    'es',
    'ĠF',
    'ire',
    'ĠMus',
    'lim',
    'ra',
    'q',
    'Ġcontin',
    'ued',
    'os',
    'h',
    'Ġprov',
    'ides',
    'Ġpr',
    'ison',
    'ĠP',
    're',
    'Ġhapp',
    'y',
    'Ġeconom',
    'y',
    'Ġtr',
    'ust',
    'ag',
    's',
    'ĠG',
    'ame',
    'Ġweap',
    'ons',
    'um',
    'an',
    'ĠC',
    'le',
    'it',
    'ation',
    'Ġanal',
    'ysis',
    'ĠT',
    'imes',
    'Ġsc',
    'ience',
    '-',
    '>',
    'Ġfig',
    'ure',
    'Ġdis',
    'app',
    'ent',
    'y',
    'Ġsoft',
    'ware',
    'Ġu',
    'lt',
    'Ġoffic',
    'ers',
    'N',
    'ew',
    'I',
    's',
    'Ġrem',
    'ains',
    'ĠInd',
    'ia',
    'Ġp',
    'sych',
    'ri',
    'ef',
    'Ġc',
    'at',
    'es',
    'c',
    'Ġob',
    'serv',
    'Ġst',
    'age',
    'ĠD',
    'ark',
    'Ġent',
    'er',
    'ch',
    'ange',
    'Ġpass',
    'ed',
    'Ġdes',
    'pite',
    'ĠO',
    'ut',
    'Ġmov',
    'ie',
    'r',
    's',
    'Ġv',
    'oice',
    'm',
    'ine',
    'ĠPl',
    'ay',
    'Ġto',
    'ward',
    'ĠT',
    'er',
    'Ġreg',
    'ion',
    'Ġval',
    'ues',
    'or',
    'ters',
    'Ġm',
    'ount',
    'Ġoffic',
    'er',
    'ĠO',
    'ther',
    'b',
    'an',
    'Ġh',
    'ous',
    'w',
    'ood',
    'ro',
    'om',
    'I',
    'V',
    'ĠS',
    'un',
    'se',
    'e',
    'ĠO',
    'ver',
    'ro',
    'g',
    '9',
    '0',
    'Ġl',
    'ay',
    'ĠT',
    'ur',
    'a',
    'wn',
    'Ġpress',
    'ure',
    'ĠS',
    'ub',
    'Ġbook',
    's',
    'ed',
    'om',
    'ĠS',
    'and',
    'A',
    'A',
    'ag',
    'o',
    'Ġre',
    'asons',
    'f',
    'ord',
    'Ġactiv',
    'ity',
    'U',
    'T',
    'N',
    'ow',
    'ĠSen',
    'ate',
    'ce',
    'll',
    'n',
    'ight',
    'Ġcall',
    's',
    'in',
    'ter',
    'Ġlet',
    'ter',
    'ĠR',
    'ob',
    'ĠJ',
    'e',
    'Ġcho',
    'ose',
    'ĠL',
    'aw',
    'G',
    'et',
    'B',
    'e',
    'Ġro',
    'b',
    'Ġtyp',
    'es',
    'Ġpl',
    'atform',
    'Ġqu',
    'arter',
    'R',
    'A',
    'ĠT',
    'ime',
    'Ġmay',
    'be',
    'ĠC',
    'r',
    '9',
    '5',
    'p',
    're',
    'Ġmov',
    'ing',
    'Ġl',
    'if',
    'Ġgo',
    'ld',
    'Ġs',
    'om',
    'Ġpat',
    'ients',
    'Ġtr',
    'uth',
    'ĠK',
    'e',
    'ur',
    'ance',
    'ant',
    'ly',
    'm',
    'ar',
    'Ġchar',
    'ge',
    'ĠG',
    'reat',
    'Ġce',
    'le',
    '----------------',
    '----------------',
    'Ġro',
    'ck',
    'ro',
    'id',
    'an',
    'cy',
    'Ġcred',
    'it',
    'a',
    'ud',
    'B',
    'y',
    'ĠE',
    'very',
    'Ġmov',
    'ed',
    'ing',
    'er',
    'rib',
    'ution',
    'Ġn',
    'ames',
    'Ġstra',
    'ight',
    'ĠHe',
    'alth',
    'ĠW',
    'ell',
    'Ġfe',
    'ature',
    'Ġr',
    'ule',
    'Ġsc',
    'he',
    'in',
    'ated',
    'ĠMich',
    'ael',
    'ber',
    'g',
    '4',
    '1',
    'il',
    'ed',
    'b',
    'and',
    'Ġcl',
    'ick',
    'ĠAng',
    'el',
    'on',
    'ents',
    'Â',
    'Ń',
    'ĠI',
    'raq',
    'ĠS',
    'aturday',
    'Ġa',
    'ware',
    'p',
    'art',
    'Ġpat',
    'tern',
    'O',
    'W',
    'ĠL',
    'et',
    'Ġgr',
    'ad',
    'ign',
    'ed',
    'Ġassoci',
    'ated',
    'Ġst',
    'yle',
    'n',
    'o',
    'i',
    'ation',
    'a',
    'ith',
    'il',
    'ies',
    'Ġst',
    'ories',
    'ur',
    'ation',
    'Ġindividual',
    's',
    'ĠâĢ',
    '¦',
    'm',
    'iss',
    'ĠAss',
    'oci',
    'ish',
    'ing',
    'ab',
    'y',
    'Ġsum',
    'mer',
    'ĠB',
    'en',
    'Ġ3',
    '2',
    'Ġar',
    'ch',
    'ut',
    'y',
    'ĠTex',
    'as',
    'h',
    'ol',
    'Ġfull',
    'y',
    'Ġm',
    'ill',
    'Ġfollow',
    'ed',
    'ĠB',
    'ill',
    'ĠInd',
    'ian',
    'ĠSec',
    'ret',
    'ĠB',
    'el',
    'ĠFeb',
    'ruary',
    'Ġjob',
    's',
    'Ġseem',
    'ed',
    'ĠGo',
    'vern',
    'i',
    'pped',
    'Ġreal',
    'ity',
    'Ġl',
    'ines',
    'Ġp',
    'ark',
    'Ġmeas',
    'ure',
    'ĠO',
    'ur',
    'I',
    'M',
    'Ġbro',
    'ther',
    'Ġgrow',
    'ing',
    'Ġb',
    'an',
    'Ġest',
    'im',
    'Ġc',
    'ry',
    'ĠS',
    'chool',
    'Ġme',
    'chan',
    'ĠO',
    'F',
    'ĠWind',
    'ows',
    'Ġr',
    'ates',
    'ĠO',
    'h',
    'Ġpos',
    'itive',
    'Ġcult',
    'ure',
    'ist',
    'ics',
    'ic',
    'a',
    'Ġh',
    'ar',
    'y',
    'a',
    'ite',
    'ly',
    'i',
    'pp',
    'Ġm',
    'ap',
    'en',
    'cies',
    'ĠWill',
    'iam',
    'I',
    'I',
    'ak',
    'ers',
    '5',
    '6',
    'ĠM',
    'art',
    'ĠR',
    'em',
    'Ġal',
    'tern',
    'it',
    'ude',
    'Ġco',
    'ach',
    'row',
    'd',
    'D',
    'on',
    'Ġk',
    'ids',
    'Ġj',
    'ournal',
    'Ġcor',
    'por',
    'Ġf',
    'alse',
    'Ġwe',
    'b',
    'Ġsle',
    'ep',
    'Ġcont',
    'ain',
    'Ġst',
    'o',
    'Ġb',
    'ed',
    'iver',
    'se',
    'ĠR',
    'ich',
    'ĠCh',
    'inese',
    'Ġp',
    'un',
    'Ġme',
    'ant',
    'k',
    'nown',
    'Ġnot',
    'ice',
    'Ġfavor',
    'ite',
    'a',
    'ven',
    'Ġcond',
    'ition',
    'Ġpur',
    'pose',
    ')',
    ')',
    'Ġorgan',
    'ization',
    'Ġchall',
    'eng',
    'Ġman',
    'ufact',
    'Ġsus',
    'p',
    'ĠA',
    'c',
    'Ġcrit',
    'ic',
    'un',
    'es',
    'uc',
    'lear',
    'Ġm',
    'er',
    'vent',
    'ion',
    'Ġ8',
    '0',
    'Ġm',
    'ist',
    'ĠU',
    's',
    'ĠT',
    'or',
    'htt',
    'p',
    'ol',
    'f',
    'Ġlarg',
    'er',
    'Ġadv',
    'ant',
    'Ġrese',
    'ar',
    'Ġact',
    'ions',
    'm',
    'l',
    'Ġke',
    'pt',
    'Ġa',
    'im',
    ',',
    "'",
    'c',
    'ol',
    'Ġbenef',
    'its',
    'if',
    'ying',
    'Ġact',
    'ual',
    'ĠIntern',
    'ational',
    'Ġveh',
    'icle',
    'Ġch',
    'ief',
    'Ġeff',
    'orts',
    'ĠLe',
    'ague',
    'ĠM',
    'ost',
    'Ġwa',
    'it',
    'Ġad',
    'ult',
    'Ġover',
    'all',
    'Ġspe',
    'ech',
    'Ġhigh',
    'ly',
    'Ġfem',
    'ale',
    'Ġer',
    'ror',
    'Ġeffect',
    'ive',
    '5',
    '4',
    'Ġenc',
    'our',
    'w',
    'ell',
    'Ġfail',
    'ed',
    'Ġcons',
    'erv',
    'Ġprogram',
    's',
    'Ġt',
    'rou',
    'Ġa',
    'head',
    '5',
    '00',
    'vertis',
    'ement',
    'I',
    'P',
    'ĠF',
    'ound',
    'p',
    'ir',
    'Ġ',
    '%',
    'Ġcr',
    'ime',
    'and',
    'er',
    'Ġloc',
    'ation',
    'ĠI',
    'ran',
    'Ġbehav',
    'ior',
    'az',
    'ing',
    'Ġr',
    'are',
    'Ġem',
    'b',
    'Ġca',
    'used',
    'Ġsh',
    'ip',
    'Ġact',
    'ive',
    'Ġcont',
    'ribut',
    'Ġg',
    'reen',
    'Ġac',
    'qu',
    'Ġref',
    'lect',
    'ven',
    'ue',
    'Ġf',
    'irm',
    'Ġb',
    'irth',
    ']',
    '.',
    'Ġclear',
    'ly',
    'Ġem',
    'ot',
    'Ġag',
    'ency',
    'ri',
    'age',
    'Ġmem',
    'ory',
    '9',
    '8',
    'S',
    'A',
    'ĠSe',
    'e',
    'ac',
    'ing',
    'C',
    'C',
    'Ġbig',
    'gest',
    'Ġr',
    'ap',
    'Ġbas',
    'ic',
    'Ġb',
    'and',
    'e',
    'at',
    'Ġsus',
    'pect',
    'ĠM',
    'ac',
    'Ġ9',
    '0',
    'm',
    'ark',
    'ist',
    'an',
    'Ġsp',
    'read',
    'am',
    's',
    'k',
    'i',
    'as',
    'y',
    'ra',
    'v',
    'ĠR',
    'ober',
    'Ġdemon',
    'str',
    'r',
    'ated',
    'Ġabs',
    'olute',
    'Ġpl',
    'aces',
    'Ġim',
    'pl',
    'ibr',
    'ary',
    'Ġc',
    'ards',
    'Ġdest',
    'roy',
    'Ġv',
    'irt',
    've',
    're',
    'Ġapp',
    'eared',
    'y',
    'an',
    'p',
    'oint',
    'Ġbe',
    'g',
    'Ġtem',
    'per',
    's',
    'pe',
    'ant',
    'ed',
    'ear',
    's',
    'ĠD',
    'irect',
    'Ġl',
    'ength',
    'Ġbl',
    'og',
    'am',
    'b',
    'Ġint',
    'eg',
    'Ġres',
    'ources',
    'ac',
    'c',
    'if',
    'ul',
    'Ġsp',
    'ot',
    'Ġfor',
    'ced',
    'Ġthous',
    'ands',
    'ĠMin',
    'ister',
    'Ġqu',
    'al',
    'ĠF',
    'rench',
    'at',
    'ically',
    'Ġgener',
    'ally',
    'Ġdr',
    'ink',
    'Ġth',
    'us',
    'I',
    'L',
    'od',
    'es',
    'Ġappro',
    'pri',
    'ĠRe',
    'ad',
    'Ġwh',
    'om',
    'Ġey',
    'e',
    'Ġcol',
    'lege',
    'Ġ4',
    '5',
    'ire',
    'ction',
    'Ġens',
    'ure',
    'Ġapp',
    'arent',
    'id',
    'ers',
    'Ġrelig',
    'ious',
    'Ġmin',
    'or',
    'ol',
    'ic',
    'Ġt',
    'ro',
    'ĠWh',
    'y',
    'rib',
    'ute',
    'm',
    'et',
    'Ġprim',
    'ary',
    'Ġdevelop',
    'ed',
    'Ġpe',
    'ace',
    'Ġsk',
    'in',
    'st',
    'e',
    'av',
    'a',
    'Ġbl',
    'ue',
    'Ġfam',
    'ilies',
    'Ġ',
    'ir',
    'Ġapp',
    'ly',
    'Ġin',
    'form',
    'ĠSm',
    'ith',
    'C',
    'T',
    'i',
    'i',
    'Ġlim',
    'it',
    'Ġres',
    'ist',
    '........',
    '........',
    'um',
    'n',
    'Ġconf',
    'lic',
    'Ġtw',
    'e',
    'ud',
    'd',
    'ĠT',
    'om',
    'Ġl',
    'iter',
    'qu',
    'e',
    'b',
    'on',
    'Ġha',
    'ir',
    'Ġevent',
    'ually',
    'Ġp',
    'us',
    'Ġhelp',
    'ed',
    'Ġag',
    'g',
    'or',
    'ney',
    'ĠApp',
    'le',
    'Ġf',
    'it',
    'ĠS',
    'ur',
    'Ġpre',
    'm',
    'Ġs',
    'ales',
    'Ġsecond',
    's',
    'Ġstreng',
    'th',
    'Ġfeel',
    'ing',
    '¿',
    '½',
    'Ġt',
    'our',
    'Ġknow',
    's',
    'o',
    'om',
    'Ġex',
    'erc',
    'Ġsom',
    'ew',
    'ï',
    '¿½',
    '>',
    '>',
    'Ġsp',
    'okes',
    'Ġide',
    'as',
    'Ġreg',
    'ist',
    'so',
    'ft',
    'ĠD',
    'el',
    'ĠP',
    'C',
    'Ġpro',
    'pos',
    'Ġlaun',
    'ch',
    'Ġbott',
    'om',
    'T',
    'H',
    'ĠP',
    'lease',
    'v',
    'est',
    'it',
    'z',
    'ĠIn',
    'ter',
    'Ġsc',
    'ript',
    'Ġr',
    'at',
    'ar',
    'ning',
    'Ġ',
    'il',
    'ĠJ',
    'er',
    'ĠA',
    're',
    'Ġwh',
    'atever',
    'ok',
    'en',
    'ci',
    'ence',
    'Ġmod',
    'e',
    'Ġag',
    'ree',
    'Ġs',
    'ources',
    'Ġinit',
    'ial',
    'Ġrest',
    'rict',
    'Ġwond',
    'er',
    'us',
    'ion',
    '##',
    '##',
    'ĠS',
    'il',
    'vil',
    'le',
    'Ġb',
    'urn',
    't',
    'w',
    'as',
    'ion',
    'ĠÂ',
    '£',
    'Ġn',
    'or',
    'u',
    'ing',
    'Ġre',
    'ached',
    'Ġs',
    'un',
    'Ġc',
    'ateg',
    'ig',
    'ration',
    'Ġc',
    'ook',
    'Ġprom',
    'ot',
    'Ġm',
    'ale',
    'Ġcl',
    'imate',
    'Ġf',
    'ix',
    'Ġalleg',
    'ed',
    'U',
    'R',
    'all',
    'ed',
    'Ġim',
    'ages',
    'C',
    'ont',
    'ot',
    'a',
    'Ġschool',
    's',
    'i',
    'os',
    'Ġd',
    'rop',
    'Ġst',
    'ream',
    'ĠM',
    'o',
    'Ġprevious',
    'ly',
    'al',
    'ing',
    'Ġp',
    'et',
    'Ġdou',
    'ble',
    'Ġ(',
    '@',
    'ann',
    'el',
    'Ġdef',
    'ault',
    't',
    'ies',
    'Ġr',
    'ank',
    'ĠD',
    'ec',
    'ĠCoun',
    'cil',
    'Ġweap',
    'on',
    'Ġst',
    'ock',
    'Ġanal',
    'y',
    'ĠSt',
    'r',
    'Ġpict',
    'ure',
    'ĠPol',
    'ice',
    'f',
    'erence',
    'Ġcent',
    'ury',
    'Ġcitiz',
    'ens',
    'Ġon',
    'to',
    'Ġexp',
    'and',
    'Ġhe',
    'ro',
    'ĠS',
    'ol',
    'Ġw',
    'ild',
    'Ġupd',
    'ate',
    'Ġcustom',
    'ers',
    'r',
    'ont',
    'd',
    'ef',
    'Ġl',
    'ik',
    'Ġcrim',
    'inal',
    'ĠChrist',
    'ian',
    'S',
    'P',
    '7',
    '6',
    'Ġle',
    'aving',
    'Ġother',
    'wise',
    'ĠD',
    'ist',
    'Ġbas',
    'is',
    '5',
    '2',
    '5',
    '3',
    'ic',
    'ip',
    'ĠB',
    'er',
    'Ġrecomm',
    'end',
    'Ġfl',
    'oor',
    'Ġc',
    'rowd',
    'ol',
    'es',
    'Ġ7',
    '0',
    'Ġcent',
    'ral',
    'ĠE',
    'v',
    'Ġd',
    'ream',
    'Ġdown',
    'load',
    'Ġconf',
    'ir',
    'ĠTh',
    'om',
    'Ġwind',
    'ow',
    'Ġhapp',
    'ens',
    'Ġun',
    'it',
    'Ġt',
    'end',
    'Ġs',
    'pl',
    'Ġbec',
    'omes',
    'Ġfight',
    'ing',
    'Ġpred',
    'ict',
    'ĠP',
    'ress',
    'ĠP',
    'ower',
    'Ġhe',
    'avy',
    'ak',
    'ed',
    'Ġf',
    'an',
    'or',
    'ter',
    'ate',
    'gy',
    'B',
    'A',
    'iz',
    'es',
    'Ġsp',
    'end',
    'H',
    'ere',
    'Ġ200',
    '7',
    'Ġad',
    'op',
    'ĠH',
    'am',
    'Ġfoot',
    'ball',
    'ĠP',
    'ort',
    'od',
    'ay',
    '5',
    '1',
    'amp',
    'ions',
    'Ġtrans',
    'fer',
    'h',
    't',
    'Ġ3',
    '8',
    'ter',
    'm',
    'ac',
    'ity',
    'Ġb',
    'ur',
    ']',
    ',',
    'tern',
    'al',
    'r',
    'ig',
    'b',
    'ut',
    'Ġthere',
    'fore',
    'ĠB',
    'ecause',
    'res',
    'p',
    're',
    'y',
    'Ġm',
    'ission',
    'S',
    'ome',
    'Ġnot',
    'ed',
    'Ġass',
    'um',
    'Ġdise',
    'ase',
    'Ġed',
    'it',
    'Ġprog',
    'ress',
    'r',
    'd',
    'ĠB',
    'rown',
    'oc',
    'al',
    'Ġadd',
    'ing',
    'Ġra',
    'ised',
    'ĠAn',
    'y',
    'Ġt',
    'ick',
    'Ġsee',
    'ing',
    'ĠPe',
    'ople',
    'Ġagre',
    'ement',
    'Ġser',
    'ver',
    'Ġw',
    'at',
    'Ġdeb',
    'ate',
    'Ġsupp',
    'osed',
    'il',
    'ing',
    'Ġlarg',
    'est',
    'Ġsuccess',
    'ful',
    'ĠP',
    'ri',
    'ĠDemocr',
    'atic',
    'Ġj',
    'ump',
    'ĠSyri',
    'a',
    'Ġown',
    'ers',
    'Ġoff',
    'ers',
    'Ġshoot',
    'ing',
    'Ġeff',
    'ic',
    'se',
    'y',
    'Ġha',
    'ven',
    'ver',
    'se',
    'te',
    'red',
    'ĠL',
    'ight',
    'im',
    'al',
    'ĠB',
    'ig',
    'Ġdef',
    'end',
    'Ġbe',
    'at',
    'Ġrecord',
    's',
    '%',
    ')',
    'Ġsc',
    'en',
    'Ġemploy',
    'ees',
    'Ġdev',
    'ices',
    'he',
    'm',
    'Ġcom',
    'mer',
    'ĠM',
    'ex',
    'Ġbenef',
    'it',
    'ĠPro',
    'f',
    'Ġil',
    'leg',
    'Ġsur',
    'face',
    'ĠAl',
    'so',
    'Ġh',
    'arm',
    'ing',
    'ly',
    'w',
    'ide',
    'ĠA',
    'lex',
    'Ġsh',
    'ut',
    'ĠC',
    'ur',
    'Ġl',
    'ose',
    'p',
    'm',
    'Ġchall',
    'enge',
    'se',
    'mb',
    'Ġst',
    'ation',
    'Ġint',
    'elligence',
    'Ġacc',
    'ur',
    'ĠFl',
    'or',
    'Ġrequ',
    'ires',
    'ĠM',
    'al',
    'b',
    'um',
    'Ġh',
    'ospital',
    'Ġsp',
    'irit',
    'Ġoff',
    'ered',
    'Ġprodu',
    'ce',
    'ĠComm',
    'un',
    'Ġcreat',
    'ing',
    'Ġcr',
    'is',
    's',
    'pect',
    'Ġend',
    'ed',
    'Ġd',
    'aily',
    'Ġvot',
    'ers',
    'land',
    's',
    'i',
    'as',
    'i',
    'h',
    'on',
    'a',
    'Ġsm',
    'art',
    'ĠOff',
    'ice',
    'ĠL',
    'ord',
    'ri',
    'al',
    'ĠIntern',
    'et',
    'Ġcirc',
    'um',
    'Ġextreme',
    'ly',
    "'",
    '.',
    'Ġopin',
    'ion',
    'ĠM',
    'il',
    'Ġg',
    'ain',
    'B',
    'S',
    'ĠF',
    'in',
    'y',
    'p',
    'Ġuse',
    'ful',
    'Ġbud',
    'get',
    'Ġcom',
    'fort',
    'is',
    'f',
    'Ġback',
    'ground',
    'el',
    'ine',
    'Ġep',
    'isode',
    'Ġen',
    'emy',
    'Ġtri',
    'al',
    'Ġestab',
    'lish',
    'd',
    'ate',
    'ĠC',
    'ap',
    'Ġcontin',
    'ues',
    'Ġshow',
    'ing',
    'ĠUn',
    'ion',
    'w',
    'ith',
    'Ġpost',
    'ed',
    'ĠSy',
    'stem',
    'Ġe',
    'at',
    'ri',
    'an',
    'Ġr',
    'ise',
    'ĠGerman',
    'y',
    'il',
    's',
    'Ġsign',
    'ed',
    'Ġv',
    'ill',
    'Ġgr',
    'and',
    'm',
    'or',
    'ĠEng',
    'land',
    'Ġproject',
    's',
    'um',
    'ber',
    'Ġconf',
    'erence',
    'z',
    'a',
    'Ġrespons',
    'ible',
    'ĠAr',
    'ab',
    'Ġlearn',
    'ed',
    'âĢĶ',
    'âĢĶ',
    'i',
    'pping',
    'ĠGe',
    'orge',
    'O',
    'C',
    'Ġreturn',
    'ed',
    'ĠAustral',
    'ia',
    'Ġb',
    'rief',
    'Q',
    'u',
    'Ġbr',
    'and',
    'ill',
    'ing',
    'ab',
    'led',
    'Ġhig',
    'hest',
    'Ġtr',
    'ain',
    'ĠComm',
    'ission',
    'wh',
    'ile',
    'Ġn',
    'om',
    'cept',
    'ion',
    'Ġm',
    'ut',
    'ĠBl',
    'ue',
    'Ġinc',
    'ident',
    'v',
    'ant',
    '8',
    '6',
    'ĠI',
    'D',
    'Ġn',
    'uclear',
    '7',
    '4',
    'ĠL',
    'ike',
    'ĠR',
    'E',
    'ĠM',
    'icro',
    'l',
    'i',
    'm',
    'ail',
    'Ġcharg',
    'es',
    '8',
    '9',
    'Ġad',
    'just',
    'ad',
    'o',
    'Ġear',
    'th',
    'N',
    'A',
    'Ġpr',
    'ices',
    'P',
    'A',
    'Ġd',
    'raft',
    'Ġrun',
    's',
    'Ġcandid',
    'ate',
    'ens',
    'es',
    'Ġmanag',
    'ement',
    'ĠPh',
    'il',
    'ĠM',
    'iss',
    'Ġte',
    'ach',
    'g',
    'ram',
    'Ġunderstand',
    'ing',
    'a',
    'it',
    'ic',
    'ago',
    'A',
    'dd',
    'ĠE',
    'p',
    'sec',
    'ut',
    'Ġsepar',
    'ate',
    'Ġinst',
    'ance',
    'Ġe',
    'th',
    'Ġun',
    'less',
    '****',
    '****',
    'ĠF',
    'ore',
    'in',
    'ate',
    'Ġoper',
    'ations',
    'S',
    'p',
    'Ġf',
    'aith',
    'g',
    'ar',
    'ĠCh',
    'urch',
    'ron',
    'ic',
    'Ġconf',
    'ig',
    'os',
    'ure',
    'Ġactiv',
    'ities',
    'Ġtrad',
    'itional',
    'Ġ3',
    '6',
    'Ġd',
    'irection',
    'Ġmach',
    'ine',
    'Ġsur',
    'round',
    'Ġp',
    'ush',
    'un',
    'ction',
    'ĠE',
    'U',
    'Ġeas',
    'ier',
    'Ġarg',
    'ument',
    'G',
    'B',
    'Ġm',
    'icro',
    'Ġsp',
    'ending',
    'iz',
    'ations',
    'Ġthe',
    'ory',
    'ad',
    'ow',
    'Ġcall',
    'ing',
    'ĠL',
    'ast',
    'Ġd',
    'er',
    'Ġinflu',
    'ence',
    'Ġcomm',
    'it',
    'Ġph',
    'oto',
    'Ġun',
    'c',
    'ist',
    'ry',
    'g',
    'n',
    'ast',
    'e',
    'ack',
    's',
    'Ġdis',
    'p',
    'ad',
    'y',
    'd',
    'o',
    'ĠG',
    'ood',
    'Ġ',
    '`',
    'Ġw',
    'ish',
    'Ġreve',
    'aled',
    'Âł',
    'Âł',
    'l',
    'ig',
    'Ġen',
    'force',
    'ĠComm',
    'ittee',
    'Ġche',
    'm',
    'Ġmil',
    'es',
    'Ġinterest',
    'ed',
    'Ġsol',
    'ution',
    'ic',
    'y',
    'in',
    'ct',
    'Ġ-',
    '>',
    'ĠD',
    'et',
    'Ġrem',
    'oved',
    'Ġcomp',
    'ar',
    'e',
    'ah',
    'Ġpl',
    'ant',
    'ĠS',
    'ince',
    'Ġachie',
    've',
    'Ġadvant',
    'age',
    'Ġslight',
    'ly',
    'b',
    'ing',
    'Ġpl',
    'aced',
    'u',
    'nder',
    '201',
    '5',
    'ĠM',
    'ad',
    'Ġt',
    'im',
    'os',
    'es',
    'Ġc',
    'ru',
    'ĠR',
    'ock',
    'Ġmost',
    'ly',
    'Ġneg',
    'ative',
    'Ġset',
    'ting',
    'Ġprodu',
    'ced',
    'Ġm',
    'ur',
    'Ġconnect',
    'ion',
    'ĠM',
    'er',
    'Ġdri',
    'ver',
    'Ġexecut',
    'ive',
    'Ġass',
    'ault',
    'Ġb',
    'orn',
    'ĠV',
    'er',
    't',
    'ained',
    'Ġstruct',
    'ure',
    'Ġredu',
    'ce',
    'Ġdec',
    'ades',
    'Ġd',
    'ed',
    'u',
    'ke',
    'ĠM',
    'any',
    'idd',
    'en',
    'Ġle',
    'ague',
    'S',
    'e',
    'Ġjo',
    'in',
    'Ġdis',
    'co',
    'Ġd',
    'ie',
    'c',
    'ks',
    'act',
    'ions',
    'Ġass',
    'ess',
    'ag',
    'n',
    'Ġgo',
    'als',
    'our',
    's',
    'I',
    'R',
    'Ġsen',
    'ior',
    'ill',
    'er',
    'm',
    'od',
    'ip',
    'ment',
    'oc',
    'ol',
    'u',
    'y',
    'ĠQ',
    'ue',
    'Ġpart',
    'ies',
    'ir',
    'gin',
    'Ġle',
    'arning',
    'it',
    'able',
    'Ġstre',
    'et',
    'Ġcamer',
    'a',
    'A',
    'pp',
    'Ġsk',
    'ills',
    'b',
    're',
    'c',
    'ious',
    'Ġcele',
    'br',
    'ĠFr',
    'anc',
    'Ġexist',
    'ing',
    'Ġwill',
    'ing',
    'l',
    'or',
    'Ġ',
    'id',
    'ĠSp',
    'ace',
    'Ġcrit',
    'ical',
    'ĠL',
    'a',
    'ortun',
    'ately',
    'Ġser',
    've',
    'Ġc',
    'old',
    'Ġspec',
    'ies',
    'T',
    'S',
    'Ġanim',
    'als',
    'ĠB',
    'ay',
    'Ġold',
    'er',
    'ĠU',
    'nder',
    'est',
    'ic',
    'ĠT',
    're',
    'Ġte',
    'acher',
    'Ġpre',
    'fer',
    'v',
    'is',
    'Ġth',
    'read',
    'ĠM',
    'att',
    'Ġmanag',
    'er',
    'ãĥ',
    '»',
    'Ġprofess',
    'ional',
    'ĠV',
    'ol',
    'Ġnot',
    'es',
    'The',
    'se',
    'ul',
    'a',
    'Ġf',
    'resh',
    'ent',
    'ed',
    'u',
    'zz',
    'ed',
    'y',
    'clus',
    'ion',
    'ĠR',
    'el',
    'Ġdoub',
    't',
    'E',
    'O',
    'Ġopen',
    'ed',
    'ĠB',
    'it',
    'Ad',
    'vertisement',
    'Ġgu',
    'ess',
    'ĠU',
    'N',
    'Ġse',
    'qu',
    'Ġexpl',
    'ain',
    'ott',
    'en',
    'Ġatt',
    'ract',
    'ak',
    's',
    'Ġstr',
    'ing',
    'Ġcont',
    'ext',
    'oss',
    'ible',
    'ĠRepublic',
    'ans',
    'Ġsol',
    'id',
    'Ġc',
    'ities',
    'Ġask',
    'ing',
    'Ġr',
    'andom',
    'u',
    'ps',
    'ur',
    'ies',
    'ar',
    'ant',
    'dd',
    'en',
    'g',
    'l',
    'ĠFlor',
    'ida',
    'Ġdep',
    'end',
    'ĠSc',
    'ott',
    'Ġ3',
    '3',
    'Ġi',
    'T',
    'ic',
    'on',
    'Ġmention',
    'ed',
    'Ġ2',
    '000',
    'Ġclaim',
    'ed',
    'Ġdefin',
    'itely',
    'ul',
    'f',
    'Ġc',
    'ore',
    'Ġopen',
    'ing',
    'ĠCon',
    'st',
    'wh',
    'ich',
    'ĠT',
    'ra',
    'A',
    'G',
    '7',
    '2',
    'Ġbelie',
    'ved',
    'ad',
    'a',
    'Ġ4',
    '8',
    'ĠSec',
    'urity',
    'yr',
    'ight',
    'ĠP',
    'et',
    'ĠL',
    'ou',
    'Ġhold',
    'ing',
    '========',
    '========',
    'Ġ',
    'ice',
    'Ġb',
    'row',
    'Ġauthor',
    'ities',
    'h',
    'ost',
    'w',
    'ord',
    'Ġsc',
    'ore',
    'ĠD',
    'iv',
    'Ġcell',
    's',
    'Ġtrans',
    'l',
    'Ġneigh',
    'bor',
    'Ġrem',
    'ove',
    'u',
    'ct',
    'Ġdist',
    'rict',
    'ĠA',
    'ccording',
    'Ġwor',
    'se',
    'Ġconcern',
    's',
    'Ġpresident',
    'ial',
    'Ġpolic',
    'ies',
    'ĠH',
    'all',
    '7',
    '3',
    'Ġh',
    'us',
    'A',
    'Y',
    'Ġ200',
    '6',
    'ĠJ',
    'ud',
    'Ġindepend',
    'ent',
    'ĠJust',
    'ice',
    'ili',
    'ar',
    'pr',
    'int',
    'igh',
    'ter',
    'Ġprotect',
    'ion',
    'z',
    'en',
    'Ġsu',
    'dden',
    'h',
    'ouse',
    'ĠJ',
    'es',
    'P',
    'R',
    'ĠIn',
    'f',
    'Ġb',
    'ul',
    'Ġ',
    '_',
    'ĠServ',
    'ice',
    'ĠP',
    'R',
    'Ġstr',
    'ategy',
    'ff',
    'ect',
    'Ġgirl',
    's',
    'Ġmiss',
    'ing',
    'oy',
    'al',
    'ĠTe',
    'am',
    'ul',
    'ated',
    'Ġd',
    'at',
    'Ġpolit',
    'ics',
    'ab',
    'or',
    'A',
    'ccording',
    'Ġspe',
    'll',
    'Ġg',
    'raph',
    'ort',
    'hern',
    'T',
    'C',
    'A',
    'b',
    'Ġlab',
    'or',
    'is',
    'her',
    'Ġk',
    'ick',
    'ĠiT',
    'unes',
    'Ġstep',
    's',
    'pos',
    'es',
    'Ġsmall',
    'er',
    'E',
    'n',
    'ber',
    't',
    'Ġro',
    'll',
    'Ġresear',
    'chers',
    'Ġcl',
    'osed',
    'Ġtrans',
    'port',
    'Ġlaw',
    'y',
    '________',
    '________',
    'ĠCh',
    'icago',
    'Ġas',
    'pect',
    'Ġn',
    'one',
    'Ġmar',
    'riage',
    '9',
    '6',
    'Ġe',
    'lements',
    'ĠF',
    're',
    'ĠS',
    'al',
    'Ġd',
    'ram',
    'F',
    'C',
    't',
    'op',
    'e',
    'qu',
    'Ġhe',
    'aring',
    'Ġsupport',
    'ed',
    'Ġtest',
    'ing',
    'co',
    'hol',
    'Ġmass',
    'ive',
    'Ġst',
    'ick',
    'Ġgu',
    'ard',
    'is',
    'co',
    'ph',
    'one',
    'F',
    'rom',
    'How',
    'ever',
    'Ġb',
    'order',
    'Ġcop',
    'y',
    'ograph',
    'y',
    'l',
    'ist',
    '7',
    '1',
    'Ġown',
    'er',
    'cl',
    'ass',
    'ru',
    'it',
    'r',
    'ate',
    'ĠO',
    'nce',
    'Ġdig',
    'ital',
    'Ġt',
    'ask',
    'ER',
    'S',
    'Ġinc',
    'red',
    't',
    'es',
    '+',
    '+',
    'ĠFr',
    'ance',
    'Ġb',
    'reat',
    'ow',
    'l',
    'Ġiss',
    'ued',
    'ĠW',
    'estern',
    'Ġdet',
    'ect',
    'Ġpart',
    'ners',
    'Ġsh',
    'ared',
    'ĠC',
    'all',
    'Ġcan',
    'cer',
    'ac',
    'he',
    'rib',
    'e',
    'Ġexpl',
    'ained',
    'Ġhe',
    'at',
    '{',
    '"',
    'Ġinvest',
    'ment',
    'ĠB',
    'ook',
    'Ġw',
    'ood',
    'Ġtool',
    's',
    'ĠAl',
    'though',
    'Ġbelie',
    'f',
    'Ġcris',
    'is',
    'Ġg',
    'e',
    'ĠM',
    'P',
    'Ġoper',
    'ation',
    'ty',
    'pe',
    '~',
    '~',
    'g',
    'a',
    'Ġcont',
    'ains',
    'ant',
    'a',
    'Ġexp',
    'ress',
    'ĠG',
    'roup',
    'ĠJ',
    'ournal',
    'k',
    'a',
    'Ġam',
    'b',
    'ĠUS',
    'A',
    'Ġfind',
    'ing',
    'Ġfund',
    'ing',
    'h',
    'ow',
    'Ġestab',
    'lished',
    'ide',
    'os',
    'Ġdeg',
    'ree',
    'Ġdanger',
    'ous',
    'ang',
    'ing',
    'Ġfre',
    'edom',
    'pp',
    'ort',
    'out',
    'hern',
    'Ġch',
    'urch',
    'Ġc',
    'atch',
    'ĠTw',
    'o',
    'Ġpres',
    'ence',
    'ĠGu',
    'ard',
    'U',
    'p',
    'Ġauthor',
    'ity',
    'ĠPro',
    'ject',
    'Ġbut',
    'ton',
    'Ġcon',
    'sequ',
    'Ġval',
    'id',
    'Ġwe',
    'ak',
    'Ġstart',
    's',
    'Ġref',
    'erence',
    'ĠM',
    'em',
    '"',
    ')',
    'U',
    'N',
    'or',
    'age',
    'ĠO',
    'pen',
    'Ġcol',
    'lection',
    'y',
    'm',
    'g',
    'ency',
    'Ġbeaut',
    'iful',
    'ro',
    's',
    'Ġtell',
    's',
    'Ġwa',
    'iting',
    'n',
    'el',
    'Ġprov',
    'iding',
    'ĠDemocr',
    'ats',
    'Ġd',
    'aughter',
    'Ġm',
    'aster',
    'Ġpur',
    'poses',
    'ĠJapan',
    'ese',
    'Ġequ',
    'al',
    'Ġturn',
    's',
    'Ġdoc',
    'uments',
    'Ġwatch',
    'ing',
    'R',
    'es',
    'Ġr',
    'an',
    '201',
    '4',
    'Ġre',
    'ject',
    'ĠKore',
    'a',
    'Ġvictim',
    's',
    'Le',
    'vel',
    'ere',
    'nces',
    'Ġw',
    'itness',
    'Ġ3',
    '4',
    'Ġre',
    'form',
    'com',
    'ing',
    'Ġocc',
    'up',
    'Ġc',
    'aught',
    'Ġtra',
    'ffic',
    'ad',
    'ing',
    'Ġmod',
    'els',
    'ar',
    'io',
    'Ġserv',
    'ed',
    'Ġb',
    'atter',
    'u',
    'ate',
    'ĠSecret',
    'ary',
    'Ġagre',
    'ed',
    'Ġtr',
    'uly',
    'yn',
    'am',
    'ĠR',
    'et',
    'Ġun',
    'its',
    'ĠRes',
    'earch',
    'h',
    'and',
    'az',
    'ine',
    'ĠM',
    'ike',
    'Ġvar',
    'iety',
    'ot',
    'al',
    'Ġam',
    'azing',
    'Ġconfir',
    'med',
    'Ġentire',
    'ly',
    'Ġpurch',
    'ase',
    'Ġe',
    'lement',
    'Ġc',
    'ash',
    'Ġdeter',
    'mine',
    'D',
    'e',
    'Ġc',
    'ars',
    'ĠW',
    'all',
    'â',
    'ĸ',
    'Ġview',
    's',
    'Ġdrug',
    's',
    'Ġdep',
    'artment',
    'ĠSt',
    'ep',
    'u',
    'it',
    'Ġ3',
    '9',
    'as',
    'ure',
    'ĠCl',
    'ass',
    'Ġc',
    'overed',
    'ĠB',
    'ank',
    'Ġme',
    're',
    'u',
    'ana',
    'Ġmult',
    'i',
    'Ġm',
    'ix',
    'Ġun',
    'like',
    'lev',
    'ision',
    'Ġsto',
    'pped',
    'Ġs',
    'em',
    'ĠG',
    'al',
    'ul',
    'es',
    'Ġwe',
    'l',
    'ĠJohn',
    'son',
    'l',
    'a',
    'Ġsk',
    'ill',
    'Ġbec',
    'oming',
    'ri',
    'e',
    'Ġappropri',
    'ate',
    'f',
    'e',
    'ell',
    'ow',
    'ĠPro',
    't',
    'ul',
    'ate',
    'oc',
    'ation',
    'Ġweek',
    'end',
    'od',
    'ies',
    'Ġsit',
    'es',
    'Ġanim',
    'al',
    'ĠT',
    'im',
    'Ġsc',
    'ale',
    'Ġcharg',
    'ed',
    'Ġinst',
    'ruct',
    'ill',
    'a',
    'Ġmethod',
    's',
    'Ġc',
    'ert',
    'Ġjud',
    'ge',
    'ĠH',
    'el',
    'Ġdoll',
    'ars',
    'Ġstand',
    'ing',
    'ĠS',
    'qu',
    'Ġdeb',
    't',
    'l',
    'iam',
    'Ġdri',
    'ving',
    'ĠS',
    'um',
    'ĠEd',
    'ition',
    'Ġal',
    'bum',
    'and',
    'on',
    'I',
    'F',
    'ĠU',
    'k',
    '6',
    '3',
    'ad',
    'er',
    'Ġcommer',
    'cial',
    'es',
    'h',
    'ĠGovern',
    'ment',
    'Ġdisc',
    'overed',
    'Ġout',
    'put',
    'ĠHill',
    'ary',
    'ĠCar',
    'ol',
    'Ġ200',
    '5',
    'Ġab',
    'use',
    'anc',
    'ing',
    'Ġsw',
    'itch',
    'Ġann',
    'ual',
    'T',
    'w',
    'Ġst',
    'ated',
    'ag',
    'ement',
    'in',
    'ner',
    'Ġdem',
    'ocr',
    'Ġres',
    'idents',
    'Ġallow',
    'ing',
    'Ġfact',
    'ors',
    'od',
    'd',
    'Ġf',
    'uck',
    'em',
    'ies',
    'Ġoccur',
    'red',
    'ot',
    'i',
    'Ġn',
    'orth',
    'ĠP',
    'ublic',
    'Ġinj',
    'ury',
    'Ġins',
    'urance',
    'C',
    'L',
    'oll',
    'y',
    'ã',
    'Ģ',
    'Ġrepe',
    'ated',
    'Ġar',
    'ms',
    'ang',
    'ed',
    'Ġconst',
    'ruction',
    'Ġf',
    'le',
    'P',
    'U',
    'ic',
    'ians',
    'Ġfor',
    'ms',
    'ĠMc',
    'C',
    'ant',
    'ic',
    'Ġm',
    'ental',
    'p',
    'ire',
    'Ġequ',
    'ipment',
    'Ġf',
    'ant',
    'Ġdiscuss',
    'ion',
    'Ġregard',
    'ing',
    'k',
    'in',
    'ar',
    'p',
    'Ġch',
    'air',
    'og',
    'ue',
    'Ġpro',
    'ceed',
    'ĠI',
    'd',
    'O',
    'ur',
    'Ġmur',
    'der',
    'M',
    'an',
    'Ġ4',
    '9',
    'as',
    'p',
    'Ġsupp',
    'ly',
    'Ġin',
    'put',
    'Ġwe',
    'alth',
    'liam',
    'ent',
    'Ġpro',
    'ced',
    'or',
    'ial',
    'ĠSt',
    'at',
    'ĠN',
    'FL',
    'hen',
    's',
    'ĠInst',
    'itute',
    'Ġput',
    'ting',
    'ourn',
    'ament',
    'et',
    'ic',
    'Ġloc',
    'ated',
    'Ġk',
    'id',
    'er',
    'ia',
    'r',
    'un',
    'Ġpr',
    'inc',
    'Ġ',
    '!',
    'go',
    'ing',
    'ĠB',
    'et',
    'Ġcl',
    'ot',
    'Ġtell',
    'ing',
    'Ġprop',
    'osed',
    'i',
    'ot',
    'or',
    'ry',
    'Ġfund',
    's',
    'g',
    'ment',
    'ĠL',
    'ife',
    'Ġb',
    'aby',
    'ĠB',
    'ack',
    'Ġsp',
    'oke',
    'Im',
    'age',
    'Ġear',
    'n',
    'ĠA',
    'T',
    'g',
    'u',
    'Ġex',
    'change',
    'ĠL',
    'in',
    'ov',
    'ing',
    'Ġp',
    'air',
    'M',
    'ore',
    'az',
    'on',
    'Ġarrest',
    'ed',
    'Ġkill',
    'ing',
    'c',
    'an',
    'ĠC',
    'ard',
    'y',
    'd',
    'Ġident',
    'ified',
    'Ġm',
    'obile',
    'Ġthan',
    'ks',
    'ony',
    'm',
    'ĠF',
    'orm',
    'Ġhundred',
    's',
    'ĠCh',
    'ris',
    'ĠC',
    'at',
    'Ġtre',
    'nd',
    'h',
    'at',
    'ĠA',
    'v',
    'om',
    'an',
    'Ġelect',
    'ric',
    'ĠW',
    'il',
    'S',
    'E',
    'O',
    'f',
    'Ġrest',
    'aur',
    'ot',
    'ed',
    'Ġtr',
    'ig',
    'Ġn',
    'ine',
    'Ġb',
    'omb',
    'Wh',
    'y',
    'Â',
    '¯',
    'Ġco',
    'verage',
    'Ġapp',
    'eal',
    'ĠRober',
    't',
    'ĠS',
    'up',
    'Ġfin',
    'ished',
    'Ġfl',
    'ow',
    'Ġdel',
    'iver',
    'Ġcal',
    'cul',
    'Ġphot',
    'os',
    'Ġph',
    'il',
    'Ġpie',
    'ces',
    'Ġapp',
    're',
    'k',
    'es',
    'Ġr',
    'ough',
    'D',
    'o',
    'Ġpart',
    'ner',
    'Ġconcern',
    'ed',
    'Ġ3',
    '7',
    'ĠG',
    'en',
    'C',
    'ol',
    'ct',
    'ors',
    'Ġ=',
    '>',
    'st',
    'ate',
    'Ġsuggest',
    'ed',
    'ĠFor',
    'ce',
    'C',
    'E',
    'Ġher',
    'self',
    'ĠPl',
    'an',
    'w',
    'orks',
    'o',
    'oth',
    'ren',
    'cy',
    'Ġcor',
    'ner',
    'Ġhus',
    'band',
    'Ġintern',
    'et',
    'ĠA',
    'ut',
    'em',
    's',
    'os',
    'en',
    'ĠAt',
    'l',
    'g',
    'en',
    'Ġbal',
    'ance',
    '6',
    '2',
    'Ġsound',
    's',
    'te',
    'xt',
    'Ġar',
    'r',
    'ov',
    'es',
    'Ġmill',
    'ions',
    'Ġrad',
    'io',
    'Ġsat',
    'isf',
    'ĠD',
    'am',
    'M',
    'r',
    'G',
    'o',
    'S',
    'pe',
    'Ġcomb',
    'at',
    'r',
    'ant',
    'ĠG',
    'ree',
    'Ġf',
    'uel',
    'Ġdist',
    'ance',
    'Ġtest',
    's',
    'Ġdec',
    're',
    'ĠE',
    'r',
    'Ġman',
    'aged',
    'D',
    'S',
    'Ġt',
    'it',
    'Ġmeas',
    'ures',
    'ĠL',
    'iber',
    'Ġatt',
    'end',
    'as',
    'hed',
    'ĠJ',
    'ose',
    'ĠN',
    'ight',
    'd',
    'it',
    'ĠN',
    'ov',
    'ĠE',
    'nd',
    'out',
    's',
    'Ġgener',
    'ation',
    'Ġadv',
    'oc',
    'y',
    'th',
    'Ġconvers',
    'ation',
    'ĠS',
    'ky',
    'act',
    'ive',
    'ce',
    'l',
    'ri',
    'er',
    'ĠFr',
    'ank',
    'Ġg',
    'ender',
    'Ġcon',
    'cent',
    'Ġcar',
    'ried',
    'and',
    'a',
    'ĠV',
    'irgin',
    'Ġarri',
    'ved',
    'ic',
    'ide',
    'ad',
    'ed',
    'Ġfail',
    'ure',
    'Ġmin',
    'imum',
    'le',
    'ts',
    'Ġwor',
    'st',
    'Ġkeep',
    'ing',
    'Ġint',
    'ended',
    'Ġilleg',
    'al',
    'Ġsub',
    'sc',
    'Ġdetermin',
    'ed',
    'Ġtri',
    'p',
    'Y',
    'es',
    'Ġra',
    'ise',
    'Ġ',
    '~',
    'Ġfeel',
    's',
    'Ġpack',
    'age',
    'ĠJ',
    'o',
    'h',
    'i',
    '201',
    '6',
    're',
    'al',
    'Ġf',
    'ra',
    'Ġsy',
    'mb',
    'M',
    'e',
    'uck',
    'y',
    'p',
    'ret',
    'ĠK',
    'h',
    'ĠEd',
    'it',
    'ĠWe',
    'b',
    'em',
    'ic',
    'ĠCol',
    'or',
    'Ġjust',
    'ice',
    'I',
    'nt',
    'Ġfar',
    'm',
    'ck',
    'now',
    '"',
    '>',
    'el',
    'ess',
    'Ġredu',
    'ced',
    'Ġ5',
    '00',
    'x',
    'x',
    'ĠR',
    'ad',
    'ĠW',
    'ood',
    'Ġcl',
    'in',
    'Ġhy',
    'p',
    'il',
    'er',
    'ur',
    'a',
    'k',
    'ins',
    '8',
    '5',
    '6',
    '1',
    'ĠThe',
    'ir',
    'ĠM',
    'ary',
    'Ġs',
    'an',
    'Ġno',
    'vel',
    'ĠWh',
    'o',
    'Ġcap',
    'acity',
    'Ġimp',
    'ossible',
    'Ġpl',
    'ays',
    'Ġmin',
    'ister',
    'ij',
    'uana',
    'ic',
    'ate',
    'ĠS',
    'et',
    'Ġf',
    'ram',
    'Ġ',
    'ing',
    'Ġcommun',
    'ities',
    'ĠF',
    'BI',
    'it',
    'a',
    'Ġb',
    'on',
    'Ġstr',
    'ateg',
    'Ġinterest',
    's',
    'l',
    'ock',
    'g',
    'ers',
    'm',
    'as',
    'ĠAN',
    'D',
    'Ġconflic',
    't',
    'Ġrequire',
    'ments',
    'Ġs',
    'ac',
    'Ġoper',
    'ating',
    'in',
    'i',
    'rel',
    'ated',
    'Ġcomm',
    'itted',
    'Ġrelative',
    'ly',
    'Ġs',
    'outh',
    'Â¯',
    'Â¯',
    'Ġaff',
    'ord',
    'Ġident',
    'ity',
    'Ġdec',
    'isions',
    'Ġacc',
    'used',
    'pl',
    'ace',
    'Ġvict',
    'ory',
    'o',
    'ch',
    'i',
    'at',
    'N',
    'ame',
    'C',
    'om',
    't',
    'ion',
    'ed',
    's',
    'Ġsee',
    'k',
    'Ġt',
    'ight',
    'ĠIm',
    'ages',
    'Ġinit',
    'i',
    'Ġhum',
    'ans',
    'Ġfam',
    'iliar',
    'Ġaud',
    'ience',
    'Ġintern',
    'al',
    'vent',
    'ure',
    'Ġs',
    'ides',
    'ĠT',
    'O',
    'Ġd',
    'im',
    'Ġcon',
    'clud',
    'Ġapp',
    'oint',
    'Ġenforce',
    'ment',
    'ĠJ',
    'im',
    'ĠAssoci',
    'ation',
    'Ġcircum',
    'st',
    'ĠCanad',
    'ian',
    'Ġjo',
    'ined',
    'Ġdiffere',
    'nces',
    'ĠL',
    'os',
    'Ġprot',
    'est',
    'Ġtw',
    'ice',
    'w',
    'in',
    'Ġgl',
    'ass',
    'ars',
    'h',
    'ĠAr',
    'my',
    'Ġexp',
    'ression',
    'Ġdec',
    'ide',
    'Ġplan',
    'ning',
    'an',
    'ia',
    'Ġhand',
    'le',
    'ĠMicro',
    'soft',
    'ĠN',
    'or',
    'Ġmax',
    'imum',
    'ĠRe',
    'v',
    'Ġse',
    'a',
    'Ġev',
    'al',
    'Ġhel',
    'ps',
    're',
    'f',
    'Ġb',
    'ound',
    'Ġm',
    'outh',
    'Ġstand',
    'ards',
    'Ġcl',
    'im',
    'ĠC',
    'amp',
    'ĠF',
    'ox',
    'cl',
    'es',
    'Ġar',
    'my',
    'ĠTe',
    'chn',
    'ack',
    'ing',
    'x',
    'y',
    'S',
    'S',
    'Ġ4',
    '2',
    'Ġbu',
    'g',
    'ĠUk',
    'rain',
    'ĠM',
    'ax',
    'ĠJ',
    'ones',
    'ĠSh',
    'ow',
    'l',
    'o',
    'Ġplan',
    'et',
    'Ġ7',
    '5',
    'Ġwin',
    'ning',
    'Ġf',
    'aster',
    'Ġspe',
    'ct',
    'Ġbro',
    'ken',
    'T',
    'R',
    'Ġdef',
    'ined',
    'Ġhealth',
    'y',
    'Ġcompet',
    'ition',
    'htt',
    'ps',
    'ĠIs',
    'land',
    'ĠF',
    'e',
    'Ġannoun',
    'ce',
    'ĠC',
    'up',
    'ĠInst',
    'ead',
    'Ġcl',
    'ient',
    'Ġposs',
    'ibly',
    'se',
    'ction',
    'ock',
    'et',
    'l',
    'ook',
    'Ġfin',
    'ish',
    'Ġcre',
    'w',
    'Ġres',
    'erv',
    'Ġed',
    'itor',
    'Ġh',
    'ate',
    'Ġs',
    'ale',
    'Ġcontro',
    'vers',
    'Ġp',
    'ages',
    'w',
    'ing',
    'Ġnum',
    'er',
    'Ġopp',
    'osition',
    'Ġ200',
    '4',
    'Ġref',
    'uge',
    'Ġfl',
    'ight',
    'Ġap',
    'art',
    'ĠL',
    'at',
    'A',
    'meric',
    'ĠAfric',
    'a',
    'Ġapplic',
    'ations',
    'ĠPal',
    'est',
    'ĠB',
    'ur',
    'Ġg',
    'ar',
    'ĠSoc',
    'ial',
    'Ġup',
    'gr',
    'Ġsh',
    'ape',
    'Ġspe',
    'aking',
    'ans',
    'ion',
    'a',
    'o',
    'ĠS',
    'n',
    'Ġwor',
    'ry',
    'ĠBrit',
    'ain',
    'P',
    'lease',
    'rou',
    'd',
    'Ġh',
    'un',
    'Ġintrodu',
    'ced',
    'Ġd',
    'iet',
    'I',
    'nd',
    'ĠSec',
    'ond',
    'Ġfun',
    'ctions',
    'ut',
    's',
    'ĠE',
    'ach',
    'ĠJe',
    'ff',
    'Ġst',
    'ress',
    'Ġaccount',
    's',
    'Ġgu',
    'arant',
    'ĠAn',
    'n',
    'ed',
    'ia',
    'Ġhon',
    'est',
    'Ġt',
    'ree',
    'ĠAfric',
    'an',
    'ĠB',
    'ush',
    '}',
    ',',
    'Ġs',
    'ch',
    'ĠOn',
    'ly',
    'Ġf',
    'if',
    'ig',
    'an',
    'Ġexerc',
    'ise',
    'ĠEx',
    'p',
    'Ġscient',
    'ists',
    'Ġlegisl',
    'ation',
    'ĠW',
    'ork',
    'ĠS',
    'pr',
    'Ã',
    'Ĥ',
    'ĠH',
    'uman',
    'Ġ',
    'è',
    'Ġsur',
    'vey',
    'Ġr',
    'ich',
    'ri',
    'p',
    'Ġmain',
    'tain',
    'Ġfl',
    'o',
    'Ġleaders',
    'hip',
    'st',
    'ream',
    'ĠIslam',
    'ic',
    'Ġ',
    '01',
    'ĠCol',
    'lege',
    'Ġmag',
    'ic',
    'ĠPr',
    'ime',
    'Ġfig',
    'ures',
    '201',
    '7',
    'ind',
    'er',
    'x',
    'ual',
    'ĠDe',
    'ad',
    'Ġabsolute',
    'ly',
    'Ġfour',
    'th',
    'Ġpresent',
    'ed',
    'resp',
    'ond',
    'rib',
    'le',
    'Ġal',
    'cohol',
    'at',
    'o',
    'ĠD',
    'E',
    'por',
    'ary',
    'Ġgr',
    'ab',
    'Ġvar',
    'i',
    'Ġqu',
    'ant',
    'ĠPh',
    'oto',
    'Ġpl',
    'us',
    'r',
    'ick',
    'ar',
    'ks',
    'Ġaltern',
    'ative',
    'Ġp',
    'il',
    'Ġappro',
    'x',
    'th',
    'at',
    'Ġobject',
    's',
    'ĠR',
    'o',
    'ĠAnd',
    'roid',
    'Ġsignificant',
    'ly',
    'ĠR',
    'oad',
    'k',
    'ay',
    'R',
    'ead',
    'av',
    'or',
    'Ġa',
    'cknow',
    'ĠH',
    'D',
    'ĠS',
    'ing',
    'O',
    'r',
    'ĠM',
    'ont',
    'Ġun',
    's',
    'pro',
    'f',
    'Ġneg',
    'oti',
    'ĠAr',
    'ch',
    'ik',
    'i',
    'Ġte',
    'levision',
    'ĠJew',
    'ish',
    'Ġcomm',
    'ittee',
    'Ġmot',
    'or',
    'Ġappear',
    'ance',
    'Ġs',
    'itting',
    'Ġstri',
    'ke',
    'ĠD',
    'own',
    'com',
    'p',
    'ĠH',
    'ist',
    'Ġf',
    'old',
    'ac',
    'ement',
    'ĠLou',
    'is',
    'Ġbel',
    'ong',
    'ĠâĢ',
    '¢',
    'Ġm',
    'ort',
    'Ġprep',
    'ared',
    'Ġ6',
    '4',
    'ĠM',
    'aster',
    'Ġind',
    'eed',
    'ĠD',
    'en',
    'Ġre',
    'nt',
    'T',
    'A',
    'our',
    'ney',
    'ar',
    'c',
    'S',
    'u',
    '9',
    '7',
    'Ġadv',
    'ice',
    'Ġchang',
    'ing',
    'Ġlist',
    'ed',
    'Ġlaun',
    'ched',
    'is',
    'ation',
    'ĠP',
    'eter',
    'is',
    'hes',
    'Ġl',
    'ived',
    'ĠM',
    'el',
    'ĠSup',
    'reme',
    'ĠF',
    'ederal',
    'Ġ)',
    ';',
    'ruct',
    'ure',
    'Ġset',
    's',
    'Ġphil',
    'os',
    'u',
    'ous',
    'ĠÂ',
    'ł',
    'Ġappl',
    'ied',
    'ĠN',
    'OT',
    'Ġhous',
    'ing',
    'ĠM',
    'ount',
    'Ġo',
    'dd',
    'Ġsu',
    'st',
    'D',
    'A',
    'ffic',
    'ient',
    'Ġ',
    '?',
    'ol',
    'ved',
    'Ġp',
    'owers',
    'Ġth',
    'r',
    'Ġrem',
    'aining',
    'ĠW',
    'ater',
    'L',
    'C',
    'Ġca',
    'uses',
    'ãģ',
    '®',
    'Ġman',
    'ner',
    'ad',
    's',
    'Ġsuggest',
    's',
    'Ġend',
    's',
    'stand',
    'ing',
    'f',
    'ig',
    'ĠD',
    'un',
    'id',
    'th',
    'Ġg',
    'ay',
    'Ġter',
    'min',
    'ĠAngel',
    'es',
    'M',
    'S',
    'Ġscient',
    'ific',
    'Ġco',
    'al',
    'ap',
    'ers',
    'b',
    'ar',
    'ĠThom',
    'as',
    'Ġsy',
    'm',
    'ĠR',
    'un',
    'th',
    'is',
    'P',
    'C',
    'igr',
    'ants',
    'Ġmin',
    'ute',
    'ĠDist',
    'rict',
    'cell',
    'ent',
    'Ġle',
    'aves',
    'Ġcomple',
    'ted',
    'am',
    'in',
    'Ġfoc',
    'used',
    'Ġmon',
    'itor',
    'Ġveh',
    'icles',
    'M',
    'A',
    'ĠM',
    'ass',
    'ĠGr',
    'and',
    'Ġaffect',
    'ed',
    'itution',
    'al',
    'Ġconst',
    'ruct',
    'Ġfollow',
    's',
    'Ġt',
    'on',
    're',
    'ens',
    'Ġh',
    'omes',
    'ĠE',
    'xt',
    'ĠLe',
    'vel',
    'r',
    'ast',
    'ĠI',
    'r',
    'Ġel',
    'im',
    'Ġlarge',
    'ly',
    'ĠJ',
    'oe',
    'Ġvot',
    'es',
    'all',
    's',
    'Ġbusiness',
    'es',
    'ĠFound',
    'ation',
    'ĠCent',
    'ral',
    'Ġy',
    'ards',
    'Ġmaterial',
    's',
    'ul',
    'ner',
    'Ġgu',
    'ide',
    'Ġclos',
    'er',
    'um',
    's',
    'Ġsp',
    'orts',
    'ed',
    'er',
    'J',
    'ust',
    'Ġtax',
    'es',
    '8',
    '4',
    'ĠO',
    'ld',
    'Ġdec',
    'ade',
    'ol',
    'a',
    'Ġv',
    'ir',
    'Ġdro',
    'pped',
    'Ġdel',
    'ay',
    'it',
    'ect',
    'Ġsec',
    'ure',
    'ste',
    'in',
    'le',
    'vel',
    'Ġtre',
    'ated',
    'Ġfil',
    'ed',
    'ain',
    'e',
    'Ġv',
    'an',
    'Ġm',
    'ir',
    'Ġcol',
    'umn',
    'ict',
    'ed',
    'e',
    'per',
    'Ġro',
    't',
    'Ġcons',
    'ult',
    'Ġent',
    'ry',
    'Ġmar',
    'ijuana',
    'ĠD',
    'ou',
    'Ġapparent',
    'ly',
    'ok',
    'ing',
    'clus',
    'ive',
    'Ġincre',
    'ases',
    'an',
    'o',
    'Ġspecific',
    'ally',
    'Ġte',
    'le',
    'ens',
    'ions',
    'Ġrelig',
    'ion',
    'ab',
    'ilities',
    'Ġfr',
    'ame',
    'ĠN',
    'ote',
    'ĠLe',
    'e',
    'Ġhelp',
    'ing',
    'Ġed',
    'ge',
    'ost',
    'on',
    'Ġorgan',
    'izations',
    'Ã',
    'ĥ',
    'ĠB',
    'oth',
    'hip',
    's',
    'Ġbig',
    'ger',
    'Ġbo',
    'ost',
    'ĠSt',
    'and',
    'Ġro',
    'w',
    'ul',
    's',
    'ab',
    'ase',
    'Ġr',
    'id',
    'L',
    'et',
    'are',
    'n',
    'ra',
    've',
    'Ġst',
    'ret',
    'P',
    'D',
    'Ġv',
    'ision',
    'Ġwe',
    'aring',
    'Ġappre',
    'ci',
    'Ġa',
    'ward',
    'ĠU',
    'se',
    'Ġfact',
    'or',
    'w',
    'ar',
    'ul',
    'ations',
    ')',
    '(',
    'Ġg',
    'od',
    'Ġter',
    'rit',
    'Ġpar',
    'am',
    'ast',
    's',
    '8',
    '7',
    'Ġen',
    'emies',
    'ĠG',
    'ames',
    'F',
    'F',
    'Ġacc',
    'ident',
    'W',
    'ell',
    'ĠMart',
    'in',
    'T',
    'ER',
    'Ġat',
    'h',
    'ĠHe',
    'll',
    'Ġfor',
    'g',
    'Ġve',
    'ter',
    'ĠMed',
    'ic',
    'f',
    'ree',
    'Ġst',
    'ars',
    'Ġexp',
    'ensive',
    'Ġac',
    'ad',
    'ra',
    'wn',
    'ĠW',
    'he',
    'Ġl',
    'ock',
    'Ġform',
    'at',
    'Ġsold',
    'iers',
    's',
    'm',
    'Ġag',
    'ent',
    'Ġrespons',
    'ibility',
    'or',
    'a',
    'ĠS',
    'cience',
    'Ġrap',
    'id',
    'Ġt',
    'ough',
    'ĠJes',
    'us',
    'Ġbelie',
    'ves',
    'M',
    'L',
    'Ġwe',
    'ar',
    'le',
    'te',
    'Ãĥ',
    'ÃĤ',
    'ĠD',
    'ri',
    'Ġcomm',
    'ission',
    'ĠB',
    'ob',
    'O',
    'h',
    'ap',
    'ed',
    'Ġwar',
    'm',
    'ÃĥÃĤ',
    'ÃĥÃĤ',
    'Ġ200',
    '3',
    'ort',
    'ion',
    'Ġhas',
    'n',
    'ust',
    'er',
    'Ġun',
    'ivers',
    'ĠI',
    'll',
    'Ġk',
    'ing',
    'olog',
    'ies',
    '9',
    '4',
    'ĠT',
    'em',
    'ĠM',
    'os',
    'Ġpat',
    'ient',
    'ĠMex',
    'ico',
    'ce',
    'an',
    'ĠDe',
    'ath',
    'ĠSand',
    'ers',
    'y',
    'ou',
    'ĠC',
    'ast',
    'ĠComp',
    'any',
    'pt',
    'y',
    'Ġhappen',
    'ing',
    'F',
    'P',
    'ĠB',
    'attle',
    'Ġb',
    'ought',
    'A',
    'm',
    'M',
    'od',
    'U',
    's',
    'ut',
    'ers',
    'ĠC',
    're',
    'ĠTh',
    'ose',
    'Ġ4',
    '4',
    'is',
    'er',
    'Ġs',
    'oul',
    'ĠT',
    'op',
    'ĠHar',
    'ry',
    'ĠA',
    'w',
    'Ġse',
    'at',
    'ff',
    'ee',
    'Ġrev',
    'olution',
    'Ġ(',
    '"',
    'ĠD',
    'uring',
    'et',
    'te',
    'Ġr',
    'ing',
    'Ġoff',
    'ensive',
    'Ġreturn',
    's',
    'Ġv',
    'ideos',
    'Ġdis',
    'cl',
    'Ġfam',
    'ous',
    'en',
    'ced',
    'ĠS',
    'ign',
    'ĠR',
    'iver',
    'Ġ3',
    '00',
    'P',
    'M',
    'ĠB',
    'us',
    'ĠC',
    'H',
    'Ġcandid',
    'ates',
    'ard',
    'en',
    'Ġpercent',
    'age',
    'Ġvis',
    'ual',
    'Ġthan',
    'k',
    'Ġtrou',
    'ble',
    'ner',
    'gy',
    'Ġ200',
    '1',
    'Ġpro',
    've',
    'ash',
    'ion',
    'Ġen',
    'h',
    'ĠL',
    'ong',
    'U',
    'M',
    'Ġconnect',
    'ed',
    'Ġposs',
    'ibility',
    'O',
    'ver',
    'Ġexper',
    't',
    'Ġl',
    'ibrary',
    'art',
    's',
    'ĠDirect',
    'or',
    'Ġfell',
    'ow',
    '9',
    '2',
    'ir',
    'ty',
    'Ġd',
    'ry',
    'Ġsign',
    's',
    'ĠL',
    'ove',
    'Ġqu',
    'iet',
    'f',
    'oot',
    'Ġp',
    'ure',
    'ĠH',
    'un',
    'Ġf',
    'illed',
    'ph',
    'as',
    'ĠE',
    'lect',
    'end',
    'ment',
    'ĠEx',
    'pl',
    'Ġun',
    'able',
    'n',
    's',
    'm',
    'o',
    'Ġv',
    'ast',
    'ob',
    'e',
    'Ġident',
    'ify',
    'app',
    'ing',
    'ĠCarol',
    'ina',
    'g',
    'ress',
    'Ġpro',
    'te',
    'Ġf',
    'ish',
    'Ġcircumst',
    'ances',
    'raz',
    'y',
    'ĠPh',
    'ot',
    'Ġb',
    'odies',
    'ĠM',
    'ur',
    'Ġdevelop',
    'ing',
    'ĠA',
    'R',
    'Ġexperien',
    'ced',
    'Ġsubst',
    'ant',
    'ĠBo',
    'ard',
    'es',
    'ome',
    'Ġdom',
    'estic',
    'Ġcomb',
    'ined',
    'ĠP',
    'ut',
    'Ġchem',
    'ical',
    'ĠCh',
    'ild',
    'Ġpo',
    'ol',
    'ĠC',
    'y',
    'Ġe',
    'gg',
    'c',
    'ons',
    'st',
    'ers',
    'Ġh',
    'urt',
    'Ġmark',
    'ets',
    'Ġconserv',
    'ative',
    'Ġsupp',
    'orters',
    'Ġag',
    'encies',
    'id',
    'el',
    'O',
    'b',
    'ur',
    'b',
    'Ġ4',
    '3',
    'ĠDef',
    'ense',
    'y',
    'e',
    'ĠA',
    'p',
    'du',
    'le',
    'Ġtemper',
    'ature',
    'Ġconduct',
    'ed',
    'ĠCh',
    'ief',
    'Ġpull',
    'ed',
    'Ġf',
    'ol',
    'L',
    'ast',
    'ont',
    'o',
    'os',
    'is',
    'V',
    'ER',
    'D',
    'es',
    'ĠP',
    'an',
    'F',
    'irst',
    'Ġadv',
    'ance',
    'Ġlic',
    'ense',
    'r',
    'ors',
    'ĠJ',
    'on',
    'Ġimag',
    'ine',
    'Ġhe',
    'll',
    'Ġf',
    'ixed',
    'Ġinc',
    'or',
    'os',
    'ite',
    'ĠL',
    'og',
    'ick',
    'en',
    ']',
    ':',
    'Ġsurpr',
    'ise',
    'h',
    'ab',
    'Ġc',
    'raft',
    'ol',
    't',
    'ĠJ',
    'ul',
    'Ġd',
    'ial',
    'Ġrele',
    'vant',
    'Ġent',
    'ered',
    'Ġlead',
    's',
    'ĠA',
    'D',
    'ĠCle',
    'an',
    'Ġpict',
    'ures',
    'ess',
    'or',
    'Ġal',
    't',
    'Ġpay',
    'ing',
    'P',
    'er',
    'ĠMark',
    'et',
    'Ġupd',
    'ates',
    'am',
    'ily',
    'ĠT',
    'ype',
    'ĠH',
    'ome',
    'Ġ5',
    '5',
    'semb',
    'ly',
    'rom',
    'e',
    '8',
    '3',
    'Ġgreat',
    'est',
    'Ġhe',
    'ight',
    'Ġhe',
    'av',
    'ain',
    'ts',
    'Ġlist',
    'en',
    'as',
    'er',
    'ĠS',
    'H',
    'Ġcap',
    'able',
    'ac',
    'le',
    'Ġpers',
    'pect',
    'in',
    'ating',
    'Ġoff',
    'ering',
    'ry',
    'pt',
    'ĠDe',
    'velop',
    'ab',
    'in',
    'r',
    'c',
    'Ġbr',
    'ight',
    'al',
    'ty',
    'ar',
    'row',
    'Ġsupp',
    'l',
    'ind',
    'ing',
    'ack',
    'ed',
    'gy',
    'pt',
    'ĠAn',
    'other',
    'p',
    'g',
    'ĠVirgin',
    'ia',
    'ĠL',
    'u',
    'Ġpl',
    'anned',
    'Ġp',
    'it',
    'Ġswe',
    'et',
    'T',
    'ype',
    'ĠD',
    'i',
    'Ġtyp',
    'ically',
    'ĠFranc',
    'isco',
    'Ġpro',
    'spect',
    'ĠD',
    'an',
    'Ġte',
    'en',
    're',
    'es',
    'Ġsc',
    'hed',
    'Ġh',
    'ol',
    'Ġsc',
    'r',
    'Ġlot',
    's',
    'l',
    'ife',
    'Ġnews',
    'p',
    'Ġfor',
    'get',
    'ĠN',
    'one',
    'ĠM',
    'iddle',
    'ĠR',
    'yan',
    'ed',
    'd',
    'Ġse',
    'vere',
    'Ġsu',
    'it',
    'll',
    'er',
    '9',
    '3',
    'Ġcor',
    'respond',
    'Ġexpl',
    'os',
    'u',
    'ations',
    'Ġfl',
    'ag',
    'g',
    'ame',
    'r',
    'id',
    'Ġpr',
    'in',
    'ĠD',
    'ata',
    'Ġde',
    'ploy',
    'ĠEn',
    'ter',
    'su',
    'it',
    'gh',
    'an',
    'ĠM',
    'en',
    'Ġthough',
    'ts',
    'Ġmat',
    'ters',
    'Ġad',
    'apt',
    'ĠA',
    'ri',
    'Ġf',
    'ill',
    'Ġfor',
    'th',
    'Ġs',
    'am',
    'Ġ4',
    '1',
    'Ġpay',
    'ment',
    'ĠH',
    'or',
    'Ġsp',
    'ring',
    'du',
    'c',
    'Ġl',
    'osing',
    'Ġbring',
    'ing',
    'F',
    'O',
    'al',
    'a',
    'Ġdist',
    'ribution',
    'he',
    'red',
    'b',
    'our',
    'ĠIsrael',
    'i',
    'om',
    'a',
    'Ġcomb',
    'ination',
    'Ġpl',
    'enty',
    'V',
    'E',
    'C',
    'an',
    'ĠH',
    'aw',
    'Ġper',
    'man',
    'ĠSpe',
    'cial',
    'Ġto',
    'w',
    'Ġsee',
    'king',
    'Ġexam',
    'ples',
    'Ġclass',
    'es',
    'c',
    'r',
    'Ġbe',
    'er',
    'Ġmov',
    'es',
    'ĠI',
    'P',
    'ĠK',
    'n',
    'Ġpan',
    'el',
    'E',
    'ven',
    'Ġproper',
    'ly',
    'Ġr',
    'is',
    'Ġpl',
    'ug',
    'Ġestim',
    'ated',
    'E',
    'very',
    'Ġdef',
    'ensive',
    'ag',
    'raph',
    'Ġpre',
    'gn',
    'Ġinst',
    'it',
    'ĠV',
    'ict',
    'Ġvol',
    'ume',
    'Ġpos',
    'itions',
    'Ġl',
    'inks',
    'ĠPro',
    'gram',
    'ĠWe',
    'ek',
    'ag',
    'ues',
    'Ġtrans',
    'form',
    'k',
    'er',
    'ĠC',
    'EO',
    'Ġc',
    'as',
    'Ġopp',
    'onent',
    'Ġtwe',
    'et',
    'ĠC',
    'ode',
    'Ġsh',
    'op',
    'Ġf',
    'ly',
    'Ġtal',
    'ks',
    'Ġb',
    'ag',
    'Ph',
    'one',
    'Ġa',
    'id',
    'Ġpl',
    'ants',
    'Ġ6',
    '5',
    'Ġatt',
    'orney',
    'ar',
    'ters',
    'qu',
    'est',
    'ĠMag',
    'ic',
    'Ġbeg',
    'ins',
    'Ġmy',
    'ster',
    'Ġenvironment',
    'al',
    'Ġst',
    'orage',
    'N',
    'N',
    'Ġm',
    'arg',
    'Ġs',
    'ke',
    'Ġmet',
    'al',
    'ell',
    'y',
    'Ġord',
    'ered',
    'Ġrem',
    'ained',
    'Ġl',
    'oved',
    'Ġprom',
    'pt',
    'Ġupd',
    'ated',
    'Ġexper',
    'ts',
    'Ġwalk',
    'ing',
    'Ġan',
    'cient',
    'Ġperform',
    'ed',
    'AT',
    'E',
    'Ġne',
    'ither',
    'i',
    'ency',
    'Ġmanufact',
    'ure',
    'ĠP',
    'ak',
    'Ġselect',
    'ed',
    'Ġm',
    'ine',
    'Ġult',
    'imately',
    'Ġexpl',
    'an',
    'Ġlab',
    'el',
    'ĠServ',
    'ices',
    'ribut',
    'ed',
    'Tr',
    'ump',
    'Ġsy',
    'n',
    'ĠU',
    'lt',
    'S',
    'C',
    'Ġme',
    'at',
    'Ġg',
    'iant',
    'ĠW',
    'ars',
    'ĠO',
    'N',
    'Ġad',
    'm',
    'Ġinter',
    'pret',
    'Ġeven',
    'ing',
    'Ġev',
    'il',
    'ĠB',
    'oston',
    'ĠW',
    'ild',
    'Ġ',
    'Ã',
    'ĠBit',
    'coin',
    'ĠAm',
    'azon',
    'D',
    'r',
    'ĠIn',
    'formation',
    'Ġobvious',
    'ly',
    'Ġadv',
    'anced',
    'Ph',
    'oto',
    'ol',
    'ar',
    'Ġwe',
    'ather',
    'Ġsymb',
    'ol',
    'Ġso',
    'le',
    'Ġpot',
    'entially',
    'ost',
    'er',
    'Ġorig',
    'inally',
    'm',
    'un',
    '3',
    '00',
    'az',
    'e',
    'ess',
    'ions',
    'Ġde',
    'ck',
    'Ġst',
    'ood',
    'Ġyou',
    'th',
    'ĠB',
    'ern',
    'R',
    'ep',
    'ĠT',
    'est',
    'Ġbas',
    'ically',
    'ot',
    'ic',
    'Ġinvol',
    've',
    'ol',
    'it',
    'ly',
    'n',
    'S',
    'ee',
    'Ġair',
    'craft',
    'Ġconf',
    'irm',
    'E',
    'W',
    'Ġmess',
    'ages',
    'ĠRich',
    'ard',
    'Ġk',
    'it',
    'Ġpro',
    'hib',
    'Ġv',
    'ulner',
    'is',
    'ters',
    'Ġexist',
    'ence',
    'Ġturn',
    'ing',
    'ĠS',
    'P',
    'Ġdes',
    'ire',
    'Ġfl',
    'at',
    'Ġm',
    'ent',
    'se',
    'ason',
    'ang',
    'es',
    'Ġneighbor',
    'hood',
    'ĠL',
    'ake',
    'AT',
    'ION',
    'Ġpoint',
    'ed',
    'b',
    'ur',
    'Ġinn',
    'ov',
    'uc',
    'ks',
    'U',
    'L',
    'Ġprofess',
    'or',
    'Ġexp',
    'ressed',
    'A',
    'B',
    'ic',
    'ious',
    'Ġ200',
    '2',
    'ĠDe',
    'v',
    'Ġs',
    'ession',
    'Ġb',
    'are',
    's',
    'en',
    'Ġdis',
    's',
    'ĠC',
    'ath',
    'ĠP',
    'ass',
    'ĠP',
    'oint',
    'Ġdo',
    'ctor',
    'or',
    'row',
    'ail',
    'ed',
    'ĠR',
    'ub',
    'ĠD',
    'C',
    'ĠChar',
    'l',
    'p',
    'erson',
    'Ġwrit',
    'er',
    'igh',
    'ters',
    'ure',
    'au',
    'Ġob',
    'lig',
    'Ġrecord',
    'ed',
    'Ġbro',
    'ke',
    'Ġord',
    'ers',
    'il',
    'ty',
    'Ġmot',
    'ion',
    'in',
    'ity',
    'l',
    'aw',
    'ad',
    'ium',
    'Ġimm',
    'igration',
    'Ġcontr',
    'ast',
    'Ġb',
    'att',
    'Ġex',
    'cellent',
    'Ġtechn',
    'ical',
    'am',
    'i',
    'Ġt',
    'un',
    'Ġcl',
    'oud',
    'ĠY',
    'ear',
    'ge',
    'on',
    'Ġcre',
    'ation',
    'Ġstr',
    'ange',
    'Ġa',
    'uth',
    'Ġfor',
    't',
    'b',
    'orn',
    'Ġext',
    'ent',
    'ĠT',
    'oday',
    'ĠCl',
    'ub',
    'Ġr',
    'ain',
    'Ġs',
    'ample',
    'Ġaccept',
    'ed',
    'Ġt',
    'act',
    'Ġf',
    'ired',
    'ĠS',
    'on',
    'Ġstand',
    's',
    'Ġb',
    'oot',
    'Ġ4',
    '7',
    'Ġstat',
    'ements',
    'Ġvers',
    'ions',
    'Ġse',
    'lling',
    'ound',
    'ed',
    'Ġ199',
    '0',
    'Ġwere',
    'n',
    'ĠW',
    'atch',
    'Ġexper',
    'iment',
    'P',
    'ost',
    'Ġret',
    'ail',
    'ul',
    'ed',
    'In',
    'st',
    'un',
    'te',
    'ãĥ',
    '¼',
    'Ġdep',
    'art',
    'Ġb',
    'ond',
    'i',
    'very',
    'om',
    'pl',
    'Ġre',
    'action',
    'ĠSyri',
    'an',
    'ĠP',
    'ac',
    'app',
    'ed',
    'ani',
    'el',
    'D',
    'P',
    'Ġres',
    'olution',
    'Ġre',
    'act',
    'Ġappro',
    'ved',
    'on',
    'om',
    'm',
    'ond',
    'ĠO',
    'ffic',
    '--',
    '-',
    'Ġrepl',
    'ace',
    'Ġt',
    'ack',
    'Ġsp',
    'ort',
    'Ġch',
    'ain',
    'Ġemer',
    'gency',
    'r',
    'ad',
    'ĠPalest',
    'in',
    'Ġ4',
    '6',
    'Ġautom',
    'atically',
    'Ġrout',
    'e',
    'Ġp',
    'al',
    'Ġb',
    'anks',
    'ĠPar',
    'is',
    'ĠMed',
    'ia',
    'ro',
    'ad',
    'ic',
    'ing',
    'i',
    'xt',
    'ist',
    'ed',
    'Ġg',
    'rew',
    'Ġco',
    'ord',
    'ĠW',
    'here',
    'om',
    'in',
    'Ġsub',
    's',
    'ï¿½',
    'ï¿½',
    'ĠÂ',
    '±',
    'Ġcorpor',
    'ate',
    'Ġse',
    'lection',
    'n',
    'oon',
    'ĠRep',
    'ort',
    'c',
    's',
    'clud',
    'ing',
    'ord',
    'ers',
    'anc',
    'he',
    'ĠIt',
    's',
    'Ġslow',
    'ly',
    'ĠE',
    'gypt',
    'ĠA',
    'cc',
    'Ġcol',
    'le',
    'iqu',
    'es',
    'E',
    'X',
    'Ġattempt',
    's',
    'ur',
    'l',
    'ĠC',
    'ross',
    'Ġfind',
    'ings',
    'ĠS',
    'C',
    'ĠO',
    'R',
    'Ġind',
    'ex',
    'ens',
    'ity',
    'ĠW',
    'ay',
    'ĠL',
    'and',
    'Ġsh',
    'ock',
    'd',
    'is',
    'Ġd',
    'ynam',
    'Ġc',
    'art',
    'm',
    'osp',
    'S',
    'ince',
    'i',
    'est',
    'ĠB',
    'oy',
    'Ġst',
    'orm',
    'ĠCont',
    'in',
    '201',
    '3',
    'he',
    'w',
    'il',
    'it',
    'Ġess',
    'ential',
    'iqu',
    'id',
    'O',
    'ther',
    'ive',
    'red',
    'Ġreason',
    'able',
    'A',
    'ct',
    'Ġsub',
    'sequ',
    'ĠP',
    'ack',
    'ĠF',
    'ort',
    'Ġconsider',
    'ing',
    'Ġun',
    'iversity',
    'l',
    'og',
    'Ġmar',
    'ried',
    'Ġill',
    'ust',
    'ĠTr',
    'ue',
    '£',
    'ı',
    'Ġnumer',
    'ous',
    'rast',
    'ructure',
    'Ġserious',
    'ly',
    'Ġrefer',
    'red',
    'u',
    'a',
    'Ġconsist',
    'ent',
    'on',
    'na',
    'ĠRe',
    'al',
    'ru',
    'ption',
    'ci',
    'ples',
    'Ġfact',
    's',
    '9',
    '1',
    'ot',
    'es',
    'er',
    'g',
    'The',
    'n',
    'Ġacc',
    'ompl',
    'N',
    'ote',
    'Ġre',
    'venue',
    'Ġpass',
    'ing',
    'Ġm',
    'al',
    'e',
    'en',
    'ĠY',
    'et',
    'Ġg',
    'ather',
    'ter',
    'day',
    'ew',
    'ork',
    'ĠA',
    'uthor',
    'P',
    'e',
    'Ġopt',
    'im',
    'Ġr',
    'ub',
    'Ġè',
    '£ı',
    'Ġun',
    'known',
    'st',
    'one',
    'Ġun',
    'ion',
    'ol',
    've',
    'Ġopportun',
    'ities',
    'Ġbrow',
    'ser',
    'ĠW',
    'al',
    'ĠC',
    'ost',
    'Ġreport',
    'ing',
    'st',
    's',
    'p',
    'et',
    'Ġs',
    'and',
    'Ġsudden',
    'ly',
    'Ġsurpr',
    'ising',
    'ĠV',
    'R',
    'Ġsomew',
    'hat',
    'ĠB',
    'as',
    'ult',
    'ure',
    'iz',
    'z',
    'ĠC',
    'D',
    'Ġchalleng',
    'es',
    'Ġsett',
    'ings',
    'Ġexperien',
    'ces',
    'ĠF',
    'ull',
    'Ġcan',
    'n',
    'Ġrece',
    'iving',
    'ES',
    'T',
    'Ġj',
    'oint',
    'Ġcult',
    'ural',
    'Ġa',
    'st',
    '8',
    '2',
    'as',
    'tern',
    'ce',
    'ived',
    'ĠC',
    'ru',
    'Ġb',
    'ull',
    'p',
    'ired',
    'am',
    'm',
    'Ġfac',
    'ing',
    'p',
    'ower',
    'Ġb',
    'oss',
    'ĠH',
    'ol',
    'Ġinst',
    'r',
    'Ġincreasing',
    'ly',
    'Ġsh',
    'ift',
    'Ġstre',
    'ets',
    'ĠWilliam',
    's',
    'ab',
    'b',
    'Ġl',
    'ie',
    'Ġl',
    'augh',
    'ĠC',
    'a',
    'P',
    'L',
    'Ġadult',
    's',
    'Ġcustom',
    'er',
    'Ġob',
    'tained',
    'Ġsupport',
    'ing',
    'ht',
    'ml',
    'f',
    'ire',
    'Ġdetail',
    'ed',
    'Ġpick',
    'ed',
    'ĠR',
    'ight',
    'ld',
    'er',
    'E',
    'E',
    'st',
    'ood',
    'ĠK',
    'im',
    'Ġw',
    'ire',
    'Ġs',
    'ight',
    'Ġdevelop',
    'ers',
    'Ġpers',
    'ons',
    'Ġs',
    'ad',
    'Ġc',
    'up',
    'Ġwar',
    'ning',
    'Ġboy',
    's',
    'l',
    'ong',
    'Ġb',
    'ird',
    'f',
    'o',
    'Ġw',
    'al',
    'Ġobserv',
    'ed',
    'Ġz',
    'one',
    'iven',
    'ess',
    'Ġch',
    'annel',
    'c',
    'ript',
    'Ġref',
    'used',
    'ĠAg',
    'ain',
    'Ġsu',
    'c',
    'Ġspokes',
    'man',
    'ĠRe',
    'f',
    'r',
    'ite',
    'ou',
    'ston',
    'ãĥ',
    '³',
    'ĠS',
    'her',
    'Ġact',
    's',
    'ĠN',
    'ame',
    'Ġstrugg',
    'le',
    'ar',
    'ry',
    'omet',
    'imes',
    'Ġdisc',
    'rim',
    'H',
    'T',
    'Ġcateg',
    'ory',
    'Ġreal',
    'ize',
    'Ġemploy',
    'ee',
    'ĠAf',
    'ghan',
    'en',
    'ger',
    'Ġgun',
    's',
    'ĠSte',
    've',
    'ĠM',
    'ot',
    'ĠO',
    'l',
    'ok',
    'ed',
    'Ġth',
    'ick',
    'Ġfair',
    'ly',
    'ill',
    'y',
    'Ġsur',
    've',
    'ĠM',
    'at',
    'we',
    'ight',
    'â',
    'Ķ',
    'Ġtro',
    'ops',
    'Ġag',
    'ents',
    'Ġbatter',
    'y',
    'Ġmot',
    'iv',
    'Ã',
    '¡',
    'S',
    'ec',
    'd',
    'en',
    'o',
    'very',
    'L',
    'S',
    'Ġfl',
    'u',
    'Ġconf',
    'ident',
    'ĠO',
    'per',
    'Ġem',
    'pty',
    'Ġp',
    'hen',
    'Ġse',
    'ctor',
    'Ġexc',
    'ited',
    'Ġrem',
    'ote',
    'ap',
    'h',
    'o',
    'en',
    'Ġdestroy',
    'ed',
    'Ġmor',
    'al',
    'ĠH',
    'P',
    'ĠR',
    'on',
    'Ġd',
    'ress',
    'ĠB',
    'at',
    'Ġl',
    'it',
    'ĠM',
    'S',
    'Ġa',
    'f',
    'H',
    'L',
    'r',
    'um',
    'is',
    'ms',
    'Ġshould',
    'n',
    'Ġsym',
    'pt',
    'ĠTor',
    'onto',
    'het',
    'ic',
    'Ġcar',
    'bon',
    'Ġinstall',
    'ed',
    'Ġviol',
    'ent',
    'Ġsol',
    'ar',
    'j',
    'a',
    'Ġpract',
    'ices',
    'Ġr',
    'ide',
    'ĠP',
    'enn',
    'Ġimpro',
    'ved',
    'Ġaud',
    'io',
    'Ġbehav',
    'i',
    'ĠP',
    'S',
    'Ġe',
    'ating',
    'D',
    'ata',
    'ĠRe',
    'view',
    'p',
    'ass',
    'cl',
    'aim',
    'u',
    'ated',
    'ang',
    'ers',
    'c',
    'hen',
    'Ġproper',
    'ties',
    'Ġany',
    'where',
    'An',
    'other',
    'Ġbl',
    'ow',
    'ĠJack',
    'son',
    'Ġp',
    'roud',
    'Ġplan',
    'e',
    'l',
    'ines',
    'Ġsqu',
    'are',
    'Ġpro',
    'of',
    'ans',
    'as',
    'Ġtalk',
    'ed',
    'm',
    'akers',
    'Ġs',
    'ister',
    'Ġhold',
    's',
    'Ġres',
    'ident',
    'Ġ=',
    '=',
    'Ġresist',
    'ance',
    'Ġspl',
    'it',
    'Ġpro',
    'secut',
    'Ġconf',
    'idence',
    'res',
    'ents',
    'Ġcut',
    's',
    'Ġexcept',
    'ion',
    'Ġz',
    'ero',
    'Get',
    'ty',
    'Ġcop',
    'yright',
    'Ġtot',
    'ally',
    'orm',
    'al',
    'ific',
    'ations',
    'ĠAustral',
    'ian',
    'Ġs',
    'ick',
    'Ġ1',
    '50',
    'Ġhouse',
    'hold',
    'Ġfe',
    'es',
    'Ġdri',
    'vers',
    'og',
    'en',
    'ĠN',
    'Y',
    'Ġnecess',
    'arily',
    'Ġregul',
    'ations',
    'ear',
    'ing',
    's',
    'l',
    'Ġperspect',
    'ive',
    'c',
    'are',
    'ic',
    'ial',
    'H',
    'is',
    'Ġesc',
    'ape',
    'Ġsurpr',
    'ised',
    'ĠV',
    'an',
    'ur',
    'rent',
    'Ġv',
    'ac',
    '8',
    '1',
    'ĠTh',
    'us',
    'Ġem',
    'phas',
    'ĠCh',
    'ampions',
    'ĠI',
    'ce',
    'Ġn',
    'arr',
    'Ġhead',
    's',
    'Ġca',
    'using',
    'b',
    'el',
    'f',
    'ortunately',
    'ĠM',
    'a',
    'Ġtarg',
    'ets',
    'ci',
    'pl',
    'Ġafter',
    'noon',
    'Ġadd',
    's',
    'ĠMay',
    'be',
    'ĠF',
    'our',
    'ess',
    'ed',
    'ple',
    'te',
    'Ġus',
    'ual',
    'ch',
    'o',
    'ing',
    'u',
    'Ġwith',
    'd',
    'ĠE',
    'nergy',
    'ĠE',
    'conom',
    'O',
    'O',
    'Ġart',
    'icles',
    'Ġinj',
    'ured',
    'Ġman',
    'age',
    'Ġexpl',
    'ains',
    'Ġdi',
    'agn',
    'R',
    'ec',
    'at',
    'ures',
    'Ġlink',
    'ed',
    'Ġdiscuss',
    'ed',
    'Ġexpl',
    'o',
    'Ġocc',
    'asion',
    'ath',
    'an',
    'Ġopp',
    'osite',
    'Ġfac',
    'es',
    'Ġden',
    'ied',
    'ĠK',
    'night',
    'Ġn',
    'ut',
    'Ġapprox',
    'imately',
    'Ġdisapp',
    'oint',
    'onym',
    'ous',
    'ĠB',
    'est',
    'ĠL',
    'o',
    'ĠH',
    'y',
    'ĠA',
    'ff',
    'Ġvot',
    'ing',
    'an',
    'while',
    'ĠII',
    'I',
    'Ġinstit',
    'utions',
    'ag',
    'ram',
    'ĠD',
    'aily',
    'Ġdr',
    'ag',
    'Ġnear',
    'by',
    'Ġgu',
    'ilty',
    'Ġcon',
    'ver',
    'P',
    're',
    's',
    'hip',
    'Ġre',
    'ward',
    'Ġphilos',
    'oph',
    'ĠS',
    'S',
    'u',
    'gh',
    'Ġapp',
    's',
    'f',
    'riend',
    'Ġu',
    'pper',
    'Ġad',
    'vert',
    'Ġs',
    'now',
    'Ġfr',
    'ust',
    'Ġour',
    'selves',
    'F',
    'r',
    'ĠD',
    'ie',
    'amp',
    'ion',
    'Ġdis',
    'miss',
    'Ġc',
    'ere',
    'Ġsign',
    'al',
    'f',
    'rom',
    'Ġ',
    ').',
    'Ġ5',
    '2',
    'Ġcr',
    'imes',
    'it',
    'ors',
    'est',
    'ival',
    'use',
    'um',
    'Ġcoun',
    'cil',
    'ĠS',
    'aud',
    'M',
    'ay',
    'ĠG',
    'un',
    'ic',
    'ian',
    'et',
    'her',
    'Ġsu',
    'fficient',
    'ĠH',
    'en',
    'so',
    'le',
    'Ġhistor',
    'ical',
    'ĠF',
    'ar',
    'ĠT',
    'urn',
    'Ġp',
    'in',
    'Ġsuc',
    'ceed',
    'm',
    'at',
    'ly',
    'mp',
    'Ġtrad',
    'ition',
    'ĠO',
    'k',
    'Ġc',
    'ro',
    'Ġdesc',
    'ription',
    'al',
    'le',
    'Ġsk',
    'y',
    'T',
    'e',
    'Ġwide',
    'ly',
    'Ġw',
    'ave',
    'Ġdefin',
    'ition',
    'ĠJew',
    's',
    'Ġcy',
    'cle',
    'Ġref',
    'ere',
    'Ġbr',
    'ings',
    'us',
    'al',
    'Ġal',
    'ive',
    'Ġfrequ',
    'ently',
    'Ġint',
    'ention',
    'ĠCont',
    'rol',
    'l',
    'v',
    'y',
    'stem',
    'Ġpriv',
    'acy',
    'g',
    'ent',
    'ren',
    'ce',
    'ĠQu',
    'est',
    'ĠChrist',
    'mas',
    'Ġr',
    'ail',
    'Ġco',
    'oper',
    'Ġtest',
    'ed',
    'ĠC',
    'apt',
    'as',
    'ks',
    'Ġcomfort',
    'able',
    'Ġdel',
    'ivered',
    'sc',
    'ape',
    'Ġdep',
    'th',
    'ĠG',
    'OP',
    'Ġwrit',
    'es',
    'Ġass',
    'ets',
    'Ġsa',
    'v',
    'im',
    'ents',
    'Ġtrans',
    'ition',
    'Ġart',
    'ist',
    'ĠL',
    'ook',
    'Ġl',
    'ob',
    'Ġcomp',
    'onents',
    'ar',
    'ity',
    'Ġwalk',
    'ed',
    'Ġro',
    'ot',
    'Ġparticip',
    'ants',
    'Ġnot',
    'iced',
    'Ġres',
    'c',
    'Ġn',
    'av',
    'ĠAd',
    'minist',
    'd',
    'a',
    'ut',
    'ral',
    'pl',
    'ate',
    'Ġimport',
    'ance',
    'Ġass',
    'ert',
    'ious',
    'ly',
    'c',
    'ription',
    'Ġinj',
    'uries',
    'ĠChe',
    'ck',
    'Ġregist',
    'ered',
    'Ġint',
    'ent',
    'Ġmiss',
    'ed',
    'ograph',
    'ic',
    'Ġsent',
    'ence',
    'oun',
    'ter',
    'Ġassist',
    'ance',
    'ev',
    'in',
    'Ġdat',
    'abase',
    'Ġbuild',
    'ings',
    'Ġclass',
    'ic',
    'Ġth',
    'inks',
    'ĠOh',
    'io',
    'P',
    'r',
    'ug',
    'g',
    'Ġfe',
    'e',
    'p',
    'an',
    'Ġeffect',
    'ively',
    'Ġfac',
    'ility',
    'Ġbe',
    'ar',
    'Ġch',
    'apter',
    'Ġdog',
    's',
    'ĠCol',
    'umb',
    'Ġl',
    'atter',
    'it',
    'ial',
    'Ġad',
    'mitted',
    'T',
    'V',
    'ĠGe',
    'org',
    'Ġpost',
    's',
    '\\',
    '\\',
    'Ġlawy',
    'er',
    'Ġequ',
    'ival',
    'Ġm',
    'and',
    'Ġcontro',
    'lled',
    'ĠW',
    'alk',
    'ĠAnd',
    'rew',
    'Ġmen',
    'u',
    'am',
    'ental',
    'Ġprotect',
    'ed',
    'v',
    'a',
    'Ġadminist',
    'r',
    'or',
    'al',
    'Ġre',
    'in',
    'ĠS',
    'ar',
    'Ġamount',
    's',
    'Ġn',
    'ative',
    'ĠM',
    'oon',
    'Ġrep',
    'resents',
    'Ġab',
    'andon',
    'Ġcarry',
    'ing',
    'Ġt',
    'ank',
    'm',
    'ary',
    'Ġdecl',
    'ared',
    'T',
    'ube',
    'Ġh',
    'at',
    'Ġpun',
    'ish',
    'el',
    'lect',
    'm',
    'es',
    'Ġun',
    'iverse',
    'ĠR',
    'od',
    'ph',
    'y',
    'Ġinf',
    'rastructure',
    'Ġ5',
    '1',
    'Ġopp',
    'osed',
    'ow',
    'nt',
    'c',
    'a',
    'ĠM',
    'ake',
    'Ġhard',
    'ware',
    'Ġco',
    'ffee',
    'R',
    'el',
    'b',
    'al',
    'w',
    'orld',
    'ĠS',
    'af',
    'ĠSe',
    'a',
    'in',
    'als',
    'Ġown',
    'ed',
    'Ġh',
    'all',
    'ers',
    'ion',
    'Ġdescrib',
    'e',
    'ĠP',
    'ot',
    'Ġport',
    'ion',
    'Ġat',
    'mosp',
    'Ġgovern',
    'ments',
    'Ġdep',
    'ending',
    'Ġoff',
    'ense',
    'Ġtr',
    'ick',
    'aw',
    'a',
    'ĠL',
    'ine',
    'ĠV',
    'is',
    'ĠH',
    'ard',
    'ĠOr',
    'ig',
    'ĠCl',
    'ick',
    'Ġdes',
    'k',
    'ĠVal',
    'ley',
    'ĠS',
    'ov',
    'Ġmov',
    'ies',
    'Ġrem',
    'ark',
    'Ġm',
    'ail',
    'Ġcons',
    'cious',
    'Ġrul',
    'ing',
    'ĠR',
    'ights',
    'Ġmed',
    'ic',
    'he',
    'nt',
    'ĠW',
    'omen',
    '>',
    '<',
    'Ġrepl',
    'aced',
    'ĠP',
    'rem',
    'ĠTh',
    'anks',
    'Ġre',
    'new',
    'ĠB',
    'all',
    'if',
    'orm',
    'Ġsh',
    'ots',
    'C',
    'omm',
    'Ġar',
    'med',
    'Ġconst',
    'ant',
    'Ġt',
    'aste',
    'Ġreal',
    'ized',
    'Ġbu',
    'ff',
    'Ġm',
    'o',
    'Ġeffic',
    'ient',
    'M',
    'ost',
    'or',
    'ation',
    'if',
    'ies',
    'Ġcommun',
    'ication',
    'Ġfl',
    'ood',
    'Ġconsequ',
    'ences',
    'Ġany',
    'way',
    'ig',
    'g',
    'ĠG',
    'M',
    'ĠTh',
    'ank',
    'Ġ',
    'iron',
    'Ġev',
    'olution',
    'ĠC',
    'op',
    'tw',
    'itter',
    'Ġ9',
    '5',
    'Ġrelationship',
    's',
    'ad',
    'el',
    'ĠYou',
    'ng',
    'Ġpropos',
    'al',
    'ay',
    'ers',
    'uild',
    'ing',
    'ĠH',
    'ot',
    'OR',
    'E',
    'c',
    'os',
    'Ġcoll',
    'abor',
    'P',
    'G',
    'ax',
    'y',
    'Ġknow',
    'ing',
    'Ġsupport',
    's',
    'ow',
    'ed',
    'Ġcontrol',
    's',
    'Ġmere',
    'ly',
    'um',
    'er',
    'Ġath',
    'let',
    'Ġf',
    'ashion',
    'p',
    'ath',
    'Ġg',
    'ift',
    'Ġer',
    'a',
    'AN',
    'D',
    'Ġkind',
    's',
    'ĠKore',
    'an',
    'Ġleg',
    'it',
    'ul',
    'ous',
    'Ġess',
    'entially',
    'Ġthe',
    'rap',
    'n',
    'ic',
    'Ġsuff',
    'ered',
    'Ġh',
    'ur',
    'Ġprom',
    'ise',
    'Ġex',
    'cess',
    'Ġover',
    'w',
    'Ġpr',
    'ime',
    'ĠH',
    'ouston',
    'er',
    'ry',
    'ĠM',
    's',
    'R',
    'S',
    '201',
    '2',
    'Ġst',
    'ores',
    'ĠO',
    'lymp',
    'Ġj',
    'ourney',
    'Al',
    'though',
    'S',
    'ub',
    'ĠE',
    'duc',
    'ĠCh',
    'apter',
    'Ġrequest',
    's',
    'Ġconsum',
    'ers',
    'Ġt',
    'iny',
    'Ġis',
    'ol',
    'ĠF',
    'air',
    'b',
    'a',
    'ĠY',
    'OU',
    'Ġcr',
    'ash',
    'ce',
    'ler',
    'Ġemot',
    'ional',
    'Ġgood',
    's',
    'Ġelect',
    'ed',
    'Ġmod',
    'er',
    'ĠLin',
    'ux',
    'Ġbl',
    'ocks',
    'Ġis',
    'land',
    'ĠSoc',
    'iety',
    'Ġelect',
    'ions',
    'Ġbroad',
    'cast',
    'Ġche',
    'ap',
    'Ġn',
    'ations',
    'Ġse',
    'asons',
    '4',
    '00',
    'Ġwas',
    'te',
    'ĠS',
    'at',
    'Ġfield',
    's',
    'em',
    'ploy',
    'Ġprof',
    'ile',
    'Ġauth',
    'ors',
    'AL',
    'L',
    'ĠG',
    'ra',
    'w',
    'est',
    'ĠT',
    'y',
    'Ġdeath',
    's',
    'Ġv',
    'acc',
    'Ġfor',
    'med',
    'Ġd',
    'u',
    'Ġon',
    'going',
    'ĠMuslim',
    's',
    'el',
    'f',
    'ig',
    'ure',
    'Ġass',
    'ume',
    'ĠUkrain',
    'e',
    'w',
    'ater',
    'Ġco',
    'ast',
    'Ġvot',
    'ed',
    'g',
    'or',
    'ĠA',
    'S',
    'ĠMich',
    'igan',
    'az',
    'a',
    'ĠAr',
    'm',
    'i',
    'ro',
    'Ġf',
    'lex',
    'as',
    'ters',
    "'",
    "'",
    'Ġwel',
    'come',
    'ar',
    'l',
    'Ġloc',
    'ations',
    'ig',
    'ation',
    'ĠF',
    'il',
    'Ġbu',
    'ying',
    'Ġarch',
    'itect',
    'Ġhard',
    'er',
    'ĠC',
    'ub',
    'Ġinter',
    'face',
    'Ġrestaur',
    'ant',
    'Ġdisco',
    'ver',
    'Ġex',
    'ceed',
    'Ġfav',
    'our',
    'ger',
    'y',
    'Ġd',
    'uty',
    'Ġp',
    'itch',
    'ad',
    'or',
    'ĠM',
    'ach',
    'b',
    'oy',
    'Ġrespond',
    'ed',
    'Ġext',
    'ended',
    'her',
    's',
    'M',
    'any',
    'ra',
    'id',
    'if',
    'er',
    'ĠIn',
    's',
    'S',
    'er',
    'Ġmed',
    'ium',
    's',
    'he',
    'ĠS',
    'ports',
    'Ġmag',
    'azine',
    'ut',
    'ation',
    'Ġlim',
    'its',
    'ĠG',
    'all',
    'Ġex',
    'ternal',
    'raz',
    'il',
    'Ġyoung',
    'er',
    't',
    'le',
    'Ġrem',
    'ind',
    'ĠC',
    'ON',
    'Ġimmedi',
    'ate',
    'Ġh',
    'idden',
    'Ġvol',
    'unte',
    'Ġsim',
    'pl',
    'od',
    'cast',
    'Ġph',
    'ase',
    'd',
    'r',
    'Ġpl',
    'ot',
    'Ġexp',
    'osure',
    'R',
    'I',
    'og',
    'rap',
    'v',
    'in',
    'an',
    'ish',
    'ĠAc',
    'ad',
    'ĠEng',
    'ine',
    'Ġexp',
    'ansion',
    'ĠP',
    'ay',
    'Y',
    'our',
    'Ġpus',
    'hed',
    'ĠE',
    'll',
    'ĠHe',
    'ad',
    'Ġmarket',
    'ing',
    'ĠA',
    'C',
    'k',
    'et',
    'Ġh',
    'its',
    'Ġg',
    'ro',
    'ĠA',
    'ge',
    'ĠSc',
    'ot',
    ']',
    '[',
    'Ġst',
    'im',
    'Ġi',
    'Phone',
    'Ī',
    'Ĵ',
    'Ġn',
    'arrow',
    'ĠGet',
    'ty',
    'ĠTur',
    'key',
    'Ġperfect',
    'ly',
    'Ġen',
    'able',
    'ut',
    'ch',
    'Ġprec',
    'ise',
    'Ġreg',
    'ime',
    'Ġsh',
    'if',
    'Ġcomp',
    'ens',
    'g',
    'un',
    'd',
    'iv',
    'Ġch',
    'osen',
    'ĠK',
    'en',
    'An',
    'y',
    'Ġtre',
    'es',
    'Ġrecomm',
    'ended',
    'ĠR',
    'en',
    'u',
    'able',
    'ĠH',
    'T',
    'F',
    'ollow',
    'E',
    'G',
    'ĠH',
    'and',
    'ĠK',
    'enn',
    'Ġarg',
    'uments',
    'Ġex',
    'ists',
    'Ġb',
    'ike',
    'ĠCons',
    'erv',
    'Ġbre',
    'aking',
    'ĠG',
    'ar',
    'Ġc',
    'razy',
    'Ġvirt',
    'ual',
    'ay',
    'lor',
    'ix',
    'el',
    'Ġ19',
    '80',
    'Ġper',
    'mission',
    'ĠSer',
    'ies',
    'Ġconsum',
    'er',
    'Ġclose',
    'ly',
    'c',
    'alled',
    'Ġ5',
    '4',
    'Ġhop',
    'es',
    'Ġar',
    'ray',
    'ĠW',
    'in',
    'ĠLab',
    'our',
    'Ġsp',
    'ons',
    'ĠI',
    're',
    'Ġp',
    'ow',
    'Ġread',
    'ers',
    'Ġemploy',
    'ment',
    'Ġcreat',
    'ure',
    'Ġresult',
    'ing',
    'Ġaccur',
    'ate',
    'Ġmom',
    'ents',
    'Ġarg',
    'ued',
    'Ġp',
    'ed',
    'D',
    'uring',
    'Ġ5',
    '3',
    'ĠT',
    'al',
    'Ġs',
    'ought',
    'Ġsuff',
    'ering',
    'Ġ',
    'icon',
    'le',
    'e',
    'Ġ(',
    '$',
    'al',
    'ian',
    'Â',
    '°',
    'Ġp',
    'ra',
    'Ġbon',
    'us',
    '(',
    '"',
    'k',
    'o',
    'Ġact',
    'ing',
    'D',
    'E',
    'f',
    'all',
    'Ġcompar',
    'ison',
    'Ġsm',
    'ooth',
    'ĠN',
    'AS',
    'u',
    'pp',
    'ĠJose',
    'ph',
    'ep',
    'ing',
    'ĠT',
    'ake',
    'ĠM',
    'id',
    'Ġs',
    'ending',
    'f',
    'ast',
    'ĠF',
    'all',
    'Ġdeal',
    'ing',
    'us',
    'er',
    'ĠOr',
    'gan',
    'C',
    'o',
    'Ġatt',
    'ached',
    'Ġse',
    'es',
    '%',
    '.',
    'Ġtyp',
    'ical',
    'AR',
    'T',
    'Ġfind',
    's',
    'ĠAs',
    'ia',
    'um',
    'in',
    'ĠC',
    'ore',
    'ĠE',
    'nt',
    'in',
    'ent',
    'u',
    'ce',
    'ĠBl',
    'ood',
    'ĠN',
    'ever',
    'Ġem',
    'ails',
    'Ġhigh',
    'light',
    'Ġconf',
    'ront',
    'at',
    'us',
    'ut',
    'ed',
    'Ġun',
    'us',
    'Ġtop',
    'ic',
    'ĠAd',
    'am',
    'Ġb',
    'le',
    'at',
    'i',
    'Ġunder',
    'stood',
    'S',
    'et',
    'st',
    'ruct',
    'T',
    'P',
    'Ġm',
    'ob',
    'a',
    'a',
    'ĠSt',
    'art',
    'pect',
    'ed',
    'se',
    'll',
    'Ġded',
    'icated',
    'ĠC',
    'A',
    'u',
    'an',
    'Ġsong',
    's',
    'esc',
    'ription',
    'Ġte',
    'ch',
    'Ġr',
    'ape',
    'Ġas',
    'ide',
    'Ġgr',
    'ant',
    'Ġ5',
    '6',
    's',
    'ub',
    'Ġarg',
    'ue',
    'Ġcont',
    'aining',
    'Ġsche',
    'dule',
    'Ġliber',
    'al',
    'Ġpublic',
    'ly',
    'Ġheav',
    'ily',
    'ĠU',
    't',
    'in',
    'er',
    'ĠS',
    'ection',
    'ĠC',
    'are',
    'we',
    'et',
    'l',
    's',
    'D',
    'is',
    'âĶ',
    'Ģ',
    'ĠF',
    'ollow',
    'B',
    'ack',
    'ĠI',
    'T',
    'Ġb',
    'es',
    'j',
    'i',
    'ĠH',
    'it',
    'est',
    'ed',
    'Ġevery',
    'body',
    'ĠSw',
    'ed',
    'Ġfem',
    'in',
    'Ġfac',
    'ilities',
    'Ġcon',
    'ven',
    'C',
    'omp',
    'ĠO',
    'S',
    'c',
    'ore',
    'Ġan',
    'x',
    'Ġdiv',
    'ision',
    'ĠC',
    'am',
    'ĠSt',
    'an',
    'm',
    'ates',
    'Ġexpl',
    'ore',
    'pl',
    'om',
    'Ġsh',
    'ares',
    'pl',
    'oad',
    'an',
    'es',
    'Ġide',
    'al',
    'et',
    'ers',
    'ĠB',
    'ase',
    'Ġpl',
    'astic',
    'Ġdist',
    'inct',
    'ĠNet',
    'work',
    'ĠSe',
    'attle',
    'Ġtrad',
    'ing',
    'ens',
    'us',
    'int',
    'end',
    'Ġex',
    'hib',
    'Ġinit',
    'ially',
    'ĠF',
    'ood',
    'Ġthous',
    'and',
    'ĠBus',
    'iness',
    'act',
    'er',
    'Ġpar',
    'agraph',
    'Ġrough',
    'ly',
    'Ġw',
    'ww',
    'Ġcreat',
    'ive',
    'ĠCon',
    'f',
    'Ġconsum',
    'ption',
    'Ġfil',
    'ms',
    'ag',
    'an',
    'Ġob',
    'tain',
    'Ġt',
    'all',
    'Ġt',
    'or',
    'Ġacknow',
    'led',
    'Ġg',
    'rown',
    'al',
    'o',
    'K',
    'E',
    'Ġ4',
    '00',
    'end',
    'ers',
    't',
    'aining',
    'U',
    'G',
    'Ġsu',
    'icide',
    'Ġwat',
    'ched',
    'ĠL',
    'ist',
    'al',
    'i',
    're',
    'hens',
    'Ġsurround',
    'ing',
    'Ġp',
    'ip',
    'Ġf',
    'lying',
    'ĠJ',
    'ava',
    'ord',
    'an',
    'Ġserv',
    'ing',
    'in',
    'ations',
    'p',
    'ost',
    'Ġsh',
    'o',
    'A',
    'v',
    'Ġj',
    'ail',
    'z',
    'y',
    'Ġ199',
    '9',
    'Ġ<',
    '/',
    'Ġliter',
    'ally',
    'ĠS',
    'ir',
    'Ġexp',
    'osed',
    'Ġl',
    'ies',
    'st',
    'ar',
    'Ġb',
    'at',
    'Ġear',
    'ned',
    'ĠD',
    'ig',
    'Ġspec',
    'ified',
    'ĠSe',
    'ason',
    'Ġdeg',
    'rees',
    'Don',
    'ald',
    'Ġcent',
    're',
    'Ġsh',
    'aring',
    'Ġwin',
    'ter',
    'ĠC',
    'O',
    'C',
    'he',
    'Ġ',
    'Î',
    'M',
    'P',
    'Ġun',
    'w',
    'Ġfew',
    'er',
    'ĠM',
    'ir',
    'Ġsomew',
    'here',
    'ĠK',
    'ey',
    'Ġattack',
    'ed',
    'ĠK',
    'ir',
    'Ġdom',
    'ain',
    'Ġstrong',
    'er',
    'Ġ9',
    '9',
    'Ġpen',
    'alty',
    'I',
    'd',
    'Sc',
    'ript',
    'Ġdecl',
    'ined',
    'Ġne',
    'ck',
    'Ġfra',
    'ud',
    'Ġcur',
    'rency',
    'Ġr',
    'ising',
    'R',
    'C',
    'âĢ¦',
    'âĢ¦',
    'H',
    'z',
    'Ġt',
    'ab',
    'Ġtal',
    'ent',
    'n',
    'am',
    'ĠN',
    'BA',
    'Ġvill',
    'age',
    'Ġleg',
    's',
    'ĠN',
    'ext',
    'E',
    'd',
    'Ġac',
    'id',
    'Ġhy',
    'd',
    '8',
    '00',
    'Ġinvol',
    'ving',
    'ĠIm',
    'age',
    'ĠBe',
    'fore',
    'F',
    'l',
    'Ġyes',
    'terday',
    'S',
    'ource',
    'Ġterror',
    'ist',
    'Ġsu',
    'p',
    'Ġsy',
    'nt',
    'ĠSaud',
    'i',
    'Ġw',
    'est',
    'Ġr',
    'u',
    'b',
    'urg',
    'Ġvis',
    'ible',
    'Ġstru',
    'ck',
    'r',
    'ison',
    'Ġaw',
    'esome',
    'Ġd',
    'rawn',
    'Ġansw',
    'ers',
    'ĠG',
    'irl',
    'ĠR',
    'am',
    'Ġthreat',
    's',
    'Ġdef',
    'eat',
    'os',
    'it',
    'Ġv',
    'ent',
    'atur',
    'ally',
    'Americ',
    'an',
    'end',
    'a',
    'ĠH',
    'oly',
    'Ġr',
    'um',
    '%',
    ',',
    'c',
    'ase',
    'ĠHist',
    'ory',
    'ĠYou',
    'Tube',
    'Ġsit',
    'uations',
    'ĠD',
    'NA',
    'S',
    'te',
    'Ġsa',
    'ved',
    'It',
    'em',
    'Ġrec',
    'ip',
    'olog',
    'ist',
    'Ġfac',
    'ed',
    'Ġel',
    'ig',
    'O',
    'nce',
    'ĠL',
    'i',
    'u',
    'h',
    'Ġmist',
    'ake',
    'ĠDiv',
    'ision',
    'ĠB',
    'ell',
    'Ġsympt',
    'oms',
    'Â',
    '®',
    'Ġdom',
    'in',
    'Ġfall',
    'ing',
    'Ġend',
    'ing',
    'as',
    'hes',
    'Ġmat',
    'ches',
    'ĠOn',
    'line',
    'Ġexplan',
    'ation',
    'D',
    'ef',
    'red',
    'it',
    'Ġany',
    'more',
    'ĠT',
    'otal',
    'ĠF',
    'OR',
    'us',
    'hed',
    'Ġlet',
    'ters',
    'Ġris',
    'ks',
    'ĠO',
    'K',
    'Ġreported',
    'ly',
    ':',
    '\\',
    'Ġpl',
    'ate',
    'Ġsubject',
    's',
    'Ġattempt',
    'ed',
    'if',
    'ier',
    'ian',
    'a',
    'Ġunlike',
    'ly',
    'ĠTh',
    'ough',
    'um',
    'a',
    'ĠIn',
    'vest',
    'ĠPr',
    'in',
    'ic',
    'an',
    'ĠD',
    'ar',
    'ĠColor',
    'ado',
    'au',
    'g',
    'Ġve',
    'get',
    'a',
    'os',
    'ri',
    'a',
    'Ġshe',
    'l',
    'Ġmark',
    'ed',
    'Ġ(',
    ')',
    'Ġsp',
    'r',
    'p',
    'o',
    'ĠL',
    'ink',
    'Ġdef',
    'e',
    'ĠJ',
    'r',
    'Ġthem',
    'e',
    'Ġpass',
    'ion',
    'ĠP',
    'en',
    'Ġinf',
    'o',
    'iz',
    'er',
    'Ġsh',
    'it',
    'ĠC',
    'ivil',
    'ap',
    'se',
    'c',
    're',
    'Ġpo',
    'ly',
    'Ġcomp',
    'onent',
    'ĠChar',
    'les',
    'ĠIre',
    'land',
    'ĠPro',
    'v',
    'Ġdo',
    'ctors',
    'Ġgr',
    'anted',
    'Ġpain',
    't',
    'Ġhon',
    'or',
    'Ġsm',
    'oke',
    'Ġpay',
    'ments',
    'Ġprim',
    'arily',
    'ĠKing',
    'dom',
    'r',
    'ich',
    'ate',
    'll',
    'Ġde',
    'als',
    'Ġsched',
    'uled',
    'Ġfund',
    'amental',
    'Ġprote',
    'in',
    'Ġnewsp',
    'aper',
    'Ġcl',
    'ients',
    'yth',
    'on',
    'ĠD',
    'ate',
    'h',
    'us',
    'Ġfeed',
    'back',
    'Ġstret',
    'ch',
    'Ġc',
    'ock',
    'Ġhot',
    'el',
    'ĠQue',
    'en',
    'Ġsu',
    'gar',
    'Ġj',
    'u',
    'Ġmil',
    'k',
    'Ġappro',
    'val',
    'ĠL',
    'ive',
    'Ġequival',
    'ent',
    'ef',
    'ully',
    'Ġins',
    'ert',
    'z',
    'ona',
    'Ġext',
    'ension',
    'd',
    'ri',
    'J',
    'ohn',
    'Ġacc',
    'omp',
    'S',
    'm',
    'ĠF',
    'und',
    'Ġconst',
    'antly',
    'Ġ`',
    '`',
    'Ġgener',
    'ated',
    'ĠA',
    'ction',
    'ĠP',
    'sych',
    'ĠT',
    'ri',
    'Ġrecogn',
    'ize',
    'Ġv',
    'ary',
    'ph',
    'a',
    'ĠR',
    'a',
    'd',
    'f',
    'et',
    'ch',
    'ĠSov',
    'iet',
    'Tw',
    'o',
    'Ġpattern',
    's',
    'Ġprof',
    'ession',
    'an',
    'ing',
    'T',
    'ime',
    'ĠL',
    'im',
    'Ġcol',
    'ors',
    'ĠA',
    'z',
    'ĠT',
    'R',
    'Ġinf',
    'ect',
    'Ġphen',
    'omen',
    'Ġshe',
    'll',
    'Al',
    'so',
    'Ġput',
    's',
    'Ġdel',
    'ivery',
    'Ġbro',
    'wn',
    'Ġprocess',
    'ing',
    'Ġlight',
    's',
    'ess',
    'age',
    'ĠBro',
    'ok',
    'ĠA',
    'ud',
    'l',
    'ation',
    'Ġindust',
    'rial',
    'L',
    'ike',
    'ĠB',
    'razil',
    'rou',
    's',
    'ES',
    'S',
    'ĠL',
    'uc',
    'Ġsome',
    'how',
    'Ġ8',
    '5',
    'Ġpro',
    'port',
    'Ġpolit',
    'icians',
    'Ġindic',
    'ate',
    'Ġh',
    'ole',
    'Ġtechn',
    'iques',
    'Ġcompet',
    'itive',
    'Ġph',
    'r',
    'Ġv',
    'o',
    'ist',
    'ent',
    'ĠD',
    'ream',
    'Ġcamp',
    'us',
    'Ġaspect',
    's',
    'Ġhelp',
    'ful',
    'Ġsh',
    'ield',
    'or',
    'se',
    'Ġtrig',
    'ger',
    'm',
    'al',
    'Ġ5',
    '8',
    'Ġt',
    'ort',
    'Ġperson',
    'ally',
    'Ġt',
    'ag',
    'Ġkeep',
    's',
    'ĠV',
    'ideo',
    'Ġben',
    'ch',
    'Ġg',
    'ap',
    'a',
    'ire',
    'Ġe',
    'ast',
    'Ġrec',
    'overy',
    'per',
    'ial',
    'Ġprof',
    'it',
    'ĠM',
    'ic',
    'Ġ5',
    '7',
    'Ġcol',
    'on',
    'Ġstrong',
    'ly',
    'st',
    'yle',
    'Ġalleg',
    'ations',
    'h',
    'an',
    'Ġrep',
    'orters',
    'j',
    'o',
    'r',
    'ine',
    'arg',
    'et',
    'and',
    'al',
    'Ġ0',
    '3',
    'Ġfl',
    'ash',
    'tr',
    'ans',
    'Ġstr',
    'ict',
    'Ġpark',
    'ing',
    'ĠPak',
    'istan',
    'Ġl',
    'i',
    'Ġwe',
    'ird',
    'ĠE',
    'ric',
    'Ġreg',
    'ions',
    'ĠJ',
    'un',
    'Ġint',
    'ellect',
    'ĠW',
    'H',
    'od',
    'ing',
    'rib',
    'utes',
    'up',
    'id',
    'ĠT',
    'it',
    'Ġf',
    'inger',
    'or',
    'ia',
    'Ġe',
    'lev',
    'ĠF',
    'ield',
    'Ġcon',
    'clusion',
    ';',
    ';',
    'Ġfeel',
    'ings',
    'Ġext',
    'ensive',
    'Ġm',
    'ixed',
    'Ġne',
    'uro',
    'v',
    'y',
    'Ġhar',
    'ass',
    'ĠC',
    'irc',
    'ou',
    'ch',
    'Ġterrit',
    'ory',
    'Ġsuccess',
    'fully',
    'M',
    'ar',
    'Ġing',
    'red',
    'Ġoverw',
    'hel',
    'Ġl',
    'ayer',
    'V',
    'iew',
    'Ġall',
    'ies',
    'ill',
    'ance',
    'ĠTh',
    'ree',
    'Ġb',
    'unch',
    'Ġnorm',
    'ally',
    'Ġnet',
    'works',
    'Ġsac',
    'r',
    'ĠC',
    'IA',
    'b',
    'les',
    'Ġch',
    'ose',
    'Ġopp',
    'onents',
    'Ġregard',
    'less',
    'Ġfr',
    'anch',
    'Ġpre',
    'f',
    'ĠP',
    'o',
    'Ġbr',
    'idge',
    'ann',
    'a',
    'ĠSil',
    'ver',
    'Ġw',
    'age',
    'p',
    'age',
    'ri',
    'or',
    'Ġrad',
    'ical',
    'ĠL',
    'ittle',
    'Ġman',
    'ip',
    'Ġsecret',
    'ary',
    'Ġg',
    'ang',
    'D',
    'R',
    'F',
    'A',
    'Ġdec',
    'ent',
    'ĠSp',
    'irit',
    'Ġun',
    'cle',
    'ĠDevelop',
    'ment',
    'Ġinvest',
    'ors',
    'Ġwall',
    's',
    'Ġpub',
    'lish',
    'Ġgener',
    'ate',
    'iss',
    'ions',
    'c',
    'ar',
    'Ġprom',
    'ote',
    'Ġcut',
    'ting',
    'Ġche',
    'st',
    'Ġdrink',
    'ing',
    'Ġcollect',
    'ed',
    'Ġ7',
    '2',
    'Ġhop',
    'ing',
    'Ġem',
    'br',
    'gor',
    'ith',
    'Ġwar',
    'ned',
    'Ġinstruct',
    'ions',
    'O',
    'G',
    'ĠD',
    'id',
    'ĠAg',
    'ency',
    'Ġg',
    'ear',
    'Ġcritic',
    'ism',
    'ĠF',
    'urther',
    'Ġut',
    'il',
    'ann',
    'y',
    'R',
    'ed',
    'Ġcoun',
    'sel',
    'ĠAs',
    'ian',
    'Ġredu',
    'ction',
    'p',
    'ool',
    'Ġteach',
    'ing',
    'Ġdeep',
    'ly',
    'i',
    'y',
    'Ġestim',
    'ates',
    'Ġcho',
    'ices',
    'Ġperman',
    'ent',
    'in',
    'em',
    'ke',
    'l',
    'Ġf',
    'asc',
    'p',
    'se',
    'f',
    'ile',
    'ĠL',
    'ow',
    'ĠP',
    'erson',
    'Ġt',
    'ournament',
    'st',
    'al',
    'Ġm',
    'el',
    'U',
    'ST',
    'ĠR',
    'ay',
    'az',
    'i',
    'V',
    'al',
    'Ġcont',
    'ained',
    'ĠH',
    'olly',
    'Ġw',
    'ake',
    'Ġreve',
    'al',
    'Ġprocess',
    'es',
    'ĠIS',
    'IS',
    'Ġ0',
    '9',
    'Ġbl',
    'ind',
    'Ġste',
    'el',
    'ĠB',
    'ad',
    'Ġcare',
    'fully',
    'app',
    'y',
    'ro',
    'it',
    'Ġg',
    'aming',
    'Ġhous',
    'es',
    'ĠC',
    'oll',
    'Ġtr',
    'uck',
    'er',
    'm',
    'Ġsc',
    'ored',
    'Ġocc',
    'as',
    'ret',
    'urn',
    'b',
    'ound',
    'v',
    'ar',
    'Ġsh',
    'arp',
    'Ġaf',
    'raid',
    'ĠE',
    'X',
    'am',
    'ber',
    'c',
    'ific',
    'Ġsche',
    'me',
    'N',
    'C',
    'ĠPol',
    'it',
    'Ġdecl',
    'ine',
    'Ġ199',
    '8',
    'Ġpus',
    'hing',
    'Ġposs',
    'ession',
    'Ġpriv',
    'ile',
    'Ġteacher',
    's',
    'Ġy',
    'ield',
    'H',
    'A',
    'ĠDav',
    'is',
    'it',
    'led',
    '####',
    '####',
    'Ġr',
    'ig',
    'ĠD',
    'aniel',
    'ac',
    'on',
    'Ġh',
    'ide',
    'ut',
    'en',
    'Ġcolle',
    'agues',
    'Ġprin',
    'ciples',
    'Ġl',
    'oud',
    'Ġs',
    'in',
    'ĠDem',
    'on',
    'Ġst',
    'one',
    'Ġ0',
    '2',
    'Ġt',
    'aught',
    'Ġter',
    'rible',
    'Ġst',
    'uck',
    'ĠPol',
    'icy',
    'te',
    'en',
    'Ġimplement',
    'ation',
    'ĠB',
    'BC',
    'ĠAP',
    'I',
    'Ġwhe',
    'el',
    'all',
    'as',
    'Ġch',
    'ampions',
    'ol',
    'ars',
    'play',
    'er',
    'Ġrepeated',
    'ly',
    'ĠSt',
    'ill',
    'Ġlik',
    'es',
    'ast',
    'y',
    'es',
    'ter',
    'ĠCath',
    'olic',
    'R',
    'L',
    'Ġb',
    'ath',
    'Ġno',
    'ise',
    't',
    'itle',
    'Ġn',
    'orthern',
    'P',
    'art',
    'Ġmag',
    'n',
    'Ġf',
    'ab',
    'ĠAs',
    'h',
    'Ġdis',
    'pl',
    'Ġtick',
    'et',
    'Ġm',
    'urd',
    'Ġalong',
    'side',
    'ĠMus',
    'ic',
    'Ġr',
    'iver',
    'ĠSte',
    'el',
    'ĠC',
    'L',
    'ĠPl',
    'ayer',
    'ĠM',
    'ult',
    'ow',
    'ing',
    're',
    'p',
    's',
    'ize',
    'Ġt',
    'ur',
    'ĠGeorg',
    'ia',
    'isc',
    'al',
    'ra',
    'ction',
    'Ġc',
    'able',
    'Ġ5',
    '9',
    'Ġw',
    'ins',
    'Ġup',
    'coming',
    'Ġsurv',
    'ive',
    'Ġins',
    'pired',
    'ĠEduc',
    'ation',
    'Ġstat',
    'istics',
    'ĠF',
    'oot',
    'iam',
    'i',
    'Ġy',
    'ellow',
    'ĠP',
    'age',
    '.',
    '-',
    'ĠH',
    'as',
    'Ġur',
    'ban',
    'Ġa',
    'x',
    'es',
    'sel',
    '\\',
    '"',
    'Ġquarter',
    'back',
    'Ġreg',
    'ister',
    'ĠLab',
    'or',
    'Ġab',
    'ilities',
    'ĠF',
    'amily',
    'Ġvar',
    'iable',
    'ĠPr',
    'ice',
    'Ġcont',
    'em',
    'Ġth',
    'in',
    'ĠE',
    'qu',
    'd',
    'ata',
    'Ġg',
    'otten',
    'Ġconst',
    'it',
    'Ġas',
    'ks',
    'Ġt',
    'ail',
    'Ġexc',
    'iting',
    'ĠE',
    'ffect',
    'ĠSp',
    'anish',
    'Ġencour',
    'age',
    'ins',
    'on',
    'ĠA',
    'h',
    'Ġcommit',
    'ment',
    'C',
    'S',
    'Ġr',
    'ally',
    'Ġ:',
    ':',
    'Ġsubs',
    'id',
    'Ġsp',
    'in',
    'Ġcapt',
    'ured',
    '201',
    '8',
    'Ġinn',
    'oc',
    'Ġalleged',
    'ly',
    'ĠC',
    'ome',
    'Ġart',
    'ists',
    'ĠN',
    'umber',
    'Ġelect',
    'ronic',
    'Ġreg',
    'ional',
    'ap',
    'es',
    'Ġw',
    'ra',
    'Ġmy',
    'th',
    'pr',
    'ise',
    'ĠM',
    'iller',
    'ĠC',
    'reat',
    'ĠEp',
    'isode',
    'b',
    'ell',
    'Ġdirect',
    'ed',
    'Ġext',
    'ract',
    'Ġs',
    'orry',
    'Ġv',
    'ice',
    'ag',
    'ger',
    'ĠSu',
    'pport',
    'Ġ6',
    '6',
    'ĠI',
    'ron',
    'Ġwonder',
    'ful',
    'Ġg',
    'ra',
    'N',
    'et',
    'ion',
    'e',
    'E',
    'ng',
    'Ġsh',
    'ips',
    'ik',
    'es',
    'ĠK',
    'evin',
    'it',
    'ar',
    'Ġactiv',
    'ists',
    'tr',
    'ue',
    'ĠAri',
    'zona',
    'ent',
    'h',
    'ĠDes',
    'pite',
    'ĠS',
    'E',
    'Ġha',
    'bit',
    'ern',
    'el',
    'Ġin',
    'qu',
    'Ġab',
    'ortion',
    'Ġv',
    'oid',
    'Ġexpl',
    'icit',
    'Ġeng',
    'aged',
    'Ġang',
    'ry',
    'Ġr',
    'ating',
    'Ġfr',
    'ag',
    'b',
    'ro',
    'ick',
    'ing',
    'd',
    'ev',
    'Ġwor',
    'ried',
    'Ġob',
    'ser',
    'Ġap',
    'artment',
    'ĠG',
    'T',
    'Ġest',
    'ate',
    'ĠConst',
    'itution',
    'em',
    'on',
    'ĠS',
    'now',
    'Ġcount',
    'y',
    'Ġdis',
    'ag',
    'ĠStep',
    'hen',
    'Ġimm',
    'igrants',
    'w',
    'ind',
    'ĠN',
    'ations',
    'Ġfol',
    'ks',
    'O',
    'ut',
    'Ġg',
    'all',
    'Ġtarget',
    'ed',
    'Ġst',
    'ead',
    'ĠB',
    'on',
    'ĠL',
    'ib',
    'Ġinform',
    'ed',
    'Ġ12',
    '0',
    'ch',
    'ain',
    'idel',
    'ines',
    'or',
    'ough',
    'Ġdri',
    'ven',
    'Ġregular',
    'ly',
    'Ġbas',
    'ket',
    'Ġprinc',
    'iple',
    'oc',
    'ument',
    'Ġst',
    'un',
    'ib',
    'ilities',
    'ĠRom',
    'an',
    'ĠAb',
    'out',
    'Ġal',
    'ert',
    'Ġdemocr',
    'acy',
    'Ġrepresent',
    'ed',
    'H',
    'S',
    'c',
    'ers',
    'p',
    'arent',
    'Ar',
    't',
    'p',
    'ack',
    'Ġdi',
    'plom',
    're',
    'ts',
    'ĠN',
    'O',
    'Ġcapt',
    'ure',
    'ĠAd',
    'v',
    'Ħ',
    '¢',
    'Ġannounce',
    'ment',
    'ĠL',
    'ear',
    'Ġh',
    'ook',
    'Ġpur',
    's',
    'ĠS',
    'uch',
    'ĠC',
    'amer',
    'Ġrefuge',
    'es',
    'ĠV',
    'e',
    'P',
    'ol',
    'Ġrecogn',
    'ized',
    'l',
    'ib',
    'Ġhad',
    'n',
    'A',
    'ss',
    'Ġpil',
    'ot',
    'us',
    'hing',
    'Ġreturn',
    'ing',
    'Ġtra',
    'il',
    'ĠSt',
    'one',
    'Ġrout',
    'ine',
    'Ġcour',
    'ts',
    'Ġdes',
    'per',
    'Ġfriend',
    'ly',
    'ĠIt',
    'aly',
    'Ġpl',
    'ed',
    'Ġbreat',
    'h',
    'Ġstud',
    'io',
    'N',
    'S',
    'Ġimp',
    'ressive',
    'ĠAfghan',
    'istan',
    'Ġf',
    'ing',
    'Ġd',
    'ownt',
    'ink',
    'ing',
    'ĠR',
    'og',
    'i',
    'ary',
    'col',
    'or',
    'se',
    'x',
    'ar',
    'on',
    'Ġf',
    'ault',
    'ĠN',
    'ick',
    'D',
    'own',
    'ĠR',
    'ose',
    'ĠS',
    'outhern',
    'X',
    'X',
    'is',
    'odes',
    'L',
    'ist',
    '6',
    '00',
    'Ġout',
    'come',
    'er',
    'r',
    'Ġelse',
    'where',
    'Ġret',
    'ire',
    'Ġp',
    'ounds',
    'ĠGl',
    'obal',
    'Pe',
    'ople',
    'Ġcommun',
    'ications',
    'Ġlo',
    'an',
    'Ġrat',
    'io',
    'ĠEm',
    'pire',
    'Ġg',
    'onna',
    'Ġinv',
    'ent',
    'D',
    'F',
    'Ġ19',
    '70',
    'ĠComm',
    'on',
    'p',
    'at',
    'Ġprom',
    'ised',
    'Ġd',
    'inner',
    'ĠH',
    'om',
    'Ġcreat',
    'es',
    'Ġoper',
    'ate',
    'ver',
    'ty',
    'ĠJ',
    'ordan',
    'et',
    'ime',
    'Ġsust',
    'ain',
    'R',
    'eg',
    'Ġincred',
    'ible',
    'im',
    'a',
    'Ġwar',
    'rant',
    'Ġm',
    'm',
    'A',
    'tt',
    'Ġlaw',
    'suit',
    'Ġreview',
    's',
    'it',
    'ure',
    'ĠS',
    'ource',
    'l',
    'ights',
    'ĠF',
    'ord',
    'Ġ6',
    '3',
    'g',
    'roup',
    'st',
    'ore',
    'Ġfeat',
    'ured',
    'Ġfore',
    'ver',
    'Ġpo',
    'verty',
    'ĠP',
    'op',
    'ĠC',
    'NN',
    'az',
    'z',
    'ab',
    'is',
    'ach',
    'ing',
    'Ġl',
    'aid',
    'ĠSu',
    'pp',
    'Ġfil',
    'ter',
    'en',
    'a',
    'ĠCommun',
    'ity',
    'Ġcreat',
    'ures',
    'u',
    'ction',
    'ĠR',
    'oyal',
    'Ġassoci',
    'ation',
    'ĠCon',
    'nect',
    'ĠBr',
    'ad',
    'âĸ',
    'Ī',
    'l',
    'ers',
    'the',
    're',
    'ĠG',
    'i',
    'Ġval',
    'uable',
    'AC',
    'K',
    'ĠT',
    'aylor',
    'Ġl',
    'iquid',
    'ĠAtt',
    'orney',
    'ĠCar',
    'l',
    'ĠF',
    'inal',
    'ag',
    'a',
    'ĠWil',
    'son',
    'B',
    'ecause',
    'ĠProf',
    'essor',
    'ak',
    'a',
    'Ġincred',
    'ibly',
    'r',
    'ance',
    '!',
    ')',
    'R',
    'ef',
    's',
    'k',
    'Ġsol',
    'utions',
    'Ġatmosp',
    'here',
    'Ġbl',
    'ame',
    'um',
    'es',
    'ĠN',
    'ob',
    'C',
    'A',
    'um',
    'ps',
    'r',
    'ical',
    'ĠPut',
    'in',
    'ĠD',
    'est',
    'or',
    'ic',
    'ĠP',
    'A',
    'Ġrespect',
    'ively',
    'w',
    'an',
    'Ġfif',
    'th',
    'â',
    'Ħ¢',
    'ĠC',
    'ry',
    'Ġgovern',
    'or',
    'res',
    'ident',
    'Ġpurch',
    'ased',
    'Ġh',
    'ack',
    'Ġint',
    'ense',
    'ob',
    's',
    'Ġorig',
    'in',
    'Ġdef',
    'ine',
    'Ġcare',
    'ful',
    '**',
    '*',
    'Ġshould',
    'er',
    'Cl',
    'ick',
    'Ġt',
    'ied',
    'Ġdest',
    'ruction',
    'ou',
    'red',
    'Ġno',
    'body',
    'Ġh',
    'o',
    'ĠEx',
    'per',
    'Ġt',
    'ip',
    '"',
    ';',
    'Ġtechn',
    'ique',
    'Ġj',
    'ur',
    'ĠP',
    'ok',
    'b',
    'ow',
    'Ġleg',
    'end',
    'Ġacc',
    'ord',
    'Ġbus',
    'y',
    'ĠInt',
    'el',
    'Ġh',
    'ang',
    'ak',
    'i',
    '.',
    ']',
    'âĢĶâĢĶ',
    'âĢĶâĢĶ',
    'Ġsur',
    'gery',
    'Ġrep',
    'rodu',
    'Ġun',
    'iform',
    'Ġscen',
    'es',
    'c',
    'ode',
    'Ġ6',
    '2',
    'l',
    'isher',
    'ĠH',
    'ave',
    'ph',
    'ia',
    'Ġcry',
    'pt',
    'Ġrec',
    'on',
    'Ġsc',
    'ream',
    'Ġadop',
    'ted',
    'Ġsc',
    'ores',
    'N',
    'e',
    'ĠIt',
    'alian',
    'in',
    'cluding',
    'B',
    'O',
    'Ġindic',
    'ated',
    'Ġent',
    'ertain',
    'G',
    'u',
    'T',
    'ext',
    'i',
    'el',
    'Ġtw',
    'enty',
    'Ġeng',
    'age',
    'off',
    's',
    'ĠPac',
    'ific',
    'Ġsm',
    'ile',
    'Ġperson',
    'nel',
    'Ġto',
    'ler',
    'Ġdo',
    'ors',
    'Ġt',
    'one',
    'Ġmach',
    'ines',
    'Ġent',
    'ering',
    'ten',
    'ance',
    'C',
    'O',
    'ĠJer',
    'sey',
    'Ġfore',
    'st',
    'Ġhor',
    'se',
    'Ġcompl',
    'aint',
    'ĠSpr',
    'ing',
    'y',
    'o',
    'ĠPl',
    'us',
    'ed',
    'ing',
    'ĠRet',
    'urn',
    'qu',
    'arters',
    'ial',
    's',
    'c',
    'ow',
    'Ġacad',
    'emic',
    'Ġf',
    'ruit',
    'Ġ199',
    '6',
    'og',
    'ether',
    'Ġw',
    'ine',
    'Ġpur',
    'su',
    'ĠSte',
    'ven',
    'Ġlic',
    'ens',
    'Wh',
    'o',
    'Ġclot',
    'hes',
    're',
    'ction',
    'Ġsqu',
    'ad',
    'Ġst',
    'able',
    'Ġr',
    'aw',
    'z',
    'ens',
    'St',
    'ar',
    'ut',
    'ies',
    'anc',
    'er',
    'Ġke',
    'ys',
    'ĠM',
    'u',
    'Ġcompl',
    'icated',
    'ig',
    'er',
    'ĠTe',
    'xt',
    'Ġabs',
    'or',
    'Ġ6',
    '8',
    'Ġfun',
    'ny',
    'Ġrel',
    'ief',
    'ĠL',
    'ew',
    'ĠC',
    'ook',
    'Ġch',
    'art',
    'Ġdraw',
    'ing',
    'G',
    'E',
    'Ġmod',
    'ule',
    'ĠB',
    'ull',
    'I',
    'LL',
    'Ġs',
    'alt',
    '0000',
    '0000',
    'il',
    'le',
    'Ġres',
    'ource',
    'aw',
    'ay',
    'adel',
    'phia',
    'ĠB',
    'ru',
    'Ġ6',
    '7',
    'Ġsome',
    'body',
    'Ġparticip',
    'ate',
    'Ġro',
    'se',
    'we',
    'red',
    'Ġmus',
    'cle',
    'Ġcons',
    'ent',
    'Ġcontin',
    'uing',
    'ĠGuard',
    'ian',
    'ĠOr',
    'der',
    'reg',
    'on',
    'Ġre',
    'ar',
    'Ġprov',
    'ision',
    'Ġlik',
    'ed',
    'ri',
    'ent',
    'Ġb',
    'ra',
    'Tr',
    'ans',
    'Ġmeet',
    'ings',
    'Ġto',
    'x',
    'Ġcon',
    'vent',
    'Ġaut',
    'o',
    'Ġrec',
    'ording',
    'ĠSo',
    'ft',
    '00',
    '1',
    'ĠR',
    'oll',
    'Ġprogram',
    'ming',
    'Ġp',
    'ic',
    'Ġprov',
    'ed',
    'Ġst',
    'ab',
    'ĠA',
    'st',
    'Ġca',
    'ption',
    'ul',
    'ating',
    'ĠAtt',
    'ack',
    'Ġnew',
    'ly',
    'Ġ199',
    '7',
    'f',
    'r',
    'Ġdis',
    'cipl',
    'ĠGree',
    'k',
    'Ġed',
    'ition',
    'ĠDo',
    'es',
    'ĠB',
    'ox',
    'if',
    'le',
    'ack',
    'et',
    'Ġpass',
    'es',
    'Ġgu',
    'est',
    'Ġac',
    'celer',
    'it',
    'als',
    'U',
    'D',
    'Ġaut',
    'hent',
    'ĠR',
    'est',
    'ov',
    'al',
    't',
    'a',
    'u',
    'ine',
    'Ġarm',
    'or',
    'ĠT',
    'own',
    'Ġcomp',
    'at',
    'Ġinc',
    'hes',
    'Des',
    'pite',
    'Ġass',
    'ign',
    'he',
    'rent',
    'Ġprep',
    'are',
    'ĠM',
    'eg',
    'oc',
    'key',
    'Ġdep',
    'ends',
    'Ġtrack',
    's',
    'w',
    'atch',
    'Ġl',
    'ists',
    'ĠN',
    'orthern',
    'Ġal',
    'ter',
    're',
    'c',
    'ĠE',
    'astern',
    'Ġcond',
    'em',
    'Ġevery',
    'where',
    '?',
    "'",
    'Ġaff',
    'ili',
    'Ġf',
    'ought',
    '":',
    '{"',
    'Ġm',
    'ac',
    'it',
    'arian',
    'Ġsc',
    'ope',
    'ĠA',
    'L',
    'aw',
    's',
    'ar',
    'ms',
    'Ġqu',
    'e',
    'Ġenjoy',
    'ed',
    'nes',
    'ota',
    'Ġagg',
    'ressive',
    'ĠSt',
    'ory',
    'ĠI',
    'V',
    'Ġrec',
    'ipe',
    'Ġrare',
    'ly',
    'ĠMed',
    'ical',
    'val',
    'ue',
    'ang',
    'el',
    'ay',
    'ing',
    'omet',
    'hing',
    'Ġsub',
    'section',
    'Ġs',
    'outhern',
    'Ġfrequ',
    'ency',
    're',
    'te',
    'roll',
    'ed',
    'ult',
    's',
    'ĠN',
    'ic',
    'Ġbeh',
    'alf',
    'Ġsequ',
    'ence',
    'ab',
    'et',
    'Ġcontrovers',
    'ial',
    'Ġcomp',
    'rom',
    'Ġwork',
    'er',
    'Ġmain',
    'ly',
    'Ġal',
    'gorith',
    'ĠM',
    'ajor',
    'or',
    'ce',
    'g',
    'ender',
    'Ġorgan',
    'ized',
    'Ġf',
    'ake',
    'Ġconclud',
    'ed',
    'ĠE',
    'D',
    'ĠEx',
    'ec',
    'r',
    'age',
    'Ġch',
    'ances',
    'ber',
    'ry',
    'ĠTr',
    'ad',
    'Ġconfig',
    'uration',
    'Ġwithd',
    'raw',
    'Ġf',
    'ro',
    'ud',
    'es',
    'ĠBro',
    'ther',
    'ĠB',
    'rian',
    'Ġtri',
    'es',
    'Ġsam',
    'ples',
    'Ġb',
    'id',
    'ĠGold',
    'en',
    'Ġphot',
    'ograph',
    'if',
    'est',
    'ĠD',
    'O',
    'ĠPar',
    'liament',
    '********',
    '********',
    'R',
    'em',
    'Ġcont',
    'est',
    'Ġsign',
    'ing',
    'p',
    'x',
    'ĠZ',
    'eal',
    'âĶĢ',
    'âĶĢ',
    'E',
    'ar',
    'Ġex',
    'it',
    'Be',
    'fore',
    'ĠCor',
    'por',
    'n',
    'ull',
    'mon',
    'th',
    'Ġrac',
    'ial',
    'ott',
    'ed',
    'ĠV',
    'eg',
    'ĠRe',
    'uters',
    'Ġsw',
    'ord',
    'ps',
    'on',
    'ĠRom',
    'ney',
    'a',
    'ed',
    'Ġt',
    'rib',
    'Ġin',
    'ner',
    'Ġprot',
    'ocol',
    'ĠB',
    'i',
    'ĠM',
    'iami',
    'ever',
    'al',
    'p',
    'ress',
    'Ġsh',
    'ipping',
    'ĠAm',
    'endment',
    'ĠHow',
    'ard',
    'con',
    'nect',
    'ĠD',
    'isc',
    'ĠJ',
    'ac',
    'iam',
    'ond',
    'ĠThere',
    'fore',
    's',
    'es',
    'ĠPrin',
    'cess',
    'ĠUS',
    'B',
    'ĠAn',
    'th',
    'Ġsurve',
    'illance',
    'Ġap',
    'olog',
    'Ġ6',
    '1',
    'ow',
    'a',
    'Ġf',
    'ulf',
    'j',
    's',
    'Ġl',
    'uck',
    'ust',
    'ed',
    'ĠÂ',
    '§',
    'n',
    'i',
    'Ġant',
    'icip',
    'em',
    'an',
    'Ġwin',
    'ner',
    'Ġsil',
    'ver',
    'll',
    'a',
    'ic',
    'ity',
    'Ġunus',
    'ual',
    'Ġcr',
    'ack',
    'Ġt',
    'ies',
    'e',
    'z',
    'Ġpract',
    'ical',
    'Ġprov',
    'ince',
    'ĠPl',
    'ace',
    'Ġprior',
    'ity',
    'IC',
    'E',
    'Ġdescrib',
    'es',
    'Ġbr',
    'anch',
    'F',
    'orm',
    'ask',
    'a',
    'miss',
    'ions',
    'b',
    'i',
    'Ġp',
    'orn',
    'ĠTur',
    'k',
    'Ġent',
    'hus',
    'Ġf',
    'ighters',
    'Ġ0',
    '8',
    'ĠDet',
    'roit',
    'Ġfound',
    'ation',
    'av',
    'id',
    'A',
    're',
    'Ġjud',
    'gment',
    'cl',
    'ing',
    'Ġsol',
    've',
    'ĠDes',
    'ign',
    'W',
    'here',
    'hes',
    'is',
    'ĠT',
    'ro',
    'a',
    'fter',
    'Ġne',
    'utral',
    'ĠPalestin',
    'ian',
    'ĠHolly',
    'wood',
    'Ġadv',
    'is',
    'ĠN',
    'on',
    'y',
    'es',
    'ol',
    'is',
    'Ġrep',
    'utation',
    'Ġsm',
    'ell',
    'Ġb',
    'read',
    'ĠB',
    'ul',
    'ĠBe',
    'ach',
    'Ġclaim',
    'ing',
    'Ġgen',
    'etic',
    'Ġtechn',
    'ologies',
    'Ġupgr',
    'ade',
    'row',
    's',
    'Ġdevelop',
    'er',
    'ĠJ',
    'osh',
    'ĠDis',
    'ney',
    'erv',
    'ed',
    'ip',
    'al',
    'Ġun',
    'ex',
    'Ġbare',
    'ly',
    't',
    'hen',
    'ĠP',
    'ub',
    'Ġill',
    'ness',
    'et',
    'ary',
    'ĠB',
    'al',
    'Ġp',
    'atch',
    'Ġbut',
    't',
    'Ġst',
    'upid',
    'ĠD',
    'og',
    'ĠD',
    'allas',
    'f',
    'ront',
    'ie',
    'ce',
    'Ġprot',
    'ests',
    'Ġch',
    'at',
    'oen',
    'ix',
    'Ġw',
    'ing',
    'Ġpar',
    'liament',
    'Ġ7',
    '7',
    'ose',
    'xual',
    'Ġre',
    'nder',
    'pt',
    'ions',
    'ĠCo',
    'ast',
    'os',
    'a',
    'ĠG',
    'reg',
    'h',
    'op',
    'ĠMan',
    'agement',
    'Ġbit',
    'coin',
    'Ġrec',
    'over',
    'Ġincor',
    'por',
    'or',
    'ne',
    'ĠUs',
    'ing',
    'Ġpre',
    'ced',
    'Ġthreat',
    'ened',
    'Ġspirit',
    'ual',
    'ĠE',
    'vent',
    'ĠF',
    'red',
    'Ġadvert',
    'ising',
    'Ġimprove',
    'ments',
    'ĠC',
    'ustom',
    'Ġer',
    'rors',
    'Ġsens',
    'itive',
    'ĠN',
    'avy',
    'Ġcre',
    'am',
    'L',
    'ook',
    'Ġex',
    'clusive',
    'Ġcomp',
    'rehens',
    'Ġde',
    'leg',
    'Ġcon',
    'ce',
    'Ġrem',
    'em',
    'Ġstruct',
    'ures',
    'Ġst',
    'ored',
    'N',
    'D',
    'Ġ1',
    '000',
    'U',
    'P',
    'ĠB',
    'udd',
    'A',
    'F',
    'w',
    'oman',
    'ĠAcad',
    'emy',
    'ð',
    'Ł',
    'se',
    'a',
    'Ġtem',
    'porary',
    'Ab',
    'out',
    'es',
    'ters',
    'Ġtick',
    'ets',
    'Ġposs',
    'ess',
    'in',
    'ch',
    'o',
    'z',
    'Ġl',
    'a',
    'Ġcontract',
    's',
    'Ġun',
    'p',
    'Ġc',
    'ig',
    'ĠK',
    'at',
    'ult',
    'ural',
    'as',
    'm',
    'Ġmount',
    'ain',
    'ĠCapt',
    'ain',
    'St',
    'ep',
    'm',
    'aking',
    'ĠSp',
    'ain',
    'Ġequ',
    'ally',
    'Ġl',
    'ands',
    'at',
    'ers',
    'Ġreject',
    'ed',
    'er',
    'a',
    'im',
    'm',
    'ri',
    'x',
    'C',
    'D',
    'Ġtrans',
    'action',
    'g',
    'ener',
    'less',
    'ly',
    'Ġ|',
    '|',
    'Ġc',
    'os',
    'ĠHen',
    'ry',
    'Ġprov',
    'isions',
    'Ġg',
    'ained',
    'Ġdirect',
    'ory',
    'Ġra',
    'ising',
    'ĠS',
    'ep',
    'ol',
    'en',
    'ond',
    'er',
    'Ġcon',
    'sole',
    'in',
    'st',
    'Ġb',
    'om',
    'Ġunc',
    'ertain',
    '1',
    '50',
    'ock',
    'ing',
    'Ġmeas',
    'ured',
    'Ġpl',
    'ain',
    'Ġse',
    'ats',
    'Ġd',
    'ict',
    'S',
    'L',
    'af',
    'e',
    'Ġest',
    'imate',
    'iz',
    'on',
    'at',
    'hered',
    'Ġcontribut',
    'ed',
    'Ġep',
    'isodes',
    'omm',
    'od',
    'G',
    'r',
    'AN',
    'T',
    'Ġ6',
    '9',
    'G',
    'ener',
    'Ġ2',
    '50',
    'vious',
    'ly',
    'rog',
    'en',
    'Ġterror',
    'ism',
    'Ġmove',
    'ments',
    'ent',
    'le',
    'oun',
    'ce',
    'ĠS',
    'oul',
    'Ġpre',
    'v',
    'ĠT',
    'able',
    'act',
    's',
    'ri',
    'ors',
    't',
    'ab',
    'Ġsuff',
    'er',
    'Ġn',
    'erv',
    'Ġmain',
    'stream',
    'ĠW',
    'olf',
    'Ġfranch',
    'ise',
    'b',
    'at',
    'Ġdem',
    'ands',
    'Ġag',
    'enda',
    'Ġdo',
    'zen',
    'Ġclin',
    'ical',
    'iz',
    'ard',
    'ĠO',
    'p',
    't',
    'd',
    'Ġvis',
    'ited',
    'ĠPer',
    'haps',
    'Ġact',
    'or',
    'Ġde',
    'lic',
    'Ġcont',
    'ribute',
    'Ġin',
    'ject',
    'ĠE',
    's',
    'ac',
    'co',
    'Ġlist',
    'ening',
    'Ġcon',
    'gress',
    'epend',
    'ent',
    'Ġprem',
    'ium',
    'Ġ7',
    '6',
    'ĠIr',
    'ish',
    'Ġass',
    'igned',
    'ĠPh',
    'ys',
    'Ġworld',
    'wide',
    'Ġnarr',
    'ative',
    'ot',
    'ype',
    'm',
    'ont',
    'b',
    'ase',
    'ĠB',
    'owl',
    'ĠAdminist',
    'ration',
    'Ġrel',
    'ation',
    'ĠE',
    'V',
    'C',
    'P',
    'Ġco',
    'vers',
    'Ġ7',
    '8',
    'Ġcert',
    'ific',
    'Ġgr',
    'ass',
    'Ġ0',
    '4',
    'pir',
    'acy',
    'ir',
    'a',
    'Ġengine',
    'ering',
    'ĠM',
    'ars',
    'Ġun',
    'employ',
    'ĠFore',
    'ign',
    'st',
    'ract',
    'Ġv',
    'en',
    'Ġst',
    'eal',
    'Ġrepl',
    'ied',
    'Ġult',
    'imate',
    'Ġtit',
    'les',
    'd',
    'ated',
    'Ġj',
    'oy',
    'a',
    'us',
    'Ġhy',
    'per',
    'ak',
    'u',
    'Ġoffic',
    'ially',
    'ĠPro',
    'duct',
    'Ġdifficult',
    'y',
    'per',
    'or',
    'Ġresult',
    'ed',
    'rib',
    'ed',
    'l',
    'ink',
    'wh',
    'o',
    '~~',
    '~~',
    'ĠSpe',
    'ed',
    'ĠV',
    'iet',
    'W',
    'ind',
    'ĠBar',
    'ack',
    'Ġrestrict',
    'ions',
    'ĠSh',
    'are',
    'Ġ199',
    '5',
    'ition',
    'ally',
    'Ġbeaut',
    'y',
    'op',
    't',
    'Ġm',
    'aps',
    'ĠC',
    'R',
    'ĠN',
    'ation',
    'ĠCru',
    'z',
    'W',
    'ill',
    'Ġelectric',
    'ity',
    'Ġor',
    'g',
    'Ġb',
    'urd',
    'Ġviol',
    'ation',
    'Ġus',
    'age',
    'Ġper',
    'mit',
    'ĠCh',
    'ron',
    'ĠF',
    'ant',
    'Ġn',
    'aturally',
    'Ġ0',
    '7',
    'Ġth',
    'rown',
    'ĠAw',
    'oken',
    'Ġal',
    'ien',
    'ĠHer',
    'o',
    'ĠK',
    'ent',
    'ĠR',
    'ick',
    'ri',
    'ke',
    'Ġp',
    'ace',
    '},',
    '{"',
    'G',
    'L',
    'Ġpo',
    'ison',
    'ĠT',
    'ower',
    'Ġform',
    'al',
    'al',
    'ysis',
    'Ġgen',
    'uine',
    'Ġk',
    'il',
    'a',
    'ver',
    'Ġproced',
    'ure',
    'ĠPro',
    'p',
    'intend',
    'o',
    'ĠM',
    'ain',
    'as',
    'ant',
    'Ġtr',
    'ained',
    'G',
    'ame',
    'ĠL',
    'oad',
    'ĠM',
    'A',
    'Ġcru',
    'cial',
    'Ġle',
    'ts',
    'ĠF',
    'R',
    'Ġch',
    'ampion',
    '1',
    '01',
    'ĠCon',
    'ference',
    'Ġwrit',
    'ers',
    'Ġconnect',
    'ions',
    'Ġo',
    'kay',
    'ir',
    'ms',
    'ĠR',
    'and',
    'Ġenc',
    'ounter',
    'ĠB',
    'uff',
    'Ġachie',
    'ved',
    'Ġche',
    'cks',
    'isc',
    'ons',
    'Ġassist',
    'ant',
    'Ġwhen',
    'ever',
    'ĠA',
    'ccess',
    'ĠU',
    'r',
    'b',
    'in',
    'Ġcl',
    'ock',
    'is',
    'p',
    'op',
    'her',
    'Ġb',
    'orrow',
    'Ġm',
    'ad',
    'Ġperson',
    'ality',
    'on',
    'ly',
    'IS',
    'T',
    'ab',
    'ama',
    'Ġg',
    'ains',
    'Ġcommon',
    'ly',
    'Ġter',
    'r',
    'Ġhyp',
    'ot',
    'Ġre',
    'ly',
    'Ġt',
    'iss',
    'iscons',
    'in',
    'Ġrid',
    'ic',
    'f',
    'unction',
    'ĠO',
    'regon',
    'Ġun',
    'com',
    'r',
    'ating',
    'el',
    'and',
    'ĠN',
    'C',
    'Ġm',
    'oon',
    'ann',
    'on',
    'Ġvulner',
    'able',
    'ut',
    'ive',
    'ÂłÂł',
    'ÂłÂł',
    'ĠRad',
    'io',
    'Ġw',
    'estern',
    'se',
    'ct',
    'ĠT',
    'ony',
    'Ġocc',
    'urs',
    'ĠO',
    's',
    'ĠH',
    'on',
    'Ã',
    'Ń',
    'Ġv',
    'essel',
    'ĠScot',
    'land',
    'Ġdiscrim',
    'ination',
    'Ġsubsequ',
    'ent',
    'st',
    'ring',
    'Ġfant',
    'asy',
    'ĠSh',
    'adow',
    'Ġtest',
    'im',
    'W',
    'E',
    'it',
    'i',
    'r',
    'as',
    'Ġbo',
    'at',
    'Ġmar',
    'ks',
    'Ġord',
    'inary',
    'Ġre',
    'n',
    'Ġrepresent',
    'ative',
    'Ġpet',
    'ition',
    'Ġ7',
    '3',
    'Ġad',
    'venture',
    'Ġign',
    'ore',
    'ĠPhil',
    'adelphia',
    'ĠS',
    'av',
    'V',
    'P',
    'Ġfact',
    'ory',
    'Ġt',
    'asks',
    'Ġdep',
    'ression',
    'z',
    'ed',
    '................',
    '................',
    'ĠSt',
    'orm',
    'Ġc',
    'ogn',
    'Ġelig',
    'ible',
    'Ġredu',
    'cing',
    'v',
    'ia',
    'Ġ0',
    '5',
    'Ġstri',
    'king',
    'Ġdoll',
    'ar',
    'h',
    'o',
    'O',
    'V',
    'Ġinstr',
    'ument',
    'Ġphilosoph',
    'y',
    'ĠMo',
    'ore',
    'ĠA',
    'venue',
    'Ġrul',
    'ed',
    'ĠFr',
    'ont',
    'IN',
    'E',
    'ĠM',
    'ah',
    'Ġscen',
    'ario',
    'ĠNAS',
    'A',
    'Ġen',
    'orm',
    'Ġdeb',
    'ut',
    'Ġte',
    'a',
    'T',
    'oday',
    'Ġabs',
    'ence',
    'S',
    'im',
    'Ġh',
    'am',
    'le',
    'ep',
    'Ġt',
    'ables',
    'ĠHe',
    'art',
    'M',
    'I',
    'K',
    'e',
    're',
    'qu',
    'V',
    'D',
    'm',
    'ap',
    'Ġchair',
    'man',
    'Ġp',
    'ump',
    'Ġrapid',
    'ly',
    'v',
    'i',
    'Ġsubstant',
    'ial',
    'E',
    'P',
    'd',
    'es',
    'ch',
    'ant',
    'ili',
    'pp',
    'ĠS',
    'anta',
    'ri',
    'ers',
    'anche',
    'ster',
    'L',
    'oad',
    'ĠC',
    'ase',
    'Ġsa',
    'ving',
    'Ġ7',
    '4',
    'ĠA',
    'FP',
    'er',
    'ning',
    'oun',
    'ced',
    'ĠMin',
    'nesota',
    'ĠW',
    'as',
    'Ġrec',
    'ru',
    'Ġassess',
    'ment',
    'ĠB',
    'ron',
    'U',
    'E',
    'Ġdynam',
    'ic',
    'Ġf',
    'urn',
    'ul',
    'ator',
    'Ġprop',
    'ag',
    'h',
    'igh',
    'Ġacc',
    'ommod',
    'Ġst',
    'ack',
    'ĠS',
    'us',
    'w',
    'rit',
    'Ġre',
    'ven',
    'ĠGod',
    'd',
    'ĠZeal',
    'and',
    'ab',
    's',
    'Ġbr',
    'ut',
    'Ġper',
    'pet',
    'h',
    'ot',
    'Ġhard',
    'ly',
    'ĠB',
    'urn',
    'ãĤ',
    '¹',
    'Ġst',
    'y',
    'Ġtrans',
    'actions',
    'Ġg',
    'ate',
    'Ġsc',
    'reens',
    'Ġsub',
    'mitted',
    'Ġ1',
    '01',
    'Ġlangu',
    'ages',
    'ugh',
    't',
    'em',
    'en',
    'Ġfall',
    's',
    'Ġc',
    'oc',
    'Ĥ',
    '¬',
    'Ġstri',
    'kes',
    'p',
    'a',
    'Ġdel',
    'iber',
    'ĠI',
    'M',
    'Ġrel',
    'ax',
    'ann',
    'els',
    'ĠSen',
    'ator',
    'Ġext',
    'rem',
    'Ġ}',
    ',',
    'ĠDe',
    'b',
    'Ġbe',
    'll',
    'Ġdis',
    'order',
    'c',
    'ut',
    'Ġi',
    'OS',
    'Ġl',
    'ocked',
    'Ġem',
    'issions',
    'Ġshort',
    'ly',
    '"',
    ']',
    'ĠJud',
    'ge',
    'ĠS',
    'ometimes',
    'Ġr',
    'ival',
    'Ġd',
    'ust',
    'Ġreach',
    'ing',
    'F',
    'ile',
    'Â¯Â¯',
    'Â¯Â¯',
    'ino',
    'is',
    'ĠJ',
    'ason',
    'Ġs',
    'atell',
    'are',
    't',
    'Ġst',
    'ations',
    'Ġag',
    'ric',
    'ĠTechn',
    'ology',
    'com',
    'es',
    'ĠUn',
    'fortunately',
    'ĠChild',
    'ren',
    'Ġappl',
    'ies',
    'ast',
    'ed',
    'Ġan',
    'ger',
    'ail',
    'ability',
    'ĠDam',
    'age',
    'Ġcomp',
    'are',
    'ĠStand',
    'ard',
    'Ġaim',
    'ed',
    'ĠB',
    'a',
    'angu',
    'age',
    'Ġreg',
    'ulation',
    'Ġj',
    'ury',
    'Ġair',
    'port',
    'Ġse',
    'ctions',
    'ĠPr',
    'ince',
    'em',
    'ed',
    'Ġmedic',
    'ine',
    'Ġh',
    'itting',
    'Ġsp',
    'ark',
    'ol',
    'ves',
    'Ġad',
    's',
    'St',
    'ate',
    'Ġfood',
    's',
    'Ġrepl',
    'acement',
    'Ġch',
    'icken',
    'Ġlow',
    'est',
    'Ġmind',
    's',
    'Ġinvol',
    'ves',
    'u',
    'i',
    'Ġarr',
    'ang',
    'Ġproced',
    'ures',
    'ĠWh',
    'ich',
    'ivers',
    'ary',
    'Ġb',
    'ills',
    'Ġimprove',
    'ment',
    'Ġin',
    'ev',
    'Ġexpect',
    'ations',
    'Ġintellect',
    'ual',
    'Ġsp',
    'aces',
    'Ġmechan',
    'ism',
    '2',
    '50',
    'bre',
    'ak',
    'ĠZ',
    'e',
    'ĠT',
    'enn',
    'ĠB',
    'alt',
    'Ġbar',
    'rel',
    'Ġstat',
    'ic',
    'man',
    'n',
    'Pol',
    'ice',
    'Ġt',
    'ips',
    'Ġhand',
    'ling',
    'c',
    'us',
    'od',
    'ed',
    'il',
    'ton',
    'ir',
    'y',
    'Ġjournal',
    'ists',
    'our',
    'se',
    'Ġcom',
    'ic',
    'Ġnom',
    'ine',
    'IT',
    'Y',
    'Ġvers',
    'us',
    'Ġlo',
    'op',
    'Ġsur',
    'f',
    'ĠInd',
    'ust',
    'ĠHun',
    'ter',
    'Ġbelief',
    's',
    'is',
    'an',
    'Ġset',
    'up',
    'Ġbre',
    'w',
    'im',
    'age',
    'Ġcomput',
    'ers',
    'f',
    'ol',
    '}',
    ',"',
    'ĠMed',
    'al',
    'Ġtax',
    'p',
    'Ġdisplay',
    'ed',
    'Ġg',
    'rav',
    'Ġf',
    'iscal',
    'M',
    'on',
    'ĠMos',
    'cow',
    'ĠK',
    'ong',
    'ĠCent',
    're',
    'Ġcamer',
    'as',
    'ĠMr',
    's',
    'ĠH',
    'ay',
    'Ġa',
    'ver',
    'ĠK',
    'elly',
    'p',
    'y',
    'Ġrequire',
    'ment',
    'Ġent',
    'itled',
    'omb',
    'ie',
    'Ġsh',
    'adow',
    'ag',
    'ic',
    'ĠA',
    'k',
    'Ġel',
    'ite',
    'Ġdiv',
    'ided',
    'Ġhead',
    'ing',
    'Ġcop',
    'ies',
    'Ġloss',
    'es',
    'Ġv',
    'it',
    'k',
    'ed',
    'ĠB',
    'ry',
    'Ġan',
    's',
    'ĠSte',
    'am',
    'Ġrep',
    'orter',
    'he',
    'im',
    'ĠIt',
    'em',
    'Ġsuper',
    'ior',
    'd',
    'on',
    'ere',
    'nt',
    'Ã',
    '¶',
    'Ġtherap',
    'y',
    'Ġpe',
    'ak',
    'ĠMod',
    'el',
    'Ġl',
    'ying',
    'Ġg',
    'am',
    'z',
    'er',
    'r',
    'itten',
    'Ġrespons',
    'es',
    'Ġconsider',
    'ation',
    'ĠB',
    'ible',
    'Ġl',
    'oyal',
    'Ġinst',
    'ant',
    'Ġp',
    'm',
    'ĠFore',
    'st',
    'Ã',
    '¼',
    'Ġext',
    'end',
    'Ġconv',
    'icted',
    'Ġfound',
    'er',
    'Ġconv',
    'in',
    'ĠO',
    'ak',
    'che',
    'ck',
    'Ġsch',
    'olars',
    'p',
    'ed',
    'Ġover',
    'se',
    'T',
    'op',
    'c',
    'ount',
    'ĠAr',
    'k',
    'Â',
    '·',
    'Ġ0',
    '6',
    'ĠL',
    'A',
    'm',
    'd',
    'ĠLat',
    'in',
    'im',
    'ental',
    'ĠC',
    'PU',
    'Ġsubst',
    'ance',
    'Ġminor',
    'ity',
    'Ġmanufact',
    'uring',
    'E',
    'r',
    'ocol',
    'ate',
    'Ġatt',
    'ended',
    'ĠMan',
    'ager',
    'r',
    'ations',
    'Ġappreci',
    'ate',
    'om',
    'y',
    'GB',
    'T',
    'id',
    'ency',
    'B',
    'L',
    'Ġguarant',
    'ee',
    'pos',
    'ition',
    'Ġo',
    'cean',
    'clud',
    'e',
    'Ġhead',
    'ed',
    'Ġt',
    'ape',
    'Ġlo',
    'ose',
    'Ġlog',
    'ic',
    'Ġpro',
    'ven',
    'Ġsp',
    'ir',
    'Ġad',
    'mit',
    'is',
    'a',
    'Ġinvestig',
    'ate',
    'Ġ199',
    '4',
    'sy',
    'lv',
    'ĠL',
    'ost',
    'c',
    'est',
    'Ġ7',
    '1',
    'Ġrequest',
    'ed',
    'Ġwind',
    'ows',
    'ĠPok',
    'Ã©',
    'ĠWith',
    'out',
    'M',
    'et',
    'Ġbehavi',
    'our',
    'Ġread',
    'er',
    'Ġh',
    'ung',
    'ĠKe',
    'ep',
    'Ġro',
    'les',
    'Ġimplement',
    'ed',
    'Ġbl',
    'ank',
    'Ġserv',
    'es',
    'ĠJ',
    'ay',
    'Ġc',
    'ited',
    'ĠF',
    'riend',
    'prof',
    'it',
    'ap',
    'on',
    'Ġrep',
    'air',
    'it',
    'em',
    'arr',
    'ass',
    'Ġcrit',
    'ics',
    'ad',
    'i',
    'ĠF',
    'ather',
    'Ġsh',
    'out',
    'Ġf',
    'ool',
    'Ġ8',
    '8',
    'Ġprodu',
    'cing',
    'Ġl',
    'ib',
    'Ġround',
    's',
    'Ġcirc',
    'le',
    'Ġpre',
    'par',
    'Ġsub',
    'mit',
    'Ġn',
    'ic',
    'mor',
    'row',
    'ãĥ',
    '«',
    'U',
    'nder',
    'Ġv',
    'ital',
    'ater',
    'n',
    'Ġpass',
    'word',
    'Ġpublic',
    'ation',
    'Ġprom',
    'inent',
    'Ġspeak',
    's',
    'Ġb',
    'ars',
    'Ġde',
    'eper',
    'ĠM',
    'ill',
    'port',
    'ed',
    'Ġw',
    'id',
    'Ġbut',
    'ter',
    'Ġsm',
    'oking',
    'Ġindic',
    'ates',
    'K',
    'ey',
    'rop',
    'ri',
    'ĠF',
    'ile',
    'all',
    'ing',
    'ast',
    'ing',
    'ĠR',
    'us',
    'Ġad',
    'j',
    'Ġ7',
    '9',
    'av',
    'al',
    'Ġpres',
    'um',
    'bur',
    'gh',
    'on',
    'ic',
    'Ġf',
    'ur',
    'Ġpoll',
    's',
    'ik',
    'a',
    'Ġsecond',
    'ary',
    'Ġmon',
    'ster',
    'ig',
    's',
    'ĠCur',
    'rent',
    'E',
    'vent',
    'Ġowners',
    'hip',
    'end',
    'ar',
    'Ġarri',
    've',
    'ĠT',
    'ax',
    'Ġn',
    'ull',
    'ĠPri',
    'v',
    'Ġth',
    'ro',
    'Ġk',
    'iss',
    'c',
    'at',
    'Ġup',
    'set',
    'ang',
    'le',
    'it',
    'ches',
    'ect',
    'or',
    'olog',
    'ists',
    'ĠGal',
    'axy',
    'Ġcor',
    'ruption',
    'Ġh',
    'int',
    'ent',
    'er',
    'ĠH',
    'ospital',
    'Ġgreat',
    'ly',
    'Ġbeg',
    'un',
    'es',
    'y',
    'Ġso',
    'il',
    'ĠAnt',
    'on',
    'Ġmain',
    'tenance',
    'ãĥ',
    '©',
    'Ġdo',
    'zens',
    'Ġhuman',
    'ity',
    'ĠAl',
    'abama',
    'Ġr',
    'om',
    'w',
    'orth',
    'ap',
    'ing',
    'sylv',
    'ania',
    'l',
    'ah',
    'Ġg',
    'athered',
    'G',
    'A',
    'Ġattack',
    'ing',
    'f',
    'ound',
    'ĠSqu',
    'are',
    'Ġar',
    'bit',
    'ict',
    'ions',
    'ĠW',
    'isconsin',
    'Ġd',
    'ance',
    'ĠS',
    'aint',
    'arch',
    'y',
    'Ġbase',
    'ball',
    'Ġcontribut',
    'ions',
    'Ġliter',
    'ature',
    'Ġex',
    'ha',
    'per',
    'ty',
    't',
    'est',
    'Ġb',
    'ab',
    'Ġcontain',
    'er',
    'let',
    'ter',
    'Ġfall',
    'en',
    'Ġwebs',
    'ites',
    'Ġbott',
    'le',
    'ĠS',
    'ac',
    'Ġbre',
    'ast',
    'ĠP',
    'L',
    'Ġveter',
    'an',
    'Ġinterview',
    's',
    'ĠA',
    'le',
    'Ġb',
    'anned',
    'eng',
    'ers',
    'ĠRev',
    'olution',
    'in',
    'th',
    'Ġconc',
    'erning',
    'IV',
    'E',
    'Ġexp',
    'enses',
    'ĠMatt',
    'hew',
    'ĠColumb',
    'ia',
    'd',
    's',
    'ist',
    'ance',
    'Ġent',
    'ity',
    '..',
    '."',
    'Ġrel',
    'iable',
    'Ġpar',
    'alle',
    'ĠChrist',
    'ians',
    'Ġopin',
    'ions',
    'Ġin',
    'du',
    'l',
    'ow',
    'Ġcompet',
    'e',
    'Ġth',
    'orough',
    'Ġemploy',
    'ed',
    'Ġestablish',
    'ment',
    'ig',
    'en',
    'ĠC',
    'ro',
    'Ġlawy',
    'ers',
    'ĠSt',
    'ation',
    'T',
    'E',
    'ĠL',
    'ind',
    'ĠP',
    'ur',
    'it',
    'ary',
    'Ġeffic',
    'iency',
    'âĢ',
    'Ĳ',
    'ĠL',
    'y',
    'Ġm',
    'ask',
    'Ġdis',
    'aster',
    'Ġag',
    'es',
    'ER',
    'E',
    'es',
    'is',
    'ĠH',
    'old',
    'Ġcas',
    'ual',
    'b',
    'led',
    'Ġen',
    'abled',
    'ĠEn',
    'vironment',
    'ĠInt',
    'elligence',
    'i',
    'per',
    'ĠM',
    'ap',
    'ĠB',
    'E',
    'Ġemer',
    'ged',
    'is',
    'dom',
    'Ġc',
    'abin',
    'Ġregist',
    'ration',
    'Ġfing',
    'ers',
    'Ġro',
    'ster',
    'Ġfram',
    'ework',
    'ĠDo',
    'ctor',
    'et',
    'ts',
    'Ġtransport',
    'ation',
    'Ġaware',
    'ness',
    'H',
    'er',
    'Ġattempt',
    'ing',
    'O',
    'ff',
    'ĠSt',
    'ore',
    'ÃĥÃĤÃĥÃĤ',
    'ÃĥÃĤÃĥÃĤ',
    'ĠK',
    'now',
    'Ġdef',
    'ence',
    'Ġsc',
    'an',
    'ĠT',
    'en',
    'ĠCh',
    'air',
    'ĠP',
    'H',
    'ĠAtl',
    'anta',
    'Ġfuck',
    'ing',
    'Ġans',
    'wered',
    'b',
    'n',
    'ĠK',
    'ar',
    'Ġcateg',
    'ories',
    'Ġr',
    'ational',
    'Ġc',
    'ust',
    'Ġrob',
    'ot',
    'Ġcorrect',
    'ly',
    'Ġg',
    'if',
    'Ġgraph',
    'ics',
    'm',
    'ic',
    'Ġground',
    's',
    'ĠO',
    'pp',
    'i',
    'ate',
    'Ġdist',
    'ributed',
    'Ġsan',
    'ctions',
    'Ġchalleng',
    'ing',
    'ut',
    'o',
    'Ġingred',
    'ients',
    'Ġinv',
    'ited',
    'Ġfound',
    'ed',
    'ĠRe',
    'qu',
    'd',
    'ed',
    'Ġb',
    'owl',
    'Ġbrother',
    's',
    'ĠH',
    'a',
    'I',
    'O',
    'Ġw',
    'ages',
    'im',
    'ore',
    'oc',
    'ial',
    'Ġse',
    'ed',
    'ative',
    'ly',
    'Ġaddress',
    'es',
    'ĠI',
    'owa',
    'ab',
    'eth',
    'Ġatt',
    'itude',
    'is',
    'd',
    'ch',
    'ild',
    'Ġm',
    'ole',
    'Ġdisco',
    'very',
    'y',
    'ard',
    'B',
    'r',
    'Ġ8',
    '2',
    'Ġsuppl',
    'ies',
    'ell',
    'ing',
    'Ġdist',
    'ingu',
    'C',
    'R',
    'Ġre',
    'cept',
    'Ġ',
    'vert',
    'Ġsw',
    'im',
    'b',
    'ec',
    'd',
    'oor',
    'ĠY',
    'eah',
    'Ġg',
    'al',
    'Ġinter',
    'act',
    'ĠE',
    'SP',
    'ĠC',
    'S',
    'amp',
    's',
    'Ġconvin',
    'ced',
    'Ġobject',
    'ive',
    'Ġdis',
    'h',
    'ĠPhot',
    'os',
    'l',
    'ad',
    'Ġdownt',
    'own',
    'o',
    'il',
    'in',
    'ction',
    'Ġto',
    'morrow',
    'ĠC',
    'OM',
    'Ġsurv',
    'ival',
    'sh',
    'ot',
    'Ġsett',
    'lement',
    'C',
    'ons',
    'ĠX',
    'box',
    'int',
    'erest',
    'ĠS',
    'M',
    'arg',
    'o',
    'en',
    'ess',
    'Ġeth',
    'nic',
    'b',
    'ered',
    'M',
    'in',
    'ĠT',
    'ok',
    'Ġinc',
    'ent',
    'ĠComm',
    'and',
    'Ġmain',
    'tained',
    'Ġbreak',
    's',
    'br',
    'idge',
    'at',
    'ar',
    'ag',
    'g',
    'ĠF',
    'inally',
    'un',
    'icip',
    'ĠO',
    'nt',
    'le',
    'ft',
    'Ġrecogn',
    'ition',
    'Ġ*',
    '/',
    'ĠP',
    'ers',
    'Ġwe',
    'lf',
    'Ġaddress',
    'ed',
    'ĠK',
    'ansas',
    'Ġvir',
    'us',
    'Ġwhere',
    'as',
    'Ġp',
    'apers',
    'ram',
    's',
    'ĠMin',
    'istry',
    'Ġple',
    'asure',
    'Ġacqu',
    'ired',
    'Ġd',
    'uration',
    'j',
    'pg',
    'Ġcal',
    'm',
    'ĠN',
    'HL',
    'Ġburn',
    'ing',
    'Ġfold',
    'er',
    'ick',
    'ed',
    'ĠP',
    'y',
    'ĠIll',
    'inois',
    'Cl',
    'ass',
    'ĠGodd',
    'ess',
    'Ġperform',
    'ing',
    'Ġwelf',
    'are',
    'j',
    'ar',
    'In',
    'ter',
    'Ġl',
    'in',
    'Ġenh',
    'ance',
    'Ġnot',
    'ion',
    'f',
    'are',
    'yp',
    'es',
    'ĠAre',
    'a',
    'Ġcann',
    'abis',
    'ĠDie',
    'go',
    'f',
    's',
    'ĠM',
    'anchester',
    'com',
    'm',
    'in',
    'ite',
    'Ġcover',
    'ing',
    'ĠS',
    'ound',
    'Ġ19',
    '60',
    'Ġ8',
    '4',
    'e',
    'lect',
    'z',
    'ing',
    'Ġcitiz',
    'en',
    'Ġph',
    'ones',
    'Ġr',
    'aid',
    'Ġign',
    'ored',
    'ĠOb',
    'ject',
    'Ġu',
    'pload',
    'c',
    'ard',
    'Ġmod',
    'ified',
    'Ġroom',
    's',
    'ia',
    'h',
    'r',
    'ange',
    'he',
    'ast',
    'ach',
    'us',
    'Ġsuggest',
    'ing',
    'âĢ',
    'ĭ',
    'gr',
    'ade',
    'E',
    'l',
    'Ġclot',
    'hing',
    'Ġr',
    'h',
    'ĠH',
    'an',
    'un',
    'ity',
    'en',
    'cing',
    'ĠAust',
    'in',
    'sec',
    'ution',
    't',
    'ra',
    'd',
    'em',
    'ĠQ',
    'ual',
    'Ġhe',
    'aven',
    'Ġst',
    'ages',
    'Ġw',
    'edd',
    'pl',
    'us',
    'ific',
    'ial',
    'ĠIm',
    'm',
    'ĠH',
    'o',
    'iet',
    'ies',
    'Ġphr',
    'ase',
    'Ġbr',
    'ill',
    'act',
    'ory',
    'Ġprov',
    'iders',
    'Ġsil',
    'ence',
    'Ġa',
    'er',
    'ĠA',
    'I',
    'ĠAd',
    'venture',
    'Ġplatform',
    's',
    'Ġdemonstr',
    'ated',
    'Ġinter',
    'f',
    'ing',
    'ton',
    'Ġr',
    'aces',
    'Ġgr',
    'ade',
    'ult',
    'ane',
    'ĠTh',
    'rough',
    'f',
    'alse',
    'Ġb',
    'ow',
    'ĠA',
    'B',
    'Ġfl',
    'avor',
    'Ġhistor',
    'ic',
    'g',
    'ov',
    'Ġcol',
    'our',
    'Ġview',
    'ed',
    'ĠEm',
    'ail',
    'el',
    'come',
    'Ġinter',
    'vention',
    'Ġd',
    'iversity',
    'Ġperiod',
    's',
    'Ġre',
    'verse',
    'ĠV',
    'ery',
    'Ġqu',
    'ote',
    'ĠLe',
    'ft',
    'th',
    'rough',
    'Ġsc',
    'rew',
    'Ġland',
    'ing',
    'Ġp',
    'ill',
    'Ġw',
    'et',
    'Ġprot',
    'esters',
    'Ġrepe',
    'at',
    'av',
    'ed',
    'er',
    'k',
    'Ġsal',
    'ary',
    'ĠPenn',
    'sylvania',
    'St',
    'ill',
    'Ġmay',
    'or',
    'Ġkit',
    'chen',
    'Ġfeat',
    'uring',
    'ĠM',
    'useum',
    'ĠT',
    'ournament',
    'ĠF',
    'al',
    'Ġser',
    'vers',
    'U',
    'C',
    'Ġany',
    'body',
    'im',
    'g',
    'ĠTr',
    'ade',
    'ixt',
    'ure',
    'the',
    'less',
    'Ġfin',
    'ance',
    'Ġcl',
    'osing',
    'ĠPat',
    'ri',
    'i',
    'ac',
    'ab',
    'el',
    'Ġ>',
    '>',
    'or',
    'ous',
    'Ġf',
    'irms',
    'sc',
    'reen',
    'un',
    'a',
    'Ġemb',
    'arrass',
    'ul',
    'se',
    'Ġlet',
    'ting',
    'Ġth',
    'rew',
    'ile',
    'y',
    'Ġch',
    'annels',
    'l',
    'an',
    'ĠVeg',
    'as',
    'Ġse',
    'ar',
    'Ġfant',
    'astic',
    'ar',
    're',
    'uzz',
    'le',
    'ĠD',
    'er',
    'Th',
    'ose',
    'Ġsw',
    'ing',
    'Ġshe',
    'et',
    'ind',
    'ex',
    'co',
    'ver',
    'og',
    'an',
    'Ġvari',
    'ables',
    'ĠTe',
    'ch',
    'Ġsp',
    'oken',
    'ac',
    'hel',
    'ĠD',
    'a',
    'ĠMount',
    'ain',
    'Ġload',
    'ed',
    'Ġfoot',
    'age',
    'vers',
    'ion',
    'Ġun',
    'l',
    'ĠPh',
    'oenix',
    'Ġthrow',
    'ing',
    'Ġf',
    'iring',
    'Ġtrack',
    'ing',
    'Ġw',
    'idth',
    'Ġstrugg',
    'ling',
    'ro',
    'oms',
    'ot',
    'ion',
    'Ġmonth',
    'ly',
    'ĠSer',
    'ver',
    'Ġegg',
    's',
    'op',
    'en',
    'M',
    'C',
    'Ġ199',
    '3',
    'Ġh',
    'ired',
    'Ġstay',
    'ed',
    'ĠAll',
    'en',
    'Ġst',
    'ro',
    'Ġ9',
    '8',
    'st',
    'ep',
    'ĠTurk',
    'ish',
    'Ġfab',
    'ric',
    'ist',
    'ing',
    'ĠD',
    'om',
    'Ġd',
    'ates',
    'Ġpr',
    'on',
    'Ġbasket',
    'ball',
    'Ġl',
    'ucky',
    'ĠArab',
    'ia',
    'Ġassum',
    'ed',
    'est',
    'y',
    'Ġaff',
    'airs',
    'Ġgl',
    'ad',
    'ĠInd',
    'eed',
    'ĠF',
    'A',
    'ĠW',
    'ord',
    'Ġjo',
    'ining',
    'if',
    'ice',
    'p',
    'read',
    'ir',
    'ts',
    'ĠSe',
    'lect',
    'Ġpop',
    'ulations',
    'aw',
    'are',
    'Ġn',
    'ose',
    'Ġcompl',
    'aints',
    'st',
    'art',
    'Ġsc',
    'oring',
    'Th',
    'anks',
    'Ġmin',
    'ing',
    'Ġvisit',
    'ors',
    'S',
    'H',
    'Ġdam',
    'aged',
    'Ġcharacter',
    'istics',
    'ĠP',
    'ent',
    'D',
    'C',
    'Ġ8',
    '3',
    'ĠS',
    'ix',
    'r',
    'ates',
    'Ġfl',
    'ags',
    'ĠB',
    'rew',
    'd',
    'og',
    'M',
    'ark',
    '//',
    '//',
    'Ġexec',
    'ution',
    'Ġj',
    'oke',
    'ph',
    'ones',
    'Ġtestim',
    'ony',
    'Ġob',
    'st',
    'Q',
    'L',
    'ĠC',
    'ut',
    'Ġstud',
    'ied',
    'ĠN',
    'intendo',
    'ick',
    'et',
    'ĠN',
    'BC',
    'Ġl',
    'ad',
    'ĠB',
    'ra',
    'ĠM',
    'oh',
    'Ġk',
    'ernel',
    'Ġoverwhel',
    'ming',
    'Ġag',
    'ed',
    'Ġapplic',
    'able',
    'ĠC',
    'ond',
    'Ġroad',
    's',
    'ĠBl',
    'ock',
    'm',
    'ade',
    'od',
    'ge',
    'Ġcomm',
    'ands',
    'Ġoff',
    'ices',
    'vel',
    'and',
    'Ġt',
    'ut',
    'Ġrece',
    'iver',
    'ĠF',
    'ro',
    'Ġsho',
    'pping',
    'Ġi',
    'P',
    'ĠSt',
    're',
    'ĠA',
    'BC',
    'Ġentertain',
    'ment',
    'ĠB',
    'ow',
    'ort',
    'ed',
    'M',
    'c',
    'Ġread',
    's',
    'gr',
    'ad',
    'ĠCol',
    'lect',
    'Ġâ',
    'ĪĴ',
    'ĠCap',
    'ital',
    'eder',
    'ation',
    'Ġemploy',
    'er',
    'Ġinvolve',
    'ment',
    'Ġanx',
    'iety',
    'al',
    'ia',
    'Ġro',
    'of',
    'ĠAm',
    'ong',
    'ĠDemocr',
    'at',
    'Ġstat',
    's',
    'ĠV',
    'ill',
    'Ġconst',
    'itutional',
    'Ġrefer',
    'ring',
    'itt',
    'y',
    'Ġtack',
    'le',
    'out',
    'ube',
    'Ġback',
    'ed',
    'ĠH',
    'ong',
    'ĠBro',
    'ad',
    'Ġe',
    'le',
    'ĠO',
    'tt',
    'Ġ199',
    '2',
    'h',
    'our',
    'achus',
    'etts',
    'C',
    'al',
    'Ġdefe',
    'ated',
    'Ġ8',
    '1',
    'es',
    'p',
    'Ġseem',
    'ingly',
    'w',
    'as',
    'ĠJ',
    'enn',
    'ĠK',
    'urd',
    'Ġg',
    'ene',
    'Ġdisc',
    'ount',
    'R',
    'et',
    'EC',
    'T',
    '(',
    ');',
    'Ġclub',
    's',
    'Ġs',
    'id',
    'ĠM',
    'arsh',
    'Che',
    'ck',
    'Ġp',
    'p',
    'ĠE',
    'ag',
    'ides',
    'pread',
    'Ġbe',
    'ings',
    'F',
    'T',
    'Ġintrodu',
    'ction',
    'ĠCh',
    'ange',
    'AR',
    'D',
    'Ġ1',
    '10',
    'ad',
    'ows',
    'ier',
    'ce',
    'Ġme',
    'al',
    'a',
    'uthor',
    'ĠB',
    'ang',
    'lah',
    'oma',
    'Ġr',
    'anks',
    '201',
    '1',
    '??',
    '??',
    'm',
    'ax',
    'Ġcoll',
    'apse',
    'Ġop',
    'ens',
    'Ġe',
    'cho',
    'Ġs',
    'oph',
    'Ġrac',
    'ist',
    'Ġenorm',
    'ous',
    'Ġw',
    'aves',
    'Ġt',
    'ap',
    'Ġcomprehens',
    'ive',
    '.',
    '--',
    'ĠR',
    'oy',
    'Ġfarm',
    'ers',
    'Rel',
    'ated',
    'a',
    'ired',
    'ron',
    'es',
    'ĠC',
    'rim',
    'Ġproport',
    'ion',
    'Ġdesign',
    's',
    'Ġnegoti',
    'ations',
    'Ġvirt',
    'ually',
    'ĠBat',
    'man',
    'Ġwar',
    'n',
    'Ġlegit',
    'imate',
    'm',
    'ate',
    'Ġcon',
    'vention',
    ',',
    ',',
    'net',
    'ic',
    'ĠS',
    'D',
    'Ġconsist',
    'ently',
    'Ġcompens',
    'ation',
    'Ġpunish',
    'ment',
    'Ġy',
    'e',
    'Ġt',
    'ie',
    'ĠB',
    'ureau',
    'ir',
    'lf',
    'ĠB',
    'u',
    'ĠA',
    'ren',
    'ĠPh',
    'ilipp',
    'Ġkn',
    'ife',
    'Ġmem',
    'ories',
    'ĠR',
    'oss',
    'Ġang',
    'le',
    'Ġ8',
    '6',
    'ĠTh',
    'under',
    'Ġre',
    'nd',
    'ĠT',
    'our',
    'Ġcount',
    's',
    's',
    'ung',
    'ĠIm',
    'p',
    'Ġeduc',
    'ational',
    'Ġaccess',
    'ible',
    'C',
    'OM',
    'Ġd',
    'rew',
    'y',
    'er',
    'G',
    'l',
    'am',
    'ine',
    'OR',
    'T',
    'O',
    'B',
    'I',
    'B',
    'm',
    'aster',
    'Ġtri',
    'als',
    'og',
    'y',
    'h',
    'ar',
    'ĠTr',
    'ust',
    'Ġprefer',
    'red',
    'irlf',
    'riend',
    'ĠN',
    'ev',
    'Ġb',
    'in',
    'Ġc',
    'ow',
    'P',
    'age',
    'Ġsign',
    'ature',
    'ĠB',
    'L',
    '7',
    '00',
    'Ġret',
    'ired',
    'Ġby',
    'tes',
    'Ġneigh',
    'b',
    'ĠLeg',
    'end',
    'Ġdev',
    'ast',
    'Ġsuspect',
    'ed',
    'is',
    'ons',
    'ĠPokÃ©',
    'mon',
    'sc',
    'ale',
    'Ġcap',
    'abilities',
    'Ġre',
    'vel',
    'Ġche',
    'ese',
    'd',
    'y',
    'igr',
    'ant',
    'Ġfail',
    'ing',
    'b',
    'its',
    'ĠHer',
    'oes',
    'ĠG',
    'host',
    'ĠS',
    'cient',
    'Ġappoint',
    'ed',
    'ur',
    'i',
    'Ġinst',
    'itution',
    'Ġexpand',
    'ed',
    'g',
    'reg',
    'Ġmonitor',
    'ing',
    'Ġp',
    'odcast',
    'Ġcoal',
    'ition',
    'Ġ9',
    '6',
    'J',
    'o',
    'Ġst',
    'olen',
    'ĠS',
    'ab',
    'Ġstop',
    's',
    'Ġhol',
    'iday',
    'Ġint',
    'r',
    'C',
    'ar',
    'Bl',
    'ack',
    'ĠL',
    'GBT',
    'Ġwar',
    'ming',
    'ĠAnd',
    'erson',
    'Ġ8',
    '9',
    'Ġprodu',
    'cer',
    'M',
    'ed',
    'Ġaccur',
    'acy',
    'ĠMar',
    'vel',
    'iz',
    'abeth',
    'ĠPat',
    'rick',
    'm',
    'ony',
    'Ġmin',
    'i',
    'ac',
    'les',
    'Ġover',
    't',
    'the',
    'y',
    'Ġmembers',
    'hip',
    'ĠV',
    'en',
    'Ġex',
    'ch',
    'Ġrem',
    'oval',
    'ĠD',
    'ave',
    'T',
    'Y',
    'm',
    'ad',
    'ĠF',
    'ind',
    'Ġad',
    'equ',
    'Ġe',
    'c',
    'Ġte',
    'eth',
    'Ġemot',
    'ion',
    'Ġper',
    'm',
    'Ġsole',
    'ly',
    'd',
    'b',
    'Ġextra',
    'ord',
    'IG',
    'HT',
    'c',
    'al',
    'Ġgu',
    'idelines',
    'Ġd',
    'ying',
    'Ġsusp',
    'ended',
    'ĠPrem',
    'ier',
    'ĠAnth',
    'ony',
    'el',
    've',
    'Ġd',
    'ad',
    'ĠE',
    'th',
    'ĠFoot',
    'ball',
    'Ġabandon',
    'ed',
    'Ġ<',
    '<',
    'Ġm',
    'arch',
    'Ġhor',
    'ror',
    'âĢ¦',
    '"',
    'Ġchild',
    'hood',
    'Ġcampaign',
    's',
    'Ġl',
    'unch',
    'ĠAl',
    'bert',
    'bl',
    'ock',
    'âĸĪ',
    'âĸĪ',
    'ound',
    'ing',
    'Ġb',
    'one',
    'or',
    'gan',
    'ad',
    'ers',
    'ĠFl',
    'ash',
    'ĠDri',
    've',
    'Ġton',
    'ight',
    'Ġw',
    'ars',
    'ĠF',
    'L',
    'Ġform',
    'ation',
    'con',
    'st',
    'New',
    's',
    'Ġcom',
    'pe',
    'or',
    'ious',
    'ĠSt',
    'aff',
    'Ġdiscuss',
    'ions',
    'ĠProt',
    'ection',
    'ĠJ',
    'am',
    'Ġcrit',
    'eria',
    'Ġinstall',
    'ation',
    'Ġaccompl',
    'ish',
    'iz',
    'za',
    'Ġpub',
    'lisher',
    'Ġresc',
    'ue',
    'ĠT',
    'ry',
    'U',
    'LL',
    'ĠS',
    'om',
    'ĠH',
    'op',
    'ore',
    't',
    'th',
    's',
    'ord',
    'on',
    'Ġp',
    'ocket',
    'ĠIn',
    'v',
    'Down',
    'load',
    'ĠCr',
    'ime',
    'Ġb',
    'ene',
    'ĠGu',
    'ide',
    'ĠAs',
    'sembly',
    'Ġparam',
    'eters',
    'I',
    'E',
    'ĠAlex',
    'ander',
    'Ġconc',
    'ert',
    'ĠSc',
    'he',
    'Ġsh',
    'oes',
    'Ġvis',
    'iting',
    'Ġrec',
    'all',
    'Ġb',
    'ub',
    'Ġr',
    'ural',
    'Ġconc',
    'rete',
    'ĠR',
    'os',
    'N',
    'ext',
    'R',
    'uss',
    'Ġlo',
    'ans',
    'ĠSh',
    'ield',
    'Ġtre',
    'm',
    'hem',
    'at',
    'k',
    'g',
    'ĠHar',
    'ris',
    'is',
    'ition',
    'ĠM',
    'ove',
    'ĠF',
    'C',
    'Ġf',
    'ate',
    'ĠCh',
    'o',
    'Ġt',
    'ired',
    'Ġprinc',
    'ipal',
    'h',
    'ist',
    'ien',
    'ces',
    'ath',
    'y',
    'Ġse',
    'vent',
    'Ġm',
    'ood',
    'Ġstrateg',
    'ic',
    'Ġdise',
    'ases',
    'Ġfor',
    'um',
    'Ġtem',
    'por',
    'Ġhead',
    'quarters',
    'P',
    'ar',
    'ig',
    'e',
    'fl',
    'ix',
    'Ġgu',
    'itar',
    'Ġ9',
    '4',
    'On',
    'ly',
    'Ġrele',
    'ases',
    'ro',
    'ph',
    '================',
    '================',
    'Ġ6',
    '00',
    'ĠContin',
    'ue',
    'ig',
    'ate',
    'ĠC',
    'rit',
    'sy',
    'stem',
    'Ġdis',
    'abled',
    'Ġunex',
    'pected',
    'ith',
    'ub',
    'Ġuncle',
    'ar',
    'ĠE',
    'st',
    'Ġcontr',
    'ad',
    'Ġstrateg',
    'ies',
    'vent',
    'ures',
    'Ġpass',
    'age',
    'AM',
    'E',
    'Ġimpro',
    'ving',
    'Ġreve',
    'als',
    'Ġdecre',
    'ase',
    'ov',
    'a',
    'Ġann',
    'oy',
    'ĠSh',
    'ort',
    'ĠL',
    'ibrary',
    'Ġcy',
    'ber',
    'n',
    'ell',
    'ĠH',
    'ur',
    'ĠC',
    'B',
    'Ġphot',
    'ograp',
    'U',
    'I',
    'Ġs',
    'ed',
    'G',
    'e',
    'Ġ8',
    '7',
    'Ġd',
    'iverse',
    'Ġencour',
    'aged',
    'Ġcons',
    'piracy',
    'Ġbird',
    's',
    'Ġoper',
    'ator',
    'Ġhand',
    'ful',
    'Ġclass',
    'ified',
    '?',
    ')',
    'Ġdram',
    'atic',
    'Ġinvestig',
    'ators',
    'it',
    'o',
    'Ġw',
    'idespread',
    'ĠR',
    'oom',
    '--------------------------------',
    '--------------------------------',
    'Ġcollect',
    'ive',
    'Ġjournal',
    'ist',
    'St',
    'ring',
    'Ġtemper',
    'atures',
    'il',
    'a',
    'Ġgu',
    'id',
    'Ġins',
    'pect',
    'Ġmiss',
    'ile',
    'ĠMay',
    'or',
    'Ġman',
    'ual',
    'Ġsim',
    'ultane',
    'Ġrat',
    'ings',
    'Ġsu',
    'ck',
    'Ġ9',
    '7',
    'Ġunivers',
    'al',
    'Ġph',
    'arm',
    'Ġdis',
    'rupt',
    'ian',
    'o',
    'A',
    'V',
    'Ġf',
    't',
    'Ġstat',
    'ist',
    'old',
    's',
    'ĠWalk',
    'er',
    'ph',
    'p',
    'Ġunder',
    't',
    'ĠL',
    'as',
    'ish',
    'op',
    'nt',
    'il',
    'res',
    'hold',
    'ĠWhe',
    'ther',
    'M',
    's',
    'Ġden',
    'y',
    'ĠCl',
    'oud',
    'Ġprov',
    'ider',
    'Ġsurv',
    'iv',
    'ĠUp',
    'date',
    'h',
    'as',
    'Ġmist',
    'akes',
    'ch',
    'arge',
    'pl',
    'ed',
    'r',
    'ity',
    'Ġn',
    'ode',
    'ĠMass',
    'achusetts',
    'ool',
    's',
    'lic',
    'ation',
    'Ġf',
    'ails',
    'em',
    'ale',
    'or',
    'i',
    'back',
    's',
    'Ġsh',
    'irt',
    "Ġ'",
    "'",
    'ĠN',
    'AT',
    'Ġwat',
    'ers',
    'els',
    'on',
    'Ġe',
    'ase',
    'Ġsc',
    'ar',
    'Ġcont',
    'ents',
    'm',
    'ind',
    'Ġcont',
    'ribution',
    'Ġsh',
    'r',
    'Ġhand',
    'ed',
    'Ġst',
    'ability',
    'Ġtra',
    've',
    'E',
    'm',
    'Ġmir',
    'ror',
    '12',
    '3',
    'Ġwe',
    'igh',
    'Ġf',
    'iction',
    'ou',
    'ver',
    'ist',
    'ant',
    'r',
    'ition',
    'ĠF',
    'ed',
    'Ġphys',
    'ically',
    'Ġst',
    'ake',
    'ĠArt',
    'icle',
    'ĠAr',
    'c',
    'ĠLew',
    'is',
    'ĠM',
    'ind',
    'Ġdemonstr',
    'ate',
    'Ġprof',
    'its',
    'v',
    'ision',
    'om',
    'ic',
    'ol',
    'id',
    'Ġbatt',
    'les',
    'Ġdri',
    'ves',
    'Ġeas',
    'tern',
    'ĠS',
    'ony',
    '!!',
    '!',
    'ar',
    'ation',
    'v',
    'ard',
    'ĠG',
    'L',
    'port',
    'ation',
    'Ġ9',
    '2',
    'Ġlaw',
    'makers',
    'Ġprotect',
    'ing',
    'ĠE',
    'PA',
    'Ġy',
    'eah',
    'Ġsh',
    'ame',
    'ol',
    'ph',
    'e',
    'ven',
    'x',
    'it',
    'Ġatt',
    'ach',
    'Ġrepresent',
    'ing',
    'Ġob',
    's',
    'ĠUt',
    'ah',
    'iff',
    's',
    'ĠFre',
    'edom',
    'Ã',
    '³',
    'A',
    'K',
    'Ġinc',
    'idents',
    'it',
    'age',
    'Ġview',
    'ers',
    'c',
    'd',
    'Ġm',
    'ouse',
    'Ġcl',
    'ar',
    'Ġaccord',
    'ance',
    'Ġb',
    'ot',
    'c',
    'or',
    'ĠSum',
    'mer',
    'he',
    'ld',
    'Ġinnoc',
    'ent',
    'Ġiniti',
    'ative',
    'ol',
    's',
    '________________',
    '________________',
    'Ġsp',
    'ots',
    'p',
    'ace',
    'Ġconvent',
    'ional',
    'Ġcorpor',
    'ations',
    'Ġblock',
    'ed',
    'H',
    'D',
    'at',
    'tered',
    'Ġref',
    'ers',
    'Ġbu',
    'ck',
    'ĠDig',
    'ital',
    '12',
    '0',
    'Ġtop',
    'ics',
    'T',
    'F',
    'Ä',
    'ģ',
    'br',
    'id',
    're',
    'ement',
    'Ġunder',
    'lying',
    'ĠM',
    'ember',
    'Ġinvestig',
    'ating',
    'Ġpregn',
    'ancy',
    'Ġtouch',
    'down',
    'ĠB',
    'and',
    'ĠCall',
    'er',
    'Ġinst',
    'ances',
    'P',
    'P',
    'w',
    'a',
    'G',
    'ood',
    'Ġ199',
    '1',
    'ĠC',
    'old',
    'Ġfear',
    's',
    'Ġrem',
    'arks',
    'Ĩ',
    'Ĵ',
    'at',
    'al',
    'Ġm',
    'it',
    'Ġexper',
    'iments',
    'i',
    'pt',
    'Col',
    'or',
    'ind',
    'u',
    'Up',
    'date',
    'Ġ9',
    '3',
    'A',
    'g',
    'Ġ',
    'å',
    'anc',
    'ouver',
    'B',
    'oth',
    'Ġjud',
    'ges',
    'Ob',
    'ject',
    'Ġst',
    'ere',
    'umb',
    'n',
    'Ġparticip',
    'ation',
    'ĠSt',
    'ars',
    'ĠJ',
    'ere',
    'Ġweek',
    'ly',
    'ĠB',
    'an',
    'Ġconvers',
    'ations',
    'ĠP',
    'itt',
    'u',
    'z',
    'ĠIndian',
    'a',
    'ĠK',
    'ick',
    'Ġinf',
    'ection',
    'Ġhero',
    'es',
    'Ġsett',
    'led',
    'Ġstri',
    'p',
    'Ġh',
    'al',
    'Ġd',
    'ump',
    'ĠS',
    'ci',
    'Ġl',
    'es',
    'Ġref',
    'erences',
    'ĠU',
    'RL',
    'ĠBr',
    'idge',
    'Ġwant',
    'ing',
    'For',
    'ce',
    'Ġex',
    'clus',
    'Me',
    'anwhile',
    'm',
    'n',
    'Ġg',
    'entle',
    'm',
    'aker',
    'sen',
    'al',
    'ĠG',
    'ro',
    'ou',
    'ri',
    'ĠR',
    'ain',
    'ĠAll',
    'iance',
    'Ġl',
    'ift',
    'el',
    'a',
    'S',
    'D',
    'ĠCle',
    'veland',
    'Ġrank',
    'ed',
    'Ġst',
    'adium',
    'Ġdead',
    'ly',
    'ä',
    '¸',
    'Ġr',
    'iding',
    'ar',
    'ia',
    'ĠAr',
    'mor',
    'Ġdocument',
    'ation',
    'ĠGree',
    'ce',
    'ree',
    'k',
    'Ġl',
    'ens',
    'ĠS',
    'a',
    'Ġg',
    'ross',
    'ĠE',
    'mer',
    'ag',
    'ers',
    'ĠD',
    'ub',
    'ĠR',
    'h',
    'ĠAM',
    'D',
    'Ġarri',
    'val',
    'Ġdes',
    'ert',
    'Ġsupp',
    'lement',
    'ĠRes',
    'p',
    'Ġkn',
    'ee',
    'Ġmarg',
    'in',
    'f',
    'ont',
    'og',
    'g',
    '201',
    '0',
    'ĠP',
    'ir',
    'ĠP',
    'rom',
    'iv',
    'als',
    'Ġint',
    'ake',
    'Ġdifferent',
    'ly',
    'ug',
    's',
    'Ġb',
    'its',
    'clud',
    'ed',
    'Ġsearch',
    'ing',
    'ĠD',
    'u',
    'um',
    'ble',
    'Ġfunction',
    'al',
    'ĠBalt',
    'imore',
    'ĠC',
    'ould',
    'Ġdes',
    'ired',
    'Ġcirc',
    'uit',
    'ĠL',
    'yn',
    'ĠG',
    'O',
    'ĠF',
    'alse',
    're',
    'pre',
    "'",
    ':',
    'alt',
    'ies',
    'Ġmin',
    'im',
    'Ġdro',
    've',
    'ĠSh',
    'ould',
    'Ġh',
    'ip',
    'Ġpro',
    's',
    'Ġut',
    'ility',
    'ĠN',
    'ature',
    'ĠM',
    'ode',
    'P',
    'resident',
    'o',
    'pp',
    'r',
    'at',
    'form',
    'ance',
    'Ġconcent',
    'ration',
    'Ġf',
    'ont',
    'ĠB',
    'ud',
    'Ġam',
    'id',
    'Ġre',
    'vers',
    'ĠM',
    'L',
    'B',
    'ar',
    'Ġinter',
    'action',
    'Ġjur',
    'isd',
    'Ġspell',
    's',
    'd',
    'ep',
    'f',
    'il',
    'Ġcivil',
    'ians',
    'ut',
    'ter',
    'ĠCo',
    'oper',
    'ĠBel',
    'ow',
    'Ġent',
    'rance',
    'Ġcon',
    'vert',
    'Ġcontrovers',
    'y',
    'ow',
    'ered',
    'Ġcontr',
    'ary',
    'Ġar',
    'c',
    'ĠExec',
    'utive',
    'ĠOffic',
    'er',
    'Ġpack',
    'ages',
    'Ġprog',
    'ressive',
    'w',
    'idth',
    'Ġreserv',
    'ed',
    'v',
    'ol',
    'ĠSam',
    'sung',
    'Ġprint',
    'ed',
    'Ġcent',
    'ers',
    'Ġintrodu',
    'ce',
    'ĠKenn',
    'edy',
    'Ġodd',
    's',
    'Ġsure',
    'ly',
    'Ġindepend',
    'ence',
    'Ġpass',
    'engers',
    'repre',
    'ne',
    'ĠBe',
    'h',
    'Ġl',
    'oves',
    'ĠESP',
    'N',
    'Ġfac',
    'ilit',
    'Ġident',
    'ical',
    'Ġdo',
    'ct',
    'Ġpartners',
    'hip',
    'con',
    'f',
    'ĠH',
    'ide',
    'Ġconf',
    'used',
    'ĠC',
    'ow',
    'M',
    'en',
    'Ġw',
    'rest',
    'ĠIraq',
    'i',
    'Ġh',
    'oles',
    'ĠStud',
    'ies',
    'Ġpregn',
    'ant',
    'h',
    'ard',
    'Ġsign',
    'als',
    'I',
    'X',
    'Ġpull',
    'ing',
    'Ġgrad',
    'uate',
    'Ġnomine',
    'e',
    'D',
    'ate',
    'Ġper',
    'mitted',
    'Ġâ',
    'Ĥ¬',
    'ĠOk',
    'lahoma',
    'St',
    'art',
    'Ġauthor',
    'ized',
    'Ġal',
    'arm',
    'ĠC',
    'os',
    'v',
    'an',
    'Ġgener',
    'ations',
    'c',
    'ular',
    'Ġdr',
    'agon',
    'ĠSoft',
    'ware',
    'ĠEd',
    'ward',
    'Ġcontro',
    'ller',
    'S',
    'en',
    'ge',
    'red',
    'ĠV',
    'ik',
    'Ġappro',
    'ached',
    'Th',
    'ank',
    'Ġcan',
    'ce',
    'Ġform',
    'ula',
    'ĠSm',
    'all',
    'Ġweak',
    'ness',
    'Ġr',
    'amp',
    'it',
    'udes',
    'j',
    'ud',
    'Ġbrill',
    'iant',
    'Ġacc',
    'us',
    's',
    'ource',
    'Ġ8',
    '00',
    'ĠE',
    'vil',
    'S',
    'w',
    'Ġhom',
    'eless',
    'we',
    'ek',
    'i',
    'ens',
    'r',
    'ics',
    'ĠTh',
    'ird',
    'T',
    'O',
    'Ġorgan',
    'ic',
    'Ġpresent',
    'ation',
    'ag',
    'h',
    'ĠDown',
    'load',
    'v',
    'ation',
    'Ġas',
    'sembly',
    'or',
    'able',
    'hold',
    'ers',
    'ĠBern',
    'ie',
    'ĠHel',
    'p',
    'Ġt',
    'ong',
    'ĠF',
    'ight',
    'Ġbe',
    'ach',
    'B',
    'ook',
    'ĠL',
    'ic',
    'Ġr',
    'ush',
    'ĠR',
    'ound',
    'ou',
    'p',
    'ĠMar',
    'x',
    'Ġcalcul',
    'ated',
    'ĠDe',
    'vil',
    'ĠSar',
    'ah',
    'Ġoccasion',
    'ally',
    'Ġbul',
    'let',
    'Av',
    'ailable',
    'g',
    'ate',
    'Ġ9',
    '1',
    'Ġh',
    'osp',
    'Ġprom',
    'ises',
    'ĠH',
    'IV',
    'ĠSt',
    'adium',
    'ĠSt',
    'ock',
    'ĠCorpor',
    'ation',
    'g',
    'age',
    'N',
    'G',
    'ĠC',
    'redit',
    'Ġs',
    'ne',
    'ib',
    'l',
    'Ġacc',
    'um',
    's',
    'uch',
    'Ġterror',
    'ists',
    'Ġconscious',
    'ness',
    'ĠZ',
    'h',
    'Ġdram',
    'a',
    'ool',
    'a',
    'pir',
    'ation',
    'Ġlab',
    'our',
    'ĠN',
    'in',
    'Ġut',
    'ter',
    'Ġdemocr',
    'atic',
    'Ġass',
    'ass',
    'il',
    'ation',
    'Ġg',
    'est',
    'Ġab',
    'road',
    'Ġmet',
    'ab',
    'Ġs',
    'orts',
    'Ġfl',
    'av',
    'U',
    'B',
    'Ġm',
    'g',
    'ĠNot',
    'hing',
    'ĠO',
    'd',
    'Ġmus',
    'ical',
    '200',
    '9',
    'Ġdro',
    'ps',
    'oc',
    'ated',
    'ater',
    'al',
    '0000',
    '00',
    'Ġg',
    're',
    'Ġequ',
    'ality',
    'Ġburd',
    'en',
    'Ġv',
    'ig',
    'ĠLe',
    'ader',
    '--------',
    '----',
    'Ġcere',
    'mony',
    'Ġf',
    'ighter',
    'Ġact',
    'ors',
    'Ġ',
    'æ',
    'am',
    'an',
    'F',
    'i',
    'Ġal',
    'ign',
    'put',
    'er',
    'Ġe',
    'lder',
    'ĠN',
    'SA',
    'Ġrepresent',
    'ation',
    'ĠOnt',
    'ario',
    'IT',
    'H',
    'usal',
    'em',
    'Ġharass',
    'ment',
    'itz',
    'er',
    'Ġsy',
    'mp',
    'Ġbox',
    'es',
    'ĠD',
    'R',
    'Ġman',
    'ifest',
    'at',
    're',
    'Ġ',
    '^',
    'Ġd',
    'ies',
    'le',
    'ton',
    'Ġmiss',
    'ions',
    'et',
    'he',
    'Ġres',
    'olve',
    'Ġfollow',
    'ers',
    'Ġas',
    'c',
    'Ġk',
    'm',
    'l',
    'ord',
    'am',
    'med',
    'Ġsil',
    'ent',
    'ĠAssoci',
    'ated',
    'Ġtim',
    'ing',
    'Ġprison',
    'ers',
    'ĠK',
    'ings',
    'ĠF',
    'ive',
    'Ġtow',
    'er',
    'Ġappro',
    'aches',
    'Ġprecise',
    'ly',
    'Ġb',
    'ureau',
    'ĠM',
    'other',
    'ĠI',
    'ss',
    'Ġkey',
    'board',
    'it',
    'ual',
    'Ġfund',
    'ed',
    'Ġstay',
    'ing',
    'Ġpsych',
    'ological',
    'Ġm',
    'ile',
    'ĠLe',
    'on',
    'ĠBar',
    'b',
    'w',
    'ill',
    'Ġw',
    'ider',
    'ĠAtl',
    'antic',
    'Ġt',
    'ill',
    'ĠR',
    'ome',
    'ro',
    't',
    'Ġaccomp',
    'an',
    'Ġfl',
    'our',
    'ac',
    'o',
    'W',
    'orld',
    'ĠExp',
    'ress',
    'ĠY',
    'u',
    'C',
    'or',
    'Ġple',
    'ased',
    'part',
    'y',
    'Ġpoint',
    'ing',
    'Ġinf',
    'lation',
    'Ġro',
    'y',
    'Ġ',
    '),',
    'ain',
    'er',
    'Ġwedd',
    'ing',
    'orm',
    'on',
    'Ġrequ',
    'iring',
    'Ġqual',
    'ified',
    'Ġse',
    'gment',
    'EN',
    'D',
    'Ġs',
    'izes',
    'e',
    'als',
    'Ġcor',
    'rupt',
    'ass',
    'ador',
    'Ġcele',
    'b',
    'Ġdream',
    's',
    'ĠM',
    'ess',
    'Ġcheck',
    'ing',
    'ĠV',
    'ersion',
    'Ġprep',
    'aring',
    'Ġact',
    'ively',
    'ĠD',
    'iff',
    'Ġl',
    'ux',
    'ĠW',
    'inter',
    'act',
    'eria',
    'ĠN',
    'E',
    'Ġdep',
    'uty',
    'Ġtrans',
    'gender',
    'Ġsum',
    'mary',
    'Ġin',
    'her',
    'er',
    'ies',
    'ch',
    'ar',
    'ĠY',
    'an',
    'Ġkn',
    'ock',
    'ĠP',
    'ath',
    'Ġl',
    'ip',
    'roll',
    'er',
    'Ġimp',
    'ression',
    'Ġcelebr',
    'ate',
    'Ġsl',
    'ide',
    'Ġgu',
    'ests',
    'Ġcl',
    'ip',
    'F',
    'S',
    'Ġsav',
    'ings',
    'Ġcapt',
    'ain',
    'Ġleg',
    'acy',
    'ĠDen',
    'ver',
    'Ġw',
    'ounded',
    'tab',
    'oola',
    'AC',
    'T',
    'Ġpurs',
    'ue',
    'Ġo',
    'xy',
    'Ġ',
    'q',
    'Ġsem',
    'i',
    'ĠN',
    'eed',
    'ĠAff',
    'airs',
    'Ġob',
    'sc',
    'Ġcheck',
    'ed',
    'Ġd',
    'ual',
    'C',
    'ode',
    'ĠM',
    'D',
    'le',
    'm',
    'ult',
    'y',
    'ĠÂ',
    '©',
    'ĠEl',
    'izabeth',
    'Ġcent',
    'uries',
    'ard',
    'ed',
    's',
    'rc',
    'Ġev',
    'ident',
    'enn',
    'is',
    'at',
    'in',
    'Ġunemploy',
    'ment',
    'ĠMar',
    'io',
    'Ġint',
    'im',
    'Ch',
    'rist',
    'Ġbi',
    'ological',
    'Ġsold',
    'ier',
    'ĠAdd',
    'ed',
    'Ġm',
    'ath',
    'ĠG',
    'il',
    'Ġbi',
    'as',
    'Ġd',
    'ating',
    'ĠO',
    'cean',
    'Ġm',
    'ice',
    'M',
    'us',
    'h',
    'ire',
    'ĠT',
    'es',
    'Ser',
    'ver',
    'lim',
    'ited',
    'S',
    'ize',
    'Ġmet',
    'ers',
    'Ġrock',
    'et',
    'es',
    'see',
    'Ġcertific',
    'ate',
    'ĠIran',
    'ian',
    'AS',
    'S',
    'Ġgr',
    'id',
    'D',
    'ec',
    'Ġro',
    'lling',
    'com',
    'mun',
    'ĠSwed',
    'en',
    'b',
    'ury',
    'Ġtiss',
    'ue',
    'Ġrac',
    'ism',
    'ĠL',
    'ocal',
    'Ġmyster',
    'y',
    'Ġexam',
    'ine',
    'Ġst',
    'em',
    'Ġs',
    'its',
    'Ġhop',
    'ed',
    'ot',
    'ing',
    'Ġdial',
    'ogue',
    'Ġpers',
    'u',
    'W',
    'atch',
    'l',
    'ay',
    'M',
    'AN',
    'Ġch',
    'ronic',
    'ĠPort',
    'land',
    'mark',
    'et',
    'ĠS',
    'EC',
    'Ġparalle',
    'l',
    'Ġsc',
    'andal',
    'Ġcar',
    'ries',
    'Ġphenomen',
    'on',
    'h',
    'uman',
    'ack',
    'er',
    'ĠO',
    'x',
    'Ġretire',
    'ment',
    'tain',
    'ment',
    'ov',
    'ie',
    'ĠG',
    'ear',
    'Ġd',
    'uties',
    'Ġdo',
    'se',
    'Ġsc',
    'roll',
    'M',
    'B',
    'in',
    'f',
    'Ġsa',
    'uce',
    'Ġland',
    'scape',
    'red',
    'dit',
    'ĠChampions',
    'hip',
    'ĠRed',
    'dit',
    'al',
    'id',
    'Ġco',
    'in',
    'Ġover',
    's',
    'Ġpost',
    'ing',
    'ab',
    'out',
    'Ġf',
    'el',
    'and',
    'y',
    'Ġb',
    'old',
    'Ġfocus',
    'ing',
    'e',
    'ffect',
    'G',
    'R',
    'Ġde',
    'emed',
    'Ġrecommend',
    'ations',
    'Ġste',
    'pped',
    'Ġvot',
    'er',
    'ĠDe',
    'ep',
    'ĠInst',
    'agram',
    'Ġmoder',
    'ate',
    'ĠMary',
    'land',
    'Ġrestrict',
    'ed',
    'ĠM',
    'B',
    'ĠCh',
    'all',
    'Ġto',
    'b',
    'Ġc',
    'ir',
    'ĠO',
    'cc',
    'ĠE',
    'ver',
    'Ġcoll',
    'aps',
    'IN',
    'FO',
    '=',
    '-',
    'ĠP',
    'ict',
    'ĠAcc',
    'ount',
    'n',
    'c',
    'Ġo',
    'ught',
    'Ġex',
    'port',
    'Ġdr',
    'unk',
    '(',
    "'",
    'Ġw',
    'ise',
    'ĠM',
    'ort',
    'ne',
    'cess',
    'Ġan',
    'cest',
    'ĠInc',
    're',
    'Ġfrequ',
    'ent',
    'm',
    'ir',
    'Ġinterpret',
    'ation',
    'Ġdepend',
    'ent',
    'Ġco',
    'ins',
    'ĠB',
    'ol',
    'V',
    'ideo',
    'ĠJust',
    'in',
    'Ġfat',
    'al',
    'Ġcook',
    'ing',
    'Ġconf',
    'usion',
    'ip',
    'her',
    'Ġcust',
    'ody',
    'ĠMor',
    'gan',
    'om',
    'ach',
    'ĠGovern',
    'or',
    'Ġrestaur',
    'ants',
    'el',
    'ing',
    'Ġacknowled',
    'ged',
    'Ġthe',
    'r',
    'Ġgen',
    'es',
    'ch',
    'ing',
    'He',
    'y',
    'Ġtact',
    'ics',
    'ĠMex',
    'ican',
    'Ġv',
    'end',
    'Ġhe',
    's',
    'qu',
    'er',
    'Ġnot',
    'ing',
    'ĠCamer',
    'on',
    'Ġtarget',
    'ing',
    'ro',
    'ck',
    'Ġcred',
    'its',
    'Ġemot',
    'ions',
    'Ġrepresent',
    'atives',
    'new',
    's',
    'Ġlegisl',
    'ative',
    'Ġrem',
    'oving',
    'Ġtweet',
    'ed',
    'ĠCar',
    'ter',
    'ĠF',
    'ixed',
    'Ġfor',
    'cing',
    'Ġspeak',
    'er',
    'Ġm',
    'ales',
    'ĠViet',
    'nam',
    'l',
    'ined',
    'Ġconcept',
    's',
    'Ġvo',
    'ices',
    'o',
    'ir',
    'ĠT',
    'rib',
    'W',
    'he',
    'ĠJer',
    'usalem',
    'ĠS',
    'ant',
    'Ġc',
    'ul',
    'Ġl',
    'ady',
    'ĠHaw',
    'ai',
    'Ġar',
    'ts',
    'ĠIn',
    'n',
    'ĠMach',
    'ine',
    'ĠEm',
    'peror',
    'Ġsl',
    'ot',
    'g',
    'ly',
    'ĠPro',
    'cess',
    'II',
    'I',
    'Ġathlet',
    'es',
    'ĠTem',
    'ple',
    'ĠRep',
    'resent',
    'Ġpres',
    'c',
    'Ġt',
    'ons',
    'Ġgold',
    'en',
    'Ġp',
    'unch',
    'ĠG',
    'R',
    'iver',
    'pool',
    'Ġen',
    'act',
    'Ġlob',
    'by',
    'Ġm',
    'os',
    'Ġpick',
    'ing',
    'Ġlif',
    'etime',
    'Ġcogn',
    'itive',
    'E',
    'ach',
    'z',
    'o',
    'Ġd',
    'ub',
    'Ġcons',
    'ists',
    'ol',
    'n',
    'Ġf',
    'estival',
    'am',
    'ous',
    'Ġint',
    'ellig',
    'w',
    'ords',
    'ĠSm',
    'art',
    'Ġde',
    'le',
    'Ġl',
    'apt',
    'Ġmag',
    'ical',
    'ĠS',
    'in',
    'b',
    'us',
    'ur',
    'ities',
    'igh',
    'th',
    'ĠRub',
    'y',
    'ĠS',
    'ure',
    'ol',
    'ving',
    'Ġj',
    'un',
    'O',
    'ST',
    'Ġimp',
    'osed',
    'Ġast',
    'ron',
    'Ġcor',
    'rel',
    'ĠN',
    'S',
    'ĠK',
    'it',
    'ĠF',
    'uture',
    'b',
    'urn',
    'Ġimm',
    'une',
    'oc',
    'us',
    'Ġcour',
    'ses',
    'ĠSt',
    'ring',
    'Ġle',
    'an',
    'Ġg',
    'host',
    'Ġout',
    'comes',
    'Ġexp',
    'ense',
    'Ġevery',
    'day',
    'Ġaccept',
    'able',
    'A',
    'h',
    'Ġequ',
    'ipped',
    'Ġor',
    'ange',
    'F',
    'R',
    'ĠD',
    'utch',
    'Th',
    'ough',
    'ĠR',
    'ank',
    'Q',
    'U',
    'ĠRober',
    'ts',
    'wh',
    'at',
    're',
    'nd',
    'Ġdisapp',
    'ear',
    'Ġsp',
    'awn',
    'ĠL',
    'am',
    'o',
    'is',
    'Ġdes',
    'erve',
    'Ġmin',
    'imal',
    'Ġnerv',
    'ous',
    'ĠW',
    'ould',
    'Ġro',
    'ok',
    'ĠV',
    'ancouver',
    'Ġres',
    'ign',
    'sh',
    'ire',
    'ĠW',
    'orks',
    'ĠB',
    'uild',
    'Ġafford',
    'able',
    'ĠG',
    'ary',
    'ĠAren',
    'a',
    'Ġh',
    'anging',
    'Ġimpl',
    'ications',
    'ĠS',
    'ong',
    'Ġmain',
    'taining',
    'Ġgu',
    'ards',
    'C',
    'ON',
    'Ġder',
    'ived',
    'Ġexecut',
    'ed',
    'Ġthe',
    'ories',
    'Ġqu',
    'oted',
    'ĠAnd',
    're',
    'og',
    'a',
    'sel',
    'ess',
    'in',
    'fo',
    'ĠBel',
    'g',
    'Ġt',
    'ears',
    'ĠSur',
    'v',
    'Ġbirth',
    'day',
    'ig',
    'ious',
    'im',
    'mer',
    'Ġspect',
    'rum',
    'Ġarchitect',
    'ure',
    'Ġrec',
    'ruit',
    'arm',
    'a',
    'T',
    'able',
    'Ġmon',
    'sters',
    'ĠG',
    'ov',
    'Ġdest',
    'ination',
    'Ġattract',
    'ive',
    'Ġf',
    'oss',
    'ĠMore',
    'over',
    'Ġpres',
    'ents',
    'TH',
    'E',
    'Ġrep',
    'ly',
    'pt',
    'on',
    'Ġc',
    'um',
    'Ġdel',
    'ight',
    'Ġaffect',
    's',
    'Ġdon',
    'ations',
    'ĠT',
    'oy',
    'ĠH',
    'im',
    'M',
    'ENT',
    'Ġover',
    'come',
    'it',
    'ched',
    'ĠFant',
    'asy',
    'ĠH',
    'at',
    'ĠBe',
    'ast',
    'b',
    'ott',
    'Ġinvestig',
    'ations',
    'R',
    'un',
    'Ġhun',
    'ting',
    'd',
    'i',
    'f',
    'und',
    'Ġs',
    'essions',
    'est',
    'yle',
    'Ġport',
    'ray',
    'oid',
    's',
    'Y',
    'eah',
    'Ġcommun',
    'icate',
    'Ġcom',
    'edy',
    'ĠY',
    'ang',
    'Ġbel',
    't',
    'ĠMar',
    'ine',
    'Ġpredict',
    'ed',
    'Pl',
    'ay',
    'Ġimportant',
    'ly',
    'Ġremark',
    'able',
    'Ġelim',
    'inate',
    'D',
    'avid',
    'Ġb',
    'ind',
    'V',
    'ID',
    'Ġadvoc',
    'ates',
    'ĠG',
    'aza',
    'im',
    'p',
    'D',
    'B',
    'ĠN',
    'a',
    'ĠSim',
    'ilar',
    'I',
    'ES',
    'Ġchar',
    'ity',
    'v',
    'as',
    'm',
    'ath',
    'Ġâ',
    'ĸ',
    'ok',
    'er',
    'nd',
    'um',
    'Ġcap',
    's',
    'ĠH',
    'al',
    '2',
    '000',
    'e',
    'an',
    'Ġfle',
    'et',
    'Ġrec',
    're',
    'R',
    'ight',
    'Ġsleep',
    'ing',
    'ij',
    'ing',
    'k',
    'ind',
    'Ġdesign',
    'ated',
    'Ã',
    '¤',
    'Ġanim',
    'ation',
    'ke',
    'e',
    'ĠInt',
    'rodu',
    'Ġ/',
    '>',
    'Ġdelay',
    'ed',
    'Ġtrem',
    'end',
    'Ġcur',
    'ious',
    'U',
    'se',
    'Ġle',
    'ct',
    'd',
    'am',
    'Ġinnov',
    'ation',
    'ĠPoint',
    's',
    'Ġload',
    'ing',
    'Ġdisp',
    'ute',
    'ct',
    'ic',
    'ird',
    's',
    'ĠB',
    'Y',
    'Ġn',
    'urs',
    'ĠVal',
    'ue',
    'ION',
    'S',
    'ĠH',
    'um',
    'Ġtem',
    'plate',
    'm',
    'ers',
    'Ġappear',
    'ances',
    'ĠEnter',
    'tainment',
    'Ġtransl',
    'ation',
    'Ġsa',
    'ke',
    'Ġbene',
    'ath',
    'Ġin',
    'hib',
    'Ġe',
    'uro',
    'abet',
    'es',
    'Ġstud',
    'ying',
    'ĠM',
    'as',
    'Ġper',
    'ceived',
    'Ġexam',
    'ined',
    'Ġe',
    'ager',
    'Ġco',
    'aches',
    'Ġim',
    'per',
    'ch',
    'i',
    'Ġprodu',
    'ces',
    '"',
    ').',
    'ĠEvery',
    'one',
    'Ġm',
    'unicip',
    'Ġg',
    'irlfriend',
    'Ġh',
    'ire',
    'ĠV',
    'ice',
    'Ġsu',
    'itable',
    'op',
    'y',
    'Ġin',
    'equ',
    'ĠD',
    'uke',
    'f',
    'ish',
    'f',
    'irst',
    'ĠO',
    'bs',
    'Ġinter',
    'ior',
    'ĠBru',
    'ce',
    'ĠR',
    'y',
    'Ġanal',
    'ys',
    'Ġconsider',
    'able',
    'Ġfore',
    'cast',
    'Ġf',
    'ert',
    'ors',
    'hip',
    'ĠD',
    'rug',
    'ĠA',
    'LL',
    ':',
    '"',
    'th',
    'ur',
    'ĠM',
    'ail',
    'Ġball',
    'ot',
    'Ġinst',
    'antly',
    'ĠCh',
    'annel',
    'Ġp',
    'icks',
    'Ġ198',
    '9',
    'Ġt',
    'ent',
    'ol',
    'i',
    'Ġcivil',
    'ian',
    'b',
    'ling',
    'ell',
    'o',
    'b',
    'u',
    'Ġin',
    'ch',
    'Ġlog',
    'o',
    'Ġcooper',
    'ation',
    'Ġwal',
    'ks',
    'Ġinvest',
    'ments',
    'Ġimp',
    'rison',
    'ĠF',
    'estival',
    'ĠK',
    'y',
    'Ġleg',
    'ally',
    'Ġg',
    'ri',
    'ch',
    'arg',
    'S',
    'l',
    'Ġthreat',
    'ening',
    'du',
    'ction',
    'fl',
    'ow',
    'Ġdismiss',
    'ed',
    'ibr',
    'aries',
    'c',
    'ap',
    'e',
    'le',
    'ĠMc',
    'G',
    'ĠHar',
    'vard',
    'ĠConserv',
    'ative',
    'ĠC',
    'BS',
    'p',
    'ng',
    'Ġro',
    'ots',
    'ĠH',
    'aving',
    'umb',
    'led',
    'ĠF',
    'un',
    '\\',
    '/',
    'ĠS',
    'earch',
    'ple',
    'x',
    'Ġdiscuss',
    'ing',
    'Ġcontin',
    'u',
    'ĠT',
    'ai',
    'ĠW',
    'ik',
    'F',
    'ree',
    'f',
    'it',
    'Ġref',
    'use',
    'Ġmanag',
    'ing',
    'Ġsy',
    'nd',
    'ip',
    'edia',
    'w',
    'alk',
    'Ġprofession',
    'als',
    'Ġguid',
    'ance',
    'Ġunivers',
    'ities',
    'Ġas',
    'semb',
    'unt',
    'u',
    'F',
    'inally',
    'AS',
    'E',
    'ĠAut',
    'o',
    'ĠH',
    'ad',
    'Ġann',
    'iversary',
    'L',
    'D',
    'ĠD',
    'ur',
    'ĠUlt',
    'imate',
    'ih',
    'ad',
    'pro',
    'duct',
    'Ġtrans',
    'it',
    'Ġrest',
    'ore',
    'Ġexpl',
    'aining',
    'Ġass',
    'et',
    'Ġtransfer',
    'red',
    'Ġbur',
    'st',
    'ap',
    'olis',
    'ĠMag',
    'azine',
    'ĠC',
    'ra',
    'ĠB',
    'R',
    'gg',
    'ed',
    'ĠH',
    'E',
    'M',
    'ich',
    'b',
    'et',
    'ĠL',
    'ady',
    'yl',
    'um',
    'erv',
    'es',
    'Ġme',
    'ets',
    'wh',
    'ite',
    'L',
    'og',
    'Ġcorrespond',
    'ing',
    'Ġins',
    'isted',
    'G',
    'G',
    'Ġsurround',
    'ed',
    'Ġt',
    'ens',
    'Ġl',
    'ane',
    'Ġco',
    'inc',
    'h',
    'ome',
    'Ġexist',
    'ed',
    'ect',
    'ed',
    'ĠDou',
    'ble',
    'lam',
    'm',
    'Ġske',
    'pt',
    'ex',
    'p',
    'Ġper',
    'ception',
    'ie',
    'v',
    'ĠBe',
    'ing',
    'o',
    'ft',
    'Ġadop',
    't',
    '.',
    ':',
    ']',
    ';',
    'Wind',
    'ows',
    'Ġsatell',
    'ite',
    'AS',
    'H',
    'Ġinf',
    'ant',
    'd',
    'escription',
    'ĠMe',
    'anwhile',
    'c',
    'm',
    'oc',
    'a',
    'ĠT',
    'reat',
    'act',
    'or',
    'Ġtob',
    'acco',
    'ĠN',
    'orm',
    'em',
    'ption',
    'Ġfl',
    'esh',
    'Ġj',
    'e',
    'o',
    'op',
    'ĠHe',
    'aven',
    'Ġbe',
    'ating',
    'an',
    'im',
    'Ġgather',
    'ing',
    'Ġcult',
    'iv',
    'G',
    'O',
    'ab',
    'e',
    'ĠJon',
    'athan',
    'ĠSaf',
    'ety',
    'Ġbad',
    'ly',
    'pro',
    't',
    'Ġcho',
    'osing',
    'Ġcontact',
    'ed',
    'Ġqu',
    'it',
    'Ġdist',
    'ur',
    'Ġst',
    'ir',
    'Ġto',
    'ken',
    'D',
    'et',
    'ĠP',
    'a',
    'Ġfunction',
    'ality',
    '00',
    '3',
    's',
    'ome',
    'Ġlimit',
    'ations',
    'Ġmet',
    'h',
    'b',
    'uild',
    'con',
    'fig',
    'N',
    'T',
    're',
    'll',
    'ble',
    'm',
    'ĠM',
    'om',
    'Ġveter',
    'ans',
    'ĠH',
    'u',
    'Ġtrend',
    's',
    'are',
    'r',
    'ĠG',
    'iven',
    'ĠCa',
    'ption',
    'm',
    'ay',
    'AS',
    'T',
    'Ġwond',
    'ering',
    'ĠCl',
    'ark',
    'n',
    'ormal',
    'Ġsepar',
    'ated',
    'Ġdes',
    'p',
    'st',
    'ic',
    'b',
    'rew',
    'Ġrel',
    'ating',
    'ĠN',
    'ik',
    'ĠF',
    'arm',
    'Ġenthus',
    'i',
    'g',
    'ood',
    'd',
    'eb',
    'Ġactiv',
    'ist',
    'Ġm',
    'art',
    'Ġexplos',
    'ion',
    'ĠEconom',
    'ic',
    'L',
    'ink',
    'Ġins',
    'ight',
    'Ġconven',
    'ient',
    'Ġcounter',
    'part',
    'su',
    'pport',
    'ĠV',
    'irt',
    'ag',
    'en',
    'ĠTenn',
    'essee',
    'ĠSim',
    'on',
    'ĠA',
    'ward',
    'OC',
    'K',
    'ĠF',
    'igure',
    'Ġoverse',
    'as',
    'Ġpr',
    'ide',
    'ĠC',
    'as',
    'n',
    'ote',
    'm',
    'g',
    'C',
    'urrent',
    'Ġdispl',
    'ays',
    'cont',
    'ent',
    'Ġtravel',
    'ing',
    'Ġhosp',
    'itals',
    'ĠFin',
    'ancial',
    'ĠP',
    'ast',
    'Ġdefend',
    'ant',
    'Ġstream',
    'ing',
    'm',
    'ble',
    'ĠBer',
    'lin',
    'uk',
    'i',
    'Ġdist',
    'ribut',
    'Ġant',
    'ib',
    'Ġch',
    'ocolate',
    'ĠCast',
    'le',
    'Ġinter',
    'rupt',
    'ĠR',
    'ow',
    'Ġconvers',
    'ion',
    'Ġbug',
    's',
    'ĠR',
    'ather',
    'li',
    'est',
    'L',
    'Y',
    'ĠJe',
    'an',
    'com',
    'mon',
    'ak',
    'h',
    'Ġ1',
    '30',
    'ot',
    'ton',
    'ĠDe',
    'an',
    'Ġam',
    'endment',
    'Ġgame',
    'play',
    'ĠWar',
    'ren',
    'od',
    'a',
    'Ġhigh',
    'lights',
    'Ġir',
    're',
    'ĠNAT',
    'O',
    'Ġball',
    's',
    'Ġdemand',
    'ing',
    'U',
    'RE',
    'ĠL',
    'uke',
    'F',
    'igure',
    'st',
    'op',
    'on',
    'ia',
    'z',
    'one',
    'iz',
    'ers',
    'ĠW',
    'R',
    'Ġaward',
    'ed',
    'Ġregul',
    'atory',
    'ĠH',
    'art',
    'ĠS',
    'N',
    'pl',
    'ing',
    'Ġs',
    'our',
    'ĠP',
    'ixel',
    'us',
    'ive',
    'Ġf',
    'et',
    'ĠS',
    'ent',
    'Ġautom',
    'atic',
    'Ġf',
    'er',
    'vern',
    'ment',
    'ĠKh',
    'an',
    'T',
    'ON',
    'f',
    'ather',
    'Ġextraord',
    'inary',
    'th',
    'rop',
    'ĠP',
    'ython',
    'ĠG',
    'PU',
    'Ġsex',
    'ually',
    'Ġdesk',
    'top',
    'it',
    'ivity',
    'ĠAnton',
    'io',
    'Ġo',
    'rient',
    'Ġe',
    'ars',
    'ob',
    'by',
    'ous',
    'es',
    'vertis',
    'ements',
    'Ġmanufacture',
    'rs',
    'ic',
    'ient',
    'min',
    'ute',
    'Ġconv',
    'iction',
    'Ġg',
    'arden',
    'p',
    'ublic',
    'Ġsatisf',
    'ied',
    'f',
    'old',
    'O',
    'K',
    'Ġin',
    'hab',
    'ĠTh',
    'ink',
    'Ġprogram',
    'me',
    'Ġst',
    'omach',
    'Ġcoord',
    'in',
    'Ġh',
    'oly',
    'Ġth',
    'reshold',
    'Ġr',
    'het',
    'Ġser',
    'ial',
    'Ġemploy',
    'ers',
    'ĠEvery',
    'thing',
    'ra',
    'h',
    'Ġb',
    'other',
    'Ġbr',
    'ands',
    'Val',
    'ue',
    'ĠT',
    'ed',
    'ĠPlan',
    'et',
    'Ġp',
    'ink',
    'ĠFurther',
    'more',
    's',
    'a',
    'P',
    'E',
    're',
    'ck',
    'ĠUS',
    'D',
    'ot',
    'te',
    'Ġ&',
    '&',
    'Ġland',
    'ed',
    'g',
    'ets',
    'Ġprodu',
    'cers',
    'Ġhealth',
    'care',
    'Ġdomin',
    'ant',
    'Ġdest',
    'ro',
    'Ġam',
    'ended',
    'ch',
    'ron',
    'Ġf',
    'its',
    'ĠSy',
    'd',
    'ĠAuthor',
    'ity',
    'AT',
    'CH',
    'Ġfight',
    's',
    'ĠL',
    'LC',
    'Ġ--',
    '-',
    'ĠCor',
    'p',
    'Ġtox',
    'ic',
    'spe',
    'cific',
    'ĠC',
    'orn',
    'ĠChe',
    'l',
    'Ġtele',
    'phone',
    'ĠP',
    'ant',
    'Ġmyster',
    'ious',
    'aun',
    'ch',
    'od',
    'ox',
    'med',
    'ia',
    'Ġwitness',
    'es',
    'ag',
    'u',
    'Ġquestion',
    'ed',
    'ĠBre',
    'xit',
    'ĠRem',
    'ember',
    'ene',
    'z',
    'Ġend',
    'orse',
    'iat',
    'ric',
    'ĠId',
    'ent',
    'Ġridic',
    'ulous',
    '1',
    '10',
    'Ġpr',
    'ayer',
    'Ġscient',
    'ist',
    'Ġ19',
    '50',
    'ĠA',
    'qu',
    'Ġunder',
    'ground',
    'ĠU',
    'FC',
    'm',
    'are',
    'ĠL',
    'ater',
    'w',
    'ich',
    'Ġsubsc',
    'rib',
    'Ġhost',
    's',
    'Ġer',
    'r',
    'Ġgr',
    'ants',
    'ant',
    'om',
    'Ġsum',
    'mon',
    'ear',
    'ly',
    'ĠC',
    'lear',
    'ĠPr',
    'im',
    'Ġsusp',
    'ension',
    'Ġguarant',
    'eed',
    'app',
    'er',
    'Ġr',
    'ice',
    'ĠSe',
    'an',
    'ĠSh',
    'in',
    'Ġrefere',
    'ndum',
    'Ġfl',
    'ed',
    'r',
    'ust',
    'Ġ3',
    '60',
    'ter',
    'y',
    'Ġsh',
    'ocked',
    'B',
    'R',
    'ĠO',
    'il',
    'ĠAll',
    'ah',
    'Ġpart',
    'ly',
    'Ġign',
    'or',
    'Ġtrans',
    'mission',
    'Ġhom',
    'osexual',
    'ivers',
    'al',
    'Ġhop',
    'efully',
    'ãĤ',
    '¤',
    'Ġless',
    'on',
    'L',
    'eg',
    'Ġ',
    '..',
    'Y',
    'et',
    't',
    'able',
    'app',
    'ropri',
    're',
    'tt',
    'Ġbo',
    'ards',
    'Ġincor',
    'rect',
    'Ġb',
    'acteria',
    'ar',
    'u',
    'am',
    'ac',
    'Ġsn',
    'ap',
    ".'",
    '"',
    'Ġpar',
    'ad',
    't',
    'em',
    'he',
    'art',
    'Ġav',
    'ailability',
    'Ġw',
    'isdom',
    'Ġ(',
    '+',
    'Ġpri',
    'est',
    'ĠÂł',
    'ĠÂł',
    'O',
    'pen',
    'Ġsp',
    'an',
    'Ġparam',
    'eter',
    'Ġconv',
    'ince',
    'Ġ(',
    '%)',
    'r',
    'ac',
    'Ġf',
    'o',
    'Ġsafe',
    'ly',
    'Ġconver',
    'ted',
    'ĠOlymp',
    'ic',
    'Ġres',
    'erve',
    'Ġhe',
    'aling',
    'ĠM',
    'ine',
    'M',
    'ax',
    'Ġin',
    'herent',
    'ĠGra',
    'ham',
    'Ġinteg',
    'rated',
    'D',
    'em',
    'Ġpip',
    'eline',
    'Ġapp',
    'lying',
    'Ġem',
    'bed',
    'ĠCharl',
    'ie',
    'Ġc',
    'ave',
    '200',
    '8',
    'Ġcons',
    'ensus',
    'Ġre',
    'wards',
    'P',
    'al',
    'ĠHT',
    'ML',
    'Ġpopular',
    'ity',
    'look',
    'ing',
    'ĠSw',
    'ord',
    'ĠAr',
    'ts',
    "'",
    ')',
    'Ġelect',
    'ron',
    'clus',
    'ions',
    'Ġinteg',
    'rity',
    'Ġexclus',
    'ively',
    'Ġgr',
    'ace',
    'Ġtort',
    'ure',
    'Ġburn',
    'ed',
    'tw',
    'o',
    'Ġ18',
    '0',
    'P',
    'rodu',
    'Ġent',
    'reprene',
    'raph',
    'ics',
    'Ġg',
    'ym',
    'ric',
    'ane',
    'ĠT',
    'am',
    'Ġadministr',
    'ative',
    'Ġmanufacture',
    'r',
    'Ġ',
    'vel',
    'ĠN',
    'i',
    'Ġisol',
    'ated',
    'ĠMedic',
    'ine',
    'Ġback',
    'up',
    'Ġpromot',
    'ing',
    'Ġcommand',
    'er',
    'Ġfle',
    'e',
    'ĠRus',
    'sell',
    'Ġforg',
    'otten',
    'ĠMiss',
    'ouri',
    'Ġres',
    'idence',
    'm',
    'ons',
    'Ġrese',
    'mb',
    'Ġw',
    'and',
    'Ġmeaning',
    'ful',
    'P',
    'T',
    'Ġb',
    'ol',
    'Ġhe',
    'lic',
    'Ġwealth',
    'y',
    'Ġr',
    'ifle',
    'str',
    'ong',
    'row',
    'ing',
    'pl',
    'an',
    'as',
    'ury',
    'âĢ¦',
    '.',
    'Ġexpand',
    'ing',
    'ĠHam',
    'ilton',
    'Ġrece',
    'ives',
    'S',
    'I',
    'eat',
    'ures',
    'ĠAn',
    'im',
    'RE',
    'E',
    'P',
    'ut',
    'Ġbrief',
    'ly',
    'ri',
    've',
    'Ġstim',
    'ul',
    'Ġ``',
    '(',
    'Ġ',
    '__',
    'Ġch',
    'ip',
    'Ġha',
    'z',
    'Ġpri',
    'ze',
    'ĠTh',
    'ings',
    'AC',
    'E',
    'ul',
    'in',
    'd',
    'ict',
    'ok',
    'u',
    'Ġassoci',
    'ate',
    'ock',
    'ets',
    'y',
    'outube',
    'St',
    'ory',
    'ateg',
    'ory',
    'Ġm',
    'ild',
    'ail',
    'ing',
    'ĠY',
    'e',
    'O',
    'rig',
    'ĠK',
    'a',
    'or',
    'ig',
    'Ġpropag',
    'anda',
    'Ġan',
    'onymous',
    'Ġstrugg',
    'led',
    'Ġout',
    'rage',
    'AT',
    'ED',
    'ĠBe',
    'ijing',
    'r',
    'ary',
    'Ġle',
    'ather',
    'Ġworld',
    's',
    'Ġbroad',
    'er',
    '12',
    '5',
    'id',
    'al',
    'ĠBet',
    'ter',
    'Ġt',
    'ear',
    'E',
    'xt',
    'Ġpropos',
    'als',
    'Ġit',
    'er',
    'ĠSqu',
    'ad',
    'Ġvol',
    'unt',
    'm',
    'i',
    'D',
    'id',
    'ĠP',
    'u',
    'p',
    'in',
    'Ġspeak',
    'ers',
    'Ġb',
    'orders',
    'Ġfig',
    'ured',
    '=',
    "'",
    'Ġsimultane',
    'ously',
    'aed',
    'a',
    'Ġcharg',
    'ing',
    'Ġur',
    'ged',
    'Ġcon',
    'j',
    '25',
    '6',
    'ĠG',
    'ordon',
    'mer',
    'ce',
    'Ġdocument',
    'ary',
    'Sh',
    'are',
    'it',
    'ol',
    'ON',
    'E',
    'ĠG',
    'arden',
    'h',
    'att',
    'ĠThom',
    'pson',
    'ane',
    'ous',
    'ap',
    'ore',
    'Ġt',
    'anks',
    'Ġless',
    'ons',
    'tr',
    'ack',
    'Ġout',
    'standing',
    'Ġvolunte',
    'ers',
    'Ġsp',
    'ray',
    'Ġmanag',
    'ers',
    'l',
    'arge',
    'Ġcamp',
    's',
    'Ġart',
    'ificial',
    'ĠR',
    'u',
    'Ġb',
    'ags',
    'th',
    'al',
    'Ġcompat',
    'ible',
    'ĠBl',
    'ade',
    'Ġf',
    'ed',
    'Ġarg',
    'ues',
    'F',
    'I',
    'Ġunf',
    'air',
    'Ġcor',
    'n',
    'Ġoff',
    'set',
    'Ġdirect',
    'ions',
    'Ġdisappoint',
    'ed',
    'ĠCon',
    'vention',
    'Ġview',
    'ing',
    'M',
    'E',
    'oc',
    'ity',
    'Ġtown',
    's',
    'Ġlay',
    'ers',
    'Ġro',
    'lled',
    'Ġjump',
    'ed',
    'Ġatt',
    'ribute',
    'Ġun',
    'necess',
    'inc',
    'oln',
    'Ġsupp',
    'ose',
    'ĠNet',
    'her',
    'ch',
    'a',
    'Ġbur',
    'ied',
    'Ġsix',
    'th',
    'B',
    'en',
    'ress',
    'ing',
    'OU',
    'R',
    'Ġw',
    'ound',
    'Ġcy',
    'cl',
    'Ġmechan',
    'isms',
    'Ġcongress',
    'ional',
    'ĠE',
    'lement',
    'Ġagre',
    'ements',
    'Ġdec',
    'or',
    'Ġclos',
    'est',
    'ĠM',
    'it',
    'Go',
    'ogle',
    '}',
    '}',
    'Ġm',
    'ixture',
    'Ġflu',
    'id',
    'S',
    'ign',
    'ĠSch',
    'olar',
    'Ġp',
    'ist',
    'ask',
    'et',
    'ab',
    'ling',
    'Ġrac',
    'ing',
    'he',
    'ro',
    'ri',
    'el',
    'ass',
    'y',
    'Ġche',
    'aper',
    'b',
    'en',
    'Ġvert',
    'ical',
    'amac',
    'are',
    'ĠRead',
    'ing',
    'g',
    'ments',
    'Ġhelic',
    'op',
    'Ġsacr',
    'ifice',
    'ay',
    'a',
    'p',
    'aren',
    'V',
    'A',
    'ĠL',
    'es',
    'ĠStud',
    'io',
    'Ġviol',
    'ations',
    'ĠAn',
    'na',
    'ac',
    'er',
    'é',
    '¾',
    'ĠR',
    'at',
    'ĠBe',
    'ck',
    'ĠD',
    'ick',
    'ĠA',
    'CT',
    'Ġcomp',
    'osition',
    'Ġtext',
    'ure',
    'ĠO',
    'wn',
    'Ġsmart',
    'phone',
    'ĠN',
    'A',
    'Ġfor',
    'b',
    'im',
    'port',
    'Ġdef',
    'ending',
    'il',
    'st',
    're',
    'r',
    'Ġo',
    'h',
    'ĠJere',
    'my',
    'Ġbank',
    'ing',
    'cept',
    'ions',
    'Ġrespect',
    'ive',
    '/',
    '.',
    'Ġdr',
    'inks',
    'ĠW',
    'i',
    'Ġb',
    'ands',
    'ĠL',
    'iverpool',
    'Ġg',
    'rip',
    'ĠB',
    'uy',
    'Ġopen',
    'ly',
    'Ġreview',
    'ed',
    'per',
    't',
    'Ġver',
    'ify',
    'ĠCo',
    'le',
    'ĠW',
    'ales',
    'M',
    'O',
    'Ġun',
    'pre',
    'Ġshel',
    'ter',
    'ĠIm',
    'perial',
    'Ġgu',
    'i',
    'ĠD',
    'ak',
    'Ġsuggest',
    'ions',
    'Ġexplicit',
    'ly',
    'Ġsl',
    'ave',
    'Ġblock',
    'chain',
    'Ġcompet',
    'ing',
    'Ġprom',
    'ising',
    'S',
    'ON',
    'Ġsoc',
    'cer',
    'Ġconst',
    'itution',
    '4',
    '29',
    'Ġdist',
    'ract',
    'ĠU',
    'ser',
    'es',
    'ides',
    'ĠMet',
    'hod',
    'ĠTok',
    'yo',
    'Ġaccompan',
    'ied',
    'Cl',
    'ient',
    's',
    'ur',
    'al',
    'og',
    'Ġident',
    'ification',
    'Ġinv',
    'asion',
    'as',
    'ma',
    'Ġindust',
    'ries',
    'pp',
    'ers',
    'Ġsub',
    'tle',
    'ĠUn',
    'it',
    'n',
    'atural',
    'Ġsurv',
    'ived',
    'Ġfl',
    'aw',
    'ĺ',
    'ħ',
    'ĠH',
    'oll',
    'Ġdef',
    'icit',
    'Ġtut',
    'orial',
    'ĠCh',
    'ance',
    'Ġarg',
    'uing',
    'Ġcontem',
    'porary',
    'Ġinteg',
    'ration',
    'for',
    'ward',
    'Ġt',
    'um',
    'it',
    'is',
    'Ġh',
    'iding',
    'ĠD',
    'omin',
    'ĠT',
    'an',
    'ĠB',
    'uilding',
    'ĠV',
    'in',
    'Ġspokes',
    'person',
    'ĠNot',
    'es',
    'Ġemer',
    'ging',
    'Ġprepar',
    'ation',
    'Ġpro',
    'st',
    'Ġsuspect',
    's',
    'Ġaut',
    'onom',
    'D',
    'escription',
    'Ġdeal',
    't',
    'ĠP',
    'ear',
    'Ġstead',
    'y',
    'Ġdecre',
    'ased',
    'Ġso',
    'vere',
    'ĠCl',
    'in',
    'Ġgrad',
    'ually',
    'ors',
    'es',
    'ĠW',
    'AR',
    'S',
    'erv',
    'ãĤ',
    '¢',
    'h',
    'r',
    'Ġd',
    'irty',
    'ĠB',
    'arn',
    'ĠB',
    'C',
    'Ġd',
    'il',
    'Ġcal',
    'endar',
    'Ġcompl',
    'iance',
    'Ġch',
    'amber',
    'b',
    'b',
    'Ġpass',
    'enger',
    'ate',
    'ful',
    'ĠT',
    'itle',
    'ĠSyd',
    'ney',
    'ĠG',
    'ot',
    'Ġdark',
    'ness',
    'Ġdef',
    'ect',
    'Ġpack',
    'ed',
    'ass',
    'ion',
    'Ġgod',
    's',
    'Ġh',
    'arsh',
    'IC',
    'K',
    'le',
    'ans',
    'Ġalgorith',
    'm',
    'Ġoxy',
    'gen',
    'Ġvis',
    'its',
    'Ġbl',
    'ade',
    'Ġkil',
    'omet',
    'ĠKent',
    'ucky',
    'Ġkill',
    'er',
    'P',
    'ack',
    'enn',
    'y',
    'Ġdiv',
    'ine',
    'Ġnom',
    'ination',
    'be',
    'ing',
    'Ġeng',
    'ines',
    'Ġc',
    'ats',
    'Ġbuff',
    'er',
    'ĠPh',
    'ill',
    'Ġtra',
    'ff',
    'AG',
    'E',
    'Ġtong',
    'ue',
    'Ġrad',
    'iation',
    'ere',
    'r',
    'm',
    'em',
    'ĠExpl',
    'icit',
    'é¾',
    'į',
    'Ġcou',
    'ples',
    'Ġphys',
    'ics',
    'ĠMc',
    'K',
    'Ġpolit',
    'ically',
    'aw',
    'ks',
    'ĠBl',
    'oom',
    'Ġwor',
    'ship',
    'e',
    'ger',
    'ut',
    'er',
    'ĠF',
    'O',
    'Ġmat',
    'hemat',
    'Ġsent',
    'enced',
    'Ġdis',
    'k',
    'ĠM',
    'arg',
    'Ġ/',
    '*',
    'P',
    'I',
    'Ġoption',
    'al',
    'Ġbab',
    'ies',
    'Ġse',
    'eds',
    'ĠScott',
    'ish',
    'Ġth',
    'y',
    ']',
    ']',
    'ĠHit',
    'ler',
    'P',
    'H',
    'ng',
    'th',
    'Ġrec',
    'overed',
    'ing',
    'e',
    'Ġpow',
    'der',
    'Ġl',
    'ips',
    'Ġdesign',
    'er',
    'Ġdis',
    'orders',
    'Ġcour',
    'age',
    'Ġch',
    'aos',
    '"',
    '},{"',
    'Ġcar',
    'rier',
    'b',
    'ably',
    'H',
    'igh',
    'ĠR',
    'T',
    'es',
    'ity',
    'l',
    'en',
    'Ġrout',
    'es',
    'u',
    'ating',
    'F',
    'il',
    'N',
    'OT',
    'w',
    'all',
    's',
    'burgh',
    'Ġeng',
    'aging',
    'ĠJava',
    'Script',
    'ore',
    'r',
    'li',
    'hood',
    'Ġun',
    'ions',
    'ĠF',
    'ederation',
    'ĠTes',
    'la',
    'Ġcomple',
    'tion',
    'ĠT',
    'a',
    'Ġprivile',
    'ge',
    'ĠOr',
    'ange',
    'Ġne',
    'ur',
    'paren',
    'cy',
    'Ġb',
    'ones',
    'Ġtit',
    'led',
    'Ġprosecut',
    'ors',
    'ĠM',
    'E',
    'Ġengine',
    'er',
    'ĠUn',
    'iverse',
    'ĠH',
    'ig',
    'n',
    'ie',
    'o',
    'ard',
    'Ġheart',
    's',
    'ĠG',
    're',
    'uss',
    'ion',
    'Ġmin',
    'istry',
    'Ġpen',
    'et',
    'ĠN',
    'ut',
    'ĠO',
    'w',
    'ĠX',
    'P',
    'in',
    'stein',
    'Ġbul',
    'k',
    'S',
    'ystem',
    'ic',
    'ism',
    'ĠMarket',
    'able',
    'Ġpre',
    'val',
    'Ġpost',
    'er',
    'Ġatt',
    'ending',
    'ur',
    'able',
    'Ġlicens',
    'ed',
    'ĠG',
    'h',
    'et',
    'ry',
    'ĠTrad',
    'able',
    'Ġbl',
    'ast',
    'à',
    '¤',
    'ĠTit',
    'an',
    'ell',
    'ed',
    'd',
    'ie',
    'H',
    'ave',
    'ĠFl',
    'ame',
    'Ġprof',
    'ound',
    'Ġparticip',
    'ating',
    'Ġan',
    'ime',
    'ĠE',
    'ss',
    'Ġspec',
    'ify',
    'Ġregard',
    'ed',
    'ĠSpe',
    'll',
    'Ġs',
    'ons',
    'own',
    'ed',
    'Ġm',
    'erc',
    'Ġexper',
    'imental',
    'land',
    'o',
    'h',
    's',
    'ĠDun',
    'geon',
    'in',
    'os',
    'Ġcomp',
    'ly',
    'ĠSystem',
    's',
    'ar',
    'th',
    'Ġse',
    'ized',
    'l',
    'ocal',
    'ĠGirl',
    's',
    'ud',
    'o',
    'on',
    'ed',
    'ĠF',
    'le',
    'Ġconstruct',
    'ed',
    'Ġhost',
    'ed',
    'Ġsc',
    'ared',
    'act',
    'ic',
    'ĠIs',
    'lands',
    'ĠM',
    'ORE',
    'Ġbl',
    'ess',
    'Ġblock',
    'ing',
    'Ġch',
    'ips',
    'Ġev',
    'ac',
    'P',
    's',
    'Ġcorpor',
    'ation',
    'Ġo',
    'x',
    'Ġlight',
    'ing',
    'Ġneighb',
    'ors',
    'ĠU',
    'b',
    'ar',
    'o',
    'Ġbe',
    'ef',
    'ĠU',
    'ber',
    'F',
    'acebook',
    'ar',
    'med',
    'it',
    'ate',
    'ĠR',
    'ating',
    'ĠQu',
    'ick',
    'Ġoccup',
    'ied',
    'Ġaim',
    's',
    'ĠAdd',
    'itionally',
    'ĠInt',
    'erest',
    'Ġdram',
    'atically',
    'Ġhe',
    'al',
    'Ġpain',
    'ting',
    'Ġengine',
    'ers',
    'M',
    'M',
    'ĠM',
    'ust',
    'Ġquant',
    'ity',
    'P',
    'aul',
    'Ġearn',
    'ings',
    'ĠPost',
    's',
    'st',
    'ra',
    'ãĥ¼',
    'ãĥ',
    'Ġst',
    'ance',
    'Ġdro',
    'pping',
    'sc',
    'ript',
    'Ġd',
    'ressed',
    'M',
    'ake',
    'Ġjust',
    'ify',
    'ĠL',
    'td',
    'Ġprompt',
    'ed',
    'Ġscr',
    'ut',
    'Ġspeed',
    's',
    'ĠGi',
    'ants',
    'om',
    'er',
    'ĠEd',
    'itor',
    'Ġdescrib',
    'ing',
    'ĠL',
    'ie',
    'ment',
    'ed',
    'Ġnow',
    'here',
    'oc',
    'aly',
    'Ġinst',
    'ruction',
    'fort',
    'able',
    'Ġent',
    'ities',
    'Ġc',
    'm',
    'ĠN',
    'atural',
    'Ġinqu',
    'iry',
    'Ġpress',
    'ed',
    'iz',
    'ont',
    'for',
    'ced',
    'Ġra',
    'ises',
    'ĠNet',
    'flix',
    'ĠS',
    'ide',
    'Ġout',
    'er',
    'Ġamong',
    'st',
    'im',
    's',
    'ows',
    'ki',
    'Ġclim',
    'b',
    'ne',
    'ver',
    'Ġcomb',
    'ine',
    'd',
    'ing',
    'Ġcomp',
    'r',
    'Ġsignific',
    'ance',
    'Ġremem',
    'bered',
    'ĠNev',
    'ada',
    'ĠT',
    'el',
    'ĠSc',
    'ar',
    'ĠWar',
    'riors',
    'ĠJ',
    'ane',
    'Ġcou',
    'p',
    'b',
    'as',
    'Ġtermin',
    'al',
    ',',
    '-',
    'O',
    'H',
    'Ġt',
    'ension',
    'Ġw',
    'ings',
    'ĠMy',
    'ster',
    'ï¿½ï¿½',
    'ï¿½ï¿½',
    'ĠUn',
    'like',
    'val',
    'id',
    'viron',
    'ments',
    'ĠAl',
    'i',
    'Ġn',
    'aked',
    'book',
    's',
    'ĠM',
    'un',
    'ĠG',
    'ulf',
    'Ġd',
    'ensity',
    'Ġdim',
    'in',
    'Ġdesper',
    'ate',
    'Ġpres',
    'idency',
    'Ġ198',
    '6',
    'h',
    'y',
    'IN',
    'D',
    'Ġun',
    'lock',
    'im',
    'ens',
    'Ġhand',
    'led',
    'ĠE',
    'b',
    'Ġdisapp',
    'eared',
    'Ġgen',
    're',
    'Ġ198',
    '8',
    'Ġdetermin',
    'ation',
    'St',
    'ream',
    'ik',
    'o',
    'ap',
    'ters',
    'Ġacknow',
    'ledge',
    'J',
    'an',
    'Ġcapital',
    'ism',
    'P',
    'at',
    'Ġ20',
    '20',
    'Ġpain',
    'ful',
    'Ġcur',
    've',
    'Ġbom',
    'bs',
    'st',
    'orm',
    'ĠMet',
    'al',
    'en',
    'cer',
    'ĠF',
    'ig',
    'ĠA',
    'aron',
    'anc',
    'hes',
    'Ġins',
    'piration',
    'Ġexha',
    'ust',
    't',
    'ains',
    'ash',
    'i',
    'Ġdesc',
    'ript',
    'Ġr',
    'itual',
    'ĠChel',
    'sea',
    'Ġpromot',
    'ion',
    'ĠH',
    'ung',
    'ĠW',
    'ard',
    'iv',
    'a',
    'ĠE',
    'T',
    'Ġto',
    'ss',
    'all',
    'ow',
    'ĠFranc',
    'is',
    'D',
    'ep',
    'Ġhapp',
    'iness',
    'ĠGl',
    'ass',
    'Ġbet',
    'a',
    'Ġstreng',
    'then',
    'N',
    'E',
    'o',
    'a',
    'Ġbutt',
    'ons',
    'ĠMur',
    'ray',
    'Ġkick',
    'ed',
    'Qu',
    'est',
    'ĠT',
    'alk',
    'ĠS',
    'everal',
    'ĠZ',
    'ero',
    'Ġdr',
    'one',
    'ul',
    'k',
    'Ġc',
    'am',
    'ĠM',
    'obile',
    'Ġprevent',
    'ing',
    'Ġret',
    'ro',
    'ĠA',
    'x',
    'Ġcru',
    'el',
    'Ġflo',
    'at',
    '.',
    '),',
    'Ġfil',
    'ing',
    'ĠGr',
    'ant',
    'ĠB',
    'or',
    'Ġr',
    'ib',
    'Ġchampions',
    'hip',
    'ĠM',
    'erc',
    'Ġsty',
    'les',
    'Ġc',
    'ake',
    'Ġbuild',
    's',
    'ĠS',
    'elf',
    'io',
    'x',
    'Ġep',
    'ic',
    'oy',
    'd',
    'B',
    'el',
    'ĠSt',
    'ew',
    '.',
    '(',
    'ah',
    'u',
    'ĠBe',
    'yond',
    'Ġout',
    's',
    'Ġsol',
    'o',
    'ĠT',
    'ree',
    'Ġpres',
    'erve',
    'Ġt',
    'ub',
    'AR',
    'E',
    'ro',
    'c',
    'ĠIm',
    'pro',
    'ĠW',
    'right',
    'Ġbu',
    'nd',
    'Ġtr',
    'aged',
    'Ġoccas',
    'ional',
    'b',
    'ian',
    'Sec',
    'ond',
    'r',
    'ons',
    'Ġinter',
    'actions',
    'form',
    'ed',
    's',
    'ing',
    'Ġown',
    's',
    'Ġh',
    'ockey',
    'Gener',
    'al',
    'Ġlog',
    'ical',
    'Ġexp',
    'end',
    'Ġesc',
    'al',
    'ĠGr',
    'iff',
    'ĠC',
    'rown',
    'ĠRes',
    'erve',
    'Ġsto',
    'pping',
    'Ġexc',
    'use',
    'sec',
    'ond',
    'Ġoper',
    'ated',
    'Ġre',
    'aches',
    'ĠMal',
    'ays',
    'Ġpoll',
    'ution',
    'ĠBrook',
    'lyn',
    'Ġde',
    'lete',
    'Ġhas',
    'h',
    'Bl',
    'ock',
    'ah',
    'a',
    'âĢ',
    '³',
    'Ġsh',
    'orter',
    'p',
    'iece',
    '>',
    '</',
    'Ġh',
    'orm',
    'ĠW',
    'at',
    'ĠBre',
    'ak',
    'Ġprohib',
    'ited',
    'Ġint',
    'ensity',
    'ĠAl',
    'an',
    'Ġli',
    'ability',
    '?',
    '!',
    'and',
    'ed',
    'Ġneigh',
    'bour',
    'ĠCol',
    'lection',
    'Ġf',
    'ires',
    'Ġrevolution',
    'ary',
    'f',
    'ly',
    'ĠOr',
    'leans',
    'Wh',
    'ite',
    'ĠW',
    'rit',
    'ĠD',
    'awn',
    'Ġsett',
    'le',
    'Ġexec',
    'ute',
    'B',
    'M',
    'Ġspokes',
    'woman',
    'Ġlif',
    'estyle',
    'Ġclick',
    'ing',
    'ĠK',
    'ill',
    'ĠLiber',
    'al',
    'ĠN',
    'azi',
    'Ġtra',
    'iler',
    'Ġmount',
    'ains',
    'Ġdam',
    'n',
    'z',
    'es',
    'p',
    'es',
    'Ġpress',
    'ing',
    'Ġb',
    'ail',
    'ĠOrgan',
    'ization',
    'Ġp',
    'ir',
    'Ġth',
    'irty',
    'Ġelect',
    'rical',
    'Ġ1',
    '15',
    'ĠP',
    'oly',
    'ĠR',
    'ap',
    'ĠSt',
    'rike',
    'ĠC',
    'ann',
    'Ġdemand',
    'ed',
    'Ġback',
    'ing',
    'def',
    'ault',
    'spe',
    'ed',
    'ĠLeg',
    'isl',
    'Ġmother',
    's',
    'ĠB',
    'ody',
    'Ġvar',
    'iation',
    'ced',
    'ented',
    'p',
    'owered',
    'le',
    'ading',
    'N',
    'ever',
    'Ġg',
    'rave',
    'ĠAnt',
    'i',
    'A',
    'W',
    'Ġinterview',
    'ed',
    'ĠG',
    'ab',
    'ĠF',
    'at',
    'Ġrook',
    'ie',
    'u',
    'u',
    'Ġdep',
    'os',
    'ix',
    'on',
    'Ġam',
    'pl',
    'ret',
    'ion',
    'ĠHe',
    'at',
    'Ġpeace',
    'ful',
    'S',
    'M',
    'ie',
    've',
    'Ġd',
    'iver',
    'ĠVict',
    'oria',
    'Ġm',
    'ic',
    'p',
    'df',
    'Ġst',
    'ating',
    'Ġl',
    'ung',
    'Ġcritic',
    'ized',
    'Ġvacc',
    'ine',
    'ĠLoad',
    'ing',
    'ur',
    'se',
    'T',
    'ake',
    'ĠFr',
    'an',
    'ĠS',
    'old',
    'ĠRob',
    'in',
    'Ġdetect',
    'ed',
    'ĠSc',
    'ript',
    'Ġadjust',
    'ed',
    'Ġsen',
    'ator',
    'Ġopp',
    'osing',
    'Er',
    'ror',
    'C',
    'ount',
    'Ġconflic',
    'ts',
    'Ġo',
    'w',
    'ĠAr',
    'gent',
    'Ġmatch',
    'ing',
    'h',
    'h',
    'ĠTre',
    'k',
    'st',
    'arter',
    '"',
    '),',
    'ĠA',
    'F',
    'od',
    'er',
    'xx',
    'xx',
    'ĠAl',
    't',
    'ac',
    're',
    'ĠP',
    'ick',
    'ĠSol',
    'ar',
    'ĠD',
    'al',
    'O',
    'ct',
    'ĠB',
    'att',
    'Ġs',
    'rc',
    'Ġeng',
    'agement',
    'Ġexecut',
    'ives',
    'Ġliber',
    'ty',
    'j',
    'ava',
    'Ġtal',
    'ented',
    'igen',
    'ous',
    'Ġcon',
    'secut',
    '..',
    '...',
    'In',
    'fo',
    'Ġhor',
    'rible',
    'Ġsurprising',
    'ly',
    'f',
    'eed',
    'ic',
    'ating',
    'ĠL',
    'ED',
    'Ġfem',
    'ales',
    'St',
    'ation',
    'ell',
    'er',
    'ĠOak',
    'land',
    'Ġmechan',
    'ical',
    'i',
    'ology',
    'ĠV',
    'ar',
    'Ġrob',
    'ust',
    'ett',
    'ings',
    'ott',
    'a',
    'Ġthe',
    'oret',
    'Ġret',
    'ain',
    'k',
    'ward',
    'Ġd',
    'a',
    'Ġdeploy',
    'ed',
    'd',
    'el',
    'ĠAnd',
    'y',
    'Ġsubsc',
    'ribe',
    'we',
    'b',
    'Ġn',
    'a',
    'ĠMic',
    'hel',
    'Ġpart',
    'ially',
    'ĠCome',
    'y',
    'Ġc',
    'rown',
    'ĠM',
    'aj',
    'ĠBl',
    'u',
    'r',
    'ator',
    'D',
    'ay',
    'IN',
    'T',
    'Ġdocument',
    'ed',
    'ĠG',
    'DP',
    'g',
    'i',
    'che',
    'll',
    'Ġbrut',
    'al',
    'ĠB',
    'ab',
    'st',
    'ration',
    'Ġthe',
    'ft',
    'Ġt',
    'ube',
    '@',
    '@',
    'Ġqu',
    'ery',
    'ĠL',
    'incoln',
    'Ġpublish',
    'ing',
    'Ġw',
    'ore',
    'or',
    'ical',
    'Ġr',
    'ic',
    'Ġnot',
    'able',
    'Ġsubsequ',
    'ently',
    'ne',
    'x',
    'Ġobser',
    've',
    'ĠB',
    'oe',
    'Ġc',
    'odes',
    'm',
    'ain',
    'W',
    'H',
    'ĠS',
    'L',
    'Ġresident',
    'ial',
    'av',
    'an',
    'Ġm',
    'as',
    'are',
    'st',
    'ade',
    'on',
    'OU',
    'T',
    'Ġsoph',
    'istic',
    'ant',
    'e',
    'Ġc',
    'ens',
    'Ġ',
    '**',
    'Ġmort',
    'ality',
    'Ġyour',
    's',
    'Ġoccas',
    'ions',
    'Ġrec',
    'alled',
    'ĠDri',
    'ver',
    'Ġv',
    'ocal',
    'Ġbath',
    'room',
    'Ġsh',
    'ops',
    'Ġcollabor',
    'ation',
    'ĠOb',
    'amacare',
    'ĠC',
    'ell',
    'Ch',
    'ar',
    'Su',
    'per',
    'C',
    're',
    'Ġt',
    'ends',
    'Ġt',
    'orn',
    'Ġeconom',
    'ics',
    'a',
    'very',
    'ĠR',
    'aid',
    'ĠS',
    'em',
    'Ġshould',
    'ers',
    'Ġexpect',
    'ing',
    'Ġexam',
    'ination',
    'en',
    'ame',
    'ĠU',
    'I',
    'i',
    'ability',
    'ol',
    'as',
    'ĠAm',
    'b',
    'ĠD',
    'ra',
    'Ġmid',
    'field',
    'ĠI',
    'C',
    'Ġlay',
    'out',
    'Ġflo',
    'ating',
    'f',
    'i',
    'it',
    'ative',
    'Ġtremend',
    'ous',
    'Ġ',
    'Ð',
    'Ġab',
    'und',
    'W',
    'ork',
    'ĠLight',
    'ning',
    'Ġsimilar',
    'ly',
    'Ġconserv',
    'atives',
    'Ġpr',
    'ay',
    'B',
    'E',
    'iz',
    'arre',
    'Ġt',
    'empt',
    'Ġemphas',
    'is',
    'ĠMet',
    'ro',
    'Ġf',
    'ishing',
    'Ġmar',
    'ry',
    'ne',
    'g',
    'ĠStud',
    'y',
    'Ġrec',
    'k',
    'Ġdis',
    'pos',
    'on',
    'ing',
    'bs',
    'ite',
    'Ġsusp',
    'ic',
    'Ġmer',
    'ch',
    'ĠG',
    'ib',
    'ĠDes',
    'cription',
    'ĠD',
    'VD',
    'w',
    'he',
    'ĠY',
    'emen',
    'Ġen',
    'vironments',
    'oot',
    'ing',
    'ĠMod',
    'ern',
    'e',
    'u',
    'Ġreflect',
    's',
    'Ġh',
    'oney',
    'Ġanaly',
    'st',
    'Ġg',
    'ut',
    'd',
    'ec',
    'A',
    'ction',
    'Ġhousehold',
    's',
    'Ġst',
    'er',
    'Ġtem',
    'ple',
    'Ġreform',
    's',
    'Ġfavour',
    'ite',
    'Ġdead',
    'line',
    'ĠL',
    'E',
    'Th',
    'ree',
    'ĠWith',
    'in',
    'A',
    'ug',
    'Ġnight',
    's',
    'elt',
    'a',
    'Ġinv',
    'alid',
    'ĠEx',
    'change',
    'ĠDel',
    'hi',
    'w',
    'hen',
    'inc',
    'ome',
    'Ġ',
    'ðŁ',
    'Ġwire',
    'less',
    'sc',
    'ribe',
    'ist',
    'a',
    'Ġhost',
    'ile',
    'Ġall',
    'y',
    'Ġg',
    'ig',
    'Ġout',
    'lets',
    'ĠD',
    'or',
    'EM',
    'ENT',
    'Ġas',
    'h',
    'Ġab',
    'stract',
    'OR',
    'D',
    'ĠMot',
    'or',
    'Ġadv',
    'iser',
    'ist',
    'le',
    'Ġb',
    'ases',
    'Ġcourt',
    'esy',
    'Ġcross',
    'ing',
    'Ġcle',
    'ared',
    'Ġrefuge',
    'e',
    'cos',
    'ystem',
    'Ġthrow',
    's',
    'f',
    'un',
    'bour',
    'ne',
    'd',
    'ays',
    'Ġdisag',
    'ree',
    'ĠN',
    'ative',
    'Ġreflect',
    'ed',
    'ĠF',
    'ast',
    'ĠY',
    'ellow',
    'ĠSing',
    'apore',
    'ĠR',
    'aven',
    'Ġembr',
    'ace',
    'ĠK',
    'u',
    'ĠC',
    'hen',
    'ĠEar',
    'ly',
    'Ġappoint',
    'ment',
    'ĠMin',
    'i',
    'it',
    'ement',
    'Ġpl',
    'acing',
    'Ġb',
    'icy',
    'S',
    'R',
    'Ġwh',
    'is',
    'S',
    'U',
    'Ġinvestig',
    'ated',
    'Ġphotograph',
    's',
    'g',
    'ithub',
    'ĠBe',
    'at',
    'ĠR',
    'ing',
    'ig',
    'hed',
    'i',
    'ar',
    'Ġev',
    'olved',
    'eral',
    'd',
    'Ġd',
    'un',
    'Ġh',
    'ub',
    'I',
    'AL',
    'Ġencour',
    'aging',
    'ĠPr',
    'int',
    'ĠD',
    'ays',
    'Ġpro',
    'secution',
    'Ġp',
    'ants',
    'az',
    'y',
    'l',
    'ive',
    'Ġfoss',
    'il',
    'ĠJ',
    'u',
    'Ġro',
    'cks',
    'ud',
    'ge',
    'ĠR',
    'ace',
    'Ġg',
    'reet',
    'b',
    'ie',
    'Ġf',
    'illing',
    'ĠL',
    'en',
    'Ġdi',
    'abetes',
    'Ġfire',
    'arms',
    'um',
    'ing',
    'enez',
    'uel',
    'ĠB',
    'B',
    'Ġaccept',
    'ing',
    'AT',
    'H',
    'Ġres',
    'ort',
    'Ġh',
    'unt',
    'ri',
    'k',
    'uck',
    'er',
    'am',
    'ents',
    'Ġsust',
    'ained',
    'Ġcross',
    'ed',
    'Ġbreak',
    'fast',
    'Ġatt',
    'ributes',
    'lect',
    'ed',
    'at',
    'ile',
    'Ġv',
    'ibr',
    'ĠK',
    'al',
    'ars',
    'on',
    'op',
    'les',
    'Ġtou',
    'ched',
    'Ġdam',
    'ages',
    'Ġimp',
    'ressed',
    'ru',
    'p',
    'Ġan',
    'ch',
    'ĠAd',
    'ams',
    'H',
    'el',
    'ĠVict',
    'or',
    'Ġmount',
    'ed',
    'ĠC',
    'C',
    'Ġdelic',
    'ious',
    'sp',
    'an',
    'ell',
    'a',
    'Ġel',
    'abor',
    'am',
    'ples',
    'Ġdef',
    'ic',
    'Ġconstit',
    'u',
    'u',
    'ates',
    'ĠM',
    'ission',
    'ĠT',
    'her',
    'ĠMon',
    'ster',
    'b',
    'es',
    'Re',
    'uters',
    'ĠInd',
    'ones',
    'h',
    'ill',
    'mun',
    'ition',
    'Ġconfirm',
    'ation',
    'ĠCons',
    'ider',
    'ac',
    'ent',
    'Ġj',
    'et',
    'ĠEm',
    'ploy',
    'ĠGT',
    'X',
    'n',
    'an',
    'ĠSp',
    'ider',
    'Ġprocess',
    'or',
    'Ġpat',
    'ri',
    'ĠPent',
    'agon',
    'ĠRob',
    'inson',
    'Ġreal',
    'istic',
    'Ã',
    '±',
    'Ġappear',
    'ing',
    'Ġp',
    'ipe',
    'om',
    'ed',
    'Ġf',
    'ru',
    'Ġaw',
    'ful',
    'Ġeval',
    'uation',
    'Ġintellig',
    'ent',
    'ĠC',
    'itiz',
    'Ġfund',
    'ra',
    'od',
    'ium',
    'Ġtwe',
    'ets',
    'Ġwor',
    'n',
    'pr',
    'ing',
    'Ġkid',
    'n',
    'Ġreb',
    'els',
    'ĠK',
    'am',
    'ĠNether',
    'lands',
    'ĠS',
    'W',
    'Ġacqu',
    'isition',
    'ĠM',
    'ale',
    'ãĥ',
    'ª',
    'omb',
    'ies',
    'Ġtrad',
    'em',
    'ĠStat',
    'us',
    'B',
    're',
    'ĠTH',
    'IS',
    'Ġad',
    'verse',
    'ĠN',
    'EW',
    's',
    'ign',
    'Ġorgan',
    'isation',
    'en',
    'c',
    'ĠHar',
    'per',
    'ap',
    'or',
    'ĠMem',
    'bers',
    'ĠPe',
    'ace',
    'ĠAir',
    'port',
    'ĠOther',
    's',
    'Ġscr',
    'atch',
    'ĠP',
    'il',
    'Ġsens',
    'or',
    'Ġadop',
    'tion',
    'ĠHot',
    'el',
    'ĠDr',
    'ag',
    'Ġhonest',
    'ly',
    'Ġy',
    'ard',
    'ĠFor',
    'ces',
    'Ġpat',
    'ent',
    'Ġb',
    'ass',
    'Ġquiet',
    'ly',
    'Ġbreat',
    'hing',
    'Ġp',
    'ose',
    'i',
    'ors',
    'ĠJ',
    'ess',
    'st',
    'atic',
    'IT',
    'E',
    'O',
    'ffic',
    'Ġj',
    'ew',
    'w',
    'cs',
    'Ġ14',
    '0',
    'Ġpre',
    'view',
    'ipp',
    'i',
    'Ġunf',
    'ortunately',
    'oke',
    'mon',
    'Ġh',
    'orn',
    'Ġre',
    'ass',
    'Ġpe',
    'er',
    'ock',
    'er',
    'Ġunt',
    'o',
    'ĠGr',
    'ay',
    'Ġclean',
    'ing',
    'Ġattract',
    'ed',
    '200',
    '7',
    'P',
    'oint',
    'k',
    'ill',
    'ĠAg',
    'reement',
    'ur',
    'ches',
    'Ġhor',
    'r',
    'ĠMiss',
    'iss',
    'Ġworth',
    'y',
    'Ġfl',
    'owers',
    't',
    'own',
    'd',
    'll',
    'Ġre',
    'actions',
    'Ġde',
    'ce',
    'Ġindic',
    'ating',
    'M',
    'D',
    'Ġpre',
    'ference',
    'ĠM',
    'VP',
    'ess',
    'ional',
    'ĠT',
    'arget',
    'g',
    'ence',
    'ĠInd',
    'ians',
    'Ġm',
    'isc',
    'Ġfree',
    'ly',
    'Ġmus',
    'cles',
    'Ġline',
    'up',
    'Ġimpact',
    's',
    'ous',
    'ing',
    'om',
    'i',
    'ac',
    'ular',
    'Ġcontro',
    'lling',
    'ag',
    'ine',
    'c',
    'ery',
    'he',
    'll',
    'Ġrank',
    'ing',
    'ĠN',
    'ich',
    'ĠA',
    've',
    '12',
    '8',
    'Ġhigh',
    'way',
    'Ġinc',
    'ons',
    'Ġb',
    'inding',
    'Ġstrugg',
    'les',
    'ĠPitt',
    'sburgh',
    'Ġgr',
    'ay',
    'r',
    'in',
    'Ġcom',
    'ics',
    'ĠS',
    'port',
    'Ġrel',
    'atives',
    'Ġfr',
    'ight',
    'Ġpro',
    'be',
    'ĠPort',
    'ug',
    'Ġv',
    'oc',
    'Ġt',
    'u',
    'ĠCor',
    'ps',
    'Ġposs',
    'ibilities',
    'Ġqual',
    'ify',
    'wcs',
    'store',
    'Ġl',
    'ibraries',
    'Ġm',
    'igrants',
    'Ġent',
    'ries',
    'Ġconsecut',
    'ive',
    'v',
    'als',
    'ĠChair',
    'man',
    'Ġh',
    'ill',
    'IM',
    'E',
    'ĠG',
    'ard',
    'Ġinequ',
    'ality',
    'f',
    'ox',
    'ĠS',
    'ave',
    'Ġc',
    'ort',
    'claim',
    'ed',
    'Ġtra',
    'its',
    'Ġp',
    'our',
    'Ġmiss',
    'iles',
    'Ġess',
    'ence',
    'Ġs',
    'ends',
    'Ġall',
    'iance',
    'Ġw',
    'ishes',
    'ĠChrist',
    'opher',
    'B',
    'ig',
    'N',
    'Y',
    'ĠJac',
    'ob',
    's',
    'an',
    'ur',
    'red',
    'ĠS',
    'O',
    'll',
    'y',
    'Ġadvoc',
    'ate',
    'ĠB',
    'ond',
    'Ġ"',
    '/',
    'Us',
    'ing',
    'Ġdistrict',
    's',
    'ĠG',
    'ate',
    'ĠB',
    'ir',
    'r',
    'idge',
    'ĠN',
    'az',
    'ĠR',
    's',
    'bo',
    'ards',
    'ĠG',
    'a',
    'ĠRe',
    'agan',
    'Ġinflu',
    'enced',
    '1',
    '000',
    'ap',
    'y',
    'Ġchalleng',
    'ed',
    'Ġb',
    'arg',
    'Ġfac',
    'ulty',
    'ĠF',
    'if',
    'Ġacqu',
    'ire',
    'A',
    'c',
    'Ġin',
    'sect',
    'Ġinstr',
    'uments',
    'Ġle',
    'af',
    'th',
    'odox',
    'M',
    'essage',
    'Ġt',
    'ale',
    'Ġthere',
    'by',
    'Ġtra',
    'p',
    'Ġstrong',
    'est',
    'ĠMil',
    'itary',
    'is',
    'ible',
    'Ġ198',
    '4',
    'ethe',
    'less',
    'Ġflex',
    'ible',
    'Ġkill',
    's',
    'Ġfin',
    'ishing',
    'ĠS',
    'ize',
    'Ġredu',
    'ces',
    'Ġep',
    'id',
    'Ġorient',
    'ation',
    'f',
    'ull',
    'Ġtr',
    'ace',
    'Ġl',
    'aser',
    'Ġopp',
    'ose',
    'Ġed',
    'iting',
    'Ġmoment',
    'um',
    'ä',
    'º',
    'sh',
    'ow',
    'V',
    'I',
    'ĠL',
    'ad',
    'Ġ198',
    '5',
    'Ġmurd',
    'ered',
    '9',
    '00',
    'ut',
    'her',
    'Ġprob',
    'ability',
    'ĠP',
    'oll',
    'Ġrel',
    'uct',
    'ĠChe',
    'm',
    'ĠMont',
    'real',
    'Ġadequ',
    'ate',
    'ĠPol',
    'and',
    'ĠSher',
    'iff',
    'um',
    'ph',
    'Ġo',
    'k',
    'Ġ',
    '000',
    'Ġ"',
    '[',
    'Ġoper',
    'ators',
    'ĠF',
    'er',
    'Ġmod',
    'es',
    'ĠE',
    've',
    'Ġdiscipl',
    'ine',
    'N',
    'ET',
    'H',
    'and',
    'Ġor',
    'al',
    'ĠW',
    'E',
    'em',
    'ail',
    'J',
    'P',
    'ĠPalestin',
    'ians',
    'Ġhe',
    'nce',
    'ĠL',
    'ess',
    'Ġover',
    'l',
    'd',
    'ig',
    'Ġintim',
    'id',
    'ĠCo',
    'al',
    'Ġr',
    'anging',
    'th',
    'a',
    'Ġdist',
    'ant',
    'Ġf',
    'ib',
    'ĠInd',
    'ex',
    'ĠW',
    'onder',
    'ĠP',
    'el',
    'hatt',
    'an',
    'ĠH',
    'ug',
    'Ã',
    'Ĺ',
    'ra',
    'it',
    'Ġwra',
    'pped',
    'ĠR',
    'PG',
    'Ġchemical',
    's',
    'ĠM',
    'oney',
    'Ġfro',
    'zen',
    'Ġind',
    'irect',
    'ĠAgain',
    'st',
    'E',
    'nd',
    'Ġuncom',
    'fortable',
    'ĠGall',
    'ery',
    'ĠPost',
    'ed',
    'Ø',
    '§',
    'ond',
    'uct',
    'Ġconsequ',
    'ence',
    'Ġbit',
    'ter',
    'Ġ198',
    '7',
    'p',
    'op',
    'Ġcount',
    'less',
    'ĠAl',
    'aska',
    'ff',
    'ff',
    'Ġdepart',
    'ure',
    'Ġref',
    'und',
    'ĠI',
    'an',
    'i',
    'ated',
    'Ġsee',
    'ks',
    'Ġmechan',
    'ics',
    'Ġjurisd',
    'iction',
    'lyn',
    'n',
    'Ġal',
    'ike',
    'ĠH',
    'unt',
    'ath',
    'on',
    'Ġres',
    'olved',
    'Ġc',
    'ache',
    'Ġdist',
    'inction',
    'd',
    'irect',
    'Ġenc',
    'ount',
    'ou',
    'b',
    'be',
    'at',
    'ĠCount',
    'ry',
    'se',
    'arch',
    'Ġcontin',
    'uous',
    'Ġmod',
    'est',
    'ĠR',
    'ail',
    'th',
    'ood',
    '1',
    '30',
    'B',
    'UG',
    'Ġcrim',
    'inals',
    'Ġindic',
    'ation',
    'Ġencount',
    'ered',
    'l',
    'ast',
    'ĠW',
    'y',
    'Ġide',
    'ology',
    'ĠP',
    'DF',
    'sec',
    'urity',
    ']',
    ')',
    'ĠJim',
    'my',
    'ĠE',
    'N',
    'Ġh',
    'iring',
    'T',
    'em',
    'Ġp',
    'ig',
    'aun',
    't',
    'ĠCry',
    'stal',
    'Ġpen',
    'alties',
    'Ġcap',
    'ability',
    'Ġp',
    'y',
    'Ġproduct',
    'ive',
    'Ġbal',
    'anced',
    'ĠGe',
    'Force',
    'cl',
    'ick',
    'olit',
    'an',
    'od',
    's',
    'Ġafter',
    'wards',
    'Ġplay',
    'offs',
    'ĠG',
    'ill',
    'U',
    'ser',
    'Ġback',
    's',
    'p',
    'ub',
    't',
    'ag',
    'Ġabs',
    'urd',
    'p',
    'iring',
    'Ġc',
    'iting',
    'Ġtr',
    'illion',
    'Ġoblig',
    'ation',
    'Ġmax',
    'im',
    'ah',
    'oo',
    'c',
    'f',
    'um',
    'i',
    'ĠAl',
    'pha',
    'ĠN',
    'elson',
    'Ġpursu',
    'ant',
    'in',
    'itely',
    'Ġf',
    'ract',
    'ent',
    'ry',
    'ber',
    'y',
    'ĠTh',
    'or',
    'Add',
    'ed',
    'ĠD',
    'J',
    'ĠG',
    'ene',
    'Ġaw',
    'kward',
    'St',
    'ud',
    'Ġwal',
    'let',
    'ĠDiv',
    'ine',
    'ari',
    'os',
    'Ġrele',
    'asing',
    'Ġed',
    'ited',
    'Ġaccompl',
    'ished',
    'B',
    'est',
    'Ġed',
    'ges',
    'Ġplan',
    'es',
    'Ġfeed',
    'ing',
    '"',
    '},"',
    'Ġdiscl',
    'osure',
    'Ġgr',
    'ain',
    'air',
    'y',
    'o',
    'ons',
    'ern',
    'and',
    'V',
    'R',
    'Ġreason',
    'ably',
    'Ġdr',
    'um',
    'Ġpart',
    'ial',
    'Ġgraph',
    'ic',
    'Ġunpre',
    'cedented',
    'Ġadv',
    'ised',
    'M',
    'icro',
    'ĠAss',
    'ad',
    'point',
    's',
    'sc',
    'ar',
    'ĠZ',
    'one',
    'tt',
    'es',
    'Ġ7',
    '00',
    'v',
    'o',
    'ĠH',
    'amp',
    'Ġfix',
    'es',
    'Ġca',
    'ution',
    'Ġstr',
    'ings',
    'Ġpan',
    'els',
    'Ġle',
    'ak',
    'Ġpr',
    'icing',
    'row',
    'th',
    'ĠEr',
    'ror',
    'ĠS',
    'aints',
    'f',
    'ix',
    'Ġobserv',
    'ations',
    'ĠA',
    'bs',
    'Ġsuggest',
    'ion',
    'ĠUkrain',
    'ian',
    'Ġbar',
    'rier',
    'Ġpain',
    'ted',
    'B',
    'et',
    'im',
    'ir',
    'ĠS',
    'pect',
    'p',
    'ot',
    'orne',
    'ys',
    'Ġcomp',
    'ound',
    'Ġbe',
    'ars',
    'ĠR',
    'ush',
    'Ġlux',
    'ury',
    'S',
    'um',
    'Ġor',
    'bit',
    'ĠMar',
    'c',
    'Ġex',
    'empt',
    'ĠTra',
    'il',
    'ĠM',
    'O',
    'ĠH',
    'ans',
    'ĠWe',
    'apon',
    'oc',
    'used',
    'umin',
    'um',
    'ĠJer',
    'ry',
    'Ġb',
    'ust',
    'ĠA',
    'G',
    'ĠW',
    'iki',
    'Ġend',
    'less',
    'ĠV',
    'lad',
    'ĠB',
    'ah',
    'ĠR',
    'adeon',
    'ke',
    'ys',
    'ĠSur',
    'vey',
    'ĠV',
    'iol',
    'def',
    'ine',
    'le',
    'an',
    'Ġcomm',
    'od',
    'Ġreven',
    'ues',
    'Å',
    'į',
    'Ġfurn',
    'iture',
    'Ġcast',
    'ing',
    'Ġdiplom',
    'atic',
    'ĠPlay',
    'ers',
    'ĠK',
    'illed',
    'Ġmod',
    'ify',
    'Ġinnov',
    'ative',
    'ĠAb',
    'u',
    'n',
    'or',
    'Ġbond',
    's',
    'Ġcoach',
    'ing',
    'M',
    'er',
    'Ġmod',
    'ules',
    'ĠPatri',
    'ots',
    'Ġenh',
    'anced',
    'Ġproceed',
    'ings',
    'Ġteam',
    'mates',
    'Ġ12',
    '8',
    'ard',
    'o',
    'Ġcomprom',
    'ise',
    'ĠM',
    'uch',
    'Ġfle',
    'w',
    'ĠEd',
    'ge',
    'Ġunnecess',
    'ary',
    'Ġdoct',
    'rine',
    're',
    'port',
    'ĠOr',
    'lando',
    'ĠProf',
    'ile',
    'Ġplay',
    'off',
    'friend',
    'ly',
    'Ġcompl',
    'ain',
    'ĠM',
    'C',
    'ĠO',
    'pt',
    'ĠG',
    'B',
    'Ġbeat',
    'en',
    'Ġg',
    'olf',
    'Ġpl',
    'acement',
    'B',
    'it',
    'Ġnews',
    'letter',
    'Ġ201',
    '9',
    'vis',
    'or',
    'raw',
    'l',
    'ĠiP',
    'ad',
    'Ġact',
    'ed',
    'Ġju',
    'ice',
    'Ġdec',
    'ks',
    'P',
    'N',
    'su',
    'ccess',
    'ĠH',
    'alf',
    'Ġdele',
    'ted',
    'Ġsec',
    'rets',
    'Ġas',
    'ylum',
    'M',
    'art',
    'ĠAct',
    'iv',
    'ĠGu',
    'y',
    'ĠT',
    's',
    'Ġd',
    'ys',
    'Ġassum',
    'ing',
    'Ġman',
    'a',
    'Ġsub',
    'ur',
    'Ġ12',
    '5',
    'M',
    'edia',
    'AR',
    'Y',
    'r',
    'ide',
    'c',
    'p',
    'Ġdifficult',
    'ies',
    'Ġcollect',
    'ing',
    'Ġbank',
    'rupt',
    'n',
    'on',
    'Ġcomp',
    'osed',
    'Ġvol',
    't',
    'Ġmilit',
    'ants',
    'Ġ>',
    '>>',
    'ĠM',
    'ormon',
    't',
    'or',
    'Ġpartic',
    'les',
    'ĠB',
    'art',
    'ry',
    'ption',
    'Ġad',
    'min',
    'Ġsqu',
    'ee',
    'VID',
    'IA',
    'Ġcreat',
    'or',
    'iam',
    'eter',
    'ic',
    'ular',
    'N',
    'BC',
    'Ġgrab',
    'bed',
    'Ġn',
    'odd',
    'Ġr',
    'ated',
    'Ġrot',
    'ation',
    'Ġgr',
    'asp',
    'Ġexcess',
    'ive',
    'ĠE',
    'C',
    'ĠWh',
    'it',
    'Ġinvent',
    'ory',
    'ault',
    's',
    'ĠF',
    'B',
    'Ġe',
    'cosystem',
    'Ġbill',
    'ions',
    'Ġvent',
    'ure',
    'n',
    'amed',
    'Ġdef',
    'ender',
    'out',
    'e',
    'Inst',
    'ead',
    'ir',
    'able',
    'W',
    'ar',
    'Ġassum',
    'ption',
    'Ġb',
    'ite',
    'Ġearth',
    'qu',
    't',
    'ail',
    'sp',
    'ace',
    'Ġgif',
    'ts',
    'boy',
    's',
    'Ġinev',
    'itable',
    'Ġstruct',
    'ural',
    'Ġbenef',
    'icial',
    'Ġcompe',
    'lling',
    'h',
    'ole',
    'erv',
    'ation',
    'Ġco',
    'at',
    'o',
    'j',
    'inc',
    'arn',
    'ĠY',
    'ears',
    'Ġdetermin',
    'ing',
    'Ġrhet',
    'oric',
    'Ġbound',
    'aries',
    'Ġwh',
    'ites',
    'A',
    'nt',
    'add',
    'y',
    ')',
    '-',
    'ra',
    'ham',
    'eter',
    'min',
    'Ġhar',
    'vest',
    'ĠCon',
    'c',
    'Ġlapt',
    'op',
    'ĠM',
    'atch',
    'Ġenjoy',
    'ing',
    'cc',
    'a',
    'oll',
    'ar',
    'Ġtri',
    'ps',
    'Ġadd',
    'iction',
    'ĠS',
    'ak',
    'Ġpow',
    'ered',
    'Ġc',
    'ous',
    'ĠRuss',
    'ians',
    'ie',
    're',
    'Ġret',
    'rie',
    'qu',
    'ality',
    'Ġdiff',
    'er',
    'Ġking',
    'dom',
    'ĠL',
    'aur',
    'ĠCap',
    'itol',
    'Ġcon',
    'clusions',
    'ĠAl',
    'tern',
    'ĠN',
    'av',
    'Ġtrans',
    'parent',
    'B',
    'ER',
    'G',
    'roup',
    'ĠCom',
    'plete',
    'Ġinf',
    'er',
    'Ġint',
    'rig',
    'Ġins',
    'ane',
    'R',
    'O',
    'oph',
    'ob',
    'is',
    'en',
    'qu',
    'al',
    'Mich',
    'ael',
    'Ġm',
    'useum',
    'ĠP',
    'ope',
    'Ġres',
    'et',
    'r',
    'ative',
    'f',
    'ive',
    'Ġagg',
    'reg',
    'itte',
    'es',
    'osit',
    'ory',
    'Ġcar',
    'b',
    'ĠRec',
    'ord',
    'Ġdec',
    'ides',
    'ĠF',
    'ix',
    'Ġexcept',
    'ions',
    'ĠCommission',
    'er',
    'un',
    's',
    'ĠEnvironment',
    'al',
    'Ġlegend',
    'ary',
    'ist',
    'ence',
    'Ġtun',
    'nel',
    'k',
    'm',
    'Ġins',
    'ult',
    'Ġt',
    'roll',
    'Ġsh',
    'ake',
    'Ġdet',
    'ention',
    'qu',
    'es',
    'ĠCh',
    'rome',
    'ĠF',
    'iles',
    'Ġsub',
    't',
    'Ġprospect',
    's',
    'Ġpro',
    'l',
    're',
    'nder',
    'pro',
    'of',
    'Ġperform',
    'ances',
    'St',
    'r',
    'Ġh',
    'ref',
    'ern',
    'ame',
    'Ġachieve',
    'ment',
    'Ġf',
    'ut',
    'F',
    'ull',
    'ĠLe',
    'ban',
    'go',
    'ogle',
    'ãĥ',
    'Ī',
    'amp',
    'a',
    'May',
    'be',
    'Ġproject',
    'ed',
    'ĠE',
    'mb',
    'Ġcol',
    'leg',
    'Ġa',
    'wards',
    'Ġâ',
    'Ķ',
    'G',
    'old',
    'ĠBl',
    'ake',
    'ĠR',
    'aj',
    'if',
    'ting',
    'Ġp',
    'ending',
    'Ġinst',
    'inct',
    'Ġdevelop',
    'ments',
    'Con',
    'nect',
    'ĠM',
    'and',
    'ĠW',
    'ITH',
    'ĠPhilipp',
    'ines',
    'prof',
    'ile',
    'Ġalt',
    'ogether',
    'ĠB',
    'und',
    'ĠT',
    'D',
    'oo',
    'oo',
    'amp',
    'ed',
    'ip',
    'h',
    'Ġste',
    'am',
    'Ġold',
    'est',
    'Ġdet',
    'ection',
    'ul',
    'pt',
    'Ġ',
    'ç',
    'ĠWay',
    'ne',
    '200',
    '6',
    'f',
    'a',
    'Ġcir',
    'cles',
    'ĠF',
    'u',
    'Ġdon',
    'ors',
    'appropri',
    'ate',
    'ĠDak',
    'ota',
    'j',
    'amin',
    'Ġmotiv',
    'ated',
    'Ġpurch',
    'ases',
    'ĠLouis',
    'iana',
    'ĠS',
    'pl',
    'Ġgl',
    'obe',
    'Ġ10',
    '5',
    'z',
    'ip',
    'c',
    'all',
    'Ġdepart',
    'ments',
    'Ġsustain',
    'able',
    '10',
    '5',
    'ĠO',
    'P',
    'if',
    'iers',
    'Ġprevent',
    'ed',
    'Ġinc',
    'omp',
    'ĠComm',
    'ander',
    'Ġdom',
    'inated',
    'ĠÂ',
    '»',
    'Ġinvest',
    'ed',
    'Ġcomplex',
    'ity',
    'Ġin',
    'cl',
    'Ġens',
    'uring',
    'Ġreal',
    'm',
    'yn',
    'c',
    'ĠInd',
    'ependent',
    'r',
    'ained',
    'ĠJ',
    'en',
    'ĠFl',
    'ight',
    'Ġat',
    'he',
    'Ġspec',
    'ulation',
    'ĠT',
    'E',
    'oc',
    'ate',
    't',
    'ic',
    'Ġpl',
    'aint',
    'her',
    'ry',
    'Ġto',
    'y',
    'Ġ1',
    '11',
    'Ġpl',
    'ates',
    'st',
    'atus',
    'ĠIs',
    'a',
    'Ġdev',
    'oted',
    'C',
    'op',
    'ĠE',
    'S',
    '25',
    '5',
    'ur',
    'rency',
    'M',
    'ain',
    'Ġsl',
    'aves',
    'Ġpe',
    'pper',
    'Ġqu',
    'otes',
    'Ġce',
    'iling',
    'ĠF',
    'ish',
    'Ġtrans',
    'formation',
    'Ġfra',
    'ction',
    'Ġadvant',
    'ages',
    'Ġto',
    'ile',
    'Ġstun',
    'ning',
    'Ġmo',
    'ist',
    'bre',
    'aking',
    's',
    'i',
    'ĠL',
    'ocation',
    'ĠMed',
    'ium',
    'Ġtext',
    's',
    'Ġu',
    'gly',
    'Ġb',
    'io',
    '.',
    'âĢĶ',
    'ĠB',
    'ased',
    'Ġtr',
    'ains',
    'ĠW',
    'ing',
    'ĠAn',
    'cient',
    'ĠRec',
    'ords',
    'ĠH',
    'ope',
    'Spe',
    'cial',
    'ades',
    'h',
    'ob',
    'i',
    '[',
    '/',
    'Ġtempor',
    'arily',
    'V',
    'er',
    'h',
    'u',
    'os',
    'er',
    'Ġover',
    'night',
    'Ġm',
    'amm',
    'ĠTre',
    'asury',
    'ĠV',
    'enezuel',
    'ĠMeg',
    'a',
    'Ġt',
    'ar',
    'Ġexpect',
    's',
    'bl',
    'ack',
    'or',
    'ph',
    '\\\\',
    '\\\\',
    'Ġaccept',
    'ance',
    'Ġrad',
    'ar',
    's',
    'is',
    'Ġjun',
    'ior',
    'Ġfram',
    'es',
    'Ġobserv',
    'ation',
    'ac',
    'ies',
    'P',
    'ower',
    'ĠAdv',
    'anced',
    'M',
    'ag',
    'olog',
    'ically',
    'ĠMe',
    'chan',
    'Ġsent',
    'ences',
    'Ġanaly',
    'sts',
    'augh',
    'ters',
    'force',
    'ment',
    'Ġv',
    'ague',
    'Ġcl',
    'ause',
    'Ġdirect',
    'ors',
    'Ġeval',
    'uate',
    'Ġcabin',
    'et',
    'M',
    'att',
    'ĠClass',
    'ic',
    'A',
    'ng',
    'Ġcl',
    'er',
    'ĠB',
    'uck',
    'Ġresear',
    'cher',
    'Ġ16',
    '0',
    'Ġpoor',
    'ly',
    'Ġexperien',
    'cing',
    'ĠP',
    'ed',
    'ĠMan',
    'hattan',
    'Ġfre',
    'ed',
    'Ġthem',
    'es',
    'ad',
    'vant',
    'Ġn',
    'in',
    'Ġpra',
    'ise',
    '10',
    '4',
    'ĠLib',
    'ya',
    'b',
    'est',
    'Ġtrust',
    'ed',
    'Ġce',
    'ase',
    'Ġd',
    'ign',
    'D',
    'irect',
    'Ġbomb',
    'ing',
    'Ġm',
    'igration',
    'ĠSci',
    'ences',
    'Ġmunicip',
    'al',
    'ĠA',
    'verage',
    'Ġgl',
    'ory',
    'Ġreve',
    'aling',
    'Ġare',
    'na',
    'Ġuncertain',
    'ty',
    'Ġbattle',
    'field',
    'ia',
    'o',
    'G',
    'od',
    'Ġc',
    'inem',
    'ra',
    'pe',
    'el',
    'le',
    'ap',
    'ons',
    'Ġlist',
    'ing',
    'Ġwa',
    'ited',
    'Ġsp',
    'otted',
    'ke',
    'ley',
    'ĠAud',
    'io',
    'e',
    'or',
    'ard',
    'ing',
    'idd',
    'ing',
    'ig',
    'ma',
    'ĠN',
    'eg',
    'Ġl',
    'one',
    'Ġ',
    '----',
    'ex',
    'e',
    'd',
    'eg',
    'Ġtrans',
    'f',
    'Ġwas',
    'h',
    'Ġsl',
    'avery',
    'Ġexpl',
    'oring',
    'ĠW',
    'W',
    'ats',
    'on',
    'Ġen',
    'cl',
    'l',
    'ies',
    'ĠC',
    'reek',
    'Ġwood',
    'en',
    'Man',
    'ager',
    'ĠBr',
    'and',
    'um',
    'my',
    'ĠAr',
    'thur',
    'Ġbureau',
    'cr',
    'Ġbl',
    'end',
    'ar',
    'ians',
    'F',
    'urther',
    'Ġsupposed',
    'ly',
    'Ġwind',
    's',
    'Ġ19',
    '79',
    'Ġgrav',
    'ity',
    'Ġanalys',
    'es',
    'ĠTra',
    'vel',
    'ĠV',
    'eter',
    'Ġd',
    'umb',
    'Ġaltern',
    'ate',
    'g',
    'al',
    'Ġconsum',
    'ed',
    'Ġeffect',
    'iveness',
    ".'",
    "'",
    'Ġpath',
    's',
    'ond',
    'a',
    'L',
    'A',
    'ĠStr',
    'ong',
    'Ġen',
    'ables',
    'Ġesc',
    'aped',
    'Ġ"',
    '"',
    'Ġ1',
    '12',
    'Ġ198',
    '3',
    'Ġsm',
    'iled',
    'Ġtend',
    'ency',
    'F',
    'ire',
    'Ġp',
    'ars',
    'ĠR',
    'oc',
    'Ġl',
    'ake',
    'Ġf',
    'itness',
    'ĠA',
    'th',
    'ĠH',
    'orn',
    'Ġh',
    'ier',
    'Ġimp',
    'ose',
    'm',
    'other',
    'Ġp',
    'ension',
    'ic',
    'ut',
    'bor',
    'ne',
    'ic',
    'iary',
    '.',
    '_',
    'ĠS',
    'U',
    'Ġpol',
    'ar',
    'is',
    'y',
    'eng',
    'u',
    'itial',
    'ized',
    'AT',
    'A',
    'w',
    'rite',
    'Ġexerc',
    'ises',
    'ĠD',
    'iamond',
    'ot',
    'ypes',
    'Ġharm',
    'ful',
    'on',
    'z',
    'Ġprint',
    'ing',
    'st',
    'ory',
    'Ġexpert',
    'ise',
    'ĠG',
    'er',
    'Ġtraged',
    'y',
    'ĠF',
    'ly',
    'Ġd',
    'ivid',
    'amp',
    'ire',
    'st',
    'ock',
    'M',
    'em',
    'Ġre',
    'ign',
    'Ġun',
    've',
    'Ġam',
    'end',
    'ĠProp',
    'het',
    'Ġmut',
    'ual',
    'ĠF',
    'ac',
    'Ġrepl',
    'acing',
    'H',
    'ar',
    'ĠCirc',
    'uit',
    'Ġthro',
    'at',
    'ĠSh',
    'ot',
    'Ġbatter',
    'ies',
    'Ġto',
    'll',
    'Ġaddress',
    'ing',
    'ĠMedic',
    'aid',
    'Ġp',
    'upp',
    'ĠN',
    'ar',
    'ol',
    'k',
    'Ġequ',
    'ity',
    'M',
    'R',
    'ĠHis',
    'pan',
    'ĠL',
    'arge',
    'm',
    'id',
    'D',
    'ev',
    'Ġexp',
    'ed',
    'Ġdem',
    'o',
    'ĠMarsh',
    'all',
    'erg',
    'us',
    'Ġf',
    'iber',
    'Ġdiv',
    'orce',
    'ĠCre',
    'ate',
    'Ġsl',
    'ower',
    'ĠPark',
    'er',
    'ĠStud',
    'ent',
    'ĠTr',
    'aining',
    'Ret',
    'urn',
    'ĠT',
    'ru',
    'Ġc',
    'ub',
    'ĠRe',
    'ached',
    'Ġpan',
    'ic',
    'Ġqu',
    'arters',
    'Ġre',
    'ct',
    'Ġtreat',
    'ing',
    'Ġr',
    'ats',
    'ĠChristian',
    'ity',
    'ol',
    'er',
    'Ġsac',
    'red',
    'Ġdecl',
    'are',
    'ul',
    'ative',
    'et',
    'ing',
    'Ġdeliver',
    'ing',
    'est',
    'one',
    'Ġt',
    'el',
    'ĠL',
    'arry',
    'Ġmet',
    'a',
    'ac',
    'cept',
    'art',
    'z',
    'ĠRog',
    'er',
    'hand',
    'ed',
    'Ġhead',
    'er',
    'Ġtra',
    'pped',
    'ĠCent',
    'ury',
    'Ġkn',
    'ocked',
    'ĠOx',
    'ford',
    'Ġsurviv',
    'ors',
    'b',
    'ot',
    'Ġdemon',
    'stration',
    'Ġd',
    'irt',
    'Ġass',
    'ists',
    'OM',
    'E',
    'ĠD',
    'raft',
    'ortun',
    'ate',
    'fol',
    'io',
    'pe',
    'red',
    'ust',
    'ers',
    'g',
    't',
    'ĠL',
    'ock',
    'Ġjud',
    'icial',
    'ver',
    'ted',
    'Ġsec',
    'ured',
    'out',
    'ing',
    'ĠBook',
    's',
    'Ġhost',
    'ing',
    'Ġlif',
    'ted',
    'l',
    'ength',
    'Ġj',
    'er',
    'Ġwhe',
    'els',
    'ĠR',
    'ange',
    'umbn',
    'ails',
    'Ġdiagn',
    'osis',
    'te',
    'ch',
    'ĠStew',
    'art',
    'ĠP',
    'ract',
    'Ġnation',
    'wide',
    'Ġde',
    'ar',
    'Ġoblig',
    'ations',
    'Ġgrow',
    's',
    'Ġmand',
    'atory',
    'Ġsusp',
    'icious',
    '!',
    "'",
    'A',
    'pr',
    'G',
    'reat',
    'Ġmort',
    'gage',
    'Ġprosecut',
    'or',
    'Ġeditor',
    'ial',
    'ĠK',
    'r',
    'Ġprocess',
    'ed',
    'ung',
    'le',
    'Ġflex',
    'ibility',
    'Ear',
    'lier',
    'ĠC',
    'art',
    'ĠS',
    'ug',
    'Ġfoc',
    'uses',
    'Ġstart',
    'up',
    'Ġbre',
    'ach',
    'ĠT',
    'ob',
    'cy',
    'cle',
    'ãĢ',
    'Į',
    'ro',
    'se',
    'Ġb',
    'izarre',
    'ãĢ',
    'į',
    'Ġveget',
    'ables',
    '$',
    '$',
    'Ġret',
    'reat',
    'osh',
    'i',
    'ĠSh',
    'op',
    'ĠG',
    'round',
    'ĠSt',
    'op',
    'ĠHawai',
    'i',
    'ĠA',
    'y',
    'Per',
    'haps',
    'ĠBe',
    'aut',
    'uff',
    'er',
    'enn',
    'a',
    'Ġproduct',
    'ivity',
    'F',
    'ixed',
    'cont',
    'rol',
    'Ġabs',
    'ent',
    'ĠCamp',
    'aign',
    'G',
    'reen',
    'Ġident',
    'ifying',
    'Ġreg',
    'ret',
    'Ġpromot',
    'ed',
    'ĠSe',
    'ven',
    'Ġer',
    'u',
    'ne',
    'ath',
    'aug',
    'hed',
    'ĠP',
    'in',
    'ĠL',
    'iving',
    'C',
    'ost',
    'om',
    'atic',
    'me',
    'ga',
    'ĠN',
    'ig',
    'oc',
    'y',
    'Ġin',
    'box',
    'Ġem',
    'pire',
    'Ġhor',
    'izont',
    'Ġbr',
    'anches',
    'Ġmet',
    'aph',
    'Act',
    'ive',
    'ed',
    'i',
    'ĠFil',
    'm',
    'ĠS',
    'omething',
    'Ġmod',
    's',
    'inc',
    'ial',
    'ĠOrig',
    'inal',
    'G',
    'en',
    'Ġspir',
    'its',
    'Ġear',
    'ning',
    'H',
    'ist',
    'Ġr',
    'iders',
    'Ġsacr',
    'ific',
    'M',
    'T',
    'ĠV',
    'A',
    'ĠS',
    'alt',
    'Ġoccup',
    'ation',
    'ĠM',
    'i',
    'Ġdis',
    'g',
    'lic',
    't',
    'Ġn',
    'it',
    'Ġn',
    'odes',
    'e',
    'em',
    'ĠP',
    'ier',
    'Ġhat',
    'red',
    'ps',
    'y',
    'ãĥ',
    'ī',
    'Ġthe',
    'ater',
    'Ġsophistic',
    'ated',
    'Ġdef',
    'ended',
    'Ġbes',
    'ides',
    'Ġthorough',
    'ly',
    'ĠMedic',
    'are',
    'Ġbl',
    'amed',
    'arent',
    'ly',
    'Ġcry',
    'ing',
    'F',
    'OR',
    'pri',
    'v',
    'Ġsing',
    'ing',
    'ĠI',
    'l',
    'Ġc',
    'ute',
    'o',
    'ided',
    'olit',
    'ical',
    'ĠNe',
    'uro',
    'å',
    '¤',
    'Ġdon',
    'ation',
    'ĠEag',
    'les',
    'ĠG',
    'ive',
    'T',
    'om',
    'Ġsubstant',
    'ially',
    'ĠLic',
    'ense',
    'ĠJ',
    'a',
    'Ġg',
    'rey',
    'ĠAn',
    'imal',
    'ĠE',
    'R',
    'ĠU',
    'nd',
    'Ġke',
    'en',
    'Ġconclud',
    'e',
    'ĠMississ',
    'ippi',
    'Eng',
    'ine',
    'ĠStud',
    'ios',
    'P',
    'ress',
    'o',
    'vers',
    'll',
    'ers',
    'Ġ3',
    '50',
    'ĠR',
    'angers',
    'Ġr',
    'ou',
    'ert',
    'o',
    'E',
    'p',
    'iss',
    'a',
    'iv',
    'an',
    'Ġse',
    'al',
    'ĠReg',
    'ist',
    'dis',
    'play',
    'Ġwe',
    'aken',
    'u',
    'um',
    'ĠComm',
    'ons',
    'ĠS',
    'ay',
    'Ġcult',
    'ures',
    'Ġl',
    'aughed',
    'Ġsl',
    'ip',
    'Ġtreat',
    'ments',
    'iz',
    'able',
    'm',
    'art',
    'ĠR',
    'ice',
    'Ġbe',
    'ast',
    'Ġob',
    'esity',
    'ĠLa',
    'ure',
    'ig',
    'a',
    'Wh',
    'ich',
    'hold',
    'er',
    'Ġelder',
    'ly',
    'Ġp',
    'ays',
    'Ġcompl',
    'ained',
    'Ġc',
    'rop',
    'Ġpro',
    'c',
    'Ġexplos',
    'ive',
    'ĠF',
    'an',
    'ĠAr',
    'senal',
    'A',
    'uthor',
    'ef',
    'ul',
    'Ġme',
    'als',
    'Ġ(',
    '-',
    'id',
    'ays',
    'Ġimag',
    'ination',
    'Ġann',
    'ually',
    'Ġm',
    's',
    'as',
    'ures',
    'H',
    'ead',
    'ik',
    'h',
    'm',
    'atic',
    'Ġboy',
    'friend',
    'ĠCom',
    'puter',
    'Ġb',
    'ump',
    'Ġsur',
    'ge',
    'ĠCra',
    'ig',
    'ĠKir',
    'k',
    'D',
    'el',
    'medi',
    'ate',
    'Ġscen',
    'arios',
    'ĠM',
    'ut',
    'ĠSt',
    'ream',
    'Ġcompet',
    'itors',
    'Ù',
    'Ħ',
    'ĠStan',
    'ford',
    'ĠRes',
    'ources',
    'az',
    'ed',
    'b',
    'age',
    'Ġorgan',
    'is',
    'ĠRe',
    'lease',
    'Ġsepar',
    'ately',
    'Ġha',
    'bits',
    'Ġmeasure',
    'ments',
    'ĠCl',
    'ose',
    'Ġaccomp',
    'any',
    'Ġg',
    'ly',
    'Ġt',
    'ang',
    'ĠR',
    'ou',
    'Ġplug',
    'in',
    'Ġcon',
    'vey',
    'ĠChall',
    'enge',
    'oot',
    's',
    'j',
    'an',
    'Ġcur',
    's',
    'ĠRel',
    'ations',
    'ke',
    'eper',
    'Ġapproach',
    'ing',
    'p',
    'ing',
    'Spe',
    'aking',
    'Ġarrang',
    'ement',
    'ĠV',
    'I',
    'are',
    'ttes',
    'Ġaffect',
    'ing',
    'Ġperm',
    'its',
    'b',
    'ecause',
    'Ġu',
    'seless',
    'ĠH',
    'us',
    '!!',
    '!!',
    'Ġdestro',
    'ying',
    'Un',
    'fortunately',
    'Ġfasc',
    'inating',
    'S',
    'em',
    'Ġelect',
    'oral',
    'Ġtrans',
    'parency',
    'ĠCh',
    'aos',
    'Ġvolunte',
    'er',
    'Ġstatist',
    'ical',
    'Ġactiv',
    'ated',
    'ro',
    'x',
    'We',
    'b',
    'H',
    'E',
    'ĠHamp',
    'shire',
    'is',
    'ive',
    'M',
    'ap',
    'Ġtr',
    'ash',
    'ĠLaw',
    'rence',
    'st',
    'ick',
    'C',
    'r',
    'Ġr',
    'ings',
    'EX',
    'T',
    'Ġoper',
    'ational',
    'op',
    'es',
    'D',
    'oes',
    'ĠEv',
    'ans',
    'Ġwitness',
    'ed',
    'P',
    'ort',
    'Ġlaunch',
    'ing',
    'ec',
    'onom',
    'w',
    'ear',
    'ĠPart',
    'icip',
    'um',
    'm',
    'cul',
    'es',
    'ĠR',
    'AM',
    'ĠT',
    'un',
    'Ġass',
    'ured',
    'Ġb',
    'inary',
    'Ġbet',
    'ray',
    'Ġexpl',
    'oration',
    'ĠF',
    'el',
    'Ġad',
    'mission',
    'it',
    'ated',
    'S',
    'y',
    'Ġav',
    'oided',
    'ĠSim',
    'ulator',
    'Ġcelebr',
    'ated',
    'ĠElect',
    'ric',
    '¥',
    'ŀ',
    'Ġcl',
    'uster',
    'itzer',
    'land',
    'he',
    'alth',
    'L',
    'ine',
    'ĠN',
    'ash',
    'at',
    'on',
    'Ġsp',
    'are',
    'Ġenter',
    'prise',
    'ĠD',
    'IS',
    'clud',
    'es',
    'Ġfl',
    'ights',
    'Ġreg',
    'ards',
    'ĠÃ',
    'Ĺ',
    'h',
    'alf',
    'Ġtr',
    'ucks',
    'Ġcontact',
    's',
    'Ġunc',
    'ons',
    'ĠCl',
    'imate',
    'Ġimm',
    'ense',
    'N',
    'EW',
    'oc',
    'c',
    'ect',
    'ive',
    'Ġemb',
    'od',
    'Ġpat',
    'rol',
    'Ġbes',
    'ide',
    'Ġv',
    'iable',
    'Ġcre',
    'ep',
    'Ġtrig',
    'gered',
    'ver',
    'ning',
    'Ġcompar',
    'able',
    'q',
    'l',
    'Ġg',
    'aining',
    'ass',
    'es',
    'Ġ(',
    ');',
    'ĠG',
    'rey',
    'ĠM',
    'LS',
    's',
    'ized',
    'Ġpros',
    'per',
    '"',
    '?',
    'Ġpoll',
    'ing',
    'Ġsh',
    'ar',
    'ĠR',
    'C',
    'Ġfire',
    'arm',
    'or',
    'ient',
    'Ġf',
    'ence',
    'Ġvari',
    'ations',
    'g',
    'iving',
    'ĠP',
    'i',
    'osp',
    'el',
    'Ġpled',
    'ge',
    'Ġc',
    'ure',
    'Ġsp',
    'y',
    'Ġviol',
    'ated',
    'Ġr',
    'ushed',
    'Ġstro',
    'ke',
    'ĠBl',
    'og',
    'sel',
    's',
    'ĠE',
    'c',
    ",'",
    "'",
    'Ġp',
    'ale',
    'ĠColl',
    'ins',
    'ter',
    'ror',
    'ĠCanad',
    'ians',
    'Ġt',
    'une',
    'Ġlabor',
    'atory',
    'Ġn',
    'ons',
    't',
    'arian',
    'Ġdis',
    'ability',
    'ĠG',
    'am',
    'Ġsing',
    'er',
    'al',
    'g',
    'ĠSen',
    'ior',
    'Ġtrad',
    'ed',
    'ĠWar',
    'rior',
    'Ġinf',
    'ring',
    'ĠFrank',
    'lin',
    'Ġstr',
    'ain',
    'ĠSwed',
    'ish',
    'Ġsevent',
    'h',
    'ĠB',
    'enn',
    'ĠT',
    'ell',
    'Ġsynd',
    'rome',
    'Ġwond',
    'ered',
    'id',
    'en',
    '++',
    '++',
    'ig',
    'o',
    'Ġpur',
    'ple',
    'Ġjournal',
    'ism',
    'Ġreb',
    'el',
    'Ġf',
    'u',
    'bl',
    'og',
    'Ġinv',
    'ite',
    'ren',
    'cies',
    'ĠCont',
    'act',
    'Is',
    'rael',
    'ĠCont',
    'ent',
    'Ġche',
    'er',
    'Ġbed',
    'room',
    'ĠEngine',
    'ering',
    'ĠQue',
    'ens',
    'Ġd',
    'well',
    'ĠPlay',
    'Station',
    'ĠD',
    'im',
    'ĠCol',
    'on',
    'l',
    'r',
    'Ġoper',
    'ates',
    'Ġmotiv',
    'ation',
    'US',
    'A',
    'ast',
    'ered',
    'C',
    'ore',
    'ĠTr',
    'uth',
    'ol',
    'o',
    'OS',
    'E',
    'ĠMem',
    'ory',
    'Ġpred',
    'ec',
    'Ġan',
    'arch',
    'Ġ19',
    '20',
    'ĠY',
    'am',
    'Ã',
    '¨',
    'b',
    'id',
    'Ġgr',
    'ateful',
    'Ġexc',
    'itement',
    'Ġtre',
    'asure',
    'Ġlong',
    'est',
    'ct',
    'ive',
    'Ġdes',
    'erves',
    'Ġreserv',
    'es',
    'Ġcop',
    's',
    'ĠOtt',
    'awa',
    'ĠEgypt',
    'ian',
    'ank',
    'ed',
    'Ġart',
    'if',
    'Ġhypot',
    'hesis',
    ':',
    '/',
    'Ġpurch',
    'asing',
    'Ġlove',
    'ly',
    'H',
    'P',
    'Ġdiv',
    'ide',
    'Ġstrict',
    'ly',
    'Ġquestion',
    'ing',
    'Ġtaxp',
    'ayers',
    'ĠJ',
    'oy',
    'Ġroll',
    's',
    'ĠHe',
    'avy',
    'Ġp',
    'orts',
    'Ġmag',
    'netic',
    'Ġinf',
    'lamm',
    'Ġbr',
    'ush',
    't',
    'ics',
    'â',
    'ĪĴ',
    'Ġbott',
    'les',
    'pp',
    'y',
    'Ġp',
    'add',
    'ãĤ',
    '¯',
    'm',
    'illion',
    'Ġdevast',
    'ating',
    'Ġcomp',
    'iled',
    'Ġmed',
    'ication',
    'Ġtw',
    'elve',
    'ĠPer',
    'ry',
    'Sp',
    'ace',
    'im',
    'b',
    'y',
    'our',
    'Ġle',
    'aked',
    'ĠT',
    'ar',
    'Ġun',
    'ity',
    'Ġinfect',
    'ed',
    'Ġtravel',
    'ed',
    'ID',
    'E',
    'ĠMc',
    'Donald',
    't',
    'xt',
    'ĠPr',
    'inc',
    'Ġinter',
    'ven',
    'ĠTai',
    'wan',
    'ĠP',
    'ow',
    'Ġbe',
    'aring',
    'ĠTh',
    'read',
    'Ġz',
    'ones',
    'iz',
    'ards',
    'un',
    'ks',
    'Ch',
    'apter',
    'll',
    'or',
    'ĠÂ',
    '·',
    'Ġw',
    'ounds',
    'Ġdisc',
    'retion',
    'Ġsucceed',
    'ed',
    'ik',
    'ing',
    'Ġicon',
    'ic',
    'C',
    'all',
    'Ġscreen',
    'ing',
    'ĠM',
    'is',
    'ict',
    's',
    'Ġmin',
    'isters',
    'Ġsepar',
    'ation',
    'Pl',
    'ayer',
    'Ġb',
    'ip',
    'Ġbel',
    'oved',
    'Ġcount',
    'ing',
    'ĠE',
    'ye',
    'ar',
    'ound',
    'ing',
    'ing',
    'Ġtable',
    't',
    'Ġoff',
    'ence',
    'in',
    'ance',
    'h',
    'ave',
    'ĠInf',
    'o',
    'ĠNin',
    'ja',
    'Ġprotect',
    'ive',
    'ĠC',
    'ass',
    'M',
    'ac',
    'ĠQual',
    'ity',
    'N',
    'orth',
    'Ġ',
    'ic',
    'ĠCub',
    'a',
    'ĠChron',
    'icle',
    'ĠPro',
    'perty',
    'Ġfast',
    'est',
    'ot',
    'os',
    'ĠG',
    'erm',
    'OW',
    'N',
    'Ġbo',
    'om',
    'ĠStan',
    'ley',
    'ergus',
    'on',
    'Ġcle',
    'ver',
    'Ġent',
    'ers',
    'm',
    'ode',
    'ter',
    'ior',
    'ĠS',
    'ens',
    'Ġlin',
    'ear',
    'AR',
    'K',
    'Ġcomp',
    'aring',
    'Ġpure',
    'ly',
    'Ġsaf',
    'er',
    'ĠPot',
    'ter',
    'Ġc',
    'ups',
    'R',
    'T',
    'Ġgl',
    'uc',
    'Ġatt',
    'ributed',
    'Ġdu',
    'pl',
    'ĠP',
    'ap',
    'Ġprec',
    'ious',
    'Ġp',
    'a',
    'iction',
    'ary',
    'ĠT',
    'ig',
    'ĠTo',
    'o',
    'ol',
    'utions',
    'st',
    'an',
    'Ġrob',
    'ots',
    'Ġlob',
    'b',
    'Ġstat',
    'ute',
    'Ġprevent',
    'ion',
    'w',
    'estern',
    '16',
    '0',
    'ĠAct',
    'ive',
    'ĠMar',
    'ia',
    'h',
    'al',
    'N',
    'one',
    'ell',
    'ar',
    'ĠK',
    'B',
    'ĠPart',
    'ners',
    'ĠSing',
    'le',
    'ĠFollow',
    'ing',
    'ang',
    'o',
    'ac',
    'ious',
    'Ġth',
    'ou',
    'Ġk',
    'g',
    'Ġinflu',
    'ential',
    'ĠFriend',
    's',
    'S',
    'ur',
    'ain',
    'ted',
    'Ġfor',
    'ums',
    'Ġst',
    'arter',
    'Ġcitizens',
    'hip',
    'ĠE',
    'lection',
    'on',
    'ge',
    'ot',
    'ation',
    'os',
    'ph',
    ';;',
    ';;',
    'ut',
    'ical',
    'p',
    'ur',
    'ere',
    'n',
    'Ġaccus',
    'ations',
    'bit',
    'ious',
    'ab',
    'bit',
    'ĠOr',
    'd',
    'Post',
    'ed',
    'ir',
    'k',
    'Ġsens',
    'itivity',
    'ic',
    'he',
    'ĠAm',
    'y',
    'ĠF',
    'ab',
    'Ġsum',
    'mit',
    'Ġped',
    'est',
    'Ġrub',
    'ber',
    'Ġagric',
    'ultural',
    'Ġcan',
    'cel',
    'A',
    'E',
    'Ġin',
    'aug',
    'Ġcont',
    'am',
    'Ġfirm',
    'ly',
    'i',
    'w',
    'st',
    'age',
    'ĠK',
    'an',
    'Ġt',
    'ier',
    'Ġinv',
    'ention',
    'Ġtransl',
    'ated',
    'ĠR',
    'ules',
    'B',
    'ox',
    'Tw',
    'itter',
    'ID',
    'S',
    'Ġp',
    'izza',
    'Ġdeb',
    'ug',
    'ĠD',
    'rop',
    'v',
    's',
    'Ġh',
    'orses',
    'b',
    'ig',
    'Ġb',
    'oring',
    'Ġh',
    'ood',
    'ĠMcC',
    'ain',
    'at',
    'ched',
    'ĠBro',
    's',
    'Ġsk',
    'ip',
    'Ġess',
    'ay',
    'st',
    'at',
    'ĠLeg',
    'ends',
    'Ġam',
    'munition',
    'au',
    'c',
    'Ġshoot',
    'er',
    'Ġun',
    'h',
    'Ġsuppl',
    'ied',
    'Ġgener',
    'ic',
    'ĠS',
    'K',
    'ib',
    'an',
    'yr',
    'ics',
    'Ġ25',
    '5',
    'Ġclim',
    'bing',
    'Form',
    'er',
    'Ġfl',
    'ip',
    'Ġjump',
    'ing',
    'Ġfrust',
    'ration',
    'ĠTer',
    'ry',
    'Ġneighborhood',
    's',
    'Ġmed',
    'ian',
    'be',
    'an',
    'Ġbr',
    'ains',
    'Follow',
    'ing',
    'Ġsh',
    'aped',
    'Ġdraw',
    's',
    'Ġal',
    'tered',
    'J',
    'ack',
    'Ġrecip',
    'es',
    'Ġsk',
    'illed',
    'we',
    'alth',
    'ach',
    'i',
    'e',
    'lection',
    'Ġbehavi',
    'ors',
    'de',
    'als',
    'ĠU',
    'ntil',
    'F',
    'e',
    'Ġdecl',
    'aration',
    'mar',
    'ks',
    'ĠBet',
    'ween',
    'cel',
    'ona',
    'Ġres',
    'on',
    'Ġbub',
    'ble',
    'Am',
    'ong',
    'Ġim',
    'perial',
    'G',
    'S',
    'Ġfemin',
    'ist',
    '200',
    '5',
    'ĠK',
    'yle',
    'Ġaccount',
    'ing',
    'ĠTe',
    'le',
    'ĠT',
    'yr',
    'Ġconnect',
    'ing',
    'Ġre',
    'hab',
    'ĠP',
    'red',
    's',
    'im',
    'Ġmeant',
    'ime',
    'Ġphys',
    'ician',
    'M',
    'W',
    'ĠCamp',
    'bell',
    'ĠBr',
    'andon',
    'Ġcontribut',
    'ing',
    'ĠR',
    'ule',
    'ĠWe',
    'ight',
    'ĠN',
    'ap',
    'Ġinter',
    'active',
    'Ġv',
    'ag',
    'Ġhel',
    'met',
    'ĠCom',
    'b',
    'f',
    'our',
    'Ġsh',
    'ipped',
    'Ġcomple',
    'ting',
    'ĠP',
    'D',
    'PD',
    'ATE',
    'Ġspread',
    'ing',
    'Ġsc',
    'ary',
    'erv',
    'ing',
    'ĠG',
    'as',
    'Ġfr',
    'ank',
    's',
    'chool',
    'Ġrom',
    'antic',
    'Ġstab',
    'il',
    'R',
    'ob',
    'Ġaccur',
    'ately',
    'Ġac',
    'ute',
    'ĠH',
    'ann',
    'Ġsymbol',
    's',
    'Ġcivil',
    'ization',
    'ĠA',
    'W',
    'Ġlight',
    'ning',
    'Ġcons',
    'iders',
    'Ġven',
    'ue',
    'Ġ',
    '×',
    'Ġo',
    'ven',
    'ĠS',
    'F',
    'h',
    'is',
    'Ġn',
    'u',
    'ĠLear',
    'n',
    'Ġpe',
    'oples',
    'Ġst',
    'd',
    'Ġsle',
    'e',
    'Ġs',
    'lic',
    'ĠStat',
    'istics',
    'Ġcor',
    'ners',
    'ĠB',
    'aker',
    'Ġ:',
    ')',
    'ment',
    'ation',
    'ol',
    'ver',
    'Ġlaugh',
    'ing',
    'ĠT',
    'odd',
    'ond',
    'e',
    'ĠH',
    'ills',
    'Ġn',
    'uts',
    'ĠW',
    'oman',
    'pl',
    'ane',
    'Ġl',
    'iver',
    'ĠIn',
    'side',
    'S',
    'orry',
    'Ġagre',
    'es',
    'Ġfund',
    'ament',
    'ĠF',
    'isher',
    'Ġa',
    'uction',
    'Ġthread',
    's',
    'gl',
    'as',
    'ĠBas',
    'ic',
    'ĠN',
    'at',
    'Ġlack',
    'ing',
    'Ġceleb',
    'ration',
    'j',
    'u',
    'Ġs',
    'illy',
    'E',
    'uro',
    'Ġt',
    'att',
    'ight',
    'y',
    'cont',
    'rolled',
    'T',
    'est',
    'ĠSing',
    'h',
    'Ġr',
    'age',
    'Ġrh',
    'yth',
    'o',
    'ffic',
    'ĠPh',
    'antom',
    'Ġhead',
    'lines',
    'Ġrespond',
    'ing',
    'ĠMor',
    'ning',
    'Ġvit',
    'amin',
    'Ġboot',
    's',
    'ĠS',
    'ite',
    'al',
    'in',
    'p',
    'i',
    'Ġvir',
    'al',
    'ĠU',
    'C',
    'D',
    'ER',
    'ĠSe',
    'x',
    'Ġst',
    'ocks',
    'c',
    'urrent',
    'Ġch',
    'urches',
    'ĠR',
    'are',
    'ĠMur',
    'phy',
    'Ġden',
    'ial',
    'ĠG',
    'aming',
    'Ġtou',
    'g',
    'Ġn',
    'ick',
    'Ġm',
    'akers',
    'ĠRon',
    'ald',
    'Ġgener',
    'ous',
    'ĠD',
    'oc',
    'ĠMor',
    'ris',
    'Ġtransform',
    'ed',
    'ĠN',
    'ormal',
    'Ġ10',
    '4',
    'ĠKick',
    'starter',
    'ĠUp',
    'on',
    'On',
    'line',
    'ĠI',
    'RS',
    'Ġw',
    'rap',
    'Ġl',
    'oving',
    'Ġarri',
    'ves',
    'ĠD',
    'ue',
    'Ġhe',
    'ter',
    'ĠM',
    'ade',
    'Ġrent',
    'al',
    'Ġbelong',
    's',
    'Ġatt',
    'orneys',
    'Ġcro',
    'ps',
    'Ġmat',
    'ched',
    'ul',
    'um',
    'ol',
    'ine',
    '10',
    '9',
    'Ġdis',
    'par',
    'Ġbuy',
    'ers',
    'ĠCam',
    'bridge',
    'Ġeth',
    'ics',
    'rou',
    'ps',
    'Ġjust',
    'ified',
    'Ġmarg',
    'inal',
    'Ġrespect',
    'ed',
    'win',
    'ning',
    'Ġnodd',
    'ed',
    'ĠSer',
    'ge',
    'ĠForm',
    'er',
    'C',
    'raft',
    '########',
    '########',
    'ĠWar',
    'ner',
    'Ġd',
    'ash',
    'et',
    'e',
    'Ġent',
    'ert',
    'ĠE',
    'scape',
    'out',
    'heast',
    'Ġkn',
    'ees',
    'ĠB',
    'omb',
    'Ġr',
    'ug',
    'P',
    'ass',
    'Ġatt',
    'itudes',
    'go',
    'vernment',
    'ĠPri',
    'or',
    'Ġqual',
    'ities',
    'Ġnot',
    'ification',
    'ĠPh',
    'one',
    'l',
    'ie',
    'Ġanticip',
    'ated',
    'ĠCom',
    'bat',
    'ĠBar',
    'ry',
    'Ġ198',
    '2',
    'Us',
    'ers',
    'on',
    'er',
    'Ġcomput',
    'ing',
    'ĠConnect',
    'icut',
    'Ġless',
    'er',
    'Ġpe',
    'ers',
    'ĠC',
    'u',
    'Ġtechn',
    'ically',
    'Ġsub',
    'mission',
    'ĠUn',
    'iversal',
    'Ġman',
    'ually',
    'our',
    'ge',
    'Ġrespond',
    'ents',
    'ĠB',
    'TC',
    'ĠH',
    'ost',
    'Ġf',
    'are',
    'ĠB',
    'ird',
    'Ġrece',
    'ipt',
    'al',
    'so',
    'Ġj',
    'ack',
    'Ġagric',
    'ulture',
    'Ġsk',
    'ull',
    'Ġ!',
    '=',
    'Ġpass',
    'ive',
    'ĠC',
    'I',
    'Ġsoc',
    'ieties',
    'Ġremind',
    'ed',
    'Ġinter',
    'ference',
    'B',
    'uy',
    'Ġâ',
    'ľ',
    'g',
    'on',
    'Ġscrut',
    'iny',
    'ĠW',
    'itch',
    'Ġconduct',
    'ing',
    'Ġ',
    'ãĥ',
    'Ġexch',
    'anges',
    'ĠMit',
    'chell',
    'Ġinhab',
    'it',
    'Ġtw',
    'ist',
    'B',
    'D',
    'Ġwhere',
    'ver',
    'group',
    'on',
    'Ġj',
    'okes',
    'ĠBen',
    'jamin',
    'ĠR',
    'andom',
    'fr',
    'ame',
    'ĠL',
    'ions',
    'Ġhighlight',
    'ed',
    'ĠArk',
    'ansas',
    'E',
    'nt',
    'Ġp',
    'ile',
    'Ġpre',
    'lim',
    'g',
    's',
    'mind',
    'ed',
    'Ġfel',
    'ony',
    'ĠG',
    'A',
    'ĠL',
    'uck',
    'Ġpract',
    'ically',
    'ĠB',
    'os',
    'Ġact',
    'ress',
    'D',
    'am',
    'ĠB',
    'ou',
    'Ġvis',
    'a',
    'Ġembed',
    'ded',
    'Ġhy',
    'brid',
    'Ġear',
    'liest',
    'Ġsoon',
    'er',
    's',
    'ocial',
    'ĠH',
    'A',
    'Ġste',
    'ep',
    'Ġdis',
    'advant',
    'Ġexplo',
    'it',
    'ĠE',
    'gg',
    'ĠUlt',
    'ra',
    'Ġnecess',
    'ity',
    'L',
    'ocal',
    'ie',
    'ge',
    'Ġd',
    'ated',
    'Ġmass',
    'es',
    'Ġsubsc',
    'ription',
    'pl',
    'ess',
    'Ġan',
    'onym',
    'Ġpresum',
    'ably',
    'Bl',
    'ue',
    'The',
    'ir',
    'asket',
    'ball',
    'ĠPhil',
    'ip',
    'Ġcom',
    'ed',
    'load',
    'ed',
    'r',
    'ane',
    'Ġref',
    'lection',
    'Ch',
    'ina',
    'Ġext',
    'ends',
    'Ġform',
    'ing',
    'Ġund',
    'ers',
    '200',
    '1',
    'Ġgr',
    'at',
    'Ġconcent',
    'rations',
    'Ġins',
    'ulin',
    'Ġsec',
    'ular',
    'Ġwh',
    'ilst',
    'Ġwin',
    'ners',
    'Ad',
    'vertisements',
    'Ġdeliber',
    'ately',
    'ĠWork',
    'ing',
    'Ġs',
    'ink',
    'et',
    'ics',
    'd',
    'ale',
    'Ġmand',
    'ate',
    'Ġg',
    'ram',
    'Ġvac',
    'ation',
    'Ġwarn',
    'ings',
    'ri',
    'pp',
    'ĠTH',
    'AT',
    'Ġcomment',
    'ary',
    'Ġint',
    'u',
    'Ġa',
    'est',
    'Ġreason',
    'ing',
    'Ġbreak',
    'down',
    'ĠZ',
    'ombie',
    'Ġ--',
    '>',
    'ĠPolit',
    'ical',
    'c',
    'ott',
    'Ġthr',
    'ust',
    'Ġtechn',
    'ological',
    'Ġdec',
    'iding',
    'Ġtraff',
    'icking',
    'L',
    'ong',
    'W',
    'elcome',
    'pr',
    'ising',
    'ĠCommun',
    'ications',
    'Ġend',
    'ors',
    'Ġsw',
    'ift',
    'Ġmetab',
    'ol',
    'co',
    'ins',
    'res',
    'a',
    'ĠHT',
    'TP',
    'Ġen',
    'roll',
    'ĠH',
    'appy',
    'us',
    'r',
    'int',
    'age',
    'Ġ[',
    '"',
    'u',
    'ably',
    'ĠM',
    'aterial',
    'Ġrepe',
    'al',
    'Se',
    'pt',
    'k',
    'h',
    'ĠMod',
    'i',
    'Ġunder',
    'neath',
    'ĠI',
    'L',
    'sh',
    'ore',
    'Ġdiagn',
    'osed',
    'ace',
    'utical',
    'Ġsh',
    'ower',
    'au',
    'x',
    'ĠSw',
    'itch',
    'ĠStre',
    'ngth',
    'Ġj',
    'ihad',
    'n',
    'ational',
    'Ġtra',
    'uma',
    'uss',
    'y',
    'on',
    'i',
    'Ġcons',
    'olid',
    'Ġcal',
    'ories',
    'ĠF',
    'lynn',
    'ag',
    'ged',
    '16',
    '8',
    'ĠP',
    'ink',
    'Ġfulf',
    'ill',
    'Ġch',
    'ains',
    'Ġnot',
    'ably',
    'ĠA',
    'V',
    'L',
    'ife',
    'ĠCh',
    'uck',
    'm',
    'us',
    'ĠUr',
    'ban',
    'ĠH',
    'end',
    'Ġdep',
    'osit',
    'ĠS',
    'ad',
    'Ġaff',
    'air',
    'OR',
    'K',
    'ie',
    'val',
    'ĠF',
    'DA',
    'Ġt',
    'rop',
    'ĠOver',
    'all',
    'Ġvirt',
    'ue',
    'Ġsatisf',
    'action',
    'au',
    'nd',
    'Ġl',
    'un',
    'ĠSw',
    'itzerland',
    'ĠOper',
    'ation',
    'pro',
    'cess',
    'Ġsh',
    'ook',
    'Ġcount',
    'ies',
    'le',
    'ased',
    'ĠCharl',
    'otte',
    '1',
    '12',
    'Ġtrans',
    'cript',
    'Ġre',
    'dd',
    'p',
    'ush',
    'ĠHe',
    'y',
    'ĠAn',
    'alysis',
    '[',
    '"',
    'Ġaltern',
    'atives',
    'ard',
    'less',
    'Ġele',
    'ph',
    'Ġpre',
    'jud',
    'ĠLe',
    'af',
    'H',
    'aving',
    'ĠH',
    'ub',
    'Ġexpress',
    'ions',
    'ĠVol',
    'ume',
    'Ġshock',
    'ing',
    'ĠRed',
    's',
    'Ġread',
    'ily',
    'Ġplan',
    'ets',
    'ad',
    'ata',
    'Ġcollaps',
    'ed',
    'ĠMad',
    'rid',
    'Ġir',
    'rit',
    'i',
    'pper',
    'ĠEn',
    'c',
    'ĠW',
    'ire',
    'Ġbu',
    'zz',
    'ĠG',
    'P',
    'ash',
    'a',
    'Ġaccident',
    'ally',
    'ur',
    'u',
    'Ġfrust',
    'rated',
    'ĠS',
    'A',
    'Ġhung',
    'ry',
    'ĠH',
    'uff',
    'Ġlab',
    'els',
    'ant',
    'o',
    'ĠE',
    'P',
    'Ġbar',
    'riers',
    ')',
    '|',
    'ĠBer',
    'keley',
    'ĠJ',
    'ets',
    'Ġp',
    'airs',
    'ĠL',
    'an',
    'J',
    'ames',
    'ĠB',
    'ear',
    'Ġhum',
    'or',
    'ĠLiber',
    'ty',
    'Ġmagn',
    'itude',
    'Ġag',
    'ing',
    'ĠM',
    'ason',
    'Ġfriends',
    'hip',
    'umb',
    'ling',
    'Ġemer',
    'ge',
    'Ġnewsp',
    'apers',
    'Ġam',
    'bitious',
    'ĠRich',
    'ards',
    'atern',
    'al',
    'Ġ198',
    '1',
    'Ġcook',
    'ies',
    'Ġsc',
    'ulpt',
    'Ġpur',
    'suit',
    'L',
    'ocation',
    'Ġscript',
    's',
    'p',
    'c',
    'Ġarrang',
    'ements',
    'Ġd',
    'iameter',
    'Ġl',
    'oses',
    'am',
    'ation',
    'Ġl',
    'iqu',
    'ĠJ',
    'ake',
    'aret',
    'te',
    'Ġunderstand',
    's',
    'ĠZ',
    'en',
    'v',
    'm',
    'Ġappro',
    've',
    'Ġw',
    'ip',
    'Ġult',
    'ra',
    'Ġint',
    'end',
    'ĠD',
    'I',
    'asc',
    'ular',
    'Ġst',
    'ays',
    'ĠK',
    'or',
    'ĠK',
    'l',
    'Ġinvest',
    'ing',
    'L',
    'a',
    'Ġbelie',
    'ving',
    'b',
    'ad',
    'm',
    'outh',
    'Ġtaxp',
    'ayer',
    'ãĥ',
    'ĥ',
    'ĠQue',
    'bec',
    'Ġl',
    'ap',
    'ĠSw',
    'iss',
    'd',
    'rop',
    'Ġdr',
    'ain',
    'ir',
    'i',
    'et',
    'c',
    'ft',
    'en',
    'ĠN',
    'ex',
    'Ġst',
    'raw',
    'Ġscream',
    'ing',
    'Ġcount',
    'ed',
    'Ġdam',
    'aging',
    'Ġamb',
    'assador',
    'cent',
    'ury',
    'Ġpro',
    'x',
    'Ġarrest',
    's',
    'u',
    'v',
    'il',
    'ateral',
    'ĠCh',
    'arg',
    'Ġpresc',
    'ribed',
    'Ġindepend',
    'ently',
    'Ġf',
    'ierce',
    'ĠB',
    'aby',
    'Ġb',
    'rave',
    'Ġsu',
    'its',
    '=',
    '>',
    'Ġbas',
    'eline',
    'ĠR',
    'ate',
    'Ġis',
    'lands',
    'Ġ(',
    '(',
    'g',
    'reen',
    'ix',
    'els',
    'Ġname',
    'ly',
    'ĠVill',
    'age',
    'th',
    'an',
    'am',
    'y',
    'V',
    'ersion',
    'g',
    'mail',
    'ential',
    's',
    'ĠS',
    'ud',
    'ĠMel',
    'bourne',
    'Ġarri',
    'ving',
    'Ġquant',
    'um',
    'e',
    'ff',
    'rop',
    'olitan',
    'T',
    'ri',
    'Ġfun',
    'eral',
    'ĠI',
    'R',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'ĠC',
    'ob',
    'it',
    'ably',
    'Ġt',
    'urb',
    'Ġcomb',
    'o',
    'Re',
    'view',
    'Ġdeploy',
    'ment',
    'u',
    'ity',
    'ĠB',
    'ott',
    'Ġinv',
    'isible',
    'Ġrender',
    'ing',
    'Ġunl',
    'ocked',
    'Ġa',
    'qu',
    'ĠVlad',
    'imir',
    'Ġp',
    'ad',
    'ĠBr',
    'ain',
    'ĠLeg',
    'acy',
    'dr',
    'agon',
    'ĠKurd',
    'ish',
    'Ġsound',
    'ed',
    'Ġdet',
    'ained',
    'ĠD',
    'M',
    'g',
    'ary',
    'Ġd',
    'aughters',
    'Ġdistur',
    'bing',
    'uk',
    'a',
    'ĠPar',
    'ad',
    'Ġt',
    'ast',
    'Ġunf',
    'ortunate',
    'Ġu',
    'l',
    'em',
    'in',
    'Ġattend',
    'ance',
    'tr',
    'l',
    'Ġpar',
    'ks',
    'ĠMem',
    'orial',
    'ĠAl',
    'ice',
    'oth',
    'y',
    'gu',
    'ard',
    'ĠD',
    'ise',
    'ĠSh',
    'an',
    'ĠFor',
    'um',
    'R',
    'ich',
    'Ġshif',
    'ted',
    'ue',
    'z',
    'Ġl',
    'ighter',
    'ĠMag',
    'n',
    'Ġc',
    'od',
    'S',
    'ch',
    'ham',
    'mad',
    'P',
    'ub',
    '3',
    '50',
    'ĠP',
    'okemon',
    'Ġprot',
    'otype',
    'Ġun',
    're',
    'B',
    'ase',
    'ĠStud',
    'ents',
    'ĠRep',
    'ly',
    'ĠCommun',
    'ist',
    'Ġg',
    'au',
    'ĠTy',
    'ler',
    'I',
    'Z',
    'Ġparticip',
    'ated',
    'Ġsup',
    'rem',
    'ĠDet',
    'ails',
    'Ġvessel',
    's',
    'ro',
    'd',
    'Ġt',
    'ribe',
    'ke',
    'ep',
    'Ġassum',
    'ptions',
    'Ġp',
    'ound',
    'Ġcr',
    'ude',
    'ĠAv',
    'ailable',
    'Ġswim',
    'ming',
    'Ġin',
    'clusion',
    'Ġadv',
    'ances',
    'c',
    'ulation',
    'Ġconserv',
    'ation',
    'Ġover',
    'd',
    'ĠBuff',
    'alo',
    'Art',
    'icle',
    'ed',
    'ge',
    'Ġaw',
    'a',
    'ĠMad',
    'ison',
    'Ġsid',
    'ew',
    'Ġcat',
    'ast',
    'ĠK',
    'rist',
    'uc',
    'le',
    'ĠHigh',
    'way',
    'ĠTer',
    'ror',
    'Ġactiv',
    'ation',
    'Ġuncons',
    'cious',
    'ĠSat',
    'an',
    'ĠSus',
    'an',
    'ill',
    'ery',
    'Ġarr',
    'anged',
    'i',
    'op',
    'Ġrum',
    'ors',
    'ur',
    'ring',
    'th',
    'ink',
    'ĠKe',
    'ith',
    'ĠK',
    'ind',
    'Ġavoid',
    'ing',
    'by',
    'n',
    'n',
    'ut',
    'ĠSpe',
    'aker',
    'r',
    'us',
    'n',
    'ames',
    'Ġgu',
    'ilt',
    'ĠOlymp',
    'ics',
    'Ġsa',
    'il',
    'ĠM',
    'es',
    'lev',
    'ant',
    'ĠColumb',
    'us',
    'a',
    'ft',
    'C',
    'ity',
    'S',
    'outh',
    'ĠHar',
    'vey',
    'ĠP',
    'un',
    'S',
    'everal',
    'Ġment',
    'ally',
    'Ġimp',
    'ress',
    'm',
    'ount',
    'ĠUb',
    'untu',
    'âĢĶâĢĶâĢĶâĢĶ',
    'âĢĶâĢĶâĢĶâĢĶ',
    'ĠSuper',
    'man',
    'ĠMP',
    's',
    'Ġintent',
    'ions',
    'ĠR',
    'acing',
    'Ġlike',
    'lihood',
    'Ġ2',
    '40',
    'T',
    'otal',
    'Ġto',
    'ys',
    'ĠW',
    'atson',
    'Ġur',
    'ge',
    'L',
    'ear',
    'ĠP',
    'aper',
    'Ġoccur',
    'ring',
    'ĠB',
    'eng',
    'ĠC',
    'ert',
    'Ġst',
    'ones',
    'T',
    'im',
    'ĠTw',
    'in',
    'z',
    'b',
    'ĠD',
    'ynam',
    'Ġpolit',
    'ician',
    'k',
    'ens',
    'ĠEnter',
    'prise',
    'UT',
    'ERS',
    'Ġab',
    'ol',
    'Ġref',
    'resh',
    'Ġarbit',
    'rary',
    'pe',
    'ction',
    'Ġtrou',
    'bles',
    'Ġ}',
    ');',
    't',
    'v',
    'Ġpil',
    'ots',
    'Ġdist',
    'ribute',
    'Ġaud',
    'it',
    'Ġp',
    'ause',
    'orig',
    'inal',
    'Ġr',
    'ivals',
    'Â',
    '£',
    'F',
    'ig',
    'T',
    'L',
    'ab',
    'il',
    'ry',
    'ing',
    'L',
    'in',
    'ion',
    'ed',
    'l',
    'on',
    'Ġf',
    'ancy',
    'Ġcr',
    'ashed',
    'Ġt',
    'ract',
    'Ġshe',
    'd',
    'Ġcons',
    'ume',
    'B',
    'ased',
    'down',
    'load',
    'in',
    'it',
    'Ġvolt',
    'age',
    'Int',
    'rodu',
    'Ġcondem',
    'ned',
    'ĠFin',
    'ance',
    'res',
    'pect',
    'Ġex',
    'cluded',
    'Ġestablish',
    'ing',
    'her',
    'ic',
    'Ġher',
    'itage',
    'Ġspect',
    'acular',
    'Ġun',
    'st',
    'ĠSnow',
    'den',
    'ĠL',
    'ane',
    'S',
    'an',
    'Ġprotect',
    'ions',
    'st',
    'ruction',
    'inc',
    'inn',
    'Ġmac',
    'ro',
    'C',
    'ustom',
    'ios',
    'ity',
    'Ġes',
    'p',
    'Ġfunction',
    'ing',
    'Ġm',
    'ush',
    'Ġp',
    'uzzle',
    'Ġeth',
    'ical',
    'M',
    'al',
    'Ġgo',
    'verning',
    'ĠF',
    'erguson',
    'Ġrest',
    'ored',
    'Ġst',
    'ressed',
    'ĠCoun',
    'ter',
    'ĠK',
    'as',
    'cl',
    'ip',
    'AN',
    'S',
    'Ġse',
    'iz',
    'U',
    'K',
    'by',
    'ss',
    'old',
    'own',
    'ap',
    'i',
    'Ġperman',
    'ently',
    'oun',
    'ters',
    'W',
    'est',
    'Th',
    'rough',
    'L',
    'ight',
    'at',
    'oes',
    'Ġne',
    'at',
    'Ġc',
    'ord',
    'ure',
    'r',
    'Ġsevere',
    'ly',
    'ĠA',
    'ven',
    'Ġinter',
    'rog',
    'Ġtri',
    'ple',
    'G',
    'iven',
    'N',
    'umber',
    'Ġar',
    'ise',
    'Ġs',
    'her',
    'pl',
    'ant',
    'Ġfl',
    'ower',
    'ĠC',
    'ou',
    'Ġat',
    'e',
    'Ġnew',
    'er',
    'b',
    'ul',
    'Ġmean',
    'while',
    'ĠL',
    'air',
    'Ġadjust',
    'ment',
    'ĠCop',
    'yright',
    'Ġd',
    'ivers',
    'i',
    'ological',
    'Ġgam',
    'ers',
    'o',
    'at',
    'Ġhistor',
    'ically',
    'Ġanal',
    'og',
    'Ġlong',
    'time',
    'Ġpres',
    'cription',
    'ĠM',
    'ist',
    'ĠHy',
    'per',
    'ĠM',
    'aine',
    'ĠDe',
    'ity',
    'Ġmulti',
    'pl',
    'ĠRe',
    'incarn',
    'ĠH',
    'yd',
    'ĠP',
    'ic',
    'S',
    'il',
    'r',
    'ants',
    'ĠC',
    'ris',
    '.',
    ';',
    '(',
    '{',
    'epend',
    'ence',
    'Ġrec',
    'y',
    'ate',
    'ur',
    'Ġqu',
    'ad',
    'Ġgl',
    'ob',
    'Ġcon',
    'ced',
    'te',
    'am',
    'Ġcapital',
    'ist',
    'ĠL',
    'ot',
    'Ġroy',
    'al',
    'ĠCy',
    'ber',
    'Ġblack',
    's',
    'met',
    'ic',
    'ri',
    'v',
    'ĠD',
    'anny',
    'Ġsp',
    'o',
    'ĠR',
    'O',
    'Ġanim',
    'ated',
    'rypt',
    'ed',
    'ĠDep',
    'uty',
    'Ġrend',
    'ered',
    'F',
    'E',
    'Ġstre',
    'ak',
    'Ġcloud',
    's',
    'ĠDou',
    'g',
    '~~~~',
    '~~~~',
    'Ġdisc',
    'our',
    'ĠVe',
    'h',
    'Ġpsych',
    'ology',
    'ĠJ',
    'ourney',
    'Ġcry',
    'stal',
    'ĠFro',
    'st',
    'Ġsuspic',
    'ion',
    'Ġrel',
    'ate',
    'or',
    'us',
    'ĠC',
    'rypt',
    'ĠN',
    'VIDIA',
    'com',
    'ed',
    'ut',
    'ing',
    'incinn',
    'ati',
    'Ġvulner',
    'ability',
    'ost',
    'ic',
    'Ġisol',
    'ation',
    'Ġcool',
    'ing',
    'ĠCoal',
    'ition',
    'Ġ1',
    '19',
    'F',
    'our',
    'ĠDe',
    'al',
    'Ġâ',
    'ī',
    'se',
    'mble',
    'ram',
    'ent',
    'ĠBar',
    'celona',
    'Ġ10',
    '2',
    'Ġcoc',
    'aine',
    'ocaly',
    'pse',
    'F',
    'eb',
    'ogen',
    'ic',
    'Ġmut',
    'ation',
    'Ġcrypt',
    'oc',
    'ĠK',
    'el',
    'ĠG',
    'it',
    'a',
    'is',
    'Ġs',
    'isters',
    'AN',
    'K',
    'Ġactiv',
    'ate',
    'T',
    'er',
    'Ġd',
    'read',
    'yl',
    'on',
    'Ġprop',
    'ri',
    'A',
    'ust',
    'ĠDef',
    'ault',
    'Ġout',
    'door',
    'Ġshe',
    'er',
    'ce',
    'ive',
    'Ġg',
    'ently',
    'Ð',
    '¾',
    'Pro',
    'gram',
    'Ġâ',
    'ĨĴ',
    'Ġve',
    'gan',
    'ĠCr',
    'us',
    'Ġrespons',
    'ibilities',
    'ĠH',
    'R',
    'OL',
    'D',
    'Ġprev',
    'ents',
    'Ġst',
    'iff',
    'ĠW',
    'ere',
    'Ġathlet',
    'ic',
    'ĠSc',
    'ore',
    'Ġ)',
    ':',
    'Ġcolumn',
    's',
    'ĠL',
    'oc',
    'av',
    'ailable',
    'ĠF',
    'ram',
    'ĠS',
    'essions',
    'Ġcompan',
    'ion',
    'Ġpack',
    's',
    '14',
    '0',
    'ĠKn',
    'ights',
    'Ġf',
    'art',
    'Ġstream',
    's',
    'Ġsh',
    'ore',
    'Ġapp',
    'eals',
    'ĠPer',
    'formance',
    'h',
    'aul',
    'ĠSt',
    'ra',
    'ĠN',
    'ag',
    '10',
    '3',
    'ĠTrans',
    'portation',
    'B',
    'B',
    'E',
    'v',
    'z',
    'an',
    'P',
    'ublic',
    'Ġtw',
    'in',
    'uls',
    'ion',
    'M',
    'ult',
    'Ġelect',
    'ro',
    'Ġstat',
    'ue',
    'ation',
    'ally',
    'ĠN',
    'ort',
    'Ġins',
    'pection',
    '/',
    '*',
    'ig',
    'ue',
    'Ġcomp',
    'assion',
    'ĠT',
    'ales',
    'ĠSte',
    'in',
    'ĠSc',
    'reen',
    'ĠB',
    'ug',
    'ĠL',
    'ion',
    'g',
    'irl',
    'Ġwithdraw',
    'al',
    'Ġobject',
    'ives',
    'Ġblood',
    'y',
    'Ġprelim',
    'inary',
    'Ġj',
    'acket',
    'Ġdim',
    'ensions',
    'ĠC',
    'ool',
    'ĠOcc',
    'up',
    'Ġw',
    'reck',
    'Ġdoub',
    'led',
    'ank',
    'ing',
    'Ġ19',
    '75',
    'Ġglass',
    'es',
    'ĠW',
    'ang',
    'pro',
    'v',
    'P',
    'ath',
    'connect',
    'ed',
    'ĠMult',
    'i',
    'ĠNor',
    'way',
    'agon',
    'ist',
    'Ġfe',
    'ared',
    'Ġtouch',
    'ing',
    'Ġarg',
    'uably',
    'Â¯Â¯Â¯Â¯',
    'Â¯Â¯Â¯Â¯',
    'ĠNC',
    'AA',
    'che',
    'm',
    'Ġsp',
    'at',
    'ĠW',
    'WE',
    'ĠC',
    'el',
    'ig',
    'ger',
    'Ġattack',
    'er',
    'ĠJo',
    'in',
    'ob',
    'ject',
    'ett',
    'a',
    'Ġelim',
    'inated',
    'd',
    'et',
    'Ġdest',
    'ruct',
    'ĠLuc',
    'as',
    'ct',
    'uary',
    '18',
    '0',
    'ĠBr',
    'ady',
    'ĠBl',
    'ues',
    'B',
    'ay',
    'au',
    'kee',
    'Ġtim',
    'eline',
    'Ġdeleg',
    'ates',
    'w',
    'ritten',
    'uff',
    'icient',
    'Ġsh',
    'apes',
    'Cop',
    'yright',
    'ou',
    'ble',
    'serv',
    'ice',
    'Ġp',
    'ione',
    'Ġcolleg',
    'es',
    'Ġrow',
    's',
    'Ġsp',
    'ite',
    'Ġassess',
    'ed',
    '3',
    '60',
    'Ġle',
    'ase',
    'Ġconfident',
    'ial',
    'ck',
    'er',
    'ĠMan',
    'ning',
    'ĠV',
    'oice',
    'Ġse',
    'aled',
    'Ġcalcul',
    'ate',
    'N',
    'O',
    'ĠAss',
    'istant',
    'Ġteen',
    'ager',
    'ul',
    'ent',
    'ather',
    'ine',
    'Ġm',
    'ock',
    'Ġd',
    'iamond',
    'Ġf',
    'est',
    'Ġsw',
    'itched',
    'Ġres',
    'ume',
    'ĠPu',
    'erto',
    'Ġl',
    'anes',
    'ir',
    'ation',
    'ĠSimilar',
    'ly',
    'Ġro',
    'd',
    'ĠS',
    'el',
    'ĠPal',
    'ace',
    'ĠLim',
    'ited',
    'e',
    'ous',
    'Ġvar',
    'iant',
    'Ġw',
    'ard',
    'Ġ)',
    ')',
    'Sh',
    'ow',
    'OO',
    'K',
    'A',
    'lex',
    'ĠN',
    'ep',
    'br',
    'is',
    'ĠWik',
    'ipedia',
    'Ġexcept',
    'ional',
    'Ġman',
    'ages',
    'ĠD',
    'raw',
    'Ag',
    'ain',
    'Ġco',
    'pper',
    'ut',
    't',
    'Ġex',
    'ports',
    'Ġport',
    'folio',
    'Ġelev',
    'ated',
    'R',
    'ated',
    'ĠOther',
    'wise',
    'ĠT',
    'act',
    'ĠShe',
    'l',
    'ĠT',
    'X',
    '"',
    'âĢĶ',
    'Ġres',
    'ur',
    'ĠW',
    'a',
    'ven',
    'ant',
    'Ġmon',
    'etary',
    'pe',
    'ople',
    'E',
    'mail',
    'Ġfif',
    'ty',
    'ĠS',
    'weet',
    'ĠMalays',
    'ia',
    'Ġconf',
    'using',
    'ĠR',
    'io',
    'ud',
    'a',
    'uten',
    'ant',
    '"',
    ');',
    'Ġpra',
    'ised',
    'Ġvol',
    'umes',
    't',
    'urn',
    'Ġm',
    'ature',
    'Ġnon',
    'profit',
    'Ġpassion',
    'ate',
    'ĠPriv',
    'ate',
    'Ġ10',
    '3',
    'Ġdesc',
    'end',
    'ç',
    '¥ŀ',
    'uff',
    'y',
    'head',
    'ed',
    'Whe',
    'ther',
    'ri',
    'en',
    'ze',
    'ch',
    'be',
    'it',
    'Ġch',
    'rom',
    'ĠMc',
    'M',
    'Ġd',
    'ancing',
    'Ġe',
    'leg',
    'ĠNot',
    'iced',
    '11',
    '5',
    'Ġadvoc',
    'acy',
    'ENT',
    'S',
    'amb',
    'ling',
    'ĠMin',
    'or',
    'ĠF',
    'inn',
    'Ġprior',
    'ities',
    'Ġthere',
    'of',
    'ĠSt',
    'age',
    'ĠRog',
    'ers',
    'Ġsubst',
    'itute',
    'ĠJ',
    'ar',
    'ĠJeff',
    'erson',
    'Ġlight',
    'ly',
    '10',
    '2',
    'ĠL',
    'isa',
    'u',
    'its',
    'ys',
    'ical',
    'Ġshif',
    'ts',
    'Ġd',
    'rones',
    'Ġwork',
    'place',
    'Ġres',
    'id',
    'ens',
    'ed',
    'ah',
    'n',
    'Ġpref',
    'erences',
    'ser',
    'ver',
    'Ġdeb',
    'ates',
    'd',
    'oc',
    'ĠGod',
    's',
    'Ġhelicop',
    'ter',
    'Ġhon',
    'our',
    'Ġconsider',
    'ably',
    'ed',
    'ed',
    'ĠF',
    'emale',
    'ĠAn',
    'ne',
    'Ġre',
    'un',
    'ĠF',
    'ace',
    'ĠHall',
    'ow',
    'ĠBud',
    'get',
    'Ġcondem',
    'n',
    'Ġt',
    'ender',
    'Pro',
    'f',
    'ocr',
    'atic',
    'ĠTurn',
    'er',
    'ĠAg',
    'ric',
    'Ġ19',
    '76',
    'Ġa',
    'pt',
    'd',
    'isc',
    'ĠF',
    'ighter',
    'ĠA',
    'ur',
    'Ġgar',
    'bage',
    'in',
    'put',
    'ĠK',
    'arl',
    'ĠOl',
    'iver',
    'ĠL',
    'anguage',
    'k',
    'n',
    'N',
    'on',
    'ĠCl',
    'ar',
    'Ġtrad',
    'itions',
    'Ġad',
    'vertisement',
    'ĠS',
    'or',
    'Ġarch',
    'ive',
    'Ġvill',
    'ages',
    '7',
    '50',
    'Ġimplement',
    'ing',
    'w',
    'aukee',
    'Ġdiet',
    'ary',
    'Ġswitch',
    'ing',
    'Rep',
    'ublic',
    'Ġvel',
    'ocity',
    'Ġc',
    'it',
    'ĠA',
    'wards',
    'Ġfin',
    'ancing',
    'Ġlast',
    'ed',
    ')',
    ']',
    'Ġrem',
    'inder',
    'P',
    'erson',
    'Ġprec',
    'ision',
    'Ġdesign',
    'ers',
    'ĠF',
    'ried',
    'ĠB',
    'order',
    'Ġtr',
    'agic',
    'Ġw',
    'ield',
    'Ġiniti',
    'atives',
    'ĠT',
    'ank',
    'w',
    'er',
    'Ġjo',
    'ins',
    'R',
    'o',
    'in',
    'ery',
    'Ġar',
    'row',
    'Ġgener',
    'ating',
    'found',
    'er',
    'Ġsear',
    'ches',
    'Ġrandom',
    'ly',
    'A',
    'ccess',
    'Ġb',
    'atch',
    'Ġp',
    'osed',
    'l',
    'at',
    'Ġpursu',
    'ing',
    'as',
    'a',
    'Ġtest',
    'ified',
    'form',
    'ing',
    'ĠSh',
    'ar',
    'w',
    'iki',
    'ĠE',
    'ither',
    'S',
    'ometimes',
    'Ġsen',
    'ators',
    'ĠJohn',
    'ny',
    'ĠTal',
    'iban',
    'ĠG',
    'PS',
    '":"',
    '/',
    'ãģ®',
    'å',
    'Ġanaly',
    'zed',
    'ĠRub',
    'io',
    'ĠMove',
    'ment',
    'op',
    'ard',
    'ii',
    'i',
    'St',
    'and',
    'f',
    'ight',
    'Ġign',
    'oring',
    'i',
    'ang',
    'ĠG',
    'N',
    'so',
    'ever',
    'ĠST',
    'AT',
    'Ġref',
    'using',
    'Ġswe',
    'at',
    'Ġb',
    'ay',
    'P',
    'ORT',
    'ir',
    'med',
    'ak',
    'y',
    'Ġdis',
    'pro',
    'Ġlabel',
    'ed',
    'Ġ10',
    '8',
    'H',
    'ello',
    'Ġple',
    'asant',
    'ab',
    'a',
    'Ġtri',
    'umph',
    'Ġab',
    'oard',
    'Ġinc',
    'om',
    'ĠC',
    'row',
    'le',
    'tt',
    'Ġfol',
    'k',
    'Ġch',
    'ase',
    '`',
    '`',
    'ĠBr',
    'us',
    'Ġte',
    'ens',
    'c',
    'ue',
    'Ġter',
    'rain',
    'h',
    'yd',
    'il',
    'ight',
    'OR',
    'Y',
    'Su',
    'pport',
    'ew',
    's',
    'll',
    'i',
    'rain',
    'ts',
    'ĠC',
    'and',
    'Ġab',
    'used',
    'ach',
    'ment',
    'l',
    'arg',
    'B',
    'as',
    'ĠC',
    'ancer',
    'Ġ19',
    '78',
    'Ġsupp',
    'orter',
    'ac',
    'cess',
    'ĠTer',
    'min',
    'ĠT',
    'ampa',
    'ĠAN',
    'Y',
    'Ġnew',
    'est',
    'ĠCrim',
    'inal',
    'ed',
    'u',
    'Ġ19',
    '30',
    'Ġadm',
    'its',
    'Ġend',
    'e',
    'Ġfail',
    'ures',
    'ur',
    'ate',
    'ful',
    'ness',
    'cy',
    'cl',
    'ĠSub',
    'ject',
    'Ġinf',
    'inite',
    'th',
    'ree',
    'W',
    'A',
    'p',
    'it',
    'ĠInst',
    'all',
    'R',
    'ad',
    'ili',
    'ation',
    'G',
    'M',
    'Ġcontin',
    'ent',
    'Ġaccommod',
    'ate',
    'ĠCl',
    'ay',
    'Ġp',
    'up',
    'ĠF',
    'unction',
    'Ġham',
    'mer',
    'ĠAlbert',
    'a',
    'Ġrev',
    'ised',
    'Ġminor',
    'ities',
    'Ġmeasure',
    'ment',
    'Con',
    'nell',
    'Ġdis',
    'able',
    'ĠM',
    'ix',
    'In',
    'cre',
    'Ġfor',
    'k',
    'ĠR',
    'osen',
    'Ġimpl',
    'ies',
    'umb',
    'lr',
    'AN',
    'G',
    'Ġprote',
    'ins',
    'Ġagg',
    'ression',
    'Ġfacilit',
    'ate',
    'S',
    'N',
    'Ġilleg',
    'ally',
    'u',
    'er',
    'Ġacad',
    'em',
    'Ġp',
    'uzz',
    'ĠSh',
    'ift',
    'p',
    'ay',
    'oll',
    'o',
    'Ġaud',
    'iences',
    'B',
    'uild',
    'Ġno',
    'ble',
    'Ġsynt',
    'ax',
    'â',
    'ĺħ',
    'Ġbe',
    'am',
    'ĠB',
    'ed',
    'ĠA',
    'ld',
    'Ġorig',
    'ins',
    'v',
    'ideo',
    'Ġ19',
    '77',
    'ĠAss',
    'ault',
    'Ġgar',
    'age',
    'Te',
    'am',
    'Ġver',
    'dict',
    'Ġd',
    'war',
    'ĠVirt',
    'ual',
    'e',
    'vent',
    'Ke',
    'ep',
    'Ġsent',
    'iment',
    'Ġwild',
    'life',
    'sh',
    'irt',
    'Ġb',
    'urg',
    'Ġrecommend',
    'ation',
    'rep',
    'resent',
    'Ġgall',
    'ery',
    'own',
    'ers',
    'Ġsch',
    'olar',
    'Ġconven',
    'ience',
    'ĠSw',
    'ift',
    'Ġconv',
    'inc',
    'C',
    'ap',
    'Ġwar',
    'fare',
    'ĠVis',
    'ual',
    'Ġconst',
    'itute',
    'Ġab',
    'ort',
    'ĠWe',
    'ather',
    'ĠLook',
    'ing',
    'ĠH',
    'em',
    'Ġmart',
    'ial',
    'Ġinc',
    'oming',
    'et',
    'ition',
    'Ġtoler',
    'ance',
    'ĠCre',
    'ated',
    'Ġfl',
    'ows',
    'ĠE',
    'lder',
    'Ġsoul',
    's',
    'Ġf',
    'oul',
    'ĠP',
    'ain',
    'ĠC',
    'AN',
    'Ġ2',
    '20',
    'b',
    'c',
    'he',
    'nd',
    'Ġgen',
    'ius',
    'R',
    'eal',
    'ĠW',
    'r',
    'omet',
    'er',
    'p',
    'ad',
    'Ġlim',
    'iting',
    'ĠS',
    'i',
    'ĠL',
    'ore',
    'ĠAd',
    'ventures',
    'Ġvar',
    'ied',
    'D',
    'isc',
    'f',
    'in',
    'ĠPerson',
    'al',
    'Ch',
    'ris',
    'Ġinv',
    'ented',
    'Ġd',
    'ive',
    'ĠR',
    'ise',
    'Ġo',
    'z',
    'ĠCom',
    'ics',
    'Ġexp',
    'ose',
    'ĠRe',
    'b',
    'let',
    'ters',
    's',
    'ite',
    'im',
    'ated',
    'Ġh',
    'acking',
    'Ġeduc',
    'ated',
    'ĠNob',
    'ody',
    'Ġdep',
    'ri',
    'Ġincent',
    'ive',
    'ãĤ',
    '·',
    'Ġovers',
    'ight',
    'Ġtrib',
    'es',
    'ĠBelg',
    'ium',
    'Ġlicens',
    'ing',
    'our',
    't',
    'Produ',
    'ct',
    'ah',
    'l',
    'ĠG',
    'em',
    'Ġspecial',
    'ist',
    'Ġc',
    'ra',
    'ann',
    'ers',
    'ĠCor',
    'byn',
    'Ġ19',
    '73',
    'RE',
    'AD',
    'Ġsum',
    'mar',
    'Ġover',
    'look',
    'ĠApp',
    'lication',
    'Ġin',
    'appropriate',
    'Ġdownload',
    'ed',
    'Q',
    'ue',
    'ĠB',
    'ears',
    'Ġth',
    'umb',
    'ĠChar',
    'acter',
    'ĠReincarn',
    'ated',
    'ĠS',
    'id',
    'Ġdemonstr',
    'ates',
    's',
    'ky',
    'ĠBloom',
    'berg',
    'ĠAr',
    'ray',
    'ĠRes',
    'ults',
    'ĠFour',
    'th',
    'ĠED',
    'T',
    'ĠO',
    'scar',
    'c',
    'end',
    'Ġ10',
    '6',
    'ĠN',
    'ULL',
    'ĠH',
    'ERE',
    'm',
    'atch',
    'ĠBr',
    'un',
    'Ġgluc',
    'ose',
    'ie',
    'g',
    'eg',
    'u',
    'Ġcert',
    'ified',
    'Ġrel',
    'ie',
    'Ġhuman',
    'itarian',
    'Ġpr',
    'ayers',
    'K',
    'ing',
    'Ġn',
    'an',
    'h',
    'ou',
    '10',
    '8',
    'ul',
    'u',
    'Ġrenew',
    'able',
    'Ġdistingu',
    'ish',
    'Ġd',
    'ense',
    'ĠV',
    'ent',
    'ĠPack',
    'age',
    'ĠB',
    'oss',
    'Ġedit',
    'ors',
    'Ġm',
    'igr',
    'T',
    'ra',
    'ĠPet',
    'ers',
    'ĠAr',
    'ctic',
    '200',
    '4',
    'ĠC',
    'ape',
    'Ġloc',
    'ally',
    'Ġlast',
    'ing',
    'Ġhand',
    'y',
    '.',
    ').',
    'P',
    'an',
    'ĠR',
    'ES',
    'Ind',
    'ex',
    'Ġt',
    'ensions',
    'Ġformer',
    'ly',
    'Ġide',
    'ological',
    'Ġsens',
    'ors',
    'Ġdeal',
    'ers',
    'Ġdef',
    'ines',
    'S',
    'k',
    'Ġproceed',
    's',
    'Ġpro',
    'xy',
    'az',
    'ines',
    'ĠB',
    'ash',
    'ĠP',
    'ad',
    'ĠC',
    'raft',
    'eal',
    'ous',
    'Ġshe',
    'ets',
    'omet',
    'ry',
    'J',
    'une',
    'cl',
    'ock',
    'T',
    'T',
    'ĠThe',
    'atre',
    'ĠB',
    'uzz',
    'Ġch',
    'apters',
    'Ġmill',
    'enn',
    'Ġd',
    'ough',
    'ĠCongress',
    'ional',
    'Ġimag',
    'ined',
    'av',
    'ior',
    'Ġclin',
    'ic',
    'Ġ19',
    '45',
    'Ġhold',
    'er',
    'ro',
    'ot',
    'oles',
    'ter',
    'Ġrest',
    'art',
    'B',
    'N',
    'ĠHam',
    'as',
    'ĠJ',
    'ob',
    'Ġor',
    'b',
    'Ġr',
    'am',
    'Ġdiscl',
    'ose',
    'Ġtransl',
    'ate',
    'Ġimm',
    'igrant',
    'Ġannoy',
    'ing',
    'Ġtreat',
    'y',
    'an',
    'ium',
    'ĠTe',
    'a',
    'ĠLeg',
    'ion',
    'Ġcrowd',
    's',
    'ĠB',
    'ec',
    'ĠA',
    'er',
    'oh',
    'yd',
    'B',
    'ro',
    'Look',
    'ing',
    'Ġl',
    'bs',
    'Ġagg',
    'ress',
    'Ġse',
    'am',
    'Ġinter',
    'cept',
    'ĠM',
    'I',
    'mer',
    'cial',
    'act',
    'iv',
    'ĠC',
    'it',
    'Ġdim',
    'ension',
    'Ġconsist',
    'ency',
    'Ġr',
    'ushing',
    'ĠDou',
    'glas',
    'Ġtr',
    'im',
    'Inst',
    'all',
    'ick',
    'er',
    'Ġsh',
    'y',
    '10',
    '6',
    'Ġment',
    'ions',
    'pe',
    'lled',
    'ĠT',
    'ak',
    'c',
    'ost',
    'Ġclass',
    'room',
    'Ġfort',
    'une',
    'dri',
    'ven',
    'Ġun',
    'le',
    'ĠWhe',
    'el',
    'Ġinvest',
    'or',
    'ĠM',
    'asters',
    'k',
    'it',
    'Ġassoci',
    'ations',
    'ĠEv',
    'olution',
    'op',
    'ing',
    'us',
    'cript',
    'Ġprov',
    'incial',
    'ĠWal',
    'ter',
    'av',
    'i',
    'S',
    'O',
    'Ġun',
    'limited',
    'Eng',
    'lish',
    'ĠC',
    'ards',
    'ĠEb',
    'ola',
    'ne',
    'red',
    'Ġreven',
    'ge',
    'Ġout',
    'right',
    'um',
    'per',
    'Ġf',
    'itting',
    'ĠSol',
    'id',
    'Ġform',
    'ally',
    'Ġproblem',
    'atic',
    'Ġhaz',
    'ard',
    'Ġenc',
    'ryption',
    'Ġstraight',
    'forward',
    'ĠA',
    'K',
    'Ġp',
    'se',
    'ĠOr',
    'b',
    'ĠCh',
    'amber',
    'ĠM',
    'ak',
    'Cont',
    'ents',
    'Ġloyal',
    'ty',
    'Ġl',
    'yrics',
    'ĠSy',
    'm',
    'Ġwel',
    'comed',
    'Ġcook',
    'ed',
    'Ġmon',
    'op',
    'Ġn',
    'urse',
    'Ġmis',
    'leading',
    'Ġe',
    'ternal',
    'Ġshif',
    'ting',
    'Ġ+',
    '=',
    'V',
    'is',
    'Ġinst',
    'itutional',
    'ill',
    'ary',
    'Ġp',
    'ant',
    'VER',
    'T',
    'ĠA',
    'CC',
    'ĠEn',
    'h',
    'Ġinc',
    'on',
    'ĠRE',
    'UTERS',
    'Ġdon',
    'ated',
    'âĢ¦âĢ¦',
    'âĢ¦âĢ¦',
    'In',
    'tern',
    'Ġexhib',
    'it',
    'Ġt',
    'ire',
    'ĠR',
    'ic',
    'ĠCh',
    'ampion',
    'ĠMu',
    'hammad',
    'N',
    'ING',
    'ĠSoc',
    'cer',
    'Ġmob',
    'ility',
    'Ġvary',
    'ing',
    'ĠM',
    'ovie',
    'Ġl',
    'ord',
    'o',
    'ak',
    'F',
    'ield',
    'Ġve',
    'ctor',
    'us',
    'ions',
    'Ġsc',
    'rap',
    'Ġen',
    'abling',
    'm',
    'ake',
    'T',
    'or',
    '.',
    '*',
    '|',
    '|',
    'ĠWe',
    'bsite',
    'ĠN',
    'PC',
    'Ġsocial',
    'ist',
    'ĠBill',
    'y',
    'ĠAdd',
    'itional',
    'Ġc',
    'argo',
    'Ġfar',
    'ms',
    'ĠSo',
    'on',
    'ĠPri',
    'ze',
    'Ġmid',
    'night',
    'Ġ9',
    '00',
    'se',
    'en',
    'ĠSp',
    'ot',
    'Ġshe',
    'ep',
    'Ġspons',
    'ored',
    'ĠH',
    'i',
    'ĠJ',
    'ump',
    'Ġ19',
    '67',
    'Micro',
    'soft',
    'ĠAg',
    'ent',
    'Ġch',
    'arts',
    'd',
    'ir',
    'Ġadj',
    'acent',
    'Ġtr',
    'icks',
    'Ġman',
    'ga',
    'Ġex',
    'agger',
    '/',
    '>',
    'foot',
    'ball',
    'ĠF',
    'CC',
    'G',
    'C',
    'ĠT',
    'ier',
    'and',
    'ra',
    'OU',
    'ND',
    '%',
    '),',
    'Ġfru',
    'its',
    'V',
    'C',
    'ĠA',
    'A',
    'R',
    'ober',
    'Ġmid',
    'st',
    'â',
    'Ĺ',
    'ank',
    'a',
    'Ġlegisl',
    'ature',
    'ĠNe',
    'il',
    'Ġtour',
    'ists',
    '"',
    '"',
    'ĠWar',
    'ning',
    'ĠNever',
    'theless',
    'ĠOffic',
    'ial',
    'ĠWh',
    'atever',
    'Ġm',
    'old',
    'Ġdraft',
    'ed',
    'Ġsubst',
    'ances',
    'Ġbre',
    'ed',
    'Ġt',
    'ags',
    'ĠT',
    'ask',
    'Ġver',
    'b',
    'Ġmanufact',
    'ured',
    'com',
    'ments',
    'ĠPol',
    'ish',
    'Pro',
    'v',
    'Ġdetermin',
    'es',
    'Ob',
    'ama',
    'k',
    'ers',
    'Ġutter',
    'ly',
    'Ġse',
    'ct',
    'sc',
    'he',
    'ĠG',
    'ates',
    'ĠCh',
    'ap',
    'Ġal',
    'uminum',
    'Ġz',
    'ombie',
    'ĠT',
    'ouch',
    'ĠU',
    'P',
    'Ġsatisf',
    'y',
    'Ġpred',
    'omin',
    'asc',
    'ript',
    'Ġelabor',
    'ate',
    'Ġ19',
    '68',
    'Ġmeas',
    'uring',
    'ĠV',
    'ari',
    'any',
    'ahu',
    'Ġs',
    'ir',
    'ul',
    'ates',
    'id',
    'ges',
    'ick',
    'ets',
    'ĠSp',
    'encer',
    'T',
    'M',
    'oub',
    'ted',
    'Ġpre',
    'y',
    'Ġinstall',
    'ing',
    'ĠC',
    'ab',
    're',
    'ed',
    're',
    'ated',
    'Su',
    'pp',
    'Ġwr',
    'ist',
    'ĠK',
    'erry',
    '10',
    '7',
    'ĠK',
    'le',
    'ĠR',
    'achel',
    'Ġc',
    'otton',
    'ĠA',
    'RE',
    'ĠE',
    'le',
    'Cont',
    'rol',
    'Ġload',
    's',
    'ĠD',
    'od',
    'an',
    'as',
    'b',
    'one',
    'Ġclass',
    'ical',
    'ĠReg',
    'ional',
    'ĠInt',
    'eg',
    'V',
    'M',
    'Ġdes',
    'ires',
    'Ġaut',
    'ism',
    'support',
    'ed',
    'ĠM',
    'essage',
    'Ġcomp',
    'act',
    'writ',
    'er',
    'Ġ10',
    '9',
    'ĠHur',
    'ricane',
    'c',
    'ision',
    'Ġcy',
    'cles',
    'Ġdr',
    'ill',
    'Ġcolle',
    'ague',
    'Ġm',
    'aker',
    'G',
    'erman',
    'Ġmist',
    'aken',
    'S',
    'un',
    'ĠG',
    'ay',
    'Ġwhat',
    'soever',
    'Ġsell',
    's',
    'ĠA',
    'irl',
    'l',
    'iv',
    'ĠO',
    'ption',
    'Ġsol',
    'ved',
    'Ġse',
    'ctors',
    'Ġhorizont',
    'al',
    'Ġequ',
    'ation',
    'ĠSk',
    'ill',
    'ĠB',
    'io',
    'g',
    'ement',
    'ĠSn',
    'ap',
    'ĠLeg',
    'al',
    'Ġtradem',
    'ark',
    'Ġmake',
    'up',
    'Ġassemb',
    'led',
    'Ġsa',
    'ves',
    'ĠHallow',
    'een',
    'ĠVer',
    'mont',
    'ĠFR',
    'OM',
    'Ġfar',
    'ming',
    'ĠP',
    'odcast',
    'accept',
    'able',
    'ĠHig',
    'her',
    'Ġas',
    'leep',
    'ull',
    'ivan',
    'Ġrefere',
    'n',
    'ĠLe',
    'v',
    'Ġbul',
    'lets',
    'ok',
    'o',
    'H',
    'C',
    'Ġst',
    'airs',
    'Ġmain',
    'tains',
    'ĠL',
    'ower',
    'ĠV',
    'i',
    'Ġmar',
    'ine',
    'Ġac',
    'res',
    'Ġcoordin',
    'ator',
    'ĠJ',
    'oh',
    'Ġcounterpart',
    's',
    'ĠBrother',
    's',
    'Ġind',
    'ict',
    'b',
    'ra',
    'Ġch',
    'unk',
    'Ġc',
    'ents',
    'H',
    'ome',
    'ĠMon',
    'th',
    'Ġaccording',
    'ly',
    'if',
    'les',
    'ĠGerm',
    'ans',
    'ĠSy',
    'n',
    'H',
    'ub',
    'Ġey',
    'eb',
    'âĶĢâĶĢ',
    'âĶĢâĶĢ',
    'Ġr',
    'anges',
    'ĠHoll',
    'and',
    'ĠRob',
    'ot',
    'f',
    'c',
    'M',
    'ike',
    'Ġpl',
    'asma',
    'Ġsw',
    'ap',
    'Ġath',
    'lete',
    'ĠR',
    'ams',
    ",'",
    '"',
    'Ġinfect',
    'ions',
    'Ġcor',
    'rid',
    'Ġv',
    'ib',
    'Ġpat',
    'ches',
    'Ġtradition',
    'ally',
    'Ġrevel',
    'ation',
    'Ġswe',
    'ep',
    'Ġgl',
    'ance',
    'Ġin',
    'ex',
    '200',
    '3',
    'ĠR',
    'aw',
    'work',
    'ing',
    'os',
    'ures',
    'ĠD',
    'at',
    'ĠLyn',
    'ch',
    'Ġle',
    'verage',
    'ĠRe',
    'id',
    'Ġcorrel',
    'ation',
    'ian',
    'ces',
    'av',
    'ascript',
    'Ġrep',
    'ository',
    'ret',
    'ty',
    'Ġ19',
    '72',
    '24',
    '0',
    'Ġo',
    'un',
    'p',
    'ol',
    'ĠRe',
    'ed',
    'Ġtact',
    'ical',
    'is',
    'ite',
    'App',
    'le',
    'ĠQu',
    'inn',
    'Ġrap',
    'ed',
    'ill',
    'o',
    'Euro',
    'pe',
    'Ġalgorith',
    'ms',
    'ĠRod',
    'rig',
    'i',
    'u',
    'Ġill',
    'um',
    'Ġf',
    'ame',
    'Ġintrodu',
    'cing',
    'Ġdel',
    'ays',
    'ĠRaid',
    'ers',
    'Ġwh',
    'istle',
    'Ġnovel',
    's',
    'ĠRe',
    'ally',
    'Ġder',
    'iv',
    'Ġpublic',
    'ations',
    'ĠNe',
    'ither',
    'ĠCom',
    'merce',
    'Ġa',
    'ston',
    'l',
    'anguage',
    'Not',
    'es',
    'ĠR',
    'oth',
    'ĠF',
    'ear',
    'Ġm',
    'ate',
    'Ġpar',
    'ade',
    'ĠQ',
    'B',
    'Ġman',
    'eu',
    'ĠC',
    'incinnati',
    'm',
    'itting',
    'Ġwa',
    'ist',
    'ĠR',
    'ew',
    'Ġdisc',
    'ont',
    'Ð',
    '°',
    'Ġst',
    'aring',
    'Ġal',
    'ias',
    'Ġsec',
    'urities',
    'Ġtoile',
    't',
    'ĠJ',
    'edi',
    'Ġun',
    'law',
    'v',
    'ised',
    '////',
    '////',
    ']',
    '(',
    'ĠWe',
    'iss',
    'Ġpre',
    'st',
    'ĠComp',
    'an',
    'Ġmem',
    'o',
    'ĠGr',
    'ace',
    'J',
    'uly',
    'ĠEl',
    'ite',
    'cent',
    'er',
    'ĠSt',
    'ay',
    'Ġgal',
    'axy',
    'Ġto',
    'oth',
    'ĠS',
    'ettings',
    'Ġsubject',
    'ed',
    'ãĤ',
    '¦',
    'Ġline',
    'back',
    'Ġretail',
    'ers',
    'ĠW',
    'ant',
    'Ġd',
    'angers',
    'A',
    'ir',
    'Ġvolunt',
    'ary',
    'ew',
    'ay',
    'Ġinterpret',
    'ed',
    'ot',
    'ine',
    'Ã',
    '§',
    'Ġp',
    'el',
    'Serv',
    'ice',
    'ĠEvent',
    'ually',
    'Ġcare',
    'ers',
    'Ġthreat',
    'en',
    'Ġmem',
    'or',
    'ĠBrad',
    'ley',
    'anc',
    'ies',
    's',
    'n',
    'ĠUn',
    'known',
    'N',
    'ational',
    'Ġsh',
    'adows',
    'ail',
    'and',
    'ĠD',
    'ash',
    'Every',
    'one',
    'izz',
    'ard',
    'M',
    'arch',
    '=',
    '(',
    'Ġpull',
    's',
    'Ġstr',
    'anger',
    'Ġback',
    'wards',
    'ĠBern',
    'ard',
    'imens',
    'ional',
    'Ġch',
    'ron',
    'Ġtheoret',
    'ical',
    'k',
    'top',
    'Ġw',
    'are',
    'ĠInvest',
    'ig',
    'ĠIn',
    'iti',
    'ĠOper',
    'ations',
    'o',
    'ven',
    'oc',
    'ide',
    '*',
    '/',
    'Ġfl',
    'ames',
    'ĠC',
    'ash',
    'sh',
    'it',
    'Ġc',
    'ab',
    'ĠAn',
    'aly',
    'ĠSe',
    'ah',
    'Ġdefin',
    'ing',
    'Ġorder',
    'ing',
    'Ġimm',
    'un',
    'Ġpers',
    'istent',
    'AC',
    'H',
    'Russ',
    'ian',
    'm',
    'ans',
    'Ġh',
    'ind',
    'Ġphot',
    'ography',
    'Â',
    '©',
    'Ġh',
    'ug',
    'Ġ10',
    '7',
    'ĠH',
    'ence',
    'i',
    'ots',
    'ude',
    'au',
    'Ġsubsid',
    'ies',
    'Ġroutine',
    'ly',
    'ĠDev',
    'ice',
    'it',
    'ic',
    'Ġdisg',
    'ust',
    'land',
    'er',
    'Ġ19',
    '40',
    'Ġassign',
    'ment',
    'ĠB',
    'esides',
    'w',
    'ick',
    'ĠD',
    'ust',
    'us',
    'c',
    'struct',
    'ed',
    '11',
    '1',
    'de',
    'velop',
    'Ġf',
    'ond',
    'Ġinter',
    'section',
    'Ġdign',
    'ity',
    'Ġcommission',
    'er',
    'With',
    'out',
    're',
    'ach',
    'Ġcart',
    'oon',
    'Ġsc',
    'ales',
    'ãĥ',
    'Ń',
    'F',
    'IG',
    'Ġsurve',
    'ys',
    'ĠIndones',
    'ia',
    'Ġart',
    'work',
    'Ġun',
    'ch',
    'Ġcy',
    'cling',
    'un',
    'ct',
    'au',
    'er',
    'or',
    'ate',
    'ĠOb',
    'viously',
    'Ġcharacter',
    'ized',
    'fe',
    'ld',
    'Ġaff',
    'irm',
    'Ġinn',
    'ings',
    'Ġ',
    'é',
    'Ġal',
    'iens',
    'Ġcl',
    'oth',
    'et',
    'ooth',
    'ĠC',
    'ertain',
    'Â',
    '§',
    'Ġdig',
    'est',
    'k',
    'now',
    'ĠX',
    'L',
    'Ġpredict',
    'ions',
    'Ġd',
    'in',
    'W',
    'AR',
    'Ġafter',
    'math',
    'Ex',
    'ample',
    'ĠSu',
    'ccess',
    'ĠTh',
    'r',
    'IG',
    'N',
    'Ġmin',
    'er',
    'B',
    'us',
    'Ġcl',
    'arity',
    'heim',
    'er',
    'ĠO',
    'UT',
    'ĠS',
    'end',
    'ĠCirc',
    'le',
    'ĠD',
    'iet',
    'Ġpron',
    'ounced',
    'Ġcreat',
    'ors',
    'Ġearthqu',
    'ake',
    'atter',
    'y',
    'ge',
    'ons',
    'Ġo',
    'd',
    'Ġlay',
    'ing',
    'or',
    'p',
    'U',
    'lt',
    'pro',
    'ject',
    'Ġunder',
    'min',
    'Ġsequ',
    'el',
    'S',
    'am',
    'ĠDark',
    'ness',
    'Ġre',
    'ception',
    'b',
    'ull',
    'Y',
    'S',
    'ĠV',
    'ir',
    'Ġsequ',
    'ences',
    'ĠCo',
    'in',
    'Ġout',
    'fit',
    'ĠW',
    'ait',
    '1',
    '19',
    'Ġdel',
    'ivers',
    '....',
    '..',
    'Ġbl',
    'own',
    'ĠE',
    'sc',
    'ĠM',
    'ath',
    'per',
    'm',
    'ĠU',
    'l',
    'Ġgl',
    'im',
    'Ġfac',
    'ial',
    'Ġgreen',
    'house',
    'Ġto',
    'kens',
    '/',
    '-',
    'ĠAnn',
    'ual',
    'ĠON',
    'E',
    'Ġteen',
    'age',
    'ĠPhys',
    'ical',
    'ĠL',
    'ang',
    'ĠC',
    'elt',
    'Ġsu',
    'ed',
    'ivid',
    'ually',
    'Ġpat',
    'ience',
    'ch',
    'air',
    'reg',
    'ular',
    'Ġa',
    'ug',
    'in',
    'v',
    'ex',
    'cept',
    'ĠL',
    'il',
    'Ġn',
    'est',
    'f',
    'd',
    's',
    'um',
    'ĠCh',
    'ase',
    'Russ',
    'ia',
    'ĠJenn',
    'ifer',
    'Ġoff',
    'season',
    'Over',
    'all',
    'F',
    'ore',
    'Ġr',
    'iot',
    'A',
    'ud',
    'form',
    'er',
    'Ġdefend',
    'ers',
    'ĠC',
    'T',
    'iot',
    'ic',
    'rib',
    'ly',
    'Ġautom',
    'ated',
    'Ġpen',
    'is',
    'Ġins',
    'ist',
    'Ġdi',
    'agram',
    'ĠS',
    'QL',
    'ĠG',
    'arc',
    'Ġw',
    'itch',
    'cl',
    'ient',
    'ier',
    'ra',
    'am',
    'bers',
    'Ġrec',
    'ount',
    'f',
    'ar',
    'V',
    'ery',
    'oster',
    'one',
    'Ġappreci',
    'ated',
    'ĠPer',
    'fect',
    'S',
    'ection',
    'Ġd',
    'oses',
    'oca',
    'ust',
    'Ġcost',
    'ly',
    'Ġg',
    'rams',
    'ĠSh',
    'i',
    'Ġwrest',
    'ling',
    'Ġ19',
    '71',
    'Ġtro',
    'phy',
    'Ġn',
    'erve',
    'ĠK',
    'az',
    'ĠExper',
    'ience',
    'Ġpled',
    'ged',
    'Ġplay',
    'back',
    'Ġcreat',
    'ivity',
    'by',
    'e',
    'Ġattack',
    'ers',
    'Ġhold',
    'ers',
    'ĠCo',
    'ach',
    'ĠPh',
    'D',
    'Ġtransf',
    'ers',
    'Ġcol',
    'ored',
    'ĠH',
    'indu',
    'Ġd',
    'rown',
    'Ġlist',
    'ened',
    'ĠW',
    'A',
    'ias',
    'm',
    'P',
    'O',
    'Ġappeal',
    'ing',
    'Ġdiscl',
    'osed',
    'ĠCh',
    'icken',
    'ag',
    'ging',
    'Ġple',
    'aded',
    'Ġnav',
    'igation',
    'ĠReturn',
    's',
    'Ġ[',
    '[',
    'R',
    'OR',
    'E',
    'A',
    'Ġphotograp',
    'her',
    'ĠR',
    'ider',
    'ipp',
    'ers',
    'Ġsl',
    'ice',
    'Ġe',
    'rect',
    'Ġhe',
    'd',
    'iss',
    'ance',
    'ĠVik',
    'ings',
    'ur',
    'ious',
    'Ġapp',
    'et',
    'oubted',
    'ly',
    'Ch',
    'ild',
    'Ġauthent',
    'ic',
    'o',
    'os',
    'ĠM',
    'aking',
    'Ġannoun',
    'cing',
    'Ġb',
    'od',
    'Ġmet',
    'er',
    'ĠN',
    'ine',
    'ĠR',
    'ogue',
    'Ġwork',
    'force',
    'Ġrenew',
    'ed',
    'Ġorganis',
    'ations',
    'ac',
    's',
    'P',
    'LE',
    'Sh',
    'ort',
    'Ġcomp',
    'ounds',
    'ĠVis',
    'it',
    'Ġen',
    'velop',
    'ear',
    'th',
    'Ġsupport',
    'ive',
    'gg',
    'le',
    'ĠBrus',
    'sels',
    'ĠGu',
    'ild',
    'Cre',
    'ate',
    'RE',
    'L',
    'Ġaver',
    'aged',
    'Ġ19',
    '69',
    'ri',
    'ages',
    'Ġlength',
    'y',
    'Ġforg',
    'ot',
    'O',
    'kay',
    'ĠE',
    'rd',
    'Ġdeal',
    'er',
    'Ġrec',
    'ession',
    'D',
    'D',
    'Ġdesper',
    'ately',
    'Ġhun',
    'ger',
    'Ġst',
    'icks',
    'Ġm',
    'ph',
    'ĠF',
    'aith',
    'Ġintention',
    'ally',
    'Ġdem',
    'ol',
    'ue',
    'ller',
    'ĠS',
    'ale',
    'Ġde',
    'bris',
    's',
    'pring',
    'Ġle',
    'ap',
    '>>',
    '>>',
    'Ġcontain',
    'ers',
    'se',
    'lling',
    'rane',
    'an',
    'atter',
    'ing',
    'Ġcomment',
    'ed',
    'ĠC',
    'M',
    'on',
    'ut',
    'Ġwood',
    's',
    'es',
    'pecially',
    'Ġorgan',
    'ize',
    'iv',
    'ic',
    'ĠWood',
    's',
    'ang',
    'a',
    's',
    'qu',
    'Ġm',
    'aj',
    'am',
    'on',
    'Ġax',
    'is',
    'Ġ19',
    '74',
    'ĠDen',
    'mark',
    'Ġwar',
    'rior',
    'ĠP',
    'and',
    'Ġout',
    'lined',
    'ĠB',
    'O',
    'ins',
    'ula',
    'z',
    'illa',
    'eb',
    'ook',
    'Ġd',
    'are',
    'Ġsear',
    'ched',
    'Ġnav',
    'igate',
    'S',
    'n',
    'writ',
    'ing',
    'Ġun',
    'ited',
    'J',
    'apan',
    'ĠHe',
    'brew',
    'Ġfl',
    'ame',
    'Ġrel',
    'ies',
    'Ġcatch',
    'ing',
    'ĠSh',
    'o',
    'Ġimprison',
    'ment',
    'Ġp',
    'ockets',
    'Ġclos',
    'ure',
    'ĠF',
    'am',
    't',
    'im',
    'ade',
    'qu',
    'Act',
    'ivity',
    'Ġrecru',
    'iting',
    'ĠW',
    'ATCH',
    'ĠArgent',
    'ina',
    'd',
    'est',
    'Ġapolog',
    'ize',
    'or',
    'o',
    'Ġlack',
    's',
    'Ġtun',
    'ed',
    'ĠGriff',
    'in',
    'Ġinf',
    'amous',
    'Ġcelebr',
    'ity',
    'ss',
    'on',
    'Ġ',
    '----------------------------------------------------------------',
    'ĠIs',
    'is',
    'ĠDis',
    'play',
    'Ġcred',
    'ibility',
    'Ġeconom',
    'ies',
    'Ġhead',
    'line',
    'ĠCow',
    'boys',
    'Ġind',
    'ef',
    'Ġl',
    'ately',
    'Ġincent',
    'ives',
    'but',
    'ton',
    'ĠM',
    'ob',
    'A',
    'ut',
    'Ġres',
    'igned',
    'ĠO',
    'm',
    'c',
    'amp',
    'Ġprof',
    'iles',
    'Ġsche',
    'mes',
    'olph',
    'ins',
    'ay',
    'ed',
    'Cl',
    'inton',
    'en',
    'h',
    'ĠY',
    'ahoo',
    'Ġab',
    'st',
    'Ġan',
    'k',
    'su',
    'its',
    'Ġw',
    'ished',
    'ĠMar',
    'co',
    'udd',
    'en',
    'Ġsp',
    'here',
    'ĠB',
    'ishop',
    'Ġincorpor',
    'ated',
    'ĠPl',
    'ant',
    '11',
    '4',
    'Ġh',
    'ated',
    'p',
    'ic',
    'Ġdon',
    'ate',
    'Ġl',
    'ined',
    'Ġbe',
    'ans',
    'Ġsteal',
    'ing',
    'Ġcost',
    'ume',
    'Ġsher',
    'iff',
    'Ġfor',
    'ty',
    'Ġint',
    'act',
    'Ġadapt',
    'ed',
    'Ġtrave',
    'lling',
    'b',
    'art',
    'Ġnice',
    'ly',
    'Ġdri',
    'ed',
    'Ġsc',
    'al',
    'os',
    'ity',
    'NOT',
    'E',
    'ĠB',
    'h',
    'ĠBron',
    'cos',
    'ĠI',
    'gn',
    'Ġint',
    'imate',
    'Ġchem',
    'istry',
    'Ġopt',
    'imal',
    'D',
    'eb',
    'ĠGener',
    'ation',
    'Ġ]',
    ',',
    'ich',
    'i',
    'ĠW',
    'ii',
    'ĠYOU',
    'R',
    'vent',
    'ions',
    'W',
    'rite',
    'Ġpop',
    'ul',
    'un',
    'ning',
    'ĠW',
    'or',
    'V',
    'ol',
    'Ġqu',
    'een',
    'head',
    's',
    'K',
    'K',
    'Ġanaly',
    'ze',
    'op',
    'ic',
    'ear',
    'chers',
    'Ġd',
    'ot',
    'leg',
    'raph',
    'ast',
    'ically',
    'Ġupgr',
    'ades',
    'Ġca',
    'res',
    'Ġext',
    'ending',
    'Ġfree',
    'ze',
    'Ġin',
    'ability',
    'Ġorg',
    'ans',
    'Ġpret',
    'end',
    'Ġout',
    'let',
    '11',
    '3',
    'ol',
    'an',
    'ĠM',
    'all',
    'ul',
    'ing',
    't',
    'alk',
    'Ġexpress',
    'ing',
    'ĠAl',
    'ways',
    'ĠBe',
    'gin',
    'f',
    'iles',
    'Ġlic',
    'enses',
    '%',
    '%',
    'ĠM',
    'itt',
    'Ġfil',
    'ters',
    'ĠMil',
    'waukee',
    'G',
    'N',
    'Ġunf',
    'old',
    'M',
    'o',
    'Ġnut',
    'rition',
    'pp',
    'o',
    'B',
    'o',
    'Ġfound',
    'ing',
    'Ġunder',
    'mine',
    'Ġeas',
    'iest',
    'ĠC',
    'zech',
    'ĠM',
    'ack',
    'Ġsexual',
    'ity',
    'ĠN',
    'ixon',
    'W',
    'in',
    'ĠAr',
    'n',
    'ĠK',
    'in',
    'ãĤ',
    '£',
    'ic',
    'er',
    'Ġfort',
    'un',
    'Ġsurf',
    'aces',
    'agh',
    'd',
    'Ġcar',
    'riers',
    'ĠP',
    'ART',
    'ĠT',
    'ib',
    'Ġinter',
    'val',
    'Ġfrust',
    'rating',
    'ĠSh',
    'ip',
    'ĠAr',
    'med',
    'ff',
    'e',
    'Ġbo',
    'ats',
    'ĠAb',
    'raham',
    'in',
    'is',
    'Ġsu',
    'ited',
    'th',
    'read',
    'i',
    'ov',
    'ab',
    'ul',
    'ĠVenezuel',
    'a',
    'Ġto',
    'm',
    'su',
    'per',
    'Ġcast',
    'le',
    'alth',
    'ough',
    'iox',
    'ide',
    'ec',
    'hes',
    'Ġevolution',
    'ary',
    'Ġnegoti',
    'ate',
    'Ġconfront',
    'ed',
    'Rem',
    'ember',
    'Ġ17',
    '0',
    'S',
    'uch',
    'Ġ9',
    '11',
    'm',
    'ult',
    'ĠA',
    'byss',
    'ur',
    'ry',
    'ke',
    'es',
    'spe',
    'c',
    'ĠBarb',
    'ara',
    'Ġbelong',
    'ing',
    'Ġvill',
    'ain',
    'ist',
    'ani',
    'Ġaccount',
    'able',
    'Ġport',
    'ions',
    'ĠDe',
    'cl',
    'U',
    'r',
    'ĠK',
    'ate',
    'g',
    're',
    'Ġmag',
    'azines',
    'UC',
    'K',
    'Ġregul',
    'ate',
    'om',
    'on',
    'ĠAl',
    'most',
    'Ġover',
    'view',
    'Ġsc',
    'ram',
    'Ġl',
    'oot',
    'ĠF',
    'itz',
    'Ġcharacter',
    'istic',
    'ĠSn',
    'ake',
    's',
    'ay',
    'ĠR',
    'ico',
    'Ġtra',
    'it',
    'ĠJo',
    'ined',
    'au',
    'cus',
    'Ġadapt',
    'ation',
    'ĠAirl',
    'ines',
    'Ġarch',
    'ae',
    'ĠI',
    'de',
    'Ġb',
    'ikes',
    'Ġliter',
    'ary',
    'Ġinflu',
    'ences',
    'ĠUs',
    'ed',
    'C',
    'reat',
    'Ġple',
    'a',
    'ĠDef',
    'ence',
    'ĠAss',
    'ass',
    'Ġp',
    'ond',
    'UL',
    'T',
    ')',
    '"',
    'Ġeval',
    'uated',
    'Ġob',
    'taining',
    'Ġdem',
    'ographic',
    'Ġvig',
    'il',
    'ale',
    'y',
    'Ġsp',
    'ouse',
    'ĠSeah',
    'awks',
    'resp',
    'ons',
    'ĠB',
    'elt',
    'um',
    'atic',
    'Ġr',
    'ises',
    'run',
    'ner',
    'ĠMichel',
    'le',
    'Ġpot',
    'ent',
    'r',
    'ace',
    'ĠP',
    'AC',
    'F',
    'ind',
    'olester',
    'ol',
    'IS',
    'S',
    'ĠIntrodu',
    'ced',
    'ress',
    'es',
    'ign',
    'ment',
    'O',
    's',
    'ĠT',
    'u',
    'ĠDe',
    'x',
    'ic',
    'ides',
    'Ġspark',
    'ed',
    'ĠLaur',
    'a',
    'ĠBry',
    'ant',
    'Ġsm',
    'iling',
    'ĠNex',
    'us',
    'Ġdefend',
    'ants',
    'ĠCat',
    'al',
    'Ġdis',
    'hes',
    'sh',
    'aped',
    'Ġpro',
    'long',
    'm',
    't',
    '(',
    '$',
    'ãĢ',
    'Ĥ',
    'Ġcalcul',
    'ations',
    'ĠS',
    'ame',
    'Ġp',
    'iv',
    'H',
    'H',
    'Ġcance',
    'lled',
    'Ġgr',
    'in',
    'Ġterrit',
    'ories',
    'ist',
    'ically',
    'C',
    'ome',
    'ĠP',
    'arent',
    'Pro',
    'ject',
    'Ġneg',
    'lig',
    'ĠPriv',
    'acy',
    'Ġam',
    'mo',
    'LE',
    'CT',
    'olute',
    'ly',
    'ĠEp',
    'ic',
    'Ġmis',
    'under',
    'w',
    'al',
    'Apr',
    'il',
    'm',
    'os',
    'path',
    'y',
    'ĠC',
    'arson',
    'Ġalbum',
    's',
    'ĠE',
    'asy',
    'Ġpist',
    'ol',
    '<',
    '<',
    'Ġ\\',
    '(',
    't',
    'arget',
    'hel',
    'p',
    'Ġinter',
    'pre',
    'cons',
    'cious',
    'ĠH',
    'ousing',
    'ĠJ',
    'oint',
    '12',
    '7',
    'Ġbe',
    'ers',
    's',
    'cience',
    'ĠFire',
    'fox',
    'effect',
    'ive',
    'ĠC',
    'abin',
    'ĠO',
    'kay',
    'ĠApp',
    'lic',
    'Ġspace',
    'craft',
    'ĠS',
    'R',
    've',
    't',
    'ĠStr',
    'ange',
    'S',
    'B',
    'Ġcor',
    'ps',
    'iber',
    'al',
    'e',
    'fficient',
    'Ġpreval',
    'ence',
    'Ġeconom',
    'ists',
    '11',
    '8',
    'Th',
    'read',
    'ord',
    'able',
    'OD',
    'E',
    'ĠC',
    'ant',
    '=-',
    '=-',
    'if',
    'iable',
    'ĠA',
    'round',
    'Ġpo',
    'le',
    'Ġwilling',
    'ness',
    'CL',
    'A',
    'ĠK',
    'id',
    'Ġcomple',
    'ment',
    'Ġsc',
    'attered',
    'Ġin',
    'mates',
    'Ġble',
    'eding',
    'e',
    'very',
    'Ġque',
    'ue',
    'ĠTr',
    'ain',
    'Ġh',
    'ij',
    'Ġme',
    'lee',
    'ple',
    'ted',
    'Ġdig',
    'it',
    'Ġg',
    'em',
    'offic',
    'ial',
    'Ġlif',
    'ting',
    'Ð',
    'µ',
    'Re',
    'qu',
    'it',
    'utes',
    'Ġpack',
    'aging',
    'ĠWork',
    'ers',
    'h',
    'ran',
    'ĠLeban',
    'on',
    'ol',
    'esc',
    'Ġpun',
    'ished',
    'ĠJ',
    'uan',
    'Ġj',
    'am',
    'ĠD',
    'ocument',
    'Ġm',
    'apping',
    'ic',
    'ates',
    'Ġinev',
    'itably',
    'Ġvan',
    'illa',
    'ĠT',
    'on',
    'Ġwat',
    'ches',
    'Ġle',
    'agues',
    'Ġiniti',
    'ated',
    'deg',
    'ree',
    'port',
    'ion',
    'Ġrec',
    'alls',
    'Ġru',
    'in',
    'Ġm',
    'elt',
    'I',
    'AN',
    'Ġhe',
    'm',
    'Ex',
    'p',
    'Ġb',
    'aking',
    'ĠCol',
    'omb',
    'at',
    'ible',
    'Ġrad',
    'ius',
    'pl',
    'ug',
    'ĠI',
    'F',
    'et',
    'ically',
    'Ġf',
    'ict',
    'H',
    'ER',
    'ĠT',
    'ap',
    'atin',
    'um',
    'Ġin',
    'k',
    'Ġco',
    'h',
    'ĠW',
    'izard',
    'b',
    'oth',
    'te',
    'x',
    'Ġsp',
    'ends',
    'ĠCurrent',
    'ly',
    'ĠP',
    'it',
    'Ġneur',
    'ons',
    'ig',
    'nt',
    'Ġr',
    'all',
    'Ġbus',
    'es',
    'b',
    'uilding',
    'Ġadjust',
    'ments',
    'Ġc',
    'ried',
    'ibl',
    'ical',
    'att',
    'ed',
    'ĠZ',
    'ion',
    'ĠM',
    'atter',
    'Ġmed',
    'itation',
    'ĠD',
    'ennis',
    'Ġour',
    's',
    'ĠT',
    'ab',
    'Ġrank',
    'ings',
    'ort',
    'al',
    'Ġad',
    'vers',
    'Ġsur',
    'render',
    'ĠG',
    'ob',
    'ci',
    'um',
    'om',
    'as',
    'im',
    'eter',
    'Ġmulti',
    'player',
    'Ġhero',
    'in',
    'Ġoptim',
    'istic',
    'Ġindic',
    'ator',
    'ĠBr',
    'ig',
    'Ġgro',
    'cery',
    'Ġapplic',
    'ant',
    'ĠRock',
    'et',
    'v',
    'id',
    'Ex',
    'ception',
    'p',
    'ent',
    'Ġorgan',
    'izing',
    'Ġenc',
    'ounters',
    'ĠT',
    'OD',
    'Ġjew',
    'el',
    'S',
    'ave',
    'ĠChrist',
    'ie',
    'Ġhe',
    'ating',
    'Ġl',
    'azy',
    'ĠC',
    'P',
    'Ġcous',
    'in',
    'Con',
    'fig',
    'Ġreg',
    'ener',
    'Ġne',
    'arest',
    'Ġachie',
    'ving',
    'EN',
    'S',
    'th',
    'row',
    'ĠRich',
    'mond',
    'ant',
    'le',
    '200',
    '2',
    'Ġan',
    'ten',
    'b',
    'ird',
    '13',
    '3',
    'Ġn',
    'arc',
    'r',
    'aint',
    'un',
    'ny',
    'ĠHispan',
    'ic',
    'ourn',
    'aments',
    'Ġprop',
    'he',
    'ĠTh',
    'ailand',
    'ĠT',
    'i',
    'Ġinject',
    'ion',
    'Ġinher',
    'it',
    'rav',
    'is',
    'Ġmed',
    'i',
    'Ġwho',
    'ever',
    'ĠDE',
    'BUG',
    'G',
    'P',
    'ĠH',
    'ud',
    'C',
    'ard',
    'p',
    'rom',
    'Ġp',
    'or',
    'Ġover',
    'head',
    'L',
    'aw',
    'Ġviol',
    'ate',
    'Ġhe',
    'ated',
    'Ġdescript',
    'ions',
    'Ġachieve',
    'ments',
    'ĠBe',
    'er',
    'ĠQu',
    'ant',
    'W',
    'as',
    'Ġe',
    'ighth',
    'ĠI',
    'v',
    'Ġspecial',
    'ized',
    'U',
    'PDATE',
    'ĠD',
    'elta',
    'P',
    'op',
    'J',
    'ul',
    'ĠAs',
    'k',
    'oph',
    'y',
    'Ġnews',
    'letters',
    'ĠT',
    'ool',
    'Ġg',
    'ard',
    'ĠConf',
    'eder',
    'ĠGM',
    'T',
    'ĠAb',
    'bott',
    'Ġimm',
    'unity',
    'ĠV',
    'M',
    'Is',
    'lam',
    'Ġimpl',
    'icit',
    'w',
    'd',
    'Ġ19',
    '44',
    'rav',
    'ity',
    'omet',
    'ric',
    'Ġsurv',
    'iving',
    'ur',
    'ai',
    'ĠPr',
    'ison',
    'Ġr',
    'ust',
    'ĠSk',
    'etch',
    'Ġbe',
    'es',
    'ĠThe',
    'ory',
    'Ġmer',
    'it',
    'T',
    'ex',
    'ch',
    'at',
    'Ġm',
    'im',
    'Ġpast',
    'e',
    'ĠK',
    'och',
    'Ġignor',
    'ance',
    'ĠSh',
    'oot',
    'Ġbas',
    'ement',
    'Un',
    'ited',
    'ĠAd',
    'vis',
    'he',
    'ight',
    'Ġf',
    'oster',
    'Ġdet',
    'ain',
    'in',
    'formation',
    'Ġne',
    'ural',
    "'",
    ';',
    'Ġprov',
    'es',
    'all',
    'ery',
    'Ġinv',
    'itation',
    'um',
    'bers',
    'Ġc',
    'attle',
    'Ġbicy',
    'cle',
    'z',
    'i',
    'Ġconsult',
    'ant',
    'Ġap',
    'ology',
    'ĠT',
    'iger',
    'Ġ12',
    '3',
    '99',
    '9',
    'Ġind',
    'ividually',
    'r',
    't',
    'ig',
    'ion',
    'ĠBrazil',
    'ian',
    'Ġdist',
    'urb',
    'Ġentreprene',
    'urs',
    'Ġfore',
    'sts',
    'cer',
    'pt',
    'pl',
    'ates',
    'p',
    'her',
    'clip',
    'se',
    'Ġtw',
    'itter',
    'Ġac',
    'ids',
    'ograph',
    'ical',
    'h',
    'um',
    'ĠB',
    'ald',
    'if',
    'ully',
    'Ġcomp',
    'iler',
    'ĠD',
    'A',
    'Ġdon',
    'or',
    'as',
    'i',
    'Ġtrib',
    'al',
    'l',
    'ash',
    'ĠCon',
    'fig',
    'Ġapplic',
    'ants',
    'Ġsal',
    'aries',
    '13',
    '5',
    'Put',
    'in',
    'ĠF',
    'ocus',
    'ir',
    's',
    'Ġmisc',
    'onduct',
    'ĠH',
    'az',
    'Ġeat',
    'en',
    'M',
    'obile',
    'Mus',
    'lim',
    'ĠMar',
    'cus',
    'v',
    'iol',
    'Ġfavor',
    'able',
    'Ġst',
    'ub',
    'ad',
    'in',
    'ĠH',
    'ob',
    'Ġfaith',
    'ful',
    'Ġelectron',
    'ics',
    'Ġvac',
    'uum',
    'w',
    'ait',
    'back',
    'ed',
    'econom',
    'ic',
    'd',
    'ist',
    'Ġten',
    'ure',
    'Ġsince',
    're',
    'ĠT',
    'ogether',
    'ĠW',
    'ave',
    'Ġprog',
    'ression',
    'Ġden',
    'ying',
    'Ġdist',
    'ress',
    'br',
    'aska',
    'th',
    'ird',
    'Ġmix',
    'ing',
    'Ġcolon',
    'ial',
    'Ġpriv',
    'ately',
    'Ġun',
    'rest',
    'atern',
    'ity',
    'Ġprem',
    'ises',
    'ant',
    'i',
    'greg',
    'ation',
    'Ġlic',
    'ence',
    'ĠH',
    'ind',
    'ĠSam',
    'uel',
    'Ġconvinc',
    'ing',
    'ĠA',
    'ce',
    'ĠR',
    'ust',
    'ĠNet',
    'anyahu',
    'Ġhand',
    'les',
    'ĠP',
    'atch',
    'orient',
    'ed',
    'ah',
    'o',
    'ĠG',
    'onz',
    'Ġhack',
    'ers',
    'claim',
    'er',
    'Ġcustom',
    's',
    'ĠGr',
    'an',
    'f',
    'ighters',
    'Ġl',
    'uc',
    'Ġman',
    'uscript',
    'aren',
    'thood',
    'Ġdev',
    'il',
    'Ġwar',
    'riors',
    'Ġoff',
    'enders',
    'Will',
    'iam',
    'Ġhol',
    'idays',
    'Ġnight',
    'mare',
    'Ġle',
    'ver',
    'iff',
    'erent',
    'St',
    'at',
    'Ġexhib',
    'ition',
    'put',
    'ed',
    'ĠP',
    'ure',
    'Ġal',
    'pha',
    'Ġenthus',
    'iasm',
    'ĠRepresent',
    'atives',
    'E',
    'AR',
    'ĠT',
    'yp',
    'Ġwhe',
    'at',
    'ĠAl',
    'f',
    'Ġcor',
    'rection',
    'Ġev',
    'angel',
    'AT',
    'T',
    'M',
    'iss',
    'Ġs',
    'oup',
    'Ġimpl',
    'ied',
    'par',
    'am',
    'Ġsex',
    'y',
    'ĠL',
    'ux',
    'Ġrep',
    'ublic',
    'p',
    'atch',
    'ab',
    'lish',
    'Ġic',
    'ons',
    'Ġfather',
    's',
    'ĠG',
    'ET',
    'ĠCar',
    'ib',
    'Ġregul',
    'ated',
    'ĠCo',
    'hen',
    'ĠBob',
    'by',
    'Ġn',
    'er',
    'Ġb',
    'ent',
    'vent',
    'ory',
    'ĠAl',
    'ong',
    'ĠE',
    'ST',
    'ĠWall',
    'ace',
    'Ġmurd',
    'ers',
    'r',
    'ise',
    'ke',
    'll',
    'ĠCommon',
    'wealth',
    'Ġn',
    'asty',
    'et',
    'a',
    'ĠM',
    'IT',
    'Ġadminist',
    'ered',
    'Ġgenuine',
    'ly',
    'Ed',
    'itor',
    'n',
    'ick',
    'Ġhyd',
    'ro',
    '****************',
    '****************',
    'ĠB',
    'le',
    'Ġfin',
    'es',
    'Ġg',
    'orge',
    'aus',
    'ible',
    'r',
    'h',
    'Ġapp',
    'le',
    'ment',
    'ioned',
    'Ġro',
    'pe',
    'ot',
    'yp',
    'H',
    'R',
    'Ġdisappoint',
    'ing',
    'Ġc',
    'age',
    'n',
    'ik',
    'Ġdoub',
    'ts',
    'ĠF',
    'REE',
    'print',
    's',
    'ĠM',
    'UST',
    'Ġvend',
    'ors',
    'ĠIn',
    'qu',
    'Ġliber',
    'als',
    'Ġcontract',
    'or',
    'Ġup',
    'side',
    'child',
    'ren',
    'Ġtrick',
    'y',
    'Ġregul',
    'ators',
    'charg',
    'ed',
    'l',
    'iter',
    'Ġ',
    '***',
    'Ġreb',
    'ell',
    'l',
    'ang',
    'Ġloc',
    'als',
    'Ġphys',
    'icians',
    'Ġhe',
    'y',
    'ar',
    'se',
    't',
    'm',
    'ĠLe',
    'x',
    'Ġbehavior',
    'al',
    'success',
    'ful',
    'F',
    'X',
    'Ġbr',
    'ick',
    'ov',
    'ic',
    'Ġcon',
    'form',
    'Ġreview',
    'ing',
    'Ġins',
    'ights',
    'Ġbi',
    'ology',
    'ĠRem',
    'ove',
    'ĠExt',
    'ra',
    'Ġcomm',
    'itting',
    'indu',
    'ced',
    'ignt',
    'y',
    'ig',
    'm',
    'Ġat',
    'omic',
    'Comm',
    'on',
    'ĠE',
    'M',
    'ĠP',
    'ere',
    'ĠIt',
    'ems',
    'e',
    'h',
    'Ġpres',
    'erved',
    'ĠH',
    'ood',
    'Ġprison',
    'er',
    'Ġbankrupt',
    'cy',
    'Ġg',
    'ren',
    'us',
    'hes',
    'Ġexplo',
    'itation',
    'Ġsign',
    'atures',
    'Ġfin',
    'an',
    ']',
    ',"',
    'ĠM',
    'R',
    'Ġme',
    'g',
    'rem',
    'lin',
    'Ġmusic',
    'ians',
    'Ġselect',
    'ing',
    'Ġexam',
    'ining',
    'IN',
    'K',
    'l',
    'ated',
    'H',
    'i',
    'Ġart',
    'ic',
    'Ġp',
    'ets',
    'Ġimp',
    'air',
    'ĠM',
    'AN',
    'Ġtable',
    'ts',
    'in',
    'clude',
    'R',
    'ange',
    'Ġca',
    'ut',
    'Ġlog',
    's',
    'Ġmount',
    'ing',
    'Ġun',
    'aware',
    'Ġdynam',
    'ics',
    'ĠPalest',
    'ine',
    'ĠQu',
    'arter',
    'ĠPur',
    'ple',
    'Ġm',
    'a',
    'ĠIm',
    'port',
    'Ġcollect',
    'ions',
    'ci',
    'ation',
    'Ġsuccess',
    'or',
    'Ġcl',
    'one',
    'Ġaim',
    'ing',
    'Ġposs',
    'essed',
    'Ġstick',
    'ing',
    'Ġsh',
    'aking',
    'Ġloc',
    'ate',
    'ĠH',
    'ockey',
    'T',
    'urn',
    '17',
    '0',
    'Ġfif',
    'teen',
    'ĠHar',
    'rison',
    'Ġcontinu',
    'ously',
    'ĠT',
    'C',
    'ĠVal',
    'ent',
    'ĠRes',
    'cue',
    'Ġby',
    'pass',
    'am',
    'ount',
    'Ġm',
    'ast',
    'Ġprotect',
    's',
    'Ġart',
    'istic',
    'Ġsomet',
    'ime',
    'Ġsh',
    'oe',
    'Ġshout',
    'ed',
    'ific',
    'ant',
    'et',
    'itive',
    'ĠReg',
    'ister',
    'ĠJ',
    'in',
    'Ġconcent',
    'rated',
    'ling',
    'ton',
    'on',
    'ies',
    'Ġgener',
    'ator',
    'yr',
    'im',
    'ĠAr',
    'men',
    'Ġclear',
    'ing',
    'id',
    'o',
    'ĠT',
    'W',
    'al',
    'ph',
    'Ġlad',
    'ies',
    'H',
    'ard',
    'Ġdial',
    'og',
    'Ġinput',
    's',
    'æ',
    'ľ',
    'Ġpos',
    'es',
    'Ġsl',
    'ots',
    'ĠPrem',
    'ium',
    'Ġle',
    'aks',
    'Ġboss',
    'es',
    'Ġ11',
    '3',
    'c',
    'ourse',
    'A',
    'cc',
    'ĠNew',
    'ton',
    'ĠAust',
    'ria',
    'ĠM',
    'age',
    'Ġte',
    'aches',
    'ab',
    'ad',
    'Ġwe',
    'ars',
    'Ġc',
    'yl',
    'Ġcur',
    'se',
    'ĠS',
    'ales',
    'ĠW',
    'ings',
    'Ġp',
    'sy',
    'Ġg',
    'aps',
    'ĠIce',
    'land',
    'ĠP',
    'interest',
    'Ġland',
    'lord',
    'Ġdefin',
    'itions',
    'ĠK',
    'er',
    'Ġsufficient',
    'ly',
    'ĠP',
    'ence',
    'ĠArch',
    'itect',
    'Ġsur',
    'pass',
    'Ġ11',
    '4',
    'Ġsuper',
    'hero',
    'ĠDise',
    'ase',
    'Ġpri',
    'ests',
    'ĠC',
    'ulture',
    'Ġdefin',
    'itive',
    'Ġsecret',
    'ly',
    'ĠD',
    'ance',
    'inst',
    'all',
    'ch',
    'ief',
    'ĠJess',
    'ica',
    'W',
    'ould',
    'Up',
    'dated',
    'Ġlock',
    'er',
    'ĠK',
    'ay',
    'Ġmem',
    'orial',
    'è',
    '¦',
    'f',
    'at',
    'Ġdis',
    'gu',
    'Ġflav',
    'ors',
    'ĠBase',
    'ball',
    'ĠRes',
    'istance',
    'Ġk',
    'icks',
    'Ġen',
    'v',
    'Ġteen',
    'agers',
    'D',
    'ark',
    'ĠC',
    'AR',
    'Ġh',
    'alt',
    'ĠL',
    'G',
    'ĠGab',
    'riel',
    'Ġfe',
    'ver',
    'Ġs',
    'atur',
    'Ġm',
    'all',
    'Ġaffili',
    'ate',
    'ĠS',
    'leep',
    'ĠSpe',
    'cific',
    'ĠV',
    'el',
    'Ġj',
    'ar',
    'ĠSac',
    'red',
    'ĠEd',
    'wards',
    'ĠA',
    'CL',
    'Ġret',
    'ained',
    'ĠG',
    'iant',
    'Ġlim',
    'itation',
    'in',
    'ces',
    'Ġref',
    'usal',
    'ĠT',
    'ale',
    'ĠBut',
    'ler',
    'Ġacc',
    'idents',
    'ĠC',
    'SS',
    'Ġimport',
    'ed',
    'ĠCop',
    'y',
    'Î',
    '±',
    'ER',
    'T',
    'z',
    'el',
    'Ġdiv',
    'isions',
    'h',
    'ots',
    'ĠAl',
    'b',
    'ĠD',
    'S',
    'Load',
    'er',
    'W',
    'ashington',
    'at',
    'isf',
    'ĠCreat',
    'ive',
    '\\',
    '.',
    'ĠAut',
    'om',
    'red',
    'ict',
    'Ġrecept',
    'or',
    'ĠCarl',
    'os',
    'Met',
    'hod',
    'ok',
    'a',
    'Ġmal',
    'icious',
    'Ġste',
    'pping',
    ',',
    '[',
    'ĠD',
    'ad',
    'Ġatt',
    'raction',
    'ĠEffect',
    's',
    'ĠPir',
    'ate',
    'ĠC',
    'er',
    'ĠIndust',
    'ry',
    'ĠR',
    'ud',
    'Ġchar',
    'ter',
    'Ġd',
    'ining',
    'Ġins',
    'ists',
    'Ġconfig',
    'ure',
    'Ġ(',
    '#',
    'ĠSim',
    'ple',
    'ĠSc',
    'roll',
    'UT',
    'C',
    '17',
    '5',
    'ĠK',
    'on',
    'Ġmarket',
    'place',
    'Ġ',
    'ãĤ',
    'Ġref',
    'res',
    'Ġg',
    'ates',
    'er',
    'red',
    'ĠP',
    'od',
    'Ġbeh',
    'ave',
    'Fr',
    'ank',
    'n',
    'ode',
    'Ġendors',
    'ed',
    'he',
    'tt',
    'as',
    'ive',
    'ĠHom',
    'eland',
    'Ġr',
    'ides',
    'ĠLe',
    'ave',
    'er',
    'ness',
    'Ġflood',
    'ing',
    'A',
    'FP',
    'Ġris',
    'en',
    'Ġcontin',
    'ually',
    'Ġun',
    'anim',
    'ĠCont',
    'ract',
    'ĠP',
    'as',
    'Ġgu',
    'ided',
    'ĠCh',
    'ile',
    'b',
    'd',
    'Ġsu',
    'cc',
    'pt',
    'ic',
    'Ġcomm',
    'ittees',
    'ĠL',
    'uther',
    'ĠAny',
    'one',
    'Ġs',
    'ab',
    '12',
    '4',
    'Ġp',
    'ixel',
    'ĠB',
    'ak',
    'ĠT',
    'ag',
    'ĠBenn',
    'ett',
    'En',
    'ter',
    'sm',
    'all',
    'ĠPresident',
    'ial',
    'Ġp',
    'ul',
    'Ġcontr',
    'ace',
    'arch',
    'ive',
    'Ġcoast',
    'al',
    'ĠK',
    'ids',
    '19',
    '2',
    'âĢ',
    '²',
    'ick',
    'y',
    'ING',
    'TON',
    'Ġw',
    'olf',
    'ĠSt',
    'alin',
    'T',
    'ur',
    'id',
    'get',
    'am',
    'as',
    'ĠUn',
    'less',
    'Ġspons',
    'or',
    'Ġmor',
    'ph',
    'ĠCho',
    'ose',
    'Ġrun',
    'ner',
    'Ġun',
    'bel',
    'Ġm',
    'ud',
    'ĠMan',
    'a',
    'Ġdub',
    'bed',
    'Ġg',
    'odd',
    'ure',
    'rs',
    'wind',
    'ow',
    'Ġrel',
    'ied',
    'Ġcelebr',
    'ating',
    'os',
    'c',
    'Ġ13',
    '5',
    'Ġlobb',
    'ying',
    'Ġincom',
    'plete',
    'Ġrestrict',
    'ion',
    'Ġinc',
    'ap',
    'it',
    'us',
    'Ġexpect',
    'ation',
    'ĠAp',
    'ollo',
    'Ġint',
    'ens',
    'Ġsyn',
    'c',
    'G',
    'H',
    'Ġmanip',
    'ulation',
    'B',
    'Y',
    'Ġspe',
    'ar',
    'Ġbre',
    'asts',
    'Ġvol',
    'can',
    'il',
    'ia',
    'M',
    'aterial',
    'Ġform',
    'ats',
    'ĠB',
    'ast',
    'Ġparliament',
    'ary',
    'Ġsn',
    'ake',
    'Ġserv',
    'ants',
    'ĠTr',
    'udeau',
    'ĠGr',
    'im',
    'ĠArab',
    'ic',
    'ĠSC',
    'P',
    'ĠBoy',
    's',
    'st',
    'ation',
    'Ġprospect',
    'ive',
    'ord',
    'e',
    'in',
    'itialized',
    'Ġb',
    'ored',
    'AB',
    'LE',
    'Ġaccess',
    'ed',
    'Ġtax',
    'i',
    'ĠShe',
    'll',
    'aid',
    'en',
    'urs',
    'ed',
    'in',
    'ates',
    'ĠIns',
    'urance',
    'ĠPet',
    'e',
    'Sept',
    'ember',
    '6',
    '50',
    'Ġad',
    'ventures',
    'ĠCo',
    'ver',
    'Ġt',
    'ribute',
    'Ġsk',
    'etch',
    'Ġem',
    'power',
    'Ġ',
    'Ø',
    'ĠGl',
    'enn',
    'ĠD',
    'aw',
    '=',
    '\\"',
    'ĠPolit',
    'ics',
    'Ġgu',
    'ides',
    'Ġd',
    'ioxide',
    'ĠG',
    'ore',
    'ĠBr',
    'ight',
    'ĠS',
    'ierra',
    'Ġval',
    'ued',
    'c',
    'ond',
    'Ġpo',
    'inter',
    'Se',
    'lect',
    'Ġrisk',
    'y',
    'Ġabsor',
    'b',
    'im',
    'ages',
    'Ġref',
    'uses',
    'Ġbon',
    'uses',
    '__',
    '_',
    'Ġh',
    'ilar',
    'ĠF',
    'eatures',
    '2',
    '20',
    'ĠCollect',
    'or',
    'F',
    'oot',
    'Ġ19',
    '64',
    'cul',
    'us',
    'Ġd',
    'awn',
    'Ġwork',
    'out',
    'ĠL',
    'O',
    'Ġphilosoph',
    'ical',
    'ĠSand',
    'y',
    'ĠYou',
    'th',
    'Ġl',
    'iable',
    'A',
    'f',
    'bl',
    'ue',
    'Ġovert',
    'urn',
    'less',
    'ness',
    'ĠTrib',
    'une',
    'ĠIn',
    'g',
    'Ġfact',
    'ories',
    'Ġcat',
    'ches',
    'Ġpr',
    'one',
    'Ġmat',
    'rix',
    'Ġlog',
    'in',
    'Ġin',
    'acc',
    'Ġex',
    'ert',
    's',
    'ys',
    'Ġneed',
    'le',
    'ĠQ',
    'ur',
    'Ġnot',
    'ified',
    'ould',
    'er',
    't',
    'x',
    'Ġremind',
    's',
    'Ġpublisher',
    's',
    'Ġn',
    'ort',
    'Ġg',
    'it',
    'Ġfl',
    'ies',
    'ĠEm',
    'ily',
    'Ġflow',
    'ing',
    'ĠAl',
    'ien',
    'ĠStr',
    'ateg',
    'Ġhard',
    'est',
    'Ġmod',
    'ification',
    'AP',
    'I',
    'ĠM',
    'Y',
    'Ġcr',
    'ashes',
    'st',
    'airs',
    'n',
    'umber',
    'Ġur',
    'ging',
    'ch',
    'annel',
    'ĠFal',
    'con',
    'Ġinhabit',
    'ants',
    'Ġterr',
    'ifying',
    'Ġutil',
    'ize',
    'Ġban',
    'ner',
    'Ġcig',
    'arettes',
    'Ġsens',
    'es',
    'ĠHol',
    'mes',
    'Ġpract',
    'ition',
    'ĠPhill',
    'ips',
    'ott',
    'o',
    'Ġcomp',
    'ile',
    'Mod',
    'el',
    'ĠK',
    'o',
    'Ġ[',
    ']',
    'Americ',
    'ans',
    'ĠTer',
    'ms',
    'Ġmed',
    'ications',
    'ĠAn',
    'a',
    'Ġfundament',
    'ally',
    'ĠNot',
    'ice',
    'Ġwe',
    'aker',
    'Ġ',
    '0000',
    'Ġgar',
    'lic',
    'Ġout',
    'break',
    'Ġeconom',
    'ist',
    'ĠB',
    'irth',
    'Ġobst',
    'acles',
    'ar',
    'cer',
    'ĠOr',
    'thodox',
    'Ġplace',
    'bo',
    'ĠC',
    'rew',
    'asp',
    'berry',
    'ĠAng',
    'els',
    'Ġdis',
    'charge',
    'Ġdestruct',
    'ive',
    '11',
    '7',
    'ĠR',
    'ising',
    'Ġd',
    'airy',
    'l',
    'ate',
    'Ġcoll',
    'ision',
    'ĠTig',
    'ers',
    'ean',
    'or',
    'ocument',
    'ed',
    'ĠIn',
    'valid',
    'Ġd',
    'ont',
    'ĠL',
    'iter',
    'ĠV',
    'a',
    'Ġhyd',
    'rogen',
    'Ġvari',
    'ants',
    'ĠBrown',
    's',
    'Ġ19',
    '65',
    'Ġind',
    'igenous',
    'Ġtrad',
    'es',
    'Ġremain',
    'der',
    'Ġswe',
    'pt',
    'ĠImp',
    'act',
    'Ġred',
    'ist',
    'Ġun',
    'int',
    'grad',
    'uate',
    'ãĥ',
    'ķ',
    'ĠW',
    'ILL',
    'ãģ®',
    'ç',
    'ĠCrit',
    'ical',
    'Ġf',
    'isher',
    'Ġv',
    'icious',
    'Ġrevers',
    'ed',
    'Y',
    'ear',
    'ĠS',
    'ox',
    'Ġshoot',
    'ings',
    'Ġfil',
    'ming',
    'Ġtouchdown',
    's',
    'ai',
    'res',
    'm',
    'el',
    'Ġgrand',
    'father',
    'Ġaffect',
    'ion',
    'ing',
    'le',
    'Ġover',
    'ly',
    'Add',
    'itional',
    'Ġsup',
    'reme',
    'ĠGr',
    'ad',
    'Ġsport',
    'ing',
    'Ġmer',
    'cy',
    'ĠBrook',
    's',
    'ount',
    'y',
    'Ġperform',
    's',
    'Ġtight',
    'ly',
    'Ġdem',
    'ons',
    'Ġkill',
    'ings',
    'Ġfact',
    'ion',
    'ĠNov',
    'a',
    'aut',
    's',
    'Ġund',
    'oubtedly',
    'ar',
    'in',
    'Ġunder',
    'way',
    'ra',
    'k',
    'Ġl',
    'iv',
    'ĠReg',
    'ion',
    'Ġbrief',
    'ing',
    's',
    'ers',
    'cl',
    'oud',
    'ĠM',
    'ik',
    'us',
    'p',
    'Ġpred',
    'iction',
    'az',
    'or',
    'Ġport',
    'able',
    'ĠG',
    'and',
    'Ġpresent',
    'ing',
    'Ġ10',
    '80',
    'Â',
    '»',
    'ush',
    'i',
    'ĠSp',
    'ark',
    'there',
    'um',
    'Ġjust',
    'ification',
    'ĠN',
    'y',
    'Ġcontract',
    'ors',
    'ming',
    'ham',
    'ĠSt',
    'yle',
    'å',
    'ħ',
    'ĠChron',
    'icles',
    'ĠPict',
    'ure',
    'Ġprov',
    'ing',
    'Ġw',
    'ives',
    'set',
    't',
    'Ġmole',
    'cules',
    'ĠFair',
    'y',
    'Ġconsist',
    'ing',
    'Ġp',
    'ier',
    'al',
    'one',
    'in',
    'ition',
    'Ġn',
    'ucle',
    'j',
    'son',
    'Ġg',
    'otta',
    'Ġmob',
    'il',
    'Ġver',
    'bal',
    'ar',
    'ium',
    'Ġmon',
    'ument',
    'uck',
    'ed',
    'Ġ25',
    '6',
    'T',
    'ech',
    'mine',
    'craft',
    'ĠTr',
    'ack',
    'Ġt',
    'ile',
    'Ġcompat',
    'ibility',
    'as',
    'is',
    'Ġs',
    'add',
    'Ġinstruct',
    'ed',
    'ĠM',
    'ueller',
    'Ġle',
    'thal',
    'Ġhorm',
    'one',
    'Ġor',
    'che',
    'el',
    'se',
    'Ġske',
    'let',
    'Ġentert',
    'aining',
    'Ġminim',
    'ize',
    'ag',
    'ain',
    'Ġunder',
    'go',
    'Ġconst',
    'raints',
    'Ġcig',
    'arette',
    'ĠIslam',
    'ist',
    'Ġtravel',
    's',
    'ĠPant',
    'hers',
    'l',
    'ings',
    'C',
    'are',
    'Ġlaw',
    'suits',
    'ur',
    'as',
    'Ġcry',
    'st',
    'Ġlow',
    'ered',
    'Ġaer',
    'ial',
    'Ġcomb',
    'inations',
    'Ġha',
    'un',
    'Ġch',
    'a',
    'Ġv',
    'ine',
    'Ġquant',
    'ities',
    'Ġlink',
    'ing',
    'b',
    'ank',
    'Ġso',
    'y',
    'B',
    'ill',
    'ĠAngel',
    'a',
    'Ġrecip',
    'ient',
    'ĠProt',
    'est',
    'Ġs',
    'ocket',
    'Ġsolid',
    'arity',
    'Ġâ',
    'Ĩ',
    'm',
    'ill',
    'Ġvar',
    'ies',
    'ĠPak',
    'istani',
    'Dr',
    'agon',
    'Ġun',
    'e',
    'Ġhor',
    'izon',
    'ÂłÂłÂłÂł',
    'ÂłÂłÂłÂł',
    'Ġprov',
    'inces',
    'Ġfrank',
    'ly',
    'Ġenact',
    'ed',
    'not',
    'es',
    '[',
    "'",
    'Ġ19',
    '2',
    'ocr',
    'acy',
    'Ġendorse',
    'ment',
    'Ġover',
    'time',
    'Tr',
    'ue',
    'L',
    'ab',
    'lic',
    'ted',
    'ĠD',
    'NC',
    'Ġbe',
    'ats',
    'ĠJam',
    'ie',
    '15',
    '2',
    'ĠIN',
    'T',
    'Cont',
    'act',
    'Ġaccount',
    'ed',
    'h',
    'ash',
    'ĠPack',
    'ers',
    'p',
    'ires',
    'Ġles',
    'bian',
    'Ġamend',
    'ments',
    'Ġhop',
    'eful',
    'ĠFin',
    'land',
    'Ġspot',
    'light',
    'Ġconfig',
    'ured',
    'Ġtrou',
    'bled',
    'Ġg',
    'aze',
    'ĠCal',
    'gary',
    'Ġrel',
    'iability',
    'Ġins',
    'urg',
    'sw',
    'er',
    'b',
    'uy',
    'ĠSk',
    'in',
    'Ġp',
    'ixels',
    'Ġhand',
    'gun',
    'Ġpar',
    'as',
    'Ġcateg',
    'or',
    'ĠE',
    'L',
    'ĠRe',
    'x',
    'Ind',
    'eed',
    'Ġkind',
    'a',
    'Ġconj',
    'unction',
    'ĠBry',
    'an',
    'ĠMan',
    'ufact',
    'y',
    'ang',
    'Pl',
    'us',
    'S',
    'QL',
    'ish',
    'ment',
    'Ġdom',
    'inate',
    'Ġn',
    'ail',
    'Ġo',
    'ath',
    'Ġeru',
    'pt',
    'ĠF',
    'ine',
    'it',
    'bart',
    'ĠCh',
    'ip',
    'ĠAb',
    'd',
    'ĠN',
    'am',
    'Ġbuy',
    'er',
    'Ġdiss',
    'ent',
    'Le',
    'aks',
    'Cont',
    'in',
    'Ġr',
    'ider',
    'ĠSome',
    'one',
    'Ġill',
    'usion',
    'c',
    'in',
    'ĠBoe',
    'ing',
    'Ġin',
    'adequ',
    'ov',
    'ation',
    'i',
    'ants',
    'Ġreb',
    'uild',
    '4',
    '50',
    'ĠDest',
    'iny',
    'S',
    'W',
    'ĠT',
    'ill',
    'H',
    'it',
    'ia',
    'z',
    'ĠBang',
    'l',
    'acher',
    's',
    'ĠRe',
    'form',
    'Ġse',
    'gments',
    'Ġsystem',
    'atic',
    'd',
    'c',
    'ĠConserv',
    'atives',
    'Ġport',
    'al',
    'h',
    'or',
    'ĠDragon',
    'bound',
    'Ġdrag',
    'ged',
    'om',
    'o',
    'Ġthe',
    'e',
    'ad',
    'vert',
    'ĠRep',
    'orts',
    'ĠE',
    't',
    'Ġbarrel',
    's',
    'Aug',
    'ust',
    'Ġcompar',
    'isons',
    'Ġhe',
    'x',
    'Ġan',
    'throp',
    '"',
    '[',
    'bor',
    'ough',
    'ab',
    'i',
    'Ġpict',
    'ured',
    'play',
    'ing',
    'ĠAdd',
    'ress',
    'ĠMir',
    'ror',
    'Sm',
    'ith',
    'Ġt',
    'ires',
    'ĠN',
    'PR',
    'AA',
    'AA',
    'Ġclass',
    'ification',
    'ĠTh',
    'an',
    'ĠH',
    'arm',
    'ĠR',
    'A',
    'Ġreject',
    'ion',
    'min',
    'ation',
    'Ġr',
    'anged',
    'ĠF',
    'alls',
    'D',
    'I',
    'H',
    'ost',
    'ãĤ',
    '´',
    'ĠEx',
    'ample',
    'list',
    'ed',
    'th',
    'irds',
    'Ġsaf',
    'egu',
    'br',
    'and',
    'Ġprob',
    'able',
    'Can',
    'ada',
    'IT',
    'ION',
    'ĠQ',
    'aeda',
    'Ġch',
    'ick',
    'Ġimport',
    's',
    'h',
    'it',
    'l',
    'oc',
    'W',
    'W',
    'Ġble',
    'w',
    'Ġany',
    'time',
    'Ġwh',
    'oles',
    'ik',
    'ed',
    'Ġcal',
    'culation',
    'cre',
    'ate',
    'ĠO',
    'ri',
    'Ġupgr',
    'aded',
    'Ġapp',
    'ar',
    'ut',
    'ory',
    'ĠM',
    'ol',
    'B',
    'rit',
    'ĠJ',
    'ong',
    'IN',
    'AL',
    'ĠStart',
    'ing',
    'Ġd',
    'ice',
    'urt',
    'le',
    'Ġre',
    'lying',
    'cl',
    'osure',
    'Ġprof',
    'itable',
    'Ġsl',
    'aughter',
    'ĠMan',
    'ual',
    'c',
    'aster',
    'Ġ"',
    '$',
    'Ġfe',
    'ather',
    'ĠSim',
    'ply',
    'ie',
    'ves',
    'Ġdeter',
    'ior',
    'ĠPC',
    'I',
    'Ġst',
    'amp',
    'Ġfl',
    'aws',
    'Ġsh',
    'ade',
    'ham',
    'mer',
    'Ġpass',
    'port',
    'Ġcont',
    'ing',
    'am',
    'el',
    'Ġobser',
    'vers',
    'Ġneg',
    'lect',
    'ĠR',
    'B',
    'ĠBrother',
    'hood',
    'Ġskept',
    'ical',
    'f',
    'amily',
    'us',
    'k',
    'Ġemotion',
    'ally',
    'â',
    'Ļ',
    'ĠBet',
    'a',
    'ason',
    'able',
    'id',
    'ity',
    'ĠM',
    'ul',
    'Ġkick',
    'ing',
    'ĠC',
    'arm',
    'oll',
    'ah',
    'VERT',
    'IS',
    'ĠAt',
    'hen',
    'Ġlad',
    'der',
    'ĠBul',
    'let',
    'å',
    '£',
    '00',
    '01',
    'ĠWild',
    'life',
    'ĠM',
    'ask',
    'ĠN',
    'an',
    'R',
    'ev',
    'Ġun',
    'acceptable',
    'leg',
    'al',
    'Ġcrowd',
    'ed',
    'ag',
    'i',
    'ĠC',
    'ox',
    'j',
    'e',
    'Ġmor',
    'ality',
    'Ġfu',
    'els',
    'Ġc',
    'ables',
    'Ġman',
    'kind',
    'ĠCarib',
    'bean',
    'Ġanch',
    'or',
    'Ġby',
    'te',
    'ĠO',
    'ften',
    'ĠO',
    'z',
    'Ġcraft',
    'ed',
    'Ġhistor',
    'ian',
    'ĠW',
    'u',
    'Ġtow',
    'ers',
    'ĠCitiz',
    'ens',
    'Ġhel',
    'm',
    'Ġcred',
    'entials',
    'Ġsing',
    'ular',
    'ĠJes',
    'se',
    'Ġtack',
    'les',
    'Ġcont',
    'empt',
    'Ġa',
    'fore',
    'ĠSh',
    'adows',
    'Ġn',
    'il',
    'Ġur',
    'gent',
    'app',
    'le',
    'bl',
    'ood',
    'Ġv',
    'on',
    'Ġoff',
    'line',
    'Ġbreat',
    'he',
    'Ġj',
    'umps',
    'Ġirre',
    'levant',
    'ox',
    'ic',
    'om',
    'al',
    'import',
    'ant',
    'J',
    'im',
    'Ġgl',
    'oves',
    'arm',
    'ing',
    'dep',
    'th',
    'Ġtal',
    'ents',
    'ook',
    'ie',
    'ĠS',
    'B',
    'Ġpal',
    'm',
    'uff',
    's',
    'est',
    'a',
    'IG',
    'H',
    'Ġcan',
    'on',
    'ĠVer',
    'izon',
    'ĠP',
    'le',
    'Ġcou',
    'pled',
    'vel',
    't',
    'Ġfundra',
    'ising',
    'ĠGet',
    'ting',
    'ĠD',
    'LC',
    'Ġmathemat',
    'ical',
    'ĠH',
    'S',
    'ĠCard',
    'inals',
    'te',
    'lling',
    'Ġspons',
    'ors',
    'Ġ',
    'Ï',
    'ĠBull',
    's',
    'op',
    'tion',
    'Ġprop',
    'ose',
    'Ġmem',
    'orable',
    'Ġembr',
    'aced',
    'Ġdecl',
    'ining',
    'He',
    'alth',
    'ed',
    'a',
    'Ġ}',
    ';',
    'Ġsp',
    'am',
    'm',
    'ile',
    'Ġpit',
    'cher',
    'ĠE',
    'ight',
    'Ġcar',
    'ing',
    'ut',
    'ic',
    'ro',
    'le',
    'Ġair',
    'line',
    'ernand',
    'ez',
    'ĠAth',
    'let',
    'Ġcert',
    'ification',
    'ux',
    'e',
    'rig',
    'er',
    'Ġem',
    'pir',
    'Ġsens',
    'ation',
    'Ġdis',
    'm',
    'Ġb',
    'olt',
    'Ġev',
    'olve',
    'H',
    'ouse',
    'Ġconsult',
    'ation',
    'ĠD',
    'uty',
    'Ġtou',
    'ches',
    'ĠN',
    'athan',
    'Ġf',
    'aint',
    'h',
    'ad',
    '"',
    '(',
    'ĠCons',
    'umer',
    'ĠExt',
    'reme',
    'Ġ12',
    '7',
    'ĠHer',
    'm',
    'ĠSac',
    'rament',
    'iz',
    'oph',
    'Ġanx',
    'ious',
    'ul',
    'ously',
    'Ġsoc',
    'ially',
    'ĠU',
    'TC',
    'Ġsol',
    'ving',
    'ĠLet',
    'ter',
    'Hist',
    'ory',
    'ed',
    'uc',
    'Pr',
    'ice',
    ')',
    ');',
    'Ġrel',
    'oad',
    'am',
    'ic',
    'Ġp',
    'ork',
    'Ġdisc',
    'ourse',
    'Ġt',
    'ournaments',
    'ai',
    'ro',
    'ĠK',
    'ur',
    'ĠCost',
    'a',
    'Ġviol',
    'ating',
    'Ġinterf',
    'ere',
    'Ġrecre',
    'ational',
    'uff',
    'le',
    'Ġspe',
    'eches',
    'Ġneed',
    'ing',
    'Ġremem',
    'bers',
    'Ġcred',
    'ited',
    'n',
    'ia',
    'f',
    'ocused',
    'amer',
    'a',
    'Ġb',
    'ru',
    'um',
    'bs',
    'ĠCub',
    'an',
    'Ġpreced',
    'ing',
    'Ġnons',
    'ense',
    'ac',
    'ial',
    'Ġsmart',
    'phones',
    'ĠSt',
    'ories',
    'S',
    'ports',
    'ĠEmer',
    'gency',
    'oun',
    'cing',
    'ef',
    'ined',
    'Ġb',
    'er',
    'Ġconsult',
    'ing',
    'Ġm',
    'asters',
    'he',
    'astern',
    '."',
    '[',
    'ĠRun',
    'ning',
    'Ġsus',
    'cept',
    'ĠF',
    'eng',
    'Americ',
    'a',
    'pr',
    'ises',
    'st',
    'itial',
    'ĠWeek',
    'ly',
    'ĠGreat',
    'er',
    'mod',
    'ules',
    'if',
    'ter',
    'G',
    'raphics',
    'ul',
    'er',
    'Ġwho',
    'lly',
    'Ġsupp',
    'ress',
    'Ġconce',
    'aled',
    'Ġhapp',
    'ily',
    'Ġaccept',
    's',
    'ĠEn',
    'joy',
    'Ġr',
    'ivers',
    'ĠEx',
    'cept',
    '2',
    '25',
    'ĠN',
    'HS',
    'ĠMc',
    'Connell',
    'Ġp',
    'ussy',
    'fer',
    'red',
    'ut',
    'able',
    'Ġatt',
    'ain',
    'Ġ>',
    '=',
    'Ġdepos',
    'its',
    'roph',
    'ic',
    'Ġnot',
    'orious',
    'ĠSh',
    'aw',
    'il',
    'itation',
    'Ġepid',
    'emic',
    'all',
    'ic',
    'Ġsmall',
    'est',
    'ov',
    'ich',
    'Ġaccess',
    'ories',
    'per',
    'ties',
    'Ġsur',
    'plus',
    'ĠMe',
    'ch',
    'Ġamb',
    'ig',
    'ĠImm',
    'igration',
    'Ġch',
    'im',
    'ev',
    'al',
    'Ġpract',
    'icing',
    'ĠMyster',
    'y',
    'Ġdom',
    'ains',
    'ĠSil',
    'icon',
    'app',
    's',
    'Ġkilomet',
    'ers',
    'e',
    'a',
    'ĠSm',
    'ash',
    'Ġwarrant',
    'y',
    'Ġn',
    'ost',
    's',
    'il',
    're',
    'v',
    'J',
    'on',
    'ĠDub',
    'lin',
    'Ġtast',
    'es',
    'Ġb',
    'out',
    'g',
    'reat',
    'er',
    'ror',
    'Ġsw',
    'itches',
    'ĠB',
    'apt',
    'D',
    'O',
    'ok',
    'i',
    'Ġsour',
    'ced',
    'pro',
    'du',
    'Ġattach',
    'ment',
    'ĠIss',
    'ue',
    'ĠQuest',
    'ion',
    'Jo',
    'in',
    'Ġf',
    'itted',
    'Ġunlaw',
    'ful',
    '^',
    '^',
    'ere',
    'k',
    'Ġauthent',
    'ication',
    'Ġst',
    'ole',
    'Ġaccount',
    'ability',
    'l',
    'abel',
    'S',
    'earch',
    'Ġal',
    'beit',
    'atic',
    'an',
    'fund',
    'ed',
    'ĠAdd',
    'ing',
    'ĠI',
    'Q',
    'Ġsub',
    'mar',
    'l',
    'it',
    'a',
    'que',
    'ĠLear',
    'ning',
    'Ġint',
    'eger',
    'M',
    'aster',
    'ĠCh',
    'rom',
    'Ġprem',
    'ier',
    'O',
    'p',
    'ĠLi',
    'u',
    'Ġbl',
    'essed',
    'ĠGl',
    'obe',
    'ĠResp',
    'onse',
    'Ġlegit',
    'im',
    'ĠMer',
    'kel',
    'Ġdispos',
    'al',
    'Â',
    '´',
    'Ġgau',
    'ge',
    'pe',
    'at',
    'Ġindu',
    'ced',
    'Ġquestion',
    'able',
    'arth',
    'y',
    'ĠV',
    'it',
    'ĠF',
    'eed',
    'U',
    'ntil',
    'U',
    't',
    'worth',
    'y',
    'R',
    'Y',
    'ĠH',
    'erald',
    'ĠHam',
    'mer',
    'Ġmed',
    'al',
    'ĠR',
    'ivers',
    'ĠH',
    'ack',
    'Ġclar',
    'ify',
    'Ġtrack',
    'ed',
    'Ġautonom',
    'ous',
    'Ġten',
    'ant',
    'ĠQ',
    'atar',
    'er',
    'ie',
    'Ġgr',
    'im',
    'ĠMon',
    'itor',
    'Ġresist',
    'ant',
    'ĠSpe',
    'c',
    'ĠWell',
    's',
    'N',
    'AS',
    '14',
    '8',
    'Ġmin',
    'ers',
    'iot',
    'ics',
    'Ġmiss',
    'es',
    '11',
    '6',
    'g',
    'ian',
    'g',
    'it',
    'ĠE',
    'yes',
    'p',
    'res',
    'Ġgrad',
    'uated',
    'Ġang',
    'el',
    'Ġsyn',
    'chron',
    'Ġefficient',
    'ly',
    'Ġtrans',
    'mitted',
    'H',
    'arry',
    'Ġglob',
    'ally',
    'EN',
    'CE',
    'ĠMont',
    'ana',
    'r',
    'aged',
    'ĠPre',
    'vention',
    'Ġp',
    'iss',
    'ĠL',
    'l',
    'Ġshe',
    'lf',
    'ĠB',
    'JP',
    'ĠTest',
    'ament',
    'ĠL',
    'ate',
    'ik',
    'er',
    'ĠH',
    'app',
    'ĠJul',
    'ian',
    'h',
    'all',
    'Ġsp',
    'ont',
    'Ġshut',
    'down',
    'Ġincons',
    'istent',
    'Ġsubscrib',
    'ers',
    'Ġske',
    'leton',
    'ĠNe',
    'braska',
    'Ġins',
    'pire',
    'ĠV',
    'oid',
    'F',
    'eed',
    'Ġang',
    'les',
    'ĠSpr',
    'ings',
    'Ġbench',
    'mark',
    'Ġvacc',
    'ines',
    'izoph',
    'ren',
    'se',
    'xual',
    'uff',
    'ed',
    'Ġsh',
    'ine',
    'ĠK',
    'ath',
    'Ġgest',
    'ure',
    'ine',
    'a',
    'Ġr',
    'ip',
    'Ġopp',
    'ression',
    'Ġcons',
    'cience',
    'b',
    't',
    'ĠL',
    'um',
    'Ġinc',
    'idence',
    'ĠF',
    'a',
    'w',
    'r',
    'Ġmin',
    'eral',
    'ĠSp',
    'urs',
    'alk',
    'y',
    'Ġth',
    'under',
    'Ġop',
    'io',
    'Be',
    'ing',
    'ĠPal',
    'm',
    'Ġwas',
    'ted',
    'Ġl',
    'b',
    'i',
    'aries',
    'ĠIniti',
    'ative',
    'Ġcur',
    'ric',
    'Ġmark',
    'er',
    'ĠMc',
    'L',
    'Ġext',
    'ensions',
    'ĠP',
    'v',
    'ĠAr',
    'ms',
    'Ġoffer',
    'ings',
    'Ġdef',
    'enses',
    'Ġvend',
    'or',
    'Ġcontrad',
    'ict',
    'ĠCol',
    'in',
    'Ġredd',
    'it',
    'Ġper',
    'ipher',
    '12',
    '2',
    'Ġs',
    'ins',
    'E',
    'dit',
    'IC',
    'T',
    'So',
    'ft',
    'ĠSh',
    'ah',
    'Ġadministr',
    'ator',
    'ĠT',
    'rip',
    'Ġporn',
    'ography',
    'Ġtu',
    'ition',
    'in',
    'ence',
    'ĠPro',
    'gress',
    'Ġcat',
    'alog',
    'Ġsu',
    'ite',
    'Ġh',
    'ike',
    'Ġreprodu',
    'ctive',
    'eng',
    'ine',
    'Ġd',
    'rought',
    'ĠNo',
    'ah',
    'Ġ2',
    '30',
    'Ġd',
    'ude',
    'Ġrelax',
    'ed',
    'Ġpart',
    'ition',
    'Ġparticip',
    'ant',
    'Ġtel',
    'esc',
    'Ġfe',
    'as',
    'ĠF',
    'F',
    'own',
    'er',
    'Ġswe',
    'eping',
    'Ġl',
    'enses',
    'Ġmatch',
    'up',
    'ĠRe',
    'pl',
    'ourn',
    'als',
    'Ġcred',
    'ible',
    'Ġgrand',
    'mother',
    'Ġther',
    'mal',
    'Ġsubscrib',
    'ing',
    'Ġident',
    'ities',
    'col',
    'm',
    'U',
    'CT',
    'Ġreluct',
    'ant',
    'us',
    'ers',
    'ĠC',
    'ort',
    'Ġassist',
    'ed',
    'OS',
    'S',
    'ATION',
    'S',
    'IS',
    'H',
    'Ġpharm',
    'aceutical',
    'ic',
    'able',
    'ad',
    'ian',
    'ĠSon',
    'ic',
    'ĠF',
    'ury',
    'ĠM',
    'ong',
    'A',
    'H',
    'ĠPsych',
    'ology',
    'Ġph',
    'osph',
    'Ġtreat',
    's',
    'Ń',
    'Ķ',
    'Ġstead',
    'ily',
    'ĠHell',
    'o',
    'Ġrel',
    'ates',
    'Ġcl',
    'ue',
    'Ex',
    'pl',
    'a',
    'uth',
    'Ġrev',
    'ision',
    'Ġe',
    'ld',
    'os',
    'ion',
    'Ġbr',
    'on',
    '14',
    '4',
    'ri',
    'kes',
    'Ġmin',
    'es',
    'Ġblank',
    'et',
    'ĠF',
    'ail',
    'el',
    'ed',
    'ĠIm',
    'agine',
    'ĠPl',
    'anned',
    'a',
    'ic',
    'Re',
    'quest',
    'M',
    'ad',
    'ĠHor',
    'se',
    'ĠEag',
    'le',
    'Ġcap',
    'ac',
    '15',
    '7',
    'Ġl',
    'ing',
    'ĠN',
    'ice',
    'ĠP',
    'arenthood',
    'min',
    'ster',
    'og',
    's',
    'ens',
    'itive',
    'Not',
    'hing',
    'Ġcar',
    'n',
    'F',
    'in',
    'ĠP',
    'E',
    'Ġr',
    'ifles',
    'ĠL',
    'P',
    'S',
    'and',
    'Ġgui',
    'Active',
    'Ġtour',
    'ist',
    'C',
    'NN',
    'Ġunve',
    'iled',
    'Ġpredec',
    'essor',
    '}',
    '{',
    'u',
    'ber',
    'Ġoff',
    'shore',
    'Ġopt',
    'ical',
    'ĠR',
    'ot',
    'ĠPear',
    'l',
    'et',
    'on',
    'Ġst',
    'ared',
    'Ġfart',
    'her',
    'at',
    'ility',
    'cont',
    'in',
    'ĠG',
    'y',
    'ĠF',
    'oster',
    'ĠC',
    'oc',
    'ri',
    'ents',
    'Ġdesign',
    'ing',
    'ĠEconom',
    'y',
    'ON',
    'G',
    'W',
    'omen',
    'ĠN',
    'ancy',
    'er',
    'ver',
    'Ġmas',
    'cul',
    'Ġcasual',
    'ties',
    'Ġ2',
    '25',
    'ĠS',
    'ullivan',
    'ĠCh',
    'oice',
    'Ġa',
    'ster',
    'w',
    's',
    'Ġhot',
    'els',
    'Ġconsider',
    'ations',
    'Ġcou',
    'ch',
    'ĠSt',
    'rip',
    'ĠG',
    'n',
    'Ġmanip',
    'ulate',
    'l',
    'ied',
    'Ġsynt',
    'hetic',
    'Ġassault',
    'ed',
    'Ġoff',
    'enses',
    'ĠDra',
    'ke',
    'Ġim',
    'pe',
    'Oct',
    'ober',
    'ĠHer',
    'itage',
    'h',
    'l',
    'ĠBl',
    'air',
    'Un',
    'like',
    'Ġg',
    'rief',
    'Ġ4',
    '50',
    'Ġopt',
    'ed',
    'Ġresign',
    'ation',
    'il',
    'o',
    'Ġver',
    'se',
    'ĠT',
    'omb',
    'Ġu',
    'pt',
    'Ġa',
    'ired',
    'ĠH',
    'ook',
    'ĠML',
    'B',
    'Ġassum',
    'es',
    'out',
    'ed',
    'ĠV',
    'ers',
    'Ġinfer',
    'ior',
    'Ġbund',
    'le',
    'ĠD',
    'NS',
    'ograp',
    'her',
    'Ġmult',
    'ip',
    'ĠSoul',
    's',
    'Ġillust',
    'rated',
    'Ġtact',
    'ic',
    'Ġdress',
    'ing',
    'Ġdu',
    'o',
    'Con',
    'f',
    'Ġrel',
    'ent',
    'Ġc',
    'ant',
    'Ġscar',
    'ce',
    'Ġcand',
    'y',
    'ĠC',
    'F',
    'Ġaffili',
    'ated',
    'Ġspr',
    'int',
    'yl',
    'an',
    'ĠGarc',
    'ia',
    'Ġj',
    'unk',
    'Pr',
    'int',
    'ex',
    'ec',
    'C',
    'rit',
    'Ġport',
    'rait',
    'ir',
    'ies',
    'ĠOF',
    'F',
    'Ġdisp',
    'utes',
    'W',
    'R',
    'L',
    'ove',
    'ãģ',
    'Ħ',
    'ĠRe',
    'yn',
    'Ġh',
    'ipp',
    'op',
    'ath',
    'Ġflo',
    'ors',
    'ĠFe',
    'el',
    'Ġwor',
    'ries',
    'Ġsett',
    'lements',
    'ĠP',
    'os',
    'Ġmos',
    'que',
    'Ġfin',
    'als',
    'Ġcr',
    'ushed',
    'ĠPro',
    'bably',
    'ĠB',
    'ot',
    'ĠM',
    'ans',
    'ĠPer',
    'iod',
    'Ġsovere',
    'ignty',
    'Ġsell',
    'er',
    'Ġap',
    'ost',
    'Ġam',
    'ateur',
    'Ġd',
    'orm',
    'Ġconsum',
    'ing',
    'Ġarm',
    'our',
    'ĠRo',
    'ose',
    'Ġint',
    'ensive',
    'Ġelim',
    'inating',
    'ĠSun',
    'ni',
    'ĠAle',
    'ppo',
    'j',
    'in',
    'Ġadv',
    'ise',
    'p',
    'al',
    'ĠH',
    'alo',
    'Ġdes',
    'cent',
    'Ġsimpl',
    'er',
    'Ġbo',
    'oth',
    'ST',
    'R',
    'L',
    'ater',
    'ĠC',
    'ave',
    '==',
    '=',
    'Ġm',
    'ol',
    'Ġf',
    'ist',
    'Ġshot',
    'gun',
    'su',
    'pp',
    'Ġrob',
    'bery',
    'E',
    'ffect',
    'Ġobsc',
    'ure',
    'ĠProf',
    'essional',
    'Ġemb',
    'assy',
    'Ġmilit',
    'ant',
    'Ġinc',
    'arcer',
    'Ġgener',
    'ates',
    'Ġlaun',
    'ches',
    'Ġadministr',
    'ators',
    'Ġsh',
    'aft',
    'Ġcirc',
    'ular',
    'Ġfresh',
    'man',
    'ĠW',
    'es',
    'ĠJo',
    'el',
    'ĠD',
    'rew',
    'ĠDun',
    'can',
    'ĠApp',
    'arently',
    's',
    'ight',
    'ĠIntern',
    'al',
    'ĠInd',
    'ividual',
    'ĠF',
    'E',
    'Ġb',
    'ore',
    'ĠM',
    't',
    'Ġbroad',
    'ly',
    'ĠO',
    'ptions',
    'ount',
    'ain',
    'ip',
    'es',
    'ĠV',
    'ideos',
    '20',
    '4',
    'Ġh',
    'ills',
    'Ġsim',
    'ulation',
    'Ġdisappoint',
    'ment',
    'it',
    'an',
    'ĠLabor',
    'atory',
    'Ġup',
    'ward',
    'Ġbound',
    'ary',
    'Ġdark',
    'er',
    'h',
    'art',
    'Ġdomin',
    'ance',
    'C',
    'ong',
    'ĠOr',
    'acle',
    'ĠL',
    'ords',
    'Ġscholars',
    'hip',
    'ĠVin',
    'cent',
    'ed',
    'e',
    'ĠR',
    'ah',
    'Ġencour',
    'ages',
    'ro',
    'v',
    'Ġqu',
    'o',
    'Ġprem',
    'ise',
    'ĠCris',
    'is',
    'ĠHol',
    'ocaust',
    'Ġrhyth',
    'm',
    'Ġmet',
    'ric',
    'cl',
    'ub',
    'Ġtransport',
    'ed',
    'Ġn',
    'od',
    'ĠP',
    'ist',
    'Ġancest',
    'ors',
    'ĠFred',
    'er',
    'th',
    'umbnails',
    'ĠC',
    'E',
    'ON',
    'D',
    'Ph',
    'il',
    'ven',
    'ge',
    'ĠProduct',
    's',
    'cast',
    'le',
    'Ġqual',
    'ifying',
    'ĠK',
    'aren',
    'VERTIS',
    'EMENT',
    'Ġmight',
    'y',
    'Ġexplan',
    'ations',
    'Ġfix',
    'ing',
    'D',
    'i',
    'Ġdecl',
    'aring',
    'Ġanonym',
    'ity',
    'Ġju',
    'ven',
    'ĠN',
    'ord',
    'ĠDo',
    'om',
    'ĠAct',
    'ually',
    'O',
    'k',
    'ph',
    'is',
    'ĠDes',
    'ert',
    'Ġ11',
    '6',
    'I',
    'K',
    'ĠF',
    'M',
    'Ġinc',
    'omes',
    'V',
    'EL',
    'ok',
    'ers',
    'Ġpe',
    'cul',
    'Ġlight',
    'weight',
    'g',
    'ue',
    'Ġacc',
    'ent',
    'Ġincre',
    'ment',
    'ĠCh',
    'an',
    'Ġcompl',
    'aining',
    'ĠB',
    'aghd',
    'Ġmidfield',
    'er',
    'Ġover',
    'haul',
    'Pro',
    'cess',
    'ĠH',
    'ollow',
    'ĠTit',
    'ans',
    'Sm',
    'all',
    'man',
    'uel',
    'ĠUn',
    'ity',
    'ĠEv',
    'ents',
    'S',
    'ty',
    'Ġdispro',
    'portion',
    'n',
    'esty',
    'en',
    'es',
    'ĠC',
    'od',
    'Ġdemonstr',
    'ations',
    'ĠCrim',
    'son',
    'ĠO',
    'H',
    'Ġen',
    'rolled',
    'Ġc',
    'el',
    'ĠBre',
    'tt',
    'Ġa',
    'ide',
    'Ġhe',
    'els',
    'Ġbroad',
    'band',
    'Ġmark',
    'ing',
    'Ġw',
    'izard',
    'ĠN',
    'J',
    'ĠChief',
    's',
    'Ġingred',
    'ient',
    'Ġd',
    'ug',
    'ĠSh',
    'ut',
    'urch',
    'ase',
    'end',
    'or',
    'Ġfar',
    'mer',
    'ĠGold',
    'man',
    '12',
    '9',
    '15',
    '5',
    'Or',
    'der',
    'Ġl',
    'ion',
    'i',
    'ably',
    'Ġst',
    'ain',
    'ar',
    'ray',
    'ilit',
    'ary',
    'ĠFA',
    'Q',
    'Ġexpl',
    'oded',
    'ĠMcC',
    'arthy',
    'ĠT',
    'weet',
    'ĠG',
    'reens',
    'ek',
    'ing',
    'l',
    'n',
    'ens',
    'en',
    'Ġmotor',
    'cycle',
    'Ġpartic',
    'le',
    'Ġch',
    'olesterol',
    'B',
    'ron',
    'Ġst',
    'air',
    'Ġox',
    'id',
    'Ġdes',
    'irable',
    'ib',
    'les',
    'Ġthe',
    'or',
    'for',
    'cing',
    'Ġpromot',
    'ional',
    'ov',
    'o',
    'b',
    'oot',
    'ĠBon',
    'us',
    'raw',
    'ling',
    'Ġshort',
    'age',
    'ĠP',
    'sy',
    'Ġrecru',
    'ited',
    'Ġinf',
    'ants',
    'Ġtest',
    'osterone',
    'Ġded',
    'uct',
    'Ġdistinct',
    'ive',
    'Ġfirm',
    'ware',
    'bu',
    'ilt',
    '14',
    '5',
    'Ġexpl',
    'ored',
    'Ġfact',
    'ions',
    'Ġv',
    'ide',
    'Ġtatt',
    'oo',
    'Ġfinan',
    'cially',
    'Ġfat',
    'igue',
    'Ġproceed',
    'ing',
    'const',
    'itutional',
    'Ġmis',
    'er',
    'Ġch',
    'airs',
    'gg',
    'ing',
    'ipp',
    'le',
    'Ġd',
    'ent',
    'Ġdis',
    'reg',
    'ç',
    'Ķ',
    'st',
    'ant',
    'll',
    'o',
    'b',
    'ps',
    'aken',
    'ing',
    'Ġab',
    'normal',
    'ĠE',
    'RA',
    'å£',
    '«',
    'ĠH',
    'BO',
    'ĠM',
    'AR',
    'Ġcon',
    'cess',
    'Ġserv',
    'ant',
    'Ġas',
    'pir',
    'l',
    'av',
    'ĠPan',
    'el',
    'am',
    'o',
    'Ġprec',
    'ip',
    'Ġrecord',
    'ings',
    'Ġproceed',
    'ed',
    'Ġcol',
    'ony',
    'ĠT',
    'ang',
    'ab',
    'lo',
    'Ġstri',
    'pped',
    'Le',
    'ft',
    'to',
    'o',
    'Ġpot',
    'atoes',
    'Ġfin',
    'est',
    '%',
    ').',
    'Ġc',
    'rap',
    'ĠZ',
    'ach',
    'ab',
    'ases',
    'ĠG',
    'oth',
    'Ġbillion',
    'aire',
    'w',
    'olf',
    'Ġsan',
    'ction',
    'S',
    'K',
    'Ġlog',
    'ged',
    'P',
    'o',
    'ey',
    'ed',
    'un',
    'al',
    'Ġcr',
    'icket',
    'Ġarm',
    'ies',
    'Ġunc',
    'overed',
    'Cl',
    'oud',
    'Ã³',
    'n',
    'Ġreb',
    'ounds',
    'Ġm',
    'es',
    'O',
    'per',
    'P',
    'ac',
    'Ġnation',
    'ally',
    'Ġinsert',
    'ed',
    'p',
    'ict',
    'Ġgovern',
    'ance',
    'Ð',
    '¸',
    'Ġprivile',
    'ges',
    'G',
    'ET',
    'Ġfavor',
    'ites',
    'im',
    'ity',
    'Ġlo',
    'ver',
    'the',
    'm',
    'em',
    'pl',
    'Ġgorge',
    'ous',
    'An',
    'n',
    'Ġsl',
    'ipped',
    'Ġve',
    'to',
    'B',
    'ob',
    'Ġsl',
    'im',
    'u',
    'cc',
    'ĠF',
    'ame',
    'udden',
    'ly',
    'Ġden',
    'ies',
    'ĠM',
    'aur',
    'Ġdist',
    'ances',
    'Ġw',
    'anna',
    't',
    'ar',
    'ĠS',
    'ER',
    'Ġâ',
    'Ī',
    'Ġle',
    'mon',
    'at',
    'hetic',
    'Ġlit',
    'eral',
    'Ġdistingu',
    'ished',
    'Ġansw',
    'ering',
    'G',
    'I',
    'Ġrelig',
    'ions',
    'ĠPhil',
    'os',
    'ĠL',
    'ay',
    'Ġcomp',
    'os',
    'ire',
    'ments',
    'ĠK',
    'os',
    'ine',
    'z',
    'roll',
    'ing',
    'Ġyoung',
    'est',
    'and',
    'ise',
    'ĠB',
    'orn',
    'Ġalt',
    'ar',
    'am',
    'ina',
    'ĠB',
    'oot',
    'v',
    'oc',
    'Ġdig',
    'ging',
    'Ġpress',
    'ures',
    'Ġl',
    'en',
    '26',
    '4',
    'Ġassass',
    'ination',
    'ĠBir',
    'mingham',
    'ĠMy',
    'th',
    'Ġsovere',
    'ign',
    'ĠArt',
    'ist',
    'ĠPhot',
    'ograph',
    'Ġdep',
    'icted',
    'Ġdisp',
    'ens',
    'orth',
    'y',
    'Ġamb',
    'ul',
    'int',
    'eg',
    'ĠC',
    'ele',
    'ĠTib',
    'et',
    'Ġhier',
    'archy',
    'Ġc',
    'u',
    'Ġpre',
    'season',
    'ĠPet',
    'erson',
    'Ġcol',
    'ours',
    'Ġworry',
    'ing',
    'Ġback',
    'ers',
    'ĠPal',
    'mer',
    'ĠÎ',
    '¼',
    'Ġcontribut',
    'or',
    'Ġhear',
    'ings',
    'Ġur',
    'ine',
    'Ġ',
    'Ù',
    'ourge',
    'ois',
    'Sim',
    'ilar',
    'ĠZ',
    'immer',
    's',
    'omething',
    'ĠUS',
    'C',
    'Ġstrength',
    's',
    'ĠF',
    'I',
    'Ġlog',
    'ging',
    'As',
    'ked',
    'ĠTh',
    'ai',
    'in',
    'qu',
    'ĠW',
    'alt',
    'Ġcrew',
    's',
    'it',
    'ism',
    '3',
    '01',
    'Ġshar',
    'ply',
    'um',
    'ed',
    'Ġred',
    'irect',
    'r',
    'ators',
    'In',
    'f',
    'ĠWe',
    'apons',
    'Ġte',
    'asp',
    '19',
    '99',
    'L',
    'ive',
    'ĠEs',
    'pecially',
    'ĠS',
    'ter',
    'ĠVeter',
    'ans',
    'Ġint',
    'ro',
    'other',
    'apy',
    'Ġmal',
    'ware',
    'Ġbre',
    'eding',
    'Ġmole',
    'cular',
    'ĠR',
    'oute',
    'ĠCom',
    'ment',
    'oc',
    'hem',
    'Ġa',
    'in',
    'Se',
    'ason',
    'Ġlineback',
    'er',
    'Ä',
    '«',
    'ĠEconom',
    'ics',
    'es',
    'ar',
    'ĠL',
    'ives',
    'ĠEm',
    'ma',
    'Ġk',
    'in',
    'ĠTer',
    'rit',
    'Ġpl',
    'anted',
    'ot',
    'on',
    'ĠBut',
    'ter',
    'ĠSp',
    'ons',
    'P',
    'ER',
    'Ġdun',
    'geon',
    'Ġsymb',
    'olic',
    'Ġfil',
    'med',
    'Ġdi',
    'ets',
    'Ġconclud',
    'es',
    'Ġcertain',
    'ty',
    'ĠForm',
    'at',
    'Ġstr',
    'angers',
    'form',
    'at',
    'ĠPh',
    'ase',
    'Ġcop',
    'ied',
    'Ġmet',
    'res',
    'ld',
    'a',
    'ĠUs',
    'ers',
    'Ġdeliber',
    'ate',
    'Ġwas',
    'hed',
    'ĠL',
    'ance',
    'im',
    'ation',
    'Ġimpro',
    'per',
    'ĠGen',
    'esis',
    'ick',
    'r',
    'ĠK',
    'ush',
    'Ġreal',
    'ise',
    'Ġembarrass',
    'ing',
    'alk',
    'ing',
    'b',
    'ucks',
    'Ġver',
    'ified',
    'Ġout',
    'line',
    'year',
    's',
    'ĠIn',
    'come',
    '20',
    '2',
    'Ġz',
    'ombies',
    'F',
    'inal',
    'ĠMill',
    'enn',
    'Ġmod',
    'ifications',
    'ĠV',
    'ision',
    'ĠM',
    'oses',
    'ver',
    'b',
    'iter',
    'ranean',
    'ĠJ',
    'et',
    'Ġnav',
    'al',
    'ĠA',
    'gg',
    'Ġur',
    'l',
    'Ġvict',
    'ories',
    'Ġnon',
    'etheless',
    'Ġinj',
    'ust',
    'ĠF',
    'act',
    'ç',
    'ļ',
    'Ġins',
    'ufficient',
    're',
    'view',
    'face',
    'book',
    'Ġnegoti',
    'ating',
    'Ġguarant',
    'ees',
    'im',
    'en',
    'uten',
    'berg',
    'Ġg',
    'ambling',
    'Ġcon',
    'gr',
    'Load',
    'ing',
    'Ġnever',
    'theless',
    'Ġpres',
    'idents',
    'ĠIndust',
    'rial',
    'Ġ11',
    '8',
    'Ġp',
    'oured',
    'ĠT',
    'ory',
    'Ġ17',
    '5',
    'Ġ:',
    '=',
    'Sc',
    'ott',
    'ange',
    'red',
    'T',
    'ok',
    'Ġorgan',
    'izers',
    'M',
    'at',
    'ĠG',
    'rowth',
    'Ġad',
    'ul',
    'Ġens',
    'ures',
    'Ġ11',
    '7',
    'é¾į',
    'å',
    'Ġmass',
    'acre',
    'Ġgr',
    'ades',
    'be',
    'fore',
    'AD',
    'VERTISEMENT',
    'ĠSl',
    'ow',
    'ĠM',
    'MA',
    'âĢĶ',
    '"',
    'ĠV',
    'atican',
    'Q',
    'aeda',
    'Ġo',
    'we',
    '66',
    '66',
    'ĠS',
    'orry',
    'ĠGr',
    'ass',
    'Ġbackground',
    's',
    'Ġexha',
    'usted',
    'Ġcl',
    'an',
    'Ġcomprom',
    'ised',
    'ĠE',
    'lf',
    'ĠIsa',
    'ac',
    'ens',
    'on',
    'In',
    'vest',
    'IF',
    'A',
    'Ġinterrupt',
    'ed',
    'ãĥī',
    'ãĥ©',
    'Ġtw',
    'isted',
    'ĠDrag',
    'ons',
    'M',
    'ode',
    'ĠK',
    'remlin',
    'Ġfert',
    'il',
    'he',
    'res',
    'ph',
    'an',
    'ĠN',
    'ode',
    'f',
    'ed',
    'ĠOr',
    'c',
    'Ġunw',
    'illing',
    'C',
    'ent',
    'Ġprior',
    'it',
    'Ġgrad',
    'uates',
    'Ġsubject',
    'ive',
    'Ġiss',
    'uing',
    'ĠL',
    't',
    'Ġview',
    'er',
    'Ġw',
    'oke',
    'Th',
    'us',
    'bro',
    'ok',
    'Ġdep',
    'ressed',
    'Ġbr',
    'acket',
    'ĠG',
    'or',
    'ĠFight',
    'ing',
    'Ġstri',
    'ker',
    'Rep',
    'ort',
    'ĠPortug',
    'al',
    'Ġne',
    'o',
    'w',
    'ed',
    '19',
    '9',
    'Ġflee',
    'ing',
    'sh',
    'adow',
    'ident',
    'ified',
    'US',
    'E',
    'Ste',
    'am',
    'Ġstret',
    'ched',
    'Ġrevel',
    'ations',
    'art',
    'ed',
    'ĠD',
    'w',
    'Ġalign',
    'ment',
    'est',
    'on',
    'ĠJ',
    'ared',
    'S',
    'ep',
    'Ġblog',
    's',
    'up',
    'date',
    'g',
    'om',
    'r',
    'isk',
    'Ġcl',
    'ash',
    'ĠH',
    'our',
    'Ġrun',
    'time',
    'Ġunw',
    'anted',
    'Ġsc',
    'am',
    'Ġr',
    'ack',
    'Ġen',
    'light',
    'on',
    'est',
    'ĠF',
    'err',
    'Ġconv',
    'ictions',
    'Ġp',
    'iano',
    'Ġcirc',
    'ulation',
    'ĠW',
    'elcome',
    'Ġback',
    'lash',
    'ĠW',
    'ade',
    'Ġrece',
    'ivers',
    'ot',
    'ive',
    'J',
    'eff',
    'Ġnetwork',
    'ing',
    'ĠPre',
    'p',
    'ĠExpl',
    'orer',
    'Ġlect',
    'ure',
    'Ġupload',
    'ed',
    'ĠMe',
    'at',
    'B',
    'LE',
    'ĠNaz',
    'is',
    'ĠSy',
    'nd',
    'st',
    'ud',
    'ro',
    'ots',
    'ri',
    'ans',
    'Ġportray',
    'ed',
    'Ġ',
    '??',
    'ĠBudd',
    'ha',
    's',
    'un',
    'Rober',
    't',
    'ĠCom',
    'plex',
    'Ġover',
    'see',
    'Ġste',
    'alth',
    'T',
    'itle',
    'ĠJ',
    'obs',
    'ĠK',
    'um',
    'Ġappreci',
    'ation',
    'ĠM',
    'OD',
    'Ġbas',
    'ics',
    'Ġcl',
    'ips',
    'Ġnurs',
    'ing',
    'Ġpropos',
    'ition',
    'Ġreal',
    'ised',
    'ĠNY',
    'C',
    'Ġall',
    'ocated',
    'ri',
    'um',
    'ar',
    'an',
    'ĠPro',
    'duction',
    'ĠV',
    'ote',
    'Ġsm',
    'ugg',
    'Ġhun',
    'ter',
    'az',
    'er',
    'ĠCh',
    'anges',
    'Ġfl',
    'uct',
    'y',
    'on',
    'Ar',
    'ray',
    'Ġk',
    'its',
    'W',
    'ater',
    'Ġuncom',
    'mon',
    'Ġrest',
    'ing',
    'ell',
    's',
    'w',
    'ould',
    'Ġpurs',
    'ued',
    'Ġassert',
    'ion',
    'omet',
    'own',
    'ĠMos',
    'ul',
    'ĠPl',
    'atform',
    'io',
    'let',
    'Ġshare',
    'holders',
    'Ġtra',
    'ils',
    'P',
    'ay',
    'ĠEn',
    'forcement',
    'ty',
    'pes',
    'ĠAn',
    'onymous',
    'Ġsatisf',
    'ying',
    'il',
    'ogy',
    'Ġ(',
    "'",
    'w',
    'ave',
    'c',
    'ity',
    'Ste',
    've',
    'Ġconfront',
    'ation',
    'ĠE',
    'ld',
    'C',
    'apt',
    'ah',
    'an',
    'ht',
    'm',
    'ĠC',
    'trl',
    'ON',
    'S',
    '2',
    '30',
    'if',
    'a',
    'hold',
    'ing',
    'Ġdelic',
    'ate',
    'Ġj',
    'aw',
    'ĠGo',
    'ing',
    'or',
    'um',
    'S',
    'al',
    'Ġd',
    'ull',
    'ĠB',
    'eth',
    'Ġpr',
    'isons',
    'Ġe',
    'go',
    'ĠEl',
    'sa',
    'avor',
    'ite',
    'ĠG',
    'ang',
    'ĠN',
    'uclear',
    'Ġsp',
    'ider',
    'ats',
    'u',
    'Ġsam',
    'pling',
    'Ġabsor',
    'bed',
    'ĠPh',
    'arm',
    'iet',
    'h',
    'Ġbuck',
    'et',
    'ĠRec',
    'omm',
    'O',
    'F',
    'ĠF',
    'actory',
    'AN',
    'CE',
    'Ġb',
    'acter',
    'H',
    'as',
    'ĠObs',
    'erv',
    '12',
    '1',
    'Ġprem',
    'iere',
    'De',
    'velop',
    'Ġcur',
    'rencies',
    'C',
    'ast',
    'Ġaccompany',
    'ing',
    'ĠNash',
    'ville',
    'Ġfat',
    'ty',
    'ĠBre',
    'nd',
    'Ġloc',
    'ks',
    'Ġcent',
    'ered',
    'ĠU',
    'T',
    'augh',
    's',
    'or',
    'ie',
    'ĠAff',
    'ordable',
    'v',
    'ance',
    'D',
    'L',
    'em',
    'et',
    'Ġthr',
    'one',
    'ĠBlu',
    'etooth',
    'Ġn',
    'aming',
    'if',
    'ts',
    'AD',
    'E',
    'Ġcorrect',
    'ed',
    'Ġprompt',
    'ly',
    'ĠST',
    'R',
    'Ġgen',
    'ome',
    'Ġcop',
    'e',
    'Ġval',
    'ley',
    'Ġround',
    'ed',
    'ĠK',
    'end',
    'al',
    'ion',
    'p',
    'ers',
    'Ġtour',
    'ism',
    'Ġst',
    'ark',
    'v',
    'l',
    'Ġblow',
    'ing',
    'ĠSche',
    'dule',
    'st',
    'd',
    'Ġunh',
    'appy',
    'Ġlit',
    'igation',
    'ced',
    'es',
    'Ġand',
    'roid',
    'Ġinteg',
    'ral',
    'ere',
    'rs',
    'ud',
    'ed',
    't',
    'ax',
    'Ġre',
    'iter',
    'ĠMot',
    'ors',
    'oci',
    'ated',
    'Ġwond',
    'ers',
    'ĠAp',
    'ost',
    'uck',
    'ing',
    'ĠRoose',
    'velt',
    'f',
    'ram',
    'Ġyield',
    's',
    'Ġconstit',
    'utes',
    'aw',
    'k',
    'Int',
    'erest',
    'Ġinter',
    'im',
    'Ġbreak',
    'through',
    'ĠC',
    'her',
    'Ġpro',
    'sec',
    'ĠD',
    'j',
    'ĠM',
    'T',
    'Res',
    'p',
    'ĠP',
    'T',
    'Ġs',
    'perm',
    'ed',
    'it',
    'B',
    'T',
    'Lin',
    'ux',
    'count',
    'ry',
    'le',
    'ague',
    'Ġd',
    'ick',
    'Ġo',
    'ct',
    'Ġinsert',
    'ing',
    'Ġsc',
    'ra',
    'ĠBrew',
    'ing',
    'Ġ19',
    '66',
    'Ġrun',
    'ners',
    'Ġpl',
    'un',
    'id',
    'y',
    'ĠD',
    'ian',
    'Ġdys',
    'function',
    'Ġex',
    'clusion',
    'Ġdis',
    'gr',
    'Ġincorpor',
    'ate',
    'Ġrecon',
    'c',
    'Ġnom',
    'inated',
    'ĠAr',
    'cher',
    'd',
    'raw',
    'achel',
    'or',
    'Ġwrit',
    'ings',
    'Ġshall',
    'ow',
    'Ġh',
    'ast',
    'ĠB',
    'MW',
    'ĠR',
    'S',
    'Ġth',
    'igh',
    'Ġ19',
    '63',
    'Ġl',
    'amb',
    'Ġfav',
    'ored',
    'ag',
    'le',
    'Ġcool',
    'er',
    'ĠH',
    'ours',
    'ĠG',
    'U',
    'ĠOrig',
    'in',
    'Ġglim',
    'pse',
    '----------------',
    '----',
    'L',
    'im',
    'Ġche',
    'ek',
    'Ġj',
    'ealous',
    '-',
    "'",
    'Ġhar',
    'ness',
    'ĠPo',
    'ison',
    'Ġdis',
    'abilities',
    'ne',
    'apolis',
    'Ġout',
    'look',
    'Ġnot',
    'ify',
    'ĠIndian',
    'apolis',
    'Ġab',
    'rupt',
    'ns',
    'ic',
    'Ġenc',
    'rypted',
    'Ġfor',
    'fe',
    'reat',
    'h',
    'Ġr',
    'abb',
    'Ġfound',
    'ations',
    'Ġcompl',
    'iment',
    'ĠInter',
    'view',
    'ĠS',
    'we',
    'Ġad',
    'olesc',
    'Ġmon',
    'itors',
    'ĠSacrament',
    'o',
    'Ġtime',
    'ly',
    'Ġcontem',
    'pl',
    'Ġposition',
    'ed',
    'Ġpost',
    'ers',
    'ph',
    'ies',
    'iov',
    'ascular',
    'v',
    'oid',
    'ĠFif',
    'th',
    'Ġinvestig',
    'ative',
    'OU',
    'N',
    'Ġinteg',
    'rate',
    'ĠIN',
    'C',
    'ish',
    'a',
    'ibl',
    'ings',
    'ĠRe',
    'quest',
    'ĠRodrig',
    'uez',
    'Ġsl',
    'ides',
    'ĠD',
    'X',
    'Ġfemin',
    'ism',
    'Ġdat',
    'as',
    'Ġb',
    'end',
    'ir',
    'us',
    'ĠNig',
    'eria',
    'F',
    'ox',
    'Ch',
    'ange',
    'Ġair',
    'plane',
    'ĠLad',
    'en',
    'Ġpublic',
    'ity',
    'ixt',
    'y',
    'Ġcommit',
    'ments',
    'Ġaggreg',
    'ate',
    'Ġdisplay',
    'ing',
    'ĠAr',
    'row',
    'Ġ12',
    '2',
    'Ġrespect',
    's',
    'and',
    'roid',
    's',
    'ix',
    'ĠSh',
    'a',
    'Ġrest',
    'oration',
    ')',
    '\\',
    'W',
    'S',
    'oy',
    's',
    'Ġillust',
    'rate',
    'with',
    'out',
    '12',
    '6',
    'ĠâĶ',
    'Ĥ',
    'Ġpick',
    'up',
    'n',
    'els',
    'Ġ',
    '....',
    'f',
    'ood',
    'ĠF',
    'en',
    ')',
    '?',
    'Ġphenomen',
    'a',
    'Ġcompan',
    'ions',
    'ĠW',
    'rite',
    'Ġsp',
    'ill',
    'Ġbr',
    'idges',
    'ĠUp',
    'dated',
    'ĠF',
    'o',
    'Ġinsect',
    's',
    'ASH',
    'INGTON',
    'Ġsc',
    'are',
    'il',
    'tr',
    'ĠZh',
    'ang',
    'Ġsever',
    'ity',
    'Ġind',
    'ul',
    '14',
    '9',
    'ĠCo',
    'ffee',
    'Ġnorm',
    's',
    'Ġp',
    'ulse',
    'ĠF',
    'T',
    'Ġhorr',
    'ific',
    'ĠDest',
    'roy',
    'ĠJ',
    'SON',
    'Ġo',
    'live',
    'Ġdiscuss',
    'es',
    'R',
    'est',
    'E',
    'lect',
    'ĠW',
    'inn',
    'ĠSurv',
    'iv',
    'ĠH',
    'ait',
    'S',
    'ure',
    'op',
    'ed',
    'Ġro',
    'oted',
    'ĠS',
    'ke',
    'ĠBron',
    'ze',
    'Ġl',
    'ol',
    'Def',
    'ault',
    'Ġcommod',
    'ity',
    'red',
    'ited',
    'Ġliber',
    'tarian',
    'Ġforb',
    'idden',
    'Ġgr',
    'an',
    'à',
    '¨',
    'Ġl',
    'ag',
    'en',
    'z',
    'dri',
    've',
    'Ġmathemat',
    'ics',
    'Ġw',
    'ires',
    'Ġcrit',
    'ically',
    'Ġcarb',
    'ohyd',
    'ĠChance',
    'llor',
    'ĠEd',
    'die',
    'Ġban',
    'ning',
    'ĠF',
    'ri',
    'Ġcompl',
    'ications',
    'et',
    'ric',
    'ĠBangl',
    'adesh',
    'Ġband',
    'width',
    'St',
    'op',
    'ĠOrig',
    'inally',
    'Ġhalf',
    'way',
    'yn',
    'asty',
    'sh',
    'ine',
    'Ġt',
    'ales',
    'rit',
    'ies',
    'av',
    'ier',
    'Ġspin',
    'ning',
    'ĠWH',
    'O',
    'Ġneighbour',
    'hood',
    'b',
    'ach',
    'Ġcommer',
    'ce',
    'ĠS',
    'le',
    'B',
    'U',
    'Ġentreprene',
    'ur',
    'Ġpecul',
    'iar',
    'ĠCom',
    'ments',
    'f',
    're',
    '3',
    '20',
    'IC',
    'S',
    'Ġimag',
    'ery',
    'ĠCan',
    'on',
    'ĠElect',
    'ronic',
    'sh',
    'ort',
    '(',
    '(',
    'D',
    'ig',
    'Ġcomm',
    'em',
    'u',
    'ced',
    'Ġincl',
    'ined',
    'ĠSum',
    'mon',
    'Ġcl',
    'iff',
    'ĠMed',
    'iterranean',
    'Ġpo',
    'etry',
    'Ġprosper',
    'ity',
    'ĠRe',
    'ce',
    'Ġp',
    'ills',
    'm',
    'ember',
    'Ġfin',
    'ale',
    'un',
    'c',
    'ĠG',
    'ig',
    'ä',
    '½',
    'Ġl',
    'od',
    'Ġback',
    'ward',
    '-',
    '+',
    'ĠFor',
    'ward',
    'Ġth',
    'ri',
    's',
    'ure',
    'Ġso',
    'ap',
    'ĠF',
    'X',
    'R',
    'ES',
    'ĠSe',
    'xual',
    'oul',
    'os',
    'Ġfool',
    'ish',
    'Ġright',
    'eous',
    'Ġco',
    'ff',
    'terror',
    'ism',
    'ust',
    'ain',
    'ot',
    'er',
    'Ġab',
    'uses',
    'ne',
    'xt',
    'Ġab',
    'usive',
    'Ġthere',
    'after',
    'Ġprohib',
    'ition',
    'ĠS',
    'UP',
    'Ġd',
    'ip',
    'Ġr',
    'ipped',
    'Ġinher',
    'ited',
    'Ġb',
    'ats',
    'st',
    'ru',
    'G',
    'T',
    'Ġflaw',
    'ed',
    'ph',
    'abet',
    'Ġf',
    'og',
    'do',
    'ors',
    'Ġim',
    'aging',
    'Ġdig',
    'its',
    'ĠHung',
    'ary',
    'Ġar',
    'rog',
    'Ġteach',
    'ings',
    'Ġprotocol',
    's',
    'ĠB',
    'anks',
    'à',
    '¸',
    'p',
    'ound',
    'ĠC',
    'urt',
    '."',
    ')',
    '.',
    '/',
    'Ġex',
    'emption',
    'end',
    'ix',
    'ĠM',
    'ull',
    'Ġimpro',
    'ves',
    'ĠG',
    'amer',
    'd',
    'imensional',
    'I',
    'con',
    'ĠMarg',
    'aret',
    'St',
    'atus',
    'd',
    'ates',
    'Ġint',
    'ends',
    'Ġdep',
    'ict',
    'Ġpark',
    'ed',
    'J',
    'oe',
    'ĠMar',
    'ines',
    'chn',
    'ology',
    '!',
    ').',
    'Ġjud',
    'ged',
    'Ġwe',
    'ights',
    'R',
    'ay',
    'Ġapart',
    'ments',
    'he',
    'ster',
    'Ġrein',
    'force',
    'Ġoff',
    'ender',
    'occ',
    'up',
    'Ġs',
    'ore',
    'e',
    'pt',
    'ĠPH',
    'P',
    'ĠB',
    'row',
    'Ġauthor',
    'ization',
    'ĠR',
    'isk',
    'ĠDel',
    'aware',
    'ĠQ',
    'U',
    'Ġnot',
    'ifications',
    'Ġsun',
    'light',
    'Ġex',
    'clude',
    'd',
    'at',
    'Ġm',
    'esh',
    'ĠSud',
    'an',
    'Ġbelong',
    'ed',
    'Ġsub',
    'way',
    'Ġno',
    'on',
    'ĠInter',
    'ior',
    'ol',
    'ics',
    'ĠL',
    'akers',
    'Ġc',
    'oding',
    'Dis',
    'claimer',
    'Cal',
    'if',
    'O',
    'ld',
    'Ġdis',
    'l',
    '????',
    '?',
    'Ġconfir',
    'ms',
    'Ġrecruit',
    'ment',
    'Ġhom',
    'icide',
    'Cons',
    'ider',
    'ĠJeff',
    'rey',
    'ft',
    'y',
    '}',
    ';',
    'Ġobject',
    'ion',
    'do',
    'ing',
    'ĠLe',
    'o',
    'W',
    'ant',
    'Ġgl',
    'ow',
    'ĠClar',
    'ke',
    'ĠNorm',
    'an',
    'Ġver',
    'ification',
    'Ġpack',
    'et',
    'ĠForm',
    'ula',
    'Ġpl',
    'ag',
    'es',
    'ville',
    'Ġshout',
    'ing',
    'Ġo',
    'v',
    'ĠR',
    'EC',
    'ĠB',
    'ub',
    'Ġn',
    'inth',
    'Ġener',
    'g',
    'Ġvalid',
    'ity',
    'Ġup',
    's',
    'j',
    'ack',
    'Ġneighbor',
    'ing',
    'ĠN',
    'ec',
    'ew',
    'orks',
    'ĠH',
    'ab',
    'are',
    'z',
    'Ġsp',
    'ine',
    'Ġevent',
    'ual',
    'ĠLe',
    'aders',
    'ĠC',
    'arn',
    'Ġprob',
    'ation',
    'Ġrom',
    'ance',
    'ms',
    'g',
    'ĠMechan',
    'ical',
    'ER',
    'Y',
    'R',
    'ock',
    'Ġpart',
    'isan',
    'N',
    'ode',
    'ass',
    'ets',
    'min',
    'ent',
    'Ġforeign',
    'ers',
    'Ġtest',
    'ify',
    'ĠUs',
    'ually',
    'l',
    'ords',
    'ĠG',
    'ren',
    'ĠPow',
    'ell',
    'BI',
    'L',
    'Ġs',
    'r',
    'Ġadd',
    'ict',
    'Ġshell',
    's',
    'Ġs',
    'igh',
    'ĠY',
    'ale',
    'tern',
    'ity',
    'Ġ7',
    '50',
    'E',
    'U',
    'ĠR',
    'ifle',
    'Ġpat',
    'ron',
    'em',
    'a',
    'ĠB',
    'annon',
    'an',
    'ity',
    'Ġtrop',
    'ical',
    'ĠV',
    'II',
    'c',
    'ross',
    'Every',
    'thing',
    'ĠIS',
    'O',
    'Ġhum',
    'ble',
    'ass',
    'ing',
    'ĠF',
    'IG',
    'Ġupd',
    'ating',
    'ys',
    'on',
    'Ġcal',
    'cium',
    'Ġcompet',
    'ent',
    'Ġste',
    'ering',
    'Pro',
    't',
    'ĠS',
    'Y',
    'ĠFin',
    'als',
    'ĠR',
    'ug',
    '15',
    '9',
    '13',
    '7',
    'ĠG',
    'olf',
    'Ġ12',
    '6',
    'Ġaccommod',
    'ation',
    'ĠHug',
    'hes',
    'Ġaest',
    'hetic',
    'art',
    'isan',
    'ĠTw',
    'ilight',
    'Ġpr',
    'ince',
    'ĠAgric',
    'ulture',
    'ĠDis',
    'co',
    'Ġpreced',
    'ent',
    'Ġtyp',
    'ing',
    'author',
    'ized',
    'O',
    'ption',
    'ĠA',
    'ub',
    'l',
    'ishes',
    'ach',
    't',
    'm',
    'ag',
    'P',
    'eter',
    'ĠU',
    'FO',
    'mont',
    'on',
    'ĠL',
    'ith',
    'Ġa',
    'rom',
    'Ġsec',
    'uring',
    'Ġconf',
    'ined',
    'priv',
    'ate',
    'Ġsw',
    'ords',
    'Ġmark',
    'ers',
    'Ġmetab',
    'olic',
    'se',
    'lect',
    'ĠCur',
    'se',
    'ĠO',
    't',
    'g',
    'ressive',
    'Ġinc',
    'umb',
    'ĠS',
    'aga',
    'Ġpr',
    'iced',
    'Ġclear',
    'ance',
    'Cont',
    'ent',
    'Ġdr',
    'illing',
    'Ġnot',
    'ices',
    'Ġb',
    'ourgeois',
    'Ġv',
    'est',
    'Ġcook',
    'ie',
    'ĠGuard',
    'ians',
    'ry',
    's',
    'in',
    'yl',
    'Ġ12',
    '4',
    'Ġpl',
    'ausible',
    'on',
    'gh',
    'ĠOd',
    'in',
    'Ġconcept',
    'ion',
    'ĠY',
    'uk',
    'ĠBaghd',
    'ad',
    'ĠFl',
    'ag',
    'Aust',
    'ral',
    'ĠI',
    'BM',
    'Ġintern',
    'ationally',
    'ĠWiki',
    'Leaks',
    'I',
    'ED',
    'Ġc',
    'yn',
    'Ġcho',
    'oses',
    'ĠP',
    'ill',
    'Ġcomb',
    'ining',
    'Ġrad',
    'i',
    'ĠMoh',
    'ammed',
    'def',
    'ense',
    'atch',
    'ing',
    'Sub',
    'ject',
    'ic',
    'iency',
    'Fr',
    'ame',
    'Ġ{',
    '"',
    'Ġche',
    'ss',
    'Ġtim',
    'er',
    '19',
    '0',
    'Ġt',
    'in',
    'Ġord',
    'inance',
    'emet',
    'ery',
    'Ġacc',
    'using',
    'Ġnotice',
    'able',
    'Ġcent',
    'res',
    'Ġl',
    'id',
    'ĠM',
    'ills',
    'img',
    'ur',
    'Ġz',
    'oom',
    'erg',
    'ic',
    'Ġcomp',
    'ression',
    'pr',
    'im',
    'f',
    'ind',
    'Ġsur',
    'g',
    'Ġp',
    'and',
    'ĠK',
    'ee',
    'ĠCh',
    'ad',
    'cell',
    'ence',
    'oy',
    'le',
    'Ġsocial',
    'ism',
    'ĠT',
    'ravis',
    'ĠM',
    'Hz',
    'Ġgu',
    'ild',
    'ALL',
    'Y',
    'ĠSub',
    'scribe',
    'ĠRel',
    'ated',
    'Ġoccur',
    'rence',
    'itch',
    'ing',
    'Ġfict',
    'ional',
    'Ġcr',
    'ush',
    'ĠE',
    'A',
    'c',
    'od',
    'm',
    'ix',
    'ĠTri',
    'ple',
    'Ġretrie',
    've',
    'Ġstimul',
    'us',
    'Ġpsych',
    'iat',
    'ĠDo',
    'or',
    'Ġhomosexual',
    'ity',
    'Ġelement',
    'ary',
    'Ġcell',
    'ular',
    'id',
    'ian',
    'ĠL',
    'aun',
    'Ġintrig',
    'uing',
    'Ġfo',
    'am',
    'ĠB',
    'ass',
    'id',
    'i',
    'its',
    'u',
    'Ġass',
    'ure',
    'Ġcongr',
    'at',
    'Ġbusiness',
    'man',
    'ĠBo',
    'ost',
    'cl',
    'ose',
    'Ġl',
    'ied',
    'Ġsc',
    'iences',
    'ĠO',
    'mega',
    'ĠG',
    'raphics',
    'Ġ<',
    '=',
    'sp',
    'oken',
    'Ġconnect',
    'ivity',
    'S',
    'aturday',
    'ĠAven',
    'gers',
    'Ġto',
    'ggle',
    'Ġank',
    'le',
    'Ġnational',
    'ist',
    'mod',
    'el',
    'ĠP',
    'ool',
    'ophob',
    'ia',
    'V',
    'ar',
    'ĠM',
    'ons',
    'ator',
    'ies',
    'Ġaggress',
    'ively',
    'C',
    'lear',
    'For',
    'ge',
    'act',
    'ers',
    'Ġhed',
    'ge',
    'Ġpip',
    'es',
    'Ġbl',
    'unt',
    'Ġs',
    'q',
    'Ġremote',
    'ly',
    'W',
    'ed',
    'as',
    'ers',
    'Ġref',
    'riger',
    'Ġt',
    'iles',
    'Ġresc',
    'ued',
    'Ġcompr',
    'ised',
    'ins',
    'ky',
    'Ġman',
    'if',
    'avan',
    'augh',
    'Ġprol',
    'ifer',
    'Ġal',
    'igned',
    'x',
    'ml',
    'Ġtri',
    'v',
    'Ġcoord',
    'ination',
    'ĠP',
    'ER',
    'ĠQu',
    'ote',
    '13',
    '4',
    'b',
    'f',
    'ĠS',
    'aw',
    'Ġtermin',
    'ation',
    'Ġ19',
    '0',
    'Ġadd',
    'itions',
    'Ġtri',
    'o',
    'Ġproject',
    'ions',
    'Ġpositive',
    'ly',
    'Ġin',
    'clusive',
    'Ġmem',
    'br',
    '19',
    '90',
    'old',
    'er',
    'Ġpract',
    'iced',
    'ink',
    'le',
    'Ar',
    'ch',
    'Ġstar',
    'ters',
    'ari',
    'us',
    'Ġinter',
    'mediate',
    'ĠBen',
    'ef',
    'ĠK',
    'iller',
    'Ġinter',
    'ventions',
    'ĠK',
    'il',
    'ĠF',
    'lying',
    'In',
    'v',
    'Ġprem',
    'ature',
    'Ġpsych',
    'iatric',
    'Ġind',
    'ie',
    'Ġcoll',
    'ar',
    'ĠRain',
    'bow',
    'af',
    'i',
    'Ġdis',
    'ruption',
    'ĠFO',
    'X',
    'cast',
    'ing',
    'Ġmis',
    'dem',
    'c',
    'ro',
    'Ġw',
    'ipe',
    'ard',
    'on',
    'Ġb',
    'ast',
    'ĠTom',
    'my',
    'ĠRepresent',
    'ative',
    'Ġbell',
    'y',
    'ĠP',
    'O',
    'ĠBre',
    'itbart',
    '13',
    '2',
    'Ġmess',
    'aging',
    'Sh',
    'ould',
    'Ref',
    'erences',
    'ĠG',
    'RE',
    'ist',
    'ical',
    'L',
    'P',
    'ĠC',
    'av',
    'ĠC',
    'razy',
    'Ġintu',
    'itive',
    'ke',
    'eping',
    'ĠM',
    'oss',
    'Ġdiscont',
    'in',
    'ĠMod',
    'ule',
    'Ġun',
    'related',
    'ĠPract',
    'ice',
    'ĠTrans',
    'port',
    'Ġstatist',
    'ically',
    'orn',
    's',
    'Ġs',
    'ized',
    'p',
    'u',
    'Ġca',
    'f',
    'ĠWorld',
    's',
    'ĠRod',
    'gers',
    'ĠL',
    'un',
    'ĠCom',
    'ic',
    'l',
    'iving',
    'Ġc',
    'ared',
    'Ġclim',
    'bed',
    ')',
    '{',
    'Ġconsist',
    'ed',
    'Ġmed',
    'ieval',
    'fol',
    'k',
    'Ġh',
    'acked',
    'Ġd',
    'ire',
    'ĠHerm',
    'ione',
    'Ġt',
    'ended',
    'ce',
    'ans',
    'D',
    'aniel',
    'w',
    'ent',
    'Ġlegisl',
    'ators',
    'Ġred',
    'es',
    'g',
    'ames',
    'Ġg',
    'n',
    'am',
    'iliar',
    'Ġ+',
    '+',
    'gg',
    'y',
    'th',
    'reat',
    'Ġmag',
    'net',
    'Ġper',
    'ceive',
    'Ġz',
    'ip',
    'Ġindict',
    'ment',
    'Ġcrit',
    'ique',
    'g',
    'ard',
    'ĠSaf',
    'e',
    'ĠC',
    'ream',
    'Ġad',
    'vent',
    'ob',
    'a',
    'Ġv',
    'owed',
    'ous',
    'ands',
    'Ġsk',
    'i',
    'Ġabort',
    'ions',
    'u',
    'art',
    'Ġstun',
    'ned',
    'Ġadv',
    'ancing',
    'Ġlack',
    'ed',
    'Ġ\\',
    '"',
    'Ġsch',
    'izophren',
    'Ġeleg',
    'ant',
    'Ġconf',
    'erences',
    'Ġcance',
    'led',
    'ĠHud',
    'son',
    'ĠHop',
    'efully',
    'Ġtr',
    'ump',
    'Ġfrequ',
    'encies',
    'Ġmet',
    'eor',
    'ĠJun',
    'ior',
    'ĠFle',
    'et',
    'ĠMal',
    'colm',
    'ĠT',
    'ools',
    'Ġ',
    '........',
    'Ġh',
    'obby',
    'ĠEurope',
    'ans',
    'Ġ15',
    '00',
    'ĠInt',
    'o',
    'Ġs',
    'way',
    'ĠApp',
    'ro',
    'ĠCom',
    'pl',
    'Comm',
    'unity',
    'Ġt',
    'ide',
    'ĠSum',
    'mit',
    'ä',
    '»',
    'Ġinter',
    'vals',
    'ĠE',
    'ther',
    'Ġhabit',
    'at',
    'ĠSteven',
    's',
    'lish',
    'ing',
    'ĠDom',
    'ain',
    'Ġtrig',
    'gers',
    'Ġch',
    'asing',
    'Ġchar',
    'm',
    'ĠFl',
    'ower',
    'it',
    'ored',
    'Ġbless',
    'ing',
    'Ġtext',
    'ures',
    'F',
    'ive',
    'Ġliqu',
    'or',
    'R',
    'P',
    'F',
    'IN',
    'Ġ19',
    '62',
    'C',
    'AR',
    'Un',
    'known',
    'Ġres',
    'il',
    'ĠL',
    'ily',
    'Ġabund',
    'ance',
    'Ġpredict',
    'able',
    'r',
    'ar',
    'Ġbull',
    'shit',
    'le',
    'en',
    'che',
    't',
    'M',
    'or',
    'M',
    'uch',
    'ä',
    '¹',
    'Ġemphas',
    'ized',
    'Ġcr',
    'ust',
    'Ġprim',
    'itive',
    'Ġenjoy',
    'able',
    'ĠPict',
    'ures',
    'Ġteam',
    'mate',
    'pl',
    'er',
    'ĠT',
    'ol',
    'ĠK',
    'ane',
    'Ġsummon',
    'ed',
    'th',
    'y',
    'ram',
    'a',
    'ĠH',
    'onda',
    'Ġreal',
    'izing',
    'Ġquick',
    'er',
    'Ġconcent',
    'rate',
    'cle',
    'ar',
    'Ġ2',
    '10',
    'ĠErd',
    'ogan',
    'ar',
    'is',
    'Ġrespond',
    's',
    'ĠB',
    'I',
    'Ġelig',
    'ibility',
    'Ġpus',
    'hes',
    'ĠId',
    'aho',
    'Ġagg',
    'rav',
    'Ġru',
    'ins',
    'ur',
    'ations',
    'Ġb',
    'ans',
    'Ġan',
    'at',
    'sh',
    'are',
    'Ġgr',
    'ind',
    'h',
    'in',
    'um',
    'en',
    'Ġut',
    'ilities',
    'ĠYan',
    'kees',
    'Ġdat',
    'abases',
    'ĠD',
    'D',
    'Ġdispl',
    'aced',
    'Ġdepend',
    'encies',
    'Ġstim',
    'ulation',
    'h',
    'un',
    'h',
    'ouses',
    'ĠP',
    'retty',
    'ĠRaven',
    's',
    'ĠTOD',
    'AY',
    'Ġassoci',
    'ates',
    'Ġthe',
    'rape',
    'cl',
    'ed',
    'Ġde',
    'er',
    'Ġrep',
    'airs',
    'rent',
    'ice',
    'Ġrecept',
    'ors',
    'Ġrem',
    'ed',
    'ĠC',
    'e',
    'Ġmar',
    'riages',
    'Ġball',
    'ots',
    'ĠSold',
    'ier',
    'Ġhilar',
    'ious',
    'op',
    'l',
    '13',
    '8',
    'Ġinherent',
    'ly',
    'Ġignor',
    'ant',
    'Ġb',
    'ounce',
    'ĠE',
    'aster',
    'REL',
    'ATED',
    'ĠCur',
    'rency',
    'E',
    'V',
    'ãĥ',
    'ŀ',
    'ĠLe',
    'ad',
    'Ġdece',
    'ased',
    'B',
    'rien',
    'ĠMus',
    'k',
    'J',
    'S',
    'Ġmer',
    'ge',
    'heart',
    'ed',
    'c',
    'reat',
    'm',
    'itt',
    'm',
    'und',
    'ĠâĢ',
    'ĭ',
    'ĠB',
    'ag',
    'Ġproject',
    'ion',
    'Ġj',
    'ava',
    'ĠStand',
    'ards',
    'ĠLeon',
    'ard',
    'Ġcoc',
    'onut',
    'ĠPop',
    'ulation',
    'Ġtra',
    'ject',
    'Ġimp',
    'ly',
    'Ġcur',
    'iosity',
    'ĠD',
    'B',
    'ĠF',
    'resh',
    'ĠP',
    'or',
    'Ġheav',
    'ier',
    'ne',
    'ys',
    'gom',
    'ery',
    'Ġdes',
    'erved',
    'Ġphr',
    'ases',
    'ĠG',
    'C',
    'Ġye',
    'ast',
    'd',
    'esc',
    'De',
    'ath',
    'Ġreb',
    'oot',
    'Ġmet',
    'adata',
    'IC',
    'AL',
    'Ġrep',
    'ay',
    'ĠInd',
    'ependence',
    'Ġsubur',
    'ban',
    'ical',
    's',
    'Ġat',
    'op',
    'Ġall',
    'ocation',
    'gener',
    'ation',
    'ĠG',
    'ram',
    'Ġmoist',
    'ure',
    'Ġp',
    'ine',
    'ĠLiber',
    'als',
    'Ġa',
    'ides',
    'Ġund',
    'erest',
    'ĠBer',
    'ry',
    'Ġcere',
    'mon',
    '3',
    '70',
    'ast',
    'rous',
    'ĠPir',
    'ates',
    'Ġt',
    'ense',
    'ĠIndust',
    'ries',
    'ĠApp',
    'eals',
    'ĠN',
    'ear',
    'Ġè£ı',
    'ç',
    'Ġlo',
    'vers',
    'ĠC',
    'AP',
    'ĠC',
    'raw',
    'Ġg',
    'iants',
    'Ġeffic',
    'acy',
    'E',
    'lement',
    'ĠBeh',
    'avior',
    'ĠToy',
    'ota',
    'Ġint',
    'est',
    'P',
    'riv',
    'A',
    'I',
    'Ġmaneu',
    'ver',
    'Ġperfect',
    'ion',
    'Ġb',
    'ang',
    'p',
    'aper',
    'r',
    'ill',
    'Ge',
    'orge',
    'b',
    'order',
    'in',
    'ters',
    'ĠS',
    'eth',
    'Ġcl',
    'ues',
    'ĠLe',
    'vi',
    'ĠRe',
    'venue',
    '14',
    '7',
    'Ġv',
    'apor',
    'Ġfortun',
    'ate',
    'Ġthreat',
    'ens',
    'Ġve',
    't',
    'Ġdepend',
    'ency',
    'ers',
    'ed',
    'art',
    'icle',
    'ĠBl',
    'izzard',
    'Ġch',
    'lor',
    'Ġmin',
    'us',
    'ĠB',
    'ills',
    'Ġcryptoc',
    'urrency',
    'Ġmetabol',
    'ism',
    'ter',
    'ing',
    'Ġp',
    'estic',
    'step',
    's',
    'ĠTre',
    'asure',
    'ract',
    'ed',
    'ĠConst',
    'ant',
    'Ġtem',
    'p',
    '13',
    '9',
    'ĠDet',
    'ective',
    'ur',
    'ally',
    'Ġrecover',
    'ing',
    'Ġcort',
    'ex',
    'Ġ14',
    '4',
    'cl',
    'osed',
    'Ġprejud',
    'ice',
    'aun',
    'ted',
    'Ġstorm',
    's',
    'ĠN',
    'OW',
    'Ġmach',
    'inery',
    'Add',
    'ress',
    'Ġcompe',
    'lled',
    '27',
    '0',
    'Ġdesp',
    'air',
    'b',
    'ane',
    'Ġveget',
    'able',
    'Ġbed',
    's',
    'Lear',
    'n',
    'Ġcolor',
    'ful',
    'Ġsp',
    'ike',
    'Ġmarg',
    'ins',
    'Ġsymp',
    'athy',
    'Ġworks',
    'hop',
    'ĠC',
    'BC',
    'S',
    'at',
    'Ġburn',
    's',
    'ĠG',
    'ender',
    'Ġ12',
    '9',
    'ĠC',
    'able',
    'Ġdeb',
    'ts',
    'ĠThe',
    'resa',
    'Ġreflect',
    'ing',
    'Ġa',
    'irst',
    'Ġr',
    'im',
    'ram',
    'id',
    'Ġweakness',
    'es',
    'W',
    'rit',
    'ogg',
    'le',
    't',
    'i',
    'ĠCh',
    'arge',
    'Ġwe',
    'ighed',
    'Ġ(',
    '.',
    'Ġl',
    'aughter',
    'Ġrou',
    'ter',
    'ĠDemocr',
    'acy',
    'D',
    'ear',
    'Ġhas',
    'ht',
    'Ġd',
    'y',
    'Ġhint',
    's',
    'run',
    'ning',
    'Ġfin',
    'ishes',
    'ar',
    'us',
    'M',
    'ass',
    'res',
    'ult',
    'asc',
    'us',
    'Ġv',
    'intage',
    'Ġcon',
    'qu',
    'Ġwild',
    'ly',
    'ac',
    'ist',
    'Ġl',
    'ingu',
    'Ġprot',
    'agonist',
    'st',
    'rom',
    'te',
    'enth',
    'ĠSol',
    'o',
    'm',
    'ac',
    'f',
    'illed',
    'Ġre',
    'nown',
    'it',
    'ives',
    'Ġmot',
    'ive',
    'ĠAnt',
    'ar',
    'ĠM',
    'ann',
    'ĠAd',
    'just',
    'Ġrock',
    'ets',
    'Ġtrou',
    'bling',
    'e',
    'i',
    'Ġorgan',
    'isms',
    'ass',
    'is',
    'Christ',
    'ian',
    'Ġ14',
    '5',
    'ĠH',
    'ass',
    'Ġsw',
    'all',
    'Ġw',
    'ax',
    'ĠSurv',
    'ival',
    'V',
    'S',
    'ĠM',
    'urd',
    'v',
    'd',
    'stand',
    'ard',
    'Ġdrag',
    'ons',
    'Ġacceler',
    'ation',
    'r',
    'ational',
    'f',
    'inal',
    'Ġp',
    'aired',
    'ĠE',
    'thereum',
    'Ġinterf',
    'aces',
    'Ġres',
    'ent',
    'Ġartif',
    'acts',
    'Å',
    '«',
    'are',
    'l',
    'Ġcompet',
    'itor',
    'ĠNich',
    'olas',
    'ĠSur',
    'face',
    'c',
    'pp',
    'ĠT',
    'ot',
    'Ġeconom',
    'ically',
    'Ġorgan',
    'ised',
    'Ġen',
    'forced',
    'in',
    'ho',
    'Ġvar',
    'ieties',
    'Ġab',
    'dom',
    'ĠBa',
    'iley',
    'id',
    'av',
    'ĠSal',
    'v',
    'p',
    'aid',
    'Ġalt',
    'itude',
    'ess',
    'ert',
    'ĠG',
    'utenberg',
    'are',
    'a',
    'op',
    'oulos',
    'Ġprofess',
    'ors',
    'igg',
    's',
    'ĠF',
    'ate',
    'he',
    'y',
    'Ġ3',
    '000',
    'D',
    'ist',
    'Ġtw',
    'ins',
    'c',
    'ill',
    'ĠM',
    'aps',
    'Ġtra',
    'ps',
    'Ġwe',
    'ed',
    'ĠK',
    'iss',
    'Ġy',
    'oga',
    'Ġrecip',
    'ients',
    'ĠWest',
    'minster',
    'Ġpool',
    's',
    'ĠWal',
    'mart',
    '18',
    '8',
    'ĠSchool',
    's',
    'att',
    'ack',
    'ĠAR',
    'M',
    'par',
    'agraph',
    'W',
    'arning',
    'j',
    'l',
    'Ġself',
    'ish',
    'anche',
    'z',
    'ĠHe',
    'ights',
    'F',
    're',
    'ĠS',
    'oph',
    'Ġ',
    '--------------------------------',
    't',
    'ml',
    '33',
    '3',
    'Ġraid',
    's',
    'Ġsatell',
    'ites',
    'KE',
    'Y',
    'Ġlast',
    's',
    'Ñ',
    'Ĥ',
    'In',
    's',
    'ĠD',
    'ame',
    'Ġunp',
    'redict',
    '//',
    '/',
    'gh',
    'ai',
    'Ġart',
    'illery',
    'Ġcru',
    'ise',
    'Ġg',
    'el',
    'ĠCabin',
    'et',
    'Ġbl',
    'ows',
    'ĠE',
    'sp',
    'Ġprox',
    'imity',
    'ot',
    'he',
    'ĠSk',
    'ills',
    'ĠU',
    'pper',
    'ob',
    'o',
    'ĠN',
    'DP',
    'Ġenjoy',
    's',
    'Ġrepe',
    'ating',
    'ĠConst',
    'ruction',
    'ĠQuest',
    'ions',
    'H',
    'illary',
    'Ġu',
    'int',
    'Ġprocess',
    'ors',
    'ĠGib',
    'son',
    'ĠMult',
    'iple',
    'q',
    'a',
    'ĠB',
    'om',
    'ĠM',
    'iles',
    'vent',
    'ional',
    'Ġhur',
    'ts',
    's',
    'kin',
    'ĠA',
    'IDS',
    'Ġadvis',
    'ers',
    'ĠR',
    'oot',
    'Ġmethod',
    'ology',
    'ĠD',
    'ale',
    'Ġdet',
    'on',
    'ĠKnow',
    'ledge',
    'sequ',
    'ently',
    'Ġ12',
    '1',
    'Ġconnect',
    's',
    'C',
    'y',
    'ĠD',
    'anger',
    'Ġcontribut',
    'ors',
    'ĠB',
    'ent',
    'Ġbr',
    'ass',
    'ĠGun',
    's',
    'int',
    'o',
    'ĠFort',
    'une',
    'Ġbro',
    'ker',
    'bal',
    'ance',
    'Ġlength',
    's',
    'Ġv',
    'ic',
    'Ġaver',
    'aging',
    'Ġappropri',
    'ately',
    'ĠCamer',
    'a',
    'Ġsand',
    'wich',
    'ĠCD',
    'C',
    'Ġcoord',
    'inate',
    'Ġnav',
    'ig',
    'Ġgood',
    'ness',
    'l',
    'aim',
    'Ġbra',
    'ke',
    'Ġextrem',
    'ist',
    'ĠW',
    'ake',
    'ĠM',
    'end',
    'ĠT',
    'iny',
    'ĠC',
    'OL',
    'ĠR',
    'F',
    'ĠD',
    'ual',
    'ĠW',
    'ine',
    'C',
    'ase',
    'Ġref',
    'ined',
    'Ġl',
    'amp',
    'L',
    'ead',
    'Ġb',
    'apt',
    'ĠCar',
    'b',
    'ĠS',
    'add',
    'ĠMin',
    'neapolis',
    'PD',
    'F',
    'Ear',
    'ly',
    'ĠH',
    'idden',
    'I',
    'ts',
    'ĠT',
    'IME',
    'Ġp',
    'ap',
    'Ġcommission',
    'ed',
    'ĠF',
    'ew',
    'ĠCol',
    'ts',
    'ĠB',
    'ren',
    'Ġbot',
    'hered',
    'Ġlike',
    'wise',
    'Ex',
    'per',
    'ĠSch',
    'w',
    'c',
    'ry',
    'n',
    'n',
    'ĠM',
    'itch',
    'im',
    'on',
    'M',
    'G',
    'b',
    'm',
    'UM',
    'P',
    'r',
    'ays',
    'Ġregist',
    'ry',
    'Ġ2',
    '70',
    'ach',
    'ine',
    're',
    'lla',
    'ant',
    'ing',
    '00',
    '000',
    'Ġru',
    'ined',
    'sp',
    'ot',
    'Ġt',
    'a',
    'Ġmaxim',
    'ize',
    'Ġincon',
    'ven',
    'D',
    'ead',
    'H',
    'uman',
    'En',
    'abled',
    'ĠMar',
    'ie',
    'Ġch',
    'ill',
    'ĠParad',
    'ise',
    'Ġstar',
    'ring',
    'ĠLat',
    'ino',
    'ĠProt',
    'ocol',
    'ĠE',
    'VER',
    'Ġsuppl',
    'iers',
    'm',
    'essage',
    'ĠBro',
    'ck',
    'Ġser',
    'um',
    'âĸĪâĸĪ',
    'âĸĪâĸĪ',
    'Ġen',
    'comp',
    'Ġamb',
    'ition',
    'ues',
    'e',
    'Ġar',
    'rows',
    'And',
    'rew',
    'Ġanten',
    'na',
    'Ġ19',
    '61',
    'ĠB',
    'ark',
    'Ġb',
    'ool',
    'ãĤ',
    'ª',
    'ĠSt',
    'orage',
    'Ġrail',
    'way',
    'Ġtoug',
    'her',
    'ĠC',
    'ad',
    'Ġwas',
    'hing',
    'P',
    'y',
    "'",
    ']',
    'em',
    'bed',
    'ĠMem',
    'phis',
    'ack',
    'le',
    'Ġfam',
    'ously',
    'ĠF',
    'ortunately',
    'ov',
    'ies',
    'Ġmind',
    'set',
    'Ġsne',
    'ak',
    'ĠD',
    'h',
    'RA',
    'W',
    'ĠSim',
    'pson',
    'Ġliv',
    'est',
    'Ġland',
    'mark',
    'Ġc',
    'ement',
    'L',
    'ow',
    'Ġthr',
    'illed',
    'ĠCour',
    'se',
    'in',
    'el',
    'Ġch',
    'uck',
    'id',
    'ate',
    'gl',
    'obal',
    'Ġwh',
    'it',
    'Ġ',
    'ï¿½',
    'ad',
    'ays',
    's',
    'ki',
    'ĠS',
    'V',
    'Ġvir',
    'uses',
    '30',
    '6',
    'ĠResp',
    'ons',
    'Ġthe',
    'aters',
    'ĠBr',
    'anch',
    'ĠGene',
    'va',
    'ĠM',
    'K',
    'Ġunbel',
    'iev',
    'Ġcommun',
    'ist',
    'Orig',
    'inal',
    'ĠRe',
    'ceived',
    'ĠTrans',
    'fer',
    'ĠAr',
    'g',
    'In',
    'put',
    'ĠStr',
    'ategy',
    'Ġpal',
    'ace',
    'the',
    'ning',
    'D',
    'ri',
    'Ġsent',
    'encing',
    'umbn',
    'ail',
    'Ġp',
    'ins',
    're',
    'cy',
    'Ġs',
    'iblings',
    'Get',
    'ting',
    'ĠB',
    'U',
    'ĠNorth',
    'west',
    'Ġprolong',
    'ed',
    'ĠSak',
    'ura',
    'C',
    'omb',
    'ĠB',
    'our',
    'Ġinadequ',
    'ate',
    'ĠK',
    'ash',
    'Ġus',
    'ername',
    'ĠImpro',
    've',
    'Ġbatt',
    'ling',
    'ĠM',
    'AC',
    'Ġcurric',
    'ulum',
    'Ġs',
    'oda',
    'ĠC',
    'annon',
    'Ġsens',
    'ible',
    'sp',
    'ons',
    'De',
    'cember',
    'Ġw',
    'icked',
    'ĠP',
    'engu',
    'Ġdict',
    'ators',
    'ĠHe',
    'arts',
    'og',
    'yn',
    'Ġsimilar',
    'ities',
    'ĠSt',
    'ats',
    'Ġh',
    'ollow',
    'it',
    'ations',
    '":',
    '[',
    'Ġh',
    'over',
    'ĠList',
    'en',
    's',
    'ch',
    'S',
    'und',
    'Ġc',
    'ad',
    'ĠPar',
    'ks',
    'Ġl',
    'ur',
    'Ġhy',
    'pe',
    'ĠL',
    'em',
    'N',
    'AME',
    'is',
    'ure',
    'Fr',
    'iday',
    'Ġshoot',
    's',
    'Ġclos',
    'es',
    'Ġd',
    'b',
    'ĠR',
    'idge',
    'ĠDiff',
    'erent',
    'Ġrepl',
    'ies',
    'ĠBroad',
    'way',
    'op',
    'ers',
    'Ġint',
    'oler',
    'ĠZe',
    'us',
    'akes',
    'pe',
    'Ġpropri',
    'etary',
    'Ġrequest',
    'ing',
    'Ġcontro',
    'llers',
    'ĠM',
    'IN',
    'im',
    'edia',
    'be',
    'cca',
    'Ġexp',
    'ans',
    'Ġoil',
    's',
    'B',
    'ot',
    'ĠCh',
    'and',
    'Ġpr',
    'inter',
    'Ġto',
    'pped',
    'ĠP',
    'OL',
    'ĠEar',
    'lier',
    'S',
    'ocial',
    'av',
    'in',
    'Ġdecre',
    'ases',
    'ĠSe',
    'b',
    'Ġspecific',
    'ations',
    'ĠBl',
    'ast',
    'ĠK',
    'urt',
    'Ġfre',
    'el',
    'B',
    'rown',
    'Ġdil',
    'ig',
    'ro',
    'e',
    'ĠPro',
    'blem',
    'ĠQu',
    'ad',
    'Ġdecent',
    'ral',
    'ĠV',
    'ector',
    'an',
    'ut',
    'Ġplug',
    'ins',
    'ĠGreg',
    'ory',
    'Ġfuck',
    'ed',
    'el',
    'ines',
    'ĠAmb',
    'assador',
    't',
    'ake',
    'Ġcle',
    'ans',
    'ong',
    'yang',
    'An',
    'onymous',
    'st',
    'ro',
    '"',
    '}',
    'al',
    'ine',
    'ĠO',
    'dd',
    'ĠE',
    'ug',
    '2',
    '16',
    'Ġbo',
    'il',
    'ĠP',
    'owers',
    'Ġnurs',
    'es',
    'Ob',
    'viously',
    'ĠTechn',
    'ical',
    'Ġexceed',
    'ed',
    'OR',
    'S',
    'Ġextrem',
    'ists',
    'Ġtr',
    'aces',
    'ex',
    'pl',
    'Ġcom',
    'r',
    'ĠS',
    'ach',
    ')',
    '/',
    'Ġm',
    'asks',
    'Ġsc',
    'i',
    'B',
    'on',
    'Ġreg',
    'ression',
    'we',
    'gian',
    'Ġadvis',
    'or',
    'it',
    'ures',
    'ĠV',
    'o',
    'ex',
    'ample',
    'ĠInst',
    'ruct',
    'Ġs',
    'iege',
    'Ġredu',
    'ctions',
    'pt',
    'r',
    'Ġstat',
    'utory',
    'Ġrem',
    'oves',
    'Ġp',
    'uck',
    'red',
    'its',
    'Ġbe',
    'e',
    'Ġsal',
    'ad',
    'Ġpromot',
    'ions',
    'ĠJosh',
    'ua',
    'with',
    'standing',
    'ET',
    'H',
    'ĠCh',
    'a',
    'im',
    'us',
    'Ġexpend',
    'iture',
    'aun',
    'ting',
    'Ġdelight',
    'ed',
    'Ġ15',
    '5',
    'be',
    'h',
    'Ġcar',
    'pet',
    'ĠSp',
    'art',
    'Ġj',
    'ungle',
    'l',
    'ists',
    'Ġbull',
    'ying',
    'ĠNob',
    'el',
    'ĠGl',
    'en',
    'Ġreferen',
    'ced',
    'Ġintrodu',
    'ces',
    'se',
    'in',
    'Ġcho',
    'pped',
    'gl',
    'ass',
    'ĠW',
    'rest',
    'Ġneutral',
    'ity',
    'Ġâ',
    'Ļ',
    'Ġinvestig',
    'ator',
    'Ġshel',
    'ves',
    'Ġun',
    'constitutional',
    'Ġreprodu',
    'ction',
    'Ġmer',
    'chant',
    'm',
    'ia',
    'Ġmet',
    'rics',
    'Ġexplos',
    'ives',
    'ĠSon',
    'ia',
    'Ġbod',
    'ily',
    'Ġthick',
    'ness',
    'Ġpredomin',
    'antly',
    'ĠAb',
    'ility',
    'Ġmon',
    'itored',
    'IC',
    'H',
    'Ġ]',
    '.',
    'ĠMart',
    'inez',
    'Ġvis',
    'ibility',
    'Ġqu',
    'eries',
    'Ġgen',
    'ocide',
    'ĠWar',
    'fare',
    'Qu',
    'ery',
    'Ġstud',
    'ios',
    'Ġemb',
    'ry',
    'Ġcorrid',
    'or',
    'Ġclean',
    'ed',
    'com',
    'plete',
    'ĠM',
    'H',
    'Ġenroll',
    'ment',
    'ING',
    'S',
    'Ġimpact',
    'ed',
    'Ġdis',
    'astrous',
    'ĠY',
    'un',
    'ĠCl',
    'aire',
    'ĠBas',
    'ically',
    'y',
    't',
    'uster',
    'ity',
    'Ġindirect',
    'ly',
    'w',
    'ik',
    'Ġd',
    'od',
    'ĠCar',
    'r',
    'Ġam',
    'p',
    'Ġprohib',
    'it',
    'ĠIn',
    'itial',
    'ĠR',
    'd',
    'ij',
    'i',
    'Ġeduc',
    'ate',
    'c',
    'orn',
    'i',
    'ott',
    'ĠBeaut',
    'y',
    'Ġdetect',
    'ive',
    'ĠCon',
    'n',
    's',
    'ince',
    'Ġst',
    'agger',
    'Ġob',
    'ese',
    'Ġb',
    'ree',
    'olog',
    'ic',
    'is',
    'se',
    'walk',
    'er',
    'Ġbl',
    'ades',
    'Ġlaw',
    'ful',
    'fun',
    'c',
    'ĠBeh',
    'ind',
    'Ġappet',
    'ite',
    'Ġ(',
    '*',
    'Ġt',
    'ennis',
    'Ġoff',
    'spring',
    'Ġj',
    'ets',
    'Ġstruct',
    'ured',
    'Ġafore',
    'mentioned',
    'N',
    'ov',
    'Ġsc',
    'aling',
    'f',
    'ill',
    'Ġst',
    'ew',
    'Ġcur',
    'b',
    'ĠStep',
    'han',
    'ed',
    'In',
    'S',
    'F',
    'ob',
    'ic',
    'é',
    'ŃĶ',
    'ou',
    'g',
    'ĠM',
    'M',
    'Ġgen',
    'etically',
    'ope',
    'z',
    '13',
    '6',
    'Ġu',
    'mb',
    'anc',
    'ers',
    'Ġcoh',
    'ort',
    'Ġmerch',
    'andise',
    'Ġimp',
    'osing',
    'ĠLegisl',
    'ature',
    'ĠArch',
    'ive',
    'iv',
    'ia',
    'ĠN',
    'aval',
    'Ġoff',
    'ences',
    'Ġmir',
    'acle',
    'Ġsn',
    'apped',
    'Ġf',
    'oes',
    'Ġextensive',
    'ly',
    'ĠR',
    'af',
    'Ġc',
    'ater',
    'ed',
    'ience',
    'K',
    'it',
    'ĠB',
    'in',
    'Ġrecomm',
    'ends',
    'ĠC',
    'ities',
    'Ġrig',
    'id',
    'ĠRE',
    'AD',
    'ĠNob',
    'le',
    'ĠT',
    'ian',
    'Ġcertific',
    'ates',
    'ant',
    'is',
    'o',
    'iler',
    'ĠBudd',
    'hist',
    'd',
    'id',
    'Ġsurvey',
    'ed',
    'Ġdown',
    'ward',
    'Ġprint',
    's',
    'ĠMot',
    'ion',
    'ron',
    'ics',
    'ĠS',
    'ans',
    'oss',
    'ibly',
    'u',
    'ctions',
    'Ġcolon',
    'ies',
    'ĠDan',
    'ish',
    'un',
    'it',
    'Ġsp',
    'oil',
    'Ġadvis',
    'ory',
    'ber',
    'ries',
    'Pl',
    'an',
    'Ġspecific',
    'ation',
    'op',
    'hers',
    'ĠRes',
    'ource',
    'Ġsh',
    'irts',
    'prising',
    'ly',
    'commun',
    'ications',
    'Ġtriv',
    'ial',
    'Ġmention',
    'ing',
    'ise',
    'xual',
    'Ġsupp',
    'lements',
    'Ġsuper',
    'vision',
    'B',
    'P',
    'v',
    'or',
    'Ġw',
    'it',
    'Ġco',
    'oldown',
    'Ġplaint',
    'iff',
    'ĠReview',
    's',
    'ĠS',
    'ri',
    'ĠM',
    'int',
    'ĠSug',
    'ar',
    'Ġafter',
    'ward',
    'ĠPri',
    'est',
    'ĠInvest',
    'ment',
    'og',
    'ene',
    'ĠT',
    'aking',
    'Ġstretch',
    'ing',
    'Ġinflamm',
    'ation',
    'ĠTe',
    'hran',
    'Ġl',
    'ining',
    'Ġfree',
    'zing',
    'ĠEnt',
    'ity',
    'Ġins',
    'piring',
    'spe',
    'cial',
    'pr',
    'ice',
    'Ġsu',
    'e',
    'ĠP',
    'orter',
    'oun',
    'ge',
    'ET',
    'A',
    'ĠD',
    'erek',
    'ĠLu',
    'is',
    'u',
    'o',
    'ym',
    'ph',
    'Ġex',
    'terior',
    'ih',
    'il',
    'ĠAsh',
    'ley',
    'in',
    'ator',
    'Ġnut',
    'rients',
    'ĠTh',
    'rones',
    'Ġfin',
    'ances',
    'ĠIn',
    'spect',
    'Ġspe',
    'cially',
    'ĠRequ',
    'ired',
    'ĠP',
    'TS',
    'ĠViol',
    'ence',
    'oint',
    'ed',
    'sh',
    'ots',
    'Ġex',
    'cerpt',
    'co',
    'on',
    'IN',
    'S',
    'ĠG',
    'ri',
    'Ġrecogn',
    'ised',
    'We',
    'ek',
    'You',
    'ng',
    'Ġv',
    'om',
    'is',
    'le',
    'ĠCur',
    'ry',
    'ĠBudd',
    'h',
    'Ġnot',
    'ebook',
    'Ġd',
    'urable',
    '/',
    '?',
    'ĠG',
    'ad',
    'ĠP',
    'upp',
    'Ġforg',
    'ive',
    'p',
    'ark',
    'Ġpersonal',
    'ities',
    'an',
    'alysis',
    'cl',
    'amation',
    'Ġelev',
    'ator',
    'Ġware',
    'house',
    'ĠR',
    'ole',
    'un',
    'n',
    'Ġillust',
    'ration',
    'ĠSc',
    'an',
    'Ġatmosp',
    'heric',
    'Im',
    'port',
    'AN',
    'C',
    'rict',
    'ed',
    'f',
    'u',
    '01',
    '0',
    'Ġar',
    'che',
    'Ġreward',
    'ed',
    'akespe',
    'are',
    'Ġintern',
    'ally',
    'ĠR',
    'BI',
    'alk',
    'er',
    'Ġeleph',
    'ant',
    'ow',
    'itz',
    'ĠP',
    'izza',
    'Ġbip',
    'artisan',
    'Ã©',
    's',
    'Ġslow',
    'ed',
    'ĠSt',
    'ark',
    'Ġover',
    'ride',
    'OU',
    'S',
    'Ġ3',
    '20',
    'undred',
    's',
    'ĠDe',
    'ck',
    'ĠC',
    'ensus',
    'be',
    'e',
    '14',
    '6',
    'ot',
    'or',
    'Ġ',
    'ip',
    'Ġu',
    'b',
    'oc',
    'ations',
    'ĠBut',
    'ton',
    'r',
    'ice',
    'Ġc',
    'ripp',
    'ff',
    'f',
    'Ġorig',
    'inated',
    'Ġoverwhel',
    'med',
    'app',
    'a',
    'Ġfore',
    'most',
    'âĢ',
    'ĳ',
    'ĠL',
    'EG',
    're',
    'lease',
    'eat',
    'ured',
    'at',
    'ches',
    'Ġre',
    'ps',
    'Ġl',
    'ending',
    'ĠRe',
    'ference',
    'ĠCl',
    'ient',
    '16',
    '5',
    'vent',
    'h',
    'Com',
    'plete',
    'ĠPat',
    'rol',
    'Ġsw',
    'orn',
    'c',
    'am',
    'Ġshut',
    'tle',
    'ĠR',
    'alph',
    'Ġh',
    'ometown',
    '-',
    ',',
    'on',
    'al',
    'ĠB',
    'P',
    'å',
    'ı',
    'Ġpersu',
    'ade',
    'ĠAlex',
    'and',
    'Ġcomb',
    'ines',
    'Ġv',
    'ivid',
    'ĠL',
    'ag',
    'Ġenc',
    'oding',
    'Ġsal',
    'vation',
    'w',
    'en',
    'ĠRec',
    'overy',
    'i',
    'ya',
    'Un',
    'iversity',
    'ĠB',
    'iden',
    'Ġbud',
    'gets',
    'ĠTex',
    'ans',
    'f',
    'its',
    'Ġhon',
    'ored',
    'Ġp',
    'ython',
    'T',
    'D',
    '##',
    '#',
    'cl',
    'one',
    'Ġbl',
    'ink',
    'ĠL',
    'iquid',
    'Ġunemploy',
    'ed',
    'Ġcl',
    'ashes',
    'ĠCoun',
    'sel',
    'Ġdirect',
    'ing',
    'Ġpun',
    'ct',
    'ĠFal',
    'cons',
    'Ġsh',
    'ark',
    'ĠDam',
    'ascus',
    'Ġje',
    'ans',
    'Ġemb',
    'ark',
    'Ġse',
    'ize',
    'Ġup',
    'wards',
    '2',
    '80',
    'ĠE',
    'z',
    'ĠAny',
    'thing',
    'Ġex',
    'otic',
    'l',
    'ower',
    'ĠCreat',
    'or',
    'ĠU',
    'm',
    'Ġsubur',
    'bs',
    'ber',
    'ger',
    'ĠW',
    'end',
    'Ġm',
    'int',
    'ĠX',
    'X',
    'ĠD',
    'ro',
    'Ġsuff',
    'ers',
    'Ġher',
    'b',
    't',
    'ree',
    'Ġfrag',
    'ile',
    'Ġflood',
    'ed',
    'ĠAl',
    'cohol',
    'ole',
    'an',
    'ny',
    'der',
    'ĠK',
    'O',
    'F',
    'ram',
    'Ġ13',
    '6',
    'Ġow',
    'ed',
    'ĠMe',
    'lee',
    'ĠH',
    'ash',
    'Ġwh',
    'isk',
    'Ġsu',
    'do',
    'r',
    'r',
    'Qu',
    'ick',
    'app',
    'ro',
    'Ġi',
    'i',
    'ĠEx',
    'amples',
    'he',
    'e',
    'Ġpromot',
    'es',
    'per',
    'ature',
    'k',
    'ar',
    'ĠHon',
    'or',
    'Ġs',
    'odium',
    'ĠL',
    'if',
    'ros',
    'so',
    'intend',
    'ent',
    'Ġcorrespond',
    'ent',
    'F',
    'ound',
    'sec',
    'ret',
    'Ġident',
    'ifies',
    'ag',
    'ne',
    'Ġl',
    'ou',
    'ĠP',
    'P',
    'Ġcoinc',
    'idence',
    'm',
    'ove',
    'Ġmilit',
    'ia',
    'Ġinf',
    'iltr',
    'ĠPrim',
    'ary',
    'Ġpitch',
    'ing',
    'ĠI',
    'b',
    'ĠGO',
    'OD',
    'ãĤ',
    '¸',
    'ĠW',
    'izards',
    'ir',
    'al',
    'ĠVen',
    'us',
    'R',
    'R',
    'ĠâĢ',
    'ķ',
    'ĠCase',
    'y',
    'Ġsad',
    'ly',
    'Ġadm',
    'ire',
    'Ġembarrass',
    'ed',
    'c',
    'b',
    'M',
    'el',
    'Ġtub',
    'es',
    'Ġbeaut',
    'ifully',
    'ĠQueens',
    'land',
    'Bel',
    'ow',
    're',
    'z',
    'qu',
    'et',
    'ple',
    'asant',
    'ĠÂ',
    '«',
    'C',
    'amp',
    'Ġdec',
    'isive',
    '19',
    '98',
    'ĠL',
    'amb',
    'ut',
    'ton',
    'h',
    'n',
    'ĠJ',
    'agu',
    'au',
    'nder',
    'ĠC',
    'ord',
    'Ġcl',
    'erk',
    'Ġca',
    'ffe',
    'Ġwip',
    'ed',
    'Ġre',
    'im',
    'ĠMount',
    'ains',
    'Ġimprison',
    'ed',
    'Ġdevelop',
    's',
    'ĠP',
    'ra',
    'Ġmodel',
    'ing',
    'Any',
    'one',
    'ance',
    'l',
    'ĠS',
    'it',
    'Ġshield',
    's',
    'Ġl',
    'awn',
    'Ġcard',
    'iovascular',
    'Ġdemonstr',
    'ating',
    'Ġpar',
    'se',
    'ĠIsrael',
    'is',
    'Ġeuro',
    's',
    '14',
    '3',
    'Ġgl',
    'orious',
    'ins',
    'ki',
    'ec',
    'd',
    'Ġcondition',
    'ing',
    'Ġhel',
    'pless',
    'Ġmicro',
    'sc',
    'ĠHar',
    'bor',
    'Ġst',
    'akes',
    'Ġ2',
    '60',
    'Ġun',
    'equ',
    'ĠFl',
    'oyd',
    'Ġd',
    'amp',
    'Ġappar',
    'atus',
    'ĠLaw',
    's',
    'Ġcoun',
    'ters',
    'Ġindu',
    'ce',
    'at',
    'able',
    'ĠAh',
    'med',
    'Ġsl',
    'am',
    'N',
    'ovember',
    'Ġpers',
    'ist',
    'Ġim',
    'minent',
    'Ã¡',
    'n',
    'Ġsh',
    'red',
    'Ġph',
    'ases',
    'ĠEd',
    'monton',
    'ĠArm',
    'strong',
    'ĠMe',
    'et',
    'ĠK',
    'itty',
    'Ñ',
    'Ģ',
    'c',
    'irc',
    'ĠAd',
    'ult',
    'Ġa',
    'rose',
    'ĠX',
    'en',
    'D',
    'an',
    'g',
    'ow',
    'Ġsuper',
    'f',
    'ĠAd',
    'mir',
    'Ġend',
    'ure',
    'Ġkey',
    'word',
    'yr',
    'us',
    'Ġy',
    'arn',
    'Ġpath',
    'way',
    'ĠHop',
    'kins',
    'mid',
    't',
    'Ġcens',
    'orship',
    'd',
    'ependent',
    'Ġinstruct',
    'or',
    'S',
    'ources',
    'Ġto',
    'e',
    'Ġball',
    'oon',
    'N',
    'ob',
    'Ġsw',
    'ear',
    'ĠCast',
    'ro',
    'Ġgl',
    'oss',
    'ĠK',
    'avanaugh',
    'Ġremark',
    'ably',
    'Ph',
    'otos',
    'ĠN',
    'om',
    'ĠS',
    'outheast',
    'y',
    'ers',
    'Ġvalid',
    'ation',
    'Ġcann',
    'on',
    'ĠVict',
    'ory',
    'ĠPier',
    're',
    'Ġcaut',
    'ious',
    'Aud',
    'io',
    'Ġf',
    'etch',
    'ĠG',
    'ift',
    'ĠH',
    'yp',
    'Ġrem',
    'edy',
    'Z',
    'E',
    'Ġsc',
    'ent',
    'Ġbe',
    'ard',
    'ĠR',
    'ut',
    '-',
    '"',
    'Ġpat',
    'ents',
    'H',
    'y',
    'Ġun',
    'just',
    'Ġpot',
    'ato',
    'Ġforth',
    'coming',
    'Ġche',
    'f',
    'ĠR',
    'ift',
    'aff',
    'e',
    'ĠR',
    'OM',
    'ĠL',
    'aunch',
    'Ġp',
    'ads',
    'ĠNe',
    'o',
    'Ġon',
    'set',
    'Ġsquee',
    'ze',
    's',
    'afe',
    'Ġpref',
    'ix',
    'ĠT',
    'M',
    'ĠN',
    'early',
    'ĠClin',
    'ical',
    'ĠM',
    'ental',
    'ot',
    'iation',
    'ĠUn',
    'ic',
    'ant',
    'ry',
    'ĠC',
    'ir',
    'Ġep',
    'it',
    'Ã',
    '¦',
    'Ġextract',
    'ed',
    'verse',
    'ly',
    'ri',
    'ad',
    'Ġstr',
    'ains',
    'Ġto',
    'ps',
    'Ġpo',
    'em',
    'ĠRand',
    'y',
    'ĠMap',
    'le',
    'TH',
    'ER',
    'up',
    'iter',
    'ĠSS',
    'D',
    'ļ',
    'é',
    'Ġun',
    'con',
    'per',
    'ing',
    'Ġsle',
    'pt',
    'in',
    'ers',
    'Ġunder',
    'water',
    'ĠEv',
    'idence',
    'g',
    'one',
    '20',
    '5',
    'Ġhistor',
    'ians',
    'Ġsynt',
    'hesis',
    'Ġf',
    'rog',
    'b',
    'asketball',
    'Ġvibr',
    'ant',
    'Ġsub',
    'ord',
    'Ġ3',
    '65',
    'ĠD',
    'ial',
    'Ġcooper',
    'ate',
    'HA',
    'HA',
    'Ġgreet',
    'ed',
    '15',
    '8',
    'Ġj',
    'azz',
    'Ġinto',
    'x',
    'ĠWalk',
    'ing',
    'Ġsuper',
    'visor',
    'ĠF',
    'usion',
    'ĠMer',
    'cedes',
    's',
    'end',
    'H',
    'am',
    's',
    'd',
    'n',
    'l',
    'Ġtour',
    's',
    'ĠF',
    'IFA',
    'Ġcul',
    'p',
    'g',
    'd',
    '30',
    '4',
    'Ġple',
    'as',
    'Ġillust',
    'rates',
    'ĠColomb',
    'ia',
    'Ġhighlight',
    'ing',
    'ĠSum',
    'mary',
    'Ġexp',
    'osing',
    'ĠD',
    'ru',
    'Ġir',
    'ony',
    'r',
    'itional',
    'ĠCar',
    'roll',
    'ĠEll',
    'is',
    'P',
    'ict',
    'ĠR',
    'apt',
    'Ġad',
    'apter',
    'Ġun',
    'm',
    'Ġcor',
    'pse',
    'Ġceleb',
    'rities',
    'D',
    'en',
    'at',
    'um',
    'ĠAp',
    'ocalypse',
    'ĠW',
    'ag',
    'lin',
    'ing',
    'Ġhorm',
    'ones',
    'R',
    'ub',
    'ĠX',
    'i',
    'ĠV',
    'aults',
    '20',
    '8',
    'alky',
    'rie',
    'inos',
    'aur',
    'Ġfeed',
    's',
    'v',
    'ity',
    'Ġdefe',
    'ating',
    'W',
    'ait',
    'Ġemphas',
    'ize',
    'ĠSteel',
    'ers',
    'yr',
    'inth',
    'le',
    'ys',
    'ĠWhe',
    'never',
    'Current',
    'ly',
    'ĠCl',
    'ock',
    'Ġcollect',
    'ively',
    'any',
    'on',
    'ĠJ',
    'P',
    'Ġment',
    'ality',
    'Ġdownload',
    's',
    'Ġsurround',
    'ings',
    'ĠBarn',
    'es',
    'Ġflags',
    'hip',
    'Ġindic',
    'ators',
    'Ġgra',
    'pp',
    'Jan',
    'uary',
    'ĠElement',
    'al',
    'ĠAthen',
    'a',
    'ib',
    'al',
    'Ġs',
    'ights',
    'Ġcap',
    'ita',
    'ĠTreat',
    'y',
    'Ġvo',
    'iced',
    'ĠG',
    'az',
    'let',
    'te',
    'Ġy',
    'a',
    'Ġexp',
    'ired',
    'Leg',
    'end',
    'H',
    'ot',
    'n',
    'ature',
    'Ġunst',
    'able',
    'Ġ2',
    '80',
    'Ã',
    'º',
    'Com',
    'ment',
    'AL',
    'E',
    'Ġquest',
    's',
    'Ġhand',
    'ler',
    'n',
    'is',
    'Ġvers',
    'atile',
    'Ġconce',
    'al',
    'enge',
    'ance',
    'ĠInter',
    'active',
    'Ġobs',
    'essed',
    'ĠDog',
    's',
    'Ġcr',
    'acked',
    'S',
    'ound',
    's',
    'v',
    'ĠD',
    'ylan',
    'ro',
    'ads',
    'f',
    'x',
    'ĠCath',
    'olics',
    'ĠH',
    'ag',
    'Ġsl',
    'ammed',
    'Ġgl',
    'owing',
    's',
    'ale',
    'Ġtiss',
    'ues',
    'ĠCh',
    'i',
    'ne',
    'e',
    'Ġc',
    'her',
    's',
    'ic',
    'ur',
    'rection',
    'Ġb',
    'acon',
    'ul',
    'atory',
    ')',
    '."',
    'Ġir',
    'regular',
    'FOR',
    'M',
    'ass',
    'ed',
    'Ġintention',
    'al',
    'Ġcompens',
    'ate',
    'ĠSpe',
    'aking',
    'ĠS',
    'ets',
    '15',
    '3',
    'Ġconvent',
    'ions',
    'b',
    'ands',
    'em',
    'ade',
    'Ġe',
    'cc',
    'ĠWin',
    'ston',
    'ĠAssass',
    'in',
    'ĠBelg',
    'ian',
    'Ġdepend',
    'ence',
    'Ġnic',
    'he',
    'Ġb',
    'ark',
    'ĠJ',
    'azz',
    'Ġdisadvant',
    'age',
    'Ġgas',
    'oline',
    'Ġ16',
    '5',
    'çļ',
    'Ħ',
    'ess',
    'a',
    'mod',
    'ule',
    'ang',
    'ular',
    'O',
    'Y',
    'ĠTreat',
    'ment',
    'it',
    'as',
    'ol',
    'ation',
    'ĠArn',
    'old',
    'Ġfe',
    'ud',
    'ĠN',
    'est',
    'Ġthe',
    'atre',
    'ew',
    'ater',
    'Ġmin',
    'ors',
    'olic',
    'y',
    'ĠH',
    'aven',
    'div',
    'ision',
    'Ġtr',
    'unk',
    'F',
    'ar',
    'ĠP',
    'ull',
    'Ġcapt',
    'uring',
    'Ġ18',
    '00',
    'ĠTe',
    'en',
    'Ġex',
    'empl',
    'Ġclin',
    'ics',
    'ĠB',
    'urg',
    'Ġsubst',
    'it',
    'Ġpay',
    'load',
    'ĠL',
    'av',
    'ĠT',
    'roy',
    'ĠW',
    'itness',
    'Ġfrag',
    'ments',
    'Ġpass',
    'words',
    'Ġg',
    'ospel',
    'ĠG',
    'in',
    'Ġten',
    'ants',
    'ol',
    'ith',
    'S',
    'ix',
    'Pre',
    'vious',
    'ĠAg',
    'es',
    'ĠDar',
    'win',
    'Ġbl',
    'at',
    'Ġem',
    'pathy',
    'sm',
    'ith',
    'b',
    'ag',
    'ĠE',
    'cho',
    'ĠC',
    'amb',
    'ĠM',
    'add',
    'ĠB',
    'oo',
    'Ġred',
    'e',
    'ĠBurn',
    'ing',
    'Ġsmooth',
    'ly',
    'ĠAd',
    'rian',
    'ĠV',
    'ampire',
    'ĠMon',
    'sters',
    'ste',
    'am',
    'Sty',
    'le',
    'M',
    'a',
    're',
    'a',
    'ĠD',
    'war',
    'aly',
    'st',
    'urs',
    'or',
    'Ġelim',
    'ination',
    'Ġcrypt',
    'o',
    'ch',
    't',
    'ĠE',
    'ternal',
    'âĢ¦',
    ']',
    'ĠS',
    'orce',
    'I',
    'll',
    'N',
    'ER',
    'Ġu',
    'h',
    'Con',
    'clusion',
    'w',
    'age',
    'Ġresp',
    'ir',
    'Ġrem',
    'inis',
    'het',
    'ical',
    'Ġg',
    'y',
    'Ġutil',
    'ized',
    'ic',
    'idal',
    'Ġ19',
    '00',
    'Ġhun',
    'ters',
    'ĠSw',
    'an',
    'ĠRe',
    'act',
    'Ġvis',
    'itor',
    'ĠThanks',
    'giving',
    '30',
    '8',
    'Post',
    's',
    'Ġh',
    'ips',
    '19',
    '97',
    'om',
    'ers',
    'Ġkn',
    'ocking',
    'ĠVeh',
    'icle',
    'Ġt',
    'il',
    'Ġ13',
    '8',
    'Ġm',
    'i',
    'ĠInvest',
    'igation',
    'ĠKen',
    'ya',
    'Ġcas',
    'ino',
    'Ġmot',
    'ives',
    'Ġreg',
    'ain',
    're',
    'x',
    'Ġweek',
    'ends',
    'Ġstab',
    'bed',
    'bor',
    'o',
    'Ġexplo',
    'ited',
    'ĠHA',
    'VE',
    'ĠTe',
    'levision',
    'c',
    'ock',
    'Ġprepar',
    'ations',
    'Ġende',
    'av',
    'ĠRem',
    'ote',
    'ĠM',
    'aker',
    'ĠPro',
    'du',
    'ĠEv',
    'an',
    'Ġinform',
    'ational',
    'ĠLouis',
    'ville',
    '15',
    '4',
    'ĠDream',
    's',
    'Ġpl',
    'ots',
    'ĠRun',
    'ner',
    'Ġhur',
    'ting',
    'Ġacad',
    'emy',
    'ĠMont',
    'gomery',
    'n',
    'm',
    'ĠL',
    'anc',
    'ĠAl',
    'z',
    '2',
    '10',
    'el',
    'ong',
    'Ġretail',
    'er',
    'Ġar',
    'ising',
    'Ġrebell',
    'ion',
    'Ġbl',
    'onde',
    'play',
    'ed',
    'Ġinstrument',
    'al',
    'C',
    'ross',
    'Ġret',
    'ention',
    'Ġtherape',
    'utic',
    'Ġse',
    'as',
    'Ġinfant',
    'ry',
    'ĠCl',
    'int',
    'Ġprompt',
    'ing',
    'Ġbit',
    'ch',
    'Ġst',
    'ems',
    'ĠK',
    'ra',
    'Ġthe',
    'sis',
    'ĠB',
    'og',
    'ru',
    'ed',
    'Ġk',
    'ings',
    'Ġcl',
    'ay',
    'ific',
    'ent',
    'ĠY',
    'ES',
    'ĠTh',
    'ing',
    'ĠCub',
    's',
    'vey',
    'ard',
    'els',
    'h',
    'in',
    'arily',
    'ĠE',
    'y',
    'ĠRoll',
    'ing',
    'Ġev',
    'olving',
    'Ind',
    'ia',
    'Ġrecogn',
    'izes',
    'Ġgrad',
    'uation',
    'is',
    'ers',
    'Ġfert',
    'ility',
    'ĠMil',
    'an',
    'Comm',
    'and',
    'Ġbox',
    'ing',
    'Ġ19',
    '43',
    'Ġgl',
    'uten',
    'ĠEm',
    'ir',
    'Ġid',
    'ol',
    'Ġcon',
    'ceived',
    'ĠCre',
    'ation',
    'Mer',
    'it',
    'udd',
    'y',
    'uss',
    'ions',
    'ĠLie',
    'utenant',
    'iet',
    'al',
    'Ġunch',
    'anged',
    'ĠSc',
    'ale',
    'ĠCrime',
    'a',
    'ball',
    's',
    'ator',
    'ial',
    'Ġdepth',
    's',
    'Ġempir',
    'ical',
    'Ġtrans',
    'm',
    'Ġuns',
    'afe',
    'miss',
    'ible',
    'com',
    'fort',
    '15',
    '6',
    'Ġmechan',
    'ic',
    '00',
    '2',
    'l',
    'ins',
    'Ġsm',
    'oked',
    'P',
    'os',
    'Ġslow',
    'ing',
    'Ġl',
    'av',
    'Tex',
    'as',
    'Ġche',
    'ating',
    'ĠMet',
    'ropolitan',
    'eth',
    'yl',
    'Ġdiscover',
    'ing',
    'as',
    'se',
    'Ġpen',
    'cil',
    'ĠPy',
    'ongyang',
    'Ġclos',
    'et',
    'ĠShe',
    'et',
    'ĠEnt',
    'ry',
    'ou',
    'stic',
    'Ġmy',
    'st',
    'er',
    'ate',
    'ari',
    'at',
    'Ġminer',
    'als',
    'Ġmusic',
    'ian',
    'ĠP',
    'ul',
    'ĠM',
    'az',
    '24',
    '9',
    'Ġper',
    'missions',
    'Ġ',
    'iv',
    'en',
    'ary',
    'ick',
    'ers',
    'ĠB',
    'ing',
    'he',
    'a',
    'en',
    'able',
    'Ġgri',
    'ev',
    'Ġassert',
    'ed',
    'ĠColon',
    'el',
    'Ġaff',
    'idav',
    'w',
    'o',
    'Ġse',
    'ated',
    'ĠR',
    'ide',
    'Ġpaint',
    'ings',
    'ĠP',
    'ix',
    'Ġ13',
    '7',
    'ish',
    'i',
    'umb',
    'ai',
    'g',
    'otten',
    'ĠEar',
    'l',
    'Ġin',
    'ning',
    'Ġc',
    'ensus',
    'Ġtrave',
    'lled',
    'ĠCons',
    'ult',
    '18',
    '5',
    'b',
    'ind',
    'Ġsimpl',
    'icity',
    'Ġoverlook',
    'ed',
    'ĠHelp',
    'ful',
    'Ġmon',
    'key',
    'Ġoverwhelming',
    'ly',
    'Bl',
    'ood',
    'ĠFl',
    'int',
    'ĠJ',
    'ama',
    'ĠPres',
    'ent',
    'ĠR',
    'age',
    'ĠT',
    'A',
    'pt',
    'ive',
    'Ġturn',
    'out',
    'w',
    'ald',
    'ĠD',
    'olphins',
    'ĠV',
    'PN',
    'Ġon',
    'ion',
    'Ġcraft',
    'ing',
    'm',
    'ma',
    'ĠMerc',
    'ury',
    'Ġarr',
    'ange',
    'Ġalert',
    's',
    'ĠO',
    'T',
    'zb',
    'ollah',
    'Ġg',
    'ases',
    'ĠRichards',
    'on',
    's',
    'al',
    'l',
    'ar',
    'Ġfro',
    'st',
    'Ġlower',
    'ing',
    'Ġacc',
    'laim',
    'Ġstart',
    'ups',
    'ĠG',
    'ain',
    'ess',
    'ment',
    'Ġguard',
    'ian',
    'äº',
    'º',
    'ĠP',
    'ie',
    'ĠL',
    'inks',
    'Ġmer',
    'its',
    'Ġaw',
    'ake',
    'Ġparent',
    'al',
    'Ġexceed',
    's',
    'Ġid',
    'le',
    'ĠPil',
    'ot',
    'Ġe',
    'Bay',
    'ĠAc',
    'cept',
    'ipe',
    'g',
    'C',
    'am',
    'ĠK',
    'ot',
    'Ġtrad',
    'ers',
    'olit',
    'ics',
    'unk',
    'er',
    'ĠP',
    'ale',
    'os',
    'i',
    'an',
    'mar',
    'Ġ19',
    '47',
    'ĠF',
    'ell',
    'est',
    'ial',
    'it',
    'ating',
    'G',
    'F',
    'ĠS',
    'r',
    'if',
    'ted',
    'Ġconnect',
    'or',
    'ĠB',
    'one',
    'ill',
    'es',
    '2',
    '60',
    'h',
    'ma',
    'Ġoverl',
    'ap',
    'ĠGit',
    'Hub',
    'Ġclean',
    'er',
    'ĠBapt',
    'ist',
    'ĠW',
    'AS',
    'Ġlung',
    's',
    'Ñ',
    'ģ',
    'ĠB',
    'UT',
    'Ġc',
    'ite',
    'Ġpit',
    'ched',
    'reat',
    'ment',
    'Ġtro',
    'phies',
    'ĠN',
    'u',
    '38',
    '6',
    'ĠPr',
    'ide',
    'Ġattend',
    'ees',
    '[',
    ']',
    '17',
    '9',
    'Ġspat',
    'ial',
    'Ġpri',
    'zes',
    'ĠRel',
    'igion',
    'Ġshow',
    'case',
    'ĠC',
    'ategory',
    'vid',
    'ia',
    'T',
    'arget',
    'Pro',
    'perty',
    '?',
    ',',
    'Ġf',
    'usion',
    'p',
    'ie',
    'ĠU',
    'CLA',
    'Ġsound',
    'track',
    'Ġprin',
    'cess',
    'ĠC',
    'aval',
    'sh',
    'ould',
    'Ġlim',
    'bs',
    'Back',
    'ground',
    'Ġlone',
    'ly',
    'Ġc',
    'ores',
    'ĠT',
    'ail',
    'she',
    'et',
    'Ġ13',
    '2',
    'R',
    'a',
    'ãĤ',
    '«',
    'ĠB',
    'olt',
    'Ġbook',
    'ed',
    'Ġadmin',
    'ister',
    'Ġequ',
    'als',
    'w',
    'y',
    'Ġobserv',
    'ing',
    'ĠBar',
    'on',
    'ĠAd',
    'obe',
    'Ġv',
    'irgin',
    'ĠSocial',
    'ist',
    'M',
    'ove',
    'gh',
    'azi',
    'ĠLind',
    'a',
    '2',
    '12',
    'Ġbre',
    'wing',
    'Ġmerch',
    'ants',
    'bur',
    'se',
    'Ġdiv',
    'or',
    'Ġmet',
    'als',
    'ĠN',
    'er',
    'Ġsum',
    's',
    'ĠEn',
    'emy',
    'Ġen',
    'vision',
    'Ġgrant',
    'ing',
    'ĠH',
    'oney',
    'ĠSk',
    'yrim',
    'Ġsoc',
    'io',
    'gr',
    'aded',
    'Ġselect',
    'ive',
    'W',
    'ASHINGTON',
    'Ġ19',
    '48',
    'ĠSir',
    'ius',
    'ĠG',
    'ross',
    'act',
    'ivity',
    'ĠI',
    'van',
    'Ġfur',
    'ious',
    'BS',
    'D',
    'ĠPre',
    'vious',
    'Ġrespons',
    'ive',
    'Ġchar',
    'itable',
    'Ġle',
    'aning',
    'ĠP',
    'ew',
    'Ġviol',
    'ates',
    '\\\\\\\\',
    '\\\\\\\\',
    'ĠCom',
    'ing',
    'w',
    'ire',
    'Ġpo',
    'et',
    'Ġres',
    'olutions',
    'comm',
    'and',
    'ĠPortug',
    'uese',
    'Ġnick',
    'name',
    'Ġde',
    'af',
    'Feb',
    'ruary',
    'Ġrecogn',
    'ise',
    'Ġentire',
    'ty',
    'Ġseason',
    'al',
    'pl',
    'aced',
    'ĠTe',
    'legraph',
    'Ġmicro',
    'phone',
    'our',
    'ing',
    'Ġgr',
    'ains',
    'Ġgovern',
    'ed',
    'Ġpost',
    'p',
    'ĠW',
    'aters',
    'in',
    'ement',
    'Ġund',
    'ocumented',
    'ĠCom',
    'cast',
    'Ġf',
    'ox',
    'Ġassault',
    's',
    're',
    'on',
    'man',
    'y',
    'ĠJen',
    'kins',
    'ĠAny',
    'way',
    'Ġassess',
    'ments',
    'Ġdown',
    's',
    'ĠM',
    'ouse',
    'Ġsuper',
    'b',
    'k',
    't',
    'ĠD',
    'ow',
    'Ġtax',
    'ation',
    '4',
    '01',
    'Ġsm',
    'iles',
    'Ġundert',
    'aken',
    'Ġex',
    'h',
    'Ġenthusi',
    'astic',
    'Ġtw',
    'ent',
    'Ġgovernment',
    'al',
    'Ġautonom',
    'y',
    'ĠTechn',
    'ologies',
    'ĠCh',
    'ain',
    'Ġpreval',
    'ent',
    'f',
    'b',
    'Ġnic',
    'otine',
    'og',
    'ram',
    'j',
    'ob',
    'Ġawa',
    'iting',
    'ĠMen',
    'u',
    'Ġdep',
    'uties',
    'k',
    'ov',
    'ish',
    'ops',
    'But',
    'ton',
    'ĠShan',
    'ghai',
    'Ġdies',
    'el',
    'ĠD',
    'uck',
    'R',
    'yan',
    'ĠPC',
    's',
    'N',
    'F',
    'j',
    'ury',
    'ent',
    'e',
    'Ġinacc',
    'urate',
    'edd',
    'y',
    'Wh',
    'atever',
    'Ġshow',
    'c',
    'ĠN',
    'ad',
    'od',
    'us',
    'et',
    'r',
    'Ġplaint',
    'iffs',
    'ĠW',
    'OR',
    'ĠAss',
    'ange',
    'Ġpriv',
    'at',
    'Ġpremium',
    's',
    'Ġt',
    'am',
    'UR',
    'L',
    'Ġel',
    'ites',
    'ĠR',
    'anger',
    'otten',
    'ham',
    'ĠH',
    'off',
    'ĠAt',
    'hens',
    'Ġdefin',
    'ite',
    'Ġs',
    'ighed',
    'Ġeven',
    'ly',
    '2',
    '11',
    'ĠAm',
    'ber',
    'ak',
    'ia',
    'Ġmail',
    'ing',
    'Ġcr',
    'ashing',
    'ĠConfeder',
    'ate',
    'ru',
    'gged',
    'W',
    'al',
    'ĠDep',
    'ths',
    'Ġjuven',
    'ile',
    'Ġreact',
    'or',
    'Introdu',
    'ction',
    'ĠDel',
    'uxe',
    '19',
    '95',
    'ĠS',
    'anchez',
    'ĠM',
    'ead',
    'iv',
    'able',
    ':',
    '-',
    'ĠPlan',
    'ning',
    'ĠT',
    'rap',
    'qu',
    'in',
    'ĠProt',
    'ect',
    've',
    'red',
    'In',
    'formation',
    'Ġkid',
    'ney',
    'inn',
    'amon',
    'l',
    'as',
    'Ġpolic',
    'ing',
    'Ġtoler',
    'ate',
    'ĠQ',
    'i',
    'Ġbi',
    'ased',
    'F',
    'ort',
    'ĠK',
    'i',
    's',
    'ave',
    'Ġprivile',
    'ged',
    'Ġbe',
    'asts',
    'ĠGl',
    'as',
    'ĠC',
    'inem',
    'Ġcome',
    'back',
    'Sund',
    'ay',
    'Ġext',
    'inction',
    'h',
    'ops',
    'Ġtrans',
    'mit',
    'Ġdoub',
    'les',
    'ĠFl',
    'at',
    '16',
    '7',
    'Ġdis',
    'puted',
    'Ġinjust',
    'ice',
    'f',
    'oo',
    'V',
    'ict',
    'role',
    'um',
    'ĠJul',
    'ie',
    'Con',
    'text',
    'ĠR',
    'arity',
    'iss',
    'ue',
    'Comp',
    'onent',
    'Ġcounsel',
    'ing',
    'an',
    'ne',
    'd',
    'ark',
    'Ġobject',
    'ions',
    'u',
    'ilt',
    'Ġg',
    'ast',
    'Ġpl',
    'ac',
    'Ġun',
    'used',
    'ãĥ',
    'ĩ',
    'ĠT',
    'rial',
    'ĠJ',
    'as',
    'hed',
    'ral',
    'ob',
    'b',
    'Ġtempor',
    'al',
    'ĠPR',
    'O',
    'ĠN',
    'W',
    'ĠAnn',
    'iversary',
    'L',
    'arge',
    'Ġther',
    'm',
    'Ġd',
    'avid',
    'Ġsystem',
    'ic',
    'ĠSh',
    'ir',
    'm',
    'ut',
    'ĠNe',
    'pt',
    'add',
    'ress',
    'Ġscan',
    'ning',
    'Ġunderstand',
    'able',
    'Ġcan',
    'vas',
    'C',
    'at',
    'ĠZ',
    'oo',
    'Ġang',
    'els',
    'L',
    'O',
    'ĠStat',
    'ement',
    'ĠS',
    'ig',
    'ov',
    'able',
    'ĠA',
    'way',
    'sh',
    'aring',
    'ocr',
    'ats',
    'st',
    'ated',
    'Ġweigh',
    'ing',
    'N',
    'or',
    'w',
    'ild',
    'B',
    'ey',
    'Ġaston',
    'ishing',
    'ĠReyn',
    'olds',
    'Ġop',
    'ener',
    'Ġtrain',
    'er',
    'Ġsurg',
    'ical',
    'p',
    'n',
    'Ġadjust',
    'ing',
    'whe',
    'el',
    'Ġf',
    'rown',
    'erv',
    'ative',
    'Ġsusp',
    'end',
    'With',
    'in',
    'te',
    'in',
    'Ġobst',
    'acle',
    'Ġliber',
    'ties',
    'ym',
    'es',
    'Ġur',
    'anium',
    'ans',
    'om',
    'an',
    'ol',
    'ub',
    'a',
    'ĠL',
    'oss',
    'Ġa',
    'rous',
    'ĠHend',
    'erson',
    'W',
    'ow',
    's',
    'pl',
    'c',
    'ur',
    'ĠÂ',
    'Ń',
    'Ġtheir',
    's',
    'Dam',
    'age',
    'Ġdownload',
    'ing',
    'Ġdisc',
    'ern',
    'ĠSt',
    'o',
    'ĠFl',
    'a',
    'Ġh',
    'ath',
    'ĠA',
    'j',
    'Ġun',
    'pleasant',
    'Europe',
    'an',
    'exp',
    'ensive',
    'Ġscreens',
    'hot',
    'ĠU',
    'V',
    'Ġall',
    'ied',
    'ĠPers',
    'ian',
    'Ġmonop',
    'oly',
    'Ġat',
    'om',
    'ĠReds',
    'kins',
    '">',
    '<',
    'Ġcan',
    'cell',
    'Ġcinem',
    'a',
    '13',
    '1',
    'f',
    'air',
    'ĠAlf',
    'red',
    'Ġd',
    'uck',
    'arg',
    's',
    '22',
    '3',
    'ĠIS',
    'I',
    'Ġsign',
    'aling',
    'in',
    'ar',
    'Ġlaugh',
    's',
    'Ġfor',
    'wards',
    'Ġreck',
    'less',
    'Ġlisten',
    'ers',
    'at',
    'ivity',
    'Ġvast',
    'ly',
    'n',
    'ant',
    'L',
    'ess',
    'ĠHun',
    'ting',
    'ĠScient',
    'ific',
    'IT',
    'ED',
    'Ġkn',
    'ight',
    'ĠH',
    'TC',
    'us',
    'a',
    't',
    'mp',
    'Ġr',
    'ude',
    'ĠLegend',
    'ary',
    'Ġar',
    'ises',
    'B',
    'ad',
    'ĠCl',
    'aim',
    'pe',
    'g',
    'Ġreal',
    'ities',
    'Th',
    'ink',
    'ĠÂ',
    '°',
    'Ġro',
    'de',
    'Ġstri',
    've',
    'Ġan',
    'ecd',
    'Ġshort',
    's',
    'Ġhypot',
    'hes',
    'Ġcoord',
    'inated',
    'ĠGand',
    'hi',
    'ĠF',
    'PS',
    'R',
    'ED',
    'Ġsuscept',
    'ible',
    'Ġshr',
    'ink',
    'ĠCh',
    'art',
    'Hel',
    'p',
    'Ġ',
    'ion',
    'de',
    'ep',
    'rib',
    'es',
    'ĠK',
    'ai',
    'ĠCustom',
    'er',
    'Sum',
    'mary',
    'Ġc',
    'ough',
    'w',
    'ife',
    'Ġl',
    'end',
    'Ġposition',
    'ing',
    'Ġlot',
    'tery',
    'ĠC',
    'anyon',
    'Ġf',
    'ade',
    'Ġbron',
    'ze',
    'ĠKenn',
    'y',
    'Ġbo',
    'asts',
    'ĠEnh',
    'anced',
    'rec',
    'ord',
    'Ġemer',
    'gence',
    'Ġa',
    'kin',
    'ĠB',
    'ert',
    'it',
    'ous',
    'âĸ',
    'ĳ',
    'Ġst',
    'ip',
    'Ġexch',
    'anged',
    'om',
    'ore',
    'als',
    'h',
    'Ġreserv',
    'oir',
    'Ġstand',
    'point',
    'W',
    'M',
    'Ġiniti',
    'ate',
    'Ġdec',
    'ay',
    'Ġbrew',
    'ery',
    'Ġter',
    'ribly',
    'Ġmort',
    'al',
    'lev',
    'ard',
    'Ġrev',
    'is',
    'N',
    'I',
    'el',
    'o',
    'Ġconf',
    'ess',
    'ĠMS',
    'NBC',
    'Ġsub',
    'missions',
    'Cont',
    'roller',
    'Ġ20',
    '2',
    'ĠR',
    'uth',
    '}',
    ');',
    'ĠAz',
    'ure',
    'Ġ',
    '."',
    '20',
    '6',
    'ĠMarket',
    'ing',
    'Ġl',
    'aund',
    'ien',
    'cies',
    'Ġrenown',
    'ed',
    'ĠT',
    'rou',
    'ĠN',
    'GO',
    'ble',
    'ms',
    'Ġterr',
    'ified',
    'Ġwar',
    'ns',
    'Ġper',
    't',
    'Ġuns',
    'ure',
    '4',
    '80',
    'ale',
    'z',
    'ult',
    'z',
    'ĠOut',
    'side',
    'Ġst',
    'yl',
    'ĠUnder',
    'ground',
    'Ġp',
    'anc',
    'Ġd',
    'ictionary',
    'Ġf',
    'oe',
    'rim',
    'inal',
    'ĠNor',
    'wegian',
    'Ġj',
    'ailed',
    'Ġm',
    'aternal',
    'Ã©',
    'e',
    'ĠLu',
    'cy',
    'c',
    'op',
    'Ch',
    'o',
    'Ġuns',
    'igned',
    'ĠZe',
    'lda',
    'ĠIns',
    'ider',
    'ĠContin',
    'ued',
    'Ġ13',
    '3',
    'ĠNar',
    'uto',
    'ĠMajor',
    'ity',
    '16',
    '9',
    'ĠW',
    'o',
    'ãĤ',
    'ĵ',
    'Ġpast',
    'or',
    'Ġinform',
    'al',
    'Ð',
    '½',
    'an',
    'throp',
    'jo',
    'in',
    'ãģ',
    'Ĺ',
    'it',
    'ational',
    'N',
    'P',
    'ĠWrit',
    'ing',
    'f',
    'n',
    'ĠB',
    'ever',
    '19',
    '5',
    'Ġy',
    'elling',
    'Ġdr',
    'astically',
    'Ġe',
    'ject',
    'Ġne',
    'ut',
    'Ġth',
    'rive',
    'ĠFre',
    'qu',
    'ou',
    'x',
    'Ġpossess',
    'es',
    'ĠSen',
    'ators',
    'ĠD',
    'ES',
    'ĠSh',
    'akespeare',
    'ĠFran',
    'co',
    'ĠL',
    'B',
    'uch',
    'i',
    'Ġinc',
    'arn',
    'Ġfound',
    'ers',
    'F',
    'unction',
    'Ġbright',
    'ness',
    'ĠB',
    'T',
    'Ġwh',
    'ale',
    'ĠThe',
    'ater',
    'm',
    'ass',
    'ĠD',
    'oll',
    'S',
    'omething',
    'Ġecho',
    'ed',
    'ĠHe',
    'x',
    'c',
    'rit',
    'af',
    'ia',
    'Ġgodd',
    'ess',
    'Ġele',
    'ven',
    'ĠPre',
    'view',
    'ĠAur',
    'ora',
    'Ġ4',
    '01',
    'uls',
    'ive',
    'ĠLog',
    'an',
    'in',
    'burgh',
    'ĠCent',
    'ers',
    'ĠON',
    'LY',
    'ĠA',
    'id',
    'Ġparad',
    'ox',
    'Ġh',
    'urd',
    'ĠL',
    'C',
    'D',
    'ue',
    'c',
    'ourt',
    'Ġoff',
    'ended',
    'Ġeval',
    'uating',
    'ĠMatthew',
    's',
    'Ġto',
    'mb',
    'Ġpay',
    'roll',
    'Ġextra',
    'ction',
    'ĠH',
    'ands',
    'if',
    'i',
    'Ġsuper',
    'natural',
    'ĠCOM',
    'M',
    ']',
    '=',
    'dog',
    's',
    'Ġ5',
    '12',
    'ĠMe',
    'eting',
    'Rich',
    'ard',
    'ĠMax',
    'imum',
    'Ġide',
    'als',
    'Th',
    'ings',
    'm',
    'and',
    'ĠReg',
    'ardless',
    'Ġhum',
    'ili',
    'b',
    'uffer',
    'L',
    'ittle',
    'ĠD',
    'ani',
    'ĠN',
    'ak',
    'Ġliber',
    'ation',
    'ĠA',
    'be',
    'ĠO',
    'L',
    'Ġstuff',
    'ed',
    'ac',
    'a',
    'ind',
    'a',
    'raph',
    'ic',
    'Ġmos',
    'qu',
    'Ġcampaign',
    'ing',
    'Ġoccup',
    'y',
    'S',
    'qu',
    'r',
    'ina',
    'ĠW',
    'el',
    'ĠV',
    'S',
    'Ġphys',
    'ic',
    'Ġp',
    'uls',
    'r',
    'int',
    'oad',
    'ed',
    'ET',
    'F',
    'ĠArch',
    'ives',
    'Ġven',
    'ues',
    'h',
    'ner',
    'ĠTur',
    'bo',
    'Ġl',
    'ust',
    'Ġappeal',
    'ed',
    'que',
    'z',
    'il',
    'ib',
    'ĠTim',
    'othy',
    'Ġo',
    'mn',
    'd',
    'ro',
    'Ġobs',
    'ession',
    'ĠSav',
    'age',
    '19',
    '96',
    'Gl',
    'obal',
    'J',
    'es',
    '2',
    '14',
    'Ġsl',
    'iding',
    'Ġdisapp',
    'ro',
    'ĠMag',
    'ical',
    'Ġvolunt',
    'arily',
    'g',
    'b',
    'ane',
    'y',
    'Ġprop',
    'het',
    'ĠRe',
    'in',
    'ĠJul',
    'ia',
    'ĠW',
    'orth',
    'aur',
    'us',
    'Ġb',
    'ounds',
    'ie',
    'u',
    '))',
    ')',
    'Ġcro',
    're',
    'ĠCitiz',
    'en',
    'S',
    'ky',
    'Ġcolumn',
    'ist',
    'Ġseek',
    'ers',
    'ond',
    'o',
    'IS',
    'A',
    'ĠL',
    'ength',
    'Ġnost',
    'alg',
    'Ġnew',
    'com',
    'Ġdet',
    'rim',
    'ent',
    'ric',
    '3',
    '75',
    'ĠG',
    'E',
    'Ġaut',
    'op',
    'Ġacadem',
    'ics',
    'App',
    'Data',
    'ĠS',
    'hen',
    'Ġid',
    'iot',
    'ĠTrans',
    'it',
    'Ġteasp',
    'oon',
    'W',
    'il',
    'K',
    'O',
    'ĠCom',
    'edy',
    '>',
    ',',
    'Ġpop',
    'ulated',
    'W',
    'D',
    'Ġp',
    'igs',
    'ĠO',
    'culus',
    'Ġsymp',
    'athetic',
    'Ġmar',
    'athon',
    '19',
    '8',
    'Ġseiz',
    'ure',
    's',
    'ided',
    'Ġd',
    'op',
    'irt',
    'ual',
    'L',
    'and',
    'ĠFl',
    'oor',
    'osa',
    'urs',
    '...',
    ']',
    'Ġl',
    'os',
    'Ġsubsid',
    'iary',
    'E',
    'Y',
    'ĠPart',
    's',
    'ĠSt',
    'ef',
    'ĠJud',
    'iciary',
    'Ġ13',
    '4',
    'Ġmir',
    'rors',
    'Ġk',
    'et',
    't',
    'imes',
    'Ġneuro',
    'log',
    'Ġc',
    'av',
    'ĠGu',
    'est',
    'Ġtum',
    'or',
    'sc',
    'ill',
    'ĠLl',
    'oyd',
    'E',
    'st',
    'Ġcle',
    'arer',
    'Ġstere',
    'otypes',
    'Ġd',
    'ur',
    'not',
    'hing',
    'Red',
    'dit',
    'Ġnegoti',
    'ated',
    '----------------',
    '--------',
    '23',
    '5',
    'Ġfl',
    'own',
    'ĠSe',
    'oul',
    'ĠRes',
    'ident',
    'ĠS',
    'CH',
    'Ġdisappear',
    'ance',
    'ĠV',
    'ince',
    'g',
    'rown',
    'Ġgrab',
    's',
    'r',
    'il',
    'ĠInf',
    'inite',
    'ĠTw',
    'enty',
    'Ġpedest',
    'rian',
    'Ġjer',
    'sey',
    'ĠF',
    'ur',
    'ĠInf',
    'inity',
    'ĠEll',
    'iott',
    'Ġment',
    'or',
    'Ġmor',
    'ally',
    'Ġob',
    'ey',
    'sec',
    'ure',
    'iff',
    'e',
    'Ġantib',
    'iotics',
    'ang',
    'led',
    'ĠFre',
    'eman',
    'ĠIntrodu',
    'ction',
    'J',
    'un',
    'Ġm',
    'arsh',
    'ic',
    'ans',
    'ĠEV',
    'ENTS',
    'och',
    'ond',
    'W',
    'all',
    'icult',
    'y',
    'Ġmisdem',
    'eanor',
    'Ġl',
    'y',
    'Th',
    'omas',
    'ĠRes',
    'olution',
    'Ġanim',
    'ations',
    'ĠD',
    'ry',
    'Ġinter',
    'course',
    'ĠNew',
    'castle',
    'ĠH',
    'og',
    'ĠEqu',
    'ipment',
    '17',
    '7',
    'Ġterrit',
    'orial',
    'Ġarch',
    'ives',
    '20',
    '3',
    'Fil',
    'ter',
    'ĠMun',
    'ich',
    'Ġcommand',
    'ed',
    'ĠW',
    'and',
    'Ġpit',
    'ches',
    'ĠCro',
    'at',
    'Ġrat',
    'ios',
    'ĠM',
    'its',
    'Ġaccum',
    'ulated',
    'ĠSpecific',
    'ally',
    'Ġgentle',
    'man',
    'acer',
    'b',
    'Ġp',
    'enn',
    'Ġa',
    'ka',
    'ĠF',
    'uk',
    'Ġinterven',
    'e',
    'ĠRef',
    'uge',
    'ĠAlz',
    'heimer',
    'Ġsuccess',
    'ion',
    'oh',
    'an',
    'd',
    'oes',
    'L',
    'ord',
    'Ġsepar',
    'at',
    'Ġcorrespond',
    'ence',
    'Ġsh',
    'iny',
    'P',
    'rior',
    'Ġs',
    'ulf',
    'Ġmiser',
    'able',
    'Ġded',
    'ication',
    '(',
    ').',
    'Ġspecial',
    'ists',
    'Ġdefect',
    's',
    'ĠC',
    'ult',
    'ĠX',
    'ia',
    'Ġje',
    'opard',
    'ĠO',
    're',
    'Ab',
    'ility',
    'Ġle',
    'ar',
    'Ġamb',
    'itions',
    'ĠB',
    'MI',
    'ĠArab',
    's',
    'Ġ19',
    '42',
    'Ġpres',
    'ervation',
    'ific',
    'ate',
    'Ġash',
    'amed',
    'l',
    'oss',
    'ĠRest',
    'aur',
    'Ġrese',
    'mble',
    'Ġen',
    'rich',
    'ĠK',
    'N',
    'ĠCl',
    'an',
    'fl',
    'oat',
    'Ġplay',
    'able',
    'IT',
    'T',
    'Ġharm',
    'ony',
    'arr',
    'ison',
    'ĠWe',
    'instein',
    'w',
    'ere',
    'Ġpoison',
    'ing',
    'ĠCom',
    'put',
    'ĠWord',
    'Press',
    'm',
    'ajor',
    'ĠVal',
    've',
    'F',
    'an',
    'ĠTh',
    'row',
    'ĠRom',
    'ans',
    'ĠDep',
    'ression',
    'ad',
    'os',
    'Ġtort',
    'ured',
    'Ġbal',
    'ancing',
    'bott',
    'om',
    'Ġacqu',
    'iring',
    'ĠMon',
    'te',
    'ard',
    'i',
    'Ġa',
    'ura',
    'Ġ#',
    '#',
    'ĠStand',
    'ing',
    'ĠAtl',
    'as',
    'C',
    'F',
    'Ġintr',
    'ins',
    'ĠBen',
    'ghazi',
    'Ġcamp',
    'ing',
    'Ġt',
    'apped',
    'bl',
    'ade',
    'st',
    'rous',
    'ĠR',
    'abb',
    'ĠW',
    'ritten',
    't',
    'ip',
    'ĠNe',
    'igh',
    'ster',
    'dam',
    'ĠAll',
    'ow',
    'ĠHe',
    'aling',
    'ĠR',
    'hod',
    'n',
    'um',
    'Ġcaffe',
    'ine',
    'ĠPer',
    'cent',
    'Ġbo',
    'o',
    'Ġapp',
    'les',
    '30',
    '5',
    'Ġwel',
    'coming',
    'Ġappl',
    'aud',
    'Ġa',
    'usterity',
    'Â',
    '±',
    'ĠRe',
    'ality',
    'ef',
    'e',
    'å',
    '®',
    'Ġsu',
    'cks',
    'Ġtab',
    's',
    'ĠPay',
    'Pal',
    'Ġback',
    'pack',
    'Ġgif',
    'ted',
    'abul',
    'ary',
    'ĠSc',
    'out',
    'ir',
    'teen',
    'Ġch',
    'in',
    'Ġo',
    'mitted',
    'Ġnegative',
    'ly',
    'Ġaccess',
    'ing',
    'ĠE',
    'arn',
    'Ġambul',
    'ance',
    'Ġhead',
    'phones',
    'Ġ20',
    '5',
    'ĠRef',
    'resh',
    'p',
    'resident',
    'ĠKit',
    'chen',
    'ĠEnt',
    'ered',
    'ĠS',
    'nyder',
    '00',
    '5',
    'om',
    'ical',
    'Ġborrow',
    'ed',
    'ĠN',
    'em',
    'Ġav',
    'iation',
    'Ġst',
    'all',
    'rim',
    'ination',
    'Ġuniform',
    's',
    'it',
    'ime',
    'ĠSim',
    'mons',
    'ener',
    'gy',
    'ab',
    'lished',
    'y',
    'y',
    'qual',
    'ified',
    'Ġrall',
    'ies',
    'ĠSt',
    'uart',
    'fl',
    'ight',
    'Ġgang',
    's',
    'r',
    'ag',
    'Ġv',
    'ault',
    'lu',
    'x',
    'ĠCom',
    'par',
    'Ġdesign',
    'ation',
    '20',
    '9',
    'ĠJ',
    'os',
    'd',
    'ollar',
    'z',
    'ero',
    'Ġwell',
    's',
    '30',
    '3',
    'Ġconstitu',
    'ents',
    'Ġhe',
    'ck',
    'Ġc',
    'ows',
    'Ġcommand',
    'ers',
    'Ġdifferent',
    'ial',
    'ĠC',
    'atherine',
    '29',
    '9',
    'Ġval',
    've',
    'Ġbr',
    'ace',
    'Ġperspect',
    'ives',
    'c',
    'ert',
    'f',
    'act',
    'icular',
    'ly',
    'ĠMc',
    'N',
    'pl',
    'anes',
    'Ġint',
    'ric',
    'Ġpe',
    'as',
    'ov',
    'an',
    'Ġtoss',
    'ed',
    'ret',
    'ch',
    'ĠL',
    'opez',
    'Ġunf',
    'amiliar',
    'de',
    'ath',
    'ĠA',
    'part',
    'ĠCh',
    'ang',
    'Ġrelie',
    'ved',
    'rop',
    'he',
    'Ġair',
    'ports',
    'Ġfre',
    'ak',
    'ut',
    'il',
    'M',
    'ill',
    'ĠCh',
    'in',
    'ĠOw',
    'en',
    'm',
    'ale',
    'ĠBro',
    'ken',
    'ĠWind',
    's',
    'ro',
    'b',
    'r',
    'ising',
    'Ġfire',
    'fighters',
    'Ġauthor',
    'itarian',
    'Ġ14',
    '8',
    'Bit',
    'coin',
    'ex',
    'ternal',
    'Ġbrow',
    'sers',
    'iche',
    'ver',
    'or',
    'ian',
    'Ġun',
    'b',
    'Ġpo',
    'ke',
    'ĠZ',
    'ot',
    'M',
    'id',
    'ĠPop',
    'ular',
    'Ġco',
    'vert',
    'Ġcont',
    'ributes',
    'Ġ6',
    '50',
    'Ġcont',
    'ention',
    'G',
    'ate',
    'Ġcons',
    'oles',
    'Ġchrom',
    'os',
    'ĠI',
    'X',
    'Ġvis',
    'ually',
    'ĠE',
    'isen',
    'Ġjewel',
    'ry',
    'Ġdeleg',
    'ation',
    'Ġacceler',
    'ate',
    'ĠR',
    'iley',
    'Ġsl',
    'ope',
    'Ġind',
    'oor',
    'it',
    'ially',
    'Ġhuge',
    'ly',
    'Ġtun',
    'nels',
    'Ġfin',
    'ed',
    'Ġdirect',
    'ive',
    'Ġfore',
    'head',
    'ustom',
    'ed',
    'Ġsk',
    'ate',
    'Mus',
    'ic',
    'g',
    'as',
    'Ġrecogn',
    'izing',
    'am',
    'bo',
    'Ġover',
    'weight',
    'ĠGr',
    'ade',
    'Ù',
    'Ĭ',
    'Ġsound',
    'ing',
    'Ġlock',
    'ing',
    'ĠR',
    'EM',
    'St',
    'ore',
    'Ġexc',
    'av',
    'ĠLike',
    'wise',
    'ĠL',
    'ights',
    'Ġel',
    'bow',
    'ĠSupp',
    'ly',
    'w',
    'ic',
    'Ġhands',
    'ome',
    '19',
    '94',
    'C',
    'oll',
    'Ġadequ',
    'ately',
    'ĠAssoci',
    'ate',
    'Ġstri',
    'ps',
    'Ġcrack',
    'down',
    'Ġmar',
    'vel',
    'ĠK',
    'un',
    'Ġpass',
    'ages',
    '@@',
    '@@',
    'ĠT',
    'all',
    'Ġthought',
    'ful',
    'names',
    'e',
    'Ġprost',
    'itution',
    'bus',
    'iness',
    'Ġball',
    'istic',
    'person',
    'al',
    'c',
    'ig',
    'iz',
    'ational',
    'R',
    'ound',
    'ĠÂłĠÂł',
    'ĠÂłĠÂł',
    'ĠCole',
    'man',
    'Ġadm',
    'itting',
    'ĠPl',
    'ug',
    'Ġbit',
    'coins',
    'ĠSu',
    'z',
    'Ġfair',
    'ness',
    'Ġsupp',
    'lier',
    'Ġcatast',
    'rophic',
    'ĠHel',
    'en',
    'o',
    'qu',
    'M',
    'arc',
    'ĠArt',
    'icles',
    'g',
    'ie',
    'Ġend',
    'angered',
    'Ġdest',
    'iny',
    'ĠVol',
    't',
    'ol',
    'ia',
    'ax',
    'is',
    'Ġche',
    'at',
    'Ġun',
    'ified',
    'IC',
    'O',
    'qu',
    'ote',
    '30',
    '2',
    'ĠS',
    'ed',
    'Ġsupp',
    'ression',
    'Ġanaly',
    'zing',
    'Ġsqu',
    'at',
    'Ġfig',
    'uring',
    'Ġcoordin',
    'ates',
    'Ġch',
    'unks',
    'Ġ19',
    '46',
    'Ġsub',
    'p',
    'Ġw',
    'iki',
    'ĠFor',
    'bes',
    'ĠJ',
    'upiter',
    'ĠE',
    'rik',
    'im',
    'er',
    'ĠCom',
    'mercial',
    '\\',
    ')',
    'Ġlegitim',
    'acy',
    'Ġd',
    'ental',
    'ĠMe',
    'an',
    'Ġdefic',
    'its',
    '5',
    '50',
    'Orig',
    'inally',
    'ĠHor',
    'ror',
    'Ġcontam',
    'ination',
    'll',
    'ah',
    'Ġconf',
    'isc',
    'ĠCl',
    'are',
    'T',
    'B',
    'ĠF',
    'ailed',
    'an',
    'ed',
    'Ġrul',
    'er',
    'ĠCont',
    'roller',
    'Ġfemin',
    'ists',
    'F',
    'ix',
    'g',
    'ay',
    '20',
    '7',
    'Ġr',
    'abbit',
    'Th',
    'ird',
    'ownt',
    'own',
    'Ġgl',
    'ue',
    'Ġvol',
    'atile',
    'Ġsh',
    'ining',
    'Ġf',
    'oll',
    'Ġimp',
    'aired',
    'Ġsup',
    'ers',
    'æ',
    'Ī',
    'Ġcl',
    'utch',
    'ļé',
    'ĨĴ',
    'Ġpro',
    'let',
    'Ġ(',
    '!',
    'Ġy',
    'elled',
    'ĠK',
    'iev',
    'ĠEr',
    'n',
    'ĠSh',
    'ock',
    'K',
    'B',
    'Ġsit',
    'uated',
    'qu',
    'ery',
    'ĠN',
    'as',
    'Ġan',
    'nex',
    'char',
    'acter',
    'ĠHol',
    'iday',
    'Ġautom',
    'ation',
    'ĠJ',
    'ill',
    'ĠRem',
    'astered',
    'Ġl',
    'inem',
    'Ġwild',
    'erness',
    'ĠHor',
    'izon',
    'ĠGu',
    'inea',
    'A',
    'Z',
    'Ġmain',
    'land',
    'Ġsec',
    'recy',
    'LE',
    'ASE',
    'Ġp',
    'unk',
    'ĠProv',
    'ince',
    '(',
    '),',
    'Spe',
    'ed',
    'Ġhand',
    'ing',
    'ĠSeb',
    'ast',
    'S',
    'ir',
    'r',
    'ase',
    'Ġj',
    'ournals',
    'Ġcon',
    'gest',
    'ĠT',
    'ut',
    'ir',
    'rel',
    'Ġschizophren',
    'ia',
    'Ġmis',
    'ogyn',
    'health',
    'y',
    'I',
    'ron',
    'Ġreact',
    'ed',
    '-',
    '$',
    '25',
    '2',
    'Ġpl',
    'ural',
    'Ġpl',
    'um',
    'Ġbarg',
    'ain',
    'Ġground',
    'ed',
    'f',
    'inder',
    'Ġdis',
    'se',
    'ĠL',
    'az',
    'O',
    'OD',
    'Ġat',
    'roc',
    'F',
    'actory',
    'Ġmin',
    'ions',
    'Ġo',
    'ri',
    'ĠB',
    'rave',
    'ĠP',
    'RE',
    'ĠMy',
    'anmar',
    'ĠH',
    'od',
    'Ġexped',
    'ition',
    'Ġexpl',
    'ode',
    'ĠCo',
    'ord',
    'Ġext',
    'r',
    'ĠB',
    'rief',
    'ĠAD',
    'HD',
    'Ġhard',
    'core',
    'feed',
    'ing',
    'Ġd',
    'ile',
    'ĠF',
    'ruit',
    'Ġvacc',
    'ination',
    'ĠM',
    'ao',
    'osp',
    'here',
    'Ġcont',
    'ests',
    '-',
    '|',
    'Ġf',
    'ren',
    'isp',
    'here',
    'R',
    'om',
    'ĠSh',
    'arp',
    'ĠTre',
    'nd',
    'Ġdis',
    'connect',
    'âĢ¢',
    'âĢ¢',
    'Ġper',
    'secution',
    'Ear',
    'th',
    'Ġhealth',
    'ier',
    '38',
    '4',
    'Ġc',
    'ob',
    'ĠTr',
    'inity',
    'OW',
    'S',
    'AN',
    'N',
    'Ġspecial',
    'ty',
    'Ġg',
    'ru',
    'Ġcooper',
    'ative',
    'wh',
    'y',
    'Start',
    'ing',
    'ĠIss',
    'ues',
    'st',
    're',
    'ens',
    'or',
    'Ġ18',
    '5',
    'Ad',
    'v',
    '!',
    '?',
    'ĠRe',
    'vel',
    'em',
    'ia',
    'ĠH',
    'ulk',
    'Ġcelebr',
    'ations',
    'ĠS',
    'ou',
    'ra',
    'ud',
    'ĠKle',
    'in',
    'Ġun',
    'real',
    'con',
    'text',
    'Ġpartners',
    'hips',
    'Ġadop',
    'ting',
    't',
    'ical',
    'Ġspl',
    'ash',
    'ĠHe',
    'zbollah',
    'c',
    'ategory',
    'cycl',
    'op',
    'xt',
    'on',
    'ĠD',
    'ot',
    'urd',
    'y',
    't',
    'z',
    'Ġenvelop',
    'e',
    'ĠN',
    'L',
    'â',
    'ķ',
    'Ġwhere',
    'in',
    'Spe',
    'c',
    '18',
    '4',
    'Ġte',
    'lev',
    'al',
    'iation',
    'Ġmyth',
    's',
    'å',
    '°',
    'Ġrig',
    'orous',
    'Ġcommun',
    'icating',
    'Ġobser',
    'ver',
    'Ġre',
    'he',
    'ĠW',
    'ash',
    'Ġapolog',
    'ized',
    'ĠT',
    'in',
    'Ġexpend',
    'itures',
    'work',
    'ers',
    'd',
    'ocument',
    'Ġhes',
    'itate',
    'ĠLen',
    'in',
    'Ġunpredict',
    'able',
    'Ġrenew',
    'al',
    'cl',
    'er',
    'ok',
    'ia',
    'ĠCON',
    'T',
    'Ġpost',
    'season',
    'Tok',
    'ens',
    'Ġex',
    'acerb',
    'Ġbet',
    'ting',
    'Ġ14',
    '7',
    'Ġelev',
    'ation',
    'W',
    'ood',
    'ĠSol',
    'omon',
    '19',
    '4',
    '00',
    '4',
    'out',
    'put',
    'Ġredu',
    'nd',
    'ĠM',
    'umbai',
    'Ġp',
    'H',
    'Ġreprodu',
    'ce',
    'ĠD',
    'uration',
    'MA',
    'X',
    'Ġb',
    'og',
    'C',
    'BS',
    'ĠBal',
    'ance',
    'ĠS',
    'gt',
    'ĠRec',
    'ent',
    'Ġc',
    'd',
    'Ġpo',
    'pped',
    'Ġincomp',
    'et',
    'pro',
    'p',
    'ay',
    'an',
    'g',
    'uy',
    'Pac',
    'ific',
    'Ġty',
    'r',
    'Ġ{',
    '{',
    'ĠMy',
    'stic',
    'ĠD',
    'ana',
    'Ġmast',
    'urb',
    'Ġge',
    'ometry',
    'Ã',
    '¢',
    'ĠCor',
    'rect',
    'Ġtraject',
    'ory',
    'Ġdistract',
    'ed',
    'Ġf',
    'oo',
    'ĠW',
    'elsh',
    'L',
    'uc',
    'm',
    'ith',
    'Ġrug',
    'by',
    'Ġrespir',
    'atory',
    'Ġtri',
    'angle',
    'Ġ2',
    '15',
    'Ġunder',
    'graduate',
    'ĠSuper',
    'ior',
    'ch',
    'anging',
    '_',
    '-',
    'Ġright',
    'ly',
    'Ġrefere',
    'e',
    'Ġluc',
    'rative',
    'Ġun',
    'authorized',
    'Ġresemb',
    'les',
    'ĠGN',
    'U',
    'ĠDer',
    'by',
    'Ġpath',
    'ways',
    'ĠL',
    'ed',
    'Ġend',
    'urance',
    'Ġst',
    'int',
    'Ġcollect',
    'or',
    'F',
    'ast',
    'Ġd',
    'ots',
    'Ġnational',
    's',
    'ĠSec',
    'urities',
    'Ġwh',
    'ip',
    'Par',
    'am',
    'Ġlearn',
    's',
    'M',
    'agic',
    'Ġdetail',
    'ing',
    'm',
    'oon',
    'Ġbroadcast',
    'ing',
    'Ġb',
    'aked',
    '26',
    '5',
    'hol',
    'm',
    'ĠS',
    'ah',
    'ĠHus',
    'sein',
    'ĠCourt',
    'esy',
    '17',
    '4',
    'Ġ14',
    '6',
    'Ġge',
    'ographic',
    'pe',
    'ace',
    'Ġjud',
    'ging',
    'ĠS',
    'tern',
    'B',
    'ur',
    'Ġstory',
    'line',
    'G',
    'un',
    'ĠSt',
    'ick',
    '24',
    '5',
    '30',
    '7',
    'ãĤ´',
    'ãĥ³',
    'ĠAdminist',
    'rator',
    'Ġbur',
    'nt',
    'Ġp',
    'ave',
    'ch',
    'oes',
    'Ex',
    'ec',
    'Ġcamp',
    'uses',
    'Res',
    'ult',
    'Ġmut',
    'ations',
    'ĠCh',
    'arter',
    'Ġcapt',
    'ures',
    'Ġcomp',
    'ares',
    'Ġbad',
    'ge',
    'S',
    'cient',
    'Ġer',
    'ad',
    'ier',
    'y',
    'o',
    'i',
    'ett',
    'es',
    'ĠE',
    'state',
    'Ġst',
    'rap',
    'Ġproud',
    'ly',
    'Ġf',
    'ried',
    'Ġwithd',
    'rawn',
    'ĠV',
    'oy',
    'ph',
    'ony',
    'It',
    'ems',
    'ĠP',
    'ierce',
    'b',
    'ard',
    'Ġann',
    'otation',
    'ant',
    'on',
    'ill',
    'on',
    'Im',
    'pro',
    '...',
    ')',
    'Ġhapp',
    'ier',
    '----',
    '--',
    'ad',
    'just',
    'Ġstaff',
    'ers',
    'Ġactiv',
    'ism',
    'Ġper',
    'f',
    'Ġal',
    'right',
    'N',
    'eed',
    'Ġcomm',
    'ence',
    'Ġopio',
    'id',
    'ĠAm',
    'anda',
    'E',
    's',
    'ĠP',
    'ars',
    'ĠK',
    'aw',
    'W',
    'orks',
    '24',
    '8',
    'Ġind',
    'o',
    't',
    'c',
    'end',
    'ant',
    'ĠM',
    'oto',
    'Ġlegal',
    'ization',
    'OT',
    'E',
    'Ġtask',
    'ed',
    'Ġt',
    'sp',
    'ĠACT',
    'IONS',
    '16',
    '6',
    'Ġrefres',
    'hing',
    'ĠN',
    'R',
    'ĠPere',
    'z',
    'Ġinfring',
    'ement',
    'S',
    'Y',
    'List',
    'en',
    'in',
    'ning',
    'k',
    'u',
    'Ġrot',
    'ate',
    'pro',
    'gram',
    'ar',
    'ah',
    'Des',
    'ign',
    'Ġ(',
    'Â£',
    'Ġst',
    'oring',
    'Ġwar',
    'rants',
    'Ġjud',
    'gement',
    'ĠB',
    'rist',
    'us',
    'ually',
    'ph',
    'oto',
    'ĠR',
    'an',
    'ĠP',
    'ine',
    'Ġoutrage',
    'ous',
    'ĠValent',
    'ine',
    'lu',
    'ence',
    'ĠEvery',
    'body',
    'Al',
    'tern',
    'Ġrele',
    'vance',
    'Ġtermin',
    'ated',
    'Ġd',
    'essert',
    'Ġfulf',
    'illed',
    'Ġprosecut',
    'ed',
    'ĠW',
    'ords',
    'Ġm',
    'igrant',
    'Ġcultiv',
    'ation',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'idel',
    'ity',
    'ĠV',
    'ern',
    'ĠLog',
    'in',
    'Ġmetaph',
    'or',
    'ĠT',
    'ip',
    'Ġrecru',
    'its',
    'ĠP',
    'ig',
    'rib',
    'ing',
    'Ġenthusi',
    'asts',
    'ex',
    'per',
    'Ġfright',
    'ening',
    'ĠH',
    'air',
    'ans',
    'on',
    'str',
    'ate',
    'Ġh',
    'i',
    'He',
    'ight',
    'Ġown',
    'ing',
    'n',
    'one',
    'Ġdis',
    'like',
    'Ġkn',
    'ives',
    'pher',
    'd',
    'Ġloud',
    'ly',
    'ĠAP',
    'Is',
    'Dis',
    'play',
    'ĠL',
    'ac',
    'ĠUS',
    'S',
    'ab',
    'l',
    'ver',
    'ages',
    'J',
    'ew',
    'Ġ17',
    '2',
    'ĠHist',
    'orical',
    'at',
    'oon',
    'ĠPhys',
    'ics',
    'in',
    'tern',
    'Ġwarm',
    'th',
    'Ġto',
    'pp',
    'D',
    'M',
    'Ġgun',
    'man',
    'Ġem',
    'peror',
    'od',
    'i',
    'ãĥ',
    '£',
    'in',
    'atory',
    'ĠR',
    'ib',
    'Ġ13',
    '1',
    'ĠSat',
    'urn',
    'ĠSh',
    'ining',
    'Ġw',
    'aking',
    'Qu',
    'otes',
    'Ġcomed',
    'ian',
    'en',
    'berg',
    'Â',
    '½',
    'Ġbelie',
    'vers',
    'Ġpaper',
    'work',
    'c',
    'ustom',
    'Ġle',
    'v',
    'Ġl',
    'ament',
    'Ġpour',
    'ing',
    '22',
    '2',
    'p',
    'olitical',
    'ĠSupp',
    'lement',
    'm',
    'aid',
    'Ġcruel',
    'ty',
    'Ġt',
    'read',
    'ys',
    'ics',
    'A',
    'w',
    'rit',
    'es',
    'Ġmod',
    'ifier',
    'ĠP',
    'osition',
    'Ad',
    'am',
    'l',
    'b',
    'ub',
    's',
    'Ġimper',
    'fect',
    'Ġcl',
    'usters',
    'ĠEngine',
    'er',
    'ĠC',
    'herry',
    'Ġinaug',
    'uration',
    'ĠS',
    'au',
    'Ġembod',
    'iment',
    'ĠUn',
    'cle',
    'Ġover',
    'r',
    'Ġexplos',
    'ions',
    'c',
    'ule',
    'ĠPrinc',
    'eton',
    'ĠAndre',
    'a',
    'Ġincorrect',
    'ly',
    'Ġearn',
    'est',
    'Ġpil',
    'gr',
    'ĠS',
    'print',
    'Ġslee',
    've',
    'Ġhe',
    'ars',
    'ĠAm',
    'azing',
    'Ġbrow',
    'sing',
    'ag',
    'in',
    'Ġhom',
    'eland',
    'Ġha',
    'w',
    'Ġd',
    'iving',
    'ist',
    'ered',
    '17',
    '8',
    'Ġbarg',
    'aining',
    'ĠArc',
    'ade',
    'Ġdeleg',
    'ate',
    'ters',
    'on',
    '................................',
    '................................',
    'ĠJackson',
    'ville',
    '27',
    '5',
    'Ġst',
    'agn',
    'Ġad',
    'am',
    'ĠSher',
    'man',
    'C',
    'B',
    'Ġsub',
    'urb',
    'ĠFood',
    's',
    'Ġconver',
    'ting',
    'ĠAr',
    'ist',
    'Ġch',
    'ambers',
    'l',
    'ove',
    'Ġam',
    'ino',
    'ĠG',
    'an',
    'Ġmad',
    'ness',
    'm',
    'c',
    'ĠUS',
    'E',
    'def',
    'ined',
    'Ġul',
    'tr',
    'ind',
    'ust',
    'Ġw',
    'olves',
    'l',
    'ance',
    'Add',
    'itionally',
    'Ġcr',
    'acks',
    'as',
    'ia',
    'ĠRe',
    'ason',
    'ĠP',
    'ump',
    'Ġaccident',
    'al',
    'ĠL',
    'aser',
    'ĠR',
    'id',
    'Ġinitial',
    'ized',
    'ell',
    'i',
    'Ġun',
    'named',
    'Ġn',
    'oun',
    'ĠPass',
    'ed',
    'Ġhost',
    'age',
    'ĠEth',
    'iop',
    'sh',
    'irts',
    'Ġun',
    'rel',
    'ĠEmb',
    'assy',
    'Ġ19',
    '41',
    'Ġat',
    'oms',
    'Ġpur',
    'ported',
    '16',
    '4',
    'ĠF',
    'i',
    'Ġgall',
    'ons',
    'ĠMon',
    'ica',
    'Ġp',
    'g',
    'en',
    'ment',
    'Ġsort',
    'ed',
    'ĠG',
    'ospel',
    'Ġhe',
    'ights',
    'Ġtr',
    'aced',
    'Ġunder',
    'going',
    'She',
    'll',
    'Ġs',
    'acks',
    'Ġproport',
    'ions',
    'Ġhall',
    'uc',
    'F',
    'ont',
    'ac',
    'et',
    'Ġwar',
    'mer',
    'ĠIN',
    'TER',
    'Ġgrab',
    'bing',
    'Pl',
    'ug',
    'Ġreal',
    'ization',
    'ĠBur',
    'ke',
    'Ġen',
    'chant',
    'AT',
    'ER',
    'ĠSe',
    'ed',
    'Ġabund',
    'ant',
    'F',
    'M',
    'Ġc',
    'ivic',
    'V',
    's',
    'is',
    'i',
    'Ġv',
    'ow',
    'Ġre',
    'per',
    'ĠPartners',
    'hip',
    'Ġpenet',
    'ration',
    'Ġax',
    'e',
    'Ġsh',
    'attered',
    'ĠZ',
    'ombies',
    'Ġv',
    'inyl',
    'ĠAl',
    'ert',
    'e',
    'on',
    'Ġoblig',
    'ed',
    'ĠIll',
    'ust',
    'ĠPl',
    'aza',
    'ĠFront',
    'ier',
    'Ġdavid',
    'jl',
    'ĠSer',
    'ial',
    'ĠH',
    'av',
    'ĠNut',
    'rition',
    'B',
    'i',
    'Ġâĸ',
    'Ī',
    'ĠJ',
    'ays',
    'lin',
    'ux',
    'Ġhur',
    'ry',
    'Ġv',
    'oy',
    'Ġhop',
    'eless',
    'ĠSte',
    'alth',
    'Ġ',
    'ãģ',
    'ess',
    'ors',
    'tt',
    'le',
    'b',
    'org',
    'ĠSaf',
    'ari',
    'f',
    'ell',
    'Ġw',
    'ary',
    'd',
    'ue',
    'ĠAb',
    'ove',
    'H',
    'a',
    'E',
    'LL',
    'Ġnot',
    'or',
    'ĠW',
    'on',
    'T',
    'oo',
    'Ġoccup',
    'ations',
    'Ġposs',
    'essions',
    'Ġinv',
    'iting',
    'Ġpred',
    'ators',
    'Ġacceler',
    'ated',
    'Ġ15',
    '7',
    'uter',
    'te',
    'ĠC',
    'ube',
    'e',
    'ast',
    'acc',
    'ount',
    'G',
    'ive',
    'Ġtrans',
    'plant',
    'red',
    'ients',
    'id',
    'able',
    'Ġscreens',
    'hots',
    'ĠG',
    'und',
    'ĠF',
    'S',
    'Ġtravel',
    'ers',
    'Ġsens',
    'ory',
    'ĠF',
    'iat',
    'ĠRock',
    'ets',
    'İ',
    'ĭ',
    '_',
    '{',
    'F',
    'riend',
    'Ġchar',
    'ming',
    'AL',
    'S',
    'Ġenjoy',
    'ment',
    'm',
    'ph',
    'Ġ5',
    '000',
    'ĠRE',
    'G',
    'Ù',
    'Ĩ',
    'b',
    'ia',
    'Ġcomp',
    'ilation',
    'ro',
    'st',
    'ĠV',
    'P',
    'ĠSch',
    'ne',
    '201',
    '9',
    'Ġcop',
    'ying',
    'M',
    'ORE',
    'ĠFl',
    'ore',
    'f',
    'alls',
    '2',
    '15',
    't',
    'otal',
    'Ġdis',
    'ciples',
    'd',
    'ouble',
    'Ġexceed',
    'ing',
    'Ġsm',
    'ashed',
    'Ġconcept',
    'ual',
    'ĠRom',
    'ania',
    'ĠB',
    'rent',
    'ĠI',
    'CE',
    'ĠT',
    'ou',
    'Ġg',
    'rap',
    'Ġn',
    'ails',
    '18',
    '9',
    'ãĥ',
    'ĺ',
    'Ġproc',
    'ure',
    'e',
    'ur',
    'Ġconfir',
    'ming',
    'ĠC',
    'ec',
    'aw',
    'i',
    'ĠEd',
    'en',
    'Ġn',
    'g',
    'Ġengine',
    'ered',
    'at',
    'ics',
    'Ġhook',
    'ed',
    'Ġdisgust',
    'ing',
    'ĠMur',
    'der',
    'ãĤ',
    '¿',
    'L',
    'ibrary',
    'Ġ16',
    '8',
    'Al',
    'most',
    'hem',
    'atic',
    'Men',
    'u',
    'ĠNot',
    're',
    'ĠJ',
    'ur',
    'Ġkidn',
    'apped',
    'Ġhack',
    'er',
    'ĠJ',
    'ade',
    'Ġcreep',
    'y',
    'Ġdraw',
    'ings',
    'ĠSpons',
    'or',
    'Ġcycl',
    'ists',
    'ĠGob',
    'lin',
    'Ġoptim',
    'ized',
    'Ġst',
    'aged',
    'ĠMc',
    'D',
    'bet',
    'ween',
    'A',
    'ge',
    'en',
    'o',
    'S',
    'ex',
    'ĠW',
    'ide',
    'n',
    'ings',
    'av',
    'is',
    'Ġincap',
    'able',
    'ĠK',
    'ob',
    'Ġreward',
    'ing',
    'ĠL',
    'one',
    'oles',
    'cent',
    'Ġcontract',
    'ed',
    'Ġstick',
    'y',
    'J',
    'ose',
    'B',
    'all',
    'f',
    'est',
    'ĠIn',
    'put',
    'ĠRec',
    'ently',
    'Ġto',
    'mat',
    'squ',
    'are',
    'App',
    'lication',
    'Ġnit',
    'rogen',
    'Ġdupl',
    'icate',
    'ĠRec',
    'on',
    'ĠD',
    'ear',
    'L',
    'ondon',
    'Ġint',
    'ra',
    'Ġd',
    'ock',
    'Ġout',
    'reach',
    'ĠM',
    'illion',
    'Ġmamm',
    'als',
    'am',
    'pton',
    'V',
    'AL',
    'Ġsn',
    'aps',
    'Ġd',
    'os',
    'ĠWh',
    'ole',
    'ĠRead',
    'y',
    'T',
    'ry',
    'ĠWinn',
    'ipeg',
    'ear',
    'ance',
    'Ġinc',
    'urred',
    'ren',
    'ched',
    'ĠNS',
    'W',
    'il',
    'ot',
    'rain',
    'e',
    'Ġc',
    'ube',
    'g',
    'ot',
    'Ġrun',
    'way',
    'etermin',
    'ed',
    'ĠHaw',
    'ks',
    'Ġsurviv',
    'or',
    'ĠW',
    'ish',
    'ĠD',
    'in',
    'ĠDE',
    'F',
    'ĠV',
    'ault',
    '18',
    '7',
    'Ġmush',
    'rooms',
    'Ġcris',
    'p',
    'be',
    'y',
    'ĠDisco',
    'very',
    'Ġdevelopment',
    'al',
    'Ġparad',
    'igm',
    'Ġcha',
    'otic',
    'ĠT',
    'su',
    'Ġ3',
    '33',
    'b',
    'ons',
    'Ġbacter',
    'ial',
    'Ġcomm',
    'its',
    'Ġcos',
    'mic',
    'Ġme',
    'ga',
    'oc',
    'ative',
    'ĠP',
    'aint',
    'ophob',
    'ic',
    'Ġv',
    'ain',
    'Ġcar',
    'ved',
    'ĠTh',
    'ief',
    'ĠG',
    'ul',
    'ows',
    'hip',
    'Ġc',
    'ites',
    'ĠEd',
    'inburgh',
    'Ġdimin',
    'ished',
    'Ġacknowled',
    'ges',
    'ĠK',
    'ills',
    'Ġmic',
    'row',
    'ĠHer',
    'a',
    'Ġsen',
    'iors',
    'Ġwhere',
    'by',
    'H',
    'op',
    'at',
    'ron',
    'Ġun',
    'available',
    'ĠN',
    'ate',
    'Ġ4',
    '80',
    'Ġsl',
    'ated',
    'ĠRe',
    'becca',
    'ĠB',
    'attery',
    'Ġgram',
    'mar',
    'Ġhead',
    'set',
    'Ġcurs',
    'or',
    'Ġex',
    'cluding',
    'any',
    'e',
    'aunder',
    'ing',
    'eb',
    'in',
    'Ġfeas',
    'ible',
    'ĠPub',
    'lishing',
    'ĠLab',
    's',
    'ĠCl',
    'iff',
    'ĠFerr',
    'ari',
    'Ġp',
    'ac',
    'vis',
    'ible',
    'mark',
    'ed',
    'pe',
    'll',
    'Ġpol',
    'ite',
    'Ġstagger',
    'ing',
    'ĠGal',
    'actic',
    'Ġsuper',
    'st',
    'Ġpar',
    'an',
    'ĠOffic',
    'ers',
    'ãĢ',
    'ģ',
    'Ġspecific',
    's',
    'ul',
    'us',
    '23',
    '9',
    'ĠP',
    'aste',
    'AM',
    'P',
    'ĠPan',
    'ama',
    'ĠDe',
    'lete',
    'angu',
    'ard',
    'rest',
    'rial',
    'Ġhero',
    'ic',
    'ĠD',
    'y',
    'Ø§',
    'ÙĦ',
    'Ġincumb',
    'ent',
    'Ġcr',
    'unch',
    't',
    'ro',
    'Ġsc',
    'oop',
    'Ġblog',
    'ger',
    'Ġsell',
    'ers',
    'ure',
    'n',
    'Ġmedic',
    'ines',
    'ĠC',
    'aps',
    'ĠAnim',
    'ation',
    'ox',
    'y',
    'Ġout',
    'ward',
    'Ġinqu',
    'iries',
    '22',
    '9',
    'Ġpsych',
    'ologist',
    'ĠS',
    'ask',
    'ev',
    'il',
    'Ġcontam',
    'inated',
    'ãĤ',
    '¨',
    'he',
    'rence',
    'Ġbrand',
    'ed',
    'ĠAbd',
    'ul',
    'z',
    'h',
    'Ġparagraph',
    's',
    'Ġmin',
    's',
    'Ġcor',
    'related',
    'er',
    'b',
    'Ġimp',
    'art',
    'Ġmil',
    'estone',
    'ĠSol',
    'utions',
    'ot',
    'le',
    'Ġunder',
    'cover',
    'Ġmar',
    'ched',
    'ĠCharg',
    'ers',
    'f',
    'ax',
    'ĠSec',
    'rets',
    'Ġr',
    'uth',
    'we',
    'ather',
    'Ġfemin',
    'ine',
    'Ġsh',
    'am',
    'Ġprest',
    'igious',
    'igg',
    'ins',
    'Ġs',
    'ung',
    'hist',
    'ory',
    'ett',
    'le',
    'gg',
    'ie',
    'Ġout',
    'dated',
    'ol',
    'and',
    'Ġper',
    'ceptions',
    'ĠS',
    'ession',
    'ĠDod',
    'gers',
    'u',
    'j',
    'ĠE',
    'ND',
    'D',
    'oc',
    'Ġdefic',
    'iency',
    'Gr',
    'and',
    'ĠJ',
    'oker',
    'Ġretro',
    'spect',
    'Ġdiagn',
    'ostic',
    'Ġharm',
    'less',
    'Ġro',
    'gue',
    'ĠA',
    'val',
    'E',
    'qu',
    'Ġtrans',
    'c',
    'ĠRoberts',
    'on',
    'ĠDep',
    'ending',
    'ĠBurn',
    's',
    'iv',
    'o',
    'Ġhost',
    'ility',
    'F',
    'eatures',
    'ĵ',
    'ĺ',
    'Ġdis',
    'comfort',
    'ĠL',
    'CD',
    'spec',
    'ified',
    'ĠEx',
    'pect',
    '3',
    '40',
    'Ġimper',
    'ative',
    'ĠReg',
    'ular',
    'Ch',
    'inese',
    'Ġstate',
    'wide',
    'Ġsy',
    'mm',
    'Ġlo',
    'ops',
    'Ġaut',
    'umn',
    'N',
    'ick',
    'Ġsh',
    'aping',
    'Ġqu',
    'ot',
    'Ġc',
    'herry',
    'ĠCross',
    'ref',
    'è¦',
    'ļéĨĴ',
    'Stand',
    'ard',
    'he',
    'ed',
    'ĠD',
    'ell',
    'ĠViet',
    'namese',
    'Ġo',
    'st',
    'ĠV',
    'alkyrie',
    'O',
    'A',
    'Ass',
    'ad',
    'Ġreb',
    'ound',
    'ĠTra',
    'ffic',
    'pl',
    'aces',
    'æ',
    'ĺ',
    'ĠB',
    'uc',
    '17',
    '2',
    'Ġshel',
    'ters',
    'Ġins',
    'isting',
    'ĠCertain',
    'ly',
    'ĠKenn',
    'eth',
    'ĠT',
    'CP',
    'Ġpen',
    'al',
    'ĠRe',
    'play',
    'he',
    'ard',
    'Ġdial',
    'ect',
    'iz',
    'a',
    'ĠF',
    'Y',
    'it',
    'cher',
    'ĠD',
    'L',
    'Ġspir',
    'al',
    'Ġquarterback',
    's',
    'Ġh',
    'ull',
    'Ġgo',
    'ogle',
    'Ġto',
    'dd',
    'ĠSter',
    'ling',
    'ĠPl',
    'ate',
    'Ġsp',
    'ying',
    'mb',
    'ol',
    'ĠReal',
    'm',
    'ĠPro',
    'ced',
    'ĠCr',
    'ash',
    'Ġtermin',
    'ate',
    'Ġprotest',
    'ing',
    'C',
    'enter',
    'gu',
    'ided',
    'Ġun',
    'cover',
    'Ġboy',
    'cott',
    'Ġreal',
    'izes',
    's',
    'ound',
    'Ġpret',
    'ending',
    'ĠV',
    'as',
    '19',
    '80',
    'Ġfram',
    'ed',
    'Ġ13',
    '9',
    'Ġdesc',
    'ended',
    'Ġrehab',
    'ilitation',
    'Ġborrow',
    'ing',
    'ĠB',
    'uch',
    'Ġbl',
    'ur',
    'R',
    'on',
    'ĠFro',
    'zen',
    'en',
    'za',
    'Ch',
    'ief',
    'ĠP',
    'oor',
    'Ġtransl',
    'ates',
    'M',
    'IN',
    'Ġ2',
    '12',
    'J',
    'ECT',
    'Ġerupt',
    'ed',
    'Ġsuccess',
    'es',
    'S',
    'EC',
    'Ġpl',
    'ague',
    'Ġg',
    'ems',
    'd',
    'oms',
    'Ġstret',
    'ches',
    'ĠSp',
    'y',
    'Ġstory',
    'telling',
    'C',
    'redit',
    'ĠP',
    'ush',
    'Ġtra',
    'ction',
    'Ġin',
    'effective',
    'ĠL',
    'una',
    'Ġt',
    'apes',
    'Ġanaly',
    'tics',
    'erc',
    'ise',
    'Ġprogram',
    'mes',
    'ĠCar',
    'bon',
    'Ġbeh',
    'old',
    'he',
    'avy',
    'ĠConserv',
    'ation',
    'ĠF',
    'IR',
    'Ġs',
    'ack',
    'ter',
    'min',
    'ric',
    'ks',
    'Ġhous',
    'ed',
    'Ġunus',
    'ually',
    'I',
    'ce',
    'Ġexecut',
    'ing',
    'ĠMor',
    'oc',
    'ed',
    'ay',
    'Ġed',
    'itions',
    'Ġsm',
    'arter',
    'ĠB',
    'A',
    'Ġout',
    'law',
    'Ġvan',
    'ished',
    'ib',
    'a',
    'AL',
    'SE',
    'ĠSil',
    'va',
    '23',
    '8',
    'C',
    'ould',
    'Ġphilos',
    'opher',
    'Ġevac',
    'uated',
    'Sec',
    'ret',
    '14',
    '2',
    'Ġvis',
    'as',
    'ãĤ',
    '¬',
    'ĠM',
    'alt',
    'ĠClear',
    'ly',
    'ĠN',
    'iger',
    'ĠC',
    'airo',
    'ĠF',
    'ist',
    '3',
    '80',
    'ĠX',
    'ML',
    'aut',
    'o',
    'it',
    'ant',
    'Ġrein',
    'forced',
    'Rec',
    'ord',
    'ĠSurviv',
    'or',
    'G',
    'Hz',
    'Ġscrew',
    's',
    'parent',
    's',
    'Ġo',
    'ceans',
    'ma',
    'res',
    'Ġbra',
    'kes',
    'vas',
    'ive',
    'Ġhell',
    'o',
    'ĠS',
    'IM',
    'rim',
    'p',
    'Ġo',
    're',
    'ĠArm',
    'our',
    '24',
    '7',
    'Ġterr',
    'ific',
    'Ġt',
    'ones',
    '14',
    '1',
    'ĠMin',
    'utes',
    'Ep',
    'isode',
    'Ġcur',
    'ves',
    'Ġinflamm',
    'atory',
    'Ġbat',
    'ting',
    'ĠBeaut',
    'iful',
    'L',
    'ay',
    'Ġunp',
    'op',
    'v',
    'able',
    'Ġr',
    'iots',
    'ĠTact',
    'ics',
    'b',
    'augh',
    'ĠC',
    'ock',
    'Ġorg',
    'asm',
    'ĠS',
    'as',
    'Ġconstruct',
    'or',
    'et',
    'z',
    'G',
    'ov',
    'Ġant',
    'agon',
    'Ġthe',
    'at',
    'Ġde',
    'eds',
    'ha',
    'o',
    'c',
    'uts',
    'ĠMc',
    'Cl',
    'Ġu',
    'm',
    'ĠScient',
    'ists',
    'Ġgrass',
    'roots',
    'ys',
    'sey',
    '"]',
    '=>',
    'Ġsurf',
    'aced',
    'Ġsh',
    'ades',
    'Ġneighb',
    'ours',
    'Ġad',
    'vertis',
    'oy',
    'a',
    'Ġmer',
    'ged',
    'Up',
    'on',
    'Ġg',
    'ad',
    'Ġanticip',
    'ate',
    'Any',
    'way',
    'Ġsl',
    'ogan',
    'Ġdis',
    'respect',
    'I',
    'ran',
    'ĠT',
    'B',
    'act',
    'ed',
    'Ġsubp',
    'oen',
    'medi',
    'ately',
    'OO',
    'OO',
    'Ġwa',
    'iver',
    'Ġvulner',
    'abilities',
    'ott',
    'esville',
    'ĠHuff',
    'ington',
    'J',
    'osh',
    'ĠD',
    'H',
    'M',
    'onday',
    'ĠEll',
    'en',
    'K',
    'now',
    'x',
    'on',
    'it',
    'ems',
    '22',
    '8',
    'Ġf',
    'ills',
    'ĠN',
    'ike',
    'Ġcum',
    'ulative',
    'and',
    'als',
    'I',
    'r',
    'Ġ',
    'ì',
    'Ġfr',
    'iction',
    'ig',
    'ator',
    'Ġsc',
    'ans',
    'ĠVi',
    'enna',
    'ld',
    'om',
    'Ġperform',
    'ers',
    'P',
    'rim',
    'Ġb',
    'idding',
    'M',
    'ur',
    'Ġlean',
    'ed',
    'ĠPri',
    'x',
    'al',
    'ks',
    'Ġ[',
    'âĢ¦]',
    'ĠTw',
    'itch',
    'ĠDevelop',
    'er',
    'ĠG',
    'ir',
    'Ġcall',
    'back',
    'Ab',
    'stract',
    'Ġacc',
    'ustomed',
    'Ġfreed',
    'oms',
    'ĠP',
    'G',
    'ur',
    'acy',
    'Ġl',
    'ump',
    'is',
    'man',
    ',,',
    ',,',
    '19',
    '92',
    'ĠR',
    'ED',
    'Ġwor',
    'm',
    'M',
    'atch',
    'ĠPl',
    'atinum',
    'I',
    'J',
    'ĠOwn',
    'er',
    'Tri',
    'via',
    'com',
    'pl',
    'Ġnew',
    'born',
    'Ġfant',
    'as',
    'O',
    'wn',
    'Ġ19',
    '59',
    'Ġsymp',
    'ath',
    'Ġub',
    'iqu',
    'Ġoutput',
    's',
    'Ġal',
    'lev',
    'Ġpr',
    'ag',
    'K',
    'evin',
    'Ġfav',
    'ors',
    'Ġbur',
    'ial',
    'Ġn',
    'urt',
    'so',
    'lete',
    'c',
    'ache',
    'Ġ15',
    '6',
    'Ġunl',
    'ocks',
    'te',
    'chn',
    'M',
    'aking',
    'Ġcon',
    'quer',
    'ad',
    'ic',
    'æ',
    'ĸ',
    'Ġel',
    'f',
    'Ġelect',
    'orate',
    'ĠKurd',
    's',
    'ĠSt',
    'ack',
    'ĠSam',
    'urai',
    'Ġâ',
    'ĺħ',
    'Ġ{',
    '}',
    'ĠS',
    'aid',
    'ĠFall',
    'out',
    'Ġkind',
    'ness',
    'ĠCustom',
    's',
    'ĠBou',
    'levard',
    'Ġhelicop',
    'ters',
    'ot',
    'ics',
    'ĠVe',
    'get',
    'com',
    'ment',
    'Ġcritic',
    'ised',
    'Ġpol',
    'ished',
    'ĠRem',
    'ix',
    'ĠC',
    'ultural',
    'Ġrec',
    'ons',
    'Ġdo',
    'i',
    'at',
    'em',
    'Sc',
    'reen',
    'Ġbar',
    'red',
    'Com',
    'ments',
    'ĠGener',
    'ally',
    'Ġsl',
    'ap',
    '7',
    '20',
    'V',
    'ari',
    'p',
    'ine',
    'Ġem',
    'pt',
    'Ġh',
    'ats',
    'ĠPlay',
    'ing',
    'l',
    'ab',
    'a',
    'verage',
    'form',
    's',
    'ĠC',
    'otton',
    'Ġcan',
    's',
    'ĠD',
    'ON',
    'ĠSom',
    'alia',
    'C',
    'rypt',
    'ĠIncre',
    'ases',
    'E',
    'ver',
    'mod',
    'ern',
    'Ġsur',
    'geon',
    '3',
    '000',
    'Ġrandom',
    'ized',
    '================================',
    '================================',
    'B',
    'ern',
    'im',
    'pl',
    'ĠC',
    'OR',
    'Ġpro',
    'claim',
    'th',
    'ouse',
    'Ġto',
    'es',
    'Ġam',
    'ple',
    'Ġpres',
    'erving',
    'Ġdis',
    'bel',
    'gr',
    'and',
    'B',
    'esides',
    'Ġsil',
    'k',
    'ĠPat',
    'tern',
    'h',
    'm',
    'Ġenter',
    'prises',
    'Ġaffidav',
    'it',
    'ĠAdvis',
    'ory',
    'Ġadvert',
    'ised',
    'ĠRel',
    'igious',
    'se',
    'ctions',
    'psy',
    'ch',
    'ĠField',
    's',
    'aw',
    'ays',
    'Ġhasht',
    'ag',
    'ĠNight',
    'mare',
    'Ġv',
    'ampire',
    'Ġfore',
    'nsic',
    'rosso',
    'ver',
    'n',
    'ar',
    'Ġn',
    'avy',
    'Ġvac',
    'ant',
    'ĠD',
    'uel',
    'Ġhall',
    'way',
    'Ġface',
    'book',
    'ident',
    'ally',
    'ĠN',
    'RA',
    'Ġm',
    'att',
    'Ġhur',
    'ricane',
    'ĠKir',
    'by',
    'ĠP',
    'uzzle',
    'Ġsk',
    'irt',
    'ou',
    'st',
    'du',
    'llah',
    'Ġanal',
    'ogy',
    'in',
    'ion',
    'Ġtomat',
    'oes',
    'ĠN',
    'V',
    'ĠPe',
    'ak',
    'ĠMe',
    'yer',
    'Ġappoint',
    'ments',
    'Ġm',
    'asc',
    'Ġal',
    'ley',
    're',
    'hend',
    'Ġchar',
    'ities',
    'Ġund',
    'o',
    'Ġdest',
    'inations',
    'ĠTest',
    'ing',
    '">',
    '</',
    'Ġdest',
    'ined',
    'Ġimp',
    'lements',
    'ĠHar',
    'old',
    'RE',
    'CT',
    'Ġoptim',
    'ization',
    'Ġkilomet',
    'res',
    'Ġc',
    'md',
    'Ġimpair',
    'ment',
    'Ġun',
    'successful',
    'Ġswift',
    'ly',
    'ĠGlas',
    'gow',
    'art',
    'en',
    'ĠSh',
    'ares',
    'ĠAn',
    'swer',
    'ĠAl',
    'bum',
    'Ġnut',
    'ritional',
    'ãĥ',
    'ĸ',
    'ĠF',
    'ut',
    'Ġbl',
    'oc',
    'ĠN',
    'FC',
    'Ġwholes',
    'ale',
    'ĠC',
    'W',
    'Ġneg',
    'lected',
    'Ġlaun',
    'cher',
    'Ġannounce',
    'ments',
    'OU',
    'LD',
    'com',
    'b',
    'Ġrot',
    'ating',
    'Ġrest',
    's',
    'ĠT',
    'icket',
    'ched',
    'el',
    'L',
    'ou',
    'ĠV',
    'ic',
    'Ġ"',
    "'",
    'Ġtem',
    'plates',
    'Ġrepl',
    'aces',
    'Ar',
    'c',
    '::',
    '::',
    'ĠGil',
    'bert',
    'Ġillness',
    'es',
    'Ġsched',
    'ules',
    'Ġheter',
    'osexual',
    'L',
    'INE',
    'Ġhere',
    'in',
    'Ġco',
    'erc',
    'Ġdecre',
    'asing',
    'Ġde',
    'portation',
    's',
    'udo',
    'ĠInd',
    'igenous',
    'Ġweigh',
    's',
    'Al',
    'ong',
    "'",
    ');',
    'ĠBeng',
    'als',
    '70',
    '7',
    'Ġjoint',
    's',
    'ver',
    'ts',
    'Ġ14',
    '9',
    'na',
    'ire',
    'Ġsimpl',
    'est',
    'Ġl',
    'ore',
    '10',
    '80',
    'f',
    'iction',
    'ĠDat',
    'abase',
    'Ġreserv',
    'ation',
    'Ġs',
    'ou',
    'Ġsan',
    'ctuary',
    'aud',
    'io',
    'ap',
    'le',
    'Ġveget',
    'arian',
    'Ġanticip',
    'ation',
    'm',
    'icro',
    'Ġend',
    'uring',
    'Ġdepart',
    'ed',
    'Ġsidew',
    'alk',
    'Ġprohib',
    'its',
    'ĠF',
    'ont',
    'Ġcomp',
    'ute',
    'ĠS',
    'ect',
    'Ġ15',
    '8',
    'B',
    'attle',
    'Ġbom',
    'ber',
    'Ġdist',
    'raction',
    'Ġend',
    'ured',
    'Ġpractition',
    'ers',
    'Ġdistur',
    'bed',
    'Ġdr',
    'ank',
    'ord',
    'ered',
    'Ġsurpr',
    'ises',
    'se',
    'at',
    'Sec',
    'urity',
    'ĠW',
    'isdom',
    'og',
    'o',
    'Ġsub',
    'paragraph',
    'ĠPen',
    'insula',
    'ĠOrig',
    'ins',
    'ire',
    'n',
    'ĠP',
    'av',
    'igg',
    'le',
    'Ġgrat',
    'itude',
    'ĠG',
    'ravity',
    'over',
    'ty',
    'im',
    'an',
    'ct',
    'r',
    'ĠCa',
    'esar',
    'c',
    'ould',
    'g',
    'em',
    'Ġsk',
    'ies',
    'Ġch',
    'amp',
    'Ġagree',
    'ing',
    'F',
    'amily',
    'D',
    'iv',
    '17',
    '6',
    'Ġmess',
    'y',
    'um',
    'ption',
    'F',
    'ederal',
    'ern',
    'o',
    'ĠCh',
    'at',
    'Bey',
    'ond',
    'Ġdev',
    'ote',
    'ĠW',
    'alsh',
    'Ġdump',
    'ed',
    'Ġaccum',
    'ulation',
    'st',
    'ad',
    'hib',
    'ition',
    'Ġsm',
    'okers',
    'Ġinspect',
    'or',
    'F',
    'rench',
    'iss',
    'an',
    'ĠV',
    'ita',
    'Ġresearch',
    'ing',
    'R',
    'AM',
    'ĠCelt',
    'ics',
    'Ġcl',
    'oak',
    'ĠTer',
    'ra',
    'M',
    'ary',
    'so',
    'ld',
    'ĠD',
    'OM',
    'mod',
    's',
    'Int',
    'el',
    'Ġmult',
    'itude',
    'ĠImpro',
    'ved',
    'Ġrel',
    'iance',
    'Ġartif',
    'act',
    'Ġalarm',
    'ing',
    'P',
    'rom',
    'h',
    'on',
    'T',
    'ION',
    'med',
    'ium',
    'Ġref',
    'lex',
    'ĠEx',
    'cel',
    'Ġweaken',
    'ed',
    '16',
    '3',
    '2',
    '24',
    'Ġcost',
    'umes',
    'Ġunique',
    'ly',
    'Ġs',
    'orrow',
    'Ġm',
    'ansion',
    'w',
    'p',
    'Ġsal',
    'v',
    'ĠGro',
    've',
    'bs',
    'p',
    'ĠSn',
    'iper',
    'ĠSh',
    'ipping',
    'ĠP',
    'OW',
    'Ġund',
    'is',
    'Ġbrand',
    'ing',
    'G',
    'irl',
    'ĠAh',
    'mad',
    'ĠL',
    'akes',
    'ĠCore',
    'y',
    'Ġinherit',
    'ance',
    'ener',
    'y',
    'Ġpack',
    'ing',
    'ĠP',
    'rest',
    'D',
    'est',
    'F',
    'W',
    'Ġregul',
    'ator',
    'l',
    'ocked',
    'Ġcont',
    'ested',
    'ĠMel',
    'issa',
    'ĠD',
    'uc',
    'Ġunpop',
    'ular',
    'Ġst',
    'acked',
    'Ġ19',
    '17',
    'Ġyear',
    'ly',
    'Ġst',
    'are',
    'Ġassess',
    'ing',
    'Ã',
    '¸',
    'Ġbe',
    'verages',
    'Ġcompet',
    'itions',
    'Ġstreng',
    'thening',
    'al',
    'ong',
    'ĠL',
    'ud',
    'Ġmel',
    'ted',
    'stan',
    'bul',
    'Ġb',
    'ounty',
    'EN',
    'C',
    'ĠL',
    'ands',
    'Ġdecl',
    'ares',
    'Ġcustom',
    'ize',
    'Ġcomp',
    'osite',
    'ãĥ',
    '¬',
    'C',
    'M',
    'ograph',
    'ics',
    'ĠTem',
    'p',
    'Ġcont',
    'ender',
    'Ġins',
    'ign',
    'ĠL',
    'AN',
    'Ġdis',
    'asters',
    'ins',
    'pired',
    'Ġjud',
    'gments',
    'ustain',
    'able',
    'urs',
    'ion',
    'Ġvar',
    'iance',
    'ĠUlt',
    'imately',
    'Ġ',
    '--------',
    'u',
    'ador',
    'ĠR',
    'X',
    'Ġmel',
    'ting',
    'ĠExt',
    'ended',
    'ĠT',
    'we',
    'M',
    'ajor',
    'ĠB',
    'il',
    'Ġsy',
    'rup',
    'qu',
    'ick',
    'ĠHold',
    'er',
    'Ġinnoc',
    'ence',
    'U',
    'LE',
    'ĠM',
    'ight',
    '99',
    '99',
    'Ġf',
    'al',
    'Ġcontinu',
    'ity',
    'Ġ19',
    '53',
    'ĠB',
    'S',
    'st',
    'ill',
    'L',
    'at',
    'ĠAb',
    'use',
    'Ġun',
    'supported',
    'xxxx',
    'xxxx',
    'Ġinst',
    'itute',
    'Ġfrag',
    'ment',
    'ĠP',
    'ep',
    'W',
    'estern',
    'ĠC',
    'ause',
    'ĠFr',
    'ag',
    'ĠAr',
    's',
    'à',
    '¥',
    'ast',
    'ics',
    'Ġb',
    'ishop',
    'Ġcross',
    'es',
    'Ġ15',
    '4',
    'ĠUp',
    'grade',
    'Ġmit',
    'igate',
    'ĠRay',
    'mond',
    'Mod',
    's',
    'Ġtom',
    'ato',
    'Ġst',
    'umbled',
    'Ġdiff',
    'ers',
    'In',
    'itial',
    'ĠR',
    'aspberry',
    'Ġign',
    'ores',
    'Ġt',
    'ant',
    'Ã',
    'ł',
    'Ġrel',
    'ay',
    'Ġb',
    'isexual',
    'Ġconf',
    'ession',
    'Ġd',
    'ement',
    'in',
    'as',
    'ĠHe',
    'ather',
    'pl',
    'atform',
    'dri',
    'ving',
    'bour',
    'g',
    'ĠM',
    'ush',
    'Ġhy',
    'ster',
    'Det',
    'ails',
    'Ġdr',
    'ift',
    'ĠW',
    'ald',
    'ĠLuck',
    'ily',
    'or',
    'f',
    'Ġexp',
    'ire',
    'ĠP',
    'unch',
    'zy',
    'me',
    'g',
    'old',
    'Ġunp',
    'aid',
    'ĠT',
    'rent',
    'Ġun',
    'armed',
    'Ġill',
    'icit',
    'ĠT',
    'ottenham',
    'Ġsm',
    'ash',
    'Intern',
    'ational',
    'ink',
    'er',
    'Ġst',
    'ing',
    'ĠSadd',
    'am',
    'ĠAR',
    'T',
    'Ġtruth',
    's',
    'b',
    'irth',
    'Ġso',
    'ber',
    'ĠN',
    'it',
    'Ġ',
    'ib',
    'Ġus',
    'able',
    'Ġst',
    'acks',
    'ĠSy',
    'lv',
    'Ġnort',
    'heast',
    'Ġdom',
    'ination',
    'ĠM',
    'our',
    'EN',
    'SE',
    'ĠMe',
    'asure',
    'Ġprogram',
    'mer',
    'Ġ<',
    '-',
    '18',
    '2',
    'ĠCond',
    'ition',
    'Ġback',
    'yard',
    'ir',
    'ling',
    'ĠJ',
    'eb',
    'ĠCre',
    'ed',
    'ĠH',
    'ang',
    'ĠCOM',
    'P',
    'F',
    'ER',
    'ĠIs',
    'h',
    'Ġdetect',
    'ives',
    '------------',
    '---',
    'ĠMess',
    'enger',
    'Ġlo',
    'oph',
    'Ġgate',
    'way',
    '15',
    '1',
    'ĠMaterial',
    's',
    'ĠD',
    'T',
    'Ġdo',
    'omed',
    'od',
    'o',
    'Ġslic',
    'es',
    'Ġemail',
    'ed',
    'ĠPer',
    'l',
    'Ġren',
    'ov',
    'UT',
    'H',
    'ody',
    'nam',
    'ĠSouth',
    'west',
    'get',
    'ic',
    'ĠT',
    'PP',
    'Ġoptim',
    'ism',
    'ĠT',
    'ow',
    'ul',
    'ators',
    'prot',
    'ected',
    'y',
    'les',
    'Â',
    '«',
    'Ġex',
    'ile',
    'en',
    'v',
    'P',
    'rop',
    'ĠZimmer',
    'man',
    'Ù',
    'İ',
    'C',
    'a',
    'om',
    'aly',
    'ãĥ',
    'Ĩ',
    'Ġrail',
    'road',
    'L',
    'ee',
    '23',
    '2',
    'Ġrepl',
    'icate',
    'Ġcomfort',
    'ably',
    'act',
    'ly',
    'Ġr',
    'av',
    'Ġtelesc',
    'ope',
    'Ġhonest',
    'y',
    'ĠPe',
    'pper',
    'ĠBr',
    'ing',
    'Ġric',
    'hest',
    'Ġout',
    'doors',
    'Ġh',
    'alls',
    'Ġcont',
    'end',
    'IS',
    'E',
    'Ġsub',
    'mitting',
    'Ġna',
    'ive',
    'ar',
    'ations',
    'Ġ14',
    '3',
    'Ġpo',
    'ised',
    'respons',
    'ible',
    'Ġsoc',
    'ks',
    'ĠSk',
    'ull',
    'Quest',
    'ion',
    'Ġdiscover',
    'ies',
    'Jo',
    'ined',
    'ĠEn',
    'emies',
    'ĠWire',
    'less',
    'ĠRe',
    'venge',
    'Ġpuzz',
    'les',
    'Ġce',
    'ased',
    '29',
    '0',
    'cript',
    'ions',
    'ĠCon',
    'sole',
    'Ġbo',
    'iling',
    'Ġdisc',
    'rep',
    'Ġded',
    'uction',
    'Ġar',
    'senal',
    'XX',
    'XX',
    'ĠAm',
    'sterdam',
    'rox',
    'imately',
    'ĠSh',
    'ane',
    'Ġpos',
    'ing',
    'ĠACL',
    'U',
    'ĠCompan',
    'ies',
    'Ġthe',
    'ology',
    'ĠU',
    'g',
    'qu',
    'arter',
    'ĠH',
    'ank',
    'Co',
    'in',
    'ĠL',
    'v',
    'Ġalleg',
    'ation',
    'ĠAv',
    'oid',
    'Ġindef',
    'initely',
    'Ġcommod',
    'ities',
    'Ġbr',
    'ig',
    'ĠMan',
    'it',
    'Ġt',
    'enth',
    'met',
    'hod',
    'ĠKn',
    'icks',
    'ĠâĢ',
    'İ',
    'Ġinv',
    'oked',
    'D',
    'ial',
    'AR',
    'A',
    'Ġc',
    'aucus',
    '22',
    '7',
    'ĠJ',
    'ab',
    'Ġoun',
    'ces',
    'b',
    'ay',
    'Ġbud',
    'dy',
    'f',
    'an',
    '23',
    '4',
    'ĠH',
    'il',
    'ad',
    'h',
    'ĠT',
    'Y',
    'ĠIN',
    'D',
    'Ġ19',
    '39',
    'Ġiter',
    'ation',
    'ĠGonz',
    'alez',
    'ĠV',
    'ert',
    'ĠI',
    'O',
    'em',
    'b',
    're',
    'ra',
    'en',
    'ch',
    'ĠRequ',
    'irements',
    'ĠW',
    'ins',
    'Ġlivest',
    'ock',
    'h',
    'ours',
    '"',
    'âĢ¦',
    'b',
    'ral',
    'M',
    'arg',
    'ĠD',
    'one',
    'Ġwas',
    'ting',
    'ing',
    'ed',
    'g',
    'roups',
    'Ġw',
    'ishing',
    'ĠT',
    'umblr',
    'Ġt',
    'apping',
    'Ġnational',
    'ism',
    'ĠB',
    'yr',
    'Ġsqu',
    'ares',
    'ĠAct',
    'ions',
    'ãĥ',
    '¥',
    'In',
    'side',
    'deb',
    'ug',
    'Ġapp',
    'end',
    'Ġstub',
    'born',
    'ĠC',
    'ind',
    'T',
    'ell',
    'Ġt',
    'earing',
    'ĠRe',
    'y',
    'or',
    'c',
    'ĠDay',
    'ton',
    'ĠN',
    'H',
    'ĠMad',
    'ness',
    'Ch',
    'arl',
    'ĠMor',
    'rison',
    'fil',
    'ter',
    'Ġacc',
    'use',
    'Ġ.',
    '/',
    'Ġtor',
    'rent',
    'Ġdecl',
    'ines',
    'g',
    'allery',
    'M',
    'ine',
    'Ġneg',
    'otiation',
    'ĠBash',
    'ar',
    'op',
    'ia',
    '19',
    '93',
    'em',
    'ort',
    'ĠNo',
    'vel',
    'ĠF',
    'ang',
    'ers',
    'ive',
    'ĠInst',
    'ant',
    'Ġroll',
    'er',
    'A',
    'round',
    'ĠElect',
    'ions',
    'G',
    'ames',
    'Ġin',
    'expensive',
    'Ġwor',
    's',
    'Ġv',
    'ul',
    'ĠH',
    'ole',
    'Ġunbeliev',
    'able',
    'Ġn',
    'ause',
    'Ġent',
    'r',
    'bo',
    'at',
    'ĠST',
    'E',
    'Ġbus',
    'h',
    'ĠHass',
    'an',
    'Ġw',
    'o',
    'Ġpa',
    'used',
    'ĠM',
    'ig',
    'l',
    'ived',
    'Ġsc',
    'out',
    'Ġl',
    'ith',
    'Pub',
    'lished',
    'du',
    'ino',
    'c',
    'ool',
    'Ġcirc',
    'ulating',
    'id',
    'as',
    'ĠP',
    'am',
    'viol',
    'ent',
    'ĠCraw',
    'ford',
    'udd',
    'le',
    'ĠLet',
    'ters',
    'Gu',
    'ard',
    'mor',
    'ph',
    'Ġwand',
    'ering',
    'Ġsoph',
    'omore',
    'Ġque',
    'er',
    'ĠBl',
    'ind',
    'r',
    'ue',
    'ĠMar',
    'riage',
    'D',
    'om',
    'Ġpadd',
    'ing',
    'Ġfold',
    'ers',
    'Ġmeaning',
    'less',
    'Ġcandid',
    'acy',
    'af',
    'ort',
    'Ġwhistle',
    'bl',
    'ĠIdent',
    'ified',
    'Ġcig',
    'ar',
    'Ġh',
    'id',
    'ĠDub',
    'ai',
    'Ġpost',
    'ure',
    'Ġh',
    'iking',
    'ĠTermin',
    'al',
    'Legend',
    'ary',
    'ĠT',
    'P',
    'ĠAT',
    'K',
    'ĠStar',
    'bucks',
    'ĠR',
    'iot',
    '19',
    '91',
    'ĠBott',
    'om',
    'e',
    'ffic',
    'ĠEug',
    'ene',
    'ĠWy',
    'oming',
    'ĠRock',
    'y',
    'Ġsal',
    'mon',
    'Ġmet',
    'ro',
    'Ġb',
    'ilateral',
    'Ġcelebr',
    'ates',
    'L',
    'ength',
    'b',
    'illion',
    'B',
    'at',
    'Ġre',
    'leg',
    'Ġpse',
    'udo',
    'D',
    'T',
    'ĠRh',
    'ode',
    'P',
    'arent',
    'ple',
    'tion',
    'Ġatt',
    'ribut',
    'Ġtun',
    'ing',
    'ĠNOT',
    'E',
    'ĠRe',
    'bel',
    'ic',
    'us',
    'F',
    'und',
    'Ġcock',
    'tail',
    'Ġ5',
    '01',
    'Ġsp',
    'oon',
    'Ġbrut',
    'ality',
    'Ġun',
    'ite',
    'Ġmicro',
    'bi',
    'ĠRe',
    'ich',
    'pos',
    'itive',
    'Ġam',
    'azed',
    'ĠN',
    'T',
    'D',
    'esc',
    'ECT',
    'ION',
    'Ġfalse',
    'ly',
    'ĠHigh',
    'lander',
    'ĠC',
    'rist',
    'ĠVictor',
    'ian',
    'Ġdistribut',
    'ions',
    'the',
    'ir',
    'ĠE',
    'instein',
    'Ġp',
    'od',
    'Ġepid',
    'em',
    'Ġhe',
    'ap',
    'ĠR',
    'anch',
    'Ġan',
    'them',
    'Ġre',
    'app',
    'ĠAub',
    'urn',
    'Ġconc',
    'urrent',
    'ĠThrough',
    'out',
    'ĠP',
    'OST',
    'â',
    'ĺ',
    'Ġhom',
    'emade',
    'k',
    'ick',
    'B',
    'eg',
    'Ġch',
    'assis',
    'c',
    'ounter',
    'Ġmer',
    'ger',
    'Ġl',
    'aps',
    '2',
    '17',
    'un',
    'ion',
    'ĠTr',
    'igger',
    'Ġdeb',
    'ated',
    'Ġsil',
    'ently',
    'Ġrest',
    'raint',
    'B',
    'al',
    '0000',
    '000',
    'Ġform',
    'idable',
    'ĠFil',
    'ip',
    'Ġsacrific',
    'es',
    'F',
    'ood',
    'Ġdwar',
    'f',
    'ĠSe',
    'qu',
    'in',
    'ian',
    'More',
    'over',
    'Ġtang',
    'ible',
    'ops',
    'is',
    'ĠMine',
    'craft',
    'ĠRegist',
    'ration',
    'o',
    'an',
    'Ġrepresent',
    'ations',
    'Ġth',
    'irst',
    'Ġcor',
    'p',
    'ire',
    'ment',
    'M',
    'ade',
    'l',
    'oe',
    '>',
    '"',
    'c',
    'ats',
    '*',
    '.',
    'Ġgest',
    'ures',
    'gener',
    'al',
    'Le',
    'ague',
    'Ġpack',
    'ets',
    'ĠInspect',
    'or',
    'ĠBer',
    'g',
    'Ġfraud',
    'ulent',
    'Ġcritic',
    'ize',
    'F',
    'un',
    'Ġbl',
    'aming',
    'nd',
    'ra',
    'Ġsl',
    'ash',
    'ĠE',
    'ston',
    'Ġpropos',
    'ing',
    'Ġwh',
    'ales',
    'Ġtherap',
    'ist',
    'Ġsub',
    'set',
    'Ġle',
    'isure',
    'EL',
    'D',
    'ĠC',
    'VE',
    'ĠAct',
    'ivity',
    'Ġcul',
    'min',
    'sh',
    'op',
    'ĠD',
    'AY',
    'is',
    'cher',
    'ĠAdmir',
    'al',
    'ĠAtt',
    'acks',
    'Ġ19',
    '58',
    'Ġmem',
    'oir',
    'Ġfold',
    'ed',
    'Ġsex',
    'ist',
    'Ġ15',
    '3',
    'ĠL',
    'I',
    'Ġread',
    'ings',
    'Ġembarrass',
    'ment',
    'ĠEmploy',
    'ment',
    'w',
    'art',
    'ch',
    'in',
    'Ġcontin',
    'uation',
    'l',
    'ia',
    'Rec',
    'ently',
    'Ġd',
    'uel',
    'Ġevac',
    'uation',
    'ĠKash',
    'mir',
    'Ġdis',
    'position',
    'ĠR',
    'ig',
    'Ġbol',
    'ts',
    'Ġins',
    'urers',
    '4',
    '67',
    'M',
    'ex',
    'Ġret',
    'aliation',
    'Ġmis',
    'ery',
    'Ġunre',
    'asonable',
    'r',
    'aining',
    'I',
    'mm',
    'ĠP',
    'U',
    'em',
    'er',
    'Ġgen',
    'ital',
    'ãĤ',
    '³',
    'ĠC',
    'andy',
    'Ġon',
    'ions',
    'ĠP',
    'att',
    'lin',
    'er',
    'Ġconced',
    'ed',
    'Ġf',
    'a',
    'Ġfor',
    'c',
    'ĠH',
    'ernandez',
    'ĠGe',
    'off',
    'deb',
    'ian',
    'ĠTe',
    'ams',
    'Ġc',
    'ries',
    'Ġhome',
    'owners',
    '23',
    '7',
    'A',
    'BC',
    'Ġst',
    'itch',
    'Ġstat',
    'istic',
    'Ġhead',
    'ers',
    'ĠBi',
    'ology',
    'Ġmot',
    'ors',
    'ĠG',
    'EN',
    'ĠL',
    'ip',
    'Ġh',
    'ates',
    'Ġhe',
    'el',
    'S',
    'elf',
    'i',
    'pl',
    'ED',
    'IT',
    'ort',
    'ing',
    'Ġann',
    'ot',
    'ĠSpe',
    'ech',
    'old',
    'emort',
    'ĠJ',
    'avascript',
    'ĠLe',
    'Bron',
    'Ġfoot',
    'print',
    'Ġf',
    'n',
    'Ġseiz',
    'ures',
    'n',
    'as',
    'h',
    'ide',
    'Ġ19',
    '54',
    'ĠBe',
    'e',
    'ĠDecl',
    'aration',
    'ĠKat',
    'ie',
    'Ġreserv',
    'ations',
    'N',
    'R',
    'f',
    'emale',
    'Ġsatur',
    'ated',
    'Ġb',
    'iblical',
    'Ġtroll',
    's',
    'Dev',
    'ice',
    'ph',
    'otos',
    'Ġdr',
    'ums',
    'ãĥīãĥ©',
    'ãĤ´ãĥ³',
    'N',
    'ight',
    'f',
    'ighter',
    'ĠH',
    'ak',
    'ri',
    'ber',
    'Ġc',
    'ush',
    'Ġdiscipl',
    'inary',
    'ba',
    'um',
    'ĠG',
    'H',
    'ĠSch',
    'midt',
    'ilib',
    'rium',
    'Ġs',
    'ixty',
    'ĠKush',
    'ner',
    'ro',
    'ts',
    'Ġp',
    'und',
    'ĠR',
    'ac',
    'Ġspr',
    'ings',
    'Ġcon',
    've',
    'Bus',
    'iness',
    'F',
    'all',
    'Ġqual',
    'ifications',
    'Ġvers',
    'es',
    'Ġnarc',
    'iss',
    'ĠK',
    'oh',
    'ĠW',
    'ow',
    'ĠCharl',
    'ottesville',
    'ed',
    'o',
    'Ġinterrog',
    'ation',
    'ĠW',
    'ool',
    '36',
    '5',
    'B',
    'rian',
    'Ġâľ',
    'ĵ',
    'Ġalleg',
    'es',
    'ond',
    's',
    'id',
    'ation',
    'ĠJack',
    'ie',
    'y',
    'u',
    'Ġl',
    'akes',
    'Ġworth',
    'while',
    'Ġcryst',
    'als',
    'ĠJud',
    'a',
    'Ġcomp',
    'rehend',
    'Ġfl',
    'ush',
    'Ġabsor',
    'ption',
    'ĠO',
    'C',
    'Ġfright',
    'ened',
    'ĠCh',
    'ocolate',
    'Mart',
    'in',
    'Ġbu',
    'ys',
    'Ġbu',
    'cks',
    'Ġapp',
    'ell',
    'ĠChampions',
    'hips',
    'Ġlist',
    'ener',
    'ĠDef',
    'ensive',
    'Ġc',
    'z',
    'ud',
    's',
    'ĠM',
    'ate',
    'Ġre',
    'play',
    'Ġdecor',
    'ated',
    'Ġs',
    'unk',
    'ĠV',
    'IP',
    'ĠAn',
    'k',
    'Ġ19',
    '5',
    'aa',
    'aa',
    'Nob',
    'ody',
    'ĠMil',
    'k',
    'ĠG',
    'ur',
    'ĠM',
    'k',
    'ĠS',
    'ara',
    'Ġse',
    'ating',
    'ĠW',
    'id',
    'Tr',
    'ack',
    'Ġemploy',
    's',
    'Ġgig',
    'antic',
    'AP',
    'P',
    'ãĤ',
    '§',
    'in',
    'ventory',
    'Ġtow',
    'el',
    'at',
    'che',
    'l',
    'asting',
    'ĠT',
    'L',
    'Ġlat',
    'ency',
    'Ġkn',
    'e',
    'B',
    'er',
    'me',
    'aning',
    'Ġup',
    'held',
    'Ġplay',
    'ground',
    'Ġm',
    'ant',
    'S',
    'ide',
    'Ġstere',
    'o',
    'Ġnorth',
    'west',
    'Ġexception',
    'ally',
    'Ġr',
    'ays',
    'Ġrec',
    'urring',
    'D',
    'rive',
    'Ġup',
    'right',
    'Ġab',
    'duct',
    'ĠMar',
    'athon',
    'Ġgood',
    'bye',
    'Ġal',
    'phabet',
    'h',
    'p',
    'Ġcourt',
    'room',
    'ring',
    'ton',
    'ot',
    'hing',
    'T',
    'ag',
    'Ġdiplom',
    'ats',
    'Ġbar',
    'bar',
    'ĠAqu',
    'a',
    '18',
    '3',
    '33',
    '33',
    'Ġmat',
    'urity',
    'Ġinst',
    'ability',
    'ĠAp',
    'ache',
    'Ġ=',
    '==',
    'Ġfast',
    'ing',
    'ĠGr',
    'id',
    'Mod',
    'Loader',
    'Ġ15',
    '2',
    'A',
    'bs',
    'ĠOper',
    'ating',
    'ett',
    'i',
    'Ġacqu',
    'aint',
    'Don',
    'nell',
    'ĠK',
    'em',
    'ĠFor',
    'ge',
    'Ġarm',
    'ored',
    'M',
    'il',
    'Ġphilos',
    'ophers',
    'in',
    'vest',
    'Pl',
    'ayers',
    'â',
    'Ī',
    'Ġmy',
    'riad',
    'Ġcomr',
    'ades',
    'R',
    'ot',
    'Ġremember',
    'ing',
    'Ġcorrespond',
    's',
    'Ġprogram',
    'mers',
    'ĠLyn',
    'n',
    'Ġo',
    'lig',
    'Ġco',
    'herent',
    'yn',
    'chron',
    'ĠChem',
    'ical',
    'Ġj',
    'ugg',
    'p',
    'air',
    'post',
    's',
    'E',
    'ye',
    'ĠIn',
    'ner',
    'Ġsem',
    'ester',
    'ott',
    'est',
    'ĠEmir',
    'ates',
    'ric',
    'anes',
    'or',
    'ously',
    'm',
    'its',
    'ĠW',
    'is',
    'Ġd',
    'odge',
    'l',
    'ocation',
    'Ġf',
    'aded',
    'Am',
    'azon',
    'ĠPro',
    'ceed',
    'ĠIN',
    'FO',
    'j',
    'ournal',
    'ĠTru',
    'ck',
    'T',
    'en',
    'Ġ2',
    '17',
    'Ġstat',
    'utes',
    'm',
    'obile',
    'ĠT',
    'ypes',
    'Rec',
    'omm',
    'b',
    'uster',
    'pe',
    'x',
    'Ġleg',
    'ends',
    'Ġhead',
    'ache',
    'f',
    'aced',
    'ĠWi',
    'Fi',
    'if',
    'ty',
    'ĠH',
    'ER',
    'Ġcirc',
    'uits',
    'ER',
    'ROR',
    '22',
    '6',
    'ol',
    'in',
    'Ġcyl',
    'inder',
    'osp',
    'ace',
    'ik',
    'ers',
    'P',
    'rem',
    'Qu',
    'ant',
    'Ġconflic',
    'ting',
    'Ġslight',
    'est',
    'Ġfor',
    'ged',
    'ion',
    'age',
    'Step',
    'hen',
    'ĠK',
    'ub',
    'ĠOpp',
    'ortun',
    'ĠHe',
    'al',
    'Ġbl',
    'o',
    'Ġrul',
    'ers',
    'Ġh',
    'uh',
    'Ġsubmar',
    'ine',
    'f',
    'y',
    'ass',
    'er',
    'Ġallow',
    'ance',
    'ĠKas',
    'ich',
    'ĠT',
    'as',
    'ĠAustral',
    'ians',
    'Forge',
    'ModLoader',
    'ĠâĨ',
    'ĳ',
    'ĠMat',
    'rix',
    'am',
    'ins',
    'Ġ12',
    '00',
    'ĠAc',
    'qu',
    '23',
    '6',
    'D',
    'ocument',
    'ĠBre',
    'aking',
    '19',
    '3',
    'ĠSub',
    'st',
    'ĠRoll',
    'er',
    'ĠPro',
    'perties',
    'ĠN',
    'I',
    't',
    'ier',
    'Ġcr',
    'ushing',
    'Ġadvoc',
    'ating',
    'Further',
    'more',
    'keep',
    'ers',
    'Ġsex',
    'ism',
    'x',
    'd',
    'Ġcall',
    'er',
    'ĠS',
    'ense',
    'chie',
    've',
    'ĠT',
    'F',
    'Ġfuel',
    'ed',
    'Ġreminis',
    'cent',
    'Ġobs',
    'ess',
    'ur',
    'st',
    'Ġup',
    'hold',
    'ĠF',
    'ans',
    'het',
    'ics',
    'Ġâ',
    'Ĺ',
    'ĠB',
    'ath',
    'Ġbe',
    'verage',
    'Ġo',
    'scill',
    '25',
    '4',
    'Ġpol',
    'es',
    'Ġgrad',
    'ual',
    'Ġex',
    'ting',
    'ĠS',
    'uff',
    'ĠS',
    'uddenly',
    'Ġlik',
    'ing',
    'Ġ19',
    '49',
    'un',
    'ciation',
    'am',
    'ination',
    'ĠO',
    'mar',
    'ĠL',
    'V',
    'ĠCon',
    'sequently',
    'Ġsynt',
    'hes',
    'ĠG',
    'IF',
    'Ġp',
    'ains',
    'Ġinteract',
    'ing',
    'u',
    'ously',
    'inc',
    're',
    'Ġrum',
    'or',
    'ĠScient',
    'ology',
    '19',
    '7',
    'ĠZ',
    'ig',
    'Ġspe',
    'lling',
    'ĠA',
    'SS',
    'Ġexting',
    'u',
    'ms',
    'on',
    'Ġg',
    'h',
    'Ġremark',
    'ed',
    'ĠStrateg',
    'ic',
    'ĠM',
    'ON',
    'å',
    '¥',
    'g',
    'ae',
    'ĠWH',
    'AT',
    'E',
    'ric',
    'ĠCamp',
    'us',
    'Ġmeth',
    'ane',
    'Ġimag',
    'in',
    'J',
    'UST',
    'ĠAl',
    'm',
    'X',
    'T',
    'i',
    'q',
    'ĠR',
    'SS',
    'Ġwrong',
    'doing',
    'att',
    'a',
    'Ġbig',
    'ot',
    'Ġdemonstr',
    'ators',
    'ĠCal',
    'vin',
    'ĠV',
    'illa',
    'Ġmembr',
    'ane',
    'ĠAw',
    'esome',
    'Ġbenef',
    'ic',
    '26',
    '8',
    'Ġmagn',
    'ificent',
    'ĠL',
    'ots',
    'G',
    'reg',
    'ĠBor',
    'is',
    'Ġdetain',
    'ees',
    'ĠH',
    'erman',
    'Ġwhis',
    'pered',
    'Ġa',
    'we',
    'Prof',
    'essor',
    'fund',
    'ing',
    'Ġphys',
    'iological',
    'ĠDest',
    'ruction',
    'Ġlim',
    'b',
    'Ġmanip',
    'ulated',
    'Ġbub',
    'bles',
    'Ġpse',
    'ud',
    'Ġhyd',
    'ra',
    'ĠBrist',
    'ol',
    'Ġst',
    'ellar',
    'ĠExp',
    'ansion',
    'ĠK',
    'ell',
    'ĠInterest',
    'ingly',
    'Ġm',
    'ans',
    'Ġdrag',
    'ging',
    'Ġec',
    'ological',
    'ĠF',
    'it',
    'Ġg',
    'ent',
    'Ġbenef',
    'ited',
    'ĠHait',
    'i',
    'Ġpoly',
    'g',
    'ãĥ',
    'İ',
    'Ġ20',
    '30',
    'Ġpro',
    'w',
    'Ġrecon',
    'struction',
    'Ġwas',
    't',
    'Ġpsych',
    'ic',
    'ĠGree',
    'ks',
    'Hand',
    'ler',
    '16',
    '2',
    'ĠP',
    'ulse',
    'Ġsol',
    'icit',
    'Ġsy',
    's',
    'Ġinflu',
    'x',
    'ĠG',
    'entle',
    'per',
    'cent',
    'Ġprolifer',
    'ation',
    'Ġtax',
    'able',
    'Ġdisreg',
    'ard',
    'Ġesc',
    'aping',
    'Ġg',
    'inger',
    'Ġwith',
    'stand',
    'Ġdevast',
    'ated',
    'ĠD',
    'ew',
    'ser',
    'ies',
    'Ġinject',
    'ed',
    'ela',
    'ide',
    'Ġturn',
    'over',
    'he',
    'at',
    'Ļ',
    'Ĥ',
    'H',
    'appy',
    'ĠSil',
    'ent',
    'ãĤ',
    'Ń',
    'iv',
    'ism',
    'Ġir',
    'rational',
    'AM',
    'A',
    'Ġre',
    'ef',
    'r',
    'ub',
    'Ġ16',
    '2',
    'Ġbank',
    'ers',
    'ĠEth',
    'ics',
    'v',
    'v',
    'Ġcritic',
    'isms',
    'K',
    'n',
    '18',
    '6',
    'M',
    'ovie',
    'ĠT',
    'ories',
    'Ġno',
    'od',
    'Ġdist',
    'ortion',
    'F',
    'alse',
    'od',
    'ore',
    'Ġt',
    'asty',
    'Res',
    'earch',
    'ĠU',
    'ID',
    '-',
    ')',
    'Ġdivor',
    'ced',
    'ĠM',
    'U',
    'ĠHay',
    'es',
    'ĠIs',
    'n',
    'ian',
    'i',
    'ĠH',
    'Q',
    'Ġ"',
    '#',
    'ign',
    'ant',
    'Ġtra',
    'umatic',
    'ĠL',
    'ing',
    'H',
    'un',
    'Ġsab',
    'ot',
    'on',
    'line',
    'r',
    'andom',
    'Ġren',
    'amed',
    'ra',
    'red',
    'K',
    'A',
    'd',
    'ead',
    'Ã©',
    't',
    'ĠAss',
    'istance',
    'Ġse',
    'af',
    '++++',
    '++++',
    'Ġse',
    'ldom',
    'ĠWeb',
    'b',
    'Ġbo',
    'olean',
    'u',
    'let',
    'Ġref',
    'rain',
    'ĠDI',
    'Y',
    'ru',
    'le',
    'Ġshut',
    'ting',
    'Ġutil',
    'izing',
    'load',
    'ing',
    'ĠPar',
    'am',
    'co',
    'al',
    'oot',
    'er',
    'Ġattract',
    'ing',
    'ĠD',
    'ol',
    'Ġher',
    's',
    'ag',
    'netic',
    'ĠRe',
    'ach',
    'im',
    'o',
    'Ġdisc',
    'arded',
    'ĠP',
    'ip',
    '01',
    '5',
    'Ã¼',
    'r',
    'Ġm',
    'ug',
    'Im',
    'agine',
    'C',
    'OL',
    'Ġcurs',
    'ed',
    'ĠSh',
    'ows',
    'ĠCurt',
    'is',
    'ĠSach',
    's',
    'spe',
    'aking',
    'ĠV',
    'ista',
    'ĠFram',
    'ework',
    'ong',
    'o',
    'Ġsub',
    'reddit',
    'Ġcr',
    'us',
    'ĠO',
    'val',
    'R',
    'ow',
    'g',
    'rowing',
    'Ġinstall',
    'ment',
    'Ġgl',
    'ac',
    'ĠAdv',
    'ance',
    'EC',
    'K',
    'ĠLGBT',
    'Q',
    'LE',
    'Y',
    'Ġac',
    'et',
    'Ġsuccess',
    'ive',
    'ĠNic',
    'ole',
    'Ġ19',
    '57',
    'Qu',
    'ote',
    'Ġcircumst',
    'ance',
    'ack',
    'ets',
    'Ġ14',
    '2',
    'ort',
    'ium',
    'Ġguess',
    'ed',
    'ĠFr',
    'ame',
    'Ġperpet',
    'rators',
    'ĠAv',
    'iation',
    'ĠBen',
    'ch',
    'Ġhand',
    'c',
    'A',
    'p',
    'Ġ19',
    '56',
    '25',
    '9',
    'r',
    'and',
    'Net',
    'Message',
    'd',
    'in',
    'urt',
    'les',
    'h',
    'ig',
    'ĠV',
    'III',
    'ff',
    'iti',
    'ĠSw',
    'ords',
    'b',
    'ial',
    'Ġkidn',
    'apping',
    'dev',
    'ice',
    'Ġb',
    'arn',
    'ĠEl',
    'i',
    'auc',
    'as',
    'S',
    'end',
    'Con',
    'structed',
    'ĠÂ',
    '½',
    'Ġneed',
    'les',
    'Ġad',
    'vertisements',
    'Ġv',
    'ou',
    'Ġexhib',
    'ited',
    'ĠFort',
    'ress',
    'As',
    'k',
    'B',
    'erry',
    'TY',
    'PE',
    'Ġcan',
    'cers',
    'ump',
    'ing',
    'ĠTerrit',
    'ory',
    'Ġpr',
    'ud',
    'Ġn',
    'as',
    'Ġathe',
    'ist',
    'Ġbal',
    'ances',
    'ãģ',
    'Ł',
    'ĠSh',
    'awn',
    '&',
    '&',
    'Ġland',
    'sc',
    'ĠR',
    'GB',
    'Ġpet',
    'ty',
    'Ġex',
    'cellence',
    'Ġtransl',
    'ations',
    'Ġpar',
    'cel',
    'ĠChe',
    'v',
    'E',
    'ast',
    'ĠOut',
    'put',
    'im',
    'i',
    'Ġamb',
    'ient',
    'ĠTh',
    'reat',
    'Ġvill',
    'ains',
    'Ġ5',
    '50',
    'IC',
    'A',
    'Ġtall',
    'er',
    'Ġle',
    'aking',
    'c',
    'up',
    'Ġpol',
    'ish',
    'Ġinfect',
    'ious',
    'ĠK',
    'C',
    'Ġ@',
    '@',
    'back',
    'ground',
    'Ġbureaucr',
    'acy',
    'ĠS',
    'ai',
    'un',
    'less',
    'it',
    'ious',
    'ĠSky',
    'pe',
    'At',
    'l',
    'ID',
    'ENT',
    '00',
    '8',
    'Ġhyp',
    'ocr',
    'Ġpit',
    'chers',
    'Ġguess',
    'ing',
    'ĠF',
    'INAL',
    'Bet',
    'ween',
    'Ġvill',
    'agers',
    'Ġ25',
    '2',
    'f',
    'ashion',
    'ĠTun',
    'is',
    'Be',
    'h',
    'ĠEx',
    'c',
    'ĠM',
    'ID',
    '28',
    '8',
    'ĠHas',
    'kell',
    '19',
    '6',
    'ĠN',
    'OR',
    'Ġspec',
    's',
    'Ġinv',
    'ari',
    'Ġgl',
    'ut',
    'ĠC',
    'ars',
    'Ġimp',
    'ulse',
    'Ġhon',
    'ors',
    'g',
    'el',
    'Ġjurisd',
    'ictions',
    'ĠBund',
    'le',
    'ul',
    'as',
    'Calif',
    'ornia',
    'ĠIncre',
    'ase',
    'Ġp',
    'ear',
    'Ġsing',
    'les',
    'Ġc',
    'ues',
    'Ġunder',
    'went',
    'ĠW',
    'S',
    'Ġexagger',
    'ated',
    'Ġdub',
    'ious',
    'Ġfl',
    'ashing',
    'L',
    'OG',
    ')',
    '].',
    'J',
    'ournal',
    't',
    'g',
    'V',
    'an',
    'ĠI',
    'stanbul',
    'ĠIn',
    'sp',
    'ĠFrank',
    'en',
    'D',
    'raw',
    'Ġsad',
    'ness',
    'Ġiron',
    'ic',
    'ĠF',
    'ry',
    'x',
    'c',
    'Ġ16',
    '4',
    'is',
    'ch',
    'W',
    'ay',
    'ĠProtest',
    'ant',
    'h',
    'orn',
    'Ġun',
    'aff',
    'ĠV',
    'iv',
    'ill',
    'as',
    'ĠProduct',
    'ions',
    'ĠH',
    'ogan',
    'Ġper',
    'imeter',
    'ĠS',
    'isters',
    'Ġspont',
    'aneous',
    'Ġdown',
    'side',
    'Ġdescend',
    'ants',
    'Ġor',
    'n',
    'w',
    'orm',
    'Japan',
    'ese',
    'Ġ19',
    '55',
    'Ġ15',
    '1',
    'ĠDo',
    'ing',
    'els',
    'en',
    'umb',
    'les',
    'Ġrad',
    'ically',
    'ĠDr',
    'um',
    'ĠB',
    'ach',
    'Ġli',
    'abilities',
    'ĠO',
    'B',
    'ĠElement',
    'ary',
    'Ġmem',
    'e',
    'yn',
    'es',
    'Ġfinger',
    'print',
    'ĠGr',
    'ab',
    'Ġundert',
    'ake',
    'Mem',
    'bers',
    'ĠRead',
    'er',
    'ĠSim',
    's',
    'g',
    'od',
    'Ġhypot',
    'hetical',
    's',
    'cient',
    'ĠA',
    'J',
    'Ġchar',
    'ism',
    'Ġad',
    'missions',
    'ĠMiss',
    'ile',
    'tr',
    'ade',
    'Ġexerc',
    'ising',
    'ĠBack',
    'ground',
    'W',
    'ritten',
    'Ġvoc',
    'als',
    'whe',
    'ther',
    'Ġv',
    'i',
    'ĠW',
    'inner',
    'Ġl',
    'itter',
    'ĠSh',
    'ooting',
    'ST',
    'EM',
    'ãĤ',
    '¡',
    'ĠA',
    'FL',
    'Ġvari',
    'ability',
    'Ġe',
    'ats',
    'ĠD',
    'PS',
    'b',
    'row',
    'Ġeleph',
    'ants',
    'Ġstr',
    'at',
    'Ġ',
    'Å',
    'Ġsett',
    'lers',
    'Matt',
    'hew',
    'Ġin',
    'advert',
    'H',
    'I',
    'ĠIM',
    'F',
    'ĠGo',
    'al',
    'Ġnerv',
    'es',
    'John',
    'son',
    'ey',
    'e',
    'ablish',
    'ment',
    'Th',
    'ursday',
    'BIL',
    'ITY',
    'H',
    'ad',
    'am',
    'oto',
    'het',
    'amine',
    'ep',
    's',
    'Ġmit',
    'ochond',
    'Ġcomp',
    'ressed',
    'ĠTre',
    'vor',
    'ĠAnim',
    'als',
    'T',
    'ool',
    'L',
    'ock',
    'Ġtwe',
    'ak',
    'Ġpin',
    'ch',
    'Ġcancell',
    'ation',
    'P',
    'ot',
    'Ġfoc',
    'al',
    'ĠAst',
    'ron',
    '17',
    '3',
    'ĠA',
    'SC',
    'ĠO',
    'THER',
    'umn',
    'i',
    'Ġdem',
    'ise',
    'd',
    'l',
    'Ù',
    'ħ',
    'Sem',
    'itism',
    'Ġcr',
    'acking',
    'Ġcollabor',
    'ative',
    'Ġexpl',
    'ores',
    's',
    'ql',
    'Ġher',
    'bs',
    'Ġconfig',
    'urations',
    'm',
    'is',
    'ĠRes',
    'ult',
    'ace',
    'y',
    'ĠSm',
    'oke',
    'Ġsan',
    'ct',
    'el',
    'ia',
    'Ġdeg',
    'ener',
    'Ġdeep',
    'est',
    'Ġscream',
    'ed',
    'Ġn',
    'ap',
    'Soft',
    'ware',
    'ĠST',
    'AR',
    'E',
    'F',
    'ĠX',
    'in',
    'spons',
    'ored',
    'mans',
    'hip',
    '23',
    '3',
    'Ġprim',
    'aries',
    'Ġfilter',
    'ing',
    'Ġas',
    'semble',
    'm',
    'il',
    'ĠMy',
    'ers',
    'b',
    'ows',
    'Ġpun',
    'ched',
    'M',
    'ic',
    'Ġinnov',
    'ations',
    'Ġfun',
    'c',
    'and',
    'o',
    'Ġfr',
    'acking',
    'ĠV',
    'ul',
    'Ð¾',
    'Ð',
    'osh',
    'op',
    'ĠIm',
    'mun',
    'Ġsett',
    'ling',
    'Ġadolesc',
    'ents',
    'Ġreb',
    'uilding',
    'Ġtransform',
    'ing',
    'Ġpar',
    'ole',
    'Ġhar',
    'bor',
    'Ġbook',
    'ing',
    'ot',
    'ional',
    'onge',
    'vity',
    'ĠY',
    'o',
    'b',
    'ug',
    'Ġemer',
    'ges',
    'ĠMethod',
    's',
    'ĠCh',
    'u',
    'P',
    'res',
    'ĠDun',
    'geons',
    'Ġtra',
    'iling',
    'ĠR',
    'um',
    'ĠH',
    'ugh',
    'å¤',
    '©',
    'ĠE',
    'ra',
    'ĠBatt',
    'les',
    'Res',
    'ults',
    'ĠTr',
    'ading',
    'Ġvers',
    'a',
    'c',
    'ss',
    'ax',
    'ies',
    'he',
    'et',
    'Ġgre',
    'ed',
    '19',
    '89',
    'Ġgard',
    'ens',
    'Ġconting',
    'ent',
    'P',
    'ark',
    'ĠLeaf',
    's',
    'h',
    'ook',
    'ro',
    'be',
    'Ġdiplom',
    'acy',
    'ĠF',
    'uel',
    'ĠInv',
    'asion',
    'Ġupgr',
    'ading',
    'M',
    'ale',
    'Ġe',
    'lic',
    'Ġrelent',
    'less',
    'ĠCo',
    'venant',
    'ap',
    'esh',
    'ĠT',
    'rop',
    'T',
    'y',
    'pro',
    'duction',
    'art',
    'y',
    'Ġpun',
    'ches',
    'ak',
    'o',
    'cyclop',
    'edia',
    'ĠR',
    'abbit',
    'ĠHD',
    'MI',
    'Ġ14',
    '1',
    'Ġf',
    'oil',
    'Item',
    'Image',
    'ĠF',
    'G',
    'Ġimplement',
    'ations',
    'ĠP',
    'om',
    'ixt',
    'ures',
    'Ġaw',
    'ait',
    'Ġ3',
    '30',
    'am',
    'us',
    'Ġumb',
    'rella',
    'Ġfore',
    'see',
    'se',
    'par',
    'Ġcircum',
    'cision',
    'Ġperipher',
    'al',
    'S',
    'ay',
    'ĠExper',
    't',
    'In',
    'c',
    'Ġwithd',
    'rew',
    'ĠAnd',
    'ers',
    'f',
    'ried',
    'Ġradio',
    'active',
    'ĠOp',
    'ening',
    'Ġboard',
    'ing',
    'ĠN',
    'D',
    'Ġover',
    'throw',
    'Act',
    'iv',
    'W',
    'P',
    'ĠAct',
    's',
    '×',
    'Ļ',
    'Ġmot',
    'ions',
    'v',
    'ic',
    'ĠM',
    'ighty',
    'ĠDef',
    'ender',
    'a',
    'er',
    'Ġthank',
    'ful',
    'ĠK',
    'illing',
    'ĠBr',
    'is',
    'mo',
    'il',
    'Ġpredict',
    'ing',
    '26',
    '6',
    'ch',
    'oice',
    'Ġkill',
    'ers',
    'Ġinc',
    'ub',
    'ĠChe',
    'st',
    'ather',
    'ing',
    'Ġpro',
    'claimed',
    'fl',
    'ower',
    'oss',
    'om',
    'umbled',
    'ore',
    'ĠCy',
    'cling',
    'ĠOccup',
    'y',
    'AG',
    'ES',
    'P',
    'en',
    'ĠY',
    'ug',
    'Ġpack',
    'aged',
    'Ġheight',
    'ened',
    'c',
    'ot',
    'st',
    'ack',
    'C',
    'ond',
    'Ġst',
    'amps',
    'm',
    'age',
    'Ġpersu',
    'aded',
    'Ġens',
    'l',
    'ĠCard',
    'inal',
    'Ġsol',
    'itary',
    'Ġpossess',
    'ing',
    'ĠC',
    'ork',
    'Ġev',
    'id',
    'ĠT',
    'ay',
    'Ġbl',
    'ues',
    'Ġextrem',
    'ism',
    'Ġlun',
    'ar',
    'Ġcl',
    'own',
    'Te',
    'chn',
    'Ġfest',
    'ivals',
    'ĠPv',
    'P',
    'ĠL',
    'ar',
    'Ġconsequ',
    'ently',
    'p',
    'resent',
    'Ġsom',
    'eday',
    'ç',
    'İĭ',
    'ĠMet',
    'eor',
    'Ġtour',
    'ing',
    'c',
    'ulture',
    'Ġbe',
    'aches',
    'S',
    'hip',
    'c',
    'ause',
    'ĠFl',
    'ood',
    'ãĥ',
    '¯',
    'Ġpur',
    'ity',
    'th',
    'ose',
    'Ġem',
    'ission',
    'b',
    'olt',
    'Ġch',
    'ord',
    'ĠScript',
    'ure',
    'L',
    'u',
    'Ġ$',
    '{',
    'cre',
    'ated',
    'Other',
    's',
    '25',
    '8',
    'Ġelement',
    'al',
    'Ġannoy',
    'ed',
    'ĠA',
    'E',
    'd',
    'an',
    'ĠS',
    'ag',
    'Res',
    'earchers',
    'Ġfair',
    'y',
    'âĢĵ',
    'âĢĵ',
    '========',
    '====',
    'Sm',
    'art',
    'GG',
    'GG',
    'Ġskelet',
    'ons',
    'Ġpup',
    'ils',
    'link',
    'ed',
    'Ġur',
    'gency',
    'en',
    'abled',
    'ĠF',
    'uck',
    'Ġcoun',
    'cill',
    'r',
    'ab',
    'U',
    'AL',
    'T',
    'I',
    'Ġlif',
    'es',
    'Ġconf',
    'essed',
    'B',
    'ug',
    'Ġharm',
    'on',
    'ĠCON',
    'FIG',
    'ĠNe',
    'utral',
    'D',
    'ouble',
    'Ġst',
    'aple',
    'ĠSH',
    'A',
    'Brit',
    'ish',
    'ĠSN',
    'P',
    'AT',
    'OR',
    'oc',
    'o',
    'Ġswing',
    'ing',
    'ge',
    'x',
    'ole',
    'on',
    'pl',
    'ain',
    'ĠMiss',
    'ing',
    'ĠTro',
    'phy',
    'v',
    'ari',
    'ran',
    'ch',
    'Ġ3',
    '01',
    '4',
    '40',
    '00000000',
    '00000000',
    'Ġrest',
    'oring',
    'Ġha',
    'ul',
    'uc',
    'ing',
    'ner',
    'g',
    'Ġfut',
    'ures',
    'Ġstrateg',
    'ist',
    'quest',
    'ion',
    'Ġlater',
    'al',
    'ĠB',
    'ard',
    'Ġs',
    'or',
    'ĠRhod',
    'es',
    'ĠD',
    'owntown',
    '?????',
    '-',
    'ĠL',
    'it',
    'ĠB',
    'ened',
    'Ġco',
    'il',
    'st',
    'reet',
    'ĠPort',
    'al',
    'FI',
    'LE',
    'ĠG',
    'ru',
    '*',
    ',',
    '23',
    '1',
    'ne',
    'um',
    'Ġsuck',
    'ed',
    'Ġr',
    'apper',
    'Ġtend',
    'encies',
    'ĠLaure',
    'n',
    'cell',
    'aneous',
    '26',
    '7',
    'Ġbrow',
    'se',
    'Ġover',
    'c',
    'head',
    'er',
    'o',
    'ise',
    'Ġbe',
    'et',
    'ĠG',
    'le',
    'St',
    'ay',
    'Ġm',
    'um',
    'Ġtyp',
    'ed',
    'Ġdiscount',
    's',
    'T',
    'alk',
    'ĠO',
    'g',
    'ex',
    'isting',
    'ĠS',
    'ell',
    'u',
    'ph',
    'C',
    'I',
    'ĠAust',
    'rian',
    'ĠW',
    'arm',
    'Ġdismiss',
    'al',
    'Ġaver',
    'ages',
    'c',
    'amera',
    'Ġalleg',
    'iance',
    'L',
    'AN',
    '="',
    '#',
    'Ġcomment',
    'ators',
    'ĠSet',
    'ting',
    'ĠMid',
    'west',
    'Ġpharm',
    'ac',
    'ĠEX',
    'P',
    'Ġstain',
    'less',
    'Ch',
    'icago',
    'Ġt',
    'an',
    '24',
    '4',
    'Ġcountry',
    'side',
    'ĠV',
    'ac',
    '29',
    '5',
    'Ġpin',
    'ned',
    'Ġcr',
    'ises',
    'Ġstandard',
    'ized',
    'T',
    'ask',
    'ĠJ',
    'ail',
    'ĠD',
    'ocker',
    'col',
    'ored',
    'f',
    'orth',
    '"',
    '},',
    'Ġpat',
    'rons',
    'Ġsp',
    'ice',
    'Ġm',
    'ourn',
    'ĠM',
    'ood',
    'Ġlaund',
    'ry',
    'Ġequ',
    'ip',
    'ĠM',
    'ole',
    'y',
    'll',
    'ĠTH',
    'C',
    'n',
    'ation',
    'ĠSher',
    'lock',
    'Ġiss',
    'u',
    'ĠK',
    're',
    'ĠAmeric',
    'as',
    'ĠA',
    'AA',
    'Ġsystem',
    'atically',
    'Ġcont',
    'ra',
    'ĠS',
    'ally',
    'Ġrational',
    'e',
    'Ġcar',
    'riage',
    'Ġpe',
    'aks',
    'Ġcontrad',
    'iction',
    'ens',
    'ation',
    'ĠFail',
    'ure',
    'Ġpro',
    'ps',
    'Ġnames',
    'pace',
    'Ġc',
    'ove',
    'field',
    's',
    'ãĤ',
    'ĭ',
    'Ġw',
    'ool',
    'ĠC',
    'atch',
    'Ġpresum',
    'ed',
    'ĠD',
    'iana',
    'r',
    'agon',
    'ig',
    'i',
    'Ġh',
    'amm',
    'Ġst',
    'unt',
    'ĠG',
    'UI',
    'ĠObserv',
    'atory',
    'ĠSh',
    'ore',
    'Ġsmell',
    's',
    'ann',
    'ah',
    'Ġcock',
    'pit',
    'ĠD',
    'uterte',
    '8',
    '50',
    'Ġopp',
    'ressed',
    'bre',
    'aker',
    'ĠCont',
    'ribut',
    'ĠPer',
    'u',
    'ĠMons',
    'anto',
    'ĠAtt',
    'empt',
    'Ġcommand',
    'ing',
    'Ġfr',
    'idge',
    'ĠR',
    'in',
    'ĠChe',
    'ss',
    'ual',
    'ity',
    'Ġo',
    'l',
    'Republic',
    'an',
    'ĠGl',
    'ory',
    'ĠW',
    'IN',
    '....',
    '...',
    'ag',
    'ent',
    'read',
    'ing',
    'Ġin',
    'h',
    'J',
    'ones',
    'Ġcl',
    'icks',
    'al',
    'an',
    'Ġ[',
    '];',
    'ĠMaj',
    'esty',
    'ĠC',
    'ed',
    'op',
    'us',
    'ate',
    'l',
    'Ã',
    'ª',
    'AR',
    'C',
    'ĠEc',
    'uador',
    'ãĥ',
    'ł',
    'ĠK',
    'uro',
    'Ġritual',
    's',
    'Ġcapt',
    'ive',
    'Ġoun',
    'ce',
    'Ġdisag',
    'reement',
    'Ġsl',
    'og',
    'f',
    'uel',
    'P',
    'et',
    'M',
    'ail',
    'Ġexerc',
    'ised',
    'Ġsol',
    'ic',
    'Ġrain',
    'fall',
    'Ġdev',
    'otion',
    'ĠAss',
    'essment',
    'Ġrob',
    'otic',
    'opt',
    'ions',
    'ĠR',
    'P',
    'ĠFam',
    'ilies',
    'ĠFl',
    'ames',
    'Ġassign',
    'ments',
    '00',
    '7',
    'aked',
    'own',
    'Ġvoc',
    'abulary',
    'Re',
    'illy',
    'Ġc',
    'aval',
    'g',
    'ars',
    'Ġsupp',
    'ressed',
    'ĠS',
    'ET',
    'ĠJohn',
    's',
    'Ġwar',
    'p',
    'bro',
    'ken',
    'Ġstat',
    'ues',
    'Ġadvoc',
    'ated',
    'Ġ2',
    '75',
    'Ġper',
    'il',
    'om',
    'orph',
    'ĠF',
    'emin',
    'per',
    'fect',
    'Ġh',
    'atch',
    'L',
    'ib',
    '5',
    '12',
    'Ġlif',
    'elong',
    '3',
    '13',
    'Ġche',
    'eks',
    'Ġnum',
    'bered',
    'ĠM',
    'ug',
    'B',
    'ody',
    'ra',
    'vel',
    'We',
    'ight',
    'ĠJ',
    'ak',
    'ĠHe',
    'ath',
    'Ġkiss',
    'ing',
    'ĠJ',
    'UST',
    'Ġw',
    'aving',
    'u',
    'pload',
    'Ġins',
    'ider',
    'ĠPro',
    'gressive',
    'ĠFil',
    'ter',
    'tt',
    'a',
    'ĠBe',
    'am',
    'Ġviol',
    'ently',
    'ip',
    'ation',
    'Ġskept',
    'icism',
    'Ġ19',
    '18',
    'ĠAnn',
    'ie',
    'ĠS',
    'I',
    'Ġgen',
    'etics',
    'Ġon',
    'board',
    'at',
    'l',
    'ĠFried',
    'man',
    'ĠB',
    'ri',
    'cept',
    'ive',
    'Ġpir',
    'ate',
    'ĠRep',
    'orter',
    '27',
    '8',
    'Ġmyth',
    'ology',
    'Ġe',
    'clipse',
    'Ġsk',
    'ins',
    'Ġgly',
    'ph',
    'ing',
    'ham',
    'F',
    'iles',
    'C',
    'our',
    'w',
    'omen',
    'Ġreg',
    'imes',
    'Ġphotograp',
    'hed',
    'K',
    'at',
    'ĠMA',
    'X',
    'Offic',
    'ials',
    'Ġunexpected',
    'ly',
    'Ġimpress',
    'ions',
    'F',
    'ront',
    ';;;;',
    ';;;;',
    'Ġsuprem',
    'acy',
    'Ġs',
    'ang',
    'Ġaggrav',
    'ated',
    'Ġabrupt',
    'ly',
    'ĠS',
    'ector',
    'Ġexc',
    'uses',
    'Ġcost',
    'ing',
    'ide',
    'press',
    'St',
    'ack',
    'ĠR',
    'NA',
    'ob',
    'il',
    'Ġghost',
    's',
    'ld',
    'on',
    'at',
    'ibility',
    'Top',
    'ics',
    'Ġreim',
    'burse',
    'ĠH',
    'M',
    'ĠDe',
    'g',
    'Ġth',
    'ief',
    'y',
    'et',
    'ogen',
    'esis',
    'le',
    'aning',
    'ĠK',
    'ol',
    'ĠB',
    'asketball',
    'Ġf',
    'i',
    'ĠSee',
    'ing',
    'Ġrecy',
    'cling',
    'Ġ[',
    '-',
    'Cong',
    'ress',
    'Ġlect',
    'ures',
    'P',
    'sy',
    'Ġne',
    'p',
    'Ġm',
    'aid',
    'Ġori',
    'ented',
    'A',
    'X',
    'Ġrespect',
    'ful',
    're',
    'ne',
    'fl',
    'ush',
    'ĠUn',
    'loaded',
    're',
    'quest',
    'gr',
    'id',
    'ĠAltern',
    'atively',
    'ĠHug',
    'o',
    'Ġdec',
    'ree',
    'ĠBuddh',
    'ism',
    'and',
    'um',
    'And',
    'roid',
    'ĠCong',
    'o',
    'ĠJoy',
    'ce',
    'Ġacknowled',
    'ging',
    'hes',
    'ive',
    'ĠTom',
    'orrow',
    'ĠH',
    'iro',
    'th',
    'ren',
    'ĠM',
    'aced',
    'Ġho',
    'ax',
    'ĠIncre',
    'ased',
    'ĠPr',
    'adesh',
    'W',
    'ild',
    '____',
    '__',
    '16',
    '1',
    'Ġa',
    'unt',
    'Ġdistribut',
    'ing',
    'ĠT',
    'ucker',
    'ĠSS',
    'L',
    'ĠW',
    'olves',
    'B',
    'uilding',
    'ou',
    'lt',
    'ĠLu',
    'o',
    'ĠY',
    'as',
    'ĠSp',
    'ir',
    'ĠSh',
    'ape',
    'ĠCamb',
    'od',
    'ĠIP',
    'v',
    'Ġm',
    'l',
    'Ġext',
    'rad',
    '39',
    '0',
    'ĠPenn',
    'y',
    'd',
    'ream',
    'Ġstation',
    'ed',
    'opt',
    'ional',
    'ew',
    'orthy',
    '.',
    '</',
    'Ġundert',
    'aking',
    'Ġchick',
    'ens',
    'Ġstimul',
    'i',
    'ĠEl',
    'se',
    'ig',
    'ators',
    'ĠBegin',
    'ning',
    'ct',
    'ory',
    'Ġprep',
    'ares',
    'Ġdel',
    'ta',
    'Ġvic',
    'inity',
    't',
    'ool',
    'Ġworks',
    'hops',
    'M',
    'Hz',
    'Ġaccus',
    'ation',
    'Ġhist',
    'ories',
    'rop',
    'olis',
    'ĠChurch',
    'ill',
    'Ġne',
    'on',
    'Ġb',
    'aff',
    'd',
    'ies',
    'may',
    'be',
    'Ġè£ı',
    'è¦ļéĨĴ',
    'Ġsympt',
    'om',
    'EC',
    'H',
    'ĠMan',
    'uel',
    'Ġban',
    'ana',
    'ĠH',
    'B',
    'Ġ',
    '****',
    'ĠKore',
    'ans',
    'c',
    'oll',
    'F',
    'B',
    'Ġpr',
    'aying',
    'ĠCann',
    'ot',
    'ĠM',
    'ile',
    'Ġembr',
    'acing',
    'ĠSil',
    'k',
    '39',
    '3',
    'ot',
    'ers',
    'F',
    'D',
    'Ġday',
    'light',
    'al',
    'ias',
    'ĠBrig',
    'ade',
    'ĠHann',
    'ah',
    'Ġcler',
    'gy',
    'Ġs',
    'outheast',
    'Ġalcohol',
    'ic',
    'Ġpropos',
    'es',
    'liv',
    'ion',
    'Ġcalcul',
    'ating',
    'Ġstim',
    'ulate',
    'Ġspl',
    'itting',
    'e',
    'ight',
    'ĠInd',
    'y',
    'pl',
    'ays',
    'ĠP',
    'ik',
    'Ġdom',
    'est',
    'Ġforg',
    'iveness',
    'ĠR',
    'ings',
    'pat',
    'ient',
    'kins',
    'on',
    'M',
    'ont',
    'ig',
    'ible',
    ';',
    '"',
    'Ġperiod',
    'ically',
    'amm',
    'ad',
    'ĠBr',
    'itt',
    'p',
    'ard',
    'Ġarbit',
    'ration',
    'ĠSchne',
    'ider',
    'ĠCorpor',
    'ate',
    'ĠMay',
    'a',
    'Ġsn',
    'akes',
    'a',
    'um',
    'Ġbl',
    'asted',
    'Ġmyster',
    'ies',
    'Ġrev',
    'ive',
    'oc',
    'amp',
    'ĠD',
    'odge',
    'ĠOper',
    'a',
    '27',
    '9',
    'Ġor',
    'phan',
    'Ġspec',
    'ifies',
    'ĠM',
    'ets',
    'D',
    'uration',
    'H',
    'en',
    'Ġfire',
    'works',
    'Ġprosec',
    'ute',
    'ĠTill',
    'erson',
    'd',
    'p',
    'us',
    'age',
    'l',
    'iness',
    'ĠDeb',
    'ian',
    'Ġ2',
    '24',
    'ris',
    'es',
    'ĠIn',
    'fect',
    'at',
    'ra',
    'ĠR',
    'R',
    'ĠL',
    'or',
    'd',
    'iff',
    'ĠCharl',
    'eston',
    'Ġac',
    'oustic',
    'Ġam',
    'use',
    '3',
    '30',
    'Ġc',
    'er',
    'ĠT',
    'ac',
    'Ġ[',
    '+',
    'Ġcard',
    'iac',
    'ĠRestaur',
    'ant',
    'er',
    'gy',
    'Ġf',
    'uzz',
    'Ġbit',
    'es',
    'Ġhazard',
    'ous',
    'Ġbr',
    'ighter',
    'r',
    'ans',
    'ĠStephan',
    'ie',
    'ext',
    'ra',
    'RE',
    'T',
    'ĠChrist',
    'ine',
    'ĠS',
    'ue',
    'stat',
    'ement',
    'Ġbol',
    'ster',
    'Ġant',
    'it',
    'Rad',
    'io',
    'B',
    'IT',
    'ãĤ',
    '°',
    'Ġvis',
    'ions',
    'ĠCon',
    'cept',
    'Ġin',
    'line',
    'ĠPhilos',
    'ophy',
    'is',
    'ans',
    'ĠIr',
    'ving',
    'Ã',
    '£',
    't',
    'aking',
    'Ġincons',
    'ist',
    'ĠKum',
    'ar',
    'Ġl',
    'ig',
    'ĠSch',
    'umer',
    'ĠReg',
    'ulations',
    'ĠH',
    'z',
    'th',
    'ro',
    'ĠV',
    'oldemort',
    'ĠM',
    'ED',
    'ĠFreder',
    'ick',
    'P',
    'ad',
    '22',
    '1',
    'Ġalleg',
    'ing',
    'ĠCommun',
    'ication',
    'Ġ16',
    '7',
    'Ġforecast',
    's',
    'Ġsp',
    'iders',
    'Or',
    'gan',
    'ĠParticip',
    'ants',
    'ĠO',
    'ps',
    'des',
    'ign',
    'Cl',
    'ose',
    'Ġfact',
    'o',
    'Ġbom',
    'bers',
    'res',
    'istant',
    'ateg',
    'ories',
    'S',
    'chool',
    'Ġhom',
    'ework',
    'Ġcor',
    'ro',
    'T',
    'uesday',
    'ĠBrend',
    'an',
    'ĠM',
    'X',
    'ĠT',
    'S',
    'ĠSt',
    'ri',
    'Ġstake',
    'holders',
    'ĠMillenn',
    'ium',
    'Ġtransfer',
    'ring',
    'J',
    'ud',
    'Ġt',
    'ac',
    'Ġ16',
    '00',
    'ĠSD',
    'K',
    'r',
    'b',
    'Ġinterpret',
    'ations',
    'ĠS',
    'G',
    'Ġup',
    'stairs',
    'ĠHar',
    'vest',
    'Ġvag',
    'ina',
    'Ġing',
    'est',
    'x',
    'f',
    'ĠOr',
    'ion',
    'ĠJoe',
    'y',
    'Ġsand',
    'wic',
    'Ġimm',
    'ortal',
    'Ġfl',
    'ipped',
    'ort',
    'ex',
    'threat',
    'ening',
    'Ġsn',
    'iper',
    'Ġconver',
    'ts',
    'Ġinstall',
    'ations',
    'ĠBul',
    'gar',
    'ors',
    'che',
    'm',
    'ails',
    'Ġl',
    'ure',
    'Ġnarrow',
    'ly',
    'Ġgren',
    'ade',
    'ĠG',
    'ing',
    'Ġunder',
    'wear',
    '------------',
    '--',
    'Ġch',
    'ased',
    'ĠV',
    'AL',
    'Ġparent',
    'ing',
    'ĠH',
    'amb',
    'ĠBl',
    'az',
    'Ġanarch',
    'ist',
    'ĠMed',
    'ian',
    'ĠProgram',
    's',
    'Î',
    '½',
    'Ġob',
    'j',
    'ĠN',
    'okia',
    'orm',
    'an',
    'an',
    'qu',
    'at',
    'ism',
    'op',
    'a',
    'Ġfulf',
    'illing',
    'Ġpupp',
    'y',
    'Ġent',
    'it',
    'ĠSebast',
    'ian',
    'Ġshoot',
    'ers',
    'Ġric',
    'her',
    'è',
    '¡',
    'Ġtempt',
    'ed',
    'ĠAT',
    'T',
    'ĠC',
    'V',
    'Ġto',
    're',
    'Res',
    'ource',
    'ĠDevil',
    's',
    '40',
    '8',
    'in',
    'ational',
    'Ġass',
    'urance',
    'ĠDar',
    'ren',
    'Ġwh',
    'ichever',
    'pos',
    'ure',
    'Ġf',
    'ury',
    'St',
    'ock',
    'Ġunivers',
    'ally',
    'resp',
    'onse',
    'Ġo',
    'ak',
    'Ġwork',
    'load',
    'ĠCor',
    'ner',
    'ee',
    'le',
    '"',
    '...',
    'Ġdepri',
    'ved',
    'k',
    'owski',
    'Ġcast',
    's',
    'Ġaffili',
    'ation',
    'ĠA',
    'ch',
    'ĠAs',
    'ked',
    'at',
    'he',
    'Ġl',
    'act',
    'ĠTh',
    'u',
    'r',
    'm',
    'Ġair',
    'lines',
    'Ġnot',
    'ions',
    'Form',
    'at',
    'ĠF',
    'AA',
    'ãĥ',
    'Ĭ',
    'dri',
    'ver',
    'Ġtrans',
    'cend',
    'S',
    'ettings',
    'ĠPro',
    'secut',
    'Ġsp',
    'inal',
    'Ġdefault',
    's',
    'F',
    'K',
    'Ġpref',
    'ers',
    'rend',
    'ered',
    'th',
    'us',
    'fil',
    'm',
    'Ġt',
    'iger',
    'ĠSp',
    'icer',
    'rec',
    'ogn',
    'ĠRug',
    'by',
    'Net',
    'work',
    'Ġp',
    'ity',
    'Ġcomp',
    'artment',
    'c',
    'asters',
    'ĠMon',
    'roe',
    'Ġ7',
    '20',
    'Ġcorrect',
    'ions',
    'Ġdop',
    'amine',
    'ĠA',
    'Z',
    'C',
    'ut',
    'Ġro',
    'omm',
    'Ġspec',
    'ulate',
    'H',
    'ash',
    'Ġrestrict',
    'ive',
    '11',
    '11',
    'red',
    'ible',
    'on',
    'el',
    'Ġramp',
    'ant',
    're',
    'ported',
    'ĠSu',
    'ite',
    'ĠMin',
    'imum',
    'al',
    'ys',
    'az',
    'ard',
    'lo',
    'op',
    'Ġl',
    'ent',
    'sh',
    'a',
    'Ġv',
    'andal',
    'men',
    'u',
    'ĠBoe',
    'hner',
    'Ġnarr',
    'atives',
    'Ġauthent',
    'icity',
    '26',
    '9',
    'an',
    'ic',
    'd',
    'uty',
    '28',
    '5',
    'Ġthank',
    'ed',
    'Ġbetray',
    'ed',
    'l',
    'ift',
    'Ġsouth',
    'west',
    'ĠDex',
    'ter',
    'ĠB',
    'od',
    'Ġkey',
    'words',
    'A',
    'verage',
    'D',
    'IS',
    'Ġethnic',
    'ity',
    '!',
    '),',
    'ĠNational',
    's',
    'á',
    '¹',
    'ĠT',
    'ah',
    'iox',
    'id',
    'Ġwid',
    'get',
    'Ġpast',
    'a',
    'Ġbill',
    'ing',
    'Ġtr',
    'ilogy',
    'ĠL',
    'ines',
    'Ġsn',
    'iff',
    'Ġnep',
    'hew',
    'L',
    'ate',
    'Ġprinc',
    'ip',
    'ĠLo',
    'op',
    'ĠMarx',
    'ist',
    'Ġdiss',
    'olved',
    'Ġcontext',
    's',
    'ĠAm',
    'ount',
    'ĠSp',
    'ike',
    'Ġtot',
    'als',
    'Ġorgan',
    'izer',
    'Ġup',
    'rising',
    's',
    'hips',
    'Y',
    'Y',
    'ĠNort',
    'heast',
    'm',
    'oney',
    'grad',
    'ation',
    'Ġgoal',
    'keeper',
    'ĠH',
    'ear',
    'Ġste',
    'ak',
    'ĠBuzz',
    'Feed',
    'Ġsole',
    'mn',
    'ĠSc',
    'and',
    'Ġpo',
    'pping',
    'Ġad',
    'here',
    'ĠAl',
    'leg',
    'by',
    'te',
    'ĠW',
    'olver',
    'Ġun',
    'in',
    'Ġrec',
    'ol',
    'it',
    'ud',
    'Ġmim',
    'ic',
    'ib',
    'us',
    'Ġpredict',
    's',
    'ĠKee',
    'per',
    'i',
    'ating',
    'Ġde',
    'ception',
    'Ġlear',
    'nt',
    'Ġdi',
    'ary',
    'Ġcond',
    'itional',
    'Ġre',
    'lic',
    'Ġinv',
    'oke',
    'ien',
    'ced',
    'å',
    'Ī',
    'ĠP',
    'ont',
    'Ġcell',
    'phone',
    'Ġspeed',
    'ing',
    'Ġtack',
    'ling',
    'Ġn',
    'ude',
    'op',
    'ened',
    'ĠMan',
    'afort',
    'Ġ19',
    '52',
    'Ġmaj',
    'ors',
    'ĠSil',
    'ence',
    'Ġlog',
    'istics',
    'Ġweight',
    'ed',
    'ĠPsych',
    'iat',
    '":',
    '["',
    'Ġsick',
    'ness',
    'Ġdivid',
    'ends',
    'z',
    'on',
    'Re',
    'lease',
    'ĠKe',
    'ys',
    'ĠI',
    'ch',
    'Ġen',
    'z',
    'ĠF',
    'ernand',
    'ĠÎ',
    '±',
    'Ġmean',
    'ings',
    'Ġp',
    'enny',
    'Ġst',
    'ern',
    'Ġl',
    'ar',
    'ĠPub',
    'lished',
    'Ġback',
    'drop',
    'K',
    'im',
    'ĠSy',
    'nt',
    'Ġdeb',
    'uted',
    'w',
    'm',
    'ĠIs',
    'le',
    'Ġregul',
    'ating',
    'ott',
    'i',
    'ĠSch',
    'olars',
    'ices',
    'ter',
    'ĠChe',
    'f',
    'Ġpop',
    's',
    'ĠLaun',
    'cher',
    'ĠVar',
    'ious',
    'Ġcomment',
    'ing',
    'os',
    'lav',
    'enz',
    'ie',
    'Ġrival',
    'ry',
    'â',
    'Ĥ¬',
    'Re',
    'ally',
    'Ġor',
    'c',
    'Ġbe',
    'an',
    'ĠJud',
    'y',
    'Not',
    'ice',
    'ĠB',
    'ike',
    '?',
    ']',
    'Ġrent',
    'ed',
    'st',
    'en',
    'Ġfore',
    'front',
    'ĠBald',
    'win',
    'Ġyield',
    'ed',
    't',
    'ails',
    'Pr',
    'ime',
    'ĠS',
    'ources',
    'ic',
    'ator',
    'Se',
    'an',
    'Ġmarch',
    'ing',
    'Out',
    'put',
    'ĠJ',
    'ungle',
    'Ġres',
    'ide',
    'zz',
    'le',
    'ĠAndrew',
    's',
    'Ġtor',
    'que',
    'Bas',
    'ic',
    'Act',
    'ually',
    'st',
    'rap',
    'p',
    'enter',
    'Ġexam',
    's',
    'ĠY',
    'a',
    'Ġ15',
    '9',
    'ĠDec',
    'ision',
    'Ġr',
    'ansom',
    'ete',
    'enth',
    'ens',
    'ing',
    '2',
    '13',
    'Ġsun',
    'set',
    '40',
    '4',
    'ĠRap',
    'id',
    'ĠHe',
    'in',
    'ĠAb',
    'original',
    'Ġorgan',
    'ism',
    'ĠS',
    'ever',
    'Ġcl',
    'a',
    'aj',
    'i',
    'Sim',
    'ple',
    'ĠFl',
    'avor',
    'ĠE',
    'val',
    'pr',
    'us',
    'Ġch',
    'orus',
    'D',
    'AY',
    'Ġden',
    'ounced',
    'Ġbi',
    'ography',
    'ĠTurn',
    'bull',
    'Rec',
    'ent',
    'N',
    'ormal',
    'lect',
    'ions',
    'W',
    'ord',
    'Ġf',
    'erry',
    'ĠWag',
    'ner',
    'h',
    'om',
    'Un',
    'it',
    'Ġsuper',
    'market',
    'ĠS',
    'ith',
    'Ġnomine',
    'es',
    'Ġdictators',
    'hip',
    'idd',
    'ler',
    'Ġannoun',
    'ces',
    'ĠThe',
    'm',
    'ĠNept',
    'une',
    'Ġde',
    'ity',
    'ĠY',
    'i',
    'Ġmon',
    'arch',
    'AR',
    'R',
    'Ġinv',
    'aded',
    'ĠH',
    'ok',
    'unt',
    'ary',
    'C',
    'ertain',
    'eg',
    'a',
    'Ġk',
    'idding',
    'ĠReg',
    'ulation',
    'Ġtr',
    'ay',
    'Ġphotograp',
    'hers',
    'ĠArc',
    'ane',
    'Ġdis',
    'charged',
    'Ġevangel',
    'ical',
    'Ġinter',
    'change',
    'Ġfilm',
    'maker',
    'ĠEnd',
    'less',
    'Ġ29',
    '0',
    'ĠSalv',
    'ador',
    'AS',
    'Y',
    'ĠSign',
    'al',
    'Ġwr',
    'ath',
    'â',
    'ľ',
    'l',
    'ot',
    "'",
    '/',
    'Ġproject',
    'ile',
    'Ġemploy',
    'ing',
    'ĠInter',
    'face',
    '19',
    '1',
    'atell',
    'ite',
    'ĠR',
    'ath',
    'pack',
    'age',
    'Ġindic',
    'ations',
    'J',
    'ason',
    'Ġarg',
    's',
    'ĠG',
    'Hz',
    'Ġt',
    'ilt',
    'n',
    'ants',
    'w',
    'on',
    'ãĤ',
    'µ',
    'red',
    'd',
    'res',
    'cent',
    'ĠCal',
    'endar',
    'Ġmod',
    'ular',
    'Ġassist',
    'ing',
    'Ġred',
    'eem',
    'ĠBe',
    'an',
    'Ġwor',
    'sh',
    'Ġdecentral',
    'ized',
    ')',
    '...',
    '37',
    '7',
    'Ġarr',
    'ays',
    'Ġaccomplish',
    'ments',
    'Î',
    '¿',
    'd',
    'ot',
    'Ġmut',
    'ually',
    'Ġob',
    'struct',
    'Ġmis',
    'represent',
    'ore',
    'st',
    'ion',
    'ic',
    'ru',
    'ce',
    '%',
    ';',
    'Ġknow',
    'ingly',
    'port',
    'ing',
    'in',
    'ently',
    'A',
    'ri',
    'ĠSch',
    'ultz',
    'D',
    'a',
    'ĠC',
    'ere',
    'Ġob',
    'solete',
    'ħ',
    'ĭ',
    'g',
    'ive',
    'Ġb',
    'ait',
    'Ġen',
    'larg',
    'Ne',
    'ill',
    'Ġ19',
    '33',
    'Ġrecons',
    'ider',
    'ĠSerge',
    'ant',
    'ĠDian',
    'e',
    'ĠC',
    'ogn',
    'ĠI',
    'con',
    'P',
    'osition',
    'Ġf',
    'ost',
    'Ġstir',
    'ring',
    'se',
    'ven',
    'ĠSpace',
    'X',
    'ugg',
    'ets',
    'Ġmed',
    'd',
    'G',
    'al',
    'ĠS',
    'ister',
    'B',
    'oy',
    'Ġtrigger',
    'ing',
    'T',
    'aking',
    'Ġscream',
    's',
    'Ġca',
    'usal',
    'Ġaw',
    'aken',
    'Ar',
    'm',
    '29',
    '7',
    'Ġdisp',
    'atched',
    'ĠF',
    'ALSE',
    'Ġorgan',
    'izational',
    'ĠT',
    'ong',
    'Ġdile',
    'mma',
    'd',
    'emon',
    'S',
    'pl',
    'Ġhook',
    's',
    'ud',
    'ing',
    'Ġvalid',
    'ate',
    'Ġpot',
    'ion',
    'Ġcl',
    'aw',
    'Ġburg',
    'l',
    'Ġqu',
    'ir',
    'AC',
    'A',
    'ĠBren',
    'nan',
    'Ġdur',
    'ability',
    'Ġbomb',
    'ings',
    'ĠWind',
    'ow',
    'Ġculp',
    'rit',
    '3',
    '25',
    'There',
    'fore',
    'umb',
    'ered',
    'per',
    'formance',
    'w',
    'arts',
    'Ġen',
    'forcing',
    'ĠBl',
    'ow',
    'Ġre',
    'print',
    'if',
    'ax',
    'al',
    'pha',
    'Ġsin',
    'ister',
    'Ġbur',
    'ger',
    'fight',
    'ing',
    'Sc',
    'ore',
    'ĠSt',
    'ones',
    'i',
    'em',
    '40',
    '5',
    'che',
    'my',
    'Ġvine',
    'gar',
    'n',
    'om',
    'Ġprev',
    'ailing',
    'ĠLat',
    'est',
    'Â',
    '¶',
    'Ġb',
    'a',
    'ĠWrit',
    'er',
    'Ġ17',
    '7',
    'ĠCon',
    'way',
    'Ġcollect',
    's',
    'Ġquant',
    'itative',
    'Ġhor',
    'rors',
    'og',
    'ens',
    'ĠSl',
    'ov',
    'Ġl',
    'ays',
    'h',
    'aw',
    'ĠSl',
    'ash',
    'Ġnight',
    'club',
    'ĠDav',
    'ies',
    'Ġbr',
    'ide',
    'ĠScar',
    'let',
    'y',
    'mm',
    'ĠApplic',
    'ations',
    'vel',
    'ength',
    'Ġrev',
    'ival',
    'Ġsoft',
    'ly',
    'Ġz',
    'oo',
    'ita',
    'ire',
    'C',
    'ur',
    'Ġelect',
    'rom',
    'Ġplant',
    'ing',
    'OT',
    'O',
    'ĠE',
    'lements',
    'Ġsw',
    'allow',
    'por',
    'ter',
    'Ġlapt',
    'ops',
    'Ġpe',
    'anut',
    'Ġlobby',
    'ists',
    'Î',
    '²',
    'Pan',
    'el',
    'ĠJo',
    'an',
    'im',
    'il',
    't',
    'nc',
    'Ġresist',
    'ed',
    'Ġout',
    'we',
    'Ġret',
    'aining',
    'at',
    'ri',
    'Ġpo',
    'orer',
    'ĠSyri',
    'ans',
    'ĠHam',
    'mond',
    'Ġwe',
    'ld',
    'ud',
    'er',
    'top',
    'ic',
    'ĠT',
    'T',
    'ric',
    'ia',
    'Ġth',
    'ieves',
    'L',
    'ic',
    'ĠG',
    'ust',
    'ĠW',
    'ays',
    'are',
    'th',
    '24',
    '3',
    'Ġbroad',
    'caster',
    'sh',
    'ield',
    'ass',
    'ium',
    'ub',
    'le',
    'Ġairst',
    'rikes',
    'on',
    'so',
    'Ġped',
    'al',
    'Ġcollect',
    'ors',
    'ĠV',
    'ander',
    'ĠMes',
    'a',
    'Ġdict',
    'ator',
    'Ġd',
    'ir',
    'ent',
    'on',
    'c',
    'art',
    'sc',
    'ore',
    'ad',
    'der',
    'C',
    'ry',
    'Ġs',
    'sh',
    'gg',
    'er',
    'Ġdrunk',
    'en',
    'ĠG',
    'S',
    'ĠSe',
    'at',
    'Ġcorner',
    'back',
    'Ġsk',
    'ipped',
    'ĠRes',
    'earchers',
    'ĠAud',
    'i',
    'Ref',
    'erence',
    'Ġhaun',
    'ted',
    'Ã',
    '«',
    'ĠClin',
    'ic',
    'c',
    'z',
    'Ġp',
    's',
    'ĠPal',
    'adin',
    'ĠRec',
    'ipe',
    'Ġst',
    'igma',
    'opp',
    'y',
    'Ġmon',
    'keys',
    'ĠHaw',
    'k',
    'S',
    'ad',
    '"',
    '/>',
    'ĠWorks',
    'hop',
    'ĠRet',
    'ail',
    'ĠAv',
    'atar',
    '6',
    '25',
    'N',
    'a',
    'ĠV',
    'C',
    'ĠSec',
    'ure',
    'M',
    'Y',
    '19',
    '88',
    'oss',
    'ip',
    'Ġpro',
    'state',
    'Ġund',
    'en',
    'Ġg',
    'amer',
    'ĠCont',
    'ents',
    'ĠWar',
    'hammer',
    'ĠSent',
    'inel',
    '3',
    '10',
    'Ġse',
    'gregation',
    'ĠF',
    'lex',
    'ĠM',
    'AY',
    'Ġdr',
    'ills',
    'ĠDrug',
    's',
    'Islam',
    'ic',
    'Ġsp',
    'ur',
    'Ġca',
    'fe',
    'Ġimag',
    'inary',
    'Ġgu',
    'iding',
    'Ġsw',
    'ings',
    'ĠThe',
    'me',
    'ob',
    'y',
    'Ġn',
    'ud',
    'Ġbe',
    'gging',
    'Ġstr',
    'ongh',
    'Ġreject',
    'ing',
    'Ġpedest',
    'rians',
    'ĠPro',
    'spect',
    'R',
    'are',
    's',
    'le',
    'Ġconcess',
    'ions',
    'ĠConst',
    'itutional',
    'Ġbe',
    'ams',
    'Ġfib',
    'ers',
    'p',
    'oon',
    'Ġinstinct',
    's',
    'pro',
    'perty',
    'ĠB',
    'IG',
    'Sand',
    'ers',
    'im',
    'ates',
    'Ġco',
    'ating',
    'Ġcorps',
    'es',
    'ĠTR',
    'UE',
    'check',
    'ed',
    'Ġ16',
    '6',
    'A',
    'sh',
    'ĠJ',
    'S',
    'ĠF',
    'iction',
    'Ġcommun',
    'al',
    'Ġener',
    'getic',
    'oooo',
    'oooo',
    'Ġnow',
    'adays',
    'IL',
    'D',
    'ib',
    'o',
    'ĠSU',
    'V',
    'R',
    'en',
    'Ġdwell',
    'ing',
    'Sil',
    'ver',
    'Ġt',
    'ally',
    'ĠM',
    'oving',
    'Ġcow',
    'ard',
    'Ġgener',
    'als',
    'Ġhorn',
    's',
    'Ġcirc',
    'ulated',
    'Ġrob',
    'bed',
    'ĠUn',
    'limited',
    'Ġharass',
    'ed',
    'Ġinhib',
    'it',
    'Ġcomp',
    'oser',
    'ĠSpot',
    'ify',
    'Ġspread',
    's',
    '3',
    '64',
    'Ġsu',
    'icidal',
    'Ġno',
    'ises',
    'ĠSt',
    'ur',
    'Ġs',
    'aga',
    'ĠK',
    'ag',
    'is',
    'o',
    'Ġtheoret',
    'ically',
    'M',
    'oney',
    'Ġsimilar',
    'ity',
    'Ġslic',
    'ed',
    'ut',
    'ils',
    'ing',
    'es',
    '"',
    '-',
    'Ġan',
    'th',
    'Ġimp',
    'ed',
    'Mod',
    'ule',
    'Through',
    'out',
    'Ġmen',
    'us',
    'comm',
    'ittee',
    'and',
    'i',
    'ob',
    'j',
    'in',
    'av',
    'f',
    'ired',
    'ĠAb',
    'dullah',
    'Ġund',
    'ead',
    'Ġfont',
    's',
    'H',
    'old',
    'EN',
    'G',
    'Ġsustain',
    'ability',
    'Ġfl',
    'ick',
    'Ġr',
    'azor',
    'ĠF',
    'est',
    'ĠChar',
    'acters',
    'Ġword',
    'ing',
    'Ġpopul',
    'ist',
    'Ġcritic',
    'izing',
    'Ġm',
    'use',
    'v',
    'ine',
    'Ġcard',
    'board',
    'Ġkind',
    'ly',
    'Ġfr',
    'inge',
    'ĠThe',
    'ft',
    'icult',
    'ural',
    'Ġgovern',
    'ors',
    'Ġ',
    'ï¿½ï¿½ï¿½ï¿½',
    'Ġ16',
    '3',
    'Ġtime',
    'out',
    'ĠA',
    'uth',
    'Child',
    'ren',
    'A',
    'U',
    'Ġred',
    'emption',
    'ĠAl',
    'ger',
    'Ġ19',
    '14',
    'Ġw',
    'aved',
    'Ġastron',
    'auts',
    'og',
    'rams',
    'Ġsw',
    'amp',
    'ĠFinn',
    'ish',
    'Ġcand',
    'le',
    'Ġton',
    'nes',
    'ut',
    'm',
    'Ġr',
    'ay',
    'Ġsp',
    'un',
    'Ġfear',
    'ful',
    'art',
    'icles',
    'Ġca',
    'us',
    'or',
    'ically',
    'ĠRequ',
    'ires',
    'ĠG',
    'ol',
    'Ġpop',
    'e',
    'Ġinaug',
    'ural',
    'Ġg',
    'le',
    'AD',
    'A',
    'ĠIS',
    'IL',
    'ĠOff',
    'ensive',
    'Ġwatch',
    'dog',
    'Ġbal',
    'con',
    'ent',
    'ity',
    'ĠH',
    'oo',
    'Ġgall',
    'on',
    'AC',
    'C',
    'Ġdoub',
    'ling',
    'Ġimpl',
    'ication',
    'ĠS',
    'ight',
    'Ġdoct',
    'r',
    '----',
    '---',
    'Ġ\\',
    '\\',
    'Ġm',
    'alt',
    'R',
    'oll',
    'Ġâī',
    '¥',
    'Ġrec',
    'ap',
    'add',
    'ing',
    'u',
    'ces',
    'ĠB',
    'end',
    'fig',
    'ure',
    'Ġtur',
    'key',
    'Ġsoc',
    'ietal',
    'ĠT',
    'ickets',
    'Ġcommer',
    'cially',
    'Ġsp',
    'icy',
    'Ġ2',
    '16',
    'ĠR',
    'amp',
    'Ġsuperior',
    'ity',
    'Ã',
    '¯',
    'ĠTr',
    'acker',
    'C',
    'arl',
    'ĠC',
    'oy',
    'ĠPatri',
    'ot',
    'Ġconsult',
    'ed',
    'Ġlist',
    'ings',
    'Ġsle',
    'w',
    'reens',
    'hot',
    'ĠG',
    'one',
    'Ġ[',
    '...]',
    '30',
    '9',
    'Ġh',
    'ottest',
    'Ø',
    '±',
    'Ġrock',
    'y',
    'ĠD',
    'iaz',
    'Ġmass',
    'age',
    'Ġpar',
    'aly',
    'Ġp',
    'ony',
    'A',
    'z',
    'Ġcart',
    'ridge',
    'ĠN',
    'Z',
    'Ġsn',
    'ack',
    'ĠLam',
    'ar',
    'ple',
    'ment',
    'ĠLes',
    'lie',
    'Ġm',
    'ater',
    'Ġsn',
    'ipp',
    '24',
    '6',
    'Ġjoint',
    'ly',
    'ĠBris',
    'bane',
    'ĠiP',
    'od',
    'Ġpump',
    'ing',
    'Ġgo',
    'at',
    'ĠSh',
    'aron',
    'eal',
    'ing',
    'Ġcor',
    'on',
    'Ġan',
    'omal',
    'rah',
    'im',
    'ĠConnect',
    'ion',
    'Ġsculpt',
    'ure',
    'Ġsched',
    'uling',
    'ĠD',
    'addy',
    'at',
    'hing',
    'Ġeyeb',
    'rows',
    'Ġcur',
    'ved',
    'Ġsent',
    'iments',
    'Ġdraft',
    'ing',
    'D',
    'rop',
    '(',
    '[',
    'Ġnom',
    'inal',
    'ĠLeaders',
    'hip',
    'ĠG',
    'row',
    'Ġ17',
    '6',
    'Ġconstruct',
    'ive',
    'iv',
    'ation',
    'Ġcorrupt',
    'ed',
    'ger',
    'ald',
    'ĠC',
    'ros',
    'ĠChe',
    'ster',
    'ĠL',
    'ap',
    'ãģ',
    'ª',
    'OT',
    'H',
    'D',
    'ATA',
    'Ġal',
    'mond',
    'pro',
    'bably',
    'I',
    'mp',
    'Ġfe',
    'ast',
    'ĠWar',
    'craft',
    'F',
    'lor',
    'Ġcheck',
    'point',
    'Ġtrans',
    'cription',
    'Ġ20',
    '4',
    'Ġtwe',
    'aks',
    'Ġrel',
    'ieve',
    'S',
    'cience',
    'Ġperform',
    'er',
    'Z',
    'one',
    'Ġtur',
    'moil',
    'ig',
    'ated',
    'hib',
    'it',
    'ĠC',
    'afe',
    'the',
    'med',
    'Ġflu',
    'or',
    'ben',
    'ch',
    'Ġde',
    'com',
    'ĠU',
    'nt',
    'ĠBar',
    'rett',
    'ĠF',
    'acts',
    'Ġt',
    'asting',
    'ĠPTS',
    'D',
    'ĠSe',
    'al',
    'ĠJuda',
    'ism',
    'ĠDynam',
    'ic',
    'ĠC',
    'ors',
    'V',
    'e',
    'ĠM',
    'ing',
    'ĠTrans',
    'form',
    'v',
    'on',
    'ĠDef',
    'enders',
    'ĠTact',
    'ical',
    'ĠV',
    'on',
    'ĠUn',
    'ivers',
    'Ġdist',
    'orted',
    'ĠB',
    'reath',
    "?'",
    '"',
    'Ġag',
    'on',
    'ĠDead',
    'ly',
    'Ġl',
    'an',
    'ĠCy',
    'cle',
    'orn',
    'ed',
    'Ġrel',
    'iably',
    'Ġgl',
    'or',
    'ĠMon',
    'key',
    'ãĥ',
    '¡',
    'Ġad',
    'ren',
    'Ġmicrow',
    'ave',
    'ĠAl',
    'ban',
    'irc',
    'raft',
    'dig',
    'it',
    'sm',
    'art',
    'ĠD',
    'read',
    'Â¯Â¯Â¯Â¯Â¯Â¯Â¯Â¯',
    'Â¯Â¯Â¯Â¯Â¯Â¯Â¯Â¯',
    '{',
    '{',
    'ĠRoc',
    'hester',
    'Ġsimpl',
    'ified',
    'Ġinf',
    'licted',
    'Ġtake',
    'over',
    'Ġyour',
    'selves',
    'ad',
    'itional',
    'Ġmus',
    'cular',
    'K',
    'S',
    'Ġing',
    'en',
    'T',
    'ax',
    'ĠFe',
    'ature',
    '27',
    '7',
    'Ġcru',
    'c',
    'Ġcr',
    'ate',
    'Ġun',
    'identified',
    'Ġacclaim',
    'ed',
    'ĠM',
    'anga',
    'ĠFr',
    'ances',
    'ĠNep',
    'al',
    'ĠG',
    'erald',
    'ĠKu',
    'wait',
    'Ġsl',
    'ain',
    'ĠHe',
    'b',
    'ĠG',
    'oku',
    'ãģ®',
    'æ',
    '28',
    '6',
    'M',
    'rs',
    'ĠC',
    'ody',
    'ĠSan',
    'ctuary',
    '01',
    '6',
    'Ġdism',
    'ant',
    'Ġdatas',
    'et',
    'ĠH',
    'ond',
    'b',
    'uck',
    'ĠPat',
    'terson',
    'Ġpal',
    'ette',
    'ĠG',
    'D',
    'ic',
    'ol',
    'ĠL',
    'odge',
    'Ġplanet',
    'ary',
    'ak',
    'in',
    'ĠRegist',
    'ered',
    'ab',
    'we',
    'ĠPeters',
    'burg',
    'Ġha',
    'iled',
    'ĠP',
    'iece',
    'S',
    'che',
    'ĠDO',
    'J',
    'Ġen',
    'umer',
    '18',
    '1',
    'ĠObs',
    'erver',
    'ĠB',
    'old',
    'f',
    'ounded',
    'com',
    'merce',
    'Ġexplo',
    'its',
    'ĠF',
    'inding',
    'UR',
    'N',
    'ĠS',
    'ne',
    'ĠAc',
    'id',
    'ay',
    'ette',
    'ĠVal',
    'ues',
    'Ġdr',
    'astic',
    'Ġarchitect',
    'ural',
    'Ġ"',
    '.',
    '×',
    'ķ',
    'ump',
    'ed',
    'Ġwra',
    'pping',
    'Ġwid',
    'ow',
    'ĠSl',
    'ayer',
    'l',
    'ace',
    'on',
    'ce',
    'German',
    'y',
    'av',
    'oid',
    'Ġtem',
    'ples',
    'P',
    'AR',
    'Ã',
    '´',
    'ĠLuc',
    'ifer',
    'ĠFl',
    'ickr',
    'l',
    'ov',
    'for',
    'ces',
    'Ġsc',
    'outing',
    'Ġlou',
    'der',
    'tes',
    'y',
    'Ġbefore',
    'hand',
    'Ä',
    'ĵ',
    'ĠNe',
    'on',
    'ĠW',
    'ol',
    'ĠTyp',
    'ically',
    'ĠPolit',
    'ico',
    '-+',
    '-+',
    'Ġbuild',
    'er',
    'Ġder',
    'ive',
    'K',
    'ill',
    'Ġp',
    'oker',
    'Ġambig',
    'uous',
    'Ġlif',
    'ts',
    'Ġcy',
    't',
    'Ġrib',
    's',
    'ood',
    'le',
    'ĠS',
    'ounds',
    'h',
    'air',
    'ĠSynd',
    'rome',
    't',
    'f',
    'Ġproport',
    'ional',
    'u',
    'id',
    'Ġper',
    'taining',
    'ĠKind',
    'le',
    'ĠNeg',
    'ro',
    'Ġreiter',
    'ated',
    'ĠTon',
    'ight',
    'oth',
    's',
    'ĠCorn',
    'ell',
    'Ġo',
    'wing',
    'Ġ20',
    '8',
    'elf',
    'are',
    'oc',
    'ating',
    'ĠB',
    'irds',
    'Sub',
    'scribe',
    'Ġess',
    'ays',
    'Ġburd',
    'ens',
    'Ġillust',
    'rations',
    'ar',
    'ious',
    'ER',
    'AL',
    'ĠCal',
    'cul',
    'Ġx',
    'en',
    'ĠLink',
    'edIn',
    'ĠJ',
    'ung',
    'Ġredes',
    'ign',
    'Con',
    'nor',
    '29',
    '6',
    'Ġrevers',
    'al',
    'ĠAd',
    'elaide',
    'ĠL',
    'L',
    'Ġs',
    'inking',
    'Ġg',
    'um',
    'US',
    'H',
    'c',
    'apt',
    'ĠGr',
    'imm',
    'Ġfoot',
    'steps',
    'ĠCB',
    'D',
    'isp',
    'ers',
    'Ġpro',
    'se',
    'Wed',
    'nesday',
    'ĠM',
    'ovies',
    'ed',
    'in',
    'Ġoverturn',
    'ed',
    'Ġcontent',
    'ious',
    'US',
    'B',
    '~~~~~~~~',
    '~~~~~~~~',
    'ĠCo',
    'pper',
    'Ġpoint',
    'less',
    'N',
    'V',
    'val',
    'ues',
    'olph',
    'in',
    'd',
    'ain',
    'Ġdepos',
    'ited',
    'ĠG',
    'W',
    'Ġpreced',
    'ed',
    'ĠCl',
    'a',
    'ĠGo',
    'lem',
    'ĠN',
    'im',
    'ĠÎ',
    '²',
    'ĠEngine',
    'ers',
    'm',
    'iddle',
    'Ġfl',
    'att',
    'oper',
    'ative',
    'Ġcouncil',
    's',
    'imb',
    'abwe',
    'el',
    'in',
    'Ġstress',
    'ful',
    'ĠL',
    'D',
    'Ġres',
    'h',
    'l',
    'ake',
    'Ġwheel',
    'chair',
    'ĠAltern',
    'ative',
    'Ġoptim',
    'ize',
    'oper',
    'ation',
    'Ġpe',
    'ek',
    'Ġones',
    'elf',
    'ig',
    'il',
    'Ġtrans',
    'itions',
    'op',
    'athy',
    'bl',
    'ank',
    'Ġ16',
    '9',
    '17',
    '1',
    '________________________________',
    '________________________________',
    'Ġl',
    'aundering',
    'En',
    'c',
    'ĠD',
    'EC',
    'Ġwork',
    'outs',
    'Ġsp',
    'ikes',
    'Ġdin',
    'osaurs',
    'Ġdiscrim',
    'inatory',
    'P',
    'ool',
    'R',
    'ather',
    '38',
    '5',
    'R',
    'NA',
    'tes',
    'ters',
    'et',
    'o',
    'ĠIdent',
    'ity',
    'Ġve',
    'in',
    'ĠBur',
    'ton',
    'Ġarc',
    'ade',
    '4',
    '20',
    'Ult',
    'imately',
    'ĠSad',
    'ly',
    'Ã',
    '°',
    'p',
    'ill',
    'Ġcub',
    'ic',
    'ĠSpect',
    'rum',
    'the',
    'se',
    'st',
    'ates',
    'Ġun',
    'official',
    'h',
    'awks',
    'ĠEVER',
    'Y',
    'Ġrain',
    'bow',
    'Ġincarcer',
    'ation',
    'and',
    'ing',
    'Ġsy',
    'll',
    'ĠEver',
    'ton',
    'Ġ17',
    '9',
    'ĠSer',
    'bia',
    'Ġ18',
    '9',
    'm',
    'eter',
    'ĠMic',
    'key',
    'Ġant',
    'iqu',
    'Ġfact',
    'ual',
    'ne',
    'ck',
    'ĠN',
    'are',
    'n',
    'orm',
    'm',
    'ust',
    'Ġhigh',
    'ways',
    'Ġgl',
    'am',
    'Ġdivid',
    'ing',
    'ĠSquad',
    'ron',
    'ĠMar',
    'tha',
    'Ġbirth',
    's',
    'C',
    'over',
    '////////',
    '////////',
    'ĠW',
    'ong',
    'Ph',
    'ot',
    'ĠA',
    'LS',
    'ri',
    'o',
    'ĠNon',
    'etheless',
    'ĠL',
    'emon',
    'Ġ20',
    '6',
    'ĠE',
    'E',
    'Ġderiv',
    'ative',
    'ĠWW',
    'II',
    'v',
    'ote',
    'Ġthere',
    'in',
    'Ġsepar',
    'ating',
    '44',
    '6',
    'sy',
    'nc',
    'ĠStre',
    'ets',
    'Ġr',
    'att',
    'Ġmunicip',
    'ality',
    'ĠShort',
    'ly',
    'Ġmon',
    'k',
    ')',
    ',"',
    'Ġscr',
    'ub',
    'Ġoper',
    'atives',
    'Ne',
    'ither',
    'Pl',
    'ace',
    'ĠLim',
    'it',
    'F',
    'emale',
    'ĠAct',
    'or',
    'Char',
    'acter',
    'Ġconstit',
    'uted',
    '35',
    '7',
    'Ġprotest',
    'ed',
    'ĠSt',
    'raw',
    'ĠHe',
    'ight',
    'ild',
    'a',
    'ĠTy',
    'ph',
    'Ġflood',
    's',
    'Ġcos',
    'metic',
    'W',
    'AY',
    'pert',
    'ure',
    'up',
    'on',
    't',
    'ons',
    'ess',
    'ing',
    'ĠP',
    'ocket',
    'Ġro',
    'oft',
    'ĠC',
    'aucas',
    'Ġant',
    'idepress',
    'Ġincomp',
    'atible',
    'EC',
    'D',
    'Ġoper',
    'a',
    'ĠCont',
    'est',
    'Ġgener',
    'ators',
    'l',
    'ime',
    'Def',
    'ense',
    '19',
    '87',
    'for',
    'um',
    'Ġsav',
    'age',
    'ĠHung',
    'arian',
    'n',
    'z',
    'Ġmet',
    'allic',
    'Ġex',
    'pelled',
    'Ġres',
    'idency',
    'Ġdress',
    'es',
    '66',
    '6',
    'ĠC',
    'lement',
    'f',
    'ires',
    'C',
    'ategory',
    'Ġge',
    'ek',
    'al',
    'is',
    'Ġc',
    'emetery',
    'educ',
    'ated',
    'Ġc',
    'rawl',
    'ĠUn',
    'able',
    'ĠT',
    'yson',
    'ak',
    'is',
    'Ġp',
    'ardon',
    'ĠW',
    'ra',
    'Ġstrengthen',
    'ed',
    'ĠF',
    'ors',
    '33',
    '5',
    'ĠH',
    'C',
    'ĠM',
    'ond',
    'Ġvisual',
    's',
    'ĠBeat',
    'les',
    'ett',
    'lement',
    'Ġ',
    'ï',
    'g',
    'ro',
    'Ġb',
    'ash',
    'Ġpo',
    'orest',
    'Ġex',
    'cel',
    'Ġaspir',
    'ations',
    'ĠM',
    'unicip',
    'ens',
    'ible',
    'Ġceremon',
    'ies',
    'Ġintimid',
    'ation',
    'ĠCON',
    'TR',
    'be',
    'ck',
    'ĠK',
    'ap',
    'as',
    'u',
    'Ġtradem',
    'arks',
    'ĠS',
    'ew',
    'ĠComp',
    'etition',
    'net',
    'work',
    'ĠAr',
    'ri',
    'ĠT',
    'et',
    'Ro',
    'aming',
    'W',
    'C',
    'D',
    'at',
    'Ġso',
    'b',
    'Ġpair',
    'ing',
    'Ġoverd',
    'ose',
    'SA',
    'Y',
    'ab',
    'er',
    'Ġrev',
    'olt',
    'ĠF',
    'ah',
    'act',
    'ing',
    'e',
    'q',
    'est',
    'ation',
    'F',
    'ight',
    'ĠMar',
    'ks',
    '27',
    '3',
    'Ġ17',
    '8',
    'R',
    'aw',
    'ãģ',
    'ĭ',
    '34',
    '9',
    'bl',
    'ocks',
    'Ġver',
    'ge',
    'est',
    'ine',
    'ĠPod',
    'esta',
    'Ġinv',
    'asive',
    'Ġprofound',
    'ly',
    'ĠA',
    'o',
    'e',
    'ach',
    'Ġl',
    'est',
    'inter',
    'pret',
    'Ġshr',
    'inking',
    'Ġerr',
    'one',
    'Ġche',
    'es',
    'ly',
    's',
    'ĠI',
    'vy',
    'ĠDirect',
    'ory',
    'Ġhint',
    'ed',
    'V',
    'ICE',
    'Ġcontact',
    'ing',
    'ĠG',
    'ent',
    'he',
    'i',
    'Ġlabel',
    'ing',
    'Ġmerc',
    'ury',
    'ĠL',
    'ite',
    'Ġexp',
    'ires',
    'Ġdest',
    'abil',
    'rit',
    'is',
    'c',
    'u',
    'Ġfeather',
    's',
    'Ġste',
    'er',
    'Ġprogram',
    'med',
    'ĠV',
    'ader',
    'Go',
    'ing',
    'ĠE',
    'lim',
    'Ġy',
    'o',
    'ĠMic',
    'he',
    'Ġ20',
    '3',
    'Ġslee',
    'ves',
    'Ġb',
    'ully',
    'ĠHum',
    'ans',
    '36',
    '8',
    'Ġcomp',
    'ress',
    'ĠBan',
    'ner',
    'AR',
    'S',
    'Ġa',
    'while',
    'Ġcal',
    'ib',
    'Ġspons',
    'orship',
    'ĠDiff',
    'iculty',
    'ĠP',
    'apers',
    'Ġident',
    'ifier',
    '}',
    '.',
    'Ġy',
    'og',
    'ĠSh',
    'ia',
    'Ġclean',
    'up',
    'Ġvib',
    'e',
    'int',
    'rodu',
    'im',
    'ming',
    'Austral',
    'ia',
    'Ġout',
    'lines',
    'ĠY',
    'outube',
    'tr',
    'ain',
    'ĠM',
    'akes',
    'Ġde',
    'ported',
    'Ġcent',
    'r',
    'ĠD',
    'ug',
    'ĠB',
    'oulder',
    'ĠBuff',
    'y',
    'Ġinj',
    'unction',
    'ĠHar',
    'ley',
    'ĠG',
    'roups',
    'ĠD',
    'umbledore',
    'ĠCl',
    'ara',
    'Ġ"',
    '-',
    'Ġsacrific',
    'ed',
    'ep',
    'h',
    'Sh',
    'adow',
    'ib',
    'ling',
    'Ġfreel',
    'ance',
    'Ġevident',
    'ly',
    'ph',
    'al',
    'Ġret',
    'ains',
    'M',
    'ir',
    'Ġfin',
    'ite',
    'd',
    'ar',
    'ĠC',
    'ous',
    'Ġrep',
    'aired',
    'Ġperiod',
    'ic',
    'Ġchampions',
    'hips',
    'Ġaster',
    'oid',
    'bl',
    'ind',
    'Ġexpress',
    'ly',
    'ĠAst',
    'ros',
    'Ġsc',
    'aled',
    'Ġge',
    'ographical',
    'ĠRap',
    'ids',
    'En',
    'joy',
    'Ġel',
    'astic',
    'ĠMoh',
    'amed',
    'Mark',
    'et',
    'be',
    'gin',
    'Ġdisco',
    'vers',
    'Ġtele',
    'communications',
    'Ġscan',
    'ner',
    'Ġen',
    'large',
    'Ġsh',
    'arks',
    'Ġpsy',
    'chedel',
    'ĠRou',
    'ge',
    'Ġsnap',
    'shot',
    'is',
    'ine',
    'X',
    'P',
    'Ġpestic',
    'ides',
    'ĠL',
    'SD',
    'ĠDist',
    'ribution',
    're',
    'ally',
    'Ġde',
    'gradation',
    'Ġdisgu',
    'ise',
    'Ġbi',
    'om',
    'ĠEX',
    'T',
    'Ġequ',
    'ations',
    'Ġhaz',
    'ards',
    'ĠComp',
    'ared',
    ')',
    '*',
    'Ġvirt',
    'ues',
    'Ġeld',
    'ers',
    'Ġenh',
    'ancing',
    'ĠAc',
    'ross',
    'er',
    'os',
    'ang',
    'ling',
    'Ġcomb',
    'ust',
    'ucc',
    'i',
    'Ġconc',
    'ussion',
    'Ġcontrace',
    'ption',
    'ĠK',
    'ang',
    'Ġexpress',
    'es',
    'Ġa',
    'ux',
    'ĠP',
    'ione',
    'Ġexhib',
    'its',
    'Deb',
    'ug',
    'OT',
    'AL',
    'ĠAl',
    'ready',
    'ĠWheel',
    'er',
    'Ġexp',
    'ands',
    '?',
    ':',
    'Ġreconc',
    'iliation',
    'Ġpir',
    'ates',
    'Ġpur',
    'se',
    'Ġdiscour',
    'age',
    'Ġspect',
    'acle',
    'R',
    'ank',
    'Ġwra',
    'ps',
    'ĠTh',
    'ought',
    'Ġimp',
    'ending',
    'O',
    'pp',
    'ĠAng',
    'lo',
    'ĠE',
    'UR',
    'Ġscrew',
    'ed',
    'ret',
    'ched',
    'Ġencour',
    'agement',
    'mod',
    'els',
    'Ġconf',
    'use',
    'mm',
    'm',
    'ĠVit',
    'amin',
    'âĸĳ',
    'âĸĳ',
    'C',
    'ru',
    'Ġkn',
    'ights',
    'Ġdisc',
    'ard',
    'Ġb',
    'ishops',
    'ĠW',
    'ear',
    'ĠGar',
    'rett',
    'k',
    'an',
    'ãĥ',
    'Ł',
    'Ġmascul',
    'ine',
    'cap',
    'ital',
    'ĠA',
    'us',
    'Ġfat',
    'ally',
    'th',
    'anks',
    'ĠA',
    'U',
    'ĠG',
    'ut',
    '12',
    '00',
    'Ġ',
    '00000000',
    'Ġsur',
    'rog',
    'ĠBI',
    'OS',
    'ra',
    'its',
    'ĠWat',
    'ts',
    'Ġresur',
    'rection',
    'ĠElect',
    'oral',
    'ĠT',
    'ips',
    '4',
    '000',
    'Ġnut',
    'rient',
    'Ġdepict',
    'ing',
    'Ġspr',
    'ink',
    'Ġm',
    'uff',
    'ĠL',
    'IM',
    'ĠS',
    'ample',
    'ps',
    'c',
    'ib',
    'i',
    'gener',
    'ated',
    'Ġspec',
    'imens',
    'Ġdiss',
    'atisf',
    'Ġtail',
    'ored',
    'Ġhold',
    'ings',
    'ĠMonth',
    'ly',
    'ĠE',
    'at',
    'po',
    'ons',
    'Ġne',
    'c',
    'ĠC',
    'age',
    'ĠLot',
    'us',
    'ĠLan',
    'tern',
    'Ġfront',
    'ier',
    'Ġp',
    'ensions',
    'Ġj',
    'oked',
    'ĠHard',
    'y',
    '=-=-',
    '=-=-',
    'r',
    'ade',
    'U',
    'ID',
    'Ġr',
    'ails',
    'Ġem',
    'it',
    'Ġsl',
    'ate',
    'Ġsm',
    'ug',
    'Ġsp',
    'it',
    'ĠCall',
    's',
    'ĠJac',
    'obs',
    'f',
    'eat',
    'ĠU',
    'E',
    'Ġrest',
    'ruct',
    'Ġregener',
    'ation',
    'Ġenerg',
    'ies',
    'ĠCon',
    'nor',
    'OH',
    'N',
    'ĠChe',
    'ese',
    'Ġg',
    'er',
    'Ġresur',
    'rect',
    'man',
    'agement',
    'N',
    'W',
    'Ġpres',
    'ently',
    'ĠBru',
    'ins',
    'M',
    'ember',
    'ĠM',
    'ang',
    'id',
    'an',
    'Ġboost',
    'ing',
    'w',
    'yn',
    '+',
    '.',
    'requ',
    'isite',
    'ĠNY',
    'PD',
    'ĠMe',
    'gan',
    'ĠCond',
    'itions',
    'Ġp',
    'ics',
    'nes',
    'ium',
    'ĠR',
    'ash',
    'Ġ17',
    '4',
    'ĠD',
    'ucks',
    'Ġemb',
    'ro',
    'z',
    'u',
    'on',
    'ian',
    'rel',
    'igious',
    'Ġc',
    'raz',
    'ĠAC',
    'A',
    'ĠZ',
    'ucker',
    'EM',
    'A',
    'ĠPro',
    's',
    'We',
    'apon',
    'ĠKn',
    'ox',
    'ĠAr',
    'duino',
    'Ġst',
    'ove',
    'Ġheaven',
    's',
    'ĠP',
    'urchase',
    'Ġher',
    'd',
    'Ġfundra',
    'iser',
    'Dig',
    'ital',
    '5',
    '000',
    'Ġprop',
    'onents',
    '/',
    'âĢĭ',
    'Ġj',
    'elly',
    'ĠVis',
    'a',
    'Ġmon',
    'ks',
    'Ġadvance',
    'ment',
    'ĠW',
    'er',
    'Ġ18',
    '7',
    'e',
    'us',
    'ert',
    'ility',
    'Ġfet',
    'al',
    'Ġ19',
    '36',
    'L',
    'o',
    'Ġout',
    'fits',
    'Ġstair',
    'case',
    'b',
    'omb',
    'Ġcustom',
    'ized',
    'cl',
    'air',
    'T',
    'ree',
    'Ġm',
    'apped',
    'ĠConsider',
    'ing',
    'ĠTor',
    'res',
    'Ġmeth',
    'yl',
    'Ġapprox',
    'imate',
    'Ġdo',
    'om',
    'ĠHans',
    'en',
    'Ġc',
    'rossover',
    'Ġstand',
    'alone',
    'ä',
    '¼',
    'Ġinv',
    'ites',
    'Ġgra',
    'veyard',
    'Ġh',
    'p',
    'Donald',
    'Trump',
    'Ġesc',
    'ort',
    'G',
    'ar',
    'Ġpredec',
    'essors',
    'Ġh',
    'ay',
    'Ġen',
    'zyme',
    'ĠStra',
    'ight',
    'vis',
    'ors',
    'I',
    'ng',
    'ane',
    'ously',
    'ĠApp',
    'lied',
    'Ġf',
    'ec',
    'ĠDur',
    'ant',
    'Ġout',
    'spoken',
    'or',
    'b',
    'Ġz',
    'eal',
    'Ġdisgr',
    'ace',
    "'",
    ').',
    'ĠChe',
    'ng',
    '28',
    '9',
    'ĠRen',
    'a',
    'ĠSu',
    'icide',
    '29',
    '4',
    'Ġout',
    'raged',
    'ĠNew',
    'man',
    'ĠN',
    'vidia',
    'ĠA',
    'ber',
    'ĠB',
    'ers',
    'Ġrecre',
    'ation',
    'Wind',
    'ow',
    'ĠD',
    'P',
    'x',
    'e',
    'Ġped',
    'oph',
    'Ġfall',
    'out',
    'ambo',
    'o',
    'Ġpresent',
    'ations',
    'ĠApp',
    's',
    'Ġh',
    'tml',
    '3',
    '45',
    'ĠX',
    'XX',
    'Ġrub',
    'bing',
    'ĠLe',
    'ather',
    'Ġhum',
    'idity',
    'se',
    'ys',
    'est',
    'ablished',
    'ĠUn',
    'its',
    '64',
    '6',
    'Ġrespect',
    'able',
    'A',
    'uto',
    'Ġthri',
    'ving',
    'ĠInn',
    'ovation',
    'ang',
    's',
    'Ext',
    'ra',
    'reg',
    'ulation',
    '29',
    '8',
    'p',
    'ick',
    'Ex',
    'amples',
    'ĠC',
    'J',
    'Att',
    'ack',
    'Ġdr',
    'acon',
    'L',
    'T',
    'Ġstick',
    'er',
    're',
    'rs',
    'Ġsun',
    'ny',
    'I',
    'ss',
    'reg',
    'ulated',
    'd',
    'im',
    'ĠAb',
    'stract',
    'Ġhus',
    'bands',
    'Off',
    'ice',
    'om',
    'ination',
    'it',
    'ars',
    'AN',
    'GE',
    'asc',
    'al',
    'ĠK',
    'ris',
    'ĠInf',
    'antry',
    'Ġm',
    'alf',
    'ĠA',
    'the',
    'ĠR',
    'ally',
    'bal',
    'anced',
    '................',
    '........',
    'OU',
    'P',
    'Ġmole',
    'cule',
    'met',
    'ics',
    'ĠSpl',
    'it',
    'ĠInstruct',
    'ions',
    'ĠN',
    'ights',
    'c',
    'ards',
    'Ġt',
    'ug',
    'Ġcon',
    'e',
    'å',
    'Ń',
    'Ġt',
    'x',
    'ĠDisc',
    'ussion',
    'Ġcatast',
    'rophe',
    'pp',
    'e',
    'g',
    'io',
    'Ġcommun',
    'ism',
    'Ġhal',
    'ted',
    'ĠGu',
    'ant',
    'cle',
    'an',
    'ĠSc',
    'hed',
    'ĠK',
    'anye',
    'Ġw',
    'ander',
    'ĠSer',
    'iously',
    'Ġ18',
    '8',
    'enn',
    'ial',
    'f',
    'ollow',
    'product',
    'ive',
    'ĠFl',
    'ow',
    'ĠS',
    'ail',
    'Ġc',
    'raw',
    'Ġsim',
    'ulations',
    'or',
    'u',
    'ang',
    'les',
    'ĠN',
    'olan',
    'Ġmen',
    'stru',
    '4',
    '70',
    'Ġ20',
    '7',
    'aj',
    'a',
    'Ġcas',
    'ually',
    'board',
    'ing',
    'Ġ2',
    '22',
    'ov',
    'y',
    'ĠN',
    'umbers',
    'um',
    'at',
    'O',
    'E',
    '28',
    '7',
    'ĠCle',
    'mson',
    'Ġcert',
    's',
    'Ġsl',
    'id',
    'ĠT',
    'ribe',
    'Ġto',
    'ast',
    'Ġfort',
    'unes',
    'Ġf',
    'als',
    'ĠComm',
    'ittees',
    'Ġg',
    'p',
    'Ġf',
    'iery',
    'ĠN',
    'ets',
    'ĠAn',
    'ime',
    'Pack',
    'age',
    'ĠComp',
    'are',
    'l',
    'aughter',
    'in',
    'fect',
    'Ġatroc',
    'ities',
    'Ġjust',
    'ices',
    'Ġins',
    'ults',
    'ĠVern',
    'on',
    'Ġsh',
    'aken',
    'Ġperson',
    'a',
    'est',
    'amp',
    '36',
    '7',
    'br',
    'ain',
    'Ġexperiment',
    'ing',
    'K',
    'en',
    'ĠElect',
    'ronics',
    'Ġ16',
    '1',
    'dom',
    'ain',
    'Ġgraph',
    'ical',
    'b',
    'ishop',
    'Ġwho',
    'pping',
    'ĠEv',
    'angel',
    'Ġadvertis',
    'ers',
    'ĠSpe',
    'ar',
    'Ġb',
    'ids',
    'Ġdestro',
    'ys',
    'ut',
    'z',
    'Ġunders',
    'c',
    'ĠAD',
    'D',
    'Ġan',
    'ts',
    'ĠC',
    'um',
    'ipp',
    'les',
    'ĠF',
    'ill',
    'Ġgl',
    'anced',
    'Ġind',
    'icted',
    'ĠE',
    'ff',
    'Ġmis',
    'con',
    'ĠDes',
    'ktop',
    'Ġab',
    'ide',
    'ãĥ',
    'Ģ',
    'ĠI',
    'o',
    'ĠC',
    'oul',
    'Ġcaps',
    'ule',
    'ĠCh',
    'rys',
    'M',
    'ON',
    'Ġund',
    'es',
    'ĠI',
    'RA',
    'Ġc',
    'itation',
    'Ġdict',
    'ate',
    'ĠNet',
    'works',
    'ĠConf',
    'lict',
    'ĠSt',
    'uff',
    'x',
    'a',
    'is',
    'ec',
    'ĠChem',
    'istry',
    'Ġquarter',
    'ly',
    'William',
    's',
    'an',
    'an',
    'O',
    'pt',
    'ĠAlexand',
    'ria',
    'out',
    'heastern',
    'ĠSpring',
    'field',
    'ĠBlack',
    's',
    'Ġge',
    'ography',
    '24',
    '2',
    'Ġut',
    'most',
    'ĠEx',
    'xon',
    'ab',
    'outs',
    'E',
    'VA',
    'ĠEn',
    'able',
    'ĠBar',
    'r',
    'Ġdisag',
    'reed',
    'ĠCy',
    'prus',
    'Ġdement',
    'ia',
    'Ġlab',
    's',
    'Ġubiqu',
    'itous',
    'ĠLO',
    'VE',
    'Ġconsolid',
    'ated',
    's',
    'r',
    'Ġcream',
    'y',
    'ĠTim',
    'ber',
    'Reg',
    'ardless',
    'ĠCert',
    'ificate',
    'Ġ"',
    '...',
    'ogen',
    'ous',
    'Capt',
    'ain',
    'Ġinsult',
    'ing',
    'ĠSor',
    'os',
    'ĠInst',
    'r',
    'ĠBulgar',
    'ia',
    'bet',
    'ter',
    'Ġsuck',
    'ing',
    'ĠDavid',
    'son',
    'at',
    'z',
    'Ġcoll',
    'ateral',
    'g',
    'if',
    'Ġplag',
    'ued',
    'ĠC',
    'ancel',
    'ĠGard',
    'ner',
    'R',
    'B',
    'Ġsix',
    'teen',
    'Rem',
    'ove',
    'ur',
    'istic',
    'c',
    'ook',
    'R',
    'od',
    'Ġcompr',
    'ising',
    'f',
    'le',
    ')',
    'âĢĶ',
    'ĠVik',
    'ing',
    'g',
    'rowth',
    'agon',
    'al',
    'Ġsr',
    'f',
    'af',
    'ety',
    'm',
    'ot',
    'N',
    'early',
    'st',
    'own',
    'ĠF',
    'actor',
    'Ġautom',
    'obile',
    'Ġproced',
    'ural',
    'm',
    'ask',
    'amp',
    'ires',
    'Ġdisapp',
    'ears',
    'j',
    'ab',
    '3',
    '15',
    'Ġ19',
    '51',
    'ne',
    'eded',
    'Ġd',
    'aring',
    'le',
    'ader',
    'Ġp',
    'odium',
    'Ġun',
    'healthy',
    'Ġm',
    'und',
    'Ġpy',
    'ramid',
    'oc',
    're',
    'Ġkiss',
    'ed',
    'Ġdream',
    'ed',
    'ĠFant',
    'astic',
    'ĠG',
    'ly',
    'å',
    'Ĭ',
    'Ġgreat',
    'ness',
    'Ġsp',
    'ices',
    'Ġmet',
    'ropolitan',
    'Ġcomp',
    'uls',
    'i',
    'ets',
    '101',
    '6',
    'ĠSh',
    'am',
    'ĠP',
    'yr',
    'fl',
    'ies',
    'ĠMid',
    'night',
    'Ġswall',
    'owed',
    'Ġgen',
    'res',
    'ĠL',
    'ucky',
    'ĠRew',
    'ards',
    'Ġdisp',
    'atch',
    'ĠI',
    'PA',
    'ĠApp',
    'ly',
    'Ġa',
    'ven',
    'al',
    'ities',
    '3',
    '12',
    'th',
    'ings',
    'Ġ(',
    ').',
    'Ġm',
    'ates',
    'ĠS',
    'z',
    'ĠC',
    'OP',
    'ol',
    'ate',
    'O',
    'FF',
    'Ġre',
    'charge',
    'c',
    'aps',
    'ĠYork',
    'er',
    'ic',
    'one',
    'Ġgal',
    'axies',
    'ile',
    'aks',
    'D',
    'ave',
    'ĠP',
    'uzz',
    'ĠCelt',
    'ic',
    'ĠA',
    'FC',
    '27',
    '6',
    'ĠS',
    'ons',
    'Ġaffirm',
    'ative',
    'H',
    'or',
    'Ġtutorial',
    's',
    'ĠC',
    'ITY',
    'ĠR',
    'osa',
    'ĠExt',
    'ension',
    'Ser',
    'ies',
    'Ġf',
    'ats',
    'Ġr',
    'ab',
    'l',
    'is',
    'Ġun',
    'ic',
    'Ġe',
    've',
    'ĠSp',
    'in',
    'Ġadul',
    'thood',
    'ty',
    'p',
    'Ġsect',
    'arian',
    'Ġcheck',
    'out',
    'ĠCy',
    'cl',
    'S',
    'ingle',
    'Ġmart',
    'yr',
    'Ġch',
    'illing',
    '88',
    '8',
    'ou',
    'fl',
    'Ġ]',
    ';',
    'Ġcongest',
    'ion',
    'm',
    'k',
    'ĠWhere',
    'as',
    'Ġ19',
    '38',
    'ur',
    'rencies',
    'er',
    'ion',
    'Ġbo',
    'ast',
    'ĠPat',
    'ients',
    'Ġch',
    'ap',
    'ĠB',
    'D',
    'real',
    'DonaldTrump',
    'Ġexam',
    'ines',
    'h',
    'ov',
    'Ġstart',
    'ling',
    'ĠBab',
    'ylon',
    'w',
    'id',
    'om',
    'ew',
    'br',
    'ance',
    'ĠOd',
    'yssey',
    'w',
    'ig',
    'Ġtor',
    'ch',
    'ĠV',
    'ox',
    'ĠMo',
    'z',
    'ĠT',
    'roll',
    'ĠAn',
    's',
    'Similar',
    'ly',
    'ĠF',
    'ul',
    '00',
    '6',
    'Un',
    'less',
    'ĠAl',
    'one',
    'st',
    'ead',
    'ĠPub',
    'lisher',
    'r',
    'ights',
    't',
    'u',
    'ĠDoes',
    'n',
    'Ġprofession',
    'ally',
    'Ġcl',
    'o',
    'ic',
    'z',
    'Ġste',
    'als',
    'Ġ',
    'á',
    '19',
    '86',
    'Ġst',
    'urdy',
    'ĠJoh',
    'ann',
    'Ġmed',
    'als',
    'Ġfil',
    'ings',
    'ĠFr',
    'aser',
    'd',
    'one',
    'Ġmult',
    'inational',
    'Ġf',
    'eder',
    'Ġworth',
    'less',
    'Ġp',
    'est',
    'Yes',
    'terday',
    'ank',
    'ind',
    'Ġg',
    'ays',
    'Ġb',
    'orne',
    'ĠP',
    'OS',
    'Pict',
    'ure',
    'Ġpercent',
    'ages',
    '25',
    '1',
    'r',
    'ame',
    'Ġpot',
    'ions',
    'AM',
    'D',
    'ĠLeban',
    'ese',
    'Ġr',
    'ang',
    'ĠL',
    'SU',
    'ong',
    's',
    'Ġpen',
    'insula',
    'ĠCl',
    'ause',
    'AL',
    'K',
    'oh',
    'a',
    'ĠMac',
    'Book',
    'Ġunanim',
    'ous',
    'Ġl',
    'enders',
    'Ġhang',
    's',
    'Ġfranch',
    'ises',
    'ore',
    'rs',
    'ĠUp',
    'dates',
    'Ġisol',
    'ate',
    'and',
    'ro',
    'S',
    'oon',
    'Ġdisrupt',
    'ive',
    'ĠSur',
    've',
    'Ġst',
    'itches',
    'ĠSc',
    'orp',
    'ĠDomin',
    'ion',
    'Ġsupp',
    'lying',
    'Ar',
    'g',
    'Ġtur',
    'ret',
    'ĠL',
    'uk',
    'Ġbr',
    'ackets',
    '*',
    ')',
    'ĠRevolution',
    'ary',
    'ĠHon',
    'est',
    'Ġnot',
    'icing',
    'ĠSh',
    'annon',
    'Ġafford',
    'ed',
    'Ġth',
    'a',
    'ĠJan',
    'et',
    '!',
    '--',
    'ĠNare',
    'ndra',
    'ĠPl',
    'ot',
    'H',
    'ol',
    'se',
    'ver',
    'e',
    'enth',
    'Ġobst',
    'ruction',
    'Ġ10',
    '24',
    'st',
    'aff',
    'j',
    'as',
    'or',
    'get',
    'sc',
    'enes',
    'l',
    'aughs',
    'ĠF',
    'argo',
    'cr',
    'ime',
    'Ġorche',
    'str',
    'Ġde',
    'let',
    'ili',
    'ary',
    'rie',
    'ved',
    'Ġmilit',
    'ar',
    'ĠGreen',
    'e',
    'âĹ',
    'ı',
    'ãģ',
    '¦',
    'ĠGu',
    'ards',
    'Ġunle',
    'ashed',
    'ĠWe',
    'ber',
    'Ġadjust',
    'able',
    'Ġcal',
    'iber',
    'Ġmotiv',
    'ations',
    'ĠÃ',
    'ł',
    'm',
    'Ah',
    'ĠL',
    'anka',
    'hand',
    'le',
    'Ġp',
    'ent',
    'ĠR',
    'av',
    'ĠAng',
    'ular',
    'ĠK',
    'au',
    'umb',
    'ing',
    'Ġphil',
    'anthrop',
    'Ġde',
    'hyd',
    'Ġtox',
    'icity',
    'e',
    'er',
    'ĠY',
    'ORK',
    'w',
    'itz',
    'å',
    '¼',
    'ĠI',
    'E',
    'commun',
    'ity',
    'ĠA',
    'H',
    'Ġret',
    'ali',
    'Ġmass',
    'ively',
    'ĠDani',
    'els',
    'ĠD',
    'EL',
    'Ġcar',
    'cin',
    'Ur',
    'l',
    'Ġrout',
    'ing',
    'ĠNPC',
    's',
    'ĠR',
    'AF',
    'ry',
    'ce',
    'Ġwa',
    'ived',
    'ĠGu',
    'atem',
    'Every',
    'body',
    'Ġco',
    'venant',
    'Ġ17',
    '3',
    'Ġrelax',
    'ing',
    'Ġqu',
    'art',
    'al',
    'most',
    'Ġguard',
    'ed',
    'ĠSold',
    'iers',
    'ĠPL',
    'AY',
    'Ġout',
    'going',
    'L',
    'AND',
    'Ġre',
    'write',
    'ĠM',
    'OV',
    'ĠIm',
    'per',
    'ĠS',
    'olution',
    'Ġphenomen',
    'al',
    'Ġl',
    'ongevity',
    'Ġimp',
    'at',
    'ĠN',
    'issan',
    'ir',
    'ie',
    'Ġod',
    'or',
    'ĠZ',
    'ar',
    'ok',
    's',
    'Ġmilit',
    'ias',
    'ĠSP',
    'EC',
    'Ġtoler',
    'ated',
    'ars',
    'er',
    'ĠBrad',
    'ford',
    '+',
    ',',
    'Ġsur',
    'real',
    's',
    'f',
    'Can',
    'adian',
    'Ġresemb',
    'lance',
    'Ġcarbohyd',
    'rate',
    'VI',
    'EW',
    'Ġaccess',
    'ory',
    'me',
    'al',
    'larg',
    'est',
    'ieg',
    'el',
    'Some',
    'one',
    'Ġtoug',
    'hest',
    'os',
    'o',
    'Ġfun',
    'nel',
    'Ġcondemn',
    'ation',
    'lu',
    'ent',
    'Ġw',
    'ired',
    'ĠSun',
    'set',
    'Jes',
    'us',
    'ĠP',
    'ST',
    'ĠP',
    'ages',
    'ĠTy',
    'coon',
    'ĠP',
    'F',
    'Ġselect',
    'ions',
    'Ġ',
    'à¤',
    'part',
    'isan',
    'Ġhigh',
    's',
    'ĠR',
    'une',
    'Ġcraft',
    's',
    'le',
    'ad',
    'ĠParent',
    's',
    'Ġre',
    'claim',
    'ek',
    'er',
    'ĠAll',
    'ied',
    'ae',
    'per',
    'Ġlo',
    'oming',
    'Ġbenefic',
    'iaries',
    'ĠH',
    'ull',
    'Stud',
    'ents',
    'Jew',
    'ish',
    'd',
    'j',
    'Ġp',
    'act',
    'tem',
    'plate',
    'ĠOffic',
    'ials',
    'ĠBay',
    'lor',
    'Ġhe',
    'mp',
    'Ġyouth',
    's',
    'ĠLevel',
    's',
    'ĠX',
    'iao',
    'ĠC',
    'hes',
    'Ġende',
    'avor',
    'ĠRem',
    'oved',
    'Ġhipp',
    'ocamp',
    'H',
    'ell',
    'ãĤ',
    'Ĭ',
    '80',
    '5',
    'Ġd',
    'inosaur',
    'ĠWr',
    'ath',
    'ĠIndones',
    'ian',
    'Ġcalcul',
    'ator',
    'ĠD',
    'ictionary',
    'Ġ4',
    '20',
    'ĠM',
    'AG',
    '(',
    '_',
    '!',
    ',',
    't',
    'arians',
    'Ġrestrict',
    'ing',
    'rac',
    'use',
    'Ġweek',
    'day',
    'OU',
    'NT',
    'Ġsh',
    'rugged',
    'leg',
    'round',
    'Ġb',
    'ald',
    'ĠDo',
    'ctors',
    'Ġt',
    'outed',
    'ĠMax',
    'well',
    'Ġ2',
    '14',
    'Ġdiplom',
    'at',
    'Ġrep',
    'ression',
    'Ġconstitu',
    'ency',
    'v',
    'ice',
    'r',
    'anked',
    'ĠNap',
    'oleon',
    'g',
    'ang',
    'ĠFore',
    'ver',
    't',
    'un',
    'Ġbul',
    'b',
    'ĠPD',
    'T',
    'ĠC',
    'isco',
    'V',
    'EN',
    'Ġres',
    'umed',
    'Ste',
    'ven',
    'ĠManit',
    'oba',
    'Ġfab',
    'ulous',
    'ĠAg',
    'ents',
    '19',
    '84',
    'Ġam',
    'using',
    'ĠMyster',
    'ies',
    'Ġor',
    'thodox',
    'fl',
    'oor',
    'Ġquestion',
    'naire',
    'Ġpenet',
    'rate',
    'Ġfilm',
    'makers',
    'ĠUn',
    'c',
    'Ġst',
    'amped',
    'Ġth',
    'irteen',
    'Ġout',
    'field',
    'Ġforward',
    'ed',
    'Ġapp',
    'ra',
    'Ġa',
    'ided',
    't',
    'ry',
    'Ġunf',
    'ocused',
    'ĠL',
    'iz',
    'ĠWend',
    'y',
    'ĠSc',
    'ene',
    'Ch',
    'arg',
    'Ġreject',
    's',
    'Ġleft',
    'ist',
    'ĠProv',
    'idence',
    'ĠBr',
    'id',
    'reg',
    'n',
    'Ġprophe',
    'cy',
    'ĠL',
    'IVE',
    '4',
    '99',
    'Ġfor',
    'ge',
    'ĠF',
    'ML',
    'Ġintrins',
    'ic',
    'ĠF',
    'rog',
    'Ġw',
    'ont',
    'ĠH',
    'olt',
    'Ġfam',
    'ed',
    'CL',
    'US',
    'aeper',
    'nick',
    'ĠH',
    'ate',
    'ĠC',
    'ay',
    'Ġregister',
    'ing',
    'ort',
    'ality',
    'rop',
    'y',
    'ocaly',
    'ptic',
    'a',
    'an',
    'n',
    'av',
    'Ġfasc',
    'ist',
    'IF',
    'IED',
    'Ġimpl',
    'icated',
    'ĠRes',
    'ort',
    'ĠChand',
    'ler',
    'ĠBr',
    'ick',
    'P',
    'in',
    'ys',
    'c',
    'Us',
    'age',
    'ĠHel',
    'm',
    'us',
    'ra',
    'âĺħ',
    'âĺħ',
    'ĠAb',
    'bas',
    'Ġunanim',
    'ously',
    'Ġke',
    'eper',
    'Ġadd',
    'icted',
    '??',
    '?',
    'Ġhelm',
    'ets',
    'Ġant',
    'ioxid',
    'aps',
    'ed',
    '80',
    '8',
    'gi',
    'ene',
    'Ġwa',
    'its',
    'Ġmin',
    'ion',
    'ra',
    'ved',
    'ĠP',
    'orsche',
    'Ġdream',
    'ing',
    'Ġ17',
    '1',
    'ĠC',
    'ain',
    'Ġun',
    'for',
    'ass',
    'o',
    'ĠConfig',
    'uration',
    'k',
    'un',
    'hard',
    't',
    'Ġn',
    'ested',
    'ĠL',
    'DS',
    'L',
    'ES',
    'Ġt',
    'ying',
    'en',
    'os',
    'Ġc',
    'ue',
    'ĠMar',
    'qu',
    'sk',
    'irts',
    'Ġclick',
    'ed',
    'Ġexp',
    'iration',
    'ĠAccording',
    'ly',
    'ĠW',
    'C',
    'Ġbless',
    'ings',
    'Ġaddict',
    'ive',
    'ĠN',
    'arr',
    'y',
    'x',
    'ĠJagu',
    'ars',
    'Ġrent',
    's',
    'ĠS',
    'iber',
    'Ġt',
    'ipped',
    'ous',
    'se',
    'ĠFitz',
    'gerald',
    'Ġhier',
    'arch',
    'out',
    'ine',
    'Ġwa',
    'velength',
    '>',
    '.',
    'ch',
    'id',
    'ĠProcess',
    'ing',
    '/',
    '+',
    'r',
    'anking',
    'E',
    'asy',
    'ĠConst',
    'ruct',
    'Ġt',
    'et',
    'ins',
    'ured',
    'H',
    'UD',
    'Ġqu',
    'oting',
    'Ġcommun',
    'icated',
    'in',
    'x',
    'Ġin',
    'mate',
    'Ġerect',
    'ed',
    'ĠAbs',
    'olutely',
    'ĠSure',
    'ly',
    'Ġun',
    'im',
    'ĠThr',
    'one',
    'he',
    'id',
    'Ġcl',
    'aws',
    'Ġsuper',
    'star',
    'ĠL',
    'enn',
    'ĠWh',
    'is',
    'U',
    'k',
    'ab',
    'ol',
    'Ġsk',
    'et',
    'ĠN',
    'iet',
    'Ġper',
    'ks',
    'Ġaff',
    'inity',
    'Ġopen',
    'ings',
    'phas',
    'is',
    'Ġdiscrim',
    'inate',
    'T',
    'ip',
    'v',
    'c',
    'Ġgr',
    'inding',
    'ĠJenn',
    'y',
    'Ġast',
    'hma',
    'hol',
    'es',
    'ĠHom',
    'er',
    'Ġreg',
    'isters',
    'ĠGl',
    'ad',
    'Ġcre',
    'ations',
    'Ġlith',
    'ium',
    'Ġappl',
    'ause',
    'unt',
    'il',
    'Just',
    'ice',
    'ĠTur',
    'ks',
    'Ġsc',
    'andals',
    'Ġb',
    'ake',
    't',
    'ank',
    'M',
    'ech',
    'ĠMe',
    'ans',
    'ĠM',
    'aid',
    'Republic',
    'ans',
    'is',
    'al',
    'wind',
    'ows',
    'ĠSant',
    'os',
    'Ġveget',
    'ation',
    '33',
    '8',
    't',
    'ri',
    'Ġfl',
    'ux',
    'ins',
    'ert',
    'Ġclar',
    'ified',
    'Ġmort',
    'g',
    'ĠCh',
    'im',
    'ĠT',
    'ort',
    'Ġdiscl',
    'aim',
    'met',
    'al',
    'ĠAs',
    'ide',
    'Ġindu',
    'ction',
    'Ġinf',
    'l',
    'Ġathe',
    'ists',
    'amp',
    'h',
    'Ġe',
    'ther',
    'ĠV',
    'ital',
    'ĠBu',
    'ilt',
    'M',
    'ind',
    'Ġweapon',
    'ry',
    'S',
    'ET',
    'Ġ18',
    '6',
    'ad',
    'min',
    'g',
    'am',
    'cont',
    'ract',
    'af',
    'a',
    'Ġderiv',
    'atives',
    'Ġsn',
    'acks',
    'Ġch',
    'urn',
    'E',
    'conom',
    'Ġca',
    'pped',
    'ĠUnder',
    'standing',
    'ĠH',
    'ers',
    'ĠI',
    'z',
    'Ġd',
    'uct',
    'I',
    'ENT',
    'augh',
    'ty',
    'Ġâľ',
    'Ķ',
    'ĠN',
    'P',
    'Ġsa',
    'iling',
    'In',
    'itialized',
    'Ġt',
    'ed',
    'Ġreact',
    'ors',
    'ĠL',
    'omb',
    'Ġcho',
    'ke',
    'ĠW',
    'orm',
    'Ġadm',
    'iration',
    'Ġsw',
    'ung',
    'ens',
    'ibly',
    'Ġr',
    'ash',
    'ĠGo',
    'als',
    'ĠImport',
    'ant',
    'Sh',
    'ot',
    'ĠR',
    'as',
    'Ġtrain',
    'ers',
    'ĠB',
    'un',
    'Work',
    'ing',
    'Ġhar',
    'med',
    'ĠPand',
    'ora',
    'ĠL',
    'TE',
    'Ġmush',
    'room',
    'ĠCH',
    'AR',
    'ĠF',
    'ee',
    'ĠM',
    'oy',
    'B',
    'orn',
    'ol',
    'iberal',
    'ĠMart',
    'ial',
    'Ġgentle',
    'men',
    'Ġling',
    'ering',
    'Offic',
    'ial',
    'Ġgra',
    'ffiti',
    'ĠN',
    'ames',
    'D',
    'er',
    'Ġqu',
    'int',
    'ist',
    'rate',
    'aze',
    'era',
    'ĠNOT',
    'ICE',
    'ĠFlore',
    'nce',
    'Ġpay',
    'able',
    'Ġdep',
    'icts',
    'ĠSpe',
    'cies',
    'He',
    'art',
    'âĶĢâĶĢâĶĢâĶĢ',
    'âĶĢâĶĢâĶĢâĶĢ',
    'Ġencl',
    'osed',
    'Incre',
    'ases',
    'D',
    'aily',
    'ĠL',
    'is',
    'Ġenact',
    'ment',
    'ĠB',
    'acon',
    'ĠSt',
    'eele',
    'dem',
    'and',
    'Ġ18',
    '3',
    'Ġmouth',
    's',
    'Ġstr',
    'anded',
    'Ġenhance',
    'ment',
    '01',
    '1',
    'ĠWh',
    'ats',
    'Ġhe',
    'aled',
    'en',
    'y',
    'ĠR',
    'ab',
    'Ġ3',
    '40',
    'ĠLab',
    'yrinth',
    'ro',
    'ach',
    'ĠY',
    'osh',
    'ĠCl',
    'ippers',
    'Ġconcert',
    's',
    'Intern',
    'et',
    '35',
    '5',
    'Ġstick',
    'ers',
    'Ġter',
    'med',
    'ĠAx',
    'e',
    'Ġgrand',
    'parents',
    'Fr',
    'ance',
    'ĠCl',
    'im',
    'ĠU',
    'h',
    'ul',
    'ic',
    'Ġthr',
    'ill',
    'cent',
    'ric',
    'ĠOver',
    'view',
    'ĠCond',
    'uct',
    'Ġsubstant',
    'ive',
    'Ġ18',
    '2',
    'm',
    'ur',
    'Ġstr',
    'ay',
    'ĠCo',
    'ff',
    'Ġrep',
    'etitive',
    'ĠFor',
    'gotten',
    'Ġqual',
    'ification',
    'ew',
    'itness',
    'ĠZ',
    'imbabwe',
    'Ġsim',
    'ulated',
    'ĠJ',
    'D',
    '25',
    '3',
    'ĠW',
    'are',
    'Ġun',
    'sc',
    'T',
    'imes',
    'Ġsum',
    'mons',
    'Ġdis',
    'connected',
    'Ġ18',
    '4',
    'ci',
    'us',
    'ĠGu',
    'jar',
    'od',
    'ka',
    'Ġer',
    'ase',
    'ĠTob',
    'acco',
    'elect',
    'ed',
    'Ġun',
    'cont',
    'ĠShe',
    'pard',
    'ĠL',
    'amp',
    'Ġalert',
    'ed',
    'Ġoper',
    'ative',
    'arn',
    'a',
    'u',
    'int',
    'Ġneglig',
    'ence',
    'ac',
    'ements',
    'Ġsup',
    'ra',
    'Ġprev',
    'ail',
    'ĠSh',
    'ark',
    'Ġbel',
    'ts',
    'ãģ',
    '«',
    'Ġt',
    'ighter',
    'Engine',
    'ers',
    'Ġin',
    'active',
    'Ġexp',
    'onent',
    'ĠWill',
    'ie',
    'a',
    'ples',
    'Ġhe',
    'ir',
    'ĠH',
    'its',
    'ian',
    'n',
    'ĠS',
    'ays',
    'Ġcurrent',
    's',
    'ĠBeng',
    'al',
    'Ġar',
    'ist',
    'B',
    'uffer',
    'Ġbree',
    'ze',
    'ĠWes',
    'ley',
    'Col',
    'a',
    'Ġpron',
    'oun',
    'Ġde',
    'ed',
    'ĠK',
    'ling',
    'Ġof',
    't',
    'Ġinf',
    'lict',
    'Ġpun',
    'ishing',
    'Ġn',
    'm',
    'ik',
    'u',
    'OD',
    'UCT',
    '01',
    '4',
    'Ġsubsid',
    'y',
    'ĠDE',
    'A',
    'ĠHer',
    'bert',
    'ĠJ',
    'al',
    'B',
    'ank',
    'Ġdef',
    'erred',
    'Ġship',
    'ment',
    'B',
    'ott',
    'Ġal',
    'le',
    'b',
    'earing',
    'HT',
    'ML',
    'Off',
    'line',
    'Ġ2',
    '13',
    'Ġscroll',
    'ing',
    'Ġsc',
    'anned',
    'ĠLib',
    'yan',
    'ĠT',
    'OP',
    'ch',
    'rom',
    'd',
    't',
    'col',
    'umn',
    'Psy',
    'NetMessage',
    'Z',
    'ero',
    'Ġtor',
    'so',
    '0',
    '50',
    'âķ',
    'Ĳ',
    'Ġimp',
    'erson',
    'ĠSchw',
    'artz',
    'ud',
    'ic',
    'Ġpiss',
    'ed',
    'ĠS',
    'app',
    '25',
    '7',
    'ĠIS',
    'Ps',
    'og',
    'l',
    'Ġsuper',
    'vised',
    'Ġad',
    'olescent',
    'Ġatt',
    'ained',
    'ĠDel',
    'ivery',
    'ĠB',
    'unny',
    'Ġ19',
    '37',
    'Ġmini',
    'ature',
    'Ġo',
    's',
    'Ġ3',
    '70',
    '60',
    '8',
    'ĠMour',
    'inho',
    'Ġinn',
    'ate',
    'Ġtem',
    'po',
    'ĠN',
    'M',
    'ĠFall',
    'en',
    '00',
    '9',
    'Ġprov',
    'ocative',
    'Stream',
    'er',
    'ĠBened',
    'ict',
    'ĠBol',
    'she',
    'Ġt',
    'urtle',
    'ĠPC',
    'B',
    'ĠEqu',
    'al',
    'Direct',
    'or',
    'ĠR',
    'end',
    'Ġflu',
    'ids',
    'Author',
    'ities',
    'Ġcous',
    'ins',
    'requ',
    'ency',
    'ĠNeigh',
    'bor',
    's',
    'ets',
    'sh',
    'ared',
    'Char',
    'les',
    'pass',
    'word',
    'Ġg',
    'ears',
    'Ġ2',
    '11',
    'ĠHard',
    'ware',
    'ri',
    'ka',
    'Ġup',
    'stream',
    'H',
    'om',
    'Ġdisproportion',
    'ately',
    'iv',
    'ities',
    'Ġund',
    'efined',
    'Ġelect',
    'rons',
    'Ġcommem',
    'or',
    'Event',
    'ually',
    'Ġ>',
    '<',
    'Ġir',
    'responsible',
    '2',
    '18',
    'ĠRe',
    'leased',
    'ĠO',
    'VER',
    'ĠI',
    'GN',
    'ĠB',
    'read',
    'st',
    'ellar',
    'ĠS',
    'age',
    'tt',
    'ed',
    'dam',
    'age',
    'ed',
    'ition',
    'ĠPre',
    'c',
    'Ġl',
    'ime',
    'Ġconf',
    'inement',
    'Ġcal',
    'orie',
    'we',
    'apon',
    'Ġdiff',
    'ering',
    'ĠS',
    'ina',
    'm',
    'ys',
    'am',
    'd',
    'Ġintric',
    'ate',
    'k',
    'k',
    'ĠP',
    'AT',
    'Ã£',
    'o',
    'st',
    'ones',
    'lin',
    'ks',
    'Ġr',
    'anch',
    'Sem',
    'itic',
    'Ġdifferent',
    'iate',
    'ĠS',
    'inger',
    'occup',
    'ied',
    'Ġfort',
    'ress',
    'c',
    'md',
    'Ġinter',
    'ception',
    'ĠAnk',
    'ara',
    'Ġre',
    'pt',
    'ĠSol',
    'itaire',
    'Ġrem',
    'ake',
    'p',
    'red',
    'Ġd',
    'ared',
    'aut',
    'ions',
    'ĠB',
    'ACK',
    'Run',
    'ning',
    'Ġdebug',
    'ging',
    'Ġgraph',
    's',
    '3',
    '99',
    'ĠNig',
    'el',
    'Ġb',
    'un',
    'Ġpill',
    'ow',
    'Ġprog',
    'ressed',
    'fashion',
    'ed',
    'Ġob',
    'edience',
    'ER',
    'N',
    'Ġrehe',
    'ars',
    'C',
    'ell',
    't',
    'l',
    'S',
    'her',
    'Ġher',
    'ald',
    'ĠPay',
    'ment',
    'ĠC',
    'ory',
    'ĠDe',
    'pt',
    'Ġrep',
    'ent',
    'ĠWe',
    'ak',
    'uck',
    'land',
    'Ġple',
    'asing',
    'Ġshort',
    'ages',
    'Ġjur',
    'ors',
    'ĠK',
    'ab',
    'q',
    'qa',
    'Ant',
    'i',
    'Ġw',
    'ow',
    'ĠRC',
    'MP',
    'Ġt',
    'sun',
    'ĠS',
    'ic',
    'Ġcomp',
    'rises',
    'Ġsp',
    'ies',
    'Ġprec',
    'inct',
    'n',
    'u',
    'Ġur',
    'ges',
    'Ġtim',
    'ed',
    'Ġstrip',
    'es',
    'ĠB',
    'oots',
    'Ġy',
    'en',
    'Adv',
    'anced',
    'Ġdisc',
    'rete',
    'ĠArch',
    'angel',
    'employ',
    'ment',
    'D',
    'iff',
    'Ġmon',
    'uments',
    'Ġ20',
    '9',
    'work',
    'er',
    'Ġ19',
    '6',
    'ĠI',
    'g',
    'utter',
    'stock',
    'T',
    'PS',
    'J',
    'ac',
    'Ġhomeless',
    'ness',
    'Ġcomment',
    'ator',
    'Ġrac',
    'ially',
    'f',
    'ing',
    'se',
    'ed',
    'E',
    'le',
    'ell',
    'ation',
    'Ġeth',
    'anol',
    'Ġpar',
    'ish',
    'ĠD',
    'ong',
    'ĠAw',
    'akening',
    'Ġdev',
    'iation',
    'ĠB',
    'earing',
    'ĠTsu',
    'k',
    'Ġrec',
    'ess',
    'Ġl',
    'ymph',
    'ĠCann',
    'abis',
    'å',
    'ľ',
    'ĠNEW',
    'S',
    'Ġd',
    'ra',
    'ĠStef',
    'an',
    'ĠWr',
    'ong',
    'ĠS',
    'AM',
    'Ġloose',
    'ly',
    'Ġinterpre',
    'ter',
    'ĠPl',
    'ain',
    'Go',
    'vernment',
    'Ġbigot',
    'ry',
    'Ġgren',
    'ades',
    'ave',
    'z',
    'pict',
    'ured',
    'Ġmand',
    'ated',
    'ĠMon',
    'k',
    'ĠPed',
    'ro',
    'Ġl',
    'ava',
    '27',
    '4',
    'Ġcyn',
    'ical',
    'ĠScroll',
    's',
    'l',
    'ocks',
    'M',
    'p',
    'Ġcon',
    'gregation',
    'orn',
    'ings',
    'ph',
    'il',
    'ĠI',
    'bid',
    'Ġf',
    'erv',
    'Ġdisapp',
    'earing',
    'Ġarrog',
    'ant',
    'sy',
    'n',
    'ĠMa',
    'ver',
    'ĠSu',
    'it',
    '24',
    '1',
    'Ġab',
    'bre',
    'ack',
    'ers',
    'P',
    'a',
    'ĠY',
    'el',
    'Whe',
    'never',
    'Ġ23',
    '5',
    'ĠV',
    'ine',
    'ĠAn',
    'at',
    'Ġext',
    'inct',
    'LE',
    'T',
    'Ġexecut',
    'able',
    'V',
    'ERS',
    'ox',
    'ide',
    'D',
    'NA',
    'ĠP',
    'rel',
    'Ġresent',
    'ment',
    'Ġcompr',
    'ise',
    'ĠAv',
    'iv',
    'Ġinter',
    'ceptions',
    'Ġprol',
    'ific',
    'IN',
    'A',
    'ĠEr',
    'in',
    'though',
    't',
    '2',
    '19',
    'ĠPsychiat',
    'ry',
    'un',
    'ky',
    'chem',
    'ist',
    'H',
    'o',
    'ĠMcC',
    'oy',
    'Ġbr',
    'icks',
    'L',
    'os',
    'ri',
    'ly',
    'ĠUS',
    'SR',
    'Ġr',
    'ud',
    'Ġl',
    'aud',
    'ĠW',
    'ise',
    'ĠEmer',
    'ald',
    'Ġrev',
    'ived',
    'Ġdam',
    'ned',
    'ĠRep',
    'air',
    'id',
    'em',
    'ct',
    'ica',
    'Ġpatri',
    'arch',
    'ĠN',
    'urs',
    'me',
    'g',
    'Ġcheap',
    'est',
    're',
    'ements',
    'empt',
    'y',
    'ĠCele',
    'br',
    'Ġdepri',
    'vation',
    'ch',
    'anted',
    'ĠTh',
    'umbnails',
    'E',
    'nergy',
    'ĠEth',
    'an',
    'ĠQ',
    'ing',
    'Ġopp',
    'oses',
    'W',
    'IND',
    'v',
    'ik',
    'ĠM',
    'au',
    'ĠS',
    'UB',
    '66',
    '7',
    'G',
    'RE',
    'ĠVol',
    'unte',
    'nt',
    'on',
    'C',
    'ook',
    'å',
    'Ĳ',
    'es',
    'que',
    'Ġplum',
    'met',
    'Ġsu',
    'ing',
    'Ġpron',
    'ounce',
    'Ġresist',
    'ing',
    'ĠF',
    'ishing',
    'ĠTri',
    'als',
    'Ġy',
    'ell',
    'Ġ3',
    '10',
    'Ġin',
    'duct',
    'Ġpersonal',
    'ized',
    'oft',
    'en',
    'R',
    'eb',
    'EM',
    'BER',
    'Ġview',
    'point',
    'Ġexist',
    'ential',
    '()',
    ')',
    'rem',
    'ove',
    'MENT',
    'S',
    'l',
    'asses',
    'Ġev',
    'apor',
    'Ġa',
    'isle',
    'met',
    'a',
    'Ġreflect',
    'ive',
    'Ġentit',
    'lement',
    'Ġdev',
    'ised',
    'mus',
    'ic',
    'asc',
    'ade',
    'Ġwind',
    'ing',
    'off',
    'set',
    'Ġaccess',
    'ibility',
    'ke',
    'red',
    'Bet',
    'ter',
    'ĠJohn',
    'ston',
    'th',
    'inking',
    'S',
    'now',
    'ĠCroat',
    'ia',
    'ĠAt',
    'omic',
    '27',
    '1',
    '34',
    '8',
    'Ġtext',
    'book',
    'ĠSix',
    'th',
    'Ġ',
    'Ø§ÙĦ',
    'Ġsl',
    'ider',
    'ĠBur',
    'ger',
    'b',
    'ol',
    'S',
    'ync',
    'Ġgrand',
    'children',
    'Ġc',
    'erv',
    '+',
    ')',
    'Ġe',
    'ternity',
    'Ġtweet',
    'ing',
    'Ġspec',
    'ulative',
    'Ġpiv',
    'otal',
    'ĠW',
    'P',
    'ĠT',
    'ER',
    'ynam',
    'ic',
    'Ġu',
    'pl',
    'ĠC',
    'ats',
    'per',
    'haps',
    'Ġclass',
    'mates',
    'Ġblat',
    'ant',
    "'",
    '-',
    'Ġl',
    'akh',
    'ant',
    'ine',
    'ĠB',
    'org',
    'i',
    'om',
    '/',
    '(',
    'ĠAthlet',
    'ic',
    'Ġs',
    'ar',
    'OT',
    'A',
    'ĠHoff',
    'man',
    'Never',
    'theless',
    'Ġad',
    'orable',
    'Ġspawn',
    'ed',
    'Ass',
    'ociated',
    'ĠDom',
    'estic',
    'Ġimpl',
    'ant',
    'ĠLux',
    'em',
    'ĠK',
    'ens',
    'Ġp',
    'umps',
    'ĠS',
    'AT',
    'Att',
    'ributes',
    '50',
    '9',
    'av',
    'our',
    'Ġcentral',
    'ized',
    'ĠT',
    'N',
    'Ġfresh',
    'ly',
    'ĠA',
    'chieve',
    'Ġouts',
    'iders',
    'her',
    'ty',
    'ĠRe',
    'e',
    'ĠT',
    'owers',
    'ĠD',
    'art',
    'ak',
    'able',
    'Ġm',
    'p',
    'ĠHeaven',
    'ly',
    'Ġr',
    'ipe',
    'ĠCarol',
    'ine',
    'ry',
    'an',
    'Ġclass',
    'ics',
    'Ġret',
    'iring',
    'Ġ2',
    '28',
    'Ġa',
    'h',
    'Ġdeal',
    'ings',
    'Ġpunch',
    'ing',
    'ĠChap',
    'man',
    'O',
    'ptions',
    'max',
    'well',
    'vol',
    'ume',
    'Ġst',
    'al',
    'Ġex',
    'ported',
    'ĠQu',
    'ite',
    'Ġnumer',
    'ical',
    'B',
    'urn',
    'F',
    'act',
    'ĠKey',
    'stone',
    'Ġtrend',
    'ing',
    'Ġalter',
    'ing',
    'ĠAfric',
    'ans',
    '47',
    '8',
    'ĠM',
    'N',
    'ĠKn',
    'ock',
    'Ġtempt',
    'ation',
    'Ġprest',
    'ige',
    'Over',
    'view',
    'ĠTrad',
    'itional',
    'ĠBah',
    'rain',
    'Priv',
    'ate',
    'ĠH',
    'OU',
    'Ġbar',
    'r',
    'ĠT',
    'at',
    'C',
    'ube',
    'US',
    'D',
    'ĠGrand',
    'e',
    'ĠG',
    'at',
    'ĠFl',
    'o',
    'Ġres',
    'ides',
    'Ġind',
    'ec',
    'vol',
    'ent',
    'Ġperpet',
    'ual',
    'ub',
    'es',
    'Ġworld',
    'view',
    'ĠQuant',
    'um',
    'Ġfil',
    'tered',
    'Ġen',
    'su',
    'orget',
    'own',
    'ERS',
    'ON',
    'ĠM',
    'ild',
    '37',
    '9',
    'OT',
    'T',
    'Ã',
    '¥',
    'Ġvit',
    'amins',
    'Ġrib',
    'bon',
    'Ġsincere',
    'ly',
    'ĠH',
    'in',
    'Ġeight',
    'een',
    'Ġcontradict',
    'ory',
    'Ġgl',
    'aring',
    'Ġexpect',
    'ancy',
    'Ġcons',
    'pir',
    'Ġmon',
    'strous',
    'Ġ3',
    '80',
    're',
    'ci',
    'Ġhand',
    'ic',
    'Ġpump',
    'ed',
    'Ġindic',
    'ative',
    'Ġr',
    'app',
    'Ġav',
    'ail',
    'ĠLEG',
    'O',
    'ĠMar',
    'ijuana',
    '19',
    '85',
    'ert',
    'on',
    'Ġtwent',
    'ieth',
    '################',
    '################',
    'ĠSw',
    'amp',
    'Ġval',
    'uation',
    'Ġaffili',
    'ates',
    'adjust',
    'ed',
    'ĠFac',
    'ility',
    '26',
    '2',
    'Ġenz',
    'ymes',
    'itud',
    'inal',
    'Ġimp',
    'rint',
    'S',
    'ite',
    'Ġinstall',
    'er',
    'ĠT',
    'RA',
    'm',
    'ology',
    'lin',
    'ear',
    'ĠCollect',
    'ive',
    'ig',
    'ating',
    'ĠT',
    'oken',
    'Ġspec',
    'ulated',
    'K',
    'N',
    'ĠC',
    'ly',
    'or',
    'ity',
    'Ġdef',
    'er',
    'Ġinspect',
    'ors',
    'appro',
    'ved',
    'R',
    'M',
    'ĠSun',
    's',
    'Ġinform',
    'ing',
    'ĠSy',
    'racuse',
    'ib',
    'li',
    '7',
    '65',
    'Ġgl',
    'ove',
    'Ġauthor',
    'ize',
    'âĢ¦âĢ¦âĢ¦âĢ¦',
    'âĢ¦âĢ¦âĢ¦âĢ¦',
    'ĠCru',
    'ise',
    'Ġcontract',
    'ing',
    'she',
    'll',
    'IF',
    'E',
    'ĠJew',
    'el',
    'p',
    'ract',
    'ĠPhot',
    'oshop',
    'ĠKnow',
    'ing',
    'h',
    'arm',
    'Ġattract',
    'ions',
    'ad',
    'an',
    'et',
    'us',
    '01',
    '8',
    'w',
    'agen',
    'Al',
    't',
    'Ġmultip',
    'ly',
    'Ġequ',
    'ilibrium',
    ':',
    '{',
    'ĠF',
    'ighters',
    'ĠEd',
    'gar',
    'Ġfour',
    'teen',
    'Go',
    'vern',
    'Ġmis',
    'use',
    'Ġab',
    'using',
    'Ġancest',
    'ry',
    'ram',
    'er',
    '64',
    '4',
    'Ġwor',
    'ms',
    'Ġthick',
    'er',
    'ĠComb',
    'ine',
    'Ġpeas',
    'ants',
    'Ġv',
    'ind',
    'Ġcon',
    'quest',
    'Ġm',
    'ocked',
    'Ġc',
    'innamon',
    'ĠC',
    'ald',
    'ĠGall',
    'up',
    'Ġavoid',
    'ance',
    'Ġincarn',
    'ation',
    'ĠStr',
    'at',
    'Ġt',
    'asted',
    'ent',
    'a',
    'ĠN',
    'eal',
    'p',
    'ared',
    'Ġtermin',
    'ology',
    'ject',
    'ion',
    'Scient',
    'ists',
    'ĠIN',
    'S',
    'ĠDe',
    'e',
    'Ġdirect',
    'ories',
    'R',
    'oad',
    'ĠSh',
    'ap',
    'br',
    'ight',
    'ĠDirect',
    'ors',
    'ĠCol',
    'umn',
    'Ġb',
    'ob',
    'Ġprefer',
    'ably',
    'Ġgl',
    'itch',
    'f',
    'urt',
    'Ġe',
    'g',
    'id',
    'is',
    'C',
    'BC',
    'Ġsur',
    'rendered',
    'Ġtest',
    'ament',
    '33',
    '6',
    'ug',
    'gest',
    'ĠN',
    'il',
    'an',
    'other',
    'Ġpat',
    'hetic',
    'ĠDon',
    'na',
    'Ġ2',
    '18',
    'ĠA',
    'very',
    'Ġwhis',
    'key',
    'Ġf',
    'ixture',
    'ĠCon',
    'quest',
    'Ġbet',
    's',
    'O',
    'cc',
    'ĠLe',
    'icester',
    ']',
    '."',
    'Ġ)',
    ');',
    'Ġfl',
    'ashes',
    '45',
    '6',
    'Ġmask',
    'ed',
    'ge',
    'bra',
    'Ġcomput',
    'ed',
    'che',
    'l',
    'aud',
    'er',
    'Ġdefe',
    'ats',
    'ĠLiber',
    'ation',
    'ĠOs',
    'ama',
    'ĠV',
    'ive',
    'Ch',
    'anges',
    'Ch',
    'annel',
    'Ġtar',
    'iffs',
    'Ġm',
    'age',
    'ĠS',
    'ax',
    'Ġinadvert',
    'ently',
    'ĠC',
    'RE',
    'ĠRe',
    'aper',
    'ink',
    'y',
    'gr',
    'ading',
    'Ġstere',
    'otyp',
    'Ġcur',
    'l',
    'ĠF',
    'ANT',
    'Ġfram',
    'eworks',
    'M',
    'om',
    'ĠAn',
    'ch',
    'Ġflav',
    'our',
    'car',
    'bon',
    'Ġperm',
    'itting',
    'let',
    'cher',
    'ĠMo',
    'zilla',
    'ĠPark',
    'ing',
    'ĠCh',
    'amp',
    'Sc',
    'roll',
    'Ġmurd',
    'erer',
    'Ġrest',
    'ed',
    'Ġow',
    'es',
    'ĠP',
    'oss',
    'AD',
    'D',
    'IF',
    'F',
    'res',
    'olution',
    'ĠMin',
    'ing',
    'Ġcompar',
    'ative',
    'D',
    'im',
    'Ġneighbour',
    'ing',
    'ĠA',
    'ST',
    'ĠT',
    'oxic',
    'Ġbi',
    'ases',
    'Ġgun',
    'fire',
    'ur',
    'ous',
    'ĠMom',
    'ent',
    '19',
    '83',
    'Ġper',
    'vasive',
    'tt',
    'p',
    'ĠNorm',
    'ally',
    'r',
    'ir',
    'S',
    'arah',
    'ĠAlb',
    'any',
    'Ġun',
    'sett',
    'ĠS',
    'MS',
    'ip',
    'ers',
    'l',
    'ayer',
    'ĠWh',
    'ites',
    'up',
    'le',
    'Ġtur',
    'bo',
    'ĠLe',
    'eds',
    'Ġthat',
    's',
    'ĠMin',
    'er',
    'M',
    'ER',
    'ĠRe',
    'ign',
    'Ġper',
    'me',
    'ĠBl',
    'itz',
    'Ġ19',
    '34',
    'Ġintimid',
    'ating',
    't',
    'ube',
    'Ġecc',
    'entric',
    'ab',
    'olic',
    'box',
    'es',
    'ĠAssoci',
    'ates',
    'v',
    'otes',
    'Ġsim',
    'ulate',
    'um',
    'bo',
    'aster',
    'y',
    'Ġship',
    'ments',
    'FF',
    'FF',
    'an',
    'th',
    'Ġseason',
    'ed',
    'Ġexperiment',
    'ation',
    'âĸ',
    'ł',
    'law',
    's',
    'Me',
    'et',
    'idd',
    'les',
    'ant',
    'ics',
    'R',
    'ating',
    'IS',
    'IS',
    'h',
    'ift',
    'Ġfront',
    's',
    'b',
    'uf',
    '01',
    '7',
    'Ġun',
    'att',
    'ĠD',
    'il',
    'le',
    'ases',
    'ĠGard',
    'ens',
    '77',
    '7',
    't',
    'ouch',
    've',
    'll',
    '45',
    '8',
    'Ġ=',
    '====',
    's',
    'aving',
    'Ġer',
    'osion',
    'ĠQu',
    'in',
    'Ġearn',
    's',
    'Ġaccomplish',
    'ment',
    'ĠWe',
    'i',
    'Ġ<',
    '[',
    '____',
    '_',
    'Ġir',
    'rig',
    'ĠT',
    'eddy',
    'Ġconqu',
    'ered',
    'ĠArm',
    'ored',
    'Ġassert',
    's',
    'Ġmanip',
    'ulating',
    'r',
    'Ã©',
    'Ġtranscript',
    's',
    'G',
    'allery',
    'Ġplot',
    'ting',
    'Ne',
    'il',
    'Ġbetray',
    'al',
    'load',
    'er',
    'ĠS',
    'ul',
    'Ġdispl',
    'acement',
    'Ġroy',
    'alty',
    'ĠW',
    'I',
    'he',
    'it',
    'ĠDev',
    'ices',
    'alle',
    'l',
    'Ġmunicipal',
    'ities',
    'Ġcan',
    'al',
    'St',
    'ars',
    'ĠU',
    'AE',
    'Ġ"',
    'âĢ¦',
    'ĠC',
    'U',
    'ab',
    'ove',
    'Ġreson',
    'ance',
    'ĠguiActive',
    'Un',
    'add',
    'ed',
    'ĠBra',
    'ves',
    'ĠI',
    'bn',
    'Ġhere',
    'by',
    'ĠB',
    'RE',
    'Ġshare',
    'holder',
    'ĠH',
    'ir',
    'ĠJ',
    'i',
    'Ġstrange',
    'ly',
    'Ġadm',
    'ired',
    'Ġpl',
    'ight',
    'Ġb',
    'achelor',
    'ĠP',
    'ole',
    'cipl',
    'inary',
    'T',
    'ony',
    'ĠArmen',
    'ian',
    'Ġun',
    'man',
    'ĠZion',
    'ist',
    'St',
    'age',
    'isco',
    'ver',
    'Ġautom',
    'otive',
    'Ġs',
    'idelines',
    'Ġsl',
    'ick',
    'ĠRena',
    'issance',
    'ĠF',
    'UN',
    'Im',
    'ages',
    'ĠH',
    'aj',
    'Ġp',
    'ing',
    'Ġshort',
    'cut',
    'ĠBl',
    'vd',
    'ĠLook',
    's',
    'Ġbur',
    'sts',
    'Ġcl',
    'amp',
    'Ġm',
    'ish',
    'Ġsort',
    'ing',
    'Ġpatri',
    'ot',
    'Ġcorrect',
    'ness',
    'ĠScand',
    'inav',
    'ĠCaval',
    'iers',
    'p',
    'ython',
    'az',
    'ar',
    'Ġ3',
    '75',
    'ĠJa',
    'une',
    '40',
    '9',
    'Ġdetrim',
    'ental',
    'Ġstab',
    'bing',
    'Ġpoison',
    'ed',
    'Ġf',
    'ountain',
    'oc',
    'ent',
    'or',
    'st',
    'ĠMar',
    'i',
    'Ġr',
    'ains',
    'ĠO',
    'vers',
    'ĠInst',
    'itution',
    'ud',
    'get',
    'AM',
    'Y',
    't',
    'ale',
    'ĠK',
    'R',
    'ĠPr',
    'ices',
    'Ġhead',
    'aches',
    'Ġlands',
    'l',
    'ĠA',
    'ura',
    'Bon',
    'us',
    'ĠZ',
    'hao',
    'ĠH',
    'ip',
    'Ġhop',
    's',
    'ĠKurd',
    'istan',
    'Ġexplo',
    'iting',
    'ry',
    'n',
    'Ġhypocr',
    'isy',
    'op',
    'ening',
    'Ġgun',
    'shot',
    'Ġw',
    'ed',
    'inter',
    'stitial',
    'Inter',
    'stitial',
    'Ġam',
    'en',
    'Bre',
    'aking',
    'Ġmarket',
    'ed',
    'W',
    'ire',
    'ĠC',
    'rowd',
    'Contin',
    'ue',
    'ĠK',
    'nown',
    'ĠEffect',
    'ive',
    'ore',
    'an',
    'iz',
    'ons',
    'Jose',
    'ph',
    'Ġescal',
    'ation',
    'us',
    'ername',
    'Ġcur',
    'tain',
    'AT',
    'ES',
    'ĠP',
    'AR',
    'ĠM',
    'iy',
    'Ġcounter',
    'fe',
    'l',
    'ene',
    'Ġcont',
    'enders',
    'd',
    'aily',
    'ĠAs',
    'c',
    'ĠPhill',
    'ip',
    'most',
    'ly',
    'Ġfil',
    'ename',
    'he',
    'ne',
    'Ġresemb',
    'ling',
    'Ġst',
    'aging',
    'ĠCh',
    'loe',
    'Ġw',
    'iring',
    'H',
    'on',
    'ĠRen',
    'ew',
    'ott',
    'age',
    'ĠHy',
    'brid',
    'm',
    'uch',
    'Ġstro',
    'kes',
    'Ġpolicy',
    'makers',
    'AP',
    'TER',
    'ĠArk',
    'ham',
    'pl',
    'ot',
    'Ġassist',
    'ants',
    'Ġde',
    'port',
    'ĠSe',
    'ga',
    'Ġinflu',
    'enza',
    'ĠC',
    'ursed',
    'ĠK',
    'obe',
    'Ġskin',
    'ny',
    'Prov',
    'ider',
    'ĠR',
    'ip',
    'Ġincrement',
    'al',
    'product',
    's',
    'B',
    'F',
    'Ġd',
    'ome',
    'ĠC',
    'redits',
    'Ġlos',
    'ers',
    'int',
    's',
    'ĠBet',
    'ty',
    'ĠTal',
    'ent',
    'ĠD',
    'AM',
    'L',
    'v',
    'E',
    'ss',
    'Ġd',
    'ens',
    'tem',
    'p',
    'J',
    'udge',
    'od',
    'ic',
    "Ġ'",
    '(',
    'UR',
    'ES',
    'ets',
    'k',
    'V',
    'O',
    'Ġretrie',
    'ved',
    'Ġarchitect',
    's',
    'Ù',
    'ĩ',
    'Ġeth',
    'ic',
    'ĠSecond',
    'ary',
    'st',
    'ocks',
    'ad',
    'ia',
    'Ġ3',
    '25',
    'ĠOp',
    'inion',
    'Ġsimultane',
    'ous',
    'Ġd',
    'izz',
    'ul',
    'p',
    'Ġsmugg',
    'ling',
    'ipp',
    'ery',
    'R',
    'andom',
    'f',
    'acing',
    'ĠD',
    'as',
    'Ġstock',
    'p',
    'Ġdiscl',
    'osures',
    'po',
    'inter',
    'Ġcor',
    'al',
    'ĠSe',
    'lection',
    'ĠP',
    'ike',
    'ival',
    'ent',
    'Ġruth',
    'less',
    'ĠR',
    'im',
    'Ġensu',
    'ing',
    'ĠExper',
    'iment',
    'Ġcongress',
    'man',
    'Ġbelie',
    'ver',
    'Ġun',
    'specified',
    'ĠM',
    'ord',
    'Ġknowledge',
    'able',
    'ĠV',
    'ERY',
    'T',
    'X',
    'Ġstra',
    'ps',
    'Ġtur',
    'f',
    'apesh',
    'ifter',
    'Ġmar',
    'ital',
    'Ġfl',
    'ock',
    'ãģ',
    'Ĩ',
    '26',
    '3',
    'AM',
    'ES',
    'ĠOpp',
    'osition',
    'Ġtre',
    'asures',
    'ĠG',
    'OD',
    'Ġmodel',
    'ed',
    'ĠWOR',
    'LD',
    'Ġ(',
    '[',
    'ĠUs',
    'age',
    'H',
    'F',
    'Ġ$',
    '(',
    'uss',
    'ed',
    'Ġpione',
    'er',
    'E',
    'ight',
    'par',
    'se',
    'b',
    'read',
    'rit',
    'z',
    'ĠMir',
    'anda',
    'ĠK',
    'ant',
    '++',
    ')',
    'ore',
    'n',
    'Ġprov',
    'oked',
    'Ġbre',
    'eds',
    'ĠIn',
    'cludes',
    'ĠPast',
    'ebin',
    'ĠFl',
    'ip',
    'J',
    'ava',
    'Ġbr',
    'ink',
    'Ġrum',
    'ored',
    'Ġun',
    'seen',
    'Ġgar',
    'nered',
    'ĠDef',
    'in',
    'al',
    'ted',
    'Ġtatt',
    'oos',
    'Ġhes',
    'itation',
    'is',
    'itions',
    'ĠWe',
    'aver',
    'ĠReport',
    'ing',
    'Ġtherap',
    'ies',
    'Ġconsult',
    'ants',
    'Ġresid',
    'ual',
    'ĠMal',
    'i',
    'ĠRom',
    'a',
    'i',
    'ago',
    'ĠRes',
    'idents',
    'ub',
    'i',
    'Ġremed',
    'ies',
    'Ġadapt',
    'ive',
    'ĠAl',
    'ive',
    'ĠBar',
    'cl',
    'Ġwal',
    'lets',
    'c',
    'rypt',
    'etermin',
    'ation',
    'ĠPel',
    'osi',
    'Ġsl',
    'ipping',
    'oton',
    'in',
    'Ġall',
    'iances',
    'pat',
    'rick',
    'ir',
    'is',
    'Ġor',
    'th',
    'ĠPer',
    'kins',
    'ĠDe',
    'V',
    'ĠG',
    'ets',
    'Ġdry',
    'ing',
    'ge',
    'e',
    'fore',
    'st',
    'ĠFor',
    'get',
    'ore',
    'm',
    '33',
    '9',
    'Ġvague',
    'ly',
    'ĠD',
    'ion',
    'ĠP',
    'orn',
    'ĠH',
    'OW',
    'Ġp',
    'neum',
    'Ġrub',
    'ble',
    'ĠT',
    'aste',
    'enc',
    'ia',
    'ĠG',
    'el',
    'Ġd',
    'st',
    'Ġ24',
    '5',
    'ĠMoroc',
    'co',
    'inf',
    'lamm',
    'ĠTw',
    'ins',
    'Ġb',
    'ots',
    'd',
    'aughter',
    'ĠB',
    'alk',
    'Ġbre',
    'thren',
    'Ġlog',
    'os',
    'Ġgo',
    'bl',
    'f',
    'ps',
    'Ġsub',
    'division',
    'Ġp',
    'awn',
    'Ġsquee',
    'zed',
    'Ġmor',
    'ale',
    'ĠD',
    'W',
    "'",
    '"',
    'Ġkn',
    'ot',
    'ook',
    'y',
    'Ġdiv',
    'isive',
    'Ġboost',
    'ed',
    'ch',
    'y',
    'ãĥ',
    'Ĳ',
    'if',
    'act',
    'Ġnewcom',
    'ers',
    'ĠWrest',
    'ling',
    'Ġsc',
    'outs',
    'w',
    'olves',
    'R',
    'at',
    'Ġnin',
    'eteenth',
    'ĠOs',
    'borne',
    'St',
    'ats',
    'Ġem',
    'powered',
    'Ġpsych',
    'opath',
    'ĠO',
    'EM',
    'ugg',
    'age',
    'ĠP',
    'K',
    'ĠMoh',
    'ammad',
    'P',
    'ak',
    'Ġanarch',
    'ists',
    'ĠExt',
    'ract',
    'est',
    'hes',
    'ĠStock',
    'holm',
    'l',
    'oo',
    'ĠG',
    'raph',
    'Ġdeploy',
    'ing',
    'ĠStr',
    'anger',
    'ĠM',
    'old',
    'Ġstaff',
    'er',
    'Ġdiscount',
    'ed',
    'uck',
    'le',
    'ple',
    'ase',
    'ĠLand',
    'ing',
    'ÃŃ',
    'a',
    'Ġ19',
    '3',
    'Ġan',
    'te',
    'Ġrep',
    'etition',
    'Ġ+',
    '/-',
    'Ġpar',
    'ody',
    'Ġlive',
    'ly',
    'AA',
    'A',
    'ĠHor',
    'us',
    'Ġp',
    'its',
    'ind',
    'ers',
    'L',
    'OC',
    'ĠVen',
    'ice',
    '40',
    '6',
    'ĠDis',
    'cover',
    'â',
    'Ĩ',
    'ellect',
    'ual',
    'Ġp',
    'ens',
    'Ġey',
    'el',
    'ig',
    'uous',
    'Im',
    'pl',
    'Ġj',
    'oking',
    'Ġinv',
    'al',
    'ĠBel',
    'fast',
    'Ġcredit',
    'ors',
    'ĠSky',
    'walker',
    'ov',
    'sky',
    'Ġcease',
    'fire',
    'Ġse',
    'als',
    'is',
    'oft',
    ')',
    ').',
    'ĠFel',
    'ix',
    'IT',
    'S',
    'Ġt',
    'resp',
    'ĠBlock',
    'chain',
    'ew',
    'are',
    'ĠSch',
    'war',
    'en',
    'ne',
    'mount',
    'ed',
    'ĠBe',
    'acon',
    'les',
    'h',
    'Ġimmense',
    'ly',
    'Ġche',
    'ering',
    'Em',
    'ploy',
    'sc',
    'ene',
    'ish',
    'ly',
    'atche',
    'wan',
    'ĠNic',
    'olas',
    'Ġdr',
    'ained',
    'ĠEx',
    'it',
    'ĠAz',
    'erb',
    'j',
    'un',
    'Ġflo',
    'ated',
    'u',
    'ania',
    'De',
    'ep',
    'Ġsuper',
    'v',
    'Ġmyst',
    'ical',
    'ĠD',
    'ollar',
    'ĠApost',
    'le',
    'ĠR',
    'EL',
    'ĠProv',
    'ided',
    'ĠB',
    'ucks',
    'ãĥ',
    '´',
    'cut',
    'ting',
    'Ġenhance',
    'ments',
    'ĠPengu',
    'ins',
    'ĠIsa',
    'iah',
    'Ġj',
    'erk',
    'ĠW',
    'yn',
    'Ġst',
    'alled',
    'Ġcryptoc',
    'urrencies',
    'ĠR',
    'oland',
    'sing',
    'le',
    'Ġl',
    'umin',
    'ĠF',
    'ellow',
    'ĠCap',
    'acity',
    'ĠKaz',
    'akh',
    'W',
    'N',
    'Ġfin',
    'anced',
    '38',
    '9',
    'Ġt',
    'id',
    'Ġcoll',
    'usion',
    'ĠMy',
    'r',
    'î',
    'Ģ',
    'Sen',
    'ator',
    'Ġped',
    'iatric',
    'Ġneat',
    'ly',
    'Ġsandwic',
    'hes',
    'ĠArchitect',
    'ure',
    'Ġt',
    'ucked',
    'Ġbalcon',
    'y',
    'Ġearthqu',
    'akes',
    'qu',
    'ire',
    'F',
    'uture',
    'Ġhe',
    'fty',
    'é',
    'Ĺ',
    'Ġspecial',
    'izes',
    'Ġstress',
    'es',
    'Ġs',
    'ender',
    'Ġmisunder',
    'standing',
    'Ġep',
    'ile',
    'Ġprov',
    'oke',
    'ĠCol',
    'ors',
    'Ġdis',
    'may',
    'uk',
    'o',
    '[',
    '_',
    '58',
    '6',
    'ne',
    'utral',
    'Ġdon',
    'ating',
    'ĠRand',
    'all',
    'Mult',
    'i',
    'Ġconvenient',
    'ly',
    'ĠS',
    'ung',
    'ĠC',
    'oca',
    'Ġt',
    'ents',
    'ĠAc',
    'celer',
    'Ġpart',
    'nered',
    '27',
    '2',
    'ir',
    'ming',
    'ĠB',
    'AS',
    's',
    'ometimes',
    'Ġobject',
    'ed',
    'ub',
    'ric',
    'p',
    'osed',
    'LC',
    'S',
    'gr',
    'ass',
    'Ġattribut',
    'able',
    'V',
    'IS',
    'Israel',
    'i',
    'Ġrepe',
    'ats',
    'ĠR',
    'M',
    'v',
    'ag',
    'ut',
    'a',
    'in',
    'ous',
    'Ġin',
    'ert',
    'ĠMig',
    'uel',
    'æ',
    'Ń',
    'ĠHawai',
    'ian',
    'B',
    'oard',
    'Ġart',
    'ific',
    'ĠAzerb',
    'ai',
    'as',
    'io',
    'ĠR',
    'ent',
    'A',
    'IN',
    'Ġappl',
    'iances',
    'Ġnational',
    'ity',
    'Ġass',
    'hole',
    'ĠN',
    'eb',
    'Ġnot',
    'ch',
    'h',
    'ani',
    'ĠBr',
    'ide',
    'Av',
    'ailability',
    'Ġintercept',
    'ed',
    'Ġcontin',
    'ental',
    'Ġsw',
    'elling',
    'ĠPers',
    'pect',
    'b',
    'ies',
    '.',
    '<',
    'ith',
    'metic',
    'ĠL',
    'ara',
    'Ġtempt',
    'ing',
    'add',
    'r',
    'Ġoversee',
    'ing',
    'cl',
    'ad',
    'ĠD',
    'V',
    'ĠGing',
    'rich',
    'Ġm',
    'un',
    'ĠApp',
    'ropri',
    'Ġalter',
    'ations',
    'ĠPat',
    'reon',
    'Ġha',
    'voc',
    'Ġdiscipl',
    'ines',
    'Ġnotor',
    'iously',
    'aku',
    'ya',
    'ier',
    'i',
    '?',
    ').',
    'ĠW',
    'ent',
    'Ġsil',
    'icon',
    'Ġtre',
    'mb',
    'Cont',
    'ainer',
    'K',
    'nown',
    'Ġmort',
    'ar',
    'est',
    'e',
    'ick',
    'a',
    'Ar',
    'thur',
    'ĠPre',
    'viously',
    'ĠMart',
    'y',
    'Ġsp',
    'arse',
    'g',
    'ins',
    'Ġin',
    'ward',
    'ĠParticip',
    'ant',
    'C',
    'opy',
    'ĠM',
    'isc',
    'Ġantib',
    'iotic',
    'ĠRet',
    'ro',
    'Ġel',
    'usive',
    'Ġass',
    'ail',
    'ĠBatt',
    'alion',
    'ĠB',
    'ought',
    'Ġdimin',
    'ish',
    'ĠEuro',
    'pa',
    's',
    'ession',
    'ĠDanger',
    'ous',
    'ies',
    'el',
    'Ġdisbel',
    'ief',
    'Ġbl',
    'asts',
    'ext',
    'reme',
    'ĠBoy',
    'd',
    'ĠProject',
    's',
    'ĠGu',
    'ys',
    'Ġunder',
    'gone',
    'Ġgr',
    'ill',
    'ĠDw',
    'ight',
    'Ġ19',
    '7',
    'US',
    'ER',
    'Ġfiles',
    'ystem',
    'Ġcl',
    'ocks',
    'T',
    'aylor',
    'Ġwra',
    'pper',
    'Ġfold',
    'ing',
    'ous',
    'and',
    'ĠPhilipp',
    'ine',
    'ATION',
    'AL',
    'ĠPer',
    'th',
    'Ġas',
    'hes',
    'Ġaccum',
    'ulate',
    'ĠGate',
    'way',
    'Sh',
    'op',
    'orks',
    'hire',
    'H',
    'an',
    'ĠBar',
    'rel',
    'ĠLe',
    'h',
    'ĠX',
    'V',
    'Ġwh',
    'im',
    'Ġrep',
    'o',
    'ĠC',
    'G',
    'ĠM',
    'am',
    'Ġincorpor',
    'ating',
    'Ġbail',
    'out',
    'Ġlingu',
    'istic',
    'Ġdis',
    'integ',
    'C',
    'LE',
    'Ġcinem',
    'atic',
    'ĠF',
    'iber',
    'S',
    'yn',
    'il',
    'ion',
    'ĠCom',
    'pos',
    'c',
    'hens',
    'Ġne',
    'oc',
    'Ġbo',
    'iled',
    'F',
    'INE',
    'on',
    'o',
    'un',
    'cle',
    'ik',
    'en',
    'ĠB',
    'M',
    'Î',
    '¹',
    'Ġreceipt',
    's',
    'Ġdisp',
    'osed',
    'ĠTh',
    'irty',
    'ĠR',
    'ough',
    'ĠA',
    'BS',
    'Ġnot',
    'withstanding',
    'oll',
    'en',
    '#',
    '$',
    'Ġunrel',
    'iable',
    'Ġbl',
    'oom',
    'Ġmedi',
    'ocre',
    'Ġtr',
    'am',
    'ĠTas',
    'man',
    'Ġsh',
    'akes',
    'Ġmanifest',
    'o',
    'ĠM',
    'W',
    'Ġsatisf',
    'actory',
    'Ġsh',
    'ores',
    'Ġcomput',
    'ation',
    'Ġassert',
    'ions',
    'orm',
    'ons',
    'ar',
    'ag',
    'ab',
    'it',
    'Dem',
    'ocrats',
    'ĠL',
    'oot',
    'ĠVol',
    'ks',
    'ha',
    'ired',
    'Ġgrav',
    'itational',
    'S',
    'ing',
    'ĠM',
    'iz',
    'Ġthro',
    'ttle',
    'Ġtyr',
    'anny',
    'ĠView',
    's',
    'Ġrob',
    'ber',
    'ĠMinor',
    'ity',
    'Ġsh',
    'rine',
    'sc',
    'ope',
    'pur',
    'pose',
    'Ġnucle',
    'us',
    'our',
    'cing',
    'ĠUS',
    'DA',
    'ĠD',
    'HS',
    'w',
    'ra',
    'ĠBow',
    'ie',
    'Sc',
    'ale',
    'ĠB',
    'EL',
    'x',
    'i',
    'I',
    'ter',
    'Ġ(',
    '),',
    'w',
    'right',
    'Ġsail',
    'ors',
    'ous',
    'ed',
    'NAS',
    'A',
    'ĠPro',
    'of',
    'ĠMin',
    'eral',
    't',
    'oken',
    'ĠF',
    'D',
    'R',
    'ew',
    'Ġe',
    'll',
    '6',
    '30',
    'Ġchance',
    'llor',
    'ĠG',
    'os',
    'Ġamount',
    'ed',
    'ĠRec',
    're',
    'ome',
    'z',
    'ĠOpt',
    'im',
    'ĠOl',
    'ive',
    'Ġtrack',
    'er',
    'ow',
    'ler',
    'ĠUn',
    'ique',
    'R',
    'oot',
    'Ġmar',
    'itime',
    'ĠQur',
    'an',
    'ĠAd',
    'apt',
    'Ġecosystem',
    's',
    'ĠRe',
    'peat',
    'ĠS',
    'oy',
    'ĠI',
    'MP',
    'Ġgrad',
    'uating',
    'and',
    'em',
    'P',
    'ur',
    'ĠRes',
    'et',
    'ĠTr',
    'ick',
    'ĠPh',
    'illy',
    'ĠT',
    'ue',
    'ĠMalays',
    'ian',
    'Ġclim',
    'ax',
    'Ġb',
    'ury',
    'Ġcons',
    'pic',
    'ĠSouth',
    'ampton',
    'ĠFl',
    'owers',
    'Ġesc',
    'orted',
    'ĠEduc',
    'ational',
    'ĠI',
    'RC',
    'Ġbrut',
    'ally',
    'e',
    'ating',
    'Ġpill',
    'ar',
    'ĠS',
    'ang',
    'ĠJ',
    'ude',
    'ar',
    'ling',
    'ĠAm',
    'nesty',
    'Ġrem',
    'inding',
    'ĠAdminist',
    'rative',
    'hes',
    'da',
    'Ġfl',
    'ashed',
    'ĠP',
    'BS',
    'per',
    'ate',
    'fe',
    'ature',
    'Ġsw',
    'ipe',
    'Ġgra',
    'ves',
    'oult',
    'ry',
    '26',
    '1',
    'bre',
    'aks',
    'ĠGu',
    'er',
    'Ġsh',
    'rimp',
    'ĠV',
    'oting',
    'qu',
    'ist',
    'Ġanaly',
    'tical',
    'Ġtables',
    'poons',
    'ĠS',
    'OU',
    'Ġresear',
    'ched',
    'Ġdisrupt',
    'ed',
    'Ġj',
    'our',
    'Ġrepl',
    'ica',
    'Ġcart',
    'oons',
    'b',
    'ians',
    '}',
    ')',
    'c',
    'opy',
    'G',
    'ot',
    'ou',
    'ched',
    'P',
    'UT',
    'Ġsw',
    'arm',
    'not',
    'ations',
    's',
    'aid',
    'Ġreb',
    'uilt',
    'Ġcollabor',
    'ate',
    'Ġr',
    'aging',
    'Ġn',
    'ar',
    'Ġdem',
    'ographics',
    'ĠD',
    'DR',
    'Ġdist',
    'rust',
    'oss',
    'ier',
    'ĠK',
    'ro',
    'Ġpump',
    'kin',
    'Ġreg',
    'rets',
    'Ġfatal',
    'ities',
    'ĠL',
    'ens',
    'ĠO',
    'le',
    'p',
    'd',
    'Ġpupp',
    'et',
    'ĠOut',
    'look',
    'ĠSt',
    'am',
    'O',
    'l',
    'F',
    'air',
    'U',
    'U',
    'Ġre',
    'written',
    'Ä',
    '±',
    'Ġfasc',
    'inated',
    'Ġve',
    'ctors',
    'Ġtrib',
    'unal',
    'u',
    'ay',
    'ĠM',
    'ats',
    'ĠCo',
    'ins',
    '[',
    '[',
    'Ġ18',
    '1',
    'Ġrend',
    'ers',
    'ĠK',
    'aepernick',
    'Ġesp',
    'ionage',
    'Ġsum',
    'm',
    'Ġd',
    'itch',
    'Acc',
    'ount',
    'Ġspread',
    'sheet',
    'Ġmut',
    'ant',
    'p',
    'ast',
    '40',
    '7',
    'Ġd',
    'ye',
    'Ġinit',
    'iation',
    'Ġ4',
    '000',
    'Ġpunish',
    'able',
    'Ġth',
    'inner',
    'ĠKh',
    'al',
    'Ġinter',
    'medi',
    'D',
    'un',
    'ĠGoth',
    'am',
    'Ġeager',
    'ly',
    'Ġvag',
    'inal',
    'p',
    'owers',
    'V',
    'W',
    'ĠWATCH',
    'ED',
    'Ġpred',
    'ator',
    'ams',
    'ung',
    'Ġdispar',
    'ity',
    'Ġ[',
    '*',
    'Ġam',
    'ph',
    'Ġout',
    'skirts',
    'ĠSpir',
    'its',
    'Ġskelet',
    'al',
    'Ð',
    '»',
    'ĠR',
    'ear',
    'Ġissu',
    'ance',
    'ĠLog',
    'ic',
    're',
    'leased',
    'Z',
    'Z',
    'ĠB',
    'ound',
    'Ent',
    'ry',
    'Ġex',
    'its',
    'is',
    'ol',
    'ĠFound',
    'er',
    'Ġw',
    're',
    'ĠGreen',
    'land',
    'ĠM',
    'MO',
    't',
    'aker',
    'IN',
    'C',
    'ãģ',
    '¾',
    'Ġhour',
    'ly',
    'hen',
    'ko',
    'Ġfantas',
    'ies',
    'Ġdis',
    'ob',
    'Ġdemol',
    'ition',
    'ãĥ',
    'ĭ',
    'Ġen',
    'listed',
    'rat',
    'ulations',
    'Ġmis',
    'guided',
    'Ġens',
    'ured',
    'Ġdiscour',
    'aged',
    'm',
    'ort',
    'Ġfl',
    'ank',
    'Ġc',
    'ess',
    'Ġreact',
    's',
    'ĠS',
    'ere',
    's',
    'ensitive',
    'ĠSer',
    'pent',
    'ass',
    'ad',
    'Ġ24',
    '7',
    'Ġcalm',
    'ly',
    'b',
    'usters',
    'Ġble',
    'ed',
    'ĠSt',
    'ro',
    'Ġamuse',
    'ment',
    'ĠAntar',
    'ctica',
    'Ġs',
    'cept',
    'ĠG',
    'aw',
    'a',
    'q',
    'ason',
    'ic',
    'Ġsp',
    'rawling',
    'n',
    'ative',
    'atur',
    'ated',
    'ĠBattle',
    'field',
    'IV',
    'ERS',
    'E',
    'B',
    'ĠG',
    'ems',
    'ĠNorth',
    'western',
    'ĠFil',
    'ms',
    'ĠAut',
    'omatic',
    'Ġappre',
    'hend',
    'ãģ',
    '¨',
    'Ġgui',
    'Name',
    'Ġback',
    'end',
    'Ġevid',
    'enced',
    'ge',
    'ant',
    '01',
    '2',
    'ĠS',
    'iege',
    'Ġexternal',
    'To',
    'Ġunfocused',
    'Range',
    'ĠguiActiveUn',
    'focused',
    'Ġgui',
    'Icon',
    'ĠexternalTo',
    'EVA',
    'ĠexternalToEVA',
    'Only',
    'F',
    'ri',
    'ch',
    'ard',
    'en',
    'aries',
    'Ġchief',
    's',
    'Ġc',
    'f',
    'ĠH',
    'UD',
    'Ġcorro',
    'bor',
    'Ġd',
    'B',
    'ĠT',
    'aken',
    'ĠPat',
    'ricia',
    'ra',
    'il',
    'ĠCh',
    'arm',
    'ĠLiber',
    'tarian',
    'rie',
    've',
    'Person',
    'al',
    'ĠO',
    'UR',
    'ger',
    'ies',
    'Ġdump',
    'ing',
    'Ġneurolog',
    'ical',
    'it',
    'imate',
    'ĠClint',
    'ons',
    'raft',
    'ed',
    'ĠM',
    'olly',
    'Ġtermin',
    'als',
    'reg',
    'ister',
    'Ġfl',
    'are',
    'Ġenc',
    'oded',
    'Ġautop',
    'sy',
    'p',
    'el',
    'm',
    'achine',
    'Ġexempt',
    'ions',
    'ĠRoy',
    'als',
    'd',
    'istance',
    'Ġdraft',
    's',
    'Ġl',
    'ame',
    'ĠC',
    'unning',
    'Ġsp',
    'ouses',
    'ĠMark',
    'ets',
    'ĠCar',
    'rier',
    'Ġimp',
    'lying',
    'ĠY',
    'ak',
    's',
    'id',
    'Ġl',
    'oser',
    'Ġvigil',
    'ant',
    'Ġimpe',
    'achment',
    'Ġaug',
    'mented',
    'ĠEmploy',
    'ees',
    'Ġunint',
    'ended',
    'tern',
    'ally',
    'ĠW',
    'att',
    'Ġrecogn',
    'izable',
    'ess',
    'im',
    'æ',
    'Ŀ',
    'Ġco',
    'ated',
    'r',
    'ha',
    'Ġlie',
    'utenant',
    'ĠLegisl',
    'ation',
    'pub',
    'lished',
    '44',
    '4',
    '01',
    '3',
    'Ġide',
    'ally',
    'ĠPass',
    'word',
    'Ġsimpl',
    'ify',
    'ĠMet',
    'a',
    'ĠM',
    'RI',
    'Ġple',
    'ading',
    'organ',
    'ized',
    'hand',
    'ler',
    'Ġun',
    'ravel',
    'cor',
    'rect',
    'Ġ',
    'icy',
    'Ġparan',
    'oid',
    'Ġpass',
    'er',
    'Ġinspect',
    'ions',
    'of',
    'er',
    'ĠHealth',
    'care',
    '28',
    '3',
    'ĠBr',
    'ut',
    'iol',
    'a',
    'for',
    'ge',
    'ĠMed',
    'ieval',
    'MS',
    'N',
    'ie',
    'vers',
    'ĠProgram',
    'ming',
    'å',
    'ī',
    'Ġ2',
    '23',
    'm',
    'u',
    'ĠC',
    'LE',
    'ug',
    'a',
    'Ġsho',
    'ppers',
    'Ġinform',
    'ative',
    'ĠPl',
    'ans',
    'Ġsupplement',
    'ation',
    'ĠT',
    'ests',
    'ty',
    'ard',
    'ocy',
    'tes',
    'ĠVeg',
    'a',
    'ĠGujar',
    'at',
    'erman',
    'ent',
    'Ex',
    'cept',
    'ĠL',
    'OT',
    'all',
    'a',
    'ĠC',
    'umm',
    'ĠO',
    'sw',
    'Ġven',
    'om',
    'ĠDeb',
    't',
    'ĠD',
    'OWN',
    'Ġreun',
    'ion',
    'Ġm',
    'uc',
    'ĠRel',
    'ief',
    'Ġge',
    'op',
    'ĠðŁ',
    'ĺ',
    'al',
    'ogue',
    'An',
    'th',
    'ech',
    'o',
    'Ġcor',
    'ros',
    'Ġrepl',
    'ication',
    'ĠBl',
    'azing',
    'ĠD',
    'aughter',
    'Ġinf',
    'lic',
    'ĠLind',
    'sey',
    'Ù',
    'Ī',
    '28',
    '4',
    'Ex',
    'it',
    'Ġgl',
    'oom',
    'TA',
    'IN',
    'Ġundermin',
    'ing',
    'Ġadv',
    'ising',
    'h',
    'idden',
    'Ġover',
    'flow',
    'Ġg',
    'or',
    'urd',
    'ue',
    'Ġe',
    'choes',
    'enh',
    'agen',
    'Ġimp',
    'uls',
    'd',
    'rug',
    'c',
    'ash',
    'Ġas',
    'ync',
    'Ġmir',
    'ac',
    'at',
    'ts',
    'p',
    'unk',
    'Ġpiv',
    'ot',
    'ĠLegisl',
    'ative',
    'Ġblog',
    'gers',
    'ĠCl',
    'aw',
    's',
    'burg',
    'd',
    'yl',
    'ĠRecomm',
    'end',
    'Ġver',
    'te',
    'Ġprohib',
    'iting',
    'ĠPant',
    'her',
    'Jon',
    'athan',
    'Ġo',
    'min',
    'Ġhate',
    'ful',
    '28',
    '1',
    'ĠOr',
    'che',
    'ĠMurd',
    'och',
    'down',
    's',
    'Ġas',
    'ymm',
    'G',
    'ER',
    'Al',
    'ways',
    'Ġinform',
    's',
    'ĠW',
    'M',
    'ĠP',
    'ony',
    'ĠApp',
    'endix',
    'ĠAr',
    'lington',
    'J',
    'am',
    'Ġmedic',
    'inal',
    'ĠS',
    'lam',
    'IT',
    'IES',
    'Ġre',
    'aff',
    'ĠR',
    'i',
    'F',
    'G',
    'S',
    'pring',
    'b',
    'ool',
    'Ġthigh',
    's',
    'Ġmark',
    'ings',
    'ĠRa',
    'qqa',
    'ĠL',
    'ak',
    'p',
    'oll',
    'ts',
    'ky',
    'ĠMort',
    'y',
    'ĠDef',
    'inition',
    'Ġdeb',
    'unk',
    'end',
    'ered',
    'ĠLe',
    'one',
    'a',
    'vers',
    'Ġmortg',
    'ages',
    'App',
    'arently',
    'N',
    'ic',
    'ha',
    'us',
    'ĠTh',
    'ousands',
    'au',
    'ld',
    'Ġm',
    'ash',
    'sh',
    'oot',
    'Ġdi',
    'arr',
    'Ġconscious',
    'ly',
    'H',
    'ero',
    'e',
    'as',
    'ĠN',
    'aturally',
    'ĠDestroy',
    'er',
    'Ġdash',
    'board',
    'serv',
    'ices',
    'R',
    'og',
    'Ġmillenn',
    'ials',
    'Ġinv',
    'ade',
    '-',
    '(',
    'Ġcomm',
    'issions',
    'ĠA',
    'uckland',
    'Ġbroadcast',
    's',
    'Ġfront',
    'al',
    'Ġcr',
    'ank',
    'ĠHist',
    'oric',
    'Ġrum',
    'ours',
    'CT',
    'V',
    'Ġster',
    'il',
    'Ġboost',
    'er',
    'rock',
    'et',
    'ãĤ',
    '¼',
    'ut',
    'sche',
    'ĠP',
    'I',
    'Ġ2',
    '33',
    'ĠProdu',
    'cer',
    'ĠAnaly',
    'tics',
    'Ġinval',
    'uable',
    'Ġunint',
    'ention',
    'ĠC',
    'Y',
    'Ġscrut',
    'in',
    'Ġg',
    'igg',
    'Ġeng',
    'ulf',
    'Ġprolet',
    'ariat',
    'Ġh',
    'acks',
    'ĠH',
    'ew',
    'ar',
    'ak',
    'ĠSl',
    'ime',
    'ield',
    'ing',
    'ag',
    'her',
    'ĠEll',
    'iot',
    'Ġtele',
    'com',
    'Ġ2',
    '19',
    'ult',
    'an',
    'ĠAr',
    'bor',
    'ĠSc',
    'outs',
    'B',
    'an',
    'Ġlifes',
    'pan',
    'Ġbl',
    'asp',
    '38',
    '8',
    'Ġjud',
    'iciary',
    'ĠContin',
    'ental',
    'ask',
    'ing',
    'Mc',
    'C',
    'L',
    'ED',
    'Ġbag',
    'gage',
    'ĠSorce',
    'rer',
    'Ġrem',
    'nants',
    'ĠGriff',
    'ith',
    'ets',
    'u',
    'ĠSub',
    'aru',
    'ĠPerson',
    'ality',
    'des',
    'igned',
    'ush',
    'ima',
    'agn',
    'ar',
    'Ġrec',
    'oil',
    'Ġpass',
    'ions',
    '\\',
    '":',
    'Ġte',
    'e',
    'Ġabol',
    'ition',
    'ĠCreat',
    'ing',
    'j',
    'ac',
    'Ġ19',
    '4',
    '01',
    '9',
    'Ġpill',
    'ars',
    'ric',
    'hed',
    '/',
    '"',
    't',
    'k',
    'Ġlive',
    'lihood',
    'Ġro',
    'asted',
    'ah',
    'on',
    'ĠH',
    'utch',
    'ass',
    'ert',
    'Ġdivid',
    'end',
    'Ġkn',
    'it',
    'Ġd',
    'aunting',
    'Ġdisturb',
    'ance',
    'Ġsh',
    'ale',
    'Ġcultiv',
    'ated',
    'Ġrefriger',
    'ator',
    'L',
    'B',
    'ĠN',
    'ET',
    'Ġcommercial',
    's',
    'Ġthink',
    'ers',
    '45',
    '5',
    'Ġch',
    'op',
    'B',
    'road',
    'Ġsuspic',
    'ions',
    'Ġtag',
    'ged',
    'l',
    'ifting',
    'Ġsty',
    'lish',
    'ĠShield',
    's',
    'Short',
    'ly',
    'Ġt',
    'ails',
    'A',
    'uth',
    'ST',
    'E',
    'ĠG',
    'AME',
    'Ġse',
    'ism',
    'ĠK',
    'is',
    'olog',
    'ne',
    'Ġcow',
    'ork',
    'Ġforc',
    'ibly',
    'Ġthy',
    'roid',
    'ĠP',
    'B',
    'AN',
    'E',
    'mar',
    'ried',
    'h',
    'orse',
    'Ġpoly',
    'mer',
    'ĠCh',
    'al',
    'od',
    'or',
    'DE',
    'BUG',
    'ĠCon',
    'text',
    'Ġbl',
    'iss',
    'Ġpin',
    'point',
    'ĠMat',
    'hemat',
    'leg',
    'ram',
    'ĠWeek',
    'end',
    'Ġlab',
    'elled',
    'Ġb',
    'art',
    'it',
    'les',
    'Ġest',
    'rogen',
    'âĢĶâĢĶâĢĶâĢĶâĢĶâĢĶâĢĶâĢĶ',
    'âĢĶâĢĶâĢĶâĢĶâĢĶâĢĶâĢĶâĢĶ',
    '"',
    "'",
    'Ġvis',
    'ibly',
    'Ġouts',
    'ider',
    'aid',
    'a',
    'Are',
    'a',
    'Ġdisse',
    'min',
    'Ġdish',
    'onest',
    'ĠCl',
    'osed',
    'ĠBullet',
    'in',
    'ĠRam',
    'sey',
    'sw',
    'ord',
    'ĠX',
    'I',
    'our',
    'ced',
    'S',
    'ame',
    '34',
    '6',
    'ĠRe',
    'pe',
    'ĠK',
    'ou',
    'c',
    'ake',
    'em',
    'is',
    'C',
    'ache',
    'ĠMe',
    'aning',
    'ĠEn',
    'light',
    'onom',
    'y',
    'Ġmanifest',
    'ation',
    'sw',
    'orth',
    'J',
    'ay',
    'Ġch',
    'ore',
    'Ã¶',
    'r',
    'D',
    'ream',
    'Ġsanction',
    'ed',
    'Ġcult',
    'urally',
    'ĠA',
    'ra',
    'N',
    'av',
    'Ġthe',
    'ological',
    'Ġstr',
    'ut',
    'ĠV',
    'O',
    'ĠHand',
    'book',
    'Ġconstruct',
    'ing',
    'ĠÂ',
    '¶',
    'ĠBenef',
    'its',
    'ĠPsych',
    'ological',
    's',
    'ac',
    'å',
    '¸',
    'p',
    'olicy',
    'ĠMat',
    'ters',
    'ĠReport',
    'ed',
    'ĠBy',
    'te',
    'Ġvit',
    'ro',
    'ĠM',
    'aiden',
    'Ġl',
    'am',
    'ĠJenn',
    'ings',
    'Ġgar',
    'ment',
    'ĠRut',
    'gers',
    'ĠStaff',
    'ord',
    'ĠWell',
    'ington',
    'Ġinter',
    'mitt',
    'Ġn',
    'pm',
    'Ġord',
    'eal',
    'Ġplug',
    'ged',
    'o',
    'oming',
    'in',
    'ished',
    'fram',
    'ework',
    'Ġtim',
    'ber',
    'Ġc',
    'ass',
    'Ġ8',
    '50',
    'il',
    'ess',
    'ĠRed',
    'ux',
    '7',
    '68',
    'St',
    're',
    'Ġsurpass',
    'ed',
    'w',
    'hel',
    'Ġparalle',
    'ls',
    'Ġve',
    'il',
    'ĠG',
    'I',
    'ĠR',
    'EST',
    'Ġread',
    'iness',
    's',
    'ort',
    'Ġmod',
    'ifying',
    'ĠSl',
    'ate',
    'ru',
    'ff',
    'Ġmar',
    'ble',
    'Ġinf',
    'rared',
    'Ġaud',
    'itor',
    'ĠFANT',
    'ASY',
    'ĠP',
    'overty',
    'ĠS',
    'PD',
    'Ġ"',
    '(',
    'K',
    'y',
    'RA',
    'Y',
    'Ġexecut',
    'ions',
    'ĠBever',
    'ly',
    'ĠMarx',
    'ism',
    'ĠBur',
    'st',
    'ĠK',
    'ali',
    'est',
    'ones',
    'Clear',
    'ly',
    'E',
    'll',
    'ãģ',
    '§',
    'ĠProceed',
    'ings',
    'T',
    'oken',
    'IF',
    'IC',
    'Ã±',
    'a',
    'Cent',
    'ral',
    'ĠH',
    'aley',
    'ĠD',
    'rama',
    'Ġform',
    'ations',
    'OR',
    'N',
    'Book',
    's',
    'Ġdom',
    'inating',
    'ĠFly',
    'ers',
    'ĠCompan',
    'ion',
    'Ġdiscipl',
    'ined',
    'ĠYug',
    'oslav',
    'ĠSpell',
    's',
    'Ġv',
    'engeance',
    'Ġland',
    'lords',
    'L',
    'en',
    'ĠO',
    'gre',
    'ano',
    'ia',
    'Ġpier',
    'cing',
    'Ġcon',
    'greg',
    'Ġscore',
    'r',
    'ob',
    'ia',
    'Ġnic',
    'kel',
    'ĠLear',
    'ns',
    'Ġre',
    'jo',
    'Ġmaster',
    'piece',
    'Fl',
    'ash',
    'Ġinhab',
    'ited',
    'ĠOpen',
    'GL',
    'ĠD',
    'ud',
    'ĠI',
    'CO',
    'Ġar',
    'ter',
    'Ġpl',
    'ur',
    'Ġmaster',
    'y',
    'Ġlong',
    'standing',
    'st',
    'ed',
    'Ġw',
    'ines',
    'Ġtelev',
    'ised',
    'ĠSh',
    'rine',
    'ĠBay',
    'ern',
    'Ġâ',
    'ĵĺ',
    'Ġencl',
    'osure',
    'j',
    'ohn',
    'Ġprophe',
    'ts',
    'ĠRes',
    'urrection',
    'ĠOrd',
    'ers',
    'Ġun',
    'even',
    'r',
    'als',
    'Ġd',
    'wind',
    'ĠL',
    'ah',
    'ĠSl',
    'oven',
    '37',
    '8',
    'Ġins',
    'istence',
    'aff',
    'le',
    'ĠCl',
    'one',
    'Ġhard',
    'ship',
    'ĠCongress',
    'man',
    'Ġple',
    'ad',
    'Ġreview',
    'ers',
    'Ġc',
    'ured',
    'Ġ19',
    '35',
    'as',
    'ley',
    'f',
    'ake',
    'ĠTh',
    'inking',
    'yd',
    'ia',
    'P',
    'ART',
    'ĠD',
    'ota',
    'o',
    'it',
    'Ġwh',
    'ipped',
    'Ġb',
    'ouncing',
    'ĠHispan',
    'ics',
    'com',
    'ings',
    'Ġcann',
    'abin',
    'ĠCh',
    'ambers',
    'ĠZ',
    'ack',
    'Option',
    'al',
    'Ġco',
    'ats',
    'Ġprow',
    'ess',
    'ĠNort',
    'on',
    'Ġplain',
    'ly',
    'Ġfre',
    'ight',
    'Ġinhib',
    'ition',
    'Ġcl',
    'am',
    'Ġ30',
    '3',
    'ke',
    'f',
    'ale',
    'igh',
    'L',
    'uke',
    'Ġpsych',
    'o',
    'ator',
    'ium',
    'M',
    'ED',
    'Ġtreat',
    'ies',
    'Ġind',
    'isc',
    'Ġd',
    'c',
    'OP',
    'S',
    'Ġresil',
    'ient',
    'ĠInter',
    'state',
    'Ġsl',
    'ack',
    'Ġmund',
    'ane',
    'Ġestab',
    'lishes',
    '35',
    '9',
    'Ġstr',
    'ained',
    'Ġn',
    'ond',
    'S',
    'us',
    'Ġcast',
    'e',
    'ar',
    'ate',
    'ie',
    'ving',
    'Ġunfair',
    'ly',
    'Ġpars',
    'er',
    'on',
    'ial',
    'urs',
    'ive',
    'V',
    'ia',
    'ĠOtt',
    'o',
    'ĠAuthor',
    'ities',
    'stro',
    'ke',
    'K',
    'R',
    'ĠMer',
    'cy',
    'Ġfurn',
    'ished',
    'Ġout',
    'set',
    'Ġmet',
    'ic',
    '19',
    '82',
    'olith',
    'ic',
    'ĠT',
    'ent',
    'og',
    'ical',
    'ĠA',
    'ircraft',
    'Ġh',
    'ides',
    'ĠBec',
    'ame',
    'Ġeduc',
    'ators',
    're',
    'aching',
    'Ġvol',
    'atility',
    'Ġtodd',
    'ler',
    'ĠNAS',
    'CAR',
    'ĠTw',
    'elve',
    'ĠHigh',
    'lights',
    'Ġgra',
    'pe',
    'Ġspl',
    'its',
    'Ġpe',
    'asant',
    'Ġre',
    'neg',
    'ĠMS',
    'I',
    'Tem',
    'p',
    'st',
    'ars',
    'Ġtre',
    'k',
    'ĠHy',
    'de',
    'b',
    'inding',
    'Ġreal',
    'ism',
    'Ġox',
    'ide',
    'ĠH',
    'os',
    'Ġmount',
    's',
    'Ġbit',
    'ing',
    'Ġcollaps',
    'ing',
    'Ġpost',
    'al',
    'Ġmuse',
    'ums',
    'Ġdet',
    'ached',
    'Ġrespect',
    'ing',
    'Ġmonop',
    'ol',
    'Ġwork',
    'flow',
    'ĠC',
    'ake',
    'Tem',
    'plate',
    'ĠOrgan',
    'isation',
    'Ġpers',
    'istence',
    '36',
    '9',
    'C',
    'oming',
    'B',
    'rad',
    'Ġredund',
    'ant',
    'ĠG',
    'TA',
    'Ġb',
    'ending',
    'Ġrev',
    'oked',
    'Ġoff',
    'ending',
    'Ġfram',
    'ing',
    'Ġprint',
    'f',
    'Comm',
    'un',
    'mem',
    'bers',
    'Out',
    'side',
    'Ġconst',
    'rued',
    'Ġc',
    'oded',
    'F',
    'ORE',
    'Ġch',
    'ast',
    'Ch',
    'at',
    'Ind',
    'ian',
    'ĠY',
    'ard',
    '?',
    '!"',
    'ĠP',
    'orts',
    'ĠX',
    'avier',
    'ĠR',
    'ET',
    "'",
    '."',
    'ĠBo',
    'at',
    'iv',
    'ated',
    'ich',
    't',
    'umer',
    'able',
    'D',
    's',
    'ĠDun',
    'n',
    'Ġcoff',
    'in',
    'Ġsecure',
    'ly',
    'ĠRapt',
    'ors',
    'ĠB',
    'es',
    'Install',
    'ation',
    'Ġin',
    'ception',
    'ĠHealth',
    'y',
    'end',
    'ants',
    'Ġpsych',
    'ologists',
    'ĠShe',
    'ikh',
    'c',
    'ultural',
    'ĠBlack',
    'Berry',
    'sh',
    'ift',
    'F',
    'red',
    'oc',
    'he',
    'Ġc',
    'akes',
    'ĠS',
    'EO',
    'ĠG',
    'ian',
    'ĠAs',
    'ians',
    'og',
    'ging',
    'e',
    'lement',
    'Ġpund',
    'its',
    'ĠV',
    'augh',
    'ĠG',
    'avin',
    'Ġh',
    'itter',
    'Ġdrown',
    'ed',
    'Ġch',
    'alk',
    'ĠZ',
    'ika',
    'Ġmeas',
    'les',
    '80',
    '2',
    'âĢ¦',
    '..',
    'ĠAW',
    'S',
    ']',
    '"',
    'Ġdist',
    'ort',
    'ĠM',
    'ast',
    'Ġantib',
    'odies',
    'ĠM',
    'ash',
    'Mem',
    'ory',
    'ĠUg',
    'anda',
    'ĠPro',
    'b',
    'Ġvom',
    'iting',
    'ĠTurn',
    's',
    'Ġoccup',
    'ying',
    'Ġev',
    'asion',
    'ĠTher',
    'apy',
    'Ġprom',
    'o',
    'Ġelect',
    'r',
    'Ġblue',
    'print',
    'ĠD',
    're',
    'pr',
    'iced',
    'ĠDep',
    'ot',
    'Ġallev',
    'iate',
    'ĠSom',
    'ali',
    'm',
    'arg',
    'n',
    'ine',
    'Ġnostalg',
    'ia',
    'ĠShe',
    'pherd',
    'Ġcaval',
    'ry',
    'Ġtor',
    'ped',
    'ĠBlood',
    'y',
    'x',
    'b',
    'Ġs',
    'ank',
    'Ġgo',
    'alt',
    'report',
    'print',
    'embed',
    'reportprint',
    'clone',
    'embedreportprint',
    'ĠIn',
    'itially',
    'ĠF',
    'ischer',
    'Ġnot',
    'eworthy',
    'c',
    'ern',
    'Ġin',
    'efficient',
    'raw',
    'download',
    'rawdownload',
    'cloneembedreportprint',
    'c',
    'ation',
    'ĠD',
    'ynasty',
    'l',
    'ag',
    'D',
    'ES',
    'Ġdistinct',
    'ly',
    'ĠEston',
    'ia',
    'Ġopen',
    'ness',
    'Ġg',
    'ossip',
    'ru',
    'ck',
    'W',
    'idth',
    'ĠIb',
    'rahim',
    'Ġpet',
    'roleum',
    'Ġav',
    'atar',
    'ĠH',
    'ed',
    'ath',
    'a',
    'ĠHog',
    'warts',
    'Ġc',
    'aves',
    '67',
    '8',
    'Ġsafegu',
    'ard',
    'ĠM',
    'og',
    'iss',
    'on',
    'ĠDur',
    'ham',
    'sl',
    'aught',
    'ĠGrad',
    'uate',
    'Ġsub',
    'conscious',
    'ĠEx',
    'cellent',
    'ĠD',
    'um',
    '----',
    '-',
    'Ġp',
    'iles',
    'ĠW',
    'ORK',
    'ĠG',
    'arn',
    'ĠF',
    'ol',
    'ĠAT',
    'M',
    'Ġavoid',
    's',
    'ĠT',
    'ul',
    'Ġble',
    'ak',
    'EL',
    'Y',
    'iv',
    'ist',
    'light',
    'ly',
    'P',
    'ers',
    'ĠD',
    'ob',
    'ĠL',
    'S',
    'Ġins',
    'anity',
    'Î',
    'µ',
    'atal',
    'ie',
    'En',
    'large',
    'Ġtw',
    'ists',
    'Ġfault',
    'y',
    'Ġpir',
    'acy',
    'Ġimp',
    'over',
    'Ġrug',
    'ged',
    'ĠF',
    'ashion',
    'Ġs',
    'ands',
    "'",
    '?',
    'sw',
    'ick',
    'Ġn',
    'atives',
    'Ġhe',
    'n',
    'ĠNo',
    'ise',
    'ãĥ',
    'Ĺ',
    'Ġg',
    'reens',
    'Ġfree',
    'zer',
    'Ġd',
    'ynasty',
    'ĠFather',
    's',
    'ĠNew',
    'ark',
    'Ġarchae',
    'ological',
    'Ġo',
    't',
    'ob',
    'ar',
    'Ġblock',
    'ade',
    'Ġall',
    'erg',
    'L',
    'V',
    'Ġdeb',
    'it',
    'ĠR',
    'FC',
    'ĠMil',
    'ton',
    'ĠPress',
    'ure',
    'Ġwill',
    'ingly',
    'Ġdisproportion',
    'ate',
    'Ġopp',
    'ressive',
    'Ġdiamond',
    's',
    'Ġbelong',
    'ings',
    '19',
    '70',
    'Ġbell',
    's',
    'Ġimperial',
    'ism',
    'Ġ2',
    '27',
    'Ġexpl',
    'oding',
    'ĠE',
    'clipse',
    'Ġ19',
    '19',
    'Ġr',
    'ant',
    'Ġnom',
    'inations',
    '34',
    '7',
    'Ġpeace',
    'fully',
    'ric',
    'a',
    'ĠF',
    'UCK',
    'Ġvib',
    'ration',
    'mal',
    'ink',
    'Ġro',
    'pes',
    'ĠIv',
    'anka',
    'ĠBrew',
    'ery',
    'ĠBook',
    'er',
    'ĠOw',
    'ens',
    'go',
    'ers',
    'Serv',
    'ices',
    'ĠSn',
    'ape',
    'Ġ19',
    '1',
    '39',
    '5',
    'Ġ2',
    '99',
    'just',
    'ice',
    'Ġb',
    'ri',
    'Ġdisc',
    's',
    'Ġprom',
    'inently',
    'Ġvul',
    'gar',
    'Ġsk',
    'ipping',
    'l',
    'ves',
    'Ġtsun',
    'ami',
    '37',
    '4',
    'ĠU',
    'rug',
    'ĠE',
    'id',
    'rec',
    'ated',
    'p',
    'hen',
    'Ġfault',
    's',
    'ĠStart',
    'ed',
    '9',
    '50',
    'Ġp',
    'i',
    'Ġdetect',
    'or',
    'Ġbast',
    'ard',
    'Ġvalid',
    'ated',
    'Space',
    'Engineers',
    'OUR',
    'CE',
    'Ġ(',
    '~',
    'Ġuns',
    'ur',
    'Ġaff',
    'irmed',
    'Ġfasc',
    'ism',
    'Ġres',
    'olving',
    'ĠCh',
    'avez',
    'ĠC',
    'yn',
    'Ġdet',
    'ract',
    'L',
    'ost',
    'Ġrig',
    'ged',
    'Ġhom',
    'age',
    'ĠBrun',
    'o',
    '55',
    '5',
    'ec',
    'a',
    'Ġpress',
    'es',
    'Ġhum',
    'our',
    'Ġsp',
    'acing',
    "Ġ'",
    '/',
    'olk',
    'ien',
    'C',
    'oun',
    'OP',
    'ER',
    'T',
    're',
    'S',
    'on',
    'ĠCambod',
    'ia',
    'ier',
    're',
    'm',
    'ong',
    'o',
    'zy',
    'Ġliquid',
    'ity',
    'ĠSov',
    'iets',
    'ĠFernand',
    'o',
    'Ġ2',
    '29',
    'Ġsl',
    'ug',
    'ĠCatal',
    'an',
    'elect',
    'ric',
    'Ġsc',
    'enery',
    'ĠH',
    'earth',
    'Ġconst',
    'rained',
    'Ġgoal',
    'ie',
    'ĠGu',
    'idelines',
    'ĠAm',
    'mo',
    'ĠPear',
    'son',
    'Ġtax',
    'ed',
    'Ġfet',
    'us',
    'Resp',
    'onse',
    'ĠAlex',
    'is',
    'th',
    'ia',
    'G',
    'uy',
    'Ġrecon',
    'struct',
    'Ġextrem',
    'es',
    'Ġconclud',
    'ing',
    'ĠP',
    'eg',
    'ook',
    's',
    'Ġded',
    'uctions',
    'R',
    'ose',
    'Ġground',
    'breaking',
    'ĠT',
    'arg',
    'ãĥ',
    'ģ',
    'ĠRe',
    've',
    'res',
    'ource',
    'Ġmo',
    'ons',
    'Ġelectrom',
    'agnetic',
    'Ġamid',
    'st',
    'ĠVik',
    'tor',
    'N',
    'ESS',
    'B',
    'ACK',
    'Ġcomm',
    'ute',
    'ĠAna',
    'heim',
    'Ġfluct',
    'uations',
    '6',
    '40',
    'Ġnood',
    'les',
    'ĠCop',
    'enhagen',
    'ĠT',
    'ide',
    'ĠGri',
    'zz',
    'ĠS',
    'EE',
    'Ġpip',
    'elines',
    'Ġsc',
    'ars',
    'end',
    'o',
    'ag',
    'us',
    'ĠE',
    'TF',
    '/',
    '#',
    'ĠBec',
    'ome',
    '44',
    '8',
    'Ġvis',
    'c',
    'ĠRecomm',
    'ended',
    'Ġj',
    'umper',
    'Ġcogn',
    'ition',
    'Ġassass',
    'in',
    'Ġwitness',
    'ing',
    'ĠSet',
    'up',
    'Ġl',
    'ac',
    'v',
    'im',
    'IS',
    'M',
    'p',
    'ages',
    'SS',
    'L',
    '35',
    '8',
    'Ġad',
    'ject',
    'indust',
    'rial',
    'l',
    'ore',
    'cher',
    'y',
    'Ġgl',
    'itter',
    'Ġc',
    'alf',
    'Flor',
    'ida',
    'Ġspoil',
    'ers',
    'Ġsucceed',
    's',
    'Ġch',
    'anting',
    'Ġslog',
    'ans',
    'ĠTr',
    'acy',
    'Vis',
    'it',
    'rol',
    'ogy',
    'Ġm',
    'ornings',
    'Ġline',
    'age',
    'Ġs',
    'ip',
    'Ġintense',
    'ly',
    'Ġflour',
    'ish',
    'ĠSle',
    'eping',
    'ĠF',
    'em',
    'or',
    'por',
    'ĠK',
    'lan',
    'ĠDar',
    'th',
    'h',
    'ack',
    'ĠNi',
    'elsen',
    'Ġtum',
    'ors',
    'Ġprocure',
    'ment',
    'ĠY',
    'orkshire',
    'Ġra',
    'ided',
    'K',
    'Y',
    'An',
    'na',
    'Ġ//',
    '[',
    'ĠDis',
    'order',
    'ĠMust',
    'ang',
    'ĠW',
    'en',
    'ĠTry',
    'ing',
    's',
    'q',
    'Ġdeliver',
    'ies',
    'Ġshut',
    'ter',
    'Ġcere',
    'bral',
    'Ġbip',
    'olar',
    'ĠC',
    'N',
    'l',
    'ass',
    'j',
    'et',
    'Ġdeb',
    'ating',
    '>',
    ':',
    'Ġe',
    'agle',
    'gr',
    'ades',
    'ĠD',
    'ixon',
    'UG',
    'C',
    'M',
    'AS',
    'ĠDr',
    'aco',
    'ĠMach',
    'ines',
    'aff',
    'er',
    'Ġem',
    'an',
    'Â',
    '²',
    'pr',
    'on',
    'ĠG',
    'ym',
    'Ġcompar',
    'atively',
    'ĠTrib',
    'unal',
    'PR',
    'O',
    'Ġle',
    'x',
    'Ġfert',
    'ile',
    'Ġdep',
    'ressing',
    'Ġsuperf',
    'icial',
    'ess',
    'ential',
    'ĠHun',
    'ters',
    'g',
    'p',
    'Ġprom',
    'inence',
    'L',
    'iber',
    'ĠAn',
    'cest',
    'ote',
    'chnology',
    'Ġm',
    'ocking',
    'ĠTra',
    'ff',
    'ĸ',
    'ļ',
    'Med',
    'ium',
    'I',
    'raq',
    'Ġpsychiat',
    'rist',
    'Quant',
    'ity',
    'ĠL',
    'ect',
    'Ġno',
    'isy',
    '5',
    '20',
    'G',
    'Y',
    'Ġsl',
    'apped',
    'ĠM',
    'TV',
    'Ġpar',
    'a',
    'p',
    'ull',
    'Mult',
    'iple',
    'as',
    'her',
    'Ġn',
    'our',
    'ĠSe',
    'g',
    'Spe',
    'll',
    'v',
    'ous',
    'ord',
    'ial',
    'Sen',
    'ior',
    'ĠGold',
    'berg',
    'ĠPl',
    'asma',
    'ne',
    'ed',
    'Ġmess',
    'enger',
    'ere',
    't',
    'Ġteam',
    'ed',
    'Ġliter',
    'acy',
    'ĠLe',
    'ah',
    'ĠD',
    'oyle',
    'Ġem',
    'itted',
    'U',
    'X',
    'Ġev',
    'ade',
    'Ġm',
    'aze',
    'Ġwrong',
    'ly',
    'ĠL',
    'ars',
    'Ġstere',
    'otype',
    'Ġpled',
    'ges',
    'Ġarom',
    'a',
    'ĠM',
    'ET',
    'Ġac',
    're',
    'ĠO',
    'D',
    'Ġf',
    'f',
    'Ġbrew',
    'eries',
    'ĠH',
    'ilton',
    'und',
    'le',
    'ĠK',
    'ak',
    'ĠThank',
    'fully',
    'ĠCan',
    'ucks',
    'in',
    'ctions',
    'ĠApp',
    'ears',
    'Ġco',
    'er',
    'Ġundermin',
    'ed',
    'ro',
    'vers',
    'And',
    're',
    'Ġbl',
    'aze',
    'um',
    'ers',
    'Ġfam',
    'ine',
    'amp',
    'hetamine',
    'ulk',
    'an',
    'Am',
    'ount',
    'Ġdesper',
    'ation',
    'wik',
    'ipedia',
    'develop',
    'ment',
    'ĠCor',
    'inth',
    'uss',
    'ia',
    'Jack',
    'son',
    'L',
    'I',
    'N',
    'ative',
    'R',
    's',
    'Oh',
    'io',
    'ĠKath',
    'leen',
    'F',
    'ortunately',
    'Ġattend',
    'ant',
    'ĠPre',
    'ferred',
    'ĠDid',
    'n',
    'ĠV',
    's',
    'M',
    'is',
    'Ġrespond',
    'ent',
    'Ġb',
    'oun',
    'st',
    'able',
    'Ġp',
    'aved',
    'Ġunex',
    'pl',
    'ĠChe',
    'ney',
    'L',
    'M',
    'ĠC',
    'ull',
    'bl',
    'own',
    'Ġconfront',
    'ing',
    'oc',
    'ese',
    'serv',
    'ing',
    'W',
    'i',
    'ĠLith',
    'uania',
    'ann',
    'i',
    'Ġst',
    'alk',
    'h',
    'd',
    'Ġv',
    'ener',
    'AP',
    'H',
    'ynchron',
    'ous',
    'UR',
    'R',
    'um',
    'ably',
    'hist',
    'oric',
    'H',
    'alf',
    'H',
    'ay',
    'Ġresil',
    'ience',
    'spe',
    'ction',
    'Ġabandon',
    'ing',
    'O',
    'bs',
    'ĠDeb',
    'bie',
    'Ġgrad',
    'ient',
    'ĠPl',
    'aint',
    'ĠCan',
    'al',
    'AR',
    'CH',
    'Ġexpans',
    'ive',
    'Ġfun',
    'g',
    'Ġb',
    'ounced',
    'U',
    'nd',
    'Ġprec',
    'autions',
    'Ġclar',
    'ification',
    'Ġd',
    'agger',
    'Ġgri',
    'ps',
    'ĠÂ',
    'µ',
    'ĠRiver',
    'a',
    'ĠUnd',
    'ead',
    'is',
    'ites',
    'ĠFIR',
    'ST',
    'Ã±',
    'o',
    'aud',
    'i',
    'Ġhost',
    'ages',
    'Ġcompl',
    'iant',
    'Ġal',
    'umni',
    'Se',
    'ven',
    'Ġcyber',
    'security',
    'e',
    'ither',
    'Col',
    'lect',
    'Ġinvari',
    'ably',
    'ĠS',
    'oci',
    'Ġlaw',
    'maker',
    'Ġa',
    'le',
    'ĠPerson',
    'ally',
    'N',
    'azi',
    'Ġcustom',
    'ization',
    'ĠPro',
    'c',
    'ĠSask',
    'atchewan',
    'eat',
    'uring',
    'Ġsp',
    'ared',
    'Ġdiscontin',
    'ued',
    'Ġcomput',
    'ational',
    'ĠMotor',
    'ola',
    'Ġsuprem',
    'acist',
    'government',
    'al',
    'Ġparad',
    'ise',
    'ĠDown',
    'ing',
    'ĠNik',
    'on',
    'Ġcat',
    'alyst',
    'ber',
    'ra',
    'Tor',
    'onto',
    '8',
    '75',
    'bet',
    'a',
    'ĠMac',
    'ron',
    'Ġunreal',
    'istic',
    've',
    'ctor',
    'ĠVeh',
    'icles',
    'it',
    'iveness',
    'ĠR',
    'V',
    'ĠCol',
    'bert',
    's',
    'in',
    'o',
    'ji',
    'ent',
    'in',
    'ĠKr',
    'ish',
    'hell',
    'o',
    'ff',
    'ield',
    'ok',
    'y',
    'ĠT',
    'ate',
    'Ġmap',
    'le',
    'Ġa',
    'ids',
    'chem',
    'ical',
    '33',
    '4',
    'n',
    'uts',
    'ĠWar',
    'p',
    'Ġx',
    'x',
    'ĠRob',
    'b',
    'umer',
    'ous',
    '_-',
    '_',
    'ft',
    'ime',
    'ĠV',
    'W',
    'Ġw',
    'inger',
    'ĠD',
    'ome',
    't',
    'ools',
    'ĠP',
    'V',
    'ĠGe',
    'orgetown',
    'Ġg',
    'eared',
    'Ġjihad',
    'ists',
    'Ġc',
    'p',
    'Ġster',
    'oids',
    'M',
    'other',
    'cler',
    'osis',
    'ĠDR',
    'M',
    'nes',
    'ia',
    'Ġl',
    'inger',
    'Ġimm',
    'ersive',
    'ĠC',
    'OUN',
    'Ġoutwe',
    'igh',
    'ens',
    'ual',
    'B',
    'and',
    'Ġtransform',
    's',
    'mat',
    'ched',
    'ps',
    'ons',
    'ĠJud',
    'icial',
    'f',
    'actor',
    'Ġrefer',
    'ral',
    'Ġodd',
    'ly',
    'ĠW',
    'enger',
    'B',
    'ring',
    'ĠB',
    'ows',
    '60',
    '2',
    'IC',
    'LE',
    'Ġl',
    'ions',
    'ĠAcad',
    'emic',
    'ĠTh',
    'orn',
    'ĠRa',
    'ider',
    'kef',
    'eller',
    'St',
    'orage',
    'L',
    'ower',
    'ĠOr',
    't',
    'ĠEqu',
    'ality',
    'AL',
    'T',
    'ĠS',
    'OC',
    'T',
    'ypes',
    'Ġl',
    'yn',
    'ĠAss',
    'et',
    'co',
    'at',
    'TP',
    'P',
    'C',
    'VE',
    'ĠPione',
    'er',
    'app',
    'lication',
    'Mod',
    'ern',
    'ĠH',
    'K',
    'En',
    'vironment',
    'Al',
    'right',
    'R',
    'ain',
    'IP',
    'P',
    'ĠShi',
    'ite',
    'Ġm',
    'ound',
    'ĠAb',
    'ilities',
    'cond',
    'ition',
    'St',
    'aff',
    'Ġcompet',
    'ence',
    'ĠM',
    'oor',
    'ĠDi',
    'ablo',
    'Ġwith',
    'held',
    'Ġost',
    'ensibly',
    'ĠB',
    'rom',
    'Ġms',
    'g',
    'Ġden',
    'omin',
    'ĠRef',
    'erences',
    'ĠF',
    'P',
    'Ġplun',
    'ged',
    'Ġp',
    'amph',
    'm',
    'oving',
    'cent',
    'ral',
    'Ġdown',
    'right',
    'Ġf',
    'ading',
    'T',
    'al',
    'T',
    'yp',
    'ĠTh',
    'y',
    'uk',
    'es',
    'it',
    'he',
    'Ġo',
    've',
    'Ġbatt',
    'led',
    'Ġseaf',
    'ood',
    'Ġfig',
    'ur',
    'ĠR',
    'D',
    'c',
    'rop',
    'Ġsqu',
    'ads',
    '{',
    '\\',
    'à',
    '¹',
    'ĠE',
    'h',
    'Ġinterview',
    'ing',
    'ĠQ',
    'in',
    'Ġas',
    'piring',
    'PL',
    'IC',
    'Ġcla',
    'uses',
    'ĠG',
    'ast',
    'ĠN',
    'ir',
    'Ġl',
    'uggage',
    'Ġh',
    'ose',
    'Ġsystem',
    'd',
    'Ġdesc',
    'ending',
    'ĠRev',
    'ised',
    'ĠR',
    'ails',
    'al',
    'ign',
    '70',
    '9',
    '33',
    '7',
    'Ġf',
    'ug',
    'charg',
    'ing',
    't',
    'ags',
    'Ġut',
    'er',
    'k',
    'ish',
    'WAR',
    'NING',
    '49',
    '0',
    'prof',
    'its',
    'Ġvoy',
    'age',
    'Ġa',
    'ce',
    'ĠV',
    'anguard',
    'ĠT',
    'anks',
    'ĠM',
    'uk',
    'Ġ2',
    '26',
    'S',
    'afe',
    'Ar',
    'mor',
    'Ġvolcan',
    'ic',
    'Ġwom',
    'b',
    'ĠM',
    'IL',
    'Ġbegin',
    'ner',
    'ĠRec',
    'ogn',
    'ĠA',
    'AP',
    'PL',
    'AY',
    ')',
    '!',
    'Ġdetect',
    'ing',
    'c',
    'n',
    'Ġbre',
    'aches',
    'Bas',
    'ically',
    'ĠP',
    'ag',
    'ĠMunicip',
    'al',
    'ĠInd',
    'ie',
    'ĠL',
    'af',
    'ĠDis',
    'able',
    'ĠOl',
    'son',
    'Ġrest',
    'rained',
    'Ġrul',
    'ings',
    'Ġhum',
    'ane',
    'ev',
    'ents',
    'ĠCinem',
    'a',
    'display',
    'Text',
    'ĠH',
    'atch',
    'action',
    'Date',
    'onna',
    'issance',
    'Ġassault',
    'ing',
    'ĠL',
    'ug',
    'CH',
    'AT',
    'Ġvig',
    'orous',
    'ĠPer',
    'se',
    'Ġintoler',
    'ance',
    'ĠSnap',
    'chat',
    'ĠSh',
    'arks',
    'Ġd',
    'ummy',
    'ĠDi',
    'agn',
    'ĠGu',
    'itar',
    'im',
    'eters',
    '40',
    '3',
    'RE',
    'G',
    'A',
    'x',
    'Ġsepar',
    'ates',
    'ĠMah',
    'm',
    'Ġt',
    'v',
    'j',
    'ah',
    'O',
    'OL',
    'C',
    'irc',
    'ĠWinds',
    'or',
    'uss',
    'ian',
    'Ġintu',
    'ition',
    'Ġdis',
    'dain',
    'ĠDon',
    'ovan',
    'Ġ2',
    '21',
    'E',
    'mb',
    'Ġcondem',
    'ning',
    'Ġgener',
    'osity',
    'zz',
    'y',
    'Ġpant',
    'ies',
    'ĠPre',
    'vent',
    'Action',
    'Code',
    'AN',
    'A',
    '34',
    '2',
    'external',
    'ActionCode',
    'Ġspec',
    'ifying',
    'Ġcryst',
    'all',
    'J',
    'ere',
    'Ġru',
    'pt',
    'ĠApp',
    'rentice',
    'Ġprof',
    'iling',
    'Ð',
    'º',
    'St',
    'rike',
    'Ġsid',
    'eline',
    'Ġoblig',
    'ated',
    'Ġocc',
    'ult',
    'Ġbureaucr',
    'atic',
    'ant',
    'ically',
    'rupt',
    'ed',
    'neg',
    'ative',
    'ĠEthiop',
    'ia',
    'ĠC',
    'ivic',
    'Ġins',
    'iders',
    'el',
    'igible',
    'ĠTV',
    's',
    'ĠB',
    'AR',
    'ĠT',
    'I',
    'i',
    'ologist',
    'ĠA',
    'IR',
    'Ġsubstit',
    'uted',
    'Ar',
    'ab',
    'ĠS',
    'aul',
    'ĠY',
    'og',
    'p',
    'rem',
    'Ġbuild',
    'ers',
    'Ġstation',
    'ary',
    'Ġdoubt',
    'ful',
    'Ġvig',
    'orously',
    'Ġthr',
    'illing',
    'Ph',
    'ysical',
    'ĠCare',
    'y',
    'ĠHyd',
    'ra',
    'geon',
    'ing',
    'ĠS',
    'ly',
    'y',
    'ton',
    'Ġborrow',
    'ers',
    'ĠPark',
    'inson',
    'Ġ',
    'ë',
    'ĠJama',
    'ica',
    'Ġsat',
    'ir',
    'Ġinsurg',
    'ents',
    'ĠF',
    'irm',
    'Ġis',
    'ot',
    'ĠK',
    'arn',
    'our',
    'ning',
    'ak',
    'ens',
    'doc',
    's',
    'l',
    'ittle',
    'ĠMon',
    'aco',
    'CL',
    'ASS',
    'Tur',
    'key',
    'L',
    'y',
    'ĠCon',
    'an',
    'ass',
    'ic',
    'Ġstar',
    'red',
    'ĠPac',
    'ers',
    'et',
    'ies',
    'Ġt',
    'ipping',
    'M',
    'oon',
    'ĠR',
    'w',
    's',
    'ame',
    'Ġcav',
    'ity',
    'Ġgo',
    'of',
    'ĠZ',
    'o',
    'Sh',
    'ock',
    'um',
    'mer',
    'Ġemphas',
    'izes',
    'Ġreg',
    'rett',
    'Ġnovel',
    'ty',
    'Ġen',
    'vy',
    'ĠPass',
    'ive',
    'r',
    'w',
    '50',
    '5',
    'Ġind',
    'ifferent',
    'ĠR',
    'ica',
    'ĠHim',
    'self',
    'ĠFred',
    'die',
    'Ġad',
    'ip',
    'ä¸',
    'Ģ',
    'Ġbreak',
    'out',
    'Ġhur',
    'ried',
    'ĠHu',
    'ang',
    'ĠD',
    'isk',
    'Ġro',
    'aming',
    '?????-',
    '?????-',
    'U',
    'V',
    'ĠRick',
    'y',
    'ĠS',
    'igma',
    'Ġmarginal',
    'ized',
    'Ġed',
    'its',
    'Ġ30',
    '4',
    'mem',
    'ory',
    'Ġspec',
    'imen',
    '29',
    '3',
    'ãģ',
    '¯',
    'Ġvert',
    'ically',
    'Ġaud',
    'ition',
    'ĠHe',
    'ck',
    'Ġc',
    'aster',
    'ĠHold',
    'ings',
    'ad',
    'al',
    'ĠC',
    'ron',
    'ĠL',
    'iam',
    'Ġdef',
    'lect',
    'P',
    'ick',
    'ĠDeb',
    'ug',
    'RE',
    'F',
    'Ġvers',
    'atility',
    'ot',
    'hes',
    'class',
    'ified',
    'ĠMah',
    'ar',
    'ĠH',
    'ort',
    'C',
    'ounter',
    'st',
    'asy',
    'not',
    'iced',
    '33',
    '1',
    'ĠSh',
    'im',
    'f',
    'uck',
    'ĠB',
    'ie',
    'Ġair',
    'ing',
    'ĠPro',
    'tein',
    'ĠHold',
    'ing',
    'Ġspect',
    'ators',
    'ili',
    'ated',
    'ĠThat',
    'cher',
    'n',
    'osis',
    'ãĥ¼',
    'ãĥ³',
    'Te',
    'le',
    'B',
    'oston',
    'ĠTem',
    'pl',
    'st',
    'ay',
    'Ġdecl',
    'arations',
    '47',
    '9',
    'Vol',
    'ume',
    'ĠDesign',
    'er',
    'ĠOver',
    'watch',
    'id',
    'ae',
    'Ġon',
    'wards',
    'Ġn',
    'ets',
    'ĠMan',
    'ila',
    'part',
    'icularly',
    'Ġpolit',
    'ic',
    'o',
    'other',
    'Ġport',
    'raits',
    'Ġpave',
    'ment',
    'c',
    'ffff',
    'Ġs',
    'aints',
    'Ġbegin',
    'ners',
    'ES',
    'PN',
    'Ġshort',
    'comings',
    'âķĲ',
    'âķĲ',
    'Ġcom',
    'et',
    'ĠOrgan',
    'ic',
    'qu',
    'el',
    'Ġhospital',
    'ized',
    'Bre',
    'ak',
    'Ġpe',
    'el',
    'dyl',
    'ib',
    'asp',
    'x',
    'ur',
    'ances',
    'ĠT',
    'IM',
    'P',
    'g',
    'Ġread',
    'able',
    'ĠMal',
    'ik',
    'Ġm',
    'uzzle',
    'Ġbench',
    'marks',
    'd',
    'al',
    'ĠV',
    'acc',
    'ĠH',
    'icks',
    '60',
    '9',
    'ĠB',
    'iblical',
    'he',
    'ng',
    'Ġover',
    'load',
    'ĠCivil',
    'ization',
    'Ġimm',
    'oral',
    'Ġf',
    'ries',
    'ãĤ',
    'Ĵ',
    'Ġreprodu',
    'ced',
    'Ġform',
    'ulation',
    'j',
    'ug',
    'ire',
    'z',
    'g',
    'ear',
    'Ġco',
    'ached',
    'Mp',
    'Server',
    'ĠS',
    'J',
    'ĠK',
    'w',
    'In',
    'it',
    'd',
    'eal',
    'ĠO',
    'ro',
    'ĠL',
    'oki',
    'ĠSong',
    's',
    'Ġ23',
    '2',
    'ĠLou',
    'ise',
    'asion',
    'ally',
    'Ġunc',
    'ond',
    'olly',
    'wood',
    'Ġprogress',
    'ives',
    'ĠEn',
    'ough',
    'ĠDo',
    'e',
    'Ġwreck',
    'age',
    'Ġbr',
    'ushed',
    'ĠBase',
    'Type',
    'Ġz',
    'oning',
    'ish',
    'able',
    'het',
    'ically',
    'ĠC',
    'aucus',
    'ĠH',
    'ue',
    'Ġk',
    'arma',
    'ĠSport',
    'ing',
    'Ġtrad',
    'er',
    'Ġseem',
    'ing',
    'ĠCapt',
    'ure',
    '4',
    '30',
    'b',
    'ish',
    'Ġt',
    'unes',
    'Ġindo',
    'ors',
    'ĠSp',
    'here',
    'ĠD',
    'ancing',
    'TER',
    'N',
    'Ġno',
    'b',
    'ĠG',
    'ST',
    'm',
    'aps',
    'Ġpe',
    'ppers',
    'F',
    'it',
    'Ġoverse',
    'es',
    'ĠRabb',
    'i',
    'ĠR',
    'uler',
    'vert',
    'ising',
    'off',
    'ice',
    'xx',
    'x',
    'Ġra',
    'ft',
    'Ch',
    'anged',
    'Ġtext',
    'books',
    'L',
    'inks',
    'ĠO',
    'mn',
    'ãĢ',
    'ĳ',
    'Ġinconven',
    'ience',
    'ĠDon',
    'etsk',
    '=',
    '~',
    'Ġimplicit',
    'ly',
    'Ġboost',
    's',
    'ĠB',
    'ones',
    'ĠBo',
    'om',
    'Cour',
    'tesy',
    'Ġsens',
    'ational',
    'AN',
    'Y',
    'Ġgre',
    'edy',
    'ed',
    'en',
    'Ġinex',
    'per',
    'ĠL',
    'er',
    'ĠV',
    'ale',
    'Ġtight',
    'en',
    'ĠE',
    'AR',
    'ĠN',
    'um',
    'Ġancest',
    'or',
    'S',
    'ent',
    'ĠH',
    'orde',
    'urg',
    'ical',
    'all',
    'ah',
    'Ġsa',
    'p',
    'amb',
    'a',
    'ĠSp',
    'read',
    'tw',
    'itch',
    'Ġgrand',
    'son',
    'Ġfract',
    'ure',
    'Ġmoder',
    'ator',
    'ĠSe',
    'venth',
    'ĠRe',
    'verse',
    'Ġestim',
    'ation',
    'Cho',
    'ose',
    'Ġpar',
    'ach',
    'Ġbar',
    'ric',
    'ãĢ',
    'Ĳ',
    'Ġcomp',
    'ass',
    'Ġall',
    'ergic',
    'âĢ',
    'ķ',
    'OT',
    'HER',
    'err',
    'illa',
    'Ġw',
    'agon',
    'Ġz',
    'inc',
    'Ġrub',
    'bed',
    'ĠFull',
    'er',
    'ĠLuxem',
    'bourg',
    'ĠHoo',
    'ver',
    'Ġli',
    'ar',
    'ĠEven',
    'ing',
    'ĠCob',
    'b',
    'est',
    'eem',
    'Ġselect',
    'or',
    'ĠB',
    'rawl',
    'is',
    'ance',
    'ĠE',
    'k',
    'Ġtro',
    'op',
    'Ġg',
    'uts',
    'ĠApp',
    'eal',
    'ĠTibet',
    'an',
    'Ġrout',
    'ines',
    'ĠM',
    'ent',
    'Ġsummar',
    'ized',
    'steam',
    'apps',
    'Ġtr',
    'anqu',
    'Ġ19',
    '29',
    'or',
    'an',
    'ĠAut',
    'hent',
    'Ġg',
    'maxwell',
    'Ġappre',
    'hens',
    'Ġpo',
    'ems',
    'Ġsa',
    'usage',
    'ĠWeb',
    'ster',
    'ur',
    'us',
    'Ġthem',
    'ed',
    'Ġl',
    'ounge',
    'Ġcharg',
    'er',
    'Sp',
    'oiler',
    'Ġsp',
    'illed',
    'h',
    'og',
    'ĠSu',
    'nder',
    'ĠA',
    'in',
    'ĠAng',
    'ry',
    'Ġdis',
    'qual',
    'ĠFrequ',
    'ency',
    'ĠEther',
    'net',
    'Ġhel',
    'per',
    'Per',
    'cent',
    'Ġhorr',
    'ifying',
    'Ġa',
    'il',
    'ĠAll',
    'an',
    'EE',
    'E',
    'ĠCross',
    'ing',
    '44',
    '9',
    'Ġh',
    'olog',
    'ĠPuzz',
    'les',
    'ĠGo',
    'es',
    'eren',
    'n',
    '60',
    '4',
    'ãģ',
    'ı',
    'ĠRaf',
    'ael',
    'Ġatt',
    'en',
    'ĠE',
    'manuel',
    'Ġup',
    'ro',
    'ĠSus',
    'p',
    'P',
    'sych',
    'ĠTr',
    'ainer',
    'ĠN',
    'ES',
    'ĠHun',
    'ts',
    'bec',
    'ue',
    'Ġcounsel',
    'or',
    'R',
    'ule',
    'Ġtox',
    'ins',
    'Ġb',
    'anners',
    'r',
    'ifice',
    'Ġgreet',
    'ing',
    'Ġfren',
    'zy',
    'Ġall',
    'ocate',
    'Ġ*',
    ')',
    'ex',
    'pr',
    '50',
    '3',
    'ĠCh',
    'ick',
    'ĠT',
    'orn',
    'Ġconsolid',
    'ation',
    'ĠF',
    'letcher',
    'sw',
    'itch',
    'fr',
    'ac',
    'cl',
    'ips',
    'ĠMcK',
    'in',
    'ĠLun',
    'ar',
    'Mon',
    'th',
    'IT',
    'CH',
    'Ġscholar',
    'ly',
    'rap',
    'ed',
    '39',
    '8',
    'Ġ19',
    '10',
    'Ġe',
    'greg',
    'Ġin',
    'secure',
    'Ġvict',
    'orious',
    'cffff',
    'cc',
    'Ġsing',
    'led',
    'Ġel',
    'ves',
    'ĠW',
    'ond',
    'bur',
    'st',
    'Ġcam',
    'oufl',
    'ĠBL',
    'ACK',
    'Ġcondition',
    'ed',
    'ç',
    'ī',
    'ans',
    'wered',
    'Ġcompuls',
    'ory',
    'asc',
    'ist',
    'Ġpodcast',
    's',
    'ĠFrank',
    'furt',
    'bn',
    'b',
    'Ġne',
    'oliberal',
    'ĠKey',
    'board',
    'ĠBel',
    'le',
    'w',
    'arm',
    'Ġtrust',
    's',
    'Ġins',
    'ured',
    'ĠBu',
    'cc',
    'us',
    'able',
    '60',
    '7',
    'ĠPl',
    'ains',
    'Ġ18',
    '90',
    'Ġsabot',
    'age',
    'Ġlod',
    'ged',
    'f',
    'elt',
    'Ġg',
    'a',
    'ĠN',
    'arc',
    'ĠSal',
    'em',
    'Ġsevent',
    'y',
    'ĠBl',
    'ank',
    'p',
    'ocket',
    'Ġwhis',
    'per',
    'Ġm',
    'ating',
    'om',
    'ics',
    'ĠSal',
    'man',
    'ĠK',
    'ad',
    'Ġan',
    'gered',
    'Ġcoll',
    'isions',
    'Ġextraord',
    'inarily',
    'Ġcoerc',
    'ion',
    'G',
    'host',
    'b',
    'irds',
    'è',
    'Ģ',
    'k',
    'ok',
    'Ġper',
    'missible',
    'avor',
    'able',
    'Ġpo',
    'inters',
    'Ġdiss',
    'ip',
    'ac',
    'i',
    'Ġtheat',
    'rical',
    'ĠCos',
    'mic',
    'Ġforget',
    'ting',
    'Ġfinal',
    'ized',
    'å¤',
    '§',
    'y',
    'out',
    'l',
    'ibrary',
    'Ġbo',
    'oming',
    'ĠBel',
    'ieve',
    'ĠTe',
    'acher',
    'ĠL',
    'iv',
    'ĠGOOD',
    'MAN',
    'ĠDomin',
    'ican',
    'OR',
    'ED',
    'ĠPart',
    'ies',
    'Ġprecip',
    'itation',
    'ĠSl',
    'ot',
    'R',
    'oy',
    'ĠComb',
    'ined',
    'Ġinteg',
    'rating',
    'Ġch',
    'rome',
    'Ġintest',
    'inal',
    'ĠRe',
    'bell',
    'Ġmatch',
    'ups',
    'Ġblock',
    'buster',
    'ĠLore',
    'n',
    'ĠLe',
    'vy',
    'Ġpre',
    'aching',
    'ĠS',
    'ending',
    'ĠPur',
    'pose',
    'ra',
    'x',
    'f',
    'if',
    'Ġauthor',
    'itative',
    'ĠP',
    'ET',
    'ast',
    'ical',
    'Ġdish',
    'on',
    'Ġchat',
    'ting',
    'Ġ"$',
    ':/',
    'Connect',
    'ion',
    'Ġrecre',
    'ate',
    'Ġdel',
    'inqu',
    'Ġbro',
    'th',
    'ĠD',
    'irty',
    'ĠAd',
    'min',
    'z',
    'man',
    'Ġscholars',
    'hips',
    'Ġ25',
    '3',
    'cont',
    'act',
    'als',
    'a',
    '7',
    '67',
    'c',
    'reen',
    'abb',
    'age',
    'Ġ19',
    '15',
    'Ġbl',
    'ended',
    'Ġal',
    'armed',
    'L',
    'anguage',
    '35',
    '6',
    'Ġbl',
    'ends',
    'ĠCh',
    'anged',
    'W',
    'olf',
    'Ġhe',
    'pat',
    'Creat',
    'ing',
    'Ġper',
    'secut',
    'Ġsweet',
    'ness',
    'art',
    'e',
    'Ġforfe',
    'iture',
    'ĠRober',
    'to',
    'im',
    'pro',
    'N',
    'FL',
    'ĠMag',
    'net',
    'Det',
    'ailed',
    'Ġinsign',
    'ificant',
    'ĠPOL',
    'IT',
    'ĠBB',
    'Q',
    'ĠC',
    'PS',
    'Ġse',
    'aw',
    'amin',
    'er',
    'm',
    'L',
    'end',
    'if',
    'f',
    'inals',
    'Ġ26',
    '5',
    'u',
    'ish',
    'Ġ}',
    ')',
    'ĠPro',
    'blems',
    'Ġem',
    'blem',
    'Ġserious',
    'ness',
    'Ġpars',
    'ing',
    'Ġsubst',
    'itution',
    'Ġpress',
    'ured',
    'Ġrecy',
    'cled',
    'ale',
    'b',
    'Rub',
    'y',
    'Ġprof',
    'iciency',
    'Dri',
    'ver',
    'ĠW',
    'ester',
    ':',
    "'",
    'AF',
    'TA',
    'Ġm',
    'antle',
    'ĠClay',
    'ton',
    'fl',
    'ag',
    'Ġpractition',
    'er',
    'c',
    'overed',
    'ĠSt',
    'ruct',
    'add',
    'afi',
    '4',
    '25',
    'ĠTown',
    'ship',
    'ĠHyd',
    'ro',
    'Lou',
    'is',
    '34',
    '3',
    'Ġcond',
    'o',
    'ĠT',
    'ao',
    'Ġutil',
    'ization',
    'Ġnause',
    'a',
    'ĠDem',
    's',
    'rid',
    'ges',
    'p',
    'ause',
    'Ġform',
    'ulas',
    'Ġchall',
    'enger',
    '37',
    '6',
    'Ġdefect',
    'ive',
    'ĠRail',
    'way',
    'ĠPub',
    'Med',
    'Ġyog',
    'urt',
    'l',
    'bs',
    'ĠNor',
    'folk',
    'OP',
    'E',
    'ĠMood',
    'y',
    'Ġdistribut',
    'or',
    'Ġscroll',
    's',
    'Ġextract',
    's',
    'St',
    'an',
    'Ġv',
    'iability',
    'Ġexp',
    'oses',
    'Ġstar',
    'vation',
    'ĠStep',
    's',
    'ĠD',
    'odd',
    'f',
    'ew',
    'ST',
    'D',
    '33',
    '2',
    'Ġclos',
    'ures',
    'Ġcomplement',
    'ary',
    'ĠS',
    'asha',
    'ump',
    'y',
    'Ġmon',
    'et',
    'Ġartic',
    'ulate',
    'ĠDo',
    'ct',
    'k',
    'iller',
    'Ġsc',
    'rim',
    'Ġ2',
    '64',
    'Ġprost',
    'itutes',
    'Ġse',
    'vered',
    'Ġattach',
    'ments',
    'Ġcool',
    'ed',
    'L',
    'ev',
    'ĠF',
    'alk',
    'f',
    'ail',
    'Ġpolic',
    'eman',
    'ĠD',
    'ag',
    'Ġpray',
    'ed',
    'ĠK',
    'ernel',
    'Ġcl',
    'ut',
    'Ġc',
    'ath',
    'Ġan',
    'omaly',
    'St',
    'orm',
    'em',
    'aker',
    'ĠBreak',
    'fast',
    'ul',
    'i',
    'o',
    'ire',
    'J',
    'J',
    'h',
    'z',
    'Oper',
    'ation',
    'ĠS',
    'ick',
    '35',
    '4',
    'ĠGuatem',
    'ala',
    'R',
    'ate',
    'Ġexp',
    'osures',
    'f',
    'aces',
    'ĠArch',
    'ae',
    'ra',
    'f',
    'ĠM',
    'ia',
    'Ġ20',
    '25',
    'Ġop',
    'aque',
    'Ġdisgu',
    'ised',
    'ĠHead',
    'quarters',
    'S',
    'ah',
    'Ġp',
    'ots',
    '9',
    '78',
    'ĠM',
    'alf',
    'Ġfrown',
    'ed',
    'Ġpoison',
    'ous',
    'ĠCon',
    'vers',
    'ee',
    'ks',
    'Ġcr',
    'ab',
    '."',
    '"',
    'Ġtre',
    'ason',
    'Ġr',
    'anc',
    'Ġescal',
    'ating',
    'Ġwar',
    'r',
    'Ġmob',
    's',
    'Ġl',
    'amps',
    'ĠSun',
    'shine',
    'ĠBrun',
    'swick',
    'Ph',
    'ones',
    'Ġspe',
    'lled',
    'ĠSk',
    'ip',
    'Ġ20',
    '50',
    'Ġ19',
    '11',
    'ĠPl',
    'uto',
    'ĠAm',
    'end',
    'Ġme',
    'ats',
    '38',
    '7',
    'Ġst',
    'omp',
    'ĠZh',
    'ou',
    'ĠLevi',
    'athan',
    'ĠHaz',
    'ard',
    'ad',
    'v',
    'ĠOr',
    'well',
    'Ġal',
    'oud',
    'Ġb',
    'umper',
    'ĠAn',
    'arch',
    'ub',
    'untu',
    'ĠSer',
    'ious',
    'f',
    'itting',
    'ĠOption',
    'al',
    'ĠCec',
    'il',
    'RE',
    'AM',
    'Ġser',
    'otonin',
    'Ġcultiv',
    'ate',
    'ag',
    'ogue',
    '}',
    '\\',
    'Ġmos',
    'ques',
    'ĠSun',
    'ny',
    'Ġre',
    'active',
    'rev',
    'olution',
    'ĠL',
    'up',
    'ĠFed',
    'ora',
    'Ġdefense',
    'man',
    'ĠV',
    'ID',
    'ist',
    'ine',
    'Ġdrown',
    'ing',
    'ĠBroad',
    'casting',
    'Ġthr',
    'iller',
    'ĠS',
    'cy',
    'Ġacceler',
    'ating',
    'Ġdirect',
    's',
    'od',
    'ied',
    'b',
    'ike',
    'd',
    'uration',
    'Ġpain',
    'fully',
    'R',
    'edd',
    'Ġproduct',
    'ions',
    'Ġg',
    'ag',
    'Ġwh',
    'ist',
    'Ġs',
    'ock',
    'Ġinf',
    'initely',
    'ĠConc',
    'ern',
    'ĠCit',
    'adel',
    'Ġlie',
    'u',
    'Ġcand',
    'les',
    'ogene',
    'ous',
    'arg',
    'er',
    'Ġheaven',
    'ly',
    'inflamm',
    'atory',
    'Per',
    'formance',
    'C',
    's',
    'ruct',
    'ose',
    'az',
    'aki',
    'Ġp',
    'essim',
    'Ġinf',
    'erence',
    'Ġpow',
    'd',
    'ĠZ',
    'oe',
    'Ġpain',
    'ts',
    'Ġd',
    'azz',
    'pt',
    'a',
    '--------',
    '---',
    'Ġins',
    'pir',
    'ĠExper',
    'imental',
    'ĠKn',
    'ife',
    'reg',
    'or',
    'b',
    'ors',
    'Ġshow',
    'ers',
    'rom',
    'eda',
    'Ġs',
    'aint',
    'Ġben',
    'ign',
    'ĠJ',
    'iang',
    'Ġenvision',
    'ed',
    'Ġsh',
    'roud',
    'IF',
    'T',
    'H',
    'O',
    'Ġsh',
    'uff',
    'ĠI',
    'CC',
    'Ġse',
    'greg',
    'Ġrevis',
    'it',
    'ighth',
    'ouse',
    'L',
    'i',
    'Ġsub',
    'strate',
    'ĠSe',
    'as',
    'ĠRew',
    'ard',
    'ĠH',
    'ep',
    'ĠBr',
    'ass',
    's',
    'bm',
    'Ġelim',
    'inates',
    'Ġst',
    'amina',
    'ĠV',
    'AT',
    'ĠLo',
    'an',
    'Ġconst',
    'raint',
    'Ġappropri',
    'ated',
    'Ġp',
    'es',
    'ĠA',
    'LE',
    'r',
    'anging',
    'Ġ40',
    '4',
    '39',
    '2',
    'Ġintellectual',
    's',
    'ach',
    'u',
    'Ġrestruct',
    'uring',
    'ĠLe',
    'vin',
    'Ġrun',
    'es',
    'Ġdelight',
    'ful',
    'Ġcarbohyd',
    'rates',
    'ĠMod',
    'els',
    'ĠExp',
    'o',
    'Ġtransport',
    'ing',
    'all',
    'oc',
    'Ġring',
    'ing',
    'S',
    'amsung',
    'Ġscarce',
    'ly',
    'ĠURL',
    's',
    'ĠM',
    'AS',
    'Ġprot',
    'otypes',
    'Ġnarr',
    'ator',
    'ĠCPU',
    's',
    'cd',
    'n',
    'ĠBart',
    'on',
    'Ġdecided',
    'ly',
    'ĠSh',
    'u',
    'ix',
    'ir',
    'oc',
    'ious',
    'ĠMy',
    'st',
    'N',
    'intendo',
    'Ġre',
    'use',
    'Ġforg',
    'iven',
    'F',
    'ew',
    'in',
    'ical',
    'n',
    'at',
    'Ġseam',
    'less',
    'ĠEv',
    'a',
    'ĠE',
    'VE',
    'ĠJ',
    'O',
    'land',
    'ers',
    'Ġso',
    'fter',
    'neg',
    'ie',
    'Ġtrans',
    'ient',
    'Ġorb',
    'ital',
    'Ġfulf',
    'il',
    'ĠK',
    'om',
    'Hop',
    'efully',
    'Ġdynam',
    'ically',
    'ĠHun',
    'ger',
    'å',
    'Ľ',
    'ĠArmen',
    'ia',
    'el',
    'man',
    'ber',
    'to',
    'Ġp',
    'ige',
    'ĠID',
    's',
    'lim',
    'it',
    'Ġve',
    'ins',
    'Ġso',
    'aring',
    'p',
    'acks',
    'Gold',
    'en',
    'ĠCr',
    'ab',
    'ist',
    'or',
    'ĠR',
    'PM',
    'Ġ$',
    '$',
    'g',
    'ression',
    'Ġjihad',
    'ist',
    'Ġgam',
    'ble',
    'Ġcare',
    'g',
    'Ġinf',
    'lated',
    'F',
    'ace',
    'ĠFire',
    'arms',
    'ĠEm',
    'manuel',
    'â',
    'Ŀ',
    'Ġsh',
    'ocks',
    'gr',
    'ab',
    'Ġspl',
    'end',
    'ĠHP',
    'V',
    'ab',
    'ortion',
    'Ab',
    'ove',
    'Ent',
    'ity',
    'play',
    'ers',
    'Ġcomm',
    'enced',
    'ul',
    'ence',
    'Ġfulfill',
    'ment',
    'Ġembod',
    'iments',
    'ĠW',
    'elfare',
    'Ġha',
    'il',
    'Ġ<',
    '@',
    'tt',
    'en',
    'Ġcat',
    'cher',
    'ĠJ',
    'azeera',
    'Ġvolcan',
    'o',
    'Ġstabil',
    'ize',
    'ĠHand',
    'ler',
    'Ġintens',
    'ified',
    'ĠAb',
    'rams',
    'Ġhum',
    'iliation',
    'p',
    'aced',
    '60',
    '5',
    'ĠCent',
    'OS',
    'Spe',
    'cific',
    'Ġhe',
    'ed',
    'ĠC',
    'AM',
    'ĠGal',
    'ile',
    'D',
    'ie',
    'Ġabol',
    'ished',
    'ĠThom',
    'son',
    'ĠTe',
    'achers',
    'ĠW',
    'ass',
    'j',
    'ong',
    'ĠIS',
    'BN',
    'ĠAll',
    'ies',
    'sh',
    'ake',
    'å',
    '·',
    'v',
    'ict',
    'How',
    'ard',
    'Ġde',
    'em',
    'Ġexceed',
    'ingly',
    'ĠSmart',
    'stocks',
    'ib',
    'e',
    'Ġdoor',
    'way',
    'Ġcompet',
    'ed',
    'ig',
    'mat',
    'Ġnational',
    'ists',
    'Ġg',
    'room',
    'ĠKe',
    'en',
    'Ġdispos',
    'able',
    'de',
    'cl',
    'ĠT',
    'olkien',
    'ĠSche',
    'me',
    'Ġb',
    'iod',
    'Ġav',
    'id',
    'ĠEl',
    'on',
    'ag',
    'ar',
    'ĠT',
    'SA',
    'R',
    'oman',
    'Ġartific',
    'ially',
    'Ġadvis',
    'ors',
    'X',
    'L',
    'ĠInf',
    'erno',
    '36',
    '6',
    'Ġted',
    'ious',
    'ĠPhot',
    'ography',
    'ĠCar',
    'rie',
    'Ġtro',
    'pe',
    'ĠSand',
    'ra',
    'Ġdec',
    'imal',
    'Que',
    'en',
    'ĠGund',
    'am',
    'ĠO',
    'M',
    'ote',
    'ch',
    'N',
    'BA',
    'Ġ19',
    '32',
    'Ġent',
    'renched',
    'ĠMar',
    'ion',
    'Ġfr',
    'aternity',
    'Lab',
    'our',
    'Hen',
    'ry',
    'Ġlat',
    'itude',
    'E',
    'ither',
    'Ġenh',
    'ances',
    'ĠPot',
    'ential',
    'Ġsh',
    'ines',
    'id',
    'ad',
    'Ġbread',
    'th',
    'Ġcapac',
    'ities',
    'ĠðŁ',
    'ĻĤ',
    'ĠBron',
    'x',
    'Ġsex',
    'es',
    'Ġdifferent',
    'iation',
    'Ġheavy',
    'weight',
    'ĠT',
    'aj',
    'd',
    'ra',
    'Ġmigr',
    'ate',
    'Ġexhaust',
    'ion',
    'ĠR',
    'UN',
    'els',
    'ius',
    'ĠCu',
    'omo',
    'Ġgu',
    'itars',
    'Ġcl',
    'ones',
    'ĠSom',
    'ew',
    'ĠP',
    'ry',
    '------------',
    '-',
    'Ġwarr',
    'anted',
    'cy',
    'cles',
    'Ġsalv',
    'age',
    'Ġdis',
    'ks',
    'R',
    'ANT',
    'ĠNGO',
    's',
    'ĠMart',
    'ian',
    '":[',
    '{"',
    'Ġadd',
    'icts',
    'oj',
    'ure',
    'il',
    'let',
    'Ġamazing',
    'ly',
    'art',
    'ments',
    'p',
    'ixel',
    'ĠGPU',
    's',
    'Lay',
    'out',
    'è',
    '£',
    'ĠTam',
    'il',
    'ĠBas',
    'il',
    'Ġimpart',
    'ial',
    'ĠSt',
    'ructure',
    'f',
    'ork',
    'b',
    'ryce',
    'Ġr',
    'idge',
    'ĠHamb',
    'urg',
    'ri',
    'ous',
    'Ġbl',
    'itz',
    'cig',
    'arettes',
    'Ġcan',
    'ned',
    '40',
    '2',
    'Ġiron',
    'ically',
    'Ġcompassion',
    'ate',
    'ĠHaw',
    'kins',
    '.',
    '#',
    'ĠCat',
    'hedral',
    'Ġrall',
    'ied',
    'in',
    'ternal',
    'Ġqu',
    'ota',
    'st',
    'akes',
    'T',
    'EXT',
    'm',
    'om',
    'Ġcomple',
    'tes',
    'Ġ23',
    '8',
    'Ġsh',
    'rug',
    'ãĥ',
    'ĳ',
    'ĠN',
    'inth',
    'Ġrev',
    'ise',
    'ĠProv',
    'ider',
    'Ġtre',
    'acher',
    'Ġqu',
    'asi',
    'ĠPR',
    'ES',
    'Ġdep',
    'osition',
    'Ġconfidential',
    'ity',
    'iss',
    'ors',
    'Ġim',
    'balance',
    'Ġspan',
    'ning',
    'Ġang',
    'ular',
    'ĠC',
    'ul',
    'commun',
    'ication',
    'ĠNor',
    'a',
    'ĠGen',
    'ius',
    'op',
    'ter',
    'Ġs',
    'acked',
    'Sp',
    'ot',
    'Ġfine',
    'ly',
    'ĠCH',
    'R',
    '28',
    '2',
    'w',
    'aves',
    'Pal',
    'est',
    'ĠRo',
    'hing',
    'N',
    'L',
    'è',
    '¿',
    'Ġsh',
    'itty',
    'ĠSc',
    'alia',
    '4',
    '75',
    'Pro',
    'gress',
    'Ġreferen',
    'cing',
    'Ġclass',
    'rooms',
    'ab',
    'ee',
    'Ġs',
    'od',
    'hes',
    'ion',
    '70',
    '8',
    'ĠZucker',
    'berg',
    'ĠFin',
    'ish',
    'ĠScot',
    'ia',
    'ĠSav',
    'ior',
    'ĠInstall',
    'ation',
    'an',
    'tha',
    '(',
    '-',
    'Ġ30',
    '2',
    'ĠP',
    'unk',
    'Ġcr',
    'ater',
    'yout',
    'u',
    'Ġro',
    'ast',
    'Ġinflu',
    'encing',
    'Ġd',
    'up',
    'ĠJ',
    'R',
    'ĠG',
    'rav',
    'Ġstat',
    'ure',
    'Ġbath',
    'rooms',
    'A',
    'side',
    'W',
    'iki',
    'me',
    'an',
    'ĠZ',
    'ak',
    'ĠOn',
    'es',
    'ĠN',
    'ath',
    'Ġhyper',
    't',
    'Ġcommence',
    'ment',
    'C',
    'ivil',
    'Ġmoder',
    'ately',
    'Ġdistribut',
    'ors',
    'Ġbreast',
    'feeding',
    'Ġ9',
    '80',
    'ĠS',
    'ik',
    'ĠC',
    'ig',
    'ĠAM',
    'ER',
    'R',
    'IP',
    'ĠCare',
    'er',
    'ust',
    'ing',
    'Ġmess',
    'ed',
    'Ġe',
    'h',
    'ĠJ',
    'ensen',
    '/',
    '$',
    'Ġblack',
    'mail',
    'Ġconvers',
    'ions',
    'Ġscientific',
    'ally',
    'Ġmant',
    'ra',
    'p',
    'aying',
    'Ġiv',
    'ory',
    'ĠCour',
    'ts',
    'OU',
    'GH',
    'aunt',
    'let',
    'Ser',
    'ial',
    'B',
    'row',
    'ĠH',
    'undreds',
    '3',
    '23',
    'Ġpe',
    'e',
    'Ġlin',
    'ux',
    'Ġsub',
    'mer',
    'ĠPrinc',
    'ipal',
    '48',
    '5',
    'ĠD',
    'SL',
    'ĠCous',
    'ins',
    'Ġdoctr',
    'ines',
    'ĠAthlet',
    'ics',
    'Ġ3',
    '15',
    'ĠK',
    'arma',
    'Ġatt',
    'ent',
    'ur',
    'ger',
    'Ġpresc',
    'ribe',
    'Ġenc',
    'aps',
    'ĠC',
    'ame',
    'Ġsecret',
    'ive',
    'ĠCr',
    'imes',
    'd',
    'n',
    'C',
    'lean',
    'ĠEgypt',
    'ians',
    'ĠCar',
    'penter',
    'Ġ',
    'll',
    'H',
    'um',
    'ĠMil',
    'o',
    'Ġcapital',
    'ists',
    'Ġbrief',
    'ed',
    'T',
    'we',
    'ĠBas',
    'in',
    'elve',
    't',
    'M',
    'os',
    'Ġplun',
    'ge',
    'ĠKa',
    'iser',
    'ĠFu',
    'j',
    'ill',
    'in',
    'Ġsafegu',
    'ards',
    'Ġo',
    'ste',
    'ĠOpportun',
    'ity',
    'ĠM',
    'afia',
    'ĠCall',
    'ing',
    'ap',
    'a',
    'ur',
    'ban',
    'br',
    'ush',
    'ill',
    'ard',
    'c',
    'Ã©',
    'int',
    'elligence',
    'ĠL',
    'ob',
    'ĠDru',
    'id',
    'Ġsm',
    'oother',
    'Ġfoot',
    'ing',
    'Ġmotor',
    'ists',
    'arc',
    'ity',
    'Ġmascul',
    'inity',
    'Ġm',
    'ism',
    'Ġabdom',
    'inal',
    'ĠTa',
    'vern',
    'ĠR',
    'oh',
    'Ġesc',
    'apes',
    's',
    'igned',
    'Anth',
    'ony',
    'Ġsacrific',
    'ing',
    'Ġintim',
    'acy',
    'Ġan',
    'terior',
    'ĠK',
    'od',
    'Ġmot',
    'if',
    'Ġg',
    'raz',
    'Ġvisual',
    'ization',
    'Ġguitar',
    'ist',
    'ĠTro',
    'tsky',
    'm',
    'agic',
    'D',
    'ar',
    'ĠMor',
    'i',
    'Ġw',
    'ards',
    'Ġtoile',
    'ts',
    'l',
    'est',
    'Ġtele',
    'port',
    'ĠSund',
    'ays',
    'ĠPl',
    'at',
    'ET',
    'S',
    'Ġe',
    'Sports',
    'Pat',
    'rick',
    'ĠK',
    'atherine',
    'en',
    'ko',
    'Ġhas',
    'sle',
    'ĠM',
    'ick',
    'gg',
    'les',
    'Ġh',
    'ob',
    'aint',
    'ain',
    'Ġair',
    'borne',
    'Ġsp',
    'ans',
    'Ġch',
    'ili',
    'Ġa',
    'perture',
    'Ġvolunte',
    'ered',
    'ĠInc',
    'ident',
    'ĠF',
    'res',
    'ĠVeter',
    'an',
    'augh',
    'tered',
    'ing',
    'o',
    'Ġun',
    'insured',
    'CL',
    'OSE',
    'Ġf',
    'use',
    'Ġer',
    'otic',
    'Ġadvert',
    'ise',
    'ra',
    'ising',
    'Text',
    'ure',
    'Ġatt',
    'ends',
    'ĠRE',
    'AL',
    'udd',
    'led',
    'Ġsm',
    'oot',
    'Ġ30',
    '5',
    'ĠWill',
    'is',
    'Ġbl',
    'ond',
    'An',
    'alysis',
    'ĠV',
    'T',
    'on',
    'ica',
    'Ġstrongh',
    'old',
    'R',
    'F',
    'N',
    'M',
    '.',
    '>>',
    'Ġprosper',
    'ous',
    'Ġbo',
    'asted',
    '29',
    '2',
    'ĠManufact',
    'uring',
    'PR',
    'ESS',
    'g',
    'ren',
    'Ġpharm',
    'acy',
    'ĠRoc',
    'kefeller',
    'k',
    'ai',
    'Ġth',
    'umbs',
    'ĠH',
    'ut',
    'Ġmother',
    'board',
    'Ġguard',
    'ians',
    'ĠAl',
    'ter',
    'll',
    'ular',
    'Ġsh',
    'ack',
    'Ġwise',
    'ly',
    'Ġback',
    'bone',
    'erv',
    'a',
    'Ġsu',
    'icides',
    'ĠMcG',
    'regor',
    'ij',
    'ah',
    'E',
    'mer',
    'ĠB',
    'rav',
    'Ġdesign',
    'ate',
    'P',
    'OST',
    'produ',
    'ced',
    'Ġcleans',
    'ing',
    'irl',
    'wind',
    'ex',
    'istent',
    'ĠHum',
    'ph',
    'ĠPay',
    'ne',
    'Ġv',
    'ested',
    'Å',
    '¡',
    'Ġstring',
    'ent',
    'ion',
    'a',
    'Ġuns',
    'ub',
    'Ġsum',
    'med',
    'ĠHer',
    'cules',
    'sub',
    'ject',
    'ĠR',
    'agnar',
    'ĠN',
    'os',
    'Ġcharacter',
    'ization',
    'Ġsav',
    'vy',
    'ĠDaw',
    'son',
    'ĠCas',
    'ino',
    'Ġf',
    'ri',
    'ĠBar',
    'rier',
    'Ġmis',
    'information',
    'Ġins',
    'ulation',
    'Ġcorrid',
    'ors',
    'Ġair',
    'planes',
    'ĠNo',
    'ct',
    'ah',
    'i',
    'Ġ19',
    '16',
    'k',
    'b',
    'arm',
    'ac',
    'Ġsh',
    'un',
    'Ġsche',
    'ma',
    'Ġhorr',
    'ified',
    'Ġ23',
    '9',
    'aund',
    'ers',
    'N',
    'B',
    'i',
    'ates',
    'er',
    'ity',
    'ĠSh',
    'ard',
    'Ġr',
    'arity',
    'Ġgroup',
    'ed',
    'ĠGh',
    'ana',
    'again',
    'st',
    'ĠBi',
    'ological',
    'ĠA',
    'ware',
    'ow',
    'ell',
    'Ï',
    'Ħ',
    'ĠBe',
    'au',
    'sh',
    'aw',
    'H',
    'ack',
    'ĠJul',
    'ius',
    'US',
    'S',
    'ol',
    'son',
    'aun',
    'a',
    'c',
    'ru',
    'ĠMaur',
    'ice',
    'ĠI',
    'k',
    'Ġsequ',
    'encing',
    'Ġradical',
    's',
    'Ġ(',
    '?,',
    'v',
    'irtual',
    'Ġany',
    'ways',
    'Ġreper',
    'c',
    'Ġhand',
    'lers',
    'Ġhes',
    'itant',
    'é',
    'ĥ',
    'ĠM',
    'F',
    'ple',
    'mentation',
    'ass',
    'ociated',
    'Ġcampaign',
    'ed',
    'ĠY',
    'ue',
    'ut',
    'ations',
    'ĠY',
    'oga',
    'Ġsim',
    'mer',
    'Ġro',
    'ds',
    'Ġmel',
    'ody',
    'Ġconv',
    'oy',
    'v',
    'ideos',
    'Ġscreen',
    'ed',
    'N',
    'eg',
    'ochem',
    'ical',
    'Ġ(',
    '))',
    'Ġultr',
    'as',
    'Ġant',
    'ip',
    'ĠIsland',
    'ers',
    '70',
    '4',
    'Ġfet',
    'ish',
    'Ġridic',
    'ulously',
    'ĠK',
    'art',
    'Ġmitochond',
    'rial',
    'Ġinterf',
    'ering',
    'Build',
    'er',
    'Ġover',
    'fl',
    'Ġac',
    'ne',
    'ĠM',
    'ud',
    'ĠK',
    'err',
    'f',
    'lex',
    'ĠPost',
    'al',
    'ĠBalt',
    'ic',
    '47',
    '7',
    'ĠPers',
    'ons',
    'our',
    'age',
    'H',
    'B',
    'ĠM',
    'use',
    'ĠImm',
    'ortal',
    'ĠDri',
    'ving',
    'Ġpet',
    'itions',
    'Ġsubsc',
    'ript',
    'Ġs',
    'orce',
    'ĠProcess',
    'or',
    'ut',
    'on',
    'S',
    'ony',
    'Ġph',
    'on',
    'Ġr',
    'aced',
    'ĠAnth',
    'rop',
    'Ġday',
    'time',
    'ĠEx',
    'ercise',
    'Add',
    'ing',
    'Ġeng',
    'ages',
    'ĠQual',
    'comm',
    'Ġmir',
    'acles',
    'Ġmem',
    'es',
    'ĠDr',
    'ink',
    'ĠOri',
    'oles',
    'Ġhair',
    's',
    'ĠPol',
    'ar',
    'ath',
    'om',
    'Ġsl',
    'ippery',
    'ĠR',
    'emy',
    'Ġcar',
    'amel',
    'ĠY',
    'EAR',
    'Ġal',
    'k',
    'I',
    'gn',
    'a',
    'ution',
    'ĠMer',
    'lin',
    'ĠC',
    'ran',
    'Ġap',
    'ologies',
    'Ġ4',
    '10',
    'Ġout',
    'ing',
    'ĠMem',
    'ories',
    'app',
    'ointed',
    'Ġcount',
    'ered',
    'u',
    'ld',
    'pos',
    'ing',
    'Ġfire',
    'wall',
    'ĠW',
    'ast',
    'ĠW',
    'et',
    'work',
    'ed',
    'se',
    'ller',
    'Ġrepe',
    'aled',
    'ere',
    'o',
    'ass',
    'uming',
    'BL',
    'IC',
    'm',
    'ite',
    'ĠCEO',
    's',
    'ĠChap',
    'el',
    'ellig',
    'ent',
    '________________',
    '________',
    'D',
    'og',
    'Ġw',
    'art',
    'Ġsubsc',
    'riber',
    's',
    'ports',
    'Ġbe',
    'gged',
    'ĠM',
    'V',
    'Ġsem',
    'if',
    'eth',
    'ical',
    'Ġpre',
    'ach',
    'Ġrev',
    'ital',
    'Ġpun',
    'itive',
    'Ġshort',
    'cuts',
    'Ġinstit',
    'uted',
    'ĠWars',
    'aw',
    'Ġabdom',
    'en',
    'ĠK',
    'ING',
    'Ġsuper',
    'intendent',
    'Ġf',
    'ry',
    'ĠGe',
    'o',
    'T',
    'OR',
    'Ġcontrad',
    'ictions',
    'apt',
    'ic',
    'Ġlandsc',
    'apes',
    'b',
    'ugs',
    'Ġcl',
    'ust',
    'Ġvol',
    'ley',
    'c',
    'ribed',
    'Ġt',
    'andem',
    'Ġrob',
    'es',
    'WH',
    'AT',
    'Ġpromot',
    'er',
    'Ġel',
    'oqu',
    'review',
    'ed',
    'ĠD',
    'K',
    'ĠPl',
    'ato',
    'Ġf',
    'ps',
    'T',
    'ank',
    'ĠDer',
    'rick',
    'Ġpriorit',
    'ize',
    'as',
    'per',
    'ĠHond',
    'uras',
    'ĠCom',
    'pleted',
    'ne',
    'c',
    'Ġm',
    'og',
    'n',
    'ir',
    'ĠMay',
    'o',
    'DE',
    'F',
    'st',
    'all',
    'in',
    'ness',
    'ĠVolks',
    'wagen',
    'Ġprec',
    'aution',
    'ĠM',
    'ell',
    'i',
    'ak',
    'ist',
    'ries',
    'Ġ24',
    '8',
    'Ġoverl',
    'apping',
    'Sen',
    'ate',
    'ĠEnh',
    'ance',
    'res',
    'y',
    'rac',
    'ial',
    'OR',
    'TS',
    'ĠM',
    'ormons',
    'Str',
    'ong',
    'ĠCo',
    'ch',
    'Mex',
    'ico',
    'ĠMad',
    'uro',
    'Ġj',
    'ars',
    'Ġcan',
    'e',
    'W',
    'ik',
    'oll',
    'a',
    'iff',
    'erence',
    'Ġphysic',
    'ist',
    'ĠMag',
    'gie',
    'Ġ28',
    '5',
    'Ġdep',
    'iction',
    'ĠMcL',
    'aren',
    'J',
    'u',
    'Ġsl',
    'ows',
    'Ġcommission',
    'ers',
    'ĠWill',
    'ow',
    'ĠExpl',
    'os',
    'hov',
    'ah',
    'Ġtechn',
    'ician',
    'Ġhom',
    'icides',
    'ĠFl',
    'av',
    'ĠTr',
    'uman',
    'Ġ100',
    '00',
    'u',
    'ctor',
    'Ġsh',
    'ader',
    'News',
    'letter',
    '45',
    '7',
    'Ġre',
    'ver',
    'Ġhard',
    'ened',
    'Ġwhere',
    'abouts',
    'Ġrede',
    'velop',
    'Ġcar',
    'bs',
    'Ġtra',
    'vers',
    'Ġsqu',
    'irrel',
    'Ġfoll',
    'ower',
    'Ġs',
    'ings',
    '50',
    '8',
    'Ġrabb',
    'its',
    'emon',
    'ium',
    'Ġdocument',
    'ing',
    'Ġmisunder',
    'stood',
    ')',
    "'",
    'R',
    'ick',
    'gg',
    'ies',
    'Ġprem',
    'ie',
    'Ġsk',
    'ating',
    'Ġpass',
    'ports',
    'Ġf',
    'ists',
    'aged',
    'don',
    'H',
    'aw',
    'AC',
    'P',
    '0',
    '80',
    'ĠThough',
    'ts',
    'ĠCarl',
    'son',
    'Ġpriest',
    'hood',
    'h',
    'ua',
    'Ġdun',
    'geons',
    'ĠLo',
    'ans',
    'Ġant',
    'is',
    'Ġfamiliar',
    'ity',
    'ĠS',
    'abb',
    'op',
    'al',
    'ĠIn',
    'k',
    'st',
    'rike',
    'Ġc',
    'ram',
    'Ġlegal',
    'ized',
    'Ġcu',
    'isine',
    'Ġfib',
    're',
    'Tra',
    'vel',
    'ĠMon',
    'ument',
    'OD',
    'Y',
    'eth',
    'y',
    'Ġinter',
    'state',
    'ĠP',
    'UR',
    'em',
    'porary',
    'ĠArab',
    'ian',
    'develop',
    'ed',
    'Ġsadd',
    'le',
    'Ġg',
    'ithub',
    'ĠOff',
    'er',
    'ĠIS',
    'P',
    'ro',
    'let',
    'ĠSUP',
    'ER',
    'ĠDen',
    'is',
    'Ġmultipl',
    'ier',
    'Ġstir',
    'red',
    'Interest',
    'ingly',
    'Ġcustom',
    'ary',
    'Ġbill',
    'ed',
    'he',
    'x',
    'Ġmultipl',
    'ied',
    'Ġfl',
    'ipping',
    'ĠCros',
    'by',
    'Ġfundament',
    'als',
    'ia',
    'e',
    'ĠPlay',
    'ed',
    'ĠAt',
    'om',
    'am',
    'azon',
    'ĠFl',
    'am',
    'ee',
    'z',
    'activ',
    'ated',
    'Ġtables',
    'poon',
    'Ġliberal',
    'ism',
    'ĠPal',
    'in',
    'ĠP',
    'atel',
    'N',
    'um',
    'ĠT',
    'AM',
    'Ġs',
    'urn',
    'ĠRel',
    'oaded',
    'Ġco',
    'ined',
    '"',
    '],',
    'ĠCl',
    'ash',
    'ĠAg',
    'u',
    'Ġprag',
    'matic',
    'ĠActiv',
    'ate',
    'Ġ8',
    '02',
    'Ġtrail',
    'ers',
    'Ġsil',
    'hou',
    'Ġprob',
    'es',
    'Ġcirc',
    'us',
    'ĠB',
    'ain',
    'ĠLind',
    'say',
    'ĠAb',
    'bey',
    'Del',
    'ivery',
    'Ġconcess',
    'ion',
    'Ġgast',
    'ro',
    'ĠSpr',
    'ite',
    'Ä',
    'Ł',
    'and',
    'el',
    'Ġg',
    'imm',
    'Ġaut',
    'obi',
    'ĠT',
    'urtle',
    'Ġwonder',
    'fully',
    'ĠHar',
    'am',
    'ĠWorld',
    'wide',
    'ĠHand',
    'le',
    'Ġtheor',
    'ists',
    'Ġsle',
    'ek',
    'ĠZh',
    'u',
    'ograph',
    'ically',
    'EG',
    'A',
    'ĠOwn',
    'ers',
    'ath',
    's',
    'ĠAntar',
    'ctic',
    'n',
    'atal',
    '="',
    '"',
    'fl',
    'ags',
    '``',
    '``',
    'Ġs',
    'ul',
    'K',
    'h',
    'Ġpot',
    'assium',
    'Ġlinem',
    'an',
    'Ġcere',
    'al',
    'ĠSe',
    'asons',
    'Ġ20',
    '22',
    'Ġmat',
    'hematic',
    'Ġastron',
    'omers',
    'prof',
    'essional',
    'Ġf',
    'ares',
    'cknow',
    'led',
    'Ġch',
    'i',
    'Ġyoung',
    'sters',
    'Ġmistaken',
    'ly',
    'Ġhem',
    'isphere',
    'ĠDiv',
    'inity',
    'r',
    'one',
    'Ġ"',
    ',',
    'r',
    'ings',
    'Ġattract',
    's',
    'v',
    'ana',
    'å',
    '¹',
    'C',
    'AP',
    'Ġplay',
    'list',
    'Ġpor',
    'ch',
    'ãģ',
    '£',
    'Ġincorpor',
    'ates',
    'Ġso',
    'ak',
    'Ġassert',
    'ing',
    'ĠTerror',
    'ism',
    'ĠP',
    'ablo',
    'J',
    'a',
    'ces',
    'ter',
    'Ġfear',
    'ing',
    'ĠPr',
    'ayer',
    'Ġescal',
    'ated',
    'G',
    'W',
    'Ġro',
    'be',
    'ĠBright',
    'on',
    'ac',
    'ists',
    'ĠSym',
    'phony',
    'ĠDwar',
    'f',
    'ĠPar',
    'ade',
    'ĠLe',
    'go',
    'Ġinex',
    'pl',
    'Ġl',
    'ords',
    'le',
    'af',
    'RA',
    'G',
    'l',
    'iber',
    'Ġcig',
    'ars',
    'ĠJe',
    'hovah',
    '60',
    '6',
    'WIND',
    'OWS',
    'ĠLiber',
    'ia',
    'eb',
    'us',
    'He',
    'avy',
    'Ġl',
    'ubric',
    'ĠR',
    'W',
    'angu',
    'ages',
    'Ġnarrow',
    'ed',
    'com',
    'puter',
    'ĠE',
    'mber',
    'Ġmurder',
    'ing',
    'Ġdown',
    'stream',
    'ĠT',
    'uls',
    'ĠT',
    'ables',
    'Top',
    'ic',
    'ĠAcc',
    'uracy',
    '=',
    '/',
    'l',
    'ost',
    'ĠRe',
    'i',
    'Ġprogress',
    'es',
    'b',
    'ear',
    'Ġestablish',
    'ments',
    'Just',
    'in',
    'ĠPe',
    'ach',
    'ĠG',
    'omez',
    'å',
    '¿',
    'ĠTri',
    'angle',
    'Id',
    'ent',
    'ĠH',
    'ive',
    'Res',
    'ources',
    'Ġmix',
    'es',
    'ĠAss',
    'uming',
    'M',
    'u',
    'Ġhyp',
    'oc',
    'Ġs',
    'ane',
    'ĠW',
    'an',
    'id',
    'ious',
    'Su',
    'ccess',
    'Ġ',
    'io',
    'Ang',
    'el',
    'Ġdanger',
    'ously',
    'ĠCreat',
    'ure',
    'W',
    'ORK',
    ':',
    '[',
    'ĠKat',
    'rina',
    'List',
    'ener',
    'M',
    'iller',
    'ĠId',
    'lib',
    'h',
    'ang',
    'Ġcircum',
    'vent',
    'h',
    'ref',
    'Ġcel',
    'estial',
    'ĠWe',
    'eks',
    'ĠP',
    'ug',
    'ĠDal',
    'ton',
    'Ġsubpoen',
    'a',
    'uk',
    'u',
    'Ġpers',
    'isted',
    'pe',
    'i',
    'old',
    'ing',
    'ĠDoc',
    'uments',
    'ĠH',
    'ast',
    'ĠC',
    'ENT',
    'Ġprim',
    'er',
    'Ġsyn',
    'onymous',
    'Ġn',
    'ib',
    'om',
    'bs',
    'Ġnot',
    'ation',
    'ĠD',
    'ish',
    'ĠAt',
    'mosp',
    'Ġforb',
    'id',
    'ĠAN',
    'G',
    'pat',
    'tern',
    'l',
    'os',
    'Ġproject',
    'iles',
    'b',
    'rown',
    '."',
    ',',
    'ĠVen',
    'om',
    'Ġfierce',
    'ly',
    'ub',
    'lished',
    'ĠU',
    'ran',
    'ĠNic',
    'arag',
    '4',
    '10',
    'ĠC',
    'AL',
    'OT',
    'OS',
    'ĠMir',
    'acle',
    'ĠEn',
    'chant',
    'Ġguard',
    'ing',
    'app',
    'end',
    'Att',
    'ach',
    'Ġlevel',
    'ed',
    'Ġcond',
    'oms',
    'ih',
    'ilation',
    '64',
    '9',
    'Ġnight',
    'mares',
    'ĠTHE',
    'Y',
    'ĠST',
    'ART',
    'ĠK',
    'inn',
    'Ġroomm',
    'ate',
    'Ġhy',
    'giene',
    'o',
    'pping',
    'J',
    'ob',
    'Ġl',
    'vl',
    'ĠV',
    'ER',
    'ĠKe',
    'eping',
    'ab',
    'etic',
    'Ġformat',
    'ting',
    'eral',
    'a',
    'Ġrev',
    'isions',
    'Ġres',
    'urg',
    'T',
    'el',
    'ĠGood',
    'man',
    '35',
    '3',
    'p',
    'od',
    'Ġind',
    'isp',
    'ĠTrans',
    'lation',
    'Ġg',
    'own',
    'ĠM',
    'und',
    'Ġc',
    'is',
    'Ġby',
    'stand',
    'col',
    'lect',
    'ĠPun',
    'jab',
    'act',
    'ively',
    'ĠG',
    'amb',
    'te',
    'll',
    'Ġimport',
    'ing',
    'g',
    'encies',
    'Ġloc',
    'om',
    'ĠBr',
    'ill',
    'H',
    'oly',
    'ĠBer',
    'ger',
    'Ġshow',
    'down',
    'Ġrespond',
    'ers',
    'IL',
    'Y',
    'Ġt',
    'akedown',
    'le',
    'ted',
    'Ġmat',
    'tered',
    'Ġpredict',
    'ive',
    'Ġover',
    'lay',
    'G',
    'PU',
    'ĠV',
    'ick',
    'Ġconvey',
    'ed',
    'T',
    'ab',
    'pe',
    'er',
    'Sc',
    'an',
    'Ġdefensive',
    'ly',
    'v',
    'ae',
    'Ġappro',
    'ving',
    'Ġt',
    'iers',
    'ĠV',
    'ia',
    'quer',
    'ade',
    'ĠSaud',
    'is',
    'Ġdemol',
    'ished',
    'ĠProp',
    'he',
    'Ġmon',
    'o',
    'Ġhospital',
    'ity',
    'H',
    'AM',
    'ĠAri',
    'el',
    'M',
    'OD',
    'ĠTor',
    'ah',
    'Ġbl',
    'ah',
    'ĠBel',
    'arus',
    'erent',
    'ial',
    'ĠT',
    'uc',
    'Ġbank',
    'er',
    '39',
    '7',
    'Ġmosqu',
    'it',
    'ĠScient',
    'ist',
    'ĠMus',
    'ical',
    'Ġh',
    'ust',
    'Sh',
    'ift',
    'Ġtor',
    'ment',
    'Ġstand',
    'off',
    'E',
    'duc',
    'ĠF',
    'og',
    'Ġampl',
    'ifier',
    'Sh',
    'ape',
    'Inst',
    'ance',
    'ĠCrit',
    'ics',
    'Ġda',
    'emon',
    'H',
    'ouston',
    'Ġmatt',
    'ress',
    'ĠID',
    'F',
    'Ġobsc',
    'ene',
    'ĠA',
    'mer',
    'hett',
    'i',
    'Ġcomp',
    'iling',
    '35',
    '2',
    'vere',
    'tt',
    'ĠRed',
    'uction',
    'ist',
    'ration',
    'ĠBl',
    'essed',
    'ĠB',
    'achelor',
    '3',
    '16',
    'Ġpr',
    'ank',
    'ĠVul',
    'can',
    'dd',
    'ing',
    'Ġm',
    'ourning',
    'ĠQu',
    'int',
    'ĠBl',
    'aster',
    'test',
    'ing',
    'Ġsed',
    'iment',
    '>>',
    '>',
    'ĠE',
    'ternity',
    'ĠWH',
    'ERE',
    'ĠM',
    'aze',
    'Ġreact',
    'ing',
    'ĠAl',
    'v',
    'oms',
    'day',
    'ĠC',
    'RA',
    'Ġtransl',
    'ator',
    'Ġbog',
    'us',
    'at',
    'u',
    'We',
    'bsite',
    'oll',
    's',
    'Ġbapt',
    'ism',
    'Ġs',
    'ibling',
    'ĠAut',
    'umn',
    've',
    'z',
    'ãģ®',
    'é',
    'gu',
    'ards',
    'Ge',
    'org',
    'assad',
    'ors',
    'ĠFre',
    'ud',
    'Ġcontin',
    'ents',
    'ĠReg',
    'istry',
    'Bern',
    'ie',
    'ĸļ',
    'å£«',
    'Ġtoler',
    'ant',
    'ĠU',
    'W',
    'Ġhor',
    'ribly',
    '99',
    '5',
    'ĠMID',
    'I',
    'Ġimpat',
    'ient',
    'oc',
    'ado',
    'er',
    'i',
    'ĠWor',
    'st',
    'ĠNor',
    'ris',
    'ĠTalk',
    'ing',
    'Ġdef',
    'ends',
    'ens',
    'able',
    'Ġ20',
    '21',
    'Ġanat',
    'omy',
    'L',
    'ew',
    'Ġdraw',
    'er',
    'ĠCan',
    'berra',
    'Ġpatri',
    'otic',
    'é¾įå',
    'ĸļå£«',
    'ĠAv',
    'g',
    'AR',
    'M',
    'Ġundis',
    'closed',
    'Ġfare',
    'well',
    '45',
    '9',
    'b',
    'able',
    'ĠAll',
    'ison',
    'OL',
    'OG',
    'Ġcon',
    'co',
    't',
    'ight',
    'ĠAC',
    'PI',
    'ĠM',
    'ines',
    'l',
    'ich',
    'ĠâĶ',
    'ľ',
    'represent',
    'ed',
    '200',
    '000',
    'Ġenthusi',
    'ast',
    'OT',
    'S',
    'b',
    'il',
    'ĠIng',
    'redients',
    'Ġinvent',
    'or',
    'ĠMy',
    'SQL',
    'ÂłÂł',
    'Âł',
    'ĠAB',
    'OUT',
    'with',
    'in',
    'Ġm',
    'k',
    'B',
    'ul',
    'ĠF',
    'ake',
    'Ġdracon',
    'ian',
    'W',
    'a',
    'hel',
    'm',
    'ĠTer',
    'ran',
    'erv',
    'ille',
    'Ġcommon',
    'place',
    'SI',
    'ZE',
    'Ġ"',
    '<',
    're',
    'place',
    'ograph',
    's',
    'ĠSE',
    'LECT',
    'inc',
    'ible',
    'ĠMost',
    'ly',
    'ĠShe',
    'ffield',
    'ĠID',
    'E',
    'ugg',
    'le',
    'Ġcit',
    'ations',
    'h',
    'urst',
    'ĠUn',
    'ix',
    'Ġunle',
    'ash',
    'ĠP',
    'iper',
    'ĠN',
    'ano',
    'Ġsucc',
    'umb',
    'Ġreluct',
    'ance',
    'Ġ25',
    '00',
    'ĠMer',
    'chant',
    'Ġwire',
    't',
    'Ġcomb',
    'os',
    'ĠBirth',
    'day',
    'Ġchar',
    'coal',
    'ĠU',
    'PS',
    'ĠFair',
    'fax',
    'Ġdrive',
    'way',
    'ĠT',
    'ek',
    'ĠP',
    'itch',
    'ove',
    're',
    'Ġtechn',
    'icians',
    'ĠAct',
    'ual',
    'fl',
    'ation',
    'ĠF',
    'iscal',
    'ĠEm',
    'pty',
    'an',
    'amo',
    'Ġmag',
    'nesium',
    'Ġsl',
    'ut',
    'Ġgrow',
    'ers',
    'Invest',
    'igators',
    '(',
    '):',
    'ĠS',
    'atellite',
    'ĠKe',
    'ynes',
    'miss',
    'ive',
    'l',
    'ane',
    'Ġb',
    'orough',
    '3',
    '44',
    'ĠTE',
    'AM',
    'ĠBet',
    'hesda',
    'C',
    'V',
    'h',
    'ower',
    'ĠR',
    'AD',
    'Ġch',
    'ant',
    'ĠR',
    'iy',
    'Ġcompos',
    'itions',
    'Ġmild',
    'ly',
    'Ġmedd',
    'ling',
    'Ġag',
    'ility',
    'ane',
    'ers',
    '5',
    '01',
    'Ġsyn',
    'th',
    'ling',
    'er',
    '29',
    '1',
    'Ġex',
    'claimed',
    'Part',
    'y',
    'Ġcont',
    'amin',
    'ĠMan',
    'or',
    'ĠResp',
    'ond',
    'Ġpra',
    'ising',
    'Ġman',
    'ners',
    'fle',
    'et',
    'Sum',
    'mer',
    'ĠLy',
    'nd',
    'ĠDef',
    'initely',
    'gr',
    'im',
    'Ġbow',
    'ling',
    'st',
    'ri',
    'ç',
    'Ľ',
    'y',
    'nt',
    'Ġmand',
    'ates',
    'D',
    'IV',
    'Ġreconc',
    'ile',
    'view',
    's',
    'ĠDam',
    'on',
    'vet',
    'te',
    'F',
    'lo',
    'ĠGreat',
    'est',
    'il',
    'on',
    'ic',
    'ia',
    'Ġportray',
    'al',
    'Ġcush',
    'ion',
    '50',
    '4',
    '19',
    '79',
    'oss',
    'al',
    'App',
    'lic',
    'sc',
    'ription',
    'Ġmit',
    'igation',
    'AT',
    'S',
    'p',
    'ac',
    'Ġer',
    'ased',
    'Ġdefic',
    'iencies',
    'ĠHolland',
    'e',
    'ĠX',
    'u',
    'Ġb',
    'red',
    'Ġpregn',
    'ancies',
    'f',
    'emin',
    'Ġem',
    'ph',
    'Ġpl',
    'anners',
    'Ġout',
    'per',
    'utter',
    'ing',
    'Ġperpet',
    'rator',
    'Ġm',
    'otto',
    'ĠEll',
    'ison',
    'ĠNE',
    'VER',
    'Ġadmitted',
    'ly',
    'AR',
    'I',
    'ĠAzerbai',
    'jan',
    'Ġmill',
    'isec',
    'Ġcombust',
    'ion',
    'ĠBott',
    'le',
    'ĠL',
    'und',
    'ĠP',
    's',
    'ĠD',
    'ress',
    'Ġfabric',
    'ated',
    'Ġbat',
    'tered',
    'Ġs',
    'idel',
    'ĠNot',
    'ting',
    'Fore',
    'ign',
    'ĠJer',
    'ome',
    '0',
    '20',
    'ĠAr',
    'bit',
    'Ġkn',
    'ots',
    'ĠR',
    'IGHT',
    'M',
    'oving',
    'ãģ',
    'Ļ',
    'Ġsur',
    'geries',
    'Ġcour',
    'thouse',
    'Ġm',
    'astered',
    'Ġhover',
    'ing',
    'ĠBr',
    'an',
    'ĠAl',
    'ison',
    'Ġsaf',
    'est',
    'm',
    'ilitary',
    'Ġbull',
    'ied',
    'Ġbar',
    'rage',
    'Read',
    'er',
    'ES',
    'E',
    'ĠGe',
    'ographic',
    'T',
    'ools',
    '3',
    '14',
    'ĠGe',
    'ek',
    'ro',
    'th',
    'gl',
    'ers',
    'ĠF',
    'IN',
    'Ï',
    'ģ',
    'ĠA',
    'ston',
    'al',
    'tern',
    '48',
    '8',
    'Ġveter',
    'in',
    'G',
    'amer',
    'Ġint',
    'el',
    'ren',
    'ches',
    'Sh',
    'ield',
    'Ġam',
    'nesty',
    'ĠB',
    'har',
    'Ġp',
    'iled',
    'Ġhonor',
    'able',
    'ĠInst',
    'itutes',
    'Ġso',
    'aked',
    'Ġcom',
    'a',
    'ĠE',
    'FF',
    '34',
    '1',
    'by',
    'tes',
    'ĠG',
    'mail',
    'le',
    'in',
    'ĠCanad',
    'iens',
    'm',
    'aterial',
    'I',
    'l',
    'Ġinstruct',
    'ors',
    'ĠK',
    'Y',
    'Ġconce',
    'ive',
    'ub',
    'b',
    'ĠP',
    'ossible',
    'Ġeas',
    'ing',
    'ĠChrist',
    'ina',
    'Ġcar',
    'ic',
    'ĠHD',
    'R',
    'R',
    'OM',
    'Ġsho',
    'vel',
    'de',
    'lete',
    'Ġp',
    'uff',
    'ĠCh',
    'anging',
    'Ġseam',
    'lessly',
    'Att',
    'ribute',
    'Ġacqu',
    'isitions',
    'ak',
    'ery',
    'ĠE',
    'F',
    'Ġaut',
    'istic',
    'ĠT',
    'akes',
    'ĠPow',
    'der',
    'ĠSt',
    'ir',
    '5',
    '10',
    'ĠBub',
    'ble',
    'sett',
    'ings',
    'ĠF',
    'owler',
    'Ġmust',
    'ard',
    'Ġmore',
    'over',
    'Ġcopyright',
    'ed',
    'ĠLED',
    's',
    '15',
    '00',
    'æ',
    'ī',
    'ĠH',
    'IS',
    'en',
    'f',
    'Ġcust',
    'od',
    'ĠH',
    'uck',
    'G',
    'i',
    'Ġim',
    'g',
    'An',
    'swer',
    'C',
    't',
    'j',
    'ay',
    'ĠInf',
    'rastructure',
    'Ġfeder',
    'ally',
    'L',
    'oc',
    'Ġmicro',
    'bes',
    'Ġover',
    'run',
    'dd',
    's',
    'ot',
    'ent',
    'adi',
    'ator',
    '>>>>',
    '>>>>',
    'Ġtorn',
    'ado',
    'Ġadj',
    'ud',
    'Ġintrig',
    'ued',
    'Ġs',
    'i',
    'ĠRevel',
    'ation',
    'pro',
    'gress',
    'Ġburgl',
    'ary',
    'ĠSai',
    'yan',
    'ĠK',
    'athy',
    'Ġser',
    'pent',
    'ĠAndre',
    'as',
    'Ġcomp',
    'el',
    'ess',
    'ler',
    'ĠPl',
    'astic',
    'ĠAd',
    'vent',
    'ĠPos',
    'itive',
    'ĠQ',
    't',
    'ĠHind',
    'us',
    'reg',
    'istered',
    'ular',
    'ity',
    'Ġrighteous',
    'ness',
    'Ġdemon',
    'ic',
    'u',
    'itive',
    'ĠB',
    'DS',
    'ĠGre',
    'gg',
    'c',
    'ia',
    'ĠCrus',
    'ade',
    'ĠSina',
    'i',
    'W',
    'ARE',
    '+',
    '(',
    'Ġme',
    'll',
    'Ġder',
    'ail',
    'y',
    'ards',
    'A',
    'st',
    'Ġnotice',
    'ably',
    'ĠO',
    'ber',
    'R',
    'am',
    'Ġun',
    'noticed',
    'Ġse',
    'q',
    'av',
    'age',
    'T',
    's',
    'Ġ6',
    '40',
    'Ġconced',
    'e',
    'Ġ]',
    ')',
    'F',
    'ill',
    'Ġcapt',
    'ivity',
    'ĠImprove',
    'ment',
    'ĠCrus',
    'ader',
    'ara',
    'oh',
    'M',
    'AP',
    'æ',
    'Ĺ',
    'Ġstr',
    'ide',
    'al',
    'ways',
    'F',
    'ly',
    'N',
    'it',
    'Ġal',
    'gae',
    'ĠCook',
    'ing',
    'ĠDo',
    'ors',
    'Mal',
    'ley',
    'Ġpolic',
    'emen',
    'ãģ',
    'į',
    'Ġastron',
    'aut',
    'access',
    'ible',
    '49',
    '5',
    'ĠR',
    'AW',
    'cl',
    'iffe',
    'udic',
    'rous',
    'Ġdep',
    'ended',
    'al',
    'ach',
    'Ġvent',
    'ures',
    'ra',
    'ke',
    'Ġt',
    'its',
    'ĠH',
    'ou',
    'Ġcond',
    'om',
    'ormon',
    'al',
    'Ġind',
    'ent',
    'Ġupload',
    'ing',
    'Foot',
    'note',
    'Import',
    'ant',
    'Ġ27',
    '1',
    'Ġmind',
    'ful',
    'Ġcont',
    'ends',
    'C',
    'ra',
    'Ġcal',
    'ibr',
    'ĠO',
    'ECD',
    'plug',
    'in',
    'F',
    'at',
    'ĠIS',
    'S',
    'ĠDynam',
    'ics',
    'ans',
    'en',
    '68',
    '6',
    "'",
    '),',
    'Ġsp',
    'rite',
    'Ġhand',
    'held',
    'ĠH',
    'ipp',
    '=~',
    '=~',
    'Tr',
    'ust',
    'Ġsem',
    'antics',
    'ĠBund',
    'es',
    'ĠRen',
    'o',
    'ĠLiter',
    'ature',
    's',
    'ense',
    'G',
    'ary',
    'ĠA',
    'eg',
    'ĠTr',
    'in',
    'EE',
    'K',
    'Ġcler',
    'ic',
    'ĠSS',
    'H',
    'Ġch',
    'rist',
    'Ġinv',
    'ading',
    'ib',
    'u',
    'Ġen',
    'um',
    'aur',
    'a',
    'Ġal',
    'lege',
    'ĠInc',
    'redible',
    'B',
    'BC',
    'Ġth',
    'ru',
    'Ġsa',
    'iled',
    'Ġem',
    'ulate',
    'Ġin',
    'security',
    'Ġc',
    'rou',
    'Ġaccommod',
    'ations',
    'Ġincompet',
    'ent',
    'Ġsl',
    'ips',
    'ĠEarth',
    'qu',
    's',
    'ama',
    'IL',
    'LE',
    'Ġi',
    'Phones',
    'as',
    'aki',
    'Ġby',
    'e',
    'Ġar',
    'd',
    'Ġext',
    'ras',
    'Ġsl',
    'aughtered',
    'Ġcrowd',
    'funding',
    'res',
    'so',
    'Ġfil',
    'ib',
    'ĠER',
    'ROR',
    'ĠT',
    'LS',
    'e',
    'gg',
    'ĠIt',
    'al',
    'Ġen',
    'list',
    'ĠCatal',
    'onia',
    'ĠSc',
    'ots',
    'Ġser',
    'geant',
    'Ġdiss',
    'olve',
    'N',
    'H',
    'Ġstand',
    'ings',
    'ri',
    'que',
    'I',
    'Q',
    'Ġbenef',
    'iciary',
    'Ġaqu',
    'arium',
    'You',
    'Tube',
    'ĠPower',
    'Shell',
    'Ġbright',
    'est',
    'ĠWar',
    'rant',
    'S',
    'old',
    'Writ',
    'ing',
    'Ġbegin',
    'nings',
    'ĠRes',
    'erved',
    'ĠLatin',
    'os',
    'head',
    'ing',
    'Ġ4',
    '40',
    'Ġrooft',
    'op',
    'AT',
    'ING',
    'Ġ3',
    '90',
    'VP',
    'N',
    'G',
    's',
    'k',
    'ernel',
    'turn',
    'ed',
    'Ġprefer',
    'able',
    'Ġturn',
    'overs',
    'ĠH',
    'els',
    'S',
    'a',
    'ĠShin',
    'ji',
    've',
    'h',
    'ĠMOD',
    'ULE',
    'V',
    'iol',
    'Ġex',
    'iting',
    'Ġj',
    'ab',
    'ĠVan',
    'illa',
    'Ġac',
    'ron',
    'ĠG',
    'ap',
    'ber',
    'n',
    'A',
    'k',
    'ĠMc',
    'Gu',
    'Ġend',
    'lessly',
    'ĠFar',
    'age',
    'ĠNo',
    'el',
    'V',
    'a',
    'M',
    'K',
    'Ġbr',
    'ute',
    'ĠK',
    'ru',
    'ĠES',
    'V',
    'ĠOl',
    'ivia',
    'âĢ',
    'ł',
    'ĠK',
    'af',
    'Ġtrust',
    'ing',
    'Ġh',
    'ots',
    '3',
    '24',
    'Ġmal',
    'aria',
    'Ġj',
    'son',
    'Ġp',
    'ounding',
    'ort',
    'ment',
    'Count',
    'ry',
    'Ġpostp',
    'oned',
    'Ġunequ',
    'iv',
    '?',
    '),',
    'ĠRo',
    'oney',
    'udd',
    'ing',
    'ĠLe',
    'ap',
    'ur',
    'rence',
    'sh',
    'apeshifter',
    'ĠH',
    'AS',
    'os',
    'ate',
    'Ġca',
    'vern',
    'Ġconserv',
    'atism',
    'ĠB',
    'AD',
    'Ġmile',
    'age',
    'Ġarrest',
    'ing',
    'V',
    'aults',
    'Ġmix',
    'er',
    'Dem',
    'ocratic',
    'ĠB',
    'enson',
    'Ġauth',
    'ored',
    '8',
    '000',
    'Ġpro',
    'active',
    'ĠSpirit',
    'ual',
    't',
    're',
    'Ġincarcer',
    'ated',
    'ĠS',
    'ort',
    'Ġpe',
    'aked',
    'Ġwield',
    'ing',
    're',
    'ciation',
    '×Ļ',
    '×',
    'P',
    'atch',
    'ĠEm',
    'my',
    'Ġex',
    'qu',
    'tt',
    'o',
    'ĠRat',
    'io',
    'ĠP',
    'icks',
    'ĠG',
    'ry',
    'ph',
    'ant',
    'Ġf',
    'ret',
    'Ġeth',
    'n',
    'Ġarch',
    'ived',
    '%',
    '-',
    'c',
    'ases',
    'ĠBl',
    'aze',
    'Ġim',
    'b',
    'c',
    'v',
    'y',
    'ss',
    'im',
    'ony',
    'Ġcount',
    'down',
    'Ġaw',
    'akening',
    'ĠTunis',
    'ia',
    'ĠRe',
    'fer',
    'ĠM',
    'J',
    'Ġun',
    'natural',
    'ĠCar',
    'negie',
    'iz',
    'en',
    'ĠN',
    'uggets',
    'he',
    'ss',
    'Ġev',
    'ils',
    '64',
    '7',
    'Ġintrodu',
    'ctory',
    'l',
    'oving',
    'ĠMcM',
    'ahon',
    'Ġambig',
    'uity',
    'L',
    'abel',
    'ĠAlm',
    'ighty',
    'Ġcolor',
    'ing',
    'ĠCl',
    'aus',
    'set',
    'ting',
    'N',
    'ULL',
    'ĠF',
    'avorite',
    'ĠS',
    'IG',
    '>',
    '(',
    'ĠSh',
    'iva',
    'ĠMay',
    'er',
    'Ġstorm',
    'ed',
    'ĠCo',
    'verage',
    'we',
    'apons',
    'igh',
    'am',
    'Ġun',
    'answered',
    'Ġle',
    've',
    'Ġc',
    'oy',
    'c',
    'as',
    'b',
    'ags',
    'as',
    'ured',
    'Se',
    'attle',
    'ĠSant',
    'orum',
    'ser',
    'ious',
    'Ġcourage',
    'ous',
    'ĠS',
    'oup',
    'Ġconfisc',
    'ated',
    'Ġ//',
    '/',
    'Ġuncon',
    'ventional',
    'Ġmom',
    's',
    'ĠRohing',
    'ya',
    'ĠOrche',
    'stra',
    'ĠPot',
    'ion',
    'Ġdisc',
    'redit',
    'ĠF',
    'IL',
    'f',
    'ixed',
    'ĠDe',
    'er',
    'do',
    'i',
    'ĠDim',
    'ension',
    'Ġbureaucr',
    'ats',
    'et',
    'een',
    'Ġaction',
    'Group',
    'oh',
    'm',
    'Ġb',
    'umps',
    'ĠUt',
    'ility',
    'Ġsubmar',
    'ines',
    'ren',
    'heit',
    're',
    'search',
    'ĠShap',
    'iro',
    'Ġsket',
    'ches',
    'Ġde',
    'ceptive',
    'ĠV',
    'il',
    'es',
    'ame',
    'ĠEss',
    'entially',
    'Ġramp',
    'age',
    'isk',
    'y',
    'Ġmut',
    'tered',
    'th',
    'ritis',
    'Ġ23',
    '6',
    'f',
    'et',
    'b',
    'ars',
    'Ġpup',
    'il',
    'ĠTh',
    'ou',
    'o',
    'S',
    's',
    'ong',
    'Ġfract',
    'ured',
    'Ġre',
    'vert',
    'pict',
    'ure',
    'Ġcrit',
    'erion',
    'us',
    'her',
    'Ġreperc',
    'ussions',
    'ĠV',
    'intage',
    'ĠSuper',
    'intendent',
    'Offic',
    'ers',
    'Ġflag',
    'ged',
    'Ġbl',
    'ames',
    'Ġin',
    'verse',
    'ograp',
    'hers',
    'Ġmakes',
    'hift',
    'Ġdev',
    'oid',
    'Ġfoss',
    'ils',
    'ĠArist',
    'otle',
    'ĠFund',
    's',
    'Ġde',
    'pleted',
    'ĠFl',
    'u',
    'ĠY',
    'uan',
    'Ġw',
    'oes',
    'Ġlip',
    'id',
    'Ġsit',
    'u',
    'requ',
    'isites',
    'Ġfurn',
    'ish',
    'ĠSam',
    'ar',
    'Ġshame',
    'ful',
    'Ġadverse',
    'ly',
    'Ġad',
    'ept',
    'Ġrem',
    'orse',
    'Ġmurder',
    'ous',
    'uck',
    'les',
    'ĠE',
    'SL',
    'Ġ3',
    '14',
    's',
    'ent',
    'Ġred',
    'ef',
    'ĠC',
    'ache',
    'ĠP',
    'urs',
    'ig',
    'ans',
    'Ġ4',
    '60',
    'Ġpres',
    'criptions',
    'Ġf',
    'res',
    'F',
    'uck',
    'ocr',
    'ates',
    'Tw',
    'enty',
    'ĠWe',
    'ird',
    'ĠT',
    'oggle',
    'ĠC',
    'alled',
    'itiz',
    'ens',
    'Ġp',
    'oultry',
    'Ġharvest',
    'ing',
    'ãĤ¦',
    'ãĤ¹',
    'Bott',
    'om',
    'Ġcaution',
    'ed',
    't',
    'n',
    '39',
    '6',
    'ĠNik',
    'ki',
    'Ġeval',
    'uations',
    'Ġharass',
    'ing',
    'Ġbind',
    'ings',
    'ĠMon',
    'etary',
    'Ġhit',
    'ters',
    'Ġadvers',
    'ary',
    'un',
    'ts',
    'Ġset',
    'back',
    'Ġenc',
    'rypt',
    'ĠC',
    'ait',
    'Ġl',
    'ows',
    'eng',
    'es',
    'ĠN',
    'orn',
    'Ġbul',
    'bs',
    'Ġbott',
    'led',
    'ĠVoy',
    'ager',
    '3',
    '17',
    'Ġsp',
    'heres',
    'p',
    'olitics',
    'Ġsubt',
    'ract',
    'Ġsens',
    'ations',
    'Ġapp',
    'alling',
    'Ġ3',
    '16',
    'Ġenvironment',
    'ally',
    'ĠST',
    'EM',
    'Ġpub',
    'lishes',
    '5',
    '60',
    'Ġdilig',
    'ence',
    '48',
    '4',
    'Ġadv',
    'ises',
    'Ġpet',
    'rol',
    'Ġimag',
    'ining',
    'Ġpatrol',
    's',
    'ĠInt',
    'eger',
    'ĠAs',
    'hes',
    'act',
    'us',
    'ĠRad',
    'iant',
    'ĠL',
    'T',
    'it',
    'ability',
    'ht',
    'aking',
    'Set',
    'ting',
    'Ġnu',
    'anced',
    'ĠRe',
    'ef',
    'ĠDevelop',
    'ers',
    'N',
    'i',
    'pie',
    'ces',
    '99',
    '0',
    'Lic',
    'ense',
    'Ġlow',
    'ers',
    'ĠOtt',
    'oman',
    '3',
    '27',
    'oo',
    'o',
    'Ġqu',
    'itting',
    'mark',
    'ets',
    'Beh',
    'ind',
    'Ġbas',
    'in',
    'Ġdoc',
    's',
    'an',
    'ie',
    'fl',
    'ash',
    'ct',
    'l',
    'Ġcivil',
    'ized',
    'ĠFuk',
    'ushima',
    '"]',
    ',"',
    'ĠK',
    'S',
    'ĠHonest',
    'ly',
    'ar',
    'at',
    'Ġconstruct',
    's',
    'ĠL',
    'ans',
    'ĠD',
    'ire',
    'ĠLI',
    'KE',
    'ĠTrou',
    'ble',
    'Ġwith',
    'holding',
    'ĠOb',
    'livion',
    'Ġsan',
    'ity',
    'any',
    'a',
    'Con',
    'st',
    'Ġgro',
    'cer',
    'ĠC',
    'elsius',
    'Ġrecount',
    'ed',
    'ĠW',
    'ife',
    'B',
    'order',
    'ate',
    'red',
    'h',
    'appy',
    'Ġspo',
    'iler',
    'Ġlog',
    'ically',
    'H',
    'all',
    'Ġsucceed',
    'ing',
    'Ġpoly',
    'morph',
    'Ġax',
    'es',
    'ĠShot',
    'gun',
    'ĠS',
    'lim',
    'ĠPrin',
    'ciples',
    'ĠL',
    'eth',
    'art',
    'a',
    'Ġsc',
    'or',
    'Sc',
    'reenshot',
    'Ġrelax',
    'ation',
    '#$',
    '#$',
    'Ġdeter',
    'rent',
    'idd',
    'y',
    'Ġpower',
    'less',
    'Ġles',
    'bians',
    'Ġch',
    'ords',
    'ĠEd',
    'ited',
    'se',
    'lected',
    'Ġseparat',
    'ists',
    '000',
    '2',
    'Ġair',
    'space',
    'Ġturn',
    'around',
    'Ġc',
    'unning',
    'P',
    'ATH',
    'P',
    'oly',
    'Ġbomb',
    'ed',
    'Ġt',
    'ion',
    'x',
    's',
    'Ġwith',
    'hold',
    'Ġw',
    'aged',
    'ĠLiber',
    'ties',
    'Fl',
    'ag',
    'Ġcomfort',
    'ing',
    '45',
    '4',
    'ĠI',
    'ris',
    'are',
    'rs',
    'Ġr',
    'ag',
    'Ġrel',
    'ocated',
    'ĠGu',
    'arant',
    'Ġstrateg',
    'ically',
    'Ġgam',
    'ma',
    'uber',
    'ty',
    'ĠLock',
    'heed',
    'g',
    'res',
    'Ġgr',
    'illed',
    'ĠLow',
    'e',
    'st',
    'ats',
    'ĠR',
    'ocks',
    'Ġsens',
    'ing',
    'Ġrent',
    'ing',
    'ĠGe',
    'ological',
    'Ø§',
    'Ø',
    'ot',
    'rop',
    'Ġse',
    'w',
    'Ġimproper',
    'ly',
    '48',
    '6',
    'Ġâĸ',
    'ł',
    'Ġstar',
    'ving',
    'ĠB',
    'j',
    'Disc',
    'ussion',
    '3',
    '28',
    'ĠCom',
    'bo',
    'ĠFix',
    'es',
    'N',
    'AT',
    'Ġstri',
    'ving',
    'th',
    'ora',
    'Ġharvest',
    'ed',
    'ĠP',
    'ing',
    'Ġplay',
    'ful',
    'Ġaven',
    'ues',
    'Ġoccup',
    'ational',
    'Ġw',
    'akes',
    'ĠCou',
    'rier',
    'Ġdrum',
    'mer',
    'ĠBrow',
    'ser',
    'ĠH',
    'outh',
    'it',
    'u',
    'Ġapp',
    'arel',
    'p',
    'aste',
    'Ġhun',
    'ted',
    'ĠSecond',
    'ly',
    'l',
    'ain',
    'X',
    'Y',
    'ĠP',
    'IN',
    'ic',
    'ons',
    'Ġcock',
    'tails',
    'Ġs',
    'izable',
    'Ġhurd',
    'les',
    'est',
    'inal',
    'ĠRecre',
    'ation',
    'Ġe',
    'co',
    '64',
    '8',
    'ĠD',
    'ied',
    'm',
    'int',
    'Ġfinger',
    'prints',
    'Ġdis',
    'pose',
    'ĠBos',
    'nia',
    'ts',
    'y',
    '22',
    '00',
    'Ġins',
    'pected',
    'ĠF',
    'ou',
    'Ġf',
    'uss',
    'Ġamb',
    'ush',
    'ĠR',
    'ak',
    'Ġmanif',
    'ested',
    'Pro',
    'secut',
    'Ġsuff',
    'ice',
    'ren',
    'ces',
    'Ġcompens',
    'ated',
    'ĠC',
    'yrus',
    'Ġgen',
    'us',
    'ĠWolver',
    'ine',
    'ĠTrend',
    's',
    'Ġh',
    'ikes',
    'ĠSe',
    'en',
    'Ġen',
    'rol',
    'C',
    'old',
    'Ġpol',
    'itely',
    'ĠSl',
    'av',
    'ĠRu',
    'pert',
    'Ġey',
    'ewitness',
    'ĠAl',
    'to',
    'Ġun',
    'comp',
    'Ġposter',
    'ior',
    'M',
    'ust',
    'ĠHer',
    'z',
    'Ġprogress',
    'ively',
    'Ġ23',
    '4',
    'Ġind',
    'ifference',
    'ĠCunning',
    'ham',
    'Ġacadem',
    'ia',
    'Ġse',
    'wer',
    'Ġast',
    'ounding',
    'ĠA',
    'ES',
    'r',
    'ather',
    'Ġeld',
    'est',
    'Ġclim',
    'bs',
    'ĠAdd',
    's',
    'Ġout',
    'cry',
    'Ġcont',
    'ag',
    'ĠH',
    'ouses',
    'Ġpe',
    'pt',
    'ĠMel',
    'ania',
    'interest',
    'ed',
    'ĠU',
    'CH',
    'ĠR',
    'oots',
    'ĠHub',
    'bard',
    'ĠT',
    'BD',
    'ĠRoman',
    'ian',
    'fil',
    'ename',
    'St',
    'one',
    'ĠIm',
    'pl',
    'Ġchromos',
    'ome',
    'C',
    'le',
    'd',
    'x',
    'Ġscram',
    'bled',
    'ĠP',
    't',
    'Ġ24',
    '2',
    'OP',
    'LE',
    'Ġtremend',
    'ously',
    'St',
    'reet',
    'Ġcra',
    'ving',
    'Ġbund',
    'led',
    'ĠR',
    'G',
    'p',
    'ipe',
    'Ġinj',
    'uring',
    'Ġarc',
    'ane',
    'Part',
    'icip',
    'ĠHero',
    'ic',
    'st',
    'y',
    'Ġto',
    'pping',
    'ĠTemp',
    'est',
    'rent',
    'ices',
    'b',
    'h',
    'Ġpar',
    'anoia',
    'ĠUnic',
    'ode',
    'Ġegreg',
    'ious',
    'Ġ\\',
    "'",
    'ĠOsw',
    'ald',
    'Ġgra',
    'vel',
    'ĠSim',
    'psons',
    'Ġbl',
    'and',
    'ĠGuant',
    'anamo',
    'Writ',
    'er',
    'lin',
    'ers',
    'ĠD',
    'ice',
    'J',
    'C',
    'Ġpar',
    'ity',
    'Ġs',
    'ided',
    'Ġ23',
    '7',
    'ĠPyr',
    'rha',
    'at',
    'ters',
    'd',
    'k',
    'F',
    'ine',
    'comp',
    'an',
    'Ġform',
    'ulated',
    'ĠId',
    'ol',
    'il',
    'ers',
    'hem',
    'oth',
    'ĠF',
    'av',
    'Ġintr',
    'usion',
    'Ġcar',
    'rots',
    'ĠL',
    'ayer',
    'ĠH',
    'acker',
    'Ġ',
    '----------------',
    'Ġmoder',
    'ation',
    'é',
    'ģ',
    'oc',
    'oc',
    'Ġcharacter',
    'ize',
    'ĠTe',
    'resa',
    'Ġsocio',
    'economic',
    'Ġper',
    'k',
    'ĠParticip',
    'ation',
    'tr',
    'aining',
    'ĠPaul',
    'o',
    'ph',
    'ys',
    'Ġtrust',
    'worthy',
    'Ġembod',
    'ied',
    'ĠMer',
    'ch',
    'c',
    'urrency',
    'ĠPrior',
    'ity',
    'Ġte',
    'asing',
    'Ġabsor',
    'bing',
    'Ġunf',
    'inished',
    'ĠCompar',
    'ison',
    'Ġdis',
    'ple',
    'writ',
    'ers',
    'Ġprofess',
    'ions',
    'ĠPengu',
    'in',
    'Ġang',
    'rily',
    'ĠL',
    'INK',
    '68',
    '8',
    'ĠCor',
    'respond',
    'Ġprev',
    'ailed',
    'Ġcart',
    'el',
    'l',
    'p',
    'as',
    'ms',
    'ĠRed',
    'emption',
    'ĠIslam',
    'ists',
    'effect',
    's',
    'd',
    'ose',
    'ĠL',
    'atter',
    'ĠHal',
    'ifax',
    'Ġv',
    'as',
    'ĠTop',
    'ics',
    'ĠN',
    'amed',
    'advert',
    'ising',
    'zz',
    'a',
    'IC',
    'ES',
    'Ġret',
    'arded',
    'ach',
    'able',
    'ĠPupp',
    'et',
    'ĠItem',
    'Level',
    'Ġret',
    'ract',
    'Ġident',
    'ifiable',
    'A',
    'aron',
    'ĠB',
    'uster',
    's',
    'ol',
    'hel',
    'le',
    'as',
    'semb',
    'H',
    'ope',
    'r',
    'anged',
    'B',
    'a',
    'ĠP',
    'urch',
    'é',
    'Ģ',
    'ĠSir',
    'i',
    'Ġarri',
    'vals',
    'Ġ19',
    '12',
    'Ġshort',
    'ened',
    'Ġ3',
    '12',
    'Ġdiscrep',
    'ancy',
    'ĠTem',
    'perature',
    'ĠWal',
    'ton',
    'Ġkind',
    'erg',
    'p',
    'olit',
    'Ġrem',
    'ix',
    'Ġconnect',
    'ors',
    'ãĥĺ',
    'ãĥ©',
    'ĠKazakh',
    'stan',
    'dom',
    'inated',
    'Ġsu',
    'gars',
    'im',
    'ble',
    'ĠPan',
    'ic',
    'ĠDem',
    'and',
    'ĠCol',
    'ony',
    'on',
    'en',
    'ĠM',
    'ER',
    '7',
    '75',
    'ur',
    'ia',
    'aza',
    'ar',
    'ĠDeg',
    'ree',
    'P',
    'ri',
    'Ġsun',
    'shine',
    'Ġ25',
    '1',
    'Ġpsychedel',
    'ic',
    'Ġdigit',
    'ally',
    'ĠBra',
    'un',
    'Ġsh',
    'immer',
    'Ġsh',
    'ave',
    'ĠTel',
    'esc',
    'ĠAst',
    'ral',
    'ĠVenezuel',
    'an',
    'ĠO',
    'G',
    'Ġc',
    'rawling',
    'Int',
    'eg',
    'ĠFe',
    'ather',
    'Ġunfold',
    'ing',
    'Ġappropri',
    'ation',
    'Ġè£ı',
    'è',
    'ĠMob',
    'ility',
    'ĠN',
    'ey',
    '-',
    '.',
    'b',
    'ilt',
    'L',
    'IN',
    'ĠT',
    'ube',
    'ĠCon',
    'versely',
    'Ġkey',
    'boards',
    'ĠC',
    'ao',
    'Ġover',
    'th',
    'Ġla',
    'ure',
    '>>',
    '\\',
    'ĠV',
    'iper',
    'ach',
    'a',
    'Off',
    'set',
    'ĠR',
    'aleigh',
    'ĠJ',
    'ae',
    'J',
    'ordan',
    'j',
    'p',
    'Ġtotal',
    'itarian',
    'Connect',
    'or',
    'Ġobserv',
    'es',
    'ĠSpart',
    'an',
    'ĠIm',
    'mediately',
    'ĠSc',
    'al',
    'C',
    'ool',
    'Ġt',
    'aps',
    'Ġro',
    'ar',
    'P',
    'ast',
    'Ġch',
    'ars',
    'ĠB',
    'ender',
    'ĠShe',
    'ldon',
    'Ġpain',
    'ter',
    'Ġbe',
    'acon',
    'ĠCreat',
    'ures',
    'Ġdownt',
    'urn',
    'Ġh',
    'inder',
    'ĠAnd',
    'romeda',
    'Ã',
    'Ľ',
    'cc',
    'oli',
    'ĠF',
    'itness',
    'et',
    'rical',
    'Ġutil',
    'izes',
    'Ġsen',
    'ate',
    'Ġen',
    'semble',
    'Ġche',
    'ers',
    'T',
    'W',
    'Ġaff',
    'luent',
    'k',
    'il',
    'ry',
    'lic',
    'ord',
    'ering',
    'Com',
    'puter',
    'Ġgru',
    'esome',
    'ost',
    'ics',
    'ĠUb',
    'isoft',
    'ĠKel',
    'ley',
    'Ġw',
    'rench',
    'Ġbourgeois',
    'ie',
    'IB',
    'LE',
    'ĠPrest',
    'on',
    'w',
    'orn',
    'ar',
    'ist',
    'reat',
    'ing',
    'Ġst',
    'ained',
    'ar',
    'ine',
    'Ġsl',
    'ime',
    'EN',
    'N',
    'Ġche',
    'sts',
    'Ġground',
    'water',
    'ann',
    'ot',
    'ĠTr',
    'ay',
    'ĠLoc',
    'ke',
    'ĠC',
    'TR',
    'Ġd',
    'udes',
    'ĠEx',
    'ternal',
    'ĠDec',
    'oder',
    'Ġpar',
    'amed',
    'ĠMed',
    'line',
    '80',
    '9',
    'ĠD',
    'inner',
    'rup',
    'al',
    'g',
    'z',
    'ĠG',
    'um',
    'ĠDem',
    'o',
    'j',
    'ee',
    'Ġd',
    'h',
    'ber',
    'man',
    'arch',
    's',
    'Ġen',
    'qu',
    'ĠEp',
    'stein',
    'Ġdevast',
    'ation',
    'Ġfriends',
    'hips',
    'ĠAr',
    'd',
    'Ġ23',
    '1',
    'ĠRub',
    'in',
    'ĠDist',
    'ance',
    'Ġsp',
    'urred',
    'Ġd',
    'ossier',
    'Ġover',
    'looking',
    '\\\\\\\\\\\\\\\\',
    '\\\\\\\\\\\\\\\\',
    'Fore',
    'st',
    'ĠCom',
    'es',
    '\\',
    '",',
    'ĠIran',
    'ians',
    'Ġf',
    'ixtures',
    'L',
    'aughs',
    'Ġcur',
    'ry',
    'ĠKing',
    'ston',
    'Ġsqu',
    'ash',
    'Ġcat',
    'alogue',
    'Ġabnormal',
    'ities',
    'Ġdigest',
    'ive',
    '....',
    '.....',
    'Ġsubord',
    'inate',
    'og',
    'ly',
    'Ġ24',
    '9',
    'M',
    'iddle',
    'Ġmass',
    'ac',
    'Ġburg',
    'ers',
    'Ġdown',
    'stairs',
    'Ġ19',
    '31',
    '39',
    '4',
    'ĠV',
    'G',
    'Ġl',
    'asers',
    'ĠS',
    'ikh',
    'ĠAlex',
    'a',
    'der',
    'ived',
    'Ġcycl',
    'ist',
    'ãģ®',
    'éŃĶ',
    'onel',
    'iness',
    '!!!!',
    '!!!!',
    'Ġbuff',
    's',
    'leg',
    'ate',
    'Ġrap',
    'ing',
    'Ġrecomm',
    'ending',
    'ro',
    'red',
    'Ġmult',
    'icultural',
    'un',
    'ique',
    'Ġbusiness',
    'men',
    'Ġune',
    'asy',
    'ĠM',
    'AP',
    'Ġdisp',
    'ersed',
    'cipl',
    'ine',
    'J',
    'ess',
    'ĠK',
    'erala',
    'å',
    '§',
    'Ġabst',
    'raction',
    'Sur',
    'v',
    'U',
    'h',
    'Ġprin',
    'ters',
    'ij',
    'a',
    'ow',
    'der',
    'Ġanalog',
    'ous',
    'ĠA',
    'SP',
    'af',
    'er',
    'Ġunfold',
    'ed',
    'Ġlevel',
    'ing',
    'Ġbre',
    'ached',
    'ĠH',
    'earing',
    'Ġn',
    'at',
    'Ġtransl',
    'ating',
    'crit',
    'ical',
    'Ġant',
    'agonist',
    'ĠYes',
    'terday',
    'Ġfuzz',
    'y',
    'w',
    'ash',
    'm',
    'ere',
    'Ġbe',
    'wild',
    'ĠM',
    'ae',
    'V',
    'irgin',
    'ph',
    'rase',
    'Ġsign',
    'aled',
    'ĠH',
    'IGH',
    'Ġprot',
    'ester',
    'Ġgar',
    'ner',
    'unk',
    'nown',
    'Ġk',
    'ay',
    'Ġabduct',
    'ed',
    'Ġst',
    'alking',
    'am',
    'n',
    'Ġdes',
    'erving',
    'ĠR',
    'iv',
    'ĠJ',
    'orge',
    'Ġscratch',
    'ing',
    'ĠS',
    'aving',
    'ip',
    'ing',
    'Ġte',
    'ase',
    'Ġmission',
    'ary',
    'ĠMor',
    'row',
    'T',
    'IME',
    'P',
    'resent',
    'Ġchem',
    'otherapy',
    'tern',
    'ess',
    'ĠH',
    'omes',
    'ĠP',
    'urdue',
    'Ġst',
    'aunch',
    'ĠWhit',
    'ney',
    'ĠTH',
    'ERE',
    'Î',
    '¼',
    'iat',
    'us',
    'ĠErn',
    'est',
    'ĠDe',
    'ploy',
    'Ġcove',
    'ted',
    'F',
    'ML',
    'ĠDial',
    'ogue',
    'Ġex',
    'ited',
    'f',
    'ruit',
    'Ġner',
    'd',
    '":"',
    '","',
    'Ġv',
    'ivo',
    'ru',
    'ly',
    '4',
    '60',
    'ĠAm',
    'en',
    'rehens',
    'ible',
    'Ġâ',
    'ĺ',
    'D',
    'IR',
    'Ġad',
    'herence',
    'Ġche',
    'w',
    'ĠCo',
    'ke',
    'ĠSerge',
    'i',
    'dig',
    'ital',
    'ĠNe',
    'ck',
    'g',
    'ently',
    'enth',
    'al',
    '/',
    ')',
    'Ġwe',
    'ary',
    'Ġgu',
    'ise',
    'ĠConc',
    'ord',
    'ĠOn',
    'ion',
    'at',
    'cher',
    'Ġb',
    'inge',
    'ĠDirect',
    'ive',
    'Ġman',
    'ned',
    'ans',
    'k',
    'Ġill',
    'usions',
    'Ġbillion',
    'aires',
    '38',
    '3',
    'oly',
    'n',
    'odynam',
    'ic',
    'ĠWhe',
    'at',
    'ĠA',
    'lic',
    'Ġcol',
    'oured',
    'ĠN',
    'AFTA',
    'ab',
    'o',
    'Ġmac',
    'ros',
    'ind',
    'ependent',
    's',
    'weet',
    'Ġsp',
    'ac',
    'ĠK',
    'abul',
    'Ġ',
    'Ä',
    'em',
    'e',
    'Ġdict',
    'ated',
    'Ġsh',
    'outs',
    '=',
    '{',
    'Ġr',
    'ipping',
    'ĠSh',
    'ay',
    'ĠCr',
    'icket',
    'direct',
    'ed',
    'Ġanalys',
    'ed',
    'ĠWAR',
    'RANT',
    'ag',
    'ons',
    'ĠBlaz',
    'ers',
    'Ġche',
    'ered',
    'Ġar',
    'ithmetic',
    'ĠTan',
    'z',
    '37',
    '3',
    'ĠFl',
    'ags',
    'Ġ29',
    '5',
    'Ġw',
    'itches',
    'ĠIn',
    'cluded',
    'ĠG',
    'ained',
    'ĠBl',
    'ades',
    'G',
    'am',
    'ĠSam',
    'antha',
    'ĠAtl',
    'antis',
    'ĠPr',
    'att',
    'Ġspo',
    'iled',
    'ĠI',
    'B',
    'ĠRam',
    'irez',
    'Pro',
    'bably',
    're',
    'ro',
    'ĠN',
    'g',
    'ĠWar',
    'lock',
    't',
    'p',
    'Ġover',
    'he',
    'Ġadministr',
    'ations',
    'Ġt',
    'int',
    'Ġreg',
    'iment',
    'Ġpist',
    'ols',
    'Ġblank',
    'ets',
    'Ġep',
    'ist',
    'Ġbowl',
    's',
    'Ġhydra',
    'ulic',
    'Ġde',
    'an',
    'Ġj',
    'ung',
    'Ġasc',
    'end',
    '70',
    '5',
    'ĠSant',
    'iago',
    'Ã',
    '®',
    'Ġun',
    'avoid',
    'ĠSh',
    'aman',
    're',
    'b',
    'Ġstem',
    'ming',
    '99',
    '8',
    'ĠM',
    'G',
    'st',
    'icks',
    'esthes',
    'ia',
    'ER',
    'O',
    'Ġmor',
    'bid',
    'ĠGr',
    'ill',
    'ĠP',
    'oe',
    'any',
    'l',
    'Ġdele',
    'ting',
    'ĠSurve',
    'illance',
    'Ġdirect',
    'ives',
    'Ġiter',
    'ations',
    'ĠR',
    'ox',
    'ĠMil',
    'ky',
    'F',
    'ather',
    'Ġpat',
    'ented',
    '44',
    '7',
    'Ġprec',
    'ursor',
    'Ġm',
    'aiden',
    'ĠP',
    'hen',
    'ĠVe',
    'gan',
    'ĠPat',
    'ent',
    'K',
    'elly',
    'Redd',
    'itor',
    'Ġn',
    'ods',
    'Ġvent',
    'ilation',
    'ĠSchwar',
    'z',
    'Ġw',
    'izards',
    'Ġomin',
    'ous',
    'ĠHe',
    'ads',
    'ĠB',
    'G',
    'Ġl',
    'umber',
    'ĠSp',
    'iel',
    'Ġis',
    'Enabled',
    'Ġancest',
    'ral',
    'ĠSh',
    'ips',
    'Ġwrest',
    'ler',
    'ph',
    'i',
    'Ġy',
    'uan',
    'ĠRebell',
    'ion',
    'Ġice',
    'berg',
    'Ġmag',
    'ically',
    'Ġdivers',
    'ion',
    'ar',
    'ro',
    'yth',
    'm',
    'ĠR',
    'iders',
    'ĠRob',
    'bie',
    'ĠK',
    'ara',
    'ĠMain',
    'tenance',
    'ĠHer',
    'b',
    'Ġhar',
    'ms',
    'p',
    'acked',
    'ĠFe',
    'instein',
    'Ġmarry',
    'ing',
    'Ġbl',
    'ending',
    'ĠR',
    'ates',
    'Ġ18',
    '80',
    'Ġwr',
    'ink',
    'ĠUn',
    'ch',
    'ĠTor',
    'ch',
    'desc',
    'ribed',
    'Ġhuman',
    'oid',
    'ilit',
    'ating',
    'ĠCon',
    'v',
    'ĠFe',
    'ld',
    'IGH',
    'TS',
    'Ġwhistlebl',
    'ower',
    'ort',
    'mund',
    'ets',
    'y',
    'arre',
    'tt',
    'ĠMon',
    'o',
    'ĠI',
    'ke',
    'ĠC',
    'NBC',
    'ĠW',
    'AY',
    'ĠMD',
    'MA',
    'ĠIndividual',
    's',
    'Ġsupplement',
    'al',
    'Ġpower',
    'house',
    'ĠSt',
    'ru',
    'F',
    'ocus',
    'aph',
    'ael',
    'ĠCol',
    'leg',
    'att',
    'i',
    'Z',
    'A',
    'Ġp',
    'erenn',
    'ĠSign',
    'ature',
    'ĠRod',
    'ney',
    'Ġcub',
    'es',
    'idd',
    'led',
    'ĠD',
    'ante',
    'ĠIN',
    'V',
    'iling',
    'ual',
    'ĠC',
    'th',
    'Ġso',
    'fa',
    'Ġintimid',
    'ate',
    'ĠR',
    'oe',
    'ĠDi',
    'plom',
    'ĠCount',
    'ries',
    'ays',
    'on',
    'Ġextrad',
    'ition',
    'Ġdis',
    'abling',
    'ĠCard',
    'iff',
    'Ġmemor',
    'andum',
    'ĠTr',
    'ace',
    'Ġ??',
    '?',
    'se',
    'ctor',
    'ĠRou',
    'hani',
    'ĠY',
    'ates',
    'ĠFree',
    'ze',
    'Ġbl',
    'adder',
    'M',
    'otor',
    'ĠProm',
    'ise',
    'ant',
    'asy',
    'Ġforesee',
    'able',
    'ĠC',
    'ologne',
    'cont',
    'ainer',
    'ĠTre',
    'es',
    'ĠG',
    'ors',
    'ĠSin',
    'clair',
    'Ġbar',
    'ring',
    'key',
    'e',
    'Ġsl',
    'ashed',
    'ĠStat',
    'istical',
    'é',
    'ĩ',
    'Ġâĸ',
    'º',
    'All',
    'ows',
    'Ġhum',
    'ility',
    'Ġdr',
    'illed',
    'ĠF',
    'urn',
    '44',
    '3',
    'Ġse',
    'wage',
    'Ġhome',
    'page',
    'Ġcour',
    'tyard',
    'Ġv',
    'ile',
    'Ġsubsid',
    'iaries',
    'aj',
    'o',
    'direct',
    'ory',
    'Ġam',
    'mon',
    'V',
    'ers',
    'charg',
    'es',
    'Ġ}',
    '}',
    'ĠCh',
    'ains',
    'Ġ24',
    '6',
    'n',
    'ob',
    'Ġper',
    'cept',
    'Ġg',
    'rit',
    'Ġfisher',
    'men',
    'ĠIraq',
    'is',
    'ĠDIS',
    'TR',
    'ĠF',
    'ULL',
    'ĠEval',
    'uation',
    'g',
    'raph',
    'at',
    'ial',
    'Ġcooper',
    'ating',
    'Ġmel',
    'an',
    'Ġenlight',
    'ened',
    'Ġal',
    'i',
    't',
    'ailed',
    'Ġsal',
    'ute',
    'Ġweak',
    'est',
    'ĠBull',
    'dogs',
    'U',
    'A',
    'ĠAll',
    'oy',
    'Ġsem',
    'en',
    'oc',
    'ene',
    'ĠWilliam',
    'son',
    's',
    'pr',
    ',',
    'âĢĶ',
    'ĠG',
    'F',
    'itt',
    'ens',
    'Be',
    'at',
    'ĠJ',
    'unk',
    'iph',
    'ate',
    'ĠFarm',
    'ers',
    'ĠBit',
    'coins',
    'ig',
    'ers',
    'd',
    'h',
    'ĠL',
    'oyal',
    'p',
    'ayer',
    'Ġentert',
    'ained',
    'Ġpenn',
    'ed',
    'Ġcoup',
    'on',
    'Que',
    'ue',
    'Ġweaken',
    'ing',
    'c',
    'arry',
    'Ġunderest',
    'imate',
    'Ġshoot',
    'out',
    'Ġcharism',
    'atic',
    'ĠProced',
    'ure',
    'Ġprud',
    'ent',
    'in',
    'ances',
    'Ġric',
    'hes',
    'Ġcort',
    'ical',
    'Ġstr',
    'ides',
    'Ġd',
    'rib',
    'ĠOil',
    'ers',
    '5',
    '40',
    'ĠPer',
    'form',
    'ĠBang',
    'kok',
    'Ġe',
    'uth',
    'S',
    'ER',
    'Ġsimpl',
    'istic',
    't',
    'ops',
    'camp',
    'aign',
    'Q',
    'uality',
    'Ġimpover',
    'ished',
    'ĠEisen',
    'hower',
    'Ġaug',
    'ment',
    'ĠH',
    'arden',
    'Ġinterven',
    'ed',
    'Ġlist',
    'ens',
    'ĠK',
    'ok',
    'Ġs',
    'age',
    'Ġrub',
    'bish',
    'ĠD',
    'ed',
    'Ġm',
    'ull',
    'pe',
    'lling',
    'Ġvide',
    'ot',
    'Produ',
    'ction',
    'D',
    'J',
    'm',
    'iah',
    'Ġadapt',
    'ations',
    'Ġmed',
    'ically',
    'Ġboard',
    'ed',
    'Ġarrog',
    'ance',
    'Ġscra',
    'pped',
    'Ġopp',
    'ress',
    'FORM',
    'ATION',
    'Ġj',
    'unction',
    '4',
    '15',
    'EE',
    'EE',
    'S',
    'kill',
    'Ġsub',
    'du',
    'ĠSug',
    'gest',
    'ĠP',
    'ett',
    'Ġle',
    'tt',
    'ĠMan',
    'ip',
    'ĠC',
    'af',
    'ĠCooper',
    'ation',
    'T',
    'her',
    'Ġreg',
    'ained',
    '¶',
    'æ',
    'ref',
    'lect',
    'Ġth',
    'ugs',
    'ĠShel',
    'by',
    'Ġdict',
    'ates',
    'ĠWe',
    'iner',
    'ĠH',
    'ale',
    'Ġbatt',
    'leground',
    's',
    'child',
    'Ġcond',
    'ol',
    'h',
    'unt',
    'osit',
    'ories',
    'Ġacc',
    'uses',
    'Fil',
    'ename',
    'Ġsh',
    'ri',
    'Ġmotiv',
    'ate',
    'Ġreflect',
    'ions',
    'N',
    'ull',
    'ĠL',
    'obby',
    '¥',
    'µ',
    'ĠS',
    'ATA',
    'ĠBack',
    'up',
    'Ñ',
    'ĥ',
    'n',
    'in',
    'ĠCor',
    'rection',
    'Ġju',
    'icy',
    'ut',
    'ra',
    'ĠP',
    'ric',
    'Ġrest',
    'raining',
    'ĠAir',
    'bnb',
    'ĠAr',
    'rest',
    'Ġappropri',
    'ations',
    'Ġsl',
    'opes',
    'Ġmans',
    'laughter',
    'Ġwork',
    'ings',
    'ĠH',
    'uss',
    'ĠF',
    'rey',
    'Le',
    'ave',
    'ĠHarm',
    'ony',
    'ĠF',
    'eder',
    'Ġ4',
    '30',
    'Ġt',
    'rench',
    'Ġglad',
    'ly',
    'Ġbull',
    'pen',
    'ĠG',
    'au',
    'b',
    'ones',
    'Ġgro',
    'ove',
    'Ġpre',
    'text',
    'ã',
    'ħĭ',
    'Ġtransm',
    'itter',
    'ĠComp',
    'onent',
    'Ġunder',
    'age',
    'ĠEm',
    'pires',
    'T',
    'ile',
    'Ġo',
    'y',
    'ĠMar',
    'vin',
    'ĠC',
    'AS',
    'Ġbl',
    'oss',
    'Ġrepl',
    'icated',
    'ĠMar',
    'iners',
    'Marc',
    'us',
    'ĠBl',
    'ocks',
    'Ġliber',
    'ated',
    'Ġbutter',
    'fly',
    'Fe',
    'el',
    'Ġfer',
    'mentation',
    'Ġyou',
    'tube',
    'Ġoff',
    'end',
    'ĠTer',
    'm',
    'res',
    'ist',
    'Ġcess',
    'ation',
    'Ġinsurg',
    'ency',
    'Ġb',
    'ir',
    'ĠRa',
    'ise',
    '59',
    '5',
    'Ġhypothes',
    'es',
    '50',
    '2',
    'Ġpl',
    'aque',
    'ocr',
    'at',
    'Ġjack',
    'ets',
    'ĠHuff',
    'Post',
    'am',
    'ong',
    'Ġconf',
    'er',
    '48',
    '7',
    'ĠL',
    'illy',
    'Ġadapt',
    'ing',
    'ĠF',
    'ay',
    'Ġsh',
    'oved',
    've',
    'c',
    'Ġref',
    'ine',
    'Ġg',
    'on',
    'Ġgun',
    'men',
    'z',
    'ai',
    'ĠShut',
    'tle',
    'ĠI',
    'zan',
    'Ġ19',
    '13',
    'Ġple',
    'thora',
    'Â·',
    'Â·',
    'Ġ5',
    '10',
    'Ġp',
    'uberty',
    'Ġ24',
    '1',
    'ĠWe',
    'alth',
    'ĠAl',
    'ma',
    'ĠM',
    'EM',
    'ĠAd',
    'ults',
    'C',
    'as',
    'pr',
    'ison',
    'R',
    'ace',
    'Ġwater',
    'proof',
    'Ġathlet',
    'icism',
    'Ġcapital',
    'ize',
    'ĠJu',
    'ice',
    'Ġillum',
    'inated',
    'ĠP',
    'ascal',
    'Ġirrit',
    'ation',
    'ĠWitness',
    'es',
    'ad',
    'le',
    'ĠAst',
    'ro',
    'Ġf',
    'ax',
    'ĠEl',
    'vis',
    'Prim',
    'ary',
    'ĠL',
    'ich',
    'ĠEl',
    'ves',
    'Ġres',
    'iding',
    'Ġst',
    'umble',
    '3',
    '19',
    'ĠP',
    'KK',
    'Ġadvers',
    'aries',
    'D',
    'OS',
    'ĠR',
    'itual',
    'Ġsm',
    'ear',
    'Ġar',
    'son',
    'ident',
    'al',
    'Ġsc',
    'ant',
    'Ġmon',
    'archy',
    'Ġhal',
    'ftime',
    'Ġresid',
    'ue',
    'Ġind',
    'ign',
    'ĠSh',
    'aun',
    'ĠEl',
    'm',
    'aur',
    'i',
    'A',
    'ff',
    'W',
    'ATCH',
    'ĠLy',
    'on',
    'hel',
    'ps',
    '36',
    '1',
    'Ġlobby',
    'ist',
    'Ġdimin',
    'ishing',
    'Ġout',
    'breaks',
    'Ġgo',
    'ats',
    'f',
    'avorite',
    'ĠN',
    'ah',
    'son',
    'ian',
    'ĠBo',
    'oster',
    'Ġsand',
    'box',
    'ĠF',
    'are',
    'ĠMalt',
    'a',
    'Ġatt',
    'Rot',
    'ĠM',
    'OR',
    'ld',
    'e',
    'Ġnavig',
    'ating',
    'T',
    'ouch',
    'Ġunt',
    'rue',
    'ĠDis',
    'aster',
    'Ġl',
    'udicrous',
    'Pass',
    'word',
    'ĠJ',
    'FK',
    'blog',
    'spot',
    '4',
    '16',
    'ĠUN',
    'DER',
    'ern',
    'al',
    'Ġdelay',
    'ing',
    'T',
    'OP',
    'Ġimpl',
    'ants',
    'ĠAV',
    'G',
    'ĠH',
    'uge',
    'att',
    'r',
    'Ġjournal',
    'istic',
    'ĠPe',
    'yton',
    'ĠI',
    'A',
    'R',
    'ap',
    'go',
    'al',
    'ĠProgram',
    'me',
    'Ġsm',
    'ashing',
    'w',
    'ives',
    'print',
    'ln',
    'ĠPl',
    'ague',
    'in',
    'us',
    'EE',
    'P',
    'Ġcru',
    'iser',
    'ĠPar',
    'ish',
    'umin',
    'ium',
    'Ġoccup',
    'ants',
    'ĠJ',
    'ihad',
    'm',
    'op',
    'Ġp',
    'int',
    'Ġhe',
    'ct',
    'ĠMe',
    'cca',
    'direct',
    'or',
    'ĠFund',
    'ing',
    'ĠM',
    'ixed',
    'Ġst',
    'ag',
    'T',
    'ier',
    'Ġg',
    'ust',
    'Ġbright',
    'ly',
    'ors',
    'i',
    'Ġup',
    'hill',
    'R',
    'D',
    'Ġles',
    'ions',
    'ĠBund',
    'y',
    'liv',
    'ious',
    'Ġbi',
    'ologist',
    'ĠFac',
    'ulty',
    'ĠAuthor',
    'ization',
    'Ġ24',
    '4',
    'All',
    'ow',
    'ï',
    '¸',
    'ĠGi',
    'ul',
    'Ġpert',
    'inent',
    'ot',
    'aur',
    'es',
    'se',
    'ĠRo',
    'of',
    'Ġunman',
    'ned',
    '35',
    '1',
    'ĠSh',
    'ak',
    'ĠO',
    'rient',
    'Ġend',
    'anger',
    'D',
    'ir',
    'Ġrepl',
    'en',
    'ed',
    'ient',
    'Ġtail',
    'or',
    'Ġgad',
    'gets',
    'Ġaud',
    'ible',
    'âĺ',
    'Ĩ',
    'N',
    'ice',
    'Ġbomb',
    'ard',
    'ĠR',
    'ape',
    'Ġdef',
    'iance',
    'ĠTW',
    'O',
    'ĠFilip',
    'ino',
    'Ġunaff',
    'ected',
    'erv',
    'atives',
    'Ġso',
    'ared',
    'ĠBol',
    'ton',
    'Ġcomprom',
    'ising',
    'ĠBrew',
    'ers',
    'R',
    'AL',
    'ĠA',
    'HL',
    'icy',
    'cle',
    'Ġv',
    'ampires',
    'Ġdi',
    'pped',
    'oy',
    'er',
    'ĠX',
    'III',
    'Ġsidew',
    'ays',
    'ĠW',
    'aste',
    'ĠD',
    'iss',
    'ĠâĶľ',
    'âĶĢâĶĢ',
    '$',
    '.',
    'Ġhabit',
    'ats',
    'ĠBe',
    'ef',
    'tr',
    'uth',
    'tr',
    'ained',
    'spl',
    'it',
    'R',
    'us',
    'And',
    'y',
    'ĠB',
    'ram',
    'RE',
    'P',
    'p',
    'id',
    'è£',
    'ħ',
    'ĠMut',
    'ant',
    'An',
    'im',
    'ĠMar',
    'ina',
    'Ġfut',
    'ile',
    'hig',
    'hest',
    'f',
    'requency',
    'Ġepile',
    'psy',
    'Ġcop',
    'ing',
    'Ġconc',
    'ise',
    'Ġtr',
    'acing',
    'ĠS',
    'UN',
    'pan',
    'el',
    'ĠSoph',
    'ie',
    'ĠCrow',
    'ley',
    'ĠAd',
    'olf',
    'ĠShoot',
    'er',
    'Ġsh',
    'aky',
    'ĠI',
    'G',
    'ĠL',
    'ies',
    'ĠBar',
    'ber',
    'p',
    'kg',
    'Ġupt',
    'ake',
    'Ġpred',
    'atory',
    'UL',
    'TS',
    '/',
    '**',
    'Ġintox',
    'icated',
    'ĠWest',
    'brook',
    'od',
    'der',
    'he',
    'ment',
    'Ġbas',
    'eman',
    'AP',
    'D',
    'st',
    'orage',
    'ĠFif',
    'ty',
    'ed',
    'itor',
    'G',
    'EN',
    'UT',
    'ION',
    'ir',
    'ting',
    'Ġse',
    'wing',
    'r',
    'ift',
    'Ġag',
    'ony',
    'ĠS',
    'ands',
    'Ġ25',
    '4',
    'C',
    'ash',
    'Ġl',
    'odge',
    'Ġp',
    'unt',
    'N',
    'atural',
    'ĠIde',
    'as',
    'Ġerrone',
    'ous',
    'ĠSens',
    'or',
    'ĠHann',
    'ity',
    'Ġ19',
    '21',
    'Ġm',
    'ould',
    'ĠG',
    'on',
    'kay',
    'a',
    'Ġanonym',
    'ously',
    'ĠK',
    'EY',
    'Ġsim',
    'ulator',
    'W',
    'inter',
    'Ġstream',
    'ed',
    '50',
    '7',
    '?',
    '",',
    'Ġte',
    'ased',
    'Ġco',
    'efficient',
    'Ġwart',
    'ime',
    'ĠTH',
    'R',
    "'",
    "'.",
    'ĠBank',
    'ing',
    'mp',
    'ire',
    'Ġf',
    'andom',
    'Ġl',
    'ia',
    'G',
    'a',
    'Ġdown',
    'hill',
    'Ġinterpre',
    'ting',
    'Ind',
    'ividual',
    'N',
    'orm',
    'Ġjealous',
    'y',
    'bit',
    'coin',
    'Ġple',
    'asures',
    'ĠToy',
    's',
    'ĠChev',
    'rolet',
    'ĠAd',
    'visor',
    'IZ',
    'E',
    'Ġrecept',
    'ions',
    '70',
    '6',
    'C',
    'ro',
    'Ġ26',
    '2',
    'Ġcit',
    'rus',
    'ir',
    'u',
    'Review',
    'er',
    'ject',
    'ed',
    'U',
    'ES',
    'an',
    'z',
    '19',
    '81',
    'ĠWork',
    'er',
    'Ġcompl',
    'ied',
    'ores',
    'cent',
    'contin',
    'ental',
    'T',
    'on',
    'ĠPr',
    'ism',
    'ĠShe',
    'ep',
    'Ġ28',
    '8',
    'n',
    'ox',
    'ĠV',
    'og',
    'O',
    'rd',
    'Ġreal',
    'ms',
    'te',
    'k',
    'Ġirrig',
    'ation',
    'Ġbicy',
    'cles',
    'Ġelectron',
    'ically',
    'p',
    'oly',
    't',
    'all',
    '()',
    ');',
    'Ġaest',
    'hetics',
    'ĠInteg',
    'rated',
    'Expl',
    'ore',
    'Ġd',
    'unk',
    '47',
    '6',
    'p',
    'ain',
    'ĠJac',
    'ques',
    'ĠD',
    'mit',
    'Fram',
    'es',
    'Ġreun',
    'ited',
    'Ġhum',
    'id',
    'D',
    'ro',
    'P',
    'olitical',
    'Ġyouth',
    'ful',
    'Ġent',
    'ails',
    'Ġmosqu',
    'ito',
    '36',
    '3',
    'spe',
    'cies',
    'Ġcoord',
    'inating',
    'ĠMay',
    'hem',
    'ĠMagn',
    'us',
    'M',
    'ount',
    'Impro',
    'ved',
    'ĠST',
    'ATE',
    'ATT',
    'LE',
    'Ġflow',
    'ed',
    'Ġtack',
    'led',
    'Ġfashion',
    'ed',
    'Ġre',
    'organ',
    'iv',
    'ari',
    'f',
    'inger',
    'Ġreluct',
    'antly',
    'et',
    'ting',
    'ĠV',
    'and',
    'you',
    'ng',
    'ĠGar',
    'land',
    'Ġpresum',
    'ption',
    'Ġamen',
    'ities',
    'ĠPle',
    'asant',
    'on',
    'ential',
    'ĠO',
    'xy',
    'Ġmor',
    'als',
    'ĠY',
    'ah',
    'Read',
    'y',
    'Sim',
    'on',
    'En',
    'h',
    'D',
    'emon',
    'Ġcl',
    'ich',
    'Mon',
    'itor',
    'ĠD',
    'U',
    'Ġwel',
    'comes',
    'Ġstand',
    'out',
    'Ġdread',
    'ful',
    'Ġban',
    'anas',
    'Ġball',
    'oons',
    'h',
    'ooting',
    'bas',
    'ic',
    'Ġsuff',
    'ix',
    'Ġd',
    'uly',
    'can',
    'o',
    'Ch',
    'ain',
    'at',
    'os',
    'Ġgeop',
    'olitical',
    'Ġ(',
    '&',
    'ĠGem',
    'ini',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'ÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤÃĥÃĤ',
    'Ġacqu',
    'itted',
    'L',
    'uck',
    'prot',
    'ect',
    '10',
    '24',
    'Ġsc',
    'arcity',
    'Ġmind',
    'fulness',
    'ec',
    'ided',
    'D',
    'N',
    'pr',
    'ime',
    'ĠPres',
    'idents',
    'ĠVID',
    'EO',
    'Ġ(',
    'âĪĴ',
    'add',
    'ock',
    'N',
    'OR',
    'ĠP',
    'ru',
    'p',
    'un',
    'ĠL',
    'OL',
    '))',
    '))',
    'ĠL',
    'iqu',
    'ĠS',
    'AS',
    'Ġsty',
    'ling',
    'Ġpunish',
    'ments',
    'Ġnum',
    'b',
    'Ġasc',
    'ertain',
    'ĠRock',
    'ies',
    'f',
    'lu',
    'Th',
    'umbnail',
    'Ġperpet',
    'rated',
    'ĠSem',
    'i',
    'Ġdis',
    'arm',
    'ĠOld',
    'er',
    'ĠEx',
    'ception',
    'Ġexponent',
    'ially',
    'ĠCommun',
    'ities',
    'Ġabol',
    'ish',
    'ĠPart',
    'ner',
    'pt',
    'oms',
    'Ġ7',
    '77',
    'ĠFo',
    'ley',
    'ĠC',
    'ases',
    'Ġgre',
    'ase',
    'ĠReb',
    'irth',
    'G',
    'round',
    'Ġ;',
    ')',
    'ĠDoct',
    'rine',
    'ik',
    'ini',
    'Y',
    'e',
    'ĠBl',
    'ossom',
    'Ġpers',
    'ists',
    'b',
    'ill',
    'Ġinf',
    'usion',
    'Ġbud',
    'dies',
    '9',
    '11',
    'ĠPat',
    'ient',
    'Ġdem',
    'os',
    'Ġacquaint',
    'ance',
    'ĠP',
    'aw',
    'at',
    'ari',
    'Ġx',
    'ml',
    'Ġfasc',
    'ination',
    'ĠSer',
    've',
    'Ï',
    'Ĥ',
    'br',
    'anded',
    'Ġa',
    'z',
    'Return',
    's',
    'Ġover',
    'shadow',
    'Ġro',
    'am',
    'Ġspeed',
    'y',
    'n',
    'umbered',
    'hel',
    'ial',
    'Ġdisc',
    'iple',
    'Ġass',
    'urances',
    'g',
    'iven',
    'pect',
    'ing',
    'ĠN',
    'atalie',
    'çĶ',
    '°',
    'Ġmosquit',
    'oes',
    'rote',
    'in',
    'Ġnumer',
    'ic',
    'Ġindepend',
    'ents',
    'Ġtrans',
    'itional',
    'Ġreaction',
    'ary',
    'ĠMech',
    'dragon',
    'do',
    'ctor',
    'Ġshort',
    'est',
    'Ġsequ',
    'ential',
    'ĠB',
    'ac',
    'ĠAccount',
    's',
    'ãģ',
    'Į',
    'ach',
    'y',
    'ract',
    'ive',
    'ĠReg',
    'iment',
    'Ġbreat',
    'htaking',
    'ffic',
    'iency',
    'ĠB',
    'ates',
    'Ġ3',
    '11',
    'Ġward',
    'robe',
    'ft',
    's',
    'ĠBer',
    'k',
    'Sim',
    'ply',
    'ĠRivers',
    'ide',
    'iver',
    'ing',
    'ident',
    'ial',
    'lu',
    'cent',
    'Ġen',
    'riched',
    'ĠCon',
    'ver',
    'ĠG',
    'iving',
    'ãĥ',
    'Ļ',
    'Ġlegal',
    'ize',
    'ĠF',
    'TC',
    'Ġfre',
    'aking',
    'M',
    'ix',
    'Ġter',
    'restrial',
    'es',
    'ian',
    'ci',
    'ents',
    'W',
    'ing',
    'LO',
    'AD',
    'Ġled',
    'ge',
    'ĠViol',
    'ent',
    'ĠMet',
    'all',
    'Ġ30',
    '8',
    'Ġs',
    'outheastern',
    'hett',
    'o',
    'M',
    'eat',
    'Ġslow',
    'down',
    'Ġret',
    'reated',
    'Jere',
    'my',
    'end',
    'as',
    '****',
    '*',
    'er',
    'ic',
    'Ġre',
    'ins',
    'opp',
    'able',
    'ĠHuman',
    'ity',
    'ear',
    'ances',
    'rig',
    'an',
    'C',
    'amera',
    'Ġwa',
    'ivers',
    's',
    'oc',
    'Ġalter',
    'ation',
    'trans',
    'form',
    'ĠC',
    'emetery',
    '50',
    '6',
    'Ġindef',
    'inite',
    'Ġstim',
    'ulating',
    'y',
    'g',
    '60',
    '3',
    'ĠS',
    'op',
    'Ġdescript',
    'ive',
    'Ph',
    'ase',
    'ĠEd',
    'mund',
    'Ġpneum',
    'onia',
    'vent',
    'us',
    'A',
    'mb',
    'Ġlabor',
    'atories',
    'ĠEx',
    'clusive',
    'ug',
    'ar',
    'W',
    'ere',
    'Ġmalf',
    'unction',
    'Ġhomosexual',
    's',
    'Ġ----',
    '---',
    'un',
    'i',
    'Ġturb',
    'ines',
    'ĠEqu',
    'ity',
    'D',
    'u',
    'Ġmind',
    'ed',
    'ĠR',
    'H',
    'ĠBlack',
    'hawks',
    'Ġfe',
    'ats',
    'Ġ17',
    '00',
    're',
    'pl',
    '36',
    '2',
    'lad',
    'en',
    'Ġindisp',
    'ensable',
    'ly',
    'ss',
    'tt',
    'i',
    'Ġre',
    'el',
    'Ġdiver',
    'ted',
    'Ġlik',
    'eness',
    'Ġsubscript',
    'ions',
    'Ġfing',
    'ert',
    'Ġfil',
    'thy',
    'dest',
    'ruct',
    'd',
    'raft',
    'ĠBernard',
    'ino',
    'l',
    'aunch',
    'Ġper',
    'plex',
    'ĠS',
    'UM',
    'car',
    'b',
    'Ġswe',
    'ater',
    'ĠVent',
    'ure',
    'ĠJ',
    'ag',
    'ĠCele',
    'b',
    'ĠV',
    'oters',
    'Ġstead',
    'fast',
    'Ġathlet',
    'ics',
    'ĠHans',
    'on',
    'ĠDr',
    'ac',
    'Tr',
    'acker',
    'Ġcomm',
    'end',
    'ĠPres',
    'idency',
    'ĠD',
    'ID',
    'in',
    'formed',
    'Ġweb',
    'page',
    'P',
    'retty',
    'Ġforce',
    'fully',
    'ãĥĥ',
    'ãĤ¯',
    'Ġrel',
    'ocation',
    'Ġsat',
    'ire',
    'â',
    'ī',
    'ĠSunder',
    'land',
    'æ',
    'Ħ',
    'V',
    'oice',
    '????',
    '????',
    'Ġinform',
    'ant',
    'Ġbow',
    'el',
    'ĠUn',
    'iform',
    'Ġ',
    '..."',
    'Ġpur',
    'ge',
    'Ġpic',
    'nic',
    'ĠU',
    'mb',
    'ĠU',
    'PDATE',
    'ĠSapp',
    'hire',
    'ĠSt',
    'all',
    'le',
    'arn',
    'Ġobject',
    'ively',
    'Ġob',
    'liter',
    'Ġlooph',
    'ole',
    'Ġjour',
    'neys',
    'Ġo',
    'mission',
    'Pro',
    's',
    'ĠSid',
    'ney',
    'pl',
    'oma',
    'Ġspray',
    'ed',
    'Ġg',
    'uru',
    'Ġtra',
    'itor',
    'Ġtim',
    'et',
    'Ġsn',
    'apping',
    'ĠSe',
    'vent',
    'urn',
    'al',
    'ĠUk',
    'ip',
    'Ġb',
    'owed',
    'por',
    'al',
    'l',
    'iberal',
    'R',
    'os',
    'Quest',
    'ions',
    'i',
    'OS',
    'Ġsummar',
    'ize',
    'ST',
    'AT',
    'Ġ18',
    '50',
    'ap',
    'est',
    'Ġl',
    'ender',
    'ĠVari',
    'able',
    'br',
    'inging',
    'ĠL',
    'ORD',
    ',',
    ')',
    'Ġcollaps',
    'es',
    'x',
    'iety',
    'ĠN',
    'ed',
    'Y',
    'D',
    'ĠSch',
    'a',
    'Ġantib',
    'ody',
    'Ġdis',
    'band',
    'y',
    're',
    'ill',
    'usion',
    'Ġro',
    'ver',
    's',
    'hed',
    'ĠHiro',
    'sh',
    'cc',
    'i',
    'Ġcal',
    'am',
    'ĠMort',
    'on',
    'P',
    'interest',
    'Ġ19',
    '28',
    'ĠE',
    'uras',
    'ord',
    'es',
    'Ġf',
    'ences',
    'ĠIn',
    'ventory',
    'ĠVal',
    'encia',
    'ĠU',
    'd',
    'ĠT',
    'iff',
    'Ġsqu',
    'e',
    'Ġqu',
    'otation',
    'Ġtroubles',
    'ome',
    'er',
    'ker',
    'QU',
    'EST',
    'ĠKing',
    'doms',
    's',
    'outh',
    'Ġle',
    'vy',
    'Pr',
    'ince',
    'ĠSt',
    'ing',
    'Ġnick',
    'named',
    'Ġapp',
    'e',
    'Ġphot',
    'ographic',
    'Ġcorp',
    'us',
    're',
    'ference',
    'ĠT',
    'rog',
    'U',
    'nt',
    ')',
    '=(',
    'ĠLat',
    'via',
    'Ġactiv',
    'ating',
    'Ġlicense',
    'e',
    'Ġdispar',
    'ities',
    'ĠNews',
    'letter',
    'ãĥĥ',
    'ãĥĪ',
    'Ġfree',
    'ing',
    'ĠJe',
    'ep',
    'ĠPer',
    'ception',
    'ins',
    'k',
    'Ġsil',
    'icone',
    'ĠHay',
    'den',
    'Le',
    'an',
    'ĠSuz',
    'uki',
    'ibr',
    'arian',
    '66',
    '8',
    'Ġsp',
    'or',
    'Ġcorrel',
    'ations',
    'ag',
    'hetti',
    'Ġtu',
    'ber',
    'ĠIP',
    'CC',
    'il',
    'us',
    'ĠV',
    'u',
    'Ġwealth',
    'iest',
    'ĠCarb',
    'uncle',
    'an',
    'za',
    'Ġfool',
    'ed',
    'ĠZ',
    'ur',
    'Ġd',
    'addy',
    'ran',
    'o',
    'il',
    'ian',
    'Ġknock',
    'out',
    'f',
    'man',
    'requ',
    'ired',
    'ĠWik',
    'ileaks',
    'ĠD',
    'uffy',
    'ON',
    'T',
    'Ġins',
    'ol',
    'ĠObject',
    's',
    'Ġb',
    'ou',
    'ĠNord',
    'ic',
    'ĠIns',
    'ert',
    'sc',
    'an',
    'Ġd',
    'ancers',
    'Ġid',
    'iots',
    'major',
    'ity',
    'ĠNev',
    'ille',
    'ĠFree',
    'BSD',
    'Ġt',
    'art',
    'pan',
    'ic',
    '69',
    '0',
    'Ġcoc',
    'oa',
    'Ġsam',
    'pled',
    'Ġlook',
    'up',
    'Ind',
    'ust',
    'Ġinject',
    'ions',
    'gen',
    're',
    'Ġa',
    'u',
    'Ġroad',
    'way',
    'Ġgen',
    'itals',
    'K',
    'ind',
    'ĠEx',
    'aminer',
    'ĠY',
    'az',
    'F',
    'resh',
    'Ġpar',
    'alysis',
    'ĠAl',
    'uminum',
    'Ġre',
    'ap',
    'ok',
    'Ã©',
    'Ġsl',
    'oppy',
    'ĠTun',
    'nel',
    'pos',
    'ium',
    'ner',
    'y',
    'en',
    'ic',
    'Ġher',
    'bal',
    'ĠOut',
    'er',
    'ĠBuild',
    'er',
    'Ġinc',
    'ur',
    'Ġide',
    'ologies',
    'Ġback',
    'ups',
    'cons',
    'uming',
    'ĠDet',
    'ect',
    'de',
    'ck',
    'ĠKN',
    'OW',
    'ĠG',
    'ret',
    'ĠM',
    'IC',
    'Ġtough',
    'ness',
    'ĠEx',
    'hibit',
    'Ġh',
    'ive',
    'L',
    'es',
    'ĠSCH',
    'OOL',
    'ĠAt',
    'ari',
    'ald',
    'e',
    'ĠN',
    'ull',
    'and',
    'estine',
    'm',
    'ouse',
    'Ġbrig',
    'ade',
    '48',
    '9',
    'Ġrev',
    'ol',
    'ĠLaw',
    'son',
    'ĠW',
    'ah',
    'op',
    'oly',
    'eb',
    'ted',
    'ĠS',
    'aunders',
    'Ġ3',
    '13',
    'ĠW',
    'inc',
    'Ġtab',
    'oo',
    'ĠHel',
    'met',
    'Ġw',
    'edge',
    'ch',
    'ip',
    'ĠT',
    'ina',
    'b',
    'g',
    'Ġinf',
    'uri',
    'r',
    'n',
    'Ġanomal',
    'ies',
    'ĠSy',
    'nc',
    'ĠEx',
    'am',
    'ĠComm',
    'it',
    'ĠDi',
    'ary',
    'ĠALS',
    'O',
    'ĠDe',
    'bor',
    'omed',
    'ical',
    'Ġcomprehens',
    'ion',
    '6',
    '55',
    'Ġempower',
    'ing',
    'Ġ',
    'ire',
    'Ġju',
    'ices',
    'ĠE',
    'TH',
    'ĠBox',
    'ing',
    '="',
    '/',
    'Ġfacilit',
    'ated',
    'p',
    'oke',
    'ĠPars',
    'ons',
    'ĠMod',
    'er',
    'tra',
    'vel',
    'Ġcivil',
    'izations',
    'Ġliber',
    'tarians',
    'Ġrun',
    'e',
    'ĠCl',
    'arks',
    'at',
    'hed',
    'Ġcampaign',
    'ers',
    'ĠDis',
    'patch',
    'ĠFah',
    'renheit',
    'ĠCap',
    'com',
    '--------',
    '--',
    'Ġl',
    'ace',
    'Ġdr',
    'aining',
    'Ġl',
    'iner',
    'ĠArt',
    'ificial',
    'Ã©',
    'n',
    't',
    'ask',
    ']',
    ').',
    'ĠGM',
    'O',
    'ĠOper',
    'ator',
    'ord',
    'inary',
    'ĠInf',
    'luence',
    'ĠU',
    'ps',
    'Ġpot',
    'ency',
    'uss',
    'en',
    'osp',
    'ons',
    'ĠSw',
    'im',
    'ĠDead',
    'line',
    'Un',
    'ity',
    'Ġcul',
    'inary',
    'Ġenlight',
    'enment',
    'Ġwe',
    'arer',
    'Ġmin',
    'ed',
    'Ġp',
    'ly',
    'Ġinc',
    'est',
    'ĠDVD',
    's',
    'W',
    'alk',
    'B',
    'TC',
    'Tr',
    'ade',
    'Ġdev',
    'al',
    'ib',
    'and',
    'ĠOvers',
    'ight',
    'Palest',
    'inian',
    'Ġd',
    'art',
    'Ġm',
    'ul',
    'L',
    'R',
    'Ġrem',
    'ovable',
    'ĠReal',
    'ms',
    'ì',
    'Ŀ',
    'Ġmisc',
    'ar',
    'ĠV',
    'ulkan',
    '68',
    '5',
    'Ã¨',
    're',
    'ĠS',
    'ap',
    'Ġmer',
    'ging',
    'ĠCar',
    'ly',
    'che',
    'ster',
    'Ġbr',
    'isk',
    'Ġlux',
    'urious',
    'ĠGener',
    'ator',
    'Ġbit',
    'terness',
    'Ġed',
    'ible',
    'Ġ24',
    '3',
    'T',
    'G',
    'Ġrect',
    'angle',
    'With',
    'No',
    'bel',
    'ow',
    'J',
    'enn',
    'Ġdark',
    'est',
    'Ġh',
    'itch',
    'Ġdos',
    'age',
    'Ġsc',
    'aven',
    'ĠK',
    'eller',
    'ĠIllust',
    'rated',
    'Certain',
    'ly',
    'ĠMaver',
    'icks',
    'Marg',
    'inal',
    'Ġdiarr',
    'hea',
    'Ġenorm',
    'ously',
    'Ġ9',
    '99',
    'sh',
    'r',
    'qu',
    'art',
    'Ġadam',
    'ant',
    'ĠM',
    'ew',
    'Ġren',
    'ovation',
    'Ġcerv',
    'ical',
    'ĠPercent',
    'age',
    'en',
    'ers',
    'ĠKim',
    'ber',
    'Ġflo',
    'ats',
    'Ġde',
    'x',
    'ĠW',
    'itcher',
    'ĠSwan',
    'sea',
    'd',
    'm',
    'Ġsal',
    'ty',
    'y',
    'ellow',
    'Ġca',
    'pe',
    'ĠDr',
    'ain',
    'ĠPaul',
    'a',
    'ĠTol',
    'edo',
    'les',
    'i',
    'Mag',
    'azine',
    'ĠW',
    'ick',
    'ĠM',
    'n',
    'ĠA',
    'ck',
    'ĠR',
    'iding',
    'AS',
    'ON',
    'Ġhom',
    'ophobic',
    'AR',
    'P',
    'Ġwand',
    'ered',
    'C',
    'PU',
    'ood',
    'oo',
    'ĠP',
    'ipe',
    'Ġtight',
    'ening',
    'ĠBut',
    't',
    '3',
    '18',
    'Ġdesert',
    'ed',
    'S',
    'ession',
    'Ġfacilit',
    'ating',
    'J',
    'ump',
    'Ġemer',
    'gencies',
    'OW',
    'ER',
    'Ġexhaust',
    'ive',
    'ĠAF',
    'TER',
    'Ġheart',
    'beat',
    'ĠLab',
    'el',
    'ack',
    'y',
    'ĠCert',
    'ified',
    'ilt',
    'ration',
    'Z',
    'e',
    'ĠU',
    'tt',
    'Ġ13',
    '00',
    'Ġpres',
    'ume',
    'ĠDis',
    'p',
    'Ġsur',
    'ged',
    'Ġdoll',
    's',
    'Col',
    'umb',
    'Ġchim',
    'pan',
    'ĠR',
    'azor',
    'Ġt',
    'icks',
    'Ġcouncill',
    'or',
    'Ġpilgr',
    'image',
    'ĠReb',
    'els',
    'ĠQ',
    'C',
    'ĠA',
    'uction',
    'x',
    'ia',
    'ik',
    'k',
    'b',
    'red',
    'Ġinsert',
    'ion',
    'Ġco',
    'arse',
    'd',
    'B',
    'SE',
    'E',
    'ĠZ',
    'ap',
    'ĠF',
    'oo',
    'Ġcontem',
    'por',
    'ĠQuarter',
    'ly',
    'ot',
    'ions',
    'ĠAl',
    'chemist',
    'ĠT',
    'rey',
    'ĠDu',
    'o',
    'S',
    'weet',
    '80',
    '4',
    'ĠGi',
    'ov',
    'Ġfun',
    'n',
    'N',
    'in',
    'h',
    'off',
    'Ġram',
    'ifications',
    'Ġ19',
    '22',
    'ĠExper',
    'ts',
    'az',
    'es',
    'Ġgar',
    'ments',
    'ar',
    'ial',
    'ĠN',
    'ab',
    'Ġ25',
    '7',
    'ĠV',
    'ed',
    'Ġhum',
    'orous',
    'ĠPom',
    'pe',
    'Ġn',
    'ylon',
    'Ġlur',
    'king',
    'ĠSerge',
    'y',
    'ĠMatt',
    'is',
    'Ġmisogyn',
    'y',
    'ĠComp',
    'onents',
    'ĠWatch',
    'ing',
    'ĠF',
    'olk',
    'ract',
    'ical',
    'B',
    'ush',
    'Ġt',
    'aped',
    'Ġgroup',
    'ing',
    'Ġbe',
    'ads',
    'Ġ20',
    '48',
    'Ġcon',
    'du',
    'quer',
    'que',
    'Read',
    'ing',
    'Ġgriev',
    'ances',
    'Ult',
    'ra',
    'Ġend',
    'point',
    'H',
    'ig',
    'ĠSt',
    'atic',
    'ĠScar',
    'borough',
    'L',
    'ua',
    'ĠMess',
    'i',
    'a',
    'qu',
    'ĠPsy',
    'Net',
    'ĠR',
    'udd',
    'Ġa',
    'venue',
    'v',
    'p',
    'J',
    'er',
    'Ġsh',
    'ady',
    'ĠRes',
    'ist',
    'ĠArt',
    'emis',
    'Ġcare',
    'less',
    'Ġbro',
    'kers',
    'Ġtemper',
    'ament',
    'Ġ5',
    '20',
    'T',
    'ags',
    'ĠTurn',
    'ing',
    'Ġut',
    'tered',
    'Ġp',
    'edd',
    'Ġimpro',
    'vised',
    'Ġ:',
    '(',
    'Ġtab',
    'l',
    'Ġpl',
    'ains',
    '16',
    '00',
    'press',
    'ure',
    'ĠEss',
    'ence',
    'marg',
    'in',
    'friend',
    's',
    'ĠRest',
    'oration',
    'Ġpoll',
    'ut',
    'ĠPok',
    'er',
    'ĠAugust',
    'ine',
    'ĠC',
    'IS',
    'ĠSE',
    'AL',
    'or',
    'ama',
    'Ġth',
    'wart',
    'se',
    'ek',
    'Ġp',
    'agan',
    'Â',
    'º',
    'cp',
    'u',
    'Ġg',
    'arn',
    'Ġass',
    'ortment',
    'ĠI',
    'LCS',
    't',
    'ower',
    'Recomm',
    'ended',
    'Ġun',
    'born',
    'ĠRandom',
    'Redditor',
    'ĠRandomRedditor',
    'WithNo',
    'Ġparaly',
    'zed',
    'Ġeru',
    'ption',
    'Ġinter',
    'sect',
    'ĠSt',
    'oke',
    'ĠS',
    'co',
    'B',
    'ind',
    'å',
    '¾',
    'ĠP',
    'NG',
    'ĠNeg',
    'ative',
    'ĠNO',
    'AA',
    'Le',
    'on',
    'Ġall',
    'oy',
    'ĠL',
    'ama',
    'ĠD',
    'iversity',
    '5',
    '75',
    'Ġunderest',
    'imated',
    'ĠSc',
    'or',
    'Ġm',
    'ural',
    'Ġb',
    'usted',
    'so',
    'on',
    'l',
    'if',
    'Ġnone',
    'x',
    'Ġall',
    'ergy',
    'ĠUnder',
    'world',
    'ĠR',
    'ays',
    'ĠBl',
    'asio',
    'Ġh',
    'rs',
    'ĠD',
    'ir',
    'Ġ3',
    '27',
    'by',
    'ter',
    'Ġrepl',
    'acements',
    'Ġactiv',
    'ates',
    'ri',
    'ved',
    'M',
    'H',
    'Ġp',
    'ans',
    'ĠH',
    'I',
    'Ġlong',
    'itudinal',
    'Ġnu',
    'isance',
    'al',
    'er',
    'Ġsw',
    'ell',
    'ĠS',
    'igned',
    's',
    'ci',
    'ĠIs',
    'les',
    'ĠA',
    'GA',
    'Ġdef',
    'iant',
    'Ġson',
    'ic',
    'oc',
    'on',
    'K',
    'C',
    'ĠA',
    'im',
    't',
    'ie',
    'ah',
    'ah',
    'Ġm',
    'L',
    'D',
    'X',
    'Ġb',
    'isc',
    'ĠBill',
    'board',
    'ĠSY',
    'STEM',
    'NE',
    'Y',
    'ga',
    'ard',
    'Ġdist',
    'ressed',
    'former',
    'ly',
    'Al',
    'an',
    'Ġche',
    'fs',
    'Ġopt',
    'ics',
    'ĠC',
    'omet',
    'ĠAM',
    'C',
    'Ġredes',
    'igned',
    'irm',
    'ation',
    'Ġsight',
    'ings',
    '38',
    '2',
    '3',
    '11',
    'ĠW',
    'B',
    'Ġcont',
    'raction',
    'ĠT',
    'OTAL',
    'D',
    'ual',
    'Ġstart',
    'led',
    'Ġunderstand',
    'ably',
    'Ġsung',
    'lasses',
    'ETH',
    'OD',
    'Ġd',
    'ocker',
    'Ġsurf',
    'ing',
    'ĠH',
    'EL',
    'ĠSl',
    'ack',
    'ton',
    'es',
    'Ġsh',
    'alt',
    'Vis',
    'ual',
    '49',
    '8',
    'Dep',
    'artment',
    'c',
    'ussion',
    'Ġunrest',
    'ricted',
    'Ġt',
    'ad',
    'Ġre',
    'name',
    'employ',
    'ed',
    'Ġeduc',
    'ating',
    'Ġgrin',
    'ned',
    'bed',
    'room',
    'ĠActiv',
    'ities',
    'ĠV',
    'elvet',
    'ĠSW',
    'AT',
    'Ġsh',
    'uffle',
    'ig',
    'or',
    'Ġsatur',
    'ation',
    'F',
    'inding',
    'c',
    'ream',
    'ic',
    'ter',
    'Ġv',
    'odka',
    'tr',
    'acking',
    'te',
    'c',
    'Ġfore',
    'ground',
    'iest',
    'a',
    'Ġve',
    'hement',
    'ĠEC',
    'B',
    'ĠT',
    'ie',
    'E',
    'y',
    'Ġt',
    'urtles',
    'ĠRail',
    'road',
    'ĠKat',
    'z',
    'ĠFram',
    'es',
    'Ġmen',
    'ace',
    'ĠFell',
    'owship',
    'ĠEss',
    'ential',
    'ugg',
    'ish',
    'Ġdri',
    'p',
    'ch',
    'witz',
    'ĠKy',
    'oto',
    's',
    'b',
    'ĠN',
    'ina',
    'Param',
    'eter',
    'Ġal',
    'arms',
    'ĠCl',
    'aud',
    'Ġpione',
    'ering',
    'Ġchief',
    'ly',
    'ĠSc',
    'ream',
    'Col',
    'lection',
    'Ġthank',
    'fully',
    'ĠRonald',
    'o',
    'åŃ',
    'Ĳ',
    'st',
    'rip',
    'ĠDisney',
    'land',
    'com',
    'mercial',
    'See',
    'ing',
    'S',
    'oul',
    'Ġevac',
    'uate',
    'Ġc',
    'iv',
    'ĠAs',
    'he',
    'Ġdiv',
    'ides',
    'ĠD',
    'agger',
    'rehens',
    'ive',
    'Ġber',
    'ries',
    'ĠD',
    'F',
    'Ġs',
    'ushi',
    'Ġplur',
    'ality',
    'W',
    'I',
    'Ġdisadvant',
    'aged',
    'Ġbatt',
    'alion',
    'ob',
    'iles',
    '45',
    '1',
    'Ġcl',
    'ing',
    'Ġunden',
    'iable',
    'ĠL',
    'ounge',
    'Ġha',
    'unt',
    'p',
    'he',
    'Ġquant',
    'ify',
    'Ġdiff',
    'ered',
    'Ġ[*',
    ']',
    'ĠV',
    'iz',
    'c',
    'um',
    'sl',
    'ave',
    'Ġvide',
    'og',
    'Ġqu',
    'ar',
    'Ġbund',
    'les',
    'ĠAl',
    'onso',
    't',
    'ackle',
    'Ġneur',
    'onal',
    'Ġlandsl',
    'ide',
    'conf',
    'irmed',
    'ĠDep',
    'th',
    'Ġrenew',
    'ables',
    'B',
    'ear',
    'ĠMaced',
    'onia',
    'Ġjer',
    'seys',
    'Ġb',
    'unk',
    'ĠSp',
    'awn',
    'ĠControl',
    's',
    'ĠBuch',
    'anan',
    'Ġrobot',
    'ics',
    'Ġemphas',
    'izing',
    'ĠTut',
    'orial',
    'h',
    'yp',
    'ist',
    'on',
    'Ġmonument',
    'al',
    'æ',
    '°',
    'ĠCar',
    'ry',
    'Ġt',
    'bsp',
    'en',
    'ance',
    'H',
    'ill',
    'art',
    'hed',
    'Ġro',
    'tten',
    'De',
    'an',
    'Ġtw',
    'isting',
    'Ġgood',
    'will',
    'Ġimm',
    'ersion',
    'L',
    'iving',
    'Ġbr',
    'ushes',
    'ĠC',
    'GI',
    'ĠAt',
    'k',
    'tr',
    'aditional',
    'Ġph',
    'antom',
    'ĠSt',
    'amina',
    'Ġexpans',
    'ions',
    'ĠMar',
    'in',
    'Ġembark',
    'ed',
    'ĠE',
    'g',
    'int',
    'estinal',
    'ĠPE',
    'OPLE',
    'ĠBo',
    'oth',
    'ĠApp',
    'alach',
    'Ġreleg',
    'ated',
    'V',
    'T',
    'M',
    'IT',
    'Ġmust',
    'er',
    'Ġwithdraw',
    'ing',
    'Ġmicrosc',
    'ope',
    'ĠG',
    'athering',
    'ĠC',
    'rescent',
    'ĠArgent',
    'ine',
    'ĠDec',
    're',
    'ĠDomin',
    'ic',
    'Ġbud',
    's',
    'ant',
    'age',
    'ĠI',
    'on',
    'Ġwid',
    'ened',
    'ONS',
    'ORED',
    'ĠGl',
    'oves',
    'iann',
    'opoulos',
    'raz',
    'en',
    'fe',
    'el',
    'Ġrepay',
    'ment',
    'Ġhind',
    'sight',
    'ĠRE',
    'ALLY',
    'ĠPist',
    'ol',
    'ĠBra',
    'h',
    'Ġwat',
    'ts',
    'Ġsurv',
    'ives',
    'Ġfl',
    'urry',
    'iss',
    'y',
    'Al',
    'ert',
    'ĠUrug',
    'uay',
    'Ph',
    'oenix',
    'S',
    'low',
    'ĠG',
    'rave',
    'ĠF',
    'ir',
    'Ġmanage',
    'able',
    'Ġtar',
    'iff',
    'ĠU',
    'DP',
    'ĠPist',
    'ons',
    'ĠNiger',
    'ian',
    'Ġstrike',
    'outs',
    'Ġcos',
    'metics',
    'whel',
    'ming',
    'f',
    'ab',
    'c',
    'ape',
    'pro',
    'xy',
    'Ġre',
    'think',
    'Ġover',
    'coming',
    'sim',
    'ple',
    'Ġw',
    'oo',
    'Ġdistract',
    'ing',
    'ĠSt',
    'anton',
    'ĠTuls',
    'a',
    'ĠD',
    'ock',
    '65',
    '9',
    'Ġdisc',
    'ord',
    'ĠEm',
    'acs',
    'ĠV',
    'es',
    'ĠR',
    'OB',
    'Ġreass',
    'uring',
    'Ġcons',
    'ortium',
    'Muslim',
    's',
    '3',
    '21',
    'Ġprompt',
    's',
    'se',
    'i',
    'ĠH',
    'itch',
    'imp',
    'osed',
    'ĠF',
    'ool',
    'Ġindisc',
    'rim',
    'wr',
    'ong',
    'bu',
    'querque',
    'D',
    'avis',
    '!',
    ']',
    'Ġtim',
    'eless',
    'ĠNE',
    'ED',
    'Ġpestic',
    'ide',
    'Ġrally',
    'ing',
    'ĠCal',
    'der',
    'Ġå',
    '¤',
    'Ġx',
    'p',
    'ĠUn',
    'le',
    'ĠEx',
    'port',
    'lu',
    'aj',
    'B',
    'uff',
    ')',
    '</',
    'B',
    'oot',
    'ĠChrys',
    'ler',
    'or',
    'ative',
    'M',
    'ess',
    'Ġneglig',
    'ible',
    'ert',
    'odd',
    'ĠMush',
    'room',
    'ĠG',
    'ale',
    'g',
    'c',
    'ĠCos',
    'by',
    'ĠR',
    'ural',
    'rit',
    'ical',
    'B',
    'ell',
    'Ġturb',
    'ine',
    '00',
    '200000',
    'Ġlegit',
    'imately',
    'ĠAnim',
    'ated',
    'T',
    'ED',
    'ĠThe',
    'odore',
    'c',
    'onduct',
    'ĠH',
    'ier',
    'Ġcounterfe',
    'it',
    'ĠAlger',
    'ia',
    'Ġun',
    'beat',
    'cont',
    'roller',
    'Ġun',
    'res',
    'Ġscram',
    'bling',
    'ĠFall',
    'on',
    'T',
    'es',
    'Ġam',
    'ber',
    'Ġroy',
    'alties',
    'ĠShel',
    'ter',
    'ĠL',
    'ester',
    'Ġclass',
    'ify',
    'Rem',
    'ote',
    'Ġun',
    'heard',
    'Ġcontrovers',
    'ies',
    'Ġenrich',
    'ment',
    'ĠYan',
    'kee',
    'g',
    'amer',
    'Ġpl',
    'atinum',
    'Ġec',
    'ology',
    'ĠS',
    'ark',
    'Ġunt',
    'ouched',
    'Ġsuper',
    'visors',
    'Ġ"',
    '%',
    'Ġf',
    'ooth',
    'Ġcomm',
    'ons',
    'Ġnarc',
    'otics',
    'Ġind',
    'ices',
    'ĠP',
    'ly',
    'Ġaddition',
    'ally',
    'ĠGaw',
    'ker',
    'ĠE',
    'Q',
    'Pl',
    'aying',
    'Ġcave',
    'at',
    'ĠAbs',
    'olute',
    'oss',
    'us',
    'B',
    'aby',
    'Ġr',
    'ation',
    'Ġres',
    'in',
    'Ġcalib',
    'ration',
    'ĠNew',
    'port',
    'Ġkn',
    'ocks',
    'v',
    't',
    'Ġcomp',
    'ost',
    'Sc',
    'ene',
    'Ġsar',
    'cast',
    'Ġkiss',
    'es',
    'Ġn',
    's',
    'all',
    'i',
    'ĠMar',
    'cel',
    'ĠP',
    'iet',
    'iat',
    'rics',
    'Ġsurround',
    's',
    'ĠRep',
    'rodu',
    'ĠPhill',
    'ies',
    'Ġuncertain',
    'ties',
    'ĠE',
    'ur',
    'ĠRom',
    'ance',
    'ĠH',
    'ath',
    'ĠNeed',
    's',
    'ĠCl',
    'oak',
    'Ġcre',
    'm',
    'que',
    'ue',
    'Ġ3',
    '55',
    'Ġup',
    'front',
    ']',
    ');',
    'Ġrecip',
    'roc',
    'Ġ19',
    '27',
    'Ġ11',
    '00',
    'ut',
    'su',
    'Ġdep',
    'ressive',
    'ow',
    'ment',
    'F',
    'ans',
    'Ġme',
    'ch',
    'Ġann',
    'ihil',
    'Ġcounter',
    'terrorism',
    'ĠFig',
    'ures',
    'b',
    'old',
    'ĠMo',
    'ines',
    'ĠDri',
    'vers',
    'Ġmanuscript',
    's',
    'ĠCrypt',
    'o',
    'Ġhyp',
    'not',
    'redd',
    'its',
    'Ġprosec',
    'utions',
    'Ġdiver',
    't',
    'CR',
    'IP',
    'ĠB',
    'ene',
    'ĠRe',
    'ggie',
    'Ġtax',
    'ing',
    'ĠMor',
    'ales',
    'ent',
    'ing',
    't',
    'ur',
    'sign',
    'ificant',
    'ĠPR',
    'OV',
    'Ġstr',
    'ands',
    'Ġp',
    'ouch',
    'ĠR',
    'ookie',
    '»',
    'Ĵ',
    'Ġnic',
    'er',
    'he',
    'my',
    'h',
    'w',
    'EC',
    'A',
    'Ġintimid',
    'ated',
    'Ġstr',
    'icter',
    'Ġmicro',
    'bial',
    'det',
    'ails',
    'Ġv',
    'ows',
    'Ġqu',
    'ake',
    'hh',
    'hh',
    'Ġrein',
    'vent',
    'U',
    'b',
    'Ġrel',
    'inqu',
    'ĠBuff',
    'ett',
    'lic',
    'ensed',
    'itte',
    'red',
    'ĠPic',
    'ard',
    'Ġche',
    'wing',
    'u',
    'cl',
    'organ',
    'ic',
    'Ġlocal',
    'ized',
    'ĠEconom',
    'ist',
    'Ġacqu',
    'ainted',
    'Def',
    'inition',
    's',
    'ed',
    'Crit',
    'ics',
    'Ġc',
    'c',
    '45',
    '3',
    '38',
    '1',
    'Ġfell',
    'ows',
    'Ġcheck',
    'points',
    '0',
    '25',
    'Ġre',
    'election',
    'Ġmed',
    'iated',
    'ĠK',
    'DE',
    'Ġhurd',
    'le',
    'Ġtext',
    'ing',
    'Per',
    'fect',
    'Ġtrust',
    'ees',
    'fect',
    'ure',
    'Ġd',
    'ich',
    'mon',
    'ary',
    'Ġdist',
    'inctions',
    'Ġ14',
    '00',
    'Ġus',
    'her',
    'Ġparas',
    'ites',
    'ĠSh',
    'aring',
    'ĠV',
    'im',
    'Ġbar',
    'becue',
    'ĠMin',
    'isters',
    'ere',
    'lla',
    'Ġe',
    'b',
    'Ġm',
    'c',
    'ĠSome',
    'how',
    'ĠIn',
    'sect',
    'ch',
    'anges',
    'b',
    'road',
    'ĠBy',
    'z',
    'Ġgrap',
    'es',
    '66',
    '9',
    'Ġ=',
    '================',
    'Ġass',
    'imil',
    'Ġhaun',
    'ting',
    'Ġfire',
    'power',
    'Ġdef',
    'amation',
    'em',
    'phasis',
    'Ġcomp',
    'ose',
    'Ġallerg',
    'ies',
    'Ġstr',
    'ang',
    'roll',
    'ers',
    'b',
    'ang',
    'Ġbrew',
    'ers',
    'ron',
    'gh',
    'ri',
    'ot',
    'p',
    'oor',
    'c',
    'old',
    'S',
    'ample',
    'Ġbu',
    'oy',
    '0',
    '40',
    'ĠCourt',
    'ney',
    'Ġ26',
    '8',
    'ĠWed',
    'ding',
    '70',
    '2',
    'Ġobsess',
    'ive',
    'Ġbra',
    'king',
    'ĠL',
    'al',
    'an',
    'ical',
    'å',
    '¦',
    'at',
    'en',
    'Con',
    'struction',
    'Ġclin',
    'ically',
    'iers',
    'hip',
    'N',
    'ames',
    'ĠDisc',
    'uss',
    'ĠRam',
    'os',
    'Ġloc',
    'ale',
    'ĠAgric',
    'ultural',
    'En',
    'able',
    'Ġhorse',
    'power',
    'ent',
    'ure',
    'P',
    'ref',
    'C',
    'ourt',
    'Ġstaff',
    'ing',
    'Ġfut',
    'uristic',
    'dri',
    'vers',
    'ĠMarket',
    'place',
    'æĪ',
    '¦',
    'Friend',
    's',
    'Ġdam',
    'ning',
    'ĠCustom',
    'ers',
    'Ġwe',
    'eds',
    'ĠM',
    'ai',
    'Ġag',
    'ile',
    'ĠT',
    'att',
    'ic',
    'ent',
    'R',
    'anked',
    'cro',
    'ft',
    'ĠKat',
    'y',
    'Ext',
    'reme',
    'Ġcar',
    've',
    'ĠR',
    'over',
    'ĠBy',
    'ron',
    '37',
    '2',
    'Ġconduct',
    's',
    'r',
    'atch',
    'it',
    'ia',
    'ĠPump',
    'kin',
    'Sad',
    'ly',
    'Rel',
    'oaded',
    'P',
    'olicy',
    'Ġl',
    'ick',
    'pe',
    'ak',
    'is',
    'ks',
    'ĠCD',
    's',
    'ĠEn',
    'cyclopedia',
    'in',
    'itial',
    'C',
    'os',
    'ĠAware',
    'ness',
    'ĠD',
    'ram',
    '$$',
    '$$',
    'Ġr',
    'iff',
    'Ġscript',
    'ure',
    'run',
    'ners',
    'Ġbo',
    'iler',
    'ons',
    'on',
    'o',
    'in',
    'Ġham',
    'string',
    'Ġcat',
    'aly',
    'ĠArch',
    'bishop',
    'ch',
    'all',
    'Ġf',
    'aux',
    'ok',
    'in',
    'local',
    'host',
    'ĠN',
    'AME',
    'ad',
    'obe',
    'S',
    'AN',
    'am',
    'ate',
    'Ġscram',
    'ble',
    'Ġcar',
    'c',
    'ĠMan',
    'ifest',
    'ĠCed',
    'ar',
    'ĠSer',
    'gio',
    'l',
    'ater',
    'ff',
    'er',
    'Ġgrapp',
    'ling',
    'ĠDe',
    'utsche',
    'agon',
    'ists',
    'ĠNew',
    'sp',
    'Ġpret',
    'ended',
    'arch',
    'ment',
    'Ġcur',
    'ated',
    'Ġhead',
    'phone',
    'ĠUn',
    'common',
    'ĠS',
    'IGN',
    'A',
    'gent',
    'Ġdead',
    'lines',
    'Ġhorizont',
    'ally',
    'ĠM',
    'AT',
    'ĠSum',
    'mers',
    'Ġord',
    'ained',
    'ĠLast',
    'ly',
    'ĠKend',
    'all',
    'Ġfr',
    'ig',
    'ĠMach',
    'ina',
    'ĠWater',
    'loo',
    'ĠMex',
    'icans',
    'Ġprotect',
    'or',
    'Ġgl',
    'are',
    '}',
    '"',
    'Prem',
    'ium',
    'Ġr',
    'ift',
    'ĠTelesc',
    'ope',
    'Met',
    'al',
    'Ġrec',
    'apt',
    'Ġ;',
    ';',
    'Ġincl',
    'ination',
    'Ġimp',
    'oses',
    'ing',
    'en',
    '^',
    '{',
    'Ġh',
    'aste',
    'Ġd',
    'olphins',
    'Ġcomm',
    'uters',
    'pl',
    'anned',
    'c',
    'ong',
    'm',
    'x',
    'ĠU',
    'pload',
    'Ġext',
    'rap',
    'ĠTuc',
    'son',
    'ĠExpl',
    'oration',
    'efe',
    'ated',
    'Ġsl',
    'ender',
    '70',
    '3',
    'ĠB',
    'uk',
    'is',
    'el',
    'Ġcompet',
    'itiveness',
    'ch',
    'lor',
    'ĠP',
    'ermanent',
    'ĠE',
    'verett',
    'ĠSpecial',
    'ist',
    'ĠS',
    'OL',
    'Ġcy',
    'an',
    'ĠEx',
    'actly',
    'U',
    'F',
    'ĠL',
    'IFE',
    'ary',
    'l',
    'on',
    'et',
    'ĠEmploy',
    'ee',
    'aw',
    'ed',
    'ĠRat',
    'ings',
    'Ġextra',
    'vag',
    'ul',
    'hu',
    'ĠPl',
    'ane',
    'Ġelev',
    'ate',
    'ĠCoord',
    'inator',
    'ĠWat',
    'kins',
    'Ġex',
    'cludes',
    'Ġsent',
    'ient',
    'Ġep',
    'och',
    'Ġall',
    'oc',
    'Pre',
    'viously',
    'ĠSh',
    'y',
    'ĠSlov',
    'akia',
    'L',
    'OCK',
    'Ġmarked',
    'ly',
    'Ġkn',
    'ob',
    'Ġadventure',
    'rs',
    'ĠBe',
    'en',
    'ĠCost',
    's',
    'amm',
    'ers',
    'Ġon',
    'slaught',
    'ĠSupport',
    'ed',
    'ĠT',
    'au',
    'ik',
    'arp',
    'ĠS',
    'overe',
    'ĠHam',
    'pton',
    'ãĤ',
    'ī',
    'Pre',
    'v',
    'ĠW',
    'orse',
    'Ġc',
    'ottage',
    'ĠH',
    'ades',
    'le',
    'z',
    'b',
    'owl',
    'Ġfrag',
    'rance',
    'ĠL',
    'ok',
    'EM',
    'OTE',
    'ĠPet',
    'ro',
    'Ġ19',
    '25',
    'ĠP',
    'end',
    'produ',
    'cing',
    'Ġrel',
    'ocate',
    'v',
    'ati',
    'p',
    'ole',
    'Ġsem',
    'in',
    'ĠN',
    'UM',
    'Ġrock',
    'ed',
    'b',
    'uff',
    'b',
    'ly',
    'Rep',
    'ly',
    'ĠH',
    'ai',
    'Ġartic',
    'ulated',
    'ĠIslam',
    'abad',
    '66',
    '5',
    'ĠClaim',
    's',
    'Des',
    'ktop',
    'Ġtrust',
    'ee',
    'Ġscript',
    'ing',
    'ĠS',
    'ob',
    'ĠAs',
    'ylum',
    'STD',
    'OUT',
    'ĠCl',
    'own',
    'ĠD',
    'ortmund',
    'ĠDev',
    'on',
    'l',
    'ite',
    'ĠMar',
    'ble',
    'Ġb',
    'unker',
    'Ġcre',
    'st',
    'Ġarous',
    'al',
    'ĠS',
    'ears',
    'ĠBudd',
    'y',
    'ered',
    'ith',
    'ĠP',
    'olly',
    'Ġdec',
    'ode',
    'ĠV',
    'ish',
    'ĠRef',
    'lect',
    'an',
    'on',
    'Ġrefund',
    's',
    'imm',
    'ers',
    'H',
    'M',
    'Ġwip',
    'ing',
    'Ġpuzz',
    'led',
    'Ġmat',
    'te',
    'un',
    'o',
    'P',
    'ierre',
    ')',
    '),',
    'Ġt',
    'ainted',
    'Ġsymbol',
    'ism',
    'ĠF',
    'raz',
    'Ġprotest',
    'ors',
    'ethe',
    'us',
    '%%',
    '%%',
    'W',
    'ra',
    'Ġl',
    'ax',
    'ad',
    'em',
    'atur',
    'ation',
    'ãĥ',
    'ĵ',
    'ĠTra',
    'iler',
    'ĠE',
    'NG',
    'ĠBows',
    'er',
    'Ġatt',
    'm',
    'D',
    'ur',
    '80',
    '7',
    'Ġsid',
    'x',
    'Ġc',
    'ider',
    'ĠA',
    'ffect',
    'Ġw',
    'oven',
    'ĠBark',
    'er',
    'ben',
    'ef',
    'Ġdst',
    'g',
    'ĠRy',
    'u',
    '>',
    '[',
    'Ġsq',
    'or',
    'S',
    'audi',
    'Ġis',
    'tg',
    'Ġindul',
    'ge',
    'pro',
    'c',
    'Ġdisg',
    'usted',
    'Ġcomp',
    'ounded',
    'Ġn',
    'em',
    'Ġschool',
    'ing',
    'ĠC',
    'ure',
    'process',
    'ing',
    'S',
    'ol',
    'Ġpro',
    'verb',
    'it',
    'ized',
    'ĠAlv',
    'arez',
    'Ġscar',
    'f',
    'Ġrect',
    'angular',
    're',
    've',
    'Ġh',
    'ormonal',
    'ĠSt',
    'ress',
    'itiz',
    'en',
    'Ġ4',
    '25',
    'girl',
    's',
    'ĠNo',
    'ir',
    'ĠR',
    'app',
    'Ġmar',
    'ches',
    'ch',
    'urch',
    'ĠUs',
    'es',
    'Ġ40',
    '5',
    'ĠBer',
    'm',
    'Ġord',
    'inances',
    'ĠJud',
    'gment',
    'Charg',
    'es',
    'ĠZ',
    'in',
    'Ġdust',
    'y',
    'Ġstraw',
    'berries',
    'Ġper',
    'ce',
    'ĠTh',
    'ur',
    'ĠDebor',
    'ah',
    'net',
    'flix',
    'ĠLam',
    'bert',
    'Ġam',
    'used',
    'ĠGu',
    'ang',
    'Y',
    'OU',
    'R',
    'GB',
    'ĠC',
    'CTV',
    'Ġf',
    'iat',
    'r',
    'ang',
    'Ġf',
    'ederation',
    'ĠM',
    'ant',
    'ĠB',
    'ust',
    'ĠM',
    'are',
    'respect',
    'ive',
    'ĠM',
    'igration',
    'ĠB',
    'IT',
    '59',
    '0',
    'Ġpatriot',
    'ism',
    'Ġout',
    'lining',
    'reg',
    'ion',
    'ĠJos',
    'Ã©',
    'Ġbl',
    'asting',
    'ĠEz',
    'ra',
    'B',
    's',
    'Ġundermin',
    'es',
    'ĠSm',
    'ooth',
    'Ġcl',
    'ashed',
    'rad',
    'io',
    'Ġtransition',
    'ing',
    'ĠBucc',
    'aneers',
    'ĠOw',
    'l',
    'Ġplug',
    's',
    'Ġh',
    'iatus',
    'ĠPin',
    'ball',
    'Ġm',
    'ig',
    'ĠNut',
    'r',
    'ĠWolf',
    'e',
    'Ġinteg',
    'ers',
    'Ġor',
    'bits',
    'ĠEd',
    'win',
    'ĠDirect',
    'X',
    'b',
    'ite',
    'Ġbl',
    'azing',
    'v',
    'r',
    'Ed',
    'ge',
    'ĠP',
    'ID',
    'ex',
    'it',
    'ĠCom',
    'ed',
    'ĠPath',
    'finder',
    'ĠGu',
    'id',
    'ĠSign',
    's',
    'ĠZ',
    'er',
    'ĠAg',
    'enda',
    'Ġreimburse',
    'ment',
    'M',
    'esh',
    'i',
    'Phone',
    'ĠMar',
    'cos',
    'ĠS',
    'ites',
    'h',
    'ate',
    'en',
    'burg',
    'Ġs',
    'ockets',
    'p',
    'end',
    'Bat',
    'man',
    'v',
    'ir',
    'ĠSH',
    'OW',
    'Ġprovision',
    'al',
    'con',
    'n',
    'ĠDeath',
    's',
    'AT',
    'IVE',
    'Pro',
    'file',
    'sy',
    'm',
    'J',
    'A',
    'Ġnin',
    'ja',
    'inst',
    'alled',
    'id',
    'ates',
    'eb',
    'ra',
    'ĠOm',
    'aha',
    'Ġse',
    'izing',
    'ĠBe',
    'asts',
    'Ġsal',
    'ts',
    'M',
    'ission',
    'Gener',
    'ally',
    'ĠTr',
    'ilogy',
    'he',
    'on',
    'leg',
    'ates',
    'Ġd',
    'ime',
    'Ġf',
    'aire',
    'par',
    'able',
    'G',
    'raph',
    'Ġtotal',
    'ing',
    'Ġdiagram',
    's',
    'ĠYan',
    'uk',
    'ple',
    't',
    'ĠMe',
    'h',
    'Ġmyth',
    'ical',
    'ĠStep',
    'hens',
    'aut',
    'ical',
    'ochem',
    'istry',
    'Ġkil',
    'ograms',
    'Ġel',
    'bows',
    'anc',
    'ock',
    'ĠB',
    'CE',
    'ĠPr',
    'ague',
    'Ġimpro',
    'v',
    'ĠDev',
    'in',
    'Ġ"',
    '\\',
    'par',
    'alle',
    'Ġsuprem',
    'acists',
    'ĠB',
    'illion',
    'Ġreg',
    'imen',
    'inn',
    'acle',
    'Ġrequ',
    'isite',
    'ang',
    'an',
    'ĠBur',
    'lington',
    'ain',
    'ment',
    'ĠObject',
    'ive',
    'oms',
    'ky',
    'G',
    'V',
    'Ġun',
    'ilateral',
    'Ġt',
    'c',
    'Ġh',
    'ires',
    'ment',
    'al',
    'Ġinvol',
    'untary',
    'Ġtrans',
    'pl',
    'ĠASC',
    'II',
    'Â',
    '¨',
    'Ev',
    'ents',
    'Ġdoub',
    'ted',
    'ĠKa',
    'plan',
    'ĠCour',
    'age',
    'ig',
    'on',
    'ĠMan',
    'aging',
    'ĠT',
    'art',
    'Ġfalse',
    'hood',
    'ĠV',
    'iolet',
    'Ġair',
    's',
    'Ġfertil',
    'izer',
    'Brit',
    'ain',
    'Ġaqu',
    'atic',
    'ou',
    'f',
    'W',
    'ords',
    'ĠHart',
    'ford',
    'Ġeven',
    'ings',
    'ĠV',
    'engeance',
    'qu',
    'ite',
    'G',
    'all',
    'ĠP',
    'ret',
    'Ġp',
    'df',
    'ĠL',
    'M',
    'ĠSo',
    'chi',
    'ĠInter',
    'cept',
    '9',
    '20',
    'Ġprofit',
    'ability',
    'ĠId',
    'le',
    'ĠMac',
    'Donald',
    'ĠEst',
    'ablishment',
    'um',
    'sy',
    'Ġgather',
    'ings',
    'ĠN',
    'aj',
    'Charl',
    'ie',
    'Ġas',
    'cent',
    'ĠProt',
    'ector',
    'Ġal',
    'gebra',
    'Ġbi',
    'os',
    'for',
    'ums',
    'EL',
    'S',
    'Introdu',
    'ced',
    'Ġ3',
    '35',
    'Ġastron',
    'omy',
    'Cont',
    'ribut',
    'ĠPol',
    'ic',
    'Pl',
    'atform',
    'Ġcontain',
    'ment',
    'w',
    'rap',
    'Ġcoron',
    'ary',
    'ĠJ',
    'elly',
    'man',
    'ager',
    'Ġheart',
    'breaking',
    'c',
    'air',
    'ĠChe',
    'ro',
    'c',
    'gi',
    'Med',
    'ical',
    'ĠAccount',
    'ability',
    '!',
    '!"',
    'oph',
    'ile',
    'Ġpsych',
    'otic',
    'ĠRest',
    'rict',
    'Ġequ',
    'itable',
    'iss',
    'ues',
    'Ġ19',
    '05',
    'ĠN',
    'ek',
    'c',
    'ised',
    'ĠTr',
    'acking',
    'Ġo',
    'zone',
    'Ġcook',
    'er',
    'ros',
    'is',
    'Ġre',
    'open',
    'Ġinf',
    'inity',
    'ĠPharm',
    'aceutical',
    'ens',
    'ional',
    'Att',
    'empt',
    'ĠR',
    'ory',
    'Mar',
    'co',
    'Ġawa',
    'its',
    'H',
    'OW',
    't',
    'reated',
    'Ġbol',
    'st',
    'Ġreve',
    'red',
    'Ġp',
    'ods',
    'opp',
    'ers',
    '00',
    '10',
    'Ġampl',
    'itude',
    'ric',
    'an',
    'SP',
    'ONSORED',
    'Ġtrou',
    'sers',
    'Ġhal',
    'ves',
    'ĠK',
    'aine',
    'ĠCut',
    'ler',
    'ĠA',
    'UTH',
    'Ġsplend',
    'id',
    'Ġprevent',
    'ive',
    'ĠDud',
    'ley',
    'if',
    'acts',
    'umin',
    'ati',
    'ĠY',
    'in',
    'Ġad',
    'mon',
    'ĠV',
    'ag',
    'Ġin',
    'verted',
    'Ġhast',
    'ily',
    'ĠH',
    'ague',
    'L',
    'yn',
    'Ġled',
    'ger',
    'Ġastron',
    'omical',
    'get',
    'ting',
    'Ġcirc',
    'a',
    'ĠC',
    'ic',
    'ĠTenn',
    'is',
    'Lim',
    'ited',
    'Ġd',
    'ru',
    'ĠBY',
    'U',
    'Ġtrave',
    'llers',
    'Ġp',
    'ane',
    'ĠInt',
    'ro',
    'Ġpatient',
    'ly',
    'Ġa',
    'iding',
    'Ġlo',
    'os',
    'ĠT',
    'ough',
    'Ġ29',
    '3',
    'Ġconsum',
    'es',
    'Source',
    'File',
    'Ġ""',
    '"',
    'Ġbond',
    'ing',
    'Ġtil',
    'ted',
    'Ġmenstru',
    'al',
    'ĠCel',
    'estial',
    'UL',
    'AR',
    'Plug',
    'in',
    'Ġrisk',
    'ing',
    'N',
    'az',
    'ĠRiy',
    'adh',
    'Ġacc',
    'redited',
    'Ġsk',
    'irm',
    'é',
    'Ľ',
    'Ġexam',
    'iner',
    'Ġmess',
    'ing',
    'Ġnear',
    'ing',
    'ĠC',
    'hern',
    'ĠBeck',
    'ham',
    'Ġsw',
    'apped',
    'Ġgo',
    'ose',
    'K',
    'ay',
    'Ġlo',
    'fty',
    'ĠWal',
    'let',
    'Ġ[',
    "'",
    'Ġap',
    'ocalypse',
    'Ġb',
    'amboo',
    'ĠSP',
    'ACE',
    'ĠEl',
    'ena',
    'Ġ30',
    '6',
    'ac',
    'ons',
    'Ġtight',
    'ened',
    'Ġadolesc',
    'ence',
    'Ġrain',
    'y',
    'Ġvandal',
    'ism',
    'ĠNew',
    'town',
    'Ġcon',
    'ject',
    'c',
    'akes',
    'Ġche',
    'ated',
    'Ġmoder',
    'ators',
    'par',
    'ams',
    'E',
    'FF',
    'Ġdece',
    'it',
    'ĠST',
    'L',
    'ĠTanz',
    'ania',
    'ĠR',
    'I',
    'Ġ19',
    '23',
    'ĠEx',
    'ile',
    'the',
    'l',
    'Ġthe',
    'olog',
    'Ġquir',
    'ky',
    'ĠIr',
    'vine',
    'Ġneed',
    'y',
    'or',
    'is',
    'U',
    'm',
    'K',
    'a',
    'Ġmail',
    'box',
    '3',
    '22',
    'Ġb',
    'os',
    'ĠPet',
    'ra',
    'K',
    'ING',
    'Ġenlarg',
    'ed',
    'O',
    'ften',
    'Ġbad',
    'ass',
    'Ġ3',
    '43',
    'ĠPl',
    'aces',
    'ĠC',
    'AD',
    'Ġpr',
    'istine',
    'Ġinterven',
    'ing',
    'd',
    'irection',
    'Ġl',
    'az',
    'ĠD',
    'SM',
    'Ġproject',
    'ing',
    'ĠF',
    'unk',
    'ag',
    'og',
    'pay',
    'ment',
    'n',
    'ov',
    'Ġch',
    'atter',
    'AR',
    'B',
    'Ġexam',
    'inations',
    'ĠHouse',
    'hold',
    'ĠG',
    'us',
    'F',
    'ord',
    '4',
    '14',
    'B',
    'oss',
    'Ġmy',
    'stic',
    'Ġle',
    'aps',
    'ĠB',
    'av',
    'ul',
    'z',
    'b',
    'udget',
    'Foot',
    'ball',
    'Ġsubsid',
    'ized',
    'Ġfirst',
    'hand',
    'Ġcoinc',
    'ide',
    'oc',
    'ular',
    'Con',
    'n',
    'ĠColl',
    'abor',
    'Ġfool',
    's',
    'am',
    'ura',
    'ah',
    'ar',
    'r',
    'ists',
    'Ġsw',
    'ollen',
    'Ġexp',
    'ended',
    'ĠP',
    'au',
    's',
    'up',
    'Ġsp',
    'ar',
    'Ġkey',
    'note',
    's',
    'uff',
    'Ġunequ',
    'al',
    'Ġprogress',
    'ing',
    'str',
    'ings',
    'ĠGamer',
    'gate',
    'Dis',
    'ney',
    'ĠEle',
    'ven',
    'om',
    'nia',
    'Ġscript',
    'ed',
    'Ġear',
    'ners',
    'bro',
    'ther',
    'ĠEn',
    'abled',
    'æ',
    '³',
    'Ġlar',
    'vae',
    'ĠL',
    'OC',
    'm',
    'ess',
    'Wil',
    'son',
    'ĠTem',
    'plate',
    'success',
    'fully',
    'Ġparam',
    'ount',
    'Ġcamoufl',
    'age',
    'Ġbind',
    's',
    'ĠQu',
    'iet',
    'ĠSh',
    'utterstock',
    'r',
    'ush',
    'Ġmasc',
    'ot',
    'fort',
    'une',
    'ĠCol',
    't',
    'ĠBe',
    'yon',
    'hab',
    'i',
    'Ġha',
    'irc',
    'Ġ26',
    '7',
    'ĠDe',
    'us',
    'Ġtw',
    'itch',
    'Ġconcent',
    'rating',
    'Ġn',
    'ipples',
    'c',
    'ible',
    'Ġg',
    'ir',
    'N',
    'Z',
    'M',
    'ath',
    'n',
    'ih',
    'Requ',
    'ired',
    'Ġp',
    'onder',
    'ĠS',
    'AN',
    'Ġwedd',
    'ings',
    'Ġl',
    'oneliness',
    'N',
    'ES',
    'ĠMah',
    'jong',
    '69',
    '5',
    'add',
    'le',
    'ĠGar',
    'ner',
    'ĠC',
    'OUR',
    'Br',
    'idge',
    'Ġsp',
    'ree',
    'ĠCald',
    'well',
    'Ġbri',
    'bery',
    'Ġï¿½ï¿½ï¿½ï¿½',
    'ï¿½ï¿½ï¿½ï¿½',
    'plug',
    'ins',
    'Ġr',
    'acket',
    'Ġchamp',
    'agne',
    'vers',
    'ible',
    'V',
    'ote',
    'Ġmod',
    'ifiers',
    'May',
    'or',
    '6',
    '80',
    'Ġassemb',
    'lies',
    'ĠS',
    'ultan',
    'ĠN',
    'ing',
    'ĠLad',
    'ies',
    'Ġsulf',
    'ur',
    'Ġor',
    'bs',
    'Ġ----',
    '-',
    '____',
    '___',
    'ĠJournal',
    'ism',
    'Ġes',
    'ports',
    'Ġl',
    'ush',
    'Ġh',
    'ue',
    'Ġspect',
    'ral',
    'H',
    'onest',
    'ãĥ',
    'ı',
    'Ġbus',
    'hes',
    'Ġrein',
    'forcement',
    'Ġre',
    'opened',
    'ĠWhe',
    'els',
    'ĠM',
    'org',
    'rie',
    'ving',
    'Ġaux',
    'iliary',
    'Ġj',
    'Query',
    'ĠB',
    'AT',
    'tes',
    'que',
    'Ġver',
    'tex',
    'p',
    'ure',
    'f',
    'rey',
    'ãĤ',
    'º',
    'd',
    'os',
    'Ġty',
    'ph',
    'Ġc',
    'ull',
    'Ġe',
    'q',
    'Ġdec',
    'on',
    'Ġtoss',
    'ing',
    'Ġdispar',
    'ate',
    'ĠBr',
    'igham',
    'print',
    'f',
    'led',
    'ged',
    'Ġsu',
    'nd',
    'Ġco',
    'zy',
    'Ġhepat',
    'itis',
    'per',
    'forming',
    'Ġav',
    'al',
    'ĠG',
    'G',
    'f',
    'uture',
    'Ġpet',
    'ertodd',
    'ĠKos',
    'ovo',
    'Ġmagn',
    'ets',
    'Al',
    'ready',
    'ĠEd',
    'ison',
    'ĠCe',
    'res',
    'ĠRA',
    'ID',
    'Ġbrill',
    'iance',
    '57',
    '6',
    'Ġder',
    'ives',
    'Ġhypert',
    'ension',
    'ĠÎ',
    'Ķ',
    'Ġlamb',
    'da',
    'Ġfl',
    'air',
    'Ġmission',
    'aries',
    'Ġrap',
    'es',
    'ĠSt',
    'arter',
    'ĠMon',
    'ths',
    'Ġdef',
    'y',
    'Ġseism',
    'ic',
    'ĠR',
    'aphael',
    'Ġeuro',
    'zone',
    '65',
    '6',
    'z',
    'sche',
    'Ġscr',
    'atched',
    'Ġb',
    'ows',
    'ĠLenn',
    'on',
    'ĠGa',
    'ia',
    'Ġdri',
    'pping',
    'f',
    'acts',
    'A',
    'le',
    'Ġfrog',
    's',
    'ĠBre',
    'ast',
    'ogene',
    'ity',
    'ĠProsecut',
    'or',
    'Ġampl',
    'ified',
    'ĠHod',
    'g',
    'ĠF',
    'n',
    'Th',
    'ousands',
    'ĠNI',
    'H',
    'ĠMonitor',
    'ing',
    'FT',
    'WARE',
    'ĠPri',
    'ebus',
    'ĠG',
    'rowing',
    'hun',
    'ter',
    'Ġdiagn',
    'ose',
    'ĠM',
    'ald',
    'ĠL',
    'R',
    'Ġcrown',
    'ed',
    'Ġburst',
    'ing',
    'Ġdiss',
    'olution',
    'j',
    'avascript',
    'Ġuseful',
    'ness',
    'ĠExec',
    'ution',
    ':',
    '(',
    'ĠIv',
    'ory',
    'a',
    'ah',
    'Ġpersecut',
    'ed',
    'viol',
    'ence',
    'ist',
    'as',
    'ĠCr',
    'ate',
    'Ġimpuls',
    'es',
    'ĠSp',
    'ani',
    'ed',
    'es',
    'Hand',
    'le',
    'ĠZ',
    'erg',
    'think',
    'able',
    'Last',
    'ly',
    'Ġspont',
    'aneously',
    'Ġinconven',
    'ient',
    'Ġdismiss',
    'ing',
    'Ġpl',
    'otted',
    'Ġeight',
    'y',
    'Ġ7',
    '37',
    'r',
    'ish',
    'ĠThor',
    'nton',
    'ath',
    'am',
    'Ġsit',
    'com',
    'V',
    'en',
    'Rec',
    'ipe',
    't',
    'el',
    'l',
    'und',
    'Ġcle',
    'ars',
    'ĠSas',
    'uke',
    'Ġ25',
    '8',
    'Ġopt',
    'ing',
    'Ġen',
    'raged',
    'est',
    'hetic',
    'ĠA',
    'e',
    'uch',
    's',
    'Pre',
    'p',
    'Fl',
    'ow',
    'Ġrun',
    'off',
    'ĠE',
    'ating',
    'ĠG',
    'iles',
    'ĠAct',
    'ing',
    'res',
    'ources',
    'ib',
    'aba',
    'Ġr',
    'pm',
    'Ġske',
    'wed',
    'ĠBl',
    'anc',
    'ĠS',
    'akuya',
    'Ġhot',
    'ter',
    'Ġ19',
    '24',
    'op',
    'ian',
    'ck',
    'o',
    'Ġcr',
    'umbling',
    'Ġcapt',
    'ains',
    'ĠAppropri',
    'ations',
    'le',
    'aders',
    'dro',
    'pping',
    'an',
    'uts',
    'Ġrevers',
    'ing',
    'ĠP',
    'ose',
    'ĠS',
    'ek',
    'Sc',
    'ot',
    'ĠIde',
    'a',
    'c',
    'ise',
    'ĠSloven',
    'ia',
    'Ġ3',
    '17',
    'Do',
    'ctor',
    'Ġcro',
    'cod',
    'ald',
    'i',
    'Se',
    'a',
    'ĠFar',
    'rell',
    'Ġmerc',
    'enaries',
    'ĠR',
    'NC',
    'ĠGu',
    'ess',
    'Ġp',
    'acing',
    'M',
    'achine',
    'Streamer',
    'Bot',
    'ĠChar',
    'ity',
    'Ġ29',
    '8',
    'Ġcann',
    'ons',
    'ĠTob',
    'y',
    'TPP',
    'StreamerBot',
    'ĠPass',
    'ion',
    'cf',
    'g',
    'Th',
    'om',
    'Ġbad',
    'ges',
    'ĠBern',
    'stein',
    '.',
    'âĢĵ',
    'ĠP',
    'OP',
    'ĠCon',
    'j',
    'Ġinitial',
    'ization',
    'Ġbiod',
    'iversity',
    'D',
    'ub',
    'Ġfeud',
    'al',
    'Ġdisclaim',
    'er',
    'Ġc',
    'row',
    'Ġign',
    'ition',
    'ar',
    'f',
    'S',
    'HA',
    'Ġk',
    'Hz',
    'h',
    'azard',
    'ĠArt',
    'ists',
    'oe',
    'uv',
    '67',
    '9',
    'ĠRud',
    'y',
    'N',
    'ine',
    'ĠRam',
    'adan',
    'å',
    '½',
    'itt',
    'o',
    'Ġadren',
    'aline',
    'C',
    'ert',
    'Ġsmell',
    'ed',
    'Ġimp',
    'unity',
    'Ġag',
    'endas',
    'ĠRe',
    'born',
    'ĠCon',
    'cent',
    'ĠSe',
    'ems',
    'Ġo',
    'mega',
    'ĠDust',
    'in',
    'Ġback',
    'er',
    'ĠSau',
    'ce',
    'ĠBoy',
    'le',
    'W',
    'IN',
    'Ġsp',
    'ins',
    'Ġpa',
    'uses',
    'u',
    'pt',
    'Ġshred',
    'ded',
    'Ġstra',
    'pped',
    'ĠCor',
    'ruption',
    'Ġscr',
    'atches',
    'Ġn',
    'i',
    'Ġatt',
    'ire',
    'ĠS',
    'AF',
    'Factory',
    'Reloaded',
    'ĠI',
    'PS',
    'Ġ(',
    '%',
    'Ġsem',
    'inar',
    'f',
    'ocus',
    'c',
    'ivil',
    'Ġ18',
    '60',
    'int',
    'osh',
    'Ġcontin',
    'ual',
    'Ġabbre',
    'vi',
    'ĠS',
    'ok',
    'oc',
    'obo',
    'X',
    'M',
    'Ġfr',
    'antic',
    'Ġunavoid',
    'able',
    'Ġar',
    'tery',
    'Ġannot',
    'ations',
    'b',
    'ath',
    'Cl',
    'imate',
    'Ġd',
    'ors',
    'ĠSl',
    'ide',
    'co',
    'ord',
    'ĠRel',
    'oad',
    'ĠL',
    'DL',
    'ĠLove',
    'craft',
    'Ġunim',
    'agin',
    'Ġresemb',
    'led',
    'Ġbarr',
    'acks',
    'n',
    'p',
    'Ġsurrog',
    'ate',
    'Ġcategor',
    'ized',
    'ãĤ',
    '©',
    'Ġvacc',
    'inated',
    'Ġdrain',
    'age',
    'Ġind',
    'ist',
    'ĠWhats',
    'App',
    'Ġ18',
    '70',
    'oler',
    'ance',
    'inv',
    'oke',
    'am',
    'orph',
    'Ġrecon',
    'nect',
    'Ġem',
    'anc',
    'Ġblind',
    'ness',
    'Ġ12',
    '80',
    'intern',
    'et',
    'c',
    'ollar',
    'Ġalt',
    'ru',
    'Ġab',
    'yss',
    'ĠT',
    'RI',
    '65',
    '7',
    'Ġinf',
    'used',
    'HE',
    'AD',
    'Ġforest',
    'ry',
    'ĠWood',
    'y',
    'ĠC',
    'i',
    'w',
    'i',
    's',
    'am',
    '78',
    '4',
    'hol',
    'iday',
    'Ġmog',
    'ul',
    'ĠF',
    'ees',
    'ĠD',
    'EN',
    'In',
    'ternal',
    'ur',
    'bed',
    'f',
    'usc',
    'at',
    'om',
    'ĠIll',
    'usion',
    'Ġpoll',
    'ed',
    'Ġfl',
    'ap',
    'Ġco',
    'ax',
    'L',
    'GBT',
    'An',
    'aly',
    'ĠSect',
    'ions',
    'ĠCalif',
    'orn',
    'em',
    'n',
    'Ġh',
    'ither',
    'ĠN',
    'IGHT',
    'Ġn',
    'ailed',
    'ĠPip',
    'eline',
    '39',
    '1',
    'o',
    'of',
    'ĠPr',
    'imal',
    'vere',
    'nd',
    'Ġsl',
    'ashing',
    'Ġret',
    'ri',
    'avi',
    'our',
    'Ġdepart',
    'ing',
    'g',
    'il',
    'IS',
    'C',
    'Ġmid',
    'way',
    'Ġultras',
    'ound',
    'Ġbeh',
    'aving',
    'ĠT',
    'ara',
    'class',
    'es',
    'V',
    'irtual',
    'ĠColon',
    'ial',
    'Ġstri',
    'pping',
    'Ġorchestr',
    'ated',
    'ĠGra',
    'ves',
    '45',
    '2',
    'ĠIron',
    'ically',
    'ĠWrit',
    'ers',
    'Ġl',
    'ends',
    'ĠMan',
    'z',
    'Ġra',
    'ven',
    'Ġoxid',
    'ative',
    'Ġ26',
    '6',
    'EL',
    'F',
    'act',
    'ually',
    'asc',
    'ar',
    'D',
    'raft',
    'Ġfavour',
    'able',
    'Ġhumili',
    'ating',
    'Ġf',
    'idelity',
    'ĠH',
    'of',
    'ĠX',
    'uan',
    '49',
    '6',
    'Ġlay',
    'ered',
    'at',
    'is',
    '79',
    '0',
    'Ġpay',
    'check',
    'it',
    'on',
    'K',
    'ar',
    'ĠVM',
    'ware',
    'ĠFar',
    'mer',
    'Ġserv',
    'ic',
    'gl',
    'omer',
    'Ġsl',
    'ump',
    'ĠFab',
    'ric',
    'ĠD',
    'OC',
    'est',
    'ing',
    'Ġreass',
    'ure',
    'Ġph',
    'yl',
    'v',
    'olt',
    'it',
    'ory',
    'R',
    'ules',
    'Ġoxid',
    'ation',
    'Ġpri',
    'zed',
    'Ġmist',
    'ress',
    'ĠDj',
    'ango',
    'WAR',
    'N',
    'å',
    'ĳ',
    'Ġenc',
    'ode',
    'ĠFeed',
    'back',
    'Ġstupid',
    'ity',
    'I',
    'an',
    'ĠYugoslav',
    'ia',
    '×',
    '¨',
    'ac',
    'l',
    'UT',
    'E',
    '19',
    '77',
    'Ġqual',
    'ifies',
    'Ġpuls',
    'es',
    'pret',
    'ty',
    'Ġfro',
    'ze',
    'Ġs',
    's',
    'Iter',
    'ator',
    'Ġur',
    'gently',
    'Ġm',
    'ailed',
    'ĠCh',
    'am',
    'Ġsust',
    'aining',
    'Ġbas',
    'il',
    'Ġpupp',
    'ies',
    'il',
    'ant',
    'ĠP',
    'LEASE',
    'l',
    'ap',
    'ace',
    'ous',
    'F',
    'ear',
    'ĠMaster',
    'y',
    'aut',
    'omatic',
    'ĠT',
    'AG',
    'Ġant',
    'im',
    'ag',
    'les',
    '47',
    '3',
    'fram',
    'es',
    'Ġwh',
    'ispers',
    'ĠWho',
    'ever',
    'Ġbra',
    'very',
    'ĠUK',
    'IP',
    'ract',
    'ions',
    '""',
    '"',
    'Ġt',
    'ame',
    'Ġpart',
    'ed',
    'every',
    'thing',
    'CON',
    'T',
    'Ġind',
    'ebted',
    'Ġadd',
    'r',
    're',
    'k',
    'IR',
    'ED',
    'Ġem',
    'inent',
    'cl',
    'inton',
    'Ġo',
    'usted',
    'Ġreview',
    'er',
    'Ġmelt',
    'down',
    'Ġre',
    'arr',
    'ĠY',
    'ao',
    'the',
    'real',
    'aby',
    'te',
    'Ġst',
    'umbling',
    'Ġbat',
    'ches',
    'Ġ25',
    '9',
    'Ġcontrace',
    'ptive',
    'Ġprost',
    'itute',
    'ens',
    'is',
    'De',
    'cl',
    'ĠSt',
    'rikes',
    'M',
    'ilitary',
    'ĠO',
    'ath',
    'v',
    'acc',
    'pp',
    'ings',
    '05',
    '2',
    'Ġpart',
    'Name',
    'amp',
    'ing',
    'Rep',
    'orts',
    'K',
    'I',
    'CH',
    'R',
    'Ġsubt',
    'ly',
    'sw',
    'ers',
    'Bl',
    'ake',
    'us',
    'ual',
    'Ġcontest',
    'ants',
    'Ġcart',
    'ridges',
    'ĠGRE',
    'AT',
    'Ġbl',
    'ush',
    'ĠâĢ',
    'º',
    '47',
    '2',
    'Ġreason',
    'ed',
    'ãĥ',
    '¤',
    'paralle',
    'led',
    'Ġd',
    'yn',
    'ag',
    'ate',
    'Ġnight',
    'ly',
    'å',
    'Ĩ',
    '55',
    '6',
    'Ġsem',
    'antic',
    'ĠAdv',
    'oc',
    'Ġ',
    '!!',
    'Ġdisag',
    'rees',
    'ĠB',
    'W',
    'V',
    'eh',
    'Ġharm',
    'ing',
    'Ġembr',
    'aces',
    'Ġstri',
    'ves',
    'Ġin',
    'land',
    'ĠK',
    'ard',
    'Ġhe',
    'ats',
    'ĠGin',
    'ny',
    'ut',
    'an',
    'ern',
    'aut',
    'yl',
    'ene',
    'ĠE',
    'lev',
    'J',
    'D',
    'Ġh',
    'ars',
    'ĠStar',
    'r',
    'Ġsk',
    'ysc',
    'Ġcollabor',
    'ators',
    'Us',
    'ually',
    'Ġrev',
    'olutions',
    'ĠSTAT',
    'S',
    'Ġdism',
    'antle',
    'Ġconfident',
    'ly',
    'Ġkin',
    'etic',
    'Al',
    'i',
    'Ġpercent',
    'ile',
    'Ġextract',
    'ing',
    'ill',
    'ian',
    'est',
    'ead',
    'Ġphysic',
    'ists',
    'ĠMarsh',
    'al',
    'Ġfell',
    'owship',
    'Ġd',
    'ashed',
    'ĠU',
    'R',
    'ĠSi',
    'oux',
    'ĠComp',
    'act',
    'am',
    'ide',
    'P',
    'ython',
    'ĠLe',
    'igh',
    'ĠPharm',
    'ac',
    'ist',
    'rates',
    'her',
    'ical',
    'Ġf',
    'ue',
    'ĠE',
    'min',
    'Ġ(',
    '{',
    'ĠNeighbor',
    'hood',
    'Ġdisrupt',
    'ing',
    'ĠD',
    'up',
    'Ġg',
    'land',
    'ĠSe',
    'v',
    'ĠMar',
    'ian',
    'arg',
    'on',
    'ĠD',
    'und',
    'Ġ<',
    '!--',
    'Ġstr',
    'and',
    'Ġstadium',
    's',
    'z',
    'os',
    'Ġpsych',
    'osis',
    'ĠR',
    'ack',
    'Ġbrilliant',
    'ly',
    'ï¸',
    'ı',
    'Ġsubmer',
    'ged',
    'ĠInst',
    'it',
    'ĠCh',
    'ow',
    'Ġc',
    'ages',
    'ĠH',
    'ats',
    'ĠU',
    'rs',
    'Ġdil',
    'uted',
    'us',
    'at',
    'ien',
    'ne',
    'ĠMembers',
    'hip',
    'ĠBur',
    'k',
    'Ġ',
    'ie',
    'Ġarche',
    'type',
    'D',
    'rug',
    'ult',
    'on',
    'ĠSp',
    'ock',
    'ĠMcK',
    'ay',
    'ĠDep',
    'end',
    'F',
    'eatured',
    'S',
    'oc',
    '19',
    '78',
    'ĠB',
    'ere',
    'Ġrelent',
    'lessly',
    'Ġcripp',
    'ling',
    'Ġar',
    'thritis',
    'çĶ',
    'Ł',
    'ĠTrop',
    'ical',
    'ĠBul',
    'g',
    'ĠCher',
    'yl',
    'Ġadm',
    'irable',
    'Ġsub',
    'title',
    'Over',
    'ride',
    'Ġorig',
    'inating',
    'ĠC',
    'CP',
    'Ġsw',
    'ore',
    'ĠSo',
    'le',
    'ĠDis',
    'orders',
    '3',
    '29',
    'Ġprocess',
    'ion',
    'Ġref',
    'urb',
    'Ġimm',
    'ersed',
    'requ',
    'ently',
    'Ġskept',
    'ics',
    'Ġcer',
    'amic',
    'm',
    'itter',
    'en',
    'stein',
    'b',
    'elt',
    'ĠT',
    'IT',
    'b',
    'idden',
    'Ġf',
    'ir',
    'm',
    'ist',
    '>',
    ']',
    'Ġwe',
    'ave',
    'ĠParad',
    'ox',
    'Ġentr',
    'usted',
    'ĠBarcl',
    'ays',
    'Ġnovel',
    'ist',
    'og',
    'ie',
    '80',
    '6',
    'Ġnin',
    'ety',
    'Ġdisag',
    'reements',
    '@@@@',
    '@@@@',
    'ĠAus',
    'chwitz',
    'c',
    'ars',
    'ĠL',
    'ET',
    't',
    'ub',
    'arant',
    'ine',
    'P',
    'OS',
    'Ġback',
    'story',
    'Ġcheer',
    'ful',
    'ĠR',
    'ag',
    'ek',
    'a',
    'bi',
    'ased',
    'Ġinexper',
    'ienced',
    'ak',
    'ra',
    'ĠW',
    'itt',
    't',
    'an',
    'Ġrap',
    'ist',
    'Ġplate',
    'au',
    'ch',
    'al',
    'ĠInqu',
    'is',
    'exp',
    'ression',
    'Ġc',
    'ipher',
    'Ġsh',
    'aving',
    'add',
    'en',
    're',
    'ly',
    '(',
    '\\',
    'ism',
    'a',
    'ĠReg',
    'ulatory',
    'CH',
    'AR',
    'ily',
    'n',
    'N',
    'VIDIA',
    'G',
    'U',
    'Ġmur',
    'm',
    'la',
    'us',
    'Christ',
    'opher',
    'Ġcontract',
    'ual',
    'ĠPro',
    'xy',
    'ĠJa',
    'ime',
    'ĠMethod',
    'ist',
    'Ġstew',
    'ards',
    'st',
    'a',
    'per',
    'ia',
    'Ġphys',
    'iology',
    'Ġbump',
    'ed',
    'Ġf',
    'ructose',
    'Austral',
    'ian',
    'ĠMet',
    'allic',
    'ĠMas',
    'querade',
    'ar',
    'b',
    'Ġprom',
    'ul',
    'Ġdown',
    'fall',
    'Ġbut',
    'cher',
    'Ġb',
    'our',
    'ĠIN',
    'FORMATION',
    'ĠB',
    'is',
    'pect',
    's',
    'ad',
    'ena',
    'Ġcontempl',
    'ating',
    'ar',
    'oo',
    'cent',
    'ered',
    'ĠPe',
    'aks',
    'Us',
    'ed',
    'Ġmod',
    'em',
    'Ġg',
    'enders',
    'Ġ8',
    '000',
    '37',
    '1',
    'Ġm',
    'aternity',
    'ĠR',
    'az',
    'Ġrock',
    'ing',
    'Ġhandgun',
    's',
    'ĠD',
    'ACA',
    'Aut',
    'om',
    'ĠN',
    'ile',
    'Ġtum',
    'ult',
    'ĠBenef',
    'it',
    'ĠAppro',
    'ach',
    'works',
    'hop',
    'ĠLe',
    'aving',
    'G',
    'er',
    'inst',
    'ead',
    'Ġvibr',
    'ations',
    'Ġrep',
    'ositories',
    '49',
    '7',
    'ĠA',
    'unt',
    'ĠJ',
    'ub',
    'ĠExp',
    'edition',
    'Al',
    'pha',
    'Ġs',
    'ans',
    'Ġoverd',
    'ue',
    'Ġoverc',
    'rowd',
    'Ġlegisl',
    'atures',
    'Ġp',
    'aternal',
    'ĠLeon',
    'ardo',
    'Ġexp',
    'ressive',
    'Ġdistract',
    'ions',
    'Ġsil',
    'enced',
    'tr',
    'ust',
    'Ġb',
    'iking',
    'Ġ5',
    '60',
    'Ġpropri',
    'et',
    'Ġimp',
    'osition',
    'Ġcon',
    'glomer',
    'Ġ=',
    '================================================================',
    'ĠTe',
    'aching',
    'ĠY',
    'ose',
    'int',
    'ensive',
    'T',
    'own',
    'Ġtroll',
    'ing',
    'ĠGr',
    'ac',
    'ĠAS',
    'US',
    'Y',
    'o',
    'Ġspecial',
    's',
    'ĠNep',
    'h',
    'ĠGod',
    'zilla',
    'Dat',
    'abase',
    'ĠHe',
    'gel',
    'Ġ27',
    '2',
    '19',
    '76',
    'ĠGl',
    'oria',
    'Ġdis',
    'emb',
    'ĠInvestig',
    'ations',
    'ĠB',
    'ane',
    'ag',
    'ements',
    'St',
    'range',
    'Ġtre',
    'asury',
    'ĠPl',
    'ays',
    'Ġundes',
    'irable',
    'Ġwid',
    'ening',
    'Ġverb',
    'ally',
    'Ġinf',
    'ancy',
    'Ġcut',
    'ter',
    'f',
    'ml',
    'Ġ21',
    '00',
    'prot',
    'otype',
    'f',
    'ine',
    'Ġdec',
    'riminal',
    'Ġdysfunction',
    'al',
    'Ġbes',
    'ie',
    'ĠErn',
    'st',
    'z',
    'eb',
    'Ġnort',
    'heastern',
    'Ġa',
    'ust',
    'por',
    'ate',
    'ĠMar',
    'lins',
    'Ġsegreg',
    'ated',
    'ew',
    'orld',
    'ĠMa',
    'her',
    'Ġtra',
    'verse',
    'Ġmon',
    'astery',
    'ur',
    'gy',
    'G',
    'ear',
    's',
    'and',
    'Com',
    'pl',
    'ĠE',
    'MP',
    'Ġpl',
    'ent',
    'ĠMer',
    'cer',
    'Ġ27',
    '6',
    'TA',
    'BLE',
    'Config',
    'uration',
    'H',
    'undreds',
    'Ġpr',
    'ic',
    'Ġcollabor',
    'ating',
    'ĠPar',
    'amount',
    'ĠCumm',
    'ings',
    'Ġ(',
    '<',
    'Ġrecord',
    'er',
    'Ġfl',
    'ats',
    'Ġ4',
    '16',
    'wh',
    'ose',
    'Font',
    'Size',
    'ĠOr',
    'bit',
    'Y',
    'R',
    'Ġwr',
    'ists',
    'Ġb',
    'akery',
    ')',
    '}',
    'ĠB',
    'ounty',
    'ĠLanc',
    'aster',
    'Ġend',
    'ings',
    'acc',
    'ording',
    'ĠSal',
    'am',
    'e',
    'asy',
    '75',
    '5',
    'ĠBur',
    'r',
    'ĠBarn',
    'ett',
    'onom',
    'ous',
    'Un',
    'ion',
    'Ġpreced',
    'ence',
    'ĠScholars',
    'hip',
    'ĠU',
    'X',
    'Ġroll',
    'out',
    'Ġbo',
    'on',
    'al',
    'm',
    'ĠCan',
    'ter',
    'æ',
    'µ',
    'Ġround',
    'ing',
    'Ġcl',
    'ad',
    'Ġv',
    'ap',
    'ĠF',
    'eatured',
    'is',
    'ations',
    'Ġ5',
    '40',
    'pol',
    'ice',
    'Ġunsett',
    'ling',
    'Ġdr',
    'ifting',
    'ĠLum',
    'ia',
    'ĠObama',
    'Care',
    'ĠF',
    'avor',
    'Hy',
    'per',
    'ĠRoth',
    'schild',
    'ĠMil',
    'iband',
    'an',
    'aly',
    'ĠJul',
    'iet',
    'H',
    'u',
    'Ġrec',
    'alling',
    'a',
    'head',
    '69',
    '6',
    'Ġunf',
    'avorable',
    'Ġd',
    'ances',
    'O',
    'x',
    'Ġleg',
    'ality',
    'Ġ40',
    '3',
    'rom',
    'ancer',
    'Ġinqu',
    'ire',
    'ĠM',
    'oves',
    '\\',
    '">',
    'ĠVari',
    'ant',
    'ĠMess',
    'iah',
    'ĠL',
    'CS',
    'ĠBah',
    'Ã¡',
    '75',
    '6',
    'Ġeyeb',
    'row',
    'ĠÂ',
    '¥',
    'ĠMc',
    'F',
    'ĠFort',
    'y',
    'M',
    'as',
    'Ġpan',
    'icked',
    'Ġtransform',
    'ations',
    'q',
    'q',
    'Ġrev',
    'olves',
    'ring',
    'e',
    'ĠA',
    'i',
    'ax',
    'e',
    'Ġon',
    'ward',
    'ĠC',
    'FR',
    'ĠB',
    'are',
    'log',
    'in',
    'Ġliqu',
    'ids',
    'Ġde',
    'comp',
    'second',
    'ary',
    'il',
    'an',
    'ĠCon',
    'vert',
    'ami',
    'ya',
    'Ġprosecut',
    'ing',
    'Ġâī',
    '¡',
    'ĠYork',
    'ers',
    'ĠByr',
    'ne',
    'sl',
    'ow',
    'aw',
    'ei',
    'J',
    'ean',
    'Ġ26',
    '9',
    'ĠSky',
    'dragon',
    'Ġ',
    'Ã©',
    'ĠNicarag',
    'ua',
    'ĠHuck',
    'abee',
    'ĠHigh',
    'ly',
    'Ġamph',
    'ib',
    'ĠPast',
    'or',
    'ĠL',
    'ets',
    'Ġbl',
    'urred',
    'Ġvisc',
    'eral',
    'ĠC',
    'BO',
    'Ġcollabor',
    'ated',
    'z',
    'ig',
    'Leg',
    'al',
    'Ġapart',
    'heid',
    'Ġbr',
    'id',
    'Ġpres',
    'et',
    'ĠD',
    'ET',
    'ĠAM',
    'A',
    '×',
    'Ķ',
    'arch',
    'ing',
    'auc',
    'uses',
    'build',
    'er',
    'Ġpo',
    'etic',
    'Ġem',
    'ulator',
    'ĠMole',
    'cular',
    'Ġhon',
    'oring',
    'ise',
    'um',
    'Ġtract',
    'or',
    'ĠCl',
    'uster',
    'ĠCal',
    'm',
    'ared',
    'evil',
    'Ġsidew',
    'alks',
    'Ġviol',
    'in',
    'Ġgeneral',
    'ized',
    'ĠAle',
    'c',
    'Ġemb',
    'argo',
    'Ġfast',
    'ball',
    'ĠHT',
    'TPS',
    'ĠL',
    'ack',
    'ĠCh',
    'ill',
    'ri',
    'ver',
    'C',
    'hel',
    'ĠSw',
    'arm',
    'ĠLev',
    'ine',
    'ro',
    'ying',
    'L',
    'aunch',
    'Ġkick',
    'er',
    'Ġadd',
    'itive',
    'ĠDe',
    'als',
    'W',
    'idget',
    'cont',
    'aining',
    'Ġescal',
    'ate',
    'ĠOP',
    'EN',
    'Ġtwe',
    'aked',
    'Ġst',
    'ash',
    'Ġsp',
    'arks',
    'ĠEs',
    'sex',
    'ĠE',
    'cc',
    'Ġconv',
    'ict',
    'Ġblog',
    'ging',
    'I',
    'ER',
    'ĠH',
    'L',
    'Ġmurd',
    'erers',
    '75',
    '9',
    'ĠH',
    'ib',
    'Ġde',
    'pl',
    'ĠJ',
    'ord',
    'S',
    'ac',
    'Ġdis',
    'sect',
    'ĠHow',
    'e',
    'os',
    'her',
    'Ġcustom',
    'izable',
    'ĠFran',
    'z',
    'Ġat',
    'ro',
    'Ä',
    'ĩ',
    'Ġ000',
    '4',
    'Ġout',
    'post',
    'R',
    'oss',
    'Ġglyph',
    'osate',
    'ĠHast',
    'ings',
    'ĠBE',
    'FORE',
    'Ġsh',
    'ove',
    'o',
    'pped',
    'ĠSc',
    'ala',
    'Ġam',
    'ulet',
    'an',
    'ian',
    'Ġexacerb',
    'ated',
    'Ġe',
    'ater',
    '47',
    '1',
    'UM',
    'E',
    'Ġpul',
    'p',
    'izont',
    'al',
    'ĠZ',
    'am',
    'ĠAT',
    'I',
    'imm',
    'une',
    'aby',
    'tes',
    'Ġunnecess',
    'arily',
    'ĠC',
    'AT',
    'ĠAx',
    'is',
    'Ġvisual',
    'ize',
    'Ã',
    'ī',
    'ĠRad',
    'ical',
    'f',
    'm',
    'Doc',
    'uments',
    'ĠFor',
    'rest',
    'Ġcontext',
    'ual',
    'ĠSy',
    'mbol',
    'Ġtent',
    'ative',
    'ĠDO',
    'ES',
    'ĠGood',
    's',
    'Ġintermitt',
    'ent',
    '}',
    ':',
    'medi',
    'ated',
    'Ġridic',
    'ule',
    'Ġathe',
    'ism',
    'Ġpath',
    'ogens',
    'ĠM',
    'um',
    'Ġre',
    'introdu',
    'Ġ30',
    '7',
    'i',
    'HUD',
    'Ġflash',
    'light',
    'Ġsw',
    'earing',
    'Ġp',
    'engu',
    'B',
    'u',
    'Ġrot',
    'ated',
    'ĠCr',
    'ane',
    'Ġ()',
    ');',
    'Ġfashion',
    'able',
    'Ġendors',
    'ing',
    '46',
    '3',
    ')',
    '[',
    'Ġingest',
    'ion',
    'Ġcook',
    's',
    'Ġ9',
    '50',
    'ot',
    'omy',
    'ĠIm',
    'am',
    'Ġk',
    'a',
    'Ġte',
    'aser',
    'ĠGhost',
    's',
    'ĠãĤ',
    'µ',
    '19',
    '69',
    'Ï',
    'ĥ',
    'ub',
    'by',
    'Ġconver',
    'ter',
    'zan',
    'ne',
    'end',
    'e',
    'ĠPre',
    'par',
    'ĠNic',
    'kel',
    'ĠChim',
    'era',
    'h',
    'im',
    'ĠTyr',
    'ann',
    'ĠSabb',
    'ath',
    'ĠNich',
    'ols',
    'Ġra',
    'pt',
    'ih',
    'ar',
    'Ġshe',
    'lling',
    'Ġillum',
    'inate',
    'Ġdent',
    'ist',
    'ut',
    'or',
    'ĠInteg',
    'ration',
    'Ġwh',
    'ims',
    'ĠLiter',
    'ary',
    'Be',
    'aut',
    'Ġp',
    'archment',
    'ag',
    'ara',
    'Br',
    'and',
    'Ġder',
    'og',
    'âĢ¦',
    ')',
    'ĠNor',
    'se',
    'Ġunw',
    'itting',
    'Ġc',
    'uc',
    'Ġborder',
    'line',
    'Ġupset',
    'ting',
    'Ġrec',
    'ourse',
    'Ġd',
    'raped',
    'ĠRad',
    'ar',
    'Ġcold',
    'er',
    'ĠPep',
    'si',
    'im',
    'inary',
    '],',
    '[',
    '65',
    '8',
    'V',
    'i',
    'ĠF',
    'rem',
    'ĠP',
    'es',
    'Ġveter',
    'inary',
    'ĠT',
    'ED',
    'ĠEp',
    'idem',
    'n',
    'ova',
    'k',
    'id',
    'Ġdev',
    'out',
    'o',
    'ct',
    'j',
    'ad',
    'M',
    'oh',
    'ĠP',
    'AY',
    'Ġge',
    'ometric',
    'Ġ3',
    '23',
    'Ġcircum',
    'ference',
    'ich',
    'ick',
    '19',
    '75',
    'ĠY',
    'uri',
    'ĠSh',
    'all',
    'ĠH',
    'over',
    'un',
    'in',
    'S',
    'pr',
    'Ġg',
    'raft',
    'ĠHapp',
    'iness',
    'Ġdisadvant',
    'ages',
    'att',
    'acks',
    'Ġhub',
    's',
    'ĠStar',
    'Craft',
    'é',
    'ĸ',
    'Ġgall',
    'eries',
    'ĠKor',
    'ra',
    'Ġgrocer',
    'ies',
    'ĠGors',
    'uch',
    'Ġrap',
    'ists',
    'Ġfun',
    'gi',
    'ĠTyph',
    'oon',
    'V',
    'ector',
    'ĠEm',
    'press',
    'b',
    'attle',
    '4',
    '68',
    'Ġparas',
    'ite',
    'ĠBom',
    'ber',
    'S',
    'G',
    'ex',
    'ist',
    'ĠP',
    'f',
    'Ġun',
    'se',
    'Ġsurge',
    'ons',
    'B',
    'irth',
    'ĠUn',
    'sure',
    'ĠPrint',
    'ed',
    'ĠBehavior',
    'al',
    'ĠA',
    'ster',
    'Pak',
    'istan',
    'Ġun',
    'ethical',
    'Ġs',
    'v',
    'ĠIo',
    'T',
    'Ġlay',
    'outs',
    'P',
    'ain',
    'Ġconst',
    'ants',
    'ĠL',
    'W',
    'ĠB',
    'ake',
    'Ġtow',
    'els',
    'Ġdeterior',
    'ation',
    'ĠBol',
    'ivia',
    'Ġblind',
    'ed',
    'ĠW',
    'arden',
    'ĠMist',
    'ress',
    'Ġon',
    'stage',
    'Ġcl',
    'ans',
    'ĠB',
    'EST',
    '19',
    '60',
    'Ġant',
    'ique',
    'Ġrhet',
    'orical',
    'ĠPer',
    'cy',
    'ĠRw',
    'anda',
    ',',
    '.',
    'B',
    'ruce',
    'Ġtra',
    'umat',
    'ĠParliament',
    'ary',
    'Ġfoot',
    'note',
    'id',
    'ia',
    'ĠLear',
    'ned',
    'se',
    'eking',
    'gen',
    'ic',
    'Ġdim',
    'ensional',
    'H',
    'ide',
    'èĢ',
    'ħ',
    'Ġintrig',
    'ue',
    'in',
    'se',
    'Ġle',
    'ases',
    'Ġapp',
    'rentices',
    'w',
    'ashing',
    'Ġ19',
    '26',
    'V',
    'ILLE',
    'Ġsw',
    'oop',
    's',
    'cl',
    'Ġbed',
    'rooms',
    'on',
    'ics',
    'ĠCr',
    'unch',
    'comp',
    'atible',
    'Ġincap',
    'ac',
    'ĠYemen',
    'i',
    'ash',
    'tra',
    'z',
    'hou',
    'd',
    'anger',
    'Ġmanifest',
    'ations',
    'ĠDem',
    'ons',
    'AA',
    'F',
    'Secret',
    'ary',
    'ACT',
    'ED',
    'L',
    'OD',
    'Ġam',
    'y',
    'ra',
    'per',
    'eth',
    'nic',
    '4',
    '17',
    'Ġpos',
    'itives',
    'Ġ27',
    '3',
    'ĠRefuge',
    'es',
    'Ġus',
    'b',
    'ĠV',
    'ald',
    'odd',
    'y',
    'ĠMahm',
    'oud',
    'As',
    'ia',
    'Ġskull',
    's',
    'ĠEx',
    'odus',
    'ĠComp',
    'et',
    'ĠL',
    'IC',
    'ĠM',
    'ansion',
    'ĠA',
    'me',
    'Ġconsolid',
    'ate',
    'storm',
    's',
    'ont',
    'ent',
    '99',
    '6',
    'Ġcl',
    'en',
    'Ġm',
    'ummy',
    'fl',
    'at',
    '75',
    '8',
    'ĠV',
    'OL',
    'oter',
    'ic',
    'n',
    'en',
    'ĠMin',
    'ute',
    'S',
    'ov',
    'Ġfin',
    'er',
    'R',
    'h',
    'ly',
    'cer',
    'Ġreinforce',
    'ments',
    'ĠJohann',
    'es',
    'ĠGall',
    'agher',
    'Ġgym',
    'n',
    'S',
    'uddenly',
    'Ġext',
    'ortion',
    'k',
    'r',
    'i',
    'ator',
    'T',
    'a',
    'Ġhippocamp',
    'us',
    'N',
    'PR',
    'ĠComput',
    'ing',
    'Ġsquare',
    'ly',
    'Ġmod',
    'elling',
    'ĠFor',
    'ums',
    'ĠL',
    'isp',
    'ĠKrish',
    'na',
    'Ġ3',
    '24',
    'Ġr',
    'ushes',
    'Ġens',
    'ued',
    'Ġcre',
    'eping',
    'on',
    'te',
    'n',
    'ai',
    'il',
    'ater',
    'ĠHorn',
    'ets',
    'Ġob',
    'livious',
    'IN',
    'ST',
    '55',
    '9',
    'Ġjeopard',
    'y',
    'Ġdistingu',
    'ishing',
    'j',
    'ured',
    'Ġbeg',
    's',
    'sim',
    'ilar',
    'ph',
    'ot',
    '5',
    '30',
    'ĠPark',
    'way',
    'Ġs',
    'inks',
    'ĠHearth',
    'stone',
    'ib',
    'ur',
    'ĠBat',
    'on',
    'Av',
    'oid',
    'Ġd',
    'ancer',
    'Ġmag',
    'istrate',
    'ary',
    'n',
    'Ġdisturb',
    'ances',
    'ĠRom',
    'ero',
    'Ġpar',
    'aph',
    'Ġmis',
    'chief',
    'âĸ',
    'ĵ',
    'ĠSh',
    'aria',
    'Ġur',
    'inary',
    'r',
    'oute',
    'iv',
    'as',
    'f',
    'itted',
    'Ġeject',
    'ed',
    'ĠAl',
    'buquerque',
    'Ġ4',
    '70',
    'Ġirrit',
    'ated',
    'ĠZ',
    'ip',
    'ĠB',
    'iol',
    'Ã',
    'į',
    'Ġden',
    'ounce',
    'Ġbin',
    'aries',
    'ĠVer',
    'se',
    'Ġopp',
    'os',
    'ĠKend',
    'rick',
    'ĠG',
    'PL',
    'Ġsp',
    'ew',
    'ĠEl',
    'ijah',
    'ĠE',
    'as',
    'Ġdr',
    'ifted',
    'so',
    'far',
    'Ġannoy',
    'ance',
    'ĠB',
    'ET',
    '47',
    '4',
    'ĠSt',
    'rongh',
    'it',
    'ates',
    'ĠCogn',
    'itive',
    'oph',
    'one',
    'ĠIdent',
    'ification',
    'ocr',
    'ine',
    'connect',
    'ion',
    'Ġbox',
    'er',
    'ĠAS',
    'D',
    'ĠAre',
    'as',
    'Y',
    'ang',
    't',
    'ch',
    'ull',
    'ah',
    'Ġdece',
    'ive',
    'Comb',
    'at',
    'ep',
    'isode',
    'cre',
    'te',
    'W',
    'itness',
    'Ġcondol',
    'ences',
    'ht',
    'ar',
    'Ġhe',
    'als',
    'Ġbuck',
    'ets',
    'ĠLA',
    'W',
    'B',
    'lu',
    'Ġsl',
    'ab',
    'ĠOR',
    'DER',
    'oc',
    'l',
    'att',
    'on',
    'ĠSteven',
    'son',
    'ĠG',
    'inger',
    'ĠFriend',
    'ly',
    'ĠVander',
    'bilt',
    'sp',
    'irit',
    'ig',
    'l',
    'ĠReg',
    'arding',
    'ĠPR',
    'OG',
    'Ġse',
    'aling',
    'start',
    'ing',
    'Ġcard',
    'inal',
    'ĠV',
    'ec',
    'ĠBe',
    'ir',
    'Ġmillisec',
    'onds',
    'we',
    'ak',
    'per',
    'se',
    'Ġster',
    'ile',
    'ĠCont',
    'emporary',
    'ĠPh',
    'ant',
    'ĠCl',
    'o',
    'Ġout',
    'p',
    'Ġex',
    'iled',
    'Ġ27',
    '7',
    'Ġself',
    'ie',
    'Ġman',
    'ic',
    'Ġn',
    'ano',
    'ter',
    'ms',
    'Alex',
    'ander',
    'Ġres',
    'olves',
    'Ġmillenn',
    'ia',
    'Ġexpl',
    'odes',
    'Ġconst',
    'ellation',
    'Ġadul',
    'tery',
    'm',
    'otion',
    'D',
    'OC',
    'Ġbroad',
    'casters',
    'Ġkinderg',
    'arten',
    'ĠMay',
    'weather',
    'ĠE',
    'co',
    'ich',
    'o',
    'Ġ28',
    '7',
    'l',
    'aun',
    'Ġm',
    'ute',
    'Ġdisc',
    'reet',
    'Ġpres',
    'chool',
    'Ġpre',
    'empt',
    'De',
    'lete',
    'ĠFre',
    'ed',
    'P',
    'i',
    'H',
    'K',
    'Ġblock',
    'er',
    'ĠC',
    'umber',
    'Ġw',
    'rought',
    'd',
    'ating',
    'Ġins',
    'urer',
    'Ġquot',
    'as',
    'Ġpre',
    'ached',
    'Ġev',
    'iction',
    'ĠReg',
    'ina',
    'ĠP',
    'ens',
    'Ġsevent',
    'een',
    'ĠN',
    'ass',
    'D',
    'ick',
    'Ġfold',
    's',
    'Ġd',
    'otted',
    'ĠA',
    'ad',
    'Un',
    'iversal',
    'Ġp',
    'izz',
    'ĠG',
    'uru',
    'Ġso',
    'ils',
    'Ġno',
    'vice',
    'ĠNe',
    'ander',
    'Ġst',
    'ool',
    'Ġdeton',
    'ated',
    'ĠPik',
    'achu',
    'ĠMass',
    'ive',
    'IV',
    'ER',
    'ĠAb',
    'del',
    'Ġsubdu',
    'ed',
    'Ġtall',
    'est',
    'Ġprec',
    'arious',
    'Ġa',
    'y',
    'r',
    'ification',
    'ĠOb',
    'j',
    'c',
    'ale',
    'Ġun',
    'question',
    'cul',
    'osis',
    'ad',
    'as',
    'igr',
    'ated',
    'D',
    'ays',
    'Ġque',
    'ens',
    'ĠGaz',
    'ette',
    'ĠCol',
    'our',
    'ĠBow',
    'man',
    'ĠJ',
    'J',
    'Ã¯',
    've',
    'Ġdomin',
    'ates',
    'Stud',
    'ent',
    'Ġm',
    'u',
    'Ġback',
    'log',
    'ĠElect',
    'ro',
    'Tr',
    'uth',
    '48',
    '3',
    'Ġcond',
    'ensed',
    'r',
    'ules',
    'ĠCons',
    'piracy',
    'Ġacron',
    'ym',
    'hand',
    'led',
    'ĠMat',
    'te',
    'j',
    'ri',
    'ĠImp',
    'ossible',
    'l',
    'ude',
    'cre',
    'ation',
    'Ġwar',
    'med',
    'ĠSl',
    'ave',
    'Ġmis',
    'led',
    'Ġfer',
    'ment',
    'ĠK',
    'ah',
    'ink',
    'i',
    'ke',
    'leton',
    'cy',
    'l',
    'ĠKar',
    'in',
    'Hun',
    'ter',
    'Reg',
    'ister',
    'ĠSur',
    'rey',
    'Ġst',
    'ares',
    'ĠW',
    'idth',
    'ĠN',
    'ay',
    'ĠSk',
    'i',
    'Ġblack',
    'list',
    'uck',
    'et',
    'Ġexp',
    'ulsion',
    'im',
    'et',
    'Ġret',
    'weet',
    'vant',
    'age',
    'Fe',
    'ature',
    'Ġtro',
    'opers',
    'Ġhom',
    'ers',
    '9',
    '69',
    'Ġconting',
    'ency',
    'ĠW',
    'TC',
    'ĠBrew',
    'er',
    'fore',
    'ign',
    'W',
    'are',
    'S',
    'olar',
    'Ġund',
    'ue',
    'RE',
    'C',
    'ulner',
    'able',
    'path',
    'ic',
    'ĠBo',
    'ise',
    'Ġ3',
    '22',
    'Ġarous',
    'ed',
    'ĠY',
    'ing',
    'ä¸',
    'į',
    'uel',
    'ess',
    'Ġp',
    'as',
    'Ġmor',
    'p',
    'Ġfl',
    'oral',
    'Ex',
    'press',
    'ud',
    'ging',
    'k',
    'B',
    'ĠGr',
    'anted',
    'Ø',
    '¯',
    'ĠMich',
    'a',
    'ĠGoth',
    'ic',
    'ĠSPEC',
    'IAL',
    'ĠRic',
    'ardo',
    'F',
    'ran',
    'Ġadminister',
    'ing',
    '6',
    '20',
    'por',
    'a',
    'ĠÂ',
    '®',
    'Ġcomprom',
    'ises',
    'Ġb',
    'itten',
    'Ac',
    'cept',
    'Th',
    'irty',
    'Ð',
    '²',
    'Ġmater',
    'ially',
    'ĠTer',
    'r',
    'ig',
    'matic',
    'ch',
    'ains',
    'Ġdo',
    've',
    'stad',
    't',
    'Mar',
    'vel',
    'FA',
    'ULT',
    'Ġwind',
    'shield',
    'Ġ3',
    '36',
    'ad',
    'ier',
    'Ġsw',
    'apping',
    'Ġflaw',
    'less',
    'ĠPred',
    'ator',
    'ĠMiche',
    'le',
    'Ġprop',
    'ulsion',
    'ĠPsych',
    'ic',
    'Ġassign',
    'ing',
    'Ġfabric',
    'ation',
    'Ġbar',
    'ley',
    'l',
    'ust',
    'Ġtow',
    'ering',
    'Ġalter',
    'cation',
    'ĠBent',
    'ley',
    'Sp',
    'here',
    'Ġtun',
    'a',
    'ĠClass',
    'es',
    'Fre',
    'edom',
    'un',
    'er',
    'L',
    'ady',
    'v',
    'oice',
    'Ġcool',
    'est',
    'or',
    'r',
    'Ġpal',
    'p',
    '$',
    '{',
    'Ġhyster',
    'ia',
    'ĠMet',
    'atron',
    'p',
    'ants',
    'Ġspawn',
    'ing',
    'Exper',
    'ts',
    'ĠInvest',
    'ors',
    'ĠAn',
    'archy',
    'Ġshr',
    'unk',
    'ĠVict',
    'im',
    'Ġ28',
    '9',
    'Ġec',
    'stasy',
    'ĠB',
    'inding',
    '58',
    '5',
    'ĠMel',
    'ody',
    '57',
    '8',
    'ot',
    'ally',
    'ĠE',
    'tsy',
    'lig',
    'a',
    'Ġapplaud',
    'ed',
    'Ġswe',
    'ating',
    'Ġredist',
    'ributed',
    'Ġpop',
    'corn',
    'Ġsem',
    'inal',
    'f',
    'ur',
    'ĠNeuro',
    'science',
    'R',
    'and',
    'ĠO',
    'st',
    'ĠMadd',
    'en',
    'ĠIncre',
    'asing',
    'ĠDaw',
    'kins',
    'ĠSub',
    'way',
    'Ġar',
    'sen',
    'cons',
    'erv',
    'B',
    'UR',
    'Ġsp',
    'iked',
    'ĠLy',
    'ft',
    'ĠImper',
    'ium',
    'ĠDrop',
    'box',
    'Ġfav',
    'oured',
    'Ġencomp',
    'asses',
    'gh',
    'ost',
    'Ġins',
    'pires',
    'Ġbur',
    'geoning',
    'ĠY',
    'oshi',
    'ĠVert',
    'ical',
    'ĠAud',
    'itor',
    'Ġint',
    'ending',
    'Ġfilib',
    'uster',
    'Bl',
    'oom',
    'f',
    'ac',
    'ĠCav',
    's',
    'ign',
    'ing',
    'Ġcowork',
    'ers',
    'ĠBarb',
    'arian',
    'rem',
    'ember',
    'FL',
    'AG',
    'Ġaudit',
    'ory',
    'ason',
    'ry',
    'Col',
    'lege',
    'Ġmut',
    'ed',
    'gem',
    'ony',
    'ob',
    'in',
    'ĠPsych',
    'o',
    '9',
    '68',
    'Ġlav',
    'ish',
    'Ġhierarch',
    'ical',
    'ĠDr',
    'one',
    'ou',
    'k',
    'Ġcripp',
    'led',
    'ĠMax',
    'im',
    'Sl',
    'ot',
    'Ġqu',
    'iz',
    'ĠV',
    'id',
    'if',
    'ling',
    'Ġarchae',
    'ologists',
    'Ġabandon',
    'ment',
    'd',
    'ial',
    'le',
    'on',
    'ĠF',
    'as',
    'T',
    'ed',
    'Ġr',
    'aspberry',
    'Ġmaneu',
    'vers',
    'Ġbehavi',
    'ours',
    'Ġins',
    'ure',
    'Ġrem',
    'od',
    'Sw',
    'itch',
    'h',
    'oe',
    'Ġsp',
    'aced',
    'Ġafford',
    'ability',
    'ĠF',
    'ern',
    'not',
    'ation',
    'ĠBal',
    'anced',
    'Ġoccup',
    'ies',
    'en',
    'vironment',
    'Ġneck',
    'lace',
    'Ġsed',
    'an',
    'F',
    'U',
    'ĠBrav',
    'o',
    'Ġab',
    'users',
    'ĠAn',
    'ita',
    'met',
    'adata',
    'ĠG',
    'ithub',
    'ait',
    'o',
    'ĠF',
    'aster',
    'ĠWass',
    'erman',
    'ĠF',
    'lesh',
    'Ġth',
    'orn',
    'r',
    'arily',
    'ĠMer',
    'ry',
    'w',
    'ine',
    'Ġpopul',
    'ace',
    'ĠL',
    'ann',
    'Ġrepair',
    'ing',
    'Ġpsy',
    'che',
    'Ġmod',
    'ulation',
    'aw',
    'aru',
    'âĢĭ',
    'âĢĭ',
    'ari',
    'j',
    'Ġdecor',
    'ations',
    'Ġapolog',
    'ise',
    'ĠG',
    'arg',
    'app',
    'ly',
    'Ġgive',
    'away',
    'ĠFl',
    'an',
    'ĠWy',
    'att',
    'U',
    'ber',
    'Ġauthor',
    'ised',
    'ĠMor',
    'al',
    'HAHA',
    'HAHA',
    'activ',
    'ate',
    'Ġtorped',
    'o',
    'ĠF',
    'AR',
    'Ġam',
    'assed',
    'ĠA',
    'ram',
    'ark',
    'in',
    'ĠVict',
    'ims',
    'st',
    'ab',
    'Ġo',
    'm',
    'ĠE',
    'CO',
    'Ġopio',
    'ids',
    'Ġpurpose',
    'ly',
    'ĠV',
    'est',
    'Ġer',
    'g',
    'at',
    'an',
    'ĠSur',
    'gery',
    'Ġcorrect',
    'ing',
    'ĠOrt',
    'iz',
    'ĠBe',
    'et',
    'Ġrev',
    'oke',
    'Ġfre',
    'eway',
    'ĠH',
    'iggins',
    'F',
    'ail',
    'ĠFar',
    'ms',
    'ĠAT',
    'P',
    'h',
    'ound',
    'Ġp',
    'oking',
    'ĠCommun',
    'ists',
    'mon',
    'ster',
    'iment',
    'ary',
    'Ġunlock',
    'ing',
    'Ġunf',
    'it',
    'we',
    'ed',
    'en',
    'ario',
    'at',
    'ical',
    'ĠEnlight',
    'enment',
    'ĠN',
    'G',
    'ĠComp',
    'ensation',
    'de',
    'en',
    'ĠWid',
    'ow',
    'ĠCind',
    'y',
    'ĠAfter',
    'wards',
    'Ġ6',
    '000',
    'ikh',
    'ail',
    'ag',
    'ically',
    'Ġrat',
    'ified',
    'Ġcasual',
    'ty',
    'H',
    'OME',
    'p',
    'sey',
    'f',
    'ee',
    'Ġspark',
    'ling',
    'Ġd',
    'Ã©',
    'Ġconcert',
    'ed',
    'C',
    'atal',
    'Ġcomp',
    'lying',
    'ĠA',
    'res',
    'ĠD',
    'ent',
    'Sh',
    'ut',
    'Ġsk',
    'im',
    'ad',
    'minist',
    'Ġhost',
    'ilities',
    'ĠG',
    'ins',
    'Ġ6',
    '08',
    'Ġm',
    'uddy',
    'ĠMc',
    'Int',
    'ĠDec',
    'ay',
    '5',
    '25',
    'Ġconspic',
    'uous',
    'ĠEx',
    'posure',
    'Ġresc',
    'ind',
    'Ġwear',
    'able',
    'Ġ3',
    '28',
    'our',
    'met',
    'ah',
    's',
    'ĠRob',
    'ots',
    'Ġe',
    'clips',
    'inst',
    'ance',
    'ĠRE',
    'PORT',
    'ĠApp',
    'l',
    '0',
    '30',
    'ĠSk',
    'ies',
    '01',
    '00',
    'Ġfall',
    'acy',
    'S',
    'ocket',
    'ĠRece',
    'iver',
    'Ġsol',
    'ves',
    'ĠButter',
    'fly',
    'ĠSho',
    'pping',
    'ĠFI',
    'RE',
    '65',
    '4',
    'Med',
    'ic',
    'Ġsing',
    'ers',
    'ĠNeed',
    'less',
    "''",
    "''",
    'isher',
    's',
    'ĠD',
    'ive',
    '58',
    '8',
    'Ġselect',
    'ively',
    'Ġcl',
    'umsy',
    '88',
    '9',
    'Ġpurch',
    'aser',
    'ear',
    'ned',
    'ard',
    'y',
    'Ġbenef',
    'iting',
    'eng',
    'lish',
    'Ġyield',
    'ing',
    'ĠP',
    'our',
    'Ġspin',
    'ach',
    'Ġdel',
    've',
    'ĠC',
    'rom',
    '6',
    '10',
    'Ġexport',
    'ing',
    'ĠMA',
    'KE',
    'Ġ26',
    '3',
    'Ġg',
    'rop',
    'Ġenv',
    'oy',
    'ĠInqu',
    'iry',
    'ĠLu',
    'igi',
    'd',
    'ry',
    'ĠT',
    'uring',
    'Thumbnail',
    'Image',
    'ĠVar',
    'iety',
    'Ġfac',
    'et',
    'Ġfl',
    'uffy',
    'Ġexcerpt',
    's',
    'Ġsh',
    'orth',
    'ĠOl',
    'sen',
    'CL',
    'UD',
    'Ġrel',
    'iant',
    'ĠUN',
    'C',
    'T',
    'our',
    'Ġbat',
    'hing',
    'Comp',
    'any',
    'Ġglobal',
    'ization',
    'P',
    'red',
    'ĠMalf',
    'oy',
    'Ġh',
    'oc',
    'j',
    'am',
    'craft',
    'ed',
    'ĠBond',
    's',
    'ĠKiss',
    'inger',
    'Eng',
    'land',
    'Ġorder',
    'ly',
    'cat',
    'entry',
    'Ġ26',
    '1',
    'Ġexch',
    'anging',
    'ĠInt',
    'ent',
    'ĠAmend',
    'ments',
    'D',
    'OM',
    'Ġst',
    'out',
    'ÂłÂłÂłÂłÂłÂłÂłÂł',
    'ÂłÂłÂłÂłÂłÂłÂłÂł',
    'ĠAir',
    'bus',
    'Ġ27',
    '8',
    'hy',
    'de',
    'P',
    'oll',
    'Item',
    'ThumbnailImage',
    'Ġlooph',
    'oles',
    'ĠPill',
    'ar',
    'Ġexpl',
    'or',
    'St',
    'retch',
    'A',
    'part',
    'Ġun',
    'married',
    'Lim',
    'it',
    'ĠTransform',
    'ers',
    'Ġintellect',
    'ually',
    'unct',
    'ure',
    '18',
    '00',
    'Ġd',
    'arn',
    'B',
    'razil',
    'Ġleft',
    'over',
    'ber',
    'us',
    'f',
    'red',
    'Mine',
    'craft',
    '3',
    '26',
    'ĠForm',
    's',
    'Ġproof',
    's',
    'ĠDes',
    'igned',
    'Ġindex',
    'es',
    'ĠSupp',
    'ose',
    'EM',
    'S',
    'ĠL',
    'oving',
    'ĠBon',
    'nie',
    'im',
    'ating',
    'OT',
    'US',
    'Ġconduct',
    'or',
    'Ġbehav',
    'ed',
    'ĠF',
    'ren',
    'Ġsy',
    'nerg',
    'Ġmillenn',
    'ium',
    'Ġcater',
    'ing',
    'ĠL',
    'auder',
    'W',
    'r',
    'ĠY',
    'iannopoulos',
    'ĠAT',
    'F',
    'Ġensl',
    'aved',
    'Ġawaken',
    'ed',
    'D',
    'VD',
    'ĠED',
    'ITION',
    'ĠConc',
    'ert',
    'ĠChall',
    'enger',
    'ĠH',
    'aku',
    'umer',
    'ic',
    'Ġdep',
    'recated',
    'ĠSH',
    'AR',
    '4',
    '12',
    'Ġdy',
    'stop',
    'Ġtremb',
    'ling',
    'Ġdread',
    'ed',
    'ĠSp',
    'ac',
    'p',
    'adding',
    'Re',
    'pl',
    'ĠG',
    'arrison',
    'M',
    'ini',
    'Ġun',
    'paralleled',
    'am',
    'ar',
    'URR',
    'ENT',
    'w',
    'reck',
    'c',
    'ertain',
    't',
    'al',
    'ĠC',
    'LS',
    'app',
    'ings',
    'Ġsens',
    'ed',
    'Ġf',
    'encing',
    'ĠPas',
    'o',
    'ĠDes',
    'k',
    'Ġsc',
    'off',
    'Ġcontem',
    'plate',
    'ĠL',
    'iga',
    'l',
    'iquid',
    '75',
    '7',
    'Ġapp',
    'rentice',
    'ĠUCH',
    'IJ',
    '5',
    '70',
    'ĠTh',
    'ousand',
    'ĠIll',
    'um',
    'Ġchampion',
    'ed',
    'ãĤ',
    'Į',
    'Ġelect',
    'ors',
    'Ġ3',
    '98',
    'ĠH',
    'ancock',
    'round',
    'ed',
    'ĠJ',
    'OHN',
    'Ġuns',
    'atisf',
    'Ġqual',
    'ifier',
    'ĠGad',
    'get',
    'EN',
    'E',
    'Ġdead',
    'liest',
    'ĠPl',
    'ants',
    'Ġ',
    'ions',
    'Ġacc',
    'ents',
    'Ġtwe',
    'aking',
    'Ġsh',
    'aved',
    'F',
    'REE',
    'ĠCh',
    'aser',
    'Again',
    'st',
    '9',
    '60',
    'Ġmeth',
    'amphetamine',
    'Ġnormal',
    'ized',
    'Ġ$',
    '\\',
    'ĠPre',
    'cision',
    'ĠGu',
    'am',
    'Ġch',
    'oked',
    'ĠX',
    'II',
    'ĠCast',
    'ing',
    'Tor',
    'rent',
    'Ġscal',
    'p',
    'ĠJagu',
    'ar',
    'w',
    'it',
    'Ġsem',
    'ic',
    'ix',
    'ie',
    'ĠG',
    'ould',
    'Ġconf',
    'ines',
    'N',
    'usra',
    'ĠL',
    'on',
    'ĠJ',
    'ugg',
    'y',
    'cle',
    'ĠCod',
    'ec',
    'E',
    'gypt',
    'Ġrest',
    'rain',
    'ĠAl',
    'iens',
    'Ġch',
    'oking',
    'ĠD',
    'unk',
    'ĠBell',
    'a',
    'ab',
    'c',
    'Ġsl',
    'ang',
    'Ġneuro',
    'trans',
    's',
    'av',
    'Ġempower',
    'ment',
    'â',
    'ĨĴ',
    'Ġclim',
    'bers',
    'ĠM',
    'im',
    'ĠF',
    'ra',
    'ros',
    'se',
    'Cap',
    'ital',
    'ĠCth',
    'ulhu',
    'Inter',
    'face',
    'Ġprof',
    'icient',
    'ĠIN',
    'TO',
    'Ġ3',
    '18',
    'ront',
    'al',
    '5',
    '80',
    'ĠDes',
    'pair',
    'K',
    'enn',
    'Ġscrim',
    'mage',
    'ĠCo',
    'at',
    'as',
    'ions',
    'Ġwall',
    'paper',
    'ĠJ',
    'ol',
    'Ġresurg',
    'ence',
    'Ġant',
    'iv',
    'ĠB',
    'alls',
    '²',
    '¾',
    'Ġbuff',
    'ers',
    'Ġsub',
    'system',
    'ĠSt',
    'ellar',
    'ĠL',
    'ung',
    'A',
    'IDS',
    'Ġerad',
    'icate',
    'Ġblat',
    'antly',
    'Ġbehav',
    'es',
    'ĠN',
    'un',
    'Ġant',
    'ics',
    'ex',
    'port',
    'DE',
    'V',
    'w',
    'b',
    'Ġph',
    'p',
    'ĠInteg',
    'rity',
    'Ġexplore',
    'r',
    'Ġrev',
    'olving',
    'auth',
    'ored',
    'g',
    'ans',
    'Ġbas',
    'k',
    'Ġas',
    'ynchronous',
    'å',
    'į',
    'TH',
    'ING',
    '69',
    '8',
    'G',
    'ene',
    'ĠR',
    'acer',
    'ĠN',
    'ico',
    'iss',
    'ued',
    'Ġser',
    'mon',
    'p',
    'ossibly',
    'Ġsize',
    'of',
    'Ġentrepreneur',
    'ial',
    'ox',
    'in',
    'ĠMin',
    'erva',
    'Ġpl',
    'atoon',
    'n',
    'os',
    'ri',
    'ks',
    'A',
    'UT',
    'ĠAval',
    'anche',
    'ĠDes',
    'c',
    'ĳ',
    'å£«',
    'ĠP',
    'oc',
    'Ġconf',
    'erred',
    'Î',
    '»',
    'Ġpat',
    'ched',
    'F',
    'BI',
    '66',
    '2',
    'Ġfract',
    'ures',
    'Ġdetect',
    's',
    'Ġded',
    'icate',
    'Ġconstitu',
    'ent',
    'Ġcos',
    'mos',
    'W',
    'T',
    'Ġswe',
    'ats',
    'Ġspr',
    'ung',
    'b',
    'ara',
    's',
    'olid',
    'Ġuns',
    'us',
    'Ġbul',
    'ky',
    'ĠPhilipp',
    'e',
    'ĠFen',
    'rir',
    'Ġtherap',
    'ists',
    'ore',
    'al',
    '^^',
    '^^',
    'Ġtotal',
    'ed',
    'Ġboo',
    'ze',
    'ĠR',
    'PC',
    'Prosecut',
    'ors',
    'Ġdis',
    'eng',
    'ĠSh',
    'ared',
    'Ġmotor',
    'cycles',
    'Ġinvent',
    'ions',
    'Ġlett',
    'uce',
    'ĠMer',
    'ge',
    'ĠJ',
    'C',
    'Ġspiritual',
    'ity',
    'ĠWAR',
    'NING',
    'Ġunl',
    'ucky',
    'ĠT',
    'ess',
    'Ġtong',
    'ues',
    'ĠD',
    'UI',
    'T',
    'umblr',
    'Ġle',
    'ans',
    'Ġinv',
    'aders',
    'Ġcan',
    'opy',
    'ĠHur',
    'ricanes',
    'ĠB',
    'ret',
    'ĠAP',
    'PLIC',
    'id',
    'ine',
    'ick',
    'le',
    'Reg',
    'arding',
    'Ġve',
    'ggies',
    'Ġe',
    'jac',
    'ju',
    'ven',
    'F',
    'ish',
    'D',
    'EM',
    'ĠD',
    'ino',
    'Th',
    'row',
    'ĠCheck',
    'ing',
    'be',
    'ard',
    '(',
    '&',
    'Ġj',
    'ails',
    'Ġh',
    'r',
    'trans',
    'fer',
    'iv',
    'ating',
    'Ġfle',
    'ets',
    'ĠIm',
    'ag',
    'ĠMc',
    'Donnell',
    'Ġsnipp',
    'et',
    'Is',
    'a',
    'ĠCh',
    'att',
    'ĠSt',
    'ain',
    'ĠSet',
    'FontSize',
    'ĠO',
    'y',
    'ĠMathemat',
    'ics',
    '49',
    '4',
    'Ġelectro',
    'ly',
    'ĠG',
    'ott',
    'ĠBr',
    'as',
    'B',
    'OOK',
    'ĠF',
    'inger',
    'd',
    'ump',
    'Ġmut',
    'ants',
    'Ġrent',
    'als',
    'Ġinter',
    'tw',
    'Ġc',
    'reek',
    'ail',
    'a',
    'Bro',
    'ther',
    'ĠDisc',
    'ord',
    'pe',
    'e',
    'raw',
    'ler',
    'Ġcar',
    'p',
    'Ġ27',
    '9',
    'ãĤ·',
    'ãĥ£',
    'rel',
    'ations',
    'Ġcontr',
    'asts',
    'Col',
    'umn',
    'Ġrec',
    'onnaissance',
    'Ġun',
    'know',
    'Ġl',
    'ooting',
    'Ġregul',
    'ates',
    'Ġopt',
    'imum',
    'ĠChero',
    'kee',
    'ĠA',
    'ry',
    'Lat',
    'est',
    'Ġroad',
    'side',
    'Ġd',
    'anced',
    'ĠUnic',
    'orn',
    'A',
    'cknowled',
    'Ġuncont',
    'roll',
    'ĠM',
    'US',
    'at',
    'io',
    'ch',
    'ance',
    'ha',
    'ven',
    'VAL',
    'UE',
    'Ġfavour',
    'ites',
    'Ġceremon',
    'ial',
    'b',
    'inary',
    'pe',
    'ed',
    'wood',
    's',
    'EM',
    'P',
    'Ġv',
    'ascular',
    'Ġcontempl',
    'ated',
    'Ġbar',
    'ren',
    'ĠL',
    'IST',
    'Y',
    'ellow',
    'ospons',
    'ors',
    'Ġwhisk',
    'y',
    'ĠM',
    'amm',
    'ĠDeV',
    'os',
    'min',
    'imum',
    'H',
    'ung',
    '44',
    '2',
    'P',
    'ic',
    'ĠSnap',
    'dragon',
    '77',
    '6',
    'Ġcar',
    'ving',
    'Ġund',
    'ecided',
    'Ġadvantage',
    'ous',
    'Ġpal',
    'ms',
    'ĠA',
    'Q',
    'Ġst',
    'arch',
    'L',
    'oop',
    'Ġpadd',
    'le',
    'Ġfl',
    'aming',
    'ĠHor',
    'izons',
    'An',
    'imation',
    'bo',
    'ost',
    'Ġprob',
    'abilities',
    'ĠM',
    'ish',
    'Ġex',
    'odus',
    'ĠEditor',
    'ial',
    'Ġfung',
    'us',
    'Ġdissent',
    'ing',
    'ĠDel',
    'icious',
    'rog',
    'ram',
    'ĠD',
    'yn',
    'd',
    'isk',
    't',
    'om',
    'Ġfab',
    'rics',
    'ĠC',
    'ove',
    'ĠB',
    'ans',
    'Ġsoft',
    'en',
    'ĠCON',
    'S',
    'Ġin',
    'eligible',
    'Ġestim',
    'ating',
    'ĠLex',
    'ington',
    'pract',
    'ice',
    'of',
    'i',
    'Ġshe',
    'dding',
    'ĠN',
    'ope',
    'Ġbreat',
    'hed',
    'ĠCorinth',
    'ians',
    'y',
    'ne',
    'ek',
    'i',
    'B',
    'ull',
    'Ġatt',
    'aching',
    'reens',
    'hots',
    'Ġanaly',
    'se',
    'ĠK',
    'appa',
    'Ġuns',
    'ustainable',
    'Ġinter',
    'pol',
    'ank',
    'y',
    'he',
    'mer',
    'Ġprot',
    'agonists',
    'Ġform',
    'atted',
    'ĠBry',
    'ce',
    'ĠAch',
    'illes',
    'ĠAb',
    'edin',
    'sh',
    'ock',
    'Ġb',
    'um',
    'b',
    'os',
    'qu',
    'a',
    'ĠW',
    'arn',
    'q',
    't',
    'ĠDi',
    'abetes',
    '8',
    '64',
    'ĠIn',
    'visible',
    'Ġvan',
    'ish',
    'Ġtrans',
    'mitting',
    'Ġmur',
    'ky',
    'ĠFe',
    'i',
    'Ġawa',
    'ited',
    'ĠJur',
    'assic',
    'umm',
    'ies',
    'Ġmen',
    'acing',
    'g',
    'all',
    'C',
    'ath',
    'B',
    'uilt',
    'ild',
    'o',
    'ĠV',
    'otes',
    'Ġon',
    't',
    'Ġmun',
    'itions',
    'ĠFre',
    'em',
    'ÃŃ',
    'n',
    'Ġdec',
    'ency',
    'lo',
    'pp',
    'ie',
    'ved',
    'ĠG',
    'ord',
    'Ġun',
    'thinkable',
    'ĠNews',
    'week',
    'Ġ3',
    '21',
    'He',
    'at',
    'Ġpresent',
    'er',
    'ji',
    'ang',
    'Ġpl',
    'ank',
    'ĠAval',
    'on',
    'Ġben',
    'z',
    'ĠR',
    'out',
    'Ġslam',
    'ming',
    'ĠD',
    'ai',
    'ou',
    'ter',
    'ĠCook',
    'ie',
    'ĠAlic',
    'ia',
    'ge',
    'y',
    'Ġvan',
    'ity',
    'Ġow',
    'l',
    'á',
    'µ',
    't',
    'ested',
    'ĠAw',
    'akens',
    'Ġcan',
    'v',
    'Ġblind',
    'ly',
    'ĠRid',
    'ley',
    'ĠEm',
    'ails',
    'Requ',
    'ires',
    'ĠSer',
    'bian',
    'ograp',
    'hed',
    'if',
    'rame',
    'eter',
    'ia',
    'Ġaltern',
    'ating',
    'qu',
    'iet',
    'Ġsoc',
    'iology',
    'ĠUn',
    'lock',
    'ĠCommun',
    'ism',
    'Ġo',
    'ps',
    'Ġatt',
    'ribution',
    'Ġab',
    'duction',
    'ĠAb',
    'ram',
    'Ġsidel',
    'ined',
    'ĠB',
    'OOK',
    'Ġref',
    'ining',
    'ĠFe',
    'eling',
    'ĠOs',
    'lo',
    'ĠPru',
    'itt',
    'r',
    'ack',
    'ang',
    'ible',
    'Ġcaut',
    'iously',
    'ĠM',
    'ARK',
    'eed',
    's',
    'M',
    'ouse',
    'ĠStep',
    'h',
    'ĠP',
    'air',
    'S',
    'ab',
    '99',
    '7',
    'ĠBa',
    'al',
    'B',
    'ec',
    'Ġcomm',
    'a',
    'ĠP',
    'all',
    'ĠG',
    'ael',
    'Ġmisunder',
    'stand',
    'ĠP',
    'esh',
    'Order',
    'able',
    'Ġdis',
    'mal',
    'ĠSh',
    'iny',
    '%',
    '"',
    'Ġreal',
    'istically',
    'Ġpat',
    'io',
    'ĠG',
    'w',
    'ĠVirt',
    'ue',
    'Ġexhaust',
    'ing',
    'wh',
    'atever',
    'oph',
    'ys',
    'y',
    'ip',
    '4',
    '18',
    'Ad',
    'just',
    'ĠWa',
    'iting',
    'ess',
    'on',
    'ĠMaz',
    'da',
    'ĠDo',
    'zens',
    'Ġstream',
    'lined',
    'Ġincompet',
    'ence',
    'ĠM',
    'eth',
    'Ġeth',
    'os',
    'ON',
    'ES',
    'Ġincent',
    'iv',
    'Ġgr',
    'itty',
    'ĠBut',
    'cher',
    'Head',
    'er',
    'Ġexp',
    'onential',
    'Ã',
    'Ł',
    'Ġcorrel',
    'ate',
    'Ġcons',
    'ensual',
    's',
    'ounding',
    'R',
    'ing',
    'Orig',
    'in',
    'Ġcon',
    'clusive',
    'fe',
    'et',
    'ac',
    'ly',
    'ĠF',
    'ernandez',
    'Buy',
    'able',
    'Ġd',
    'ucks',
    'aunt',
    'lets',
    'Ġel',
    'ong',
    'Ġ28',
    '6',
    'Ġsim',
    'ul',
    'G',
    'as',
    'ĠK',
    'irst',
    'Ġprot',
    'r',
    'ĠRob',
    'o',
    'ĠAo',
    'E',
    'op',
    'ol',
    'Ġpsych',
    'ologically',
    'sp',
    'in',
    'ilater',
    'ally',
    'ĠCon',
    'rad',
    'W',
    'ave',
    '44',
    '1',
    'ĠAd',
    'vertisement',
    'ĠHarm',
    'on',
    'ĠOri',
    'ental',
    'is',
    'Special',
    'Ġpresum',
    'ptive',
    'Ġw',
    'il',
    'ĠK',
    'ier',
    'ne',
    'a',
    'Ġp',
    'pm',
    'Ġhar',
    'bour',
    'ĠW',
    'ired',
    'comp',
    'any',
    'Ġcor',
    'oner',
    'atur',
    'days',
    'ĠP',
    'roud',
    'ĠN',
    'EXT',
    'ĠFl',
    'ake',
    'val',
    'ued',
    'ce',
    'iver',
    'Ġfra',
    'ught',
    'Ġc',
    'asing',
    'Ġrun',
    'away',
    'Ġg',
    'in',
    'ĠLaure',
    'nt',
    'ĠHar',
    'lem',
    'ĠCur',
    'iosity',
    'qu',
    'ished',
    'Ġneuro',
    'science',
    'ĠH',
    'ulu',
    'Ġborrow',
    'er',
    'Ġpetition',
    'er',
    'ĠCo',
    'oldown',
    'W',
    'ARD',
    'Ġinv',
    'oking',
    'conf',
    'idence',
    'For',
    'ward',
    'Ġst',
    's',
    'pop',
    'ulation',
    'Delivery',
    'Date',
    'Fil',
    'm',
    'ĠC',
    'ov',
    'quick',
    'Ship',
    'quickShip',
    'Available',
    'prim',
    'ary',
    'isSpecial',
    'Orderable',
    'inventory',
    'Quantity',
    'channel',
    'Availability',
    'BO',
    'X',
    'ĠMulti',
    'player',
    'ĠJen',
    'ner',
    '77',
    '8',
    'ĠM',
    'd',
    'Ġ~',
    '/.',
    'M',
    'N',
    'Ġchild',
    'ish',
    'Ġantioxid',
    'ant',
    'ĠChrom',
    'ebook',
    'Ġ27',
    '4',
    'Ġscreen',
    'play',
    'Ġadvent',
    'urous',
    'ĠRelations',
    'hip',
    'respons',
    'ive',
    'ming',
    'ton',
    'Ġcorner',
    'stone',
    'ĠF',
    'ey',
    'F',
    'IR',
    'Ġrook',
    'ies',
    'ĠF',
    'eaturing',
    'Ġorig',
    'inate',
    'Ġelectro',
    'des',
    'ant',
    'es',
    'Ġscript',
    'ures',
    'Ġgl',
    'ued',
    'Ġdiscont',
    'ent',
    'Ġaff',
    'licted',
    'lay',
    'out',
    'B',
    'rave',
    'Ġm',
    'osa',
    'ĠQuant',
    'ity',
    'ĠH',
    'ik',
    'w',
    'inner',
    'H',
    'ours',
    'Ġent',
    'ail',
    'ĠCell',
    's',
    'olog',
    'ue',
    'Ġv',
    'il',
    'Ġpre',
    'acher',
    'Ġdecor',
    'ative',
    'd',
    'ifferent',
    'Ġprejud',
    'ices',
    'ĠSm',
    'oking',
    'ĠNotting',
    'ham',
    'so',
    'Type',
    'Ġrhyth',
    'ms',
    'ĠAl',
    'ph',
    'bl',
    'ast',
    'Ste',
    'el',
    'ĠDaniel',
    'le',
    'Ġstr',
    'ife',
    'Ġrem',
    'atch',
    'so',
    'DeliveryDate',
    'ĠF',
    'ork',
    't',
    'rip',
    'ol',
    'ulu',
    'hes',
    'es',
    'C',
    'G',
    'ĠPOLIT',
    'ICO',
    'ost',
    'a',
    'ĠDr',
    'ift',
    'é¾įå',
    '¥',
    'é¾įå¥',
    'ĳå£«',
    'Ġvet',
    'ting',
    'ĠJin',
    'ping',
    'ĠRec',
    'ession',
    'Min',
    'or',
    'ĠF',
    'raud',
    'enf',
    'ranch',
    'Ġconven',
    'ed',
    'ĠNA',
    'ACP',
    'ĠMill',
    'ions',
    'ĠFarm',
    'ing',
    'ĠW',
    'oo',
    'ĠFl',
    'are',
    'rit',
    'o',
    'imm',
    'igrant',
    'Ġvac',
    'ancy',
    'ĠHE',
    'AD',
    'ĠV',
    'aj',
    'eg',
    'al',
    'ĠV',
    'igil',
    'Stud',
    'y',
    'Ġru',
    'ining',
    'Ġr',
    'acks',
    'Ġhe',
    'ater',
    'ĠRand',
    'olph',
    'ĠBr',
    'ush',
    'ĠT',
    'ir',
    'Ø',
    '¨',
    'Ġc',
    'ov',
    '%',
    ']',
    'Ġrecount',
    's',
    'ĠO',
    'PT',
    'ĠM',
    'elt',
    'Ġtr',
    'uce',
    'Ġcas',
    'inos',
    'Ġcrus',
    'ade',
    'Ġcarn',
    'age',
    'Ġstri',
    'pe',
    'ĠK',
    'yl',
    'Text',
    'ures',
    'Ġ6',
    '98',
    'Ġpro',
    'clamation',
    'Ġgood',
    'ies',
    'Ġ........',
    '..',
    'pro',
    'claimed',
    'P',
    'olit',
    'Ġtop',
    'ical',
    'Ġspecial',
    'ize',
    'ĠA',
    'min',
    'g',
    'm',
    'Ġanch',
    'ored',
    'Ġbear',
    'ings',
    's',
    'ample',
    'ĠHigh',
    'land',
    'ĠAut',
    'ism',
    'Ġmerc',
    'enary',
    'Ġinterview',
    'er',
    'L',
    'ER',
    'ĠSom',
    'ers',
    'Ġembry',
    'o',
    'ĠAss',
    'y',
    'Ġ28',
    '1',
    'ĠEd',
    'iting',
    'ĠCh',
    'osen',
    '6',
    '60',
    'Ġp',
    'ci',
    'ĠThunder',
    'bolt',
    'BI',
    'LL',
    'Ġchuck',
    'led',
    'jri',
    'wal',
    'h',
    'of',
    'Ġearth',
    'ly',
    '()',
    '{',
    'ind',
    'ependence',
    'Ġdisp',
    'ers',
    'ĠV',
    'endor',
    'ĠG',
    'areth',
    'Ġp',
    'als',
    'P',
    'enn',
    'ĠSub',
    'mit',
    'ic',
    'um',
    'Th',
    'u',
    'Ġcl',
    'andestine',
    'Ġcann',
    'ibal',
    'ĠCl',
    'erk',
    'E',
    'Stream',
    'gal',
    'itarian',
    'âĻ',
    '¥',
    'g',
    'ew',
    'Ġhor',
    'rend',
    'ĠL',
    'ov',
    'ĠRe',
    'action',
    'ocr',
    'in',
    'Class',
    'ic',
    'Ġecho',
    'ing',
    'Ġdiscl',
    'osing',
    'ĠIns',
    'ight',
    'og',
    'un',
    'ĠInc',
    'arn',
    'upload',
    's',
    'pp',
    'erc',
    'guy',
    'en',
    'Ġ19',
    '01',
    'ĠB',
    'ars',
    '68',
    '7',
    'Ġb',
    'ribes',
    'ĠFres',
    'no',
    'ur',
    'at',
    'ĠRe',
    'ese',
    'Ġintr',
    'usive',
    'Ġgri',
    'pping',
    'ĠBlue',
    'print',
    'ĠR',
    'asm',
    'un',
    'ia',
    'man',
    'aged',
    'ĠHeb',
    'do',
    'Ġ3',
    '45',
    'Ġdec',
    'oding',
    'Ġpo',
    'ets',
    'Ġj',
    'aws',
    'ĠF',
    'IGHT',
    'am',
    'eless',
    'ĠMead',
    'ows',
    'ĠHar',
    'baugh',
    'Inter',
    'view',
    'ĠH',
    'osp',
    'ĠB',
    'RA',
    'Ġdelet',
    'ion',
    'm',
    'ob',
    'W',
    'alker',
    'ĠMoon',
    'light',
    'ĠJ',
    'ed',
    'ĠSoph',
    'ia',
    'Ġus',
    'ur',
    'Ġfortun',
    'ately',
    'ĠPut',
    'ting',
    'ĠF',
    'old',
    'Ġsan',
    'itation',
    'Ġpart',
    'isans',
    'IS',
    'ON',
    'B',
    'ow',
    'ĠCON',
    'C',
    'ĠRed',
    'uced',
    'ĠS',
    'utton',
    'Ġtouch',
    'screen',
    'Ġembry',
    'os',
    'âĢ¢âĢ¢',
    'âĢ¢âĢ¢',
    'ĠK',
    'rug',
    'com',
    'bat',
    'ĠPet',
    'roleum',
    'Ġam',
    'd',
    'ĠCos',
    'mos',
    'Ġpresc',
    'ribing',
    'Ġconform',
    'ity',
    'ours',
    'es',
    'Ġplent',
    'iful',
    'Ġdis',
    'illusion',
    'ĠEc',
    'ology',
    'itt',
    'al',
    'Ġf',
    'anc',
    'Ġassass',
    'inated',
    'regn',
    'ancy',
    'Ġperenn',
    'ial',
    'ĠBul',
    'lets',
    'Ġst',
    'ale',
    'Ġc',
    'ached',
    'ĠJud',
    'ith',
    'ĠDise',
    'ases',
    'All',
    'en',
    'Ġl',
    'as',
    'Ġsh',
    'ards',
    'ĠSu',
    'arez',
    'ĠFriend',
    'ship',
    'inter',
    'face',
    'ĠSupp',
    'orters',
    'add',
    'ons',
    '46',
    '2',
    'ĠIm',
    'ran',
    'ĠW',
    'im',
    'Ġnew',
    'found',
    'ĠM',
    'b',
    'An',
    'imal',
    'Ġd',
    'arling',
    'and',
    'e',
    'Ġrh',
    'y',
    'ĠTw',
    'isted',
    'pos',
    'al',
    'yn',
    'ski',
    'Var',
    'ious',
    '×',
    'ľ',
    'ĠK',
    'iw',
    'uy',
    'omi',
    'Ġwell',
    'being',
    'ĠL',
    'au',
    'an',
    'os',
    'Ġunm',
    'ist',
    'Ġmac',
    'OS',
    'Ġrest',
    'room',
    'ĠOl',
    'iv',
    'ĠAir',
    'ways',
    'Ġtimet',
    'able',
    '9',
    '80',
    'Ġrad',
    'ios',
    'v',
    'oy',
    'ias',
    'co',
    'Ġcloud',
    'y',
    'ĠDraw',
    'ing',
    'Any',
    'thing',
    'Sy',
    'ria',
    'ĠH',
    'ert',
    'st',
    'aking',
    'Ġun',
    'checked',
    'Ġb',
    'razen',
    'ĠN',
    'RS',
    '69',
    '7',
    'onom',
    'ic',
    'est',
    'ablish',
    'Ġl',
    'eng',
    'Ġdi',
    'agonal',
    'ĠF',
    'ior',
    'L',
    'air',
    'ĠSt',
    'ard',
    'Ġdef',
    'icient',
    'jo',
    'ining',
    'be',
    'am',
    'Ġomn',
    'ip',
    'Ġbl',
    'ender',
    'Ġsun',
    'rise',
    'Mo',
    'ore',
    'ĠF',
    'ault',
    'ĠCost',
    'ume',
    'ĠM',
    'ub',
    'Fl',
    'ags',
    'an',
    'se',
    'Ġpay',
    'out',
    'ĠGovern',
    'ors',
    'ĠD',
    'illon',
    'ĠBan',
    'ana',
    'N',
    'ar',
    'Ġtra',
    'iled',
    'Ġimperial',
    'ist',
    'um',
    'ann',
    'ats',
    'uki',
    '4',
    '35',
    'ĠRoad',
    's',
    'Ġsl',
    'ur',
    'ĠIde',
    'ally',
    'Ġt',
    'renches',
    'C',
    'trl',
    'Ġmir',
    'rored',
    'ĠZ',
    'el',
    'ĠC',
    'rest',
    'Comp',
    'at',
    'ĠRoll',
    's',
    'sc',
    'rib',
    'ĠTra',
    'ils',
    'omet',
    'ers',
    'w',
    'inter',
    'Ġimm',
    'ortality',
    'il',
    'ated',
    'Ġcontrad',
    'icts',
    'un',
    'iversal',
    'ill',
    'ions',
    'ĠM',
    'ama',
    'opt',
    'im',
    'AT',
    'URE',
    'Ġge',
    'o',
    'et',
    'ter',
    'ĠCar',
    'lo',
    '4',
    '24',
    'Ġcanon',
    'ical',
    'ĠStrongh',
    'old',
    'n',
    'ear',
    'Ġperf',
    'ume',
    'Ġorche',
    'stra',
    'od',
    'iac',
    'Ġup',
    'he',
    'Ġreign',
    'ing',
    'vers',
    'ive',
    'Ġc',
    'aucuses',
    'ĠD',
    'EM',
    'Ġinsult',
    'ed',
    'Ġ----',
    '--',
    'ĠCr',
    'ush',
    'Ġroot',
    'ing',
    'ĠWra',
    'ith',
    'Ġwh',
    'ore',
    'Ġto',
    'fu',
    'C',
    'md',
    'ĠB',
    'ree',
    'Ġ$',
    '_',
    'Ġr',
    'ive',
    'ĠAd',
    'vertising',
    'Ġw',
    'att',
    'ĠH',
    'O',
    'Ġpersu',
    'asive',
    'ĠParam',
    'eters',
    'Ġobserv',
    'ational',
    'ĠN',
    'CT',
    'ĠMo',
    'j',
    'ĠSal',
    'on',
    'Ġtr',
    'unc',
    'Ġexqu',
    'isite',
    'ĠMar',
    'a',
    'Ġpo',
    'op',
    'ĠAN',
    'N',
    'Ex',
    'c',
    'ĠWonder',
    'ful',
    'ĠT',
    'aco',
    'Ġhome',
    'owner',
    'ĠSmith',
    'sonian',
    'orpor',
    'ated',
    'mm',
    'mm',
    'Ġlo',
    'af',
    'ĠYam',
    'ato',
    'ĠInd',
    'o',
    'Ġcl',
    'inging',
    'Ã¡',
    's',
    'Ġimm',
    'utable',
    'h',
    'ub',
    'Or',
    'ange',
    'Ġfingert',
    'ips',
    'ĠWood',
    'en',
    'ĠK',
    'idd',
    'ĠJ',
    'PM',
    'ĠDam',
    'n',
    'C',
    'ow',
    'c',
    'odes',
    '48',
    '2',
    'Ġiniti',
    'ating',
    'ĠEl',
    'k',
    'ĠCut',
    'ting',
    'Ġabsent',
    'ee',
    'ĠV',
    'ance',
    'ĠLil',
    'ith',
    'G',
    'UI',
    'Ġobsc',
    'ured',
    'Ġdwar',
    'ves',
    'ĠCh',
    'op',
    'ĠB',
    'oko',
    'Val',
    'ues',
    'Ġmult',
    'imedia',
    'Ġbrew',
    'ed',
    'Reg',
    'ular',
    'CRIP',
    'TION',
    'ĠMort',
    'al',
    'Ġa',
    'pex',
    'Ġtravel',
    'er',
    'Ġbo',
    'ils',
    'Ġspray',
    'ing',
    'Rep',
    'resent',
    'ĠStars',
    'hip',
    '4',
    '28',
    'Ġdisappro',
    'val',
    'Ġshadow',
    'y',
    'Ġlament',
    'ed',
    'ĠRe',
    'place',
    'ĠFran',
    'Ã§',
    '67',
    '7',
    'd',
    'or',
    'Ġunst',
    'oppable',
    'Ġcoh',
    'orts',
    'gy',
    'n',
    'ĠClass',
    'ics',
    'ĠAm',
    'ph',
    'Ġsl',
    'uggish',
    'ĠAdd',
    'iction',
    'ĠPad',
    'res',
    'Ġins',
    'cription',
    'Ġin',
    'human',
    'min',
    'us',
    'ĠJere',
    'miah',
    'at',
    'ars',
    'Ter',
    'ror',
    'ĠT',
    'os',
    'ĠSh',
    'arma',
    'ast',
    'a',
    'c',
    'atch',
    'Ġpl',
    'umbing',
    'ĠTim',
    'bers',
    'Sh',
    'ar',
    'H',
    'al',
    'ĠO',
    'sc',
    'Ġcou',
    'pling',
    'hum',
    'ans',
    'Ġsp',
    'onge',
    'Ġid',
    'ols',
    'ĠSp',
    'a',
    'ĠAdv',
    'ocate',
    'ĠBe',
    'ats',
    'lu',
    'a',
    'Ġtick',
    'ing',
    'Ġload',
    'er',
    'ĠG',
    'ron',
    '8',
    '10',
    'Ġstim',
    'ulated',
    'Ġside',
    'bar',
    'ĠManufact',
    'urer',
    'ore',
    'And',
    '19',
    '73',
    'Ġpra',
    'ises',
    'ĠFl',
    'ores',
    'dis',
    'able',
    'ĠElect',
    'rical',
    'ra',
    'ise',
    'E',
    'th',
    'Ġmigr',
    'ated',
    'Ġlect',
    'urer',
    'K',
    'ids',
    'ĠCa',
    'vern',
    'Ġk',
    'ettle',
    'Ġgly',
    'c',
    'ĠMand',
    'ela',
    'ĠF',
    'ully',
    'å§',
    '«',
    'FIN',
    'EST',
    'Ġsquee',
    'zing',
    'ĠRy',
    'der',
    'amp',
    'oo',
    'oreAnd',
    'Online',
    'Inst',
    'oreAndOnline',
    'Buyable',
    'InstoreAndOnline',
    'Ġcommem',
    'orate',
    'ĠRamp',
    'age',
    'Aust',
    'in',
    'ĠSh',
    'roud',
    'ĠRu',
    'ins',
    '9',
    '15',
    'ĠK',
    'H',
    'Ġwater',
    'front',
    'ĠE',
    'SC',
    'b',
    'aby',
    'ĠC',
    'out',
    'ĠEm',
    'blem',
    'Ġequival',
    'ents',
    '49',
    '2',
    'Un',
    'ique',
    'ĠNiet',
    'zsche',
    'brow',
    'ser',
    'Ġim',
    'itation',
    'ĠWere',
    'wolf',
    'ĠKir',
    'in',
    'ac',
    'as',
    "'",
    ',"',
    'ĠÃ',
    '¾',
    'Review',
    'ed',
    'Ġc',
    'unt',
    'Ġvo',
    'ic',
    'ĠLen',
    'ovo',
    'Ġbond',
    'ed',
    '48',
    '1',
    'Ġinhib',
    'itors',
    'Ġendeav',
    'ors',
    'ĠHav',
    'ana',
    'ĠSt',
    'out',
    'ĠJ',
    'olly',
    'A',
    'ctor',
    '*/',
    '(',
    'Ġoccur',
    'rences',
    'ĠT',
    'ens',
    'Incre',
    'ased',
    'ĠACT',
    'ION',
    'Ġ',
    'ãĢĮ',
    'ĠRank',
    'ings',
    'ĠB',
    'reat',
    'Ġ30',
    '9',
    'D',
    'ou',
    'Ġimpact',
    'ing',
    'ĠDuc',
    'hess',
    'pre',
    'fix',
    'Q',
    'B',
    'Ġsummon',
    'ing',
    'Ġbest',
    'owed',
    'ĠKe',
    'pler',
    'ĠPOW',
    'ER',
    'c',
    'ube',
    'ĠK',
    'its',
    'ĠG',
    'rip',
    'Ġop',
    'ium',
    'Ġrep',
    'utable',
    't',
    'oc',
    'ich',
    'ael',
    'ĠR',
    'ipple',
    'Ġcaf',
    'Ã©',
    'ĠZ',
    'oom',
    'ĠBur',
    'ma',
    'Ġwa',
    'ive',
    'Ġst',
    'alls',
    'Ġdem',
    'eanor',
    'inc',
    'erity',
    'Ġfluor',
    'ide',
    'ĠSH',
    'OULD',
    'Par',
    'is',
    'Ġlong',
    'ing',
    'Ġpl',
    'at',
    'Ġgross',
    'ly',
    'Ġbull',
    's',
    'Ġshowc',
    'asing',
    'ex',
    'pected',
    'ĠG',
    'addafi',
    'engine',
    'ering',
    'Re',
    'peat',
    'ĠK',
    'ut',
    'Ġconce',
    'ivable',
    'Ġtrim',
    'med',
    'osc',
    'ope',
    'ĠCand',
    'idate',
    'ĠT',
    'ears',
    'rol',
    'og',
    'Lew',
    'is',
    'S',
    'UP',
    'Ġroad',
    'map',
    'Ġsal',
    'iva',
    'Ġtrump',
    'et',
    'Jim',
    'my',
    'Ġmirac',
    'ulous',
    'Ġcolon',
    'ization',
    'Ġam',
    'put',
    'ĠGN',
    'OME',
    'ate',
    'ch',
    'D',
    'ifferent',
    'ĠE',
    'LE',
    'ĠGovern',
    'ments',
    'ĠA',
    'head',
    'ãħĭ',
    'ãħĭ',
    'word',
    'press',
    'L',
    'IB',
    'ĠIn',
    'clude',
    'ĠDor',
    'othy',
    '0',
    '45',
    'ĠColomb',
    'ian',
    'Ġle',
    'ased',
    '88',
    '4',
    'Ġde',
    'grading',
    'ĠDa',
    'isy',
    'i',
    'ations',
    'Ġbapt',
    'ized',
    'Ġsurn',
    'ame',
    'co',
    'x',
    'Ġblink',
    'ed',
    'ãĥ',
    '¢',
    'Ġpoll',
    'en',
    'Ġder',
    'mat',
    'Ġre',
    'gex',
    'ĠNich',
    'olson',
    'ĠE',
    'ater',
    'ç',
    'ľ',
    'rad',
    'or',
    'Ġnarrow',
    'er',
    'Ġhur',
    'ricanes',
    'Ġhalluc',
    'inations',
    'r',
    'idden',
    'ISS',
    'ION',
    'ĠFire',
    'fly',
    'Ġattain',
    'ment',
    'Ġnom',
    'inate',
    'Ġav',
    'ocado',
    'ĠM',
    'eredith',
    'Ġt',
    's',
    'Ġreve',
    'rence',
    'Ġe',
    'uph',
    'Ġcr',
    'ates',
    'ĠT',
    'EXT',
    'Ġ4',
    '43',
    'Ġ3',
    '19',
    'J',
    'SON',
    'iqu',
    'ette',
    'Ġshort',
    'stop',
    'ic',
    'key',
    'Ġpro',
    'pelled',
    'Ġap',
    'i',
    'ĠTh',
    'ieves',
    '77',
    '9',
    'Ġovers',
    'aw',
    'Ġcol',
    'i',
    'ĠNic',
    'ola',
    'Ġover',
    'cl',
    'ik',
    'awa',
    'ĠC',
    'yr',
    'Ġ38',
    '4',
    '78',
    '9',
    'ĠAll',
    'ows',
    '10',
    '27',
    'Det',
    'roit',
    'TR',
    'Y',
    'set',
    'up',
    'ĠSocial',
    'ism',
    'Sov',
    'iet',
    's',
    'usp',
    'ĠAP',
    'R',
    'ĠShut',
    'down',
    'Ġal',
    'uminium',
    'zb',
    'ek',
    'ĠL',
    'over',
    'GGGG',
    'GGGG',
    'Ġdemocr',
    'acies',
    'Ġ19',
    '08',
    'ĠMer',
    'rill',
    'ĠFranco',
    'is',
    'gd',
    'ala',
    'Ġtraff',
    'ickers',
    'ĠT',
    'il',
    'ĠGo',
    'at',
    'Ġsp',
    'ed',
    'ĠRes',
    'erv',
    'Ġpro',
    'd',
    '55',
    '2',
    'Ġc',
    'ac',
    'ĠUn',
    'iv',
    'ĠSch',
    'we',
    'Ġsw',
    'irling',
    'ĠWild',
    'erness',
    'ĠEgg',
    's',
    'Ġsadd',
    'ened',
    'Ġarch',
    'aic',
    'H',
    'yd',
    'Ġexcess',
    'ively',
    'B',
    'RE',
    'Ġaer',
    'ospace',
    'ĠVo',
    'ices',
    'Cra',
    'ig',
    'Ġign',
    'ited',
    'In',
    'itially',
    'ĠMc',
    'A',
    'Ġhand',
    'set',
    'Ġreform',
    'ing',
    'Ġfrust',
    'rations',
    'ĠDead',
    'pool',
    'ĠBel',
    'ichick',
    'ract',
    'or',
    'ĠRagnar',
    'ok',
    'ĠD',
    'rupal',
    'ĠApp',
    'roximately',
    '19',
    '20',
    'ĠHub',
    'ble',
    'arm',
    'or',
    'ĠSar',
    'as',
    'ĠJon',
    'as',
    'Ġnostalg',
    'ic',
    'Ġfeas',
    'ibility',
    'Sah',
    'aran',
    'Ġorb',
    'iting',
    'Ġ9',
    '70',
    'R',
    'u',
    'Ġsh',
    'in',
    'ĠInvestig',
    'ators',
    'Ġinconsist',
    'encies',
    'ĠP',
    'AN',
    'B',
    'G',
    'Ġgraz',
    'ing',
    'Ġdetect',
    'ors',
    'ĠStart',
    'up',
    'ĠFun',
    'ny',
    'ĠNa',
    'omi',
    'Consider',
    'ing',
    'Ġh',
    'og',
    'ut',
    'f',
    'ce',
    'mic',
    'Ġfort',
    'ified',
    'ĠFun',
    'ctions',
    'Ġcod',
    'ec',
    'nut',
    'rition',
    'H',
    'at',
    '"',
    '!',
    'micro',
    'soft',
    '55',
    '8',
    'ĠTh',
    'in',
    'ĠA',
    'CE',
    'Al',
    'ias',
    'ĠO',
    'PS',
    'p',
    'apers',
    'P',
    'K',
    'ãĢ',
    'İ',
    'Ġimpro',
    'bable',
    'N',
    'orthern',
    'equ',
    'al',
    'Ġlook',
    'out',
    'Ġty',
    'res',
    'ĠMod',
    'ified',
    'ĠK',
    'op',
    'Abs',
    'olutely',
    'Ġbuild',
    'up',
    'sil',
    'ver',
    'Ġaud',
    'i',
    'Ġgro',
    'tesque',
    'ĠSab',
    'er',
    'ĠPres',
    'byter',
    'ON',
    'Y',
    'Ġglac',
    'iers',
    'ĠSho',
    'als',
    'ĠK',
    'ass',
    'ĠH',
    'RC',
    'ĠNic',
    'ol',
    'ĠL',
    'unch',
    'ĠF',
    'oss',
    'âĸ',
    'Ĵ',
    'AD',
    'RA',
    'ĠOne',
    'Plus',
    'o',
    'ing',
    'ground',
    's',
    'Ġincident',
    'al',
    'Ġdatas',
    'ets',
    '68',
    '9',
    'ĠClarks',
    'on',
    'Ġassemb',
    'ling',
    'ĠCorrect',
    'ions',
    'Ġdrink',
    'ers',
    'Ġqual',
    'ifiers',
    'Ġle',
    'ash',
    'Ġunf',
    'ounded',
    'ĠH',
    'undred',
    'Ġkick',
    'off',
    'T',
    'i',
    'Ġrecon',
    'cil',
    'ĠGr',
    'ants',
    'ĠCompl',
    'iance',
    'ĠDexter',
    'ity',
    'Ġ19',
    '06',
    'w',
    'arn',
    'D',
    'allas',
    'Max',
    'imum',
    'n',
    'ard',
    'av',
    'ia',
    'be',
    'aut',
    'ens',
    'itivity',
    'tr',
    'ace',
    'Ġpione',
    'ers',
    'ĠF',
    'ract',
    'ãĢ',
    'ı',
    'Ġpre',
    'cept',
    'Ġgloss',
    'y',
    'ĠI',
    'EEE',
    'Ac',
    'ross',
    'Ġ6',
    '80',
    'S',
    'leep',
    'che',
    'on',
    'Ġsatir',
    'ical',
    'ĠMin',
    'otaur',
    'ĠCla',
    'ude',
    'Ġr',
    'Ã©',
    'ape',
    'go',
    'Ġcar',
    'rot',
    'ĠSem',
    'in',
    'ino',
    'a',
    'Ġz',
    'o',
    'Ind',
    'ependent',
    'Ġdiagn',
    'oses',
    'ĠC',
    'ue',
    'M',
    'AR',
    'Ġrend',
    'ition',
    'ĠK',
    'ik',
    'Ġpath',
    'ology',
    'Ġselect',
    's',
    'Link',
    'edIn',
    'Ġass',
    'ay',
    'ĠD',
    'res',
    'Ġtext',
    'ual',
    'post',
    'ed',
    'IT',
    'AL',
    'ĠM',
    'aul',
    'N',
    'eal',
    'Ġinter',
    'connected',
    'Ġerr',
    'atic',
    'ĠVir',
    'us',
    'Ġ5',
    '30',
    'Ġenvironmental',
    'ists',
    'ĠP',
    'helps',
    'Ġeng',
    'agements',
    'ĠIN',
    'ST',
    'Ġeconom',
    'ical',
    'nox',
    'ious',
    'Ġg',
    'earing',
    'izz',
    'y',
    'Ġfavor',
    'ably',
    'ĠMcG',
    'ill',
    'T',
    'erm',
    'Ġh',
    'anged',
    'Ġball',
    'park',
    'ĠRe',
    'yes',
    'Ġbe',
    'ware',
    'ĠP',
    'sal',
    'ĠMass',
    'acre',
    'q',
    'i',
    'Ġin',
    'accessible',
    'acly',
    'sm',
    'Ġfr',
    'ay',
    'ill',
    'ac',
    'Ġbitter',
    'ly',
    'ĠCert',
    'ification',
    'Mich',
    'igan',
    'Ġir',
    'respective',
    'al',
    'ore',
    'Em',
    'pty',
    'Ġendorse',
    'ments',
    'Ġund',
    'et',
    'f',
    'g',
    'equ',
    'ipped',
    'Ġmerc',
    'iless',
    'ĠC',
    'ust',
    'Ġimm',
    'ature',
    'Ġvou',
    'cher',
    'ĠBlack',
    'well',
    'Ñ',
    'ı',
    'h',
    'awk',
    'dis',
    'ciplinary',
    'ile',
    'e',
    'ĠMak',
    'oto',
    'ĠD',
    'ude',
    'ãĥĩ',
    'ãĤ£',
    'Y',
    'ears',
    'Ġin',
    'ver',
    'Ġsh',
    'aman',
    'ĠY',
    'ong',
    'ip',
    'el',
    'ell',
    'en',
    'ĠCath',
    'y',
    'br',
    'ids',
    'Ġs',
    'arc',
    '65',
    '1',
    'N',
    'ear',
    'Ġground',
    'work',
    'Ġam',
    'az',
    'Ġ4',
    '15',
    'ĠHunting',
    'ton',
    'hew',
    's',
    'ĠB',
    'ung',
    'Ġarbit',
    'rarily',
    'ĠW',
    'it',
    'ĠAl',
    'berto',
    'Ġdis',
    'qualified',
    'best',
    'os',
    '46',
    '1',
    'Ġp',
    'c',
    'Ġ28',
    '4',
    'ro',
    'bat',
    'Rob',
    'in',
    'Ġh',
    'ugs',
    'ĠTrans',
    'ition',
    'ĠOcc',
    'asionally',
    'Ġ3',
    '26',
    'ĠWh',
    'ilst',
    'ĠLe',
    'y',
    'Ġspaces',
    'hip',
    'cs',
    'v',
    'Ġun',
    'successfully',
    'ĠA',
    'u',
    'le',
    'ck',
    'ĠWing',
    'ed',
    'ĠGrizz',
    'lies',
    '.',
    'ï¿½',
    'Ġne',
    'arer',
    'ĠSorce',
    'ress',
    'ĠInd',
    'igo',
    'El',
    'se',
    '8',
    '40',
    'let',
    'es',
    'Co',
    'ach',
    'Ġup',
    'bringing',
    'ĠK',
    'es',
    'Ġseparat',
    'ist',
    'Ġrac',
    'ists',
    'Ġch',
    'ained',
    'Ġabst',
    'inence',
    'lear',
    'ning',
    'Ġrein',
    'stated',
    'Ġsymm',
    'etry',
    'Ġremind',
    'ers',
    'ĠChe',
    'vy',
    'Ġm',
    'ont',
    'Ġexempl',
    'ary',
    'ĠT',
    'OR',
    'Z',
    'X',
    'Ġqual',
    'itative',
    'ĠSt',
    'amp',
    'ĠSav',
    'annah',
    'ĠRoss',
    'i',
    'Ġp',
    'aed',
    'Ġdispens',
    'aries',
    'ĠWall',
    's',
    'ĠCh',
    'ronic',
    'Ġcompliment',
    'ary',
    'ĠBeir',
    'ut',
    'Ġ+',
    '---',
    'igs',
    'list',
    'Ġcrypt',
    'ographic',
    'mas',
    'ters',
    'ĠCap',
    'itals',
    'Ġmax',
    'imal',
    'Ġent',
    'ropy',
    'Point',
    's',
    'Ġcombat',
    'ants',
    'l',
    'ip',
    'ĠGl',
    'ob',
    'ĠB',
    'MC',
    'ph',
    'ase',
    'th',
    'ank',
    'HT',
    'TP',
    'Ġcomm',
    'uter',
    'Ġ\\(',
    '\\',
    '..',
    '/',
    'ĠReg',
    'ener',
    'ĠDO',
    'I',
    'ĠActiv',
    'ision',
    'Ġsl',
    'it',
    'os',
    'al',
    'RE',
    'M',
    'Ġch',
    'ants',
    'Y',
    'u',
    'Ke',
    'ys',
    'Bre',
    'xit',
    'ĠFor',
    'ced',
    'Ari',
    'zona',
    'Ġsquad',
    'ron',
    'IS',
    'O',
    'ĠMal',
    'one',
    'Ġ3',
    '38',
    'Ġcontrast',
    'ing',
    'Ġt',
    'idal',
    'Ġlib',
    'el',
    'Ġimpl',
    'anted',
    'Ġupro',
    'ar',
    'ĠC',
    'ater',
    'Ġpropos',
    'itions',
    'M',
    'anchester',
    'ĠEuro',
    's',
    'it',
    'amin',
    'G',
    'il',
    'ĠEl',
    'ven',
    'ĠSe',
    'ek',
    'ĠB',
    'ai',
    'Ġredevelop',
    'ment',
    'ĠTown',
    's',
    'ĠL',
    'ub',
    '!',
    '",',
    'al',
    'on',
    'K',
    'rist',
    'Ġmeas',
    'urable',
    'Ġimagin',
    'able',
    'Ġapost',
    'les',
    'Y',
    'N',
    '7',
    '60',
    'Ġster',
    'oid',
    'Ġspecific',
    'ity',
    'ĠL',
    'ocated',
    'ĠBeck',
    'er',
    'ĠE',
    'du',
    'ĠDiet',
    'ary',
    'uts',
    'ch',
    'ĠMar',
    'ilyn',
    'Ġbl',
    'ister',
    'ĠM',
    'EP',
    'ĠK',
    'oz',
    'ĠC',
    'MS',
    'y',
    'ahoo',
    'ĠCar',
    'ney',
    'Ġbo',
    'asting',
    'ĠC',
    'aleb',
    'By',
    'te',
    'read',
    's',
    'ad',
    'en',
    'Pro',
    'blem',
    'ĠWood',
    'ward',
    'S',
    'we',
    'S',
    'up',
    'ĠK',
    'GB',
    'Set',
    'up',
    'Ġtac',
    'it',
    'Ġret',
    'ribution',
    'Ġd',
    'ues',
    'ĠM',
    'Ã¼',
    '.',
    '?',
    'ä¸',
    'Ń',
    'p',
    'ots',
    'Ġcame',
    'o',
    'ĠP',
    'AL',
    'educ',
    'ation',
    'A',
    'my',
    'like',
    'ly',
    'g',
    'ling',
    'Ġconstitution',
    'ally',
    'ĠHam',
    'm',
    'ĠSpe',
    'ak',
    'Ġwid',
    'gets',
    'br',
    'ate',
    'Ġcra',
    'ppy',
    'ĠI',
    'ter',
    'Ġanticip',
    'ating',
    'ĠB',
    'out',
    'P',
    'ixel',
    'ĠY',
    'ep',
    'ĠLaur',
    'ie',
    'Ġh',
    'ut',
    'Ġbullet',
    'in',
    'ĠSal',
    'vation',
    'Ġch',
    'ats',
    'ear',
    'able',
    'Honest',
    'ly',
    'AL',
    'TH',
    'onse',
    'qu',
    'c',
    'ult',
    'isco',
    'very',
    'ovy',
    'ch',
    'Ġse',
    'lves',
    'ĠSat',
    'oshi',
    'S',
    'ounds',
    'Ġconver',
    'gence',
    'ĠRosen',
    'berg',
    '19',
    '74',
    'Ġnas',
    'al',
    'Ġfull',
    'est',
    'Ġfer',
    'ocious',
    'x',
    'us',
    'ist',
    'e',
    'AM',
    'S',
    'Ġlobb',
    'ied',
    'Ġso',
    'othing',
    'ĠGun',
    'n',
    't',
    'oday',
    '0',
    '24',
    'Ġinspir',
    'ational',
    'ĠN',
    'BN',
    'p',
    'b',
    'g',
    'ewater',
    'or',
    'ah',
    'all',
    'owed',
    'ĠCol',
    'iseum',
    'Ġspecial',
    'izing',
    'Ġinsane',
    'ly',
    'ĠT',
    'ape',
    'del',
    'ay',
    'Ġt',
    'arn',
    'ĠP',
    'ound',
    'Ġmel',
    'anch',
    'Ġdeploy',
    'ments',
    'il',
    'and',
    'Ġless',
    'en',
    'Ġfur',
    'ry',
    'ĠUE',
    'FA',
    'Ġblood',
    'shed',
    'ĠMe',
    'ier',
    'ither',
    'ing',
    'Ġhe',
    'irs',
    'ĠJ',
    'aw',
    'ax',
    'ter',
    'ĠPublic',
    'ations',
    'Ġal',
    'ters',
    'int',
    'ention',
    'ĠWinc',
    'hester',
    'd',
    'etermination',
    'ĠLif',
    'etime',
    'th',
    'in',
    'Mon',
    'ster',
    '7',
    '80',
    'Ġapprox',
    'imation',
    'Ġsuper',
    'markets',
    'ĠSecond',
    's',
    'or',
    'os',
    'h',
    'uge',
    'Ġb',
    'ribe',
    'ĠLIM',
    'ITED',
    'un',
    'ed',
    'Ġmis',
    'interpret',
    'ĠIn',
    'jury',
    'Ġ3',
    '67',
    'Ġthreshold',
    's',
    'ĠCarn',
    'ival',
    'Ġgastro',
    'intestinal',
    'Ġguid',
    'eline',
    'Ġde',
    'ceived',
    'f',
    'eatures',
    'Ġpurported',
    'ly',
    'ĠRon',
    'nie',
    'ĠNew',
    't',
    'Ġsp',
    'acious',
    'as',
    'us',
    'Ġsuperhero',
    'es',
    'ĠCyn',
    'thia',
    'le',
    'gged',
    'k',
    'amp',
    'ch',
    'io',
    'Ġth',
    'umbnail',
    'ĠShir',
    'ley',
    'ill',
    'ation',
    'Ġshe',
    'ds',
    'ĠZ',
    'y',
    'E',
    'PA',
    'Ġdam',
    's',
    'Ġy',
    'awn',
    'n',
    'ah',
    'ĠPe',
    'ggy',
    'ĠE',
    'rie',
    'ĠJu',
    'ventus',
    'ĠF',
    'ountain',
    'r',
    'x',
    'don',
    'ald',
    'al',
    'bum',
    'ĠComp',
    'rehensive',
    'Ġc',
    'aching',
    'ĠU',
    'z',
    'ulner',
    'ability',
    'ĠPrinc',
    'iple',
    'ĠJ',
    'ian',
    'ing',
    'ers',
    'cast',
    's',
    'ĠOs',
    'iris',
    'ch',
    'art',
    't',
    'ile',
    'ĠTiff',
    'any',
    'ĠPatt',
    'on',
    'ĠWh',
    'ip',
    'Ġovers',
    'ized',
    'J',
    'e',
    'ĠCind',
    'erella',
    'ĠB',
    'orders',
    'ĠDa',
    'esh',
    'M',
    'ah',
    'Ġdog',
    'ma',
    'Ġcommun',
    'ists',
    'v',
    'u',
    'Coun',
    'cil',
    'Ġfresh',
    'water',
    'Ġw',
    'ounding',
    'Ġdeb',
    'acle',
    'Ġyoung',
    'ster',
    'Ġthread',
    'ed',
    'ĠB',
    'ots',
    'ĠSav',
    'ings',
    'ãģ',
    'Ĥ',
    'ol',
    'ing',
    'oh',
    'o',
    'Ġillum',
    'ination',
    'M',
    'RI',
    'Ġlo',
    'osen',
    'tr',
    'ump',
    'ag',
    'ency',
    'ur',
    'ion',
    'Ġmoment',
    'arily',
    'ĠCh',
    'un',
    'ĠBud',
    'apest',
    'ĠAl',
    'ley',
    'D',
    'isk',
    'Ġaston',
    'ished',
    'ĠCon',
    'quer',
    'ĠAccount',
    'ing',
    'h',
    'aving',
    'ĠWe',
    'in',
    'ĠAl',
    'right',
    'Ġrev',
    'olver',
    'Ġdel',
    'usion',
    'Ġrelic',
    's',
    'Ġad',
    'herent',
    'qu',
    'ant',
    'Ġhand',
    'made',
    'or',
    'io',
    'Ġcomb',
    'ating',
    'c',
    'oded',
    'Ġquad',
    'ru',
    're',
    'th',
    'N',
    'ik',
    'ĠTrib',
    'al',
    'ĠMyster',
    'ious',
    'Ġin',
    'hal',
    'ĠWin',
    'ning',
    'ĠClass',
    'ification',
    'ch',
    'anged',
    'Ġun',
    'ab',
    'Ġsc',
    'orn',
    'icip',
    'ated',
    'w',
    'l',
    'ond',
    'uctor',
    'Ġrein',
    'forcing',
    'ĠChild',
    'hood',
    'an',
    'ova',
    'Ġadventure',
    'r',
    'Ġdoctor',
    'al',
    'ĠStrateg',
    'ies',
    'Ġengulf',
    'ed',
    'ĠEnc',
    'ounter',
    'Ġl',
    'ashes',
    'Crit',
    'ical',
    'ric',
    'ular',
    'ĠU',
    'TF',
    'oci',
    'ation',
    'check',
    'ing',
    'ĠConsult',
    'ing',
    'Run',
    'time',
    'per',
    'iod',
    'ĠAs',
    'gard',
    'Ġdist',
    'illed',
    'ĠPas',
    'adena',
    'ĠD',
    'ying',
    'ĠCOUN',
    'TY',
    'Ġgran',
    'ite',
    'Ġsm',
    'ack',
    'Ġparach',
    'ute',
    'ĠS',
    'UR',
    'Virgin',
    'ia',
    'ĠF',
    'urious',
    '78',
    '7',
    'ĠO',
    'kin',
    'Ġcam',
    'el',
    'ĠM',
    'bps',
    '19',
    '72',
    'ĠCh',
    'ao',
    'ĠC',
    'yan',
    'j',
    'oice',
    'ef',
    'er',
    'ĠW',
    'rap',
    'ĠDeb',
    'ate',
    'S',
    'eg',
    'Ġfore',
    'arm',
    'ĠIgn',
    'ore',
    'Ġtim',
    'estamp',
    'Ġprob',
    'ing',
    'ĠNo',
    'on',
    'ĠGra',
    'il',
    'f',
    'en',
    'Ġdorm',
    'ant',
    'ĠFirst',
    'ly',
    'ĠE',
    'ighth',
    'ĠH',
    'UN',
    'ĠDes',
    'ire',
    'or',
    'as',
    'Girl',
    's',
    'ĠDes',
    'mond',
    'z',
    'ar',
    'am',
    'ines',
    'O',
    'AD',
    'exec',
    'ute',
    'Ġbo',
    'obs',
    'ĠAT',
    'L',
    '_',
    '(',
    'Chel',
    'sea',
    'Ġmasturb',
    'ation',
    'ĠCo',
    'C',
    'Ġdestroy',
    'er',
    'ĠCh',
    'omsky',
    'Ġsc',
    'atter',
    'ĠAss',
    'ets',
    '79',
    '6',
    'ĠC',
    'argo',
    'Ġrecept',
    'ive',
    'ĠSc',
    'ope',
    'Ġmarket',
    'ers',
    'Ġlaun',
    'chers',
    'Ġax',
    'le',
    'ĠSE',
    'A',
    'se',
    'q',
    'ĠM',
    'off',
    'f',
    'inding',
    'ĠGib',
    'bs',
    'Georg',
    'ia',
    'extreme',
    'ly',
    'N',
    'J',
    'Ġlab',
    'orers',
    'st',
    'als',
    'Ġmed',
    'iation',
    'ĠH',
    'edge',
    'at',
    'own',
    'Ġi',
    'od',
    'des',
    'pite',
    'v',
    'ill',
    'J',
    'ane',
    'ex',
    'istence',
    'Ġcoinc',
    'ided',
    'ĠUt',
    'ilities',
    'ĠChe',
    'ap',
    'Ġlog',
    'istical',
    'Ġcul',
    'mination',
    'ĠNic',
    'otine',
    'p',
    'ak',
    'F',
    'older',
    'Ġrod',
    'ents',
    'st',
    'uff',
    'Ġlaw',
    'fully',
    'Ġreper',
    'to',
    'io',
    'ch',
    'j',
    'j',
    'Dial',
    'ogue',
    'HH',
    'HH',
    'lic',
    'tion',
    'Look',
    's',
    'Ġ29',
    '7',
    'Ġtur',
    'rets',
    'ĠAb',
    'andon',
    'Ġinc',
    'ess',
    'ĠTraff',
    'ord',
    'Ġcur',
    'led',
    'Ġprefer',
    'ring',
    'Ġprivat',
    'ization',
    'Ġir',
    'resist',
    'ĠP',
    'anda',
    'ĠSh',
    'ake',
    'ĠMc',
    'Gr',
    'ãĥ',
    'Ħ',
    'und',
    'ers',
    'Ġdiscrim',
    'inated',
    'Ġbart',
    'ender',
    'I',
    'LE',
    'Atl',
    'antic',
    'Ġprop',
    'ensity',
    'ĠW',
    'iz',
    'ĠG',
    'im',
    'con',
    'ference',
    'Ġrein',
    'forces',
    'G',
    'h',
    'w',
    'agon',
    'Ġe',
    'erie',
    'F',
    'al',
    'Ġhug',
    'ged',
    'rac',
    'ist',
    'R',
    'IC',
    'F',
    'u',
    'Ġf',
    'iller',
    'ĠSt',
    'ub',
    'Ġeng',
    'raved',
    'ĠWrest',
    'le',
    'Ġimagin',
    'ative',
    'ĠPe',
    'er',
    'ĠFact',
    'ors',
    'an',
    'us',
    'ĠDrac',
    'ula',
    'mon',
    'itor',
    'Ġrou',
    'ters',
    'ib',
    'ia',
    'ĠBoo',
    'lean',
    'end',
    'ale',
    'ĠSl',
    'aughter',
    'ĠSh',
    'ack',
    'R',
    'FC',
    'ĠSpiel',
    'berg',
    'S',
    'ax',
    'ĠPH',
    'OTO',
    'ĠCl',
    'over',
    'ĠR',
    'ae',
    'Dep',
    'ending',
    'ĠMem',
    'or',
    'ar',
    'am',
    'Ġpier',
    'ced',
    'Ġcur',
    'tains',
    'v',
    'ale',
    'ĠInqu',
    'isition',
    'ĠP',
    'oke',
    'Ġforecast',
    'ing',
    'Ġcompl',
    'ains',
    'S',
    'ense',
    'ĠHer',
    'mes',
    'isc',
    'overed',
    'Ġb',
    'ible',
    'ĠMor',
    'ph',
    'Ġg',
    'erm',
    '78',
    '5',
    'D',
    'ON',
    'Ġcon',
    'gen',
    'Ġcr',
    'ane',
    'ĠD',
    'PR',
    'Ġrespect',
    'fully',
    'R',
    'oom',
    'ĠN',
    'aw',
    'ĠDal',
    'ai',
    're',
    'ason',
    'ĠAng',
    'us',
    'Educ',
    'ation',
    'ĠTitan',
    'ic',
    'Ë',
    'ľ',
    'Ġo',
    'val',
    'un',
    'ited',
    'Ġthird',
    's',
    'Ġmoist',
    'ur',
    'ĠC',
    'PC',
    'M',
    'iami',
    'Ġtent',
    'acles',
    'ĠPol',
    'aris',
    'ex',
    'c',
    'ex',
    'clusive',
    'ĠPra',
    'irie',
    'Ġcol',
    'ossal',
    'ĠBl',
    'end',
    'sur',
    'prisingly',
    'ÃŃ',
    's',
    'Ġindo',
    'ctr',
    'Ġbas',
    'al',
    'ĠMP',
    'EG',
    'und',
    'o',
    'Spl',
    'it',
    'Develop',
    'ment',
    'Ġlan',
    'tern',
    '19',
    '71',
    'Ġprov',
    'ocation',
    'Ġang',
    'uish',
    'ĠB',
    'ind',
    'ĠLe',
    'ia',
    'duc',
    'ers',
    'ipp',
    'y',
    'conserv',
    'ancy',
    'Ġinitial',
    'ize',
    'ĠTw',
    'ice',
    'ĠSu',
    'k',
    'Ġpred',
    'ic',
    'Ġdi',
    'ploma',
    'Ġsoc',
    'iop',
    'Ing',
    'redients',
    'Ġhamm',
    'ered',
    'ĠIr',
    'ma',
    'Q',
    'aida',
    'Ġglim',
    'ps',
    'ĠB',
    'ian',
    'Ġst',
    'acking',
    'Ġf',
    'end',
    'gov',
    'track',
    'Ġun',
    'n',
    'dem',
    'ocratic',
    'ig',
    'ree',
    'Ġ5',
    '80',
    'Ġ29',
    '4',
    'Ġstraw',
    'berry',
    'ID',
    'ER',
    'Ġcher',
    'ished',
    'ĠH',
    'ots',
    'Ġinfer',
    'red',
    'Ġ8',
    '08',
    'ĠS',
    'ocrates',
    'O',
    'regon',
    'ĠR',
    'oses',
    'ĠFO',
    'IA',
    'Ġins',
    'ensitive',
    'Ġ40',
    '8',
    'Recomm',
    'end',
    'ĠSh',
    'ine',
    'Ġpain',
    'staking',
    'UG',
    'E',
    'ĠHell',
    'er',
    'ĠEnter',
    'prises',
    'I',
    'OR',
    'ad',
    'j',
    'N',
    'RS',
    'L',
    'G',
    'Ġalien',
    'ated',
    'Ġacknowled',
    'gement',
    'ĠA',
    'UD',
    'ĠRen',
    'eg',
    'Ġvou',
    'chers',
    'Ġ9',
    '60',
    'Ġm',
    'oot',
    'ĠDim',
    'ensions',
    'Ġc',
    'abbage',
    'B',
    'right',
    'g',
    'at',
    'ĠK',
    'lu',
    'Ġlat',
    'ent',
    'Ġz',
    'e',
    'ĠM',
    'eng',
    'Ġdis',
    'perse',
    'Ġpand',
    'emonium',
    'H',
    'Q',
    'Ġvirt',
    'uous',
    'ĠLoc',
    'ations',
    'ee',
    'per',
    'prov',
    'ided',
    'Ġse',
    'ams',
    'ĠW',
    'T',
    'iz',
    'o',
    'PR',
    'OV',
    'Ġtit',
    'anium',
    'Ġrecol',
    'lection',
    'Ġcr',
    'an',
    'Ġ7',
    '80',
    'ĠN',
    'F',
    '49',
    '1',
    '64',
    '2',
    'p',
    'acking',
    '59',
    '8',
    'text',
    'ure',
    'Sp',
    'ider',
    'fre',
    'edom',
    'cipl',
    'ed',
    'ĠTAM',
    'ADRA',
    'âĻ',
    '¦',
    'aut',
    'hent',
    'ĠW',
    'ANT',
    'r',
    'ified',
    'Ġr',
    'ites',
    'Ġuter',
    'us',
    'k',
    'iss',
    'Ġâī',
    '¤',
    'Ġsk',
    'illet',
    'Ġdis',
    'enfranch',
    'ĠGa',
    'al',
    'Comp',
    'an',
    'Ġage',
    'ing',
    'gu',
    'ide',
    'B',
    'alt',
    'Ġiter',
    'ator',
    'Ġdiscretion',
    'ary',
    't',
    'ips',
    'Ġprim',
    'ates',
    'ĠTechn',
    'ique',
    'ĠPay',
    'ments',
    'az',
    'el',
    'ĠR',
    'OCK',
    'stant',
    'ial',
    '0',
    '60',
    'Ġd',
    'mg',
    'ĠJack',
    'ets',
    'ĠPlay',
    'off',
    'Ġnurs',
    'ery',
    'ĠSy',
    'mb',
    'art',
    'on',
    'Ġannex',
    'ation',
    'Color',
    'ado',
    'Ġco',
    'ils',
    'ĠSh',
    'oes',
    'âĦ¢',
    ':',
    'ĠRo',
    'z',
    'COM',
    'PLE',
    'ĠEve',
    'rest',
    'ĠTri',
    'umph',
    'J',
    'oy',
    'G',
    'rid',
    'à',
    '¼',
    'process',
    'or',
    'ĠPros',
    'per',
    'ĠSever',
    'us',
    'ĠSelect',
    'ed',
    'r',
    'g',
    'ĠTay',
    'yip',
    'St',
    'ra',
    'Ġski',
    'ing',
    'Ġ?',
    ')',
    'Ġpe',
    'g',
    'Tes',
    'la',
    'Ġtime',
    'frame',
    'Ġmaster',
    'mind',
    'ĠN',
    'B',
    'scient',
    'ific',
    'ĠSh',
    'it',
    'gener',
    'ic',
    'IN',
    'TER',
    'N',
    'UM',
    'Ġst',
    'roll',
    'ĠEn',
    'ix',
    'ĠM',
    'MR',
    'ĠE',
    'MS',
    'm',
    'ovie',
    'Ĥ',
    'ª',
    'Ġminim',
    'izing',
    'idd',
    'ling',
    'Ġilleg',
    'itimate',
    'Ġprot',
    'otyp',
    'Ġpremature',
    'ly',
    'Ġmanual',
    's',
    'obb',
    'ies',
    'ĠCass',
    'idy',
    'D',
    'EC',
    'des',
    'ktop',
    'Ġaer',
    'os',
    'Ġscreen',
    'ings',
    'Ġdeb',
    'ilitating',
    'ĠGr',
    'ind',
    'nature',
    'conservancy',
    'Ġf',
    'ades',
    'ter',
    'mination',
    'assets',
    'adobe',
    'F',
    'actor',
    'Ġdefinitive',
    'ly',
    'P',
    'okÃ©',
    'ap',
    'ult',
    'ĠLaf',
    'ayette',
    'C',
    'orn',
    'ĠCor',
    'al',
    'Ġstagn',
    'ant',
    'T',
    'ue',
    'Ġdissatisf',
    'action',
    'G',
    'ender',
    'Ġkid',
    'neys',
    'ĠG',
    'ow',
    'ĠDef',
    'eat',
    'ĠAsh',
    'ton',
    'Ġcart',
    'els',
    'Ġfore',
    'closure',
    'ĠExpl',
    'ore',
    'stre',
    'ngth',
    'ot',
    'in',
    'Ġveterin',
    'arian',
    'Ġf',
    'umble',
    'Ġpar',
    'ap',
    'ĠSt',
    'rait',
    'r',
    'ils',
    'Ġpr',
    'ick',
    'ĠBerm',
    'uda',
    'ĠAm',
    'munition',
    'skin',
    'ned',
    'Ġab',
    'ound',
    'ĠB',
    'raz',
    'Ġshar',
    'per',
    'ĠAsc',
    'ension',
    'Ġ9',
    '78',
    'Ġpreview',
    's',
    'Ġcommun',
    'ion',
    'ĠX',
    'Y',
    'Ġph',
    'ony',
    'Ġnewcom',
    'er',
    'Ġ3',
    '32',
    '."',
    ',"',
    'Ġredist',
    'ribution',
    'Prot',
    'ect',
    'ĠSo',
    'f',
    'K',
    'al',
    'Ġlip',
    'stick',
    'w',
    'orst',
    'Ġtang',
    'led',
    'Ġretrospect',
    'ive',
    'int',
    'eger',
    'Ġvolunte',
    'ering',
    'Ġ19',
    '07',
    'Ġ',
    '--------------------',
    'ic',
    'hen',
    'Ġunve',
    'iling',
    'Ġsen',
    'seless',
    'Ġfisher',
    'ies',
    '\\',
    '-',
    'Ġh',
    'inges',
    'Ġcalcul',
    'us',
    'My',
    'th',
    'Ġund',
    'efeated',
    'Ġoptim',
    'izations',
    'Ġdep',
    'ress',
    'Ġbill',
    'board',
    'ĠY',
    'ad',
    'ĠPy',
    'ramid',
    'Is',
    'n',
    'I',
    'de',
    'Ġleg',
    'ion',
    'ĠK',
    'ramer',
    'ent',
    'anyl',
    'Ġpenet',
    'rating',
    'ĠHaw',
    'th',
    'ĠPR',
    'ODUCT',
    'ĠGer',
    'ard',
    'ĠP',
    'act',
    'ĠIn',
    'cluding',
    'ĠEl',
    'ias',
    'ĠEl',
    'aine',
    'vis',
    'ual',
    'Ġhum',
    'ming',
    'Ġcond',
    'esc',
    'ĠF',
    'asc',
    'ä¸',
    'Ĭ',
    'Ġe',
    'galitarian',
    'Ġdev',
    's',
    'ĠD',
    'ahl',
    'O',
    'ps',
    'D',
    'H',
    'ĠB',
    'ounce',
    'id',
    'ated',
    'ald',
    'o',
    'Ġrepublic',
    'an',
    'Ġh',
    'amb',
    'ĠS',
    'ett',
    'ograph',
    'ies',
    'CH',
    'APTER',
    'Ġtrans',
    'sexual',
    'Ġsky',
    'rocket',
    'ans',
    'wer',
    'Ġmark',
    'up',
    'Ø',
    'ª',
    'Ġhero',
    'ine',
    'Comp',
    'are',
    'ĠT',
    'av',
    'Be',
    'ast',
    'Ġsuccess',
    'ors',
    'Ġna',
    'Ã¯ve',
    'ĠBuck',
    'ley',
    'st',
    'ress',
    'me',
    'at',
    'Ġdownload',
    'able',
    'Ġindex',
    'ed',
    'Ġsc',
    'aff',
    'ĠL',
    'ump',
    'ĠHom',
    'o',
    'Stud',
    'io',
    'In',
    'sp',
    'Ġr',
    'acked',
    'far',
    'ious',
    'ĠPet',
    'ty',
    'Ex',
    'ternal',
    'Ġ19',
    '09',
    'W',
    'ars',
    'com',
    'mit',
    'put',
    'ers',
    'Ġun',
    'ob',
    'ĠEr',
    'r',
    'ĠE',
    'G',
    'ĠAl',
    'am',
    'ĠSiber',
    'ia',
    'ĠAtmosp',
    'heric',
    'IS',
    'TER',
    'ĠSatan',
    'ic',
    'trans',
    'lation',
    'ĠL',
    'oud',
    'tra',
    'umatic',
    'l',
    'ique',
    'Ġreson',
    'ate',
    'ĠWel',
    'ch',
    'Ġspark',
    'ing',
    'ĠT',
    'OM',
    't',
    'one',
    'Ġout',
    'l',
    'Ġhandc',
    'uffed',
    'ĠSer',
    'ie',
    '8',
    '01',
    'Ġland',
    'marks',
    'ĠRee',
    'ves',
    'Ġsoft',
    'ened',
    'Ġdazz',
    'ling',
    'ĠW',
    'anted',
    'month',
    's',
    'Mag',
    'ikarp',
    'Ġunt',
    'reated',
    'ĠBed',
    'ford',
    'M',
    'i',
    'ĠDynam',
    'o',
    'O',
    're',
    '79',
    '5',
    'Ġwrong',
    'ful',
    'Ġl',
    'ured',
    'Ġcort',
    'isol',
    'Ġve',
    'x',
    'd',
    'rawn',
    'ile',
    't',
    'Download',
    'ha',
    'ĠF',
    'action',
    'Ġlab',
    'yrinth',
    'Ġhij',
    'acked',
    'w',
    'aters',
    'er',
    'ick',
    'Ġsuper',
    'iors',
    'ĠRow',
    'ling',
    'ĠGu',
    'inness',
    'Ġt',
    'd',
    '99',
    '2',
    'Ġune',
    'arthed',
    'Ġcentr',
    'if',
    'Ġsham',
    'eless',
    'P',
    'od',
    'ĠF',
    'ib',
    'Ġ',
    'icing',
    'Ġpredict',
    'or',
    'Ġ29',
    '2',
    'fore',
    'station',
    'con',
    'struct',
    'C',
    'and',
    '@',
    '#',
    'Ġag',
    'itated',
    'Ġre',
    'pr',
    'OV',
    'A',
    'Ġkn',
    'itting',
    'ĠLim',
    'a',
    'Ġf',
    'odder',
    '68',
    '4',
    'ĠPerson',
    'a',
    'k',
    'l',
    '7',
    '01',
    'Ġbreak',
    'up',
    'á',
    '¸',
    'Ġapp',
    'alled',
    'Ġantidepress',
    'ants',
    'ĠSus',
    'sex',
    'Har',
    'ris',
    'ĠTher',
    'mal',
    'ee',
    'ee',
    'U',
    'pload',
    'Ġg',
    'ulf',
    'Ġdoor',
    'step',
    'ĠSh',
    'ank',
    'L',
    'U',
    'ĠM',
    'EN',
    'ĠP',
    'ond',
    's',
    'orry',
    'Ġmis',
    'fortune',
    'n',
    'ance',
    'Ġb',
    'ona',
    'M',
    'ut',
    'Ġde',
    'graded',
    'ĠL',
    'OG',
    'ĠN',
    'ess',
    'an',
    'imal',
    'Ġa',
    'version',
    'und',
    'own',
    'Ġsupplement',
    'ed',
    'ĠC',
    'ups',
    'Ġ50',
    '4',
    'Ġdep',
    'rive',
    'ĠSpark',
    'le',
    'Å',
    'Ĥ',
    'ĠMed',
    'itation',
    'auth',
    'ors',
    'ĠSab',
    'an',
    'ĠN',
    'aked',
    'air',
    'd',
    'ĠMand',
    'arin',
    'ĠScript',
    'ures',
    'ĠPerson',
    'nel',
    'ĠMahar',
    'ashtra',
    'Ġ19',
    '03',
    'ĠP',
    'ai',
    'ĠMir',
    'age',
    'omb',
    'at',
    'Access',
    'ory',
    'Ġfrag',
    'mented',
    'T',
    'ogether',
    'Ġbelie',
    'vable',
    'ĠGl',
    'adiator',
    'al',
    'igned',
    'ĠSl',
    'ug',
    'M',
    'AT',
    'Ġconvert',
    'ible',
    'ĠBour',
    'bon',
    'amer',
    'on',
    'ĠRe',
    'hab',
    'nt',
    'ax',
    'Ġpowd',
    'ered',
    'pill',
    'ar',
    'Ġsm',
    'oker',
    'ĠMans',
    'on',
    'ĠB',
    'F',
    '5',
    '11',
    'ĠGood',
    'ell',
    'ĠD',
    'AR',
    'm',
    'ud',
    'g',
    'art',
    'Ġob',
    'edient',
    'ĠTrans',
    'mission',
    'ĠDon',
    'ation',
    '8',
    '80',
    'Ġbother',
    'ing',
    'Material',
    's',
    'ãĤ',
    '±',
    'dest',
    'roy',
    'Ġfore',
    'going',
    'Ġanarch',
    'ism',
    'ĠK',
    'ry',
    'ice',
    'ps',
    'Ġl',
    'ittered',
    'ĠSch',
    'iff',
    'Ġanecd',
    'otal',
    'un',
    'its',
    'Ġf',
    'ian',
    'ĠSt',
    'im',
    'ĠS',
    'OME',
    'ĠInv',
    'aders',
    'Ġbehaviour',
    'al',
    'ĠVent',
    'ures',
    'Ġsub',
    'lime',
    'Ġfru',
    'ition',
    'ĠPen',
    'alty',
    'Ġcorros',
    'ion',
    '¶',
    'ħ',
    'Ġlik',
    'ened',
    'Ġbesie',
    'ged',
    'ween',
    'ey',
    'ĠCre',
    'ep',
    'Ġlinem',
    'en',
    'mult',
    'i',
    'ic',
    'ably',
    'ud',
    'der',
    'Ġvital',
    'ity',
    'Ġshort',
    'fall',
    'ĠP',
    'ants',
    'ap',
    'ist',
    'H',
    'idden',
    'ĠDro',
    'ps',
    'med',
    'ical',
    'Ġpron',
    'unciation',
    'ĠN',
    'RL',
    'Ġinsight',
    'ful',
    'J',
    'V',
    'ĠBe',
    'ard',
    'ĠCh',
    'ou',
    'Ġchar',
    'ms',
    'Ġb',
    'ins',
    'Ġamb',
    'assadors',
    'ĠS',
    'aturdays',
    'Ġinhib',
    'itor',
    'ĠFr',
    'anch',
    '6',
    '01',
    "',",
    "'",
    'ĠCon',
    'or',
    'art',
    'ney',
    'ĠX',
    'peria',
    'g',
    'rave',
    'be',
    'es',
    'ĠProtest',
    'ants',
    'Ġso',
    'aking',
    'ĠM',
    'andal',
    'Ġph',
    'ased',
    'Ġ6',
    '60',
    'Ġsc',
    'ams',
    'Ġbuzz',
    'ing',
    'ĠItal',
    'ians',
    'ĠLoren',
    'zo',
    'ĠJ',
    'A',
    'Ġhes',
    'itated',
    'Ġcl',
    'iffs',
    'ĠG',
    'OT',
    'ingu',
    'ishable',
    'Ġk',
    'o',
    'Ġinter',
    'ruption',
    'Z',
    'ip',
    'Lear',
    'ning',
    'Ġundersc',
    'ores',
    'ĠBl',
    'ink',
    'K',
    'u',
    '57',
    '9',
    'ĠAut',
    'ob',
    'I',
    'RE',
    'Ġwater',
    'ing',
    'Ġpast',
    'ry',
    '8',
    '20',
    'Ġvision',
    'ary',
    'ĠTempl',
    'ar',
    'awa',
    'ited',
    'Ġpist',
    'on',
    'Ġant',
    'id',
    'current',
    'ly',
    'Ġp',
    'ard',
    'Ġw',
    'aging',
    'Ġnob',
    'ility',
    'ĠY',
    'us',
    'Ġinject',
    'ing',
    'f',
    'aith',
    'ĠP',
    'ASS',
    'å',
    'º',
    'Ġret',
    'ake',
    'ĠPR',
    'OC',
    'Ġcat',
    'hedral',
    'b',
    'ash',
    'Ġwrest',
    'lers',
    'Ġpartner',
    'ing',
    'Ġn',
    'oses',
    'Ġ3',
    '58',
    'Trans',
    'form',
    'am',
    'en',
    'Ġb',
    'outs',
    'ĠId',
    'eal',
    'ĠConstant',
    'in',
    'Ġse',
    'p',
    'ĠMon',
    'arch',
    'att',
    'en',
    'ĠPe',
    'oples',
    'mod',
    'ified',
    'Ġmor',
    'atorium',
    'Ġpen',
    'chant',
    'Ġoffensive',
    'ly',
    'Ġprox',
    'ies',
    'ok',
    'ane',
    'ĠTaiwan',
    'ese',
    'ĠP',
    'oo',
    'ĠH',
    'OME',
    'us',
    'ional',
    'Ġver',
    'bs',
    'ĠO',
    'man',
    'vis',
    'ory',
    'Ġpersu',
    'asion',
    'Ġmult',
    'it',
    'Ġsc',
    'issors',
    'G',
    'ay',
    'ow',
    'ay',
    'oph',
    'ysical',
    'l',
    'us',
    'gn',
    'u',
    'Ġap',
    'ocalyptic',
    'Ġabsurd',
    'ity',
    'Ġplay',
    'book',
    'Ġautobi',
    'ography',
    'I',
    'UM',
    'Ġsne',
    'aking',
    'ĠSim',
    'ulation',
    'pp',
    's',
    'ell',
    'ery',
    'Plan',
    'et',
    'Ġright',
    'fully',
    'Ġn',
    'iece',
    'ĠN',
    'EC',
    'ĠIP',
    'O',
    'ĠDis',
    'closure',
    'lean',
    'or',
    'ous',
    'y',
    'ST',
    'ER',
    'Ġ28',
    '2',
    'Cru',
    'z',
    'Ch',
    'all',
    '64',
    '3',
    'ĠSurv',
    'ive',
    'ĠF',
    'atal',
    'ĠAm',
    'id',
    'ap',
    'o',
    'We',
    'apons',
    'D',
    'EN',
    '7',
    '70',
    'ĠGreen',
    'wald',
    'Ġlin',
    'en',
    'al',
    'os',
    'Ġpollut',
    'ants',
    'ĠPCI',
    'e',
    'k',
    'at',
    'Ġp',
    'aw',
    'ĠK',
    'raft',
    'C',
    'hem',
    'ĠTermin',
    'ator',
    'Ġre',
    'incarn',
    'Ġ]',
    '[',
    'ĠSe',
    'eds',
    'Ġsilhou',
    'ette',
    'ĠSt',
    'ores',
    'Ġgro',
    'oming',
    'ĠD',
    'irection',
    'ĠIs',
    'abel',
    'ĠBr',
    'idges',
    'ðŁ',
    'ĳ',
    'E',
    'ED',
    'ĠM',
    'orsi',
    'Ġval',
    'ves',
    'ĠRank',
    'ed',
    'ĠPh',
    'arma',
    'ĠOrgan',
    'izations',
    'Ġpenet',
    'rated',
    'ĠRod',
    'ham',
    'ĠProt',
    'oss',
    'Ġove',
    'rest',
    'Ġex',
    'asper',
    'ĠT',
    'J',
    'Ġ',
    '000000',
    'Ġtrick',
    'le',
    'Ġbour',
    'bon',
    'WH',
    'O',
    'Ġw',
    'retched',
    'Ġmicrosc',
    'opic',
    'Ġcheck',
    'list',
    'Ġad',
    'orned',
    'R',
    'oyal',
    'Ad',
    'minist',
    'ĠRet',
    'irement',
    'ĠHig',
    'hest',
    'We',
    'ather',
    'ile',
    'ge',
    'Ġincre',
    'ments',
    'ĠC',
    'osponsors',
    'Ġmas',
    'se',
    'ĠS',
    'inn',
    'r',
    'f',
    'Ġh',
    'ordes',
    'as',
    'sembly',
    '75',
    '4',
    'ĠNat',
    'asha',
    'ĠTY',
    'PE',
    'ĠGEN',
    'ERAL',
    'Ġarr',
    'anging',
    'Ġ40',
    '7',
    'l',
    'ator',
    'Ġg',
    'lean',
    'Ġdisc',
    'redited',
    'Ġclin',
    'icians',
    'UN',
    'E',
    'Ġachie',
    'ves',
    'ĠEm',
    'erson',
    'com',
    'plex',
    '=',
    '[',
    'Ġprincip',
    'ally',
    'Ġfra',
    'il',
    'p',
    'icked',
    'Ġthan',
    'king',
    'Ġre',
    'cl',
    'ĠL',
    'AST',
    'Ġsupp',
    'ressing',
    'il',
    'ic',
    'Ġantidepress',
    'ant',
    'ĠLis',
    'bon',
    'Ġth',
    'or',
    'Ġsp',
    'a',
    'Ġking',
    'doms',
    'ĠPear',
    'ce',
    'em',
    'o',
    'Ġpl',
    'ung',
    'Ġdiv',
    'est',
    'Ġ',
    '********************************',
    'b',
    'is',
    'osp',
    'els',
    'ad',
    'r',
    'Sp',
    'irit',
    'hall',
    'a',
    'P',
    'ink',
    'end',
    'ez',
    'Ġresurrect',
    'ed',
    'esc',
    'ape',
    'ĠRosen',
    'stein',
    'Ġge',
    'ological',
    'Ġnecess',
    'ities',
    'Ġcarn',
    'iv',
    'ĠE',
    'lys',
    'ĠBar',
    'ney',
    'Ġ29',
    '6',
    'dig',
    'y',
    'ST',
    'ON',
    'D',
    'OWN',
    'Ġmil',
    'estones',
    'Ġk',
    'er',
    'Ġdismant',
    'ling',
    'Ġre',
    'prim',
    'Ġcross',
    'ings',
    '19',
    '45',
    'Ġpatri',
    'archy',
    'Ġblasp',
    'hemy',
    'Ġ3',
    '59',
    'met',
    'ry',
    'ĠOb',
    'esity',
    'ĠDiff',
    'erences',
    'bl',
    'ocking',
    'ãĥķ',
    'ãĤ¡',
    'ich',
    'ita',
    'ĠSab',
    'ha',
    'ph',
    'alt',
    'ĠCol',
    'o',
    'ual',
    'a',
    'effic',
    'ients',
    'ĠMed',
    'ina',
    'con',
    'sole',
    '55',
    '7',
    'ĠHann',
    'ibal',
    'ĠHab',
    'it',
    'ĠF',
    'ever',
    'Ġthen',
    'ce',
    'Ġsyn',
    'agogue',
    'Ġessential',
    's',
    'Ġw',
    'ink',
    'ĠTr',
    'ader',
    'ID',
    'A',
    'ĠSp',
    'oiler',
    'ĠIceland',
    'ic',
    'ĠHay',
    'ward',
    'Ġpe',
    'ac',
    'Ġmal',
    'ice',
    'Ġflash',
    'back',
    'Ġth',
    'w',
    'Ġlay',
    'offs',
    'L',
    'iquid',
    'Ġtro',
    'oper',
    'Ġh',
    'inge',
    'ĠRead',
    'ers',
    'Ph',
    'ill',
    'ĠB',
    'auer',
    'Cre',
    'ated',
    'Ġaud',
    'its',
    'ac',
    'compan',
    'Ġunsus',
    'pecting',
    'ier',
    'a',
    '6666',
    '6666',
    'Ġbro',
    'ch',
    'Ġapprehend',
    'ed',
    'ĠM',
    'alk',
    'cer',
    'ning',
    'ĠCod',
    'ex',
    'O',
    'VER',
    'M',
    'arsh',
    'ĠD',
    'eng',
    'ĠExp',
    'ression',
    'Ġdisrespect',
    'ful',
    'Ġasc',
    'ending',
    't',
    'ests',
    'ĠPlaint',
    'iff',
    'ster',
    'y',
    'ĠAl',
    'ibaba',
    'din',
    'and',
    'ĠDem',
    'psey',
    'Applic',
    'ations',
    'mor',
    'al',
    'Ġthrough',
    'put',
    'Ġquar',
    'rel',
    'Ġm',
    'ills',
    'Ġhe',
    'mor',
    'ĠC',
    'ASE',
    'terror',
    'ist',
    'st',
    'im',
    'ifest',
    'yle',
    'ro',
    'zen',
    'CE',
    'PT',
    'Ar',
    'k',
    'u',
    'ci',
    'lect',
    'ic',
    'Ġirrit',
    'ating',
    'she',
    'ets',
    'A',
    'y',
    'Ġrede',
    'emed',
    'Ġhorn',
    'y',
    'ĠTe',
    'ach',
    'ĠS',
    'ear',
    'dem',
    'ocracy',
    '4',
    '65',
    'ĠRest',
    'ore',
    'Ġstand',
    'by',
    'ĠP',
    'is',
    'iff',
    'in',
    'Ġsleep',
    'y',
    'Ġextr',
    'ater',
    'Ġcompl',
    'iments',
    'Fram',
    'eworks',
    'Ġinstall',
    's',
    'Ġb',
    'anging',
    'sur',
    'face',
    'found',
    'land',
    'Ġmetaph',
    'ysical',
    'Ġ28',
    '3',
    'oul',
    's',
    'dev',
    'ices',
    'Ar',
    'gs',
    'ĠSac',
    'rifice',
    'ĠMcC',
    'orm',
    'es',
    'on',
    'Cons',
    'ervative',
    'ĠM',
    'ikhail',
    'see',
    'ing',
    'is',
    'ively',
    'ĠRo',
    'oms',
    'ĠGener',
    'ic',
    'Ġenthusi',
    'astically',
    'Ġgri',
    'pped',
    'Ġcomed',
    'ic',
    'ĠElectric',
    'ity',
    'Ġgu',
    'errilla',
    'Ġdec',
    'oration',
    'ĠPerspect',
    'ive',
    'Ġconsult',
    'ations',
    'Ġun',
    'amb',
    'Ġplag',
    'iar',
    'Ġmagic',
    'ian',
    'Ġe',
    'rection',
    'ĠTour',
    'ism',
    'or',
    'ied',
    'ro',
    'xy',
    '11',
    '00',
    'T',
    'am',
    'Ī',
    'è',
    'Î',
    '³',
    '×',
    'ª',
    'ĠPred',
    'ators',
    'Nit',
    'rome',
    'Ġtelesc',
    'opes',
    'project',
    's',
    'Ġun',
    'protected',
    'Ġst',
    'ocked',
    'ĠEnt',
    'reprene',
    'nex',
    'pected',
    'Ġwast',
    'ewater',
    'V',
    'ill',
    'Ġint',
    'imately',
    'Ġi',
    'Cloud',
    'ĠConst',
    'able',
    'Ġspo',
    'of',
    'Ġne',
    'farious',
    'Ġfin',
    's',
    'Ġcens',
    'or',
    'ĠMod',
    'es',
    'ĠEs',
    'per',
    'ar',
    'bon',
    'Ġinter',
    'sections',
    'Ġlaud',
    'ed',
    'Ġphys',
    'i',
    'Ġgener',
    'ously',
    'ĠThe',
    'Nitrome',
    'ĠTheNitrome',
    'Fan',
    'Ġar',
    'isen',
    'ĠÙ',
    'Ī',
    'Ġg',
    'lands',
    'ĠPav',
    'ilion',
    'ĠGu',
    'pta',
    'Ġuniform',
    'ly',
    'Ġr',
    'amps',
    'ri',
    'et',
    'ĠWH',
    'EN',
    'ĠVan',
    'essa',
    'Ġrout',
    'ed',
    'Ġlim',
    'p',
    'ĠC',
    'PI',
    'p',
    'ter',
    'int',
    'uitive',
    'Ġv',
    'aping',
    'Ġexperiment',
    'ed',
    'ĠOlymp',
    'us',
    'ĠAm',
    'on',
    'Ġsight',
    'ing',
    'Ġinfiltr',
    'ate',
    'ĠGentle',
    'man',
    'Ġsign',
    'ings',
    'ĠMe',
    'ow',
    'ĠNav',
    'igation',
    'che',
    'cks',
    '4',
    '33',
    'Ġel',
    'apsed',
    'ĠBulg',
    'arian',
    'esp',
    'ie',
    'ĠS',
    'OM',
    'd',
    'uring',
    'Ġsp',
    'ills',
    'anc',
    'a',
    'ĠPly',
    'mouth',
    'M',
    'AL',
    'Ġdomest',
    'ically',
    'ĠWater',
    'gate',
    'ĠF',
    'AM',
    'k',
    'illed',
    'ed',
    'ited',
    'ĠYour',
    'self',
    'Ġsynchron',
    'ization',
    'ĠPract',
    'ices',
    'ST',
    'EP',
    'Ġgen',
    'omes',
    'ĠQ',
    'R',
    'not',
    'ice',
    'Ġloc',
    'ating',
    'z',
    'in',
    'Ġ3',
    '29',
    'al',
    'cohol',
    'Ġk',
    'itten',
    'V',
    'o',
    'Ġr',
    'inse',
    'Ġgrapp',
    'le',
    'ĠSc',
    'rew',
    'ĠD',
    'ul',
    'A',
    'IR',
    'Ġle',
    'asing',
    'ĠCaf',
    'Ã©',
    'Ġro',
    'ses',
    'ĠRes',
    'pect',
    'Ġmis',
    'lead',
    'Ġperfect',
    'ed',
    'Ġnud',
    'ity',
    'Ġnon',
    'partisan',
    'ĠCons',
    'umption',
    'Report',
    'ing',
    'Ġnu',
    'ances',
    'Ġdeduct',
    'ible',
    'ĠSh',
    'ots',
    'Ġ3',
    '77',
    'Ġæ',
    'ľ',
    'ano',
    'oga',
    'Ben',
    'ef',
    'ĠB',
    'am',
    'ĠS',
    'amp',
    'if',
    'ix',
    'Ġgal',
    'van',
    'ĠMed',
    'als',
    'rad',
    'ius',
    'Ġno',
    'bles',
    'Ġe',
    'aves',
    'igr',
    'ate',
    'K',
    'T',
    'ĠHar',
    'bour',
    'u',
    'ers',
    'Ġrisk',
    'ed',
    're',
    'q',
    'Ġneuro',
    't',
    'get',
    'table',
    'ain',
    'a',
    'Rom',
    'ney',
    'Ġunder',
    'pin',
    'Ġlo',
    'ft',
    'ĠSub',
    'committee',
    'ĠMong',
    'ol',
    'b',
    'iz',
    'Ġmanif',
    'ests',
    'ass',
    'isted',
    'ĠG',
    'aga',
    'Ġsy',
    'nergy',
    'Ġreligious',
    'ly',
    'ĠPre',
    'f',
    'ĠG',
    'erry',
    'T',
    'AG',
    'ĠCho',
    'i',
    '4',
    '66',
    'beh',
    'ind',
    'ĠO',
    'u',
    'Gold',
    'Magikarp',
    'Ġhemor',
    'rh',
    'R',
    'iver',
    'Ġtend',
    'on',
    'Ġinj',
    'ure',
    'ĠF',
    'iona',
    'Ġp',
    'ag',
    'Ġag',
    'itation',
    '||',
    '||',
    'ur',
    'an',
    'ĠE',
    'SA',
    'Ġest',
    'eem',
    'Ġdod',
    'ging',
    'Ġ4',
    '12',
    'r',
    'ss',
    'Ġce',
    'ases',
    'ex',
    'cluding',
    'Ġint',
    'akes',
    'Ġinsert',
    's',
    'Ġemb',
    'old',
    'ĠO',
    'ral',
    'up',
    'uncture',
    '4',
    '11',
    'ĠUn',
    'ified',
    'ĠDe',
    'le',
    'Ġfurn',
    'ace',
    'ĠCoy',
    'otes',
    'ĠBr',
    'ach',
    'L',
    'abor',
    'Ġhand',
    'shake',
    'Ġbru',
    'ises',
    'Gr',
    'ade',
    'éĹ',
    'ĺ',
    'ĠGram',
    'my',
    'ile',
    'en',
    'St',
    'ates',
    'ĠScandinav',
    'ian',
    'ĠKard',
    'ash',
    '8',
    '66',
    'Ġeffort',
    'lessly',
    'ĠDI',
    'RECT',
    'ĠTH',
    'EN',
    'ĠMe',
    'i',
    'ert',
    'ation',
    '19',
    '68',
    'Ġgro',
    'in',
    'w',
    'itch',
    'Requ',
    'irements',
    '98',
    '5',
    'Ġroof',
    's',
    'Ġest',
    'ates',
    'ĠH',
    'F',
    'Ġha',
    'ha',
    'Ġdense',
    'ly',
    'ĠO',
    'CT',
    'Ġpl',
    'astics',
    'Ġincident',
    'ally',
    'ĠTr',
    'acks',
    'ĠTax',
    'es',
    'Ġch',
    'anted',
    'Ġforce',
    'ful',
    'ĠBie',
    'ber',
    'ĠK',
    'ahn',
    'K',
    'ent',
    'ĠC',
    'ot',
    'lic',
    'ts',
    'F',
    'ed',
    'Ġhide',
    'ous',
    'ĠVer',
    'd',
    'ĠSynd',
    'icate',
    'ĠIl',
    'legal',
    'J',
    'et',
    'ĠD',
    'AV',
    're',
    'asonable',
    'c',
    'rew',
    'Ġfundamental',
    'ist',
    'Ġtruth',
    'ful',
    'ĠJ',
    'ing',
    'Ġl',
    'il',
    'Ġdown',
    'ed',
    'Ġen',
    'chanted',
    'ĠPolic',
    'ies',
    'ĠMcM',
    'aster',
    'ĠH',
    'are',
    'ides',
    'how',
    'Ġpar',
    'ams',
    'en',
    'cers',
    'gorith',
    'm',
    'Ġallow',
    'ances',
    'Ġturb',
    'ulent',
    'Ġcomplex',
    'ities',
    'ĠK',
    'T',
    'Ġ3',
    '37',
    'ĠGen',
    'etic',
    'F',
    'UN',
    'D',
    'oug',
    't',
    'ick',
    'Ġg',
    'igs',
    'ument',
    'hal',
    'Ġpatriarch',
    'al',
    'Ġcal',
    'c',
    ',',
    '...',
    'Ġc',
    'out',
    'ĠGu',
    'an',
    'Ġpath',
    'ological',
    'ĠR',
    'ivals',
    'Ġunder',
    'rated',
    'Ġflu',
    'orescent',
    'ĠJ',
    'iu',
    'arna',
    'ev',
    'ĠQu',
    'an',
    'Ġ4',
    '29',
    'Ġ',
    'à¨',
    'M',
    'ario',
    'Con',
    'struct',
    'ĠC',
    'itation',
    'ĠR',
    'acial',
    'ĠR',
    'SA',
    'ĠF',
    'idel',
    'Ġ3',
    '95',
    'Person',
    'ally',
    'C',
    'ause',
    'Ã',
    '»',
    'rad',
    'ical',
    'in',
    'en',
    'Ġvehement',
    'ly',
    'ĠPap',
    'a',
    'Ġintern',
    'ship',
    'Ġfl',
    'akes',
    'ĠRe',
    'ck',
    'Luck',
    'ily',
    'B',
    'ra',
    '20',
    '20',
    'rav',
    'ings',
    'R',
    'N',
    'W',
    'onder',
    'Ser',
    'iously',
    'Ġre',
    'usable',
    'Ġpoll',
    'uted',
    'ĠP',
    'eng',
    'le',
    'igh',
    'ind',
    'le',
    'Ġcircuit',
    'ry',
    'ĠMad',
    'onna',
    'ĠB',
    'ART',
    'Res',
    'idents',
    'att',
    'ribute',
    'Phil',
    'adelphia',
    'Cl',
    'ub',
    'Ġplan',
    'ner',
    'Ġfr',
    'antically',
    'Ġfaith',
    'fully',
    'ĠTerrit',
    'ories',
    'ĠL',
    'AT',
    'ĠAnders',
    'en',
    'an',
    'u',
    'ĠP',
    'ARK',
    'ĠS',
    'ora',
    'i',
    'age',
    'ĠPlay',
    'offs',
    'ĠG',
    'CC',
    '4',
    '27',
    'Ġab',
    'norm',
    'ĠL',
    'ever',
    'Ġdisob',
    'edience',
    'As',
    'ync',
    'ĠShe',
    'a',
    'V',
    'ert',
    'Ġsk',
    'irts',
    'ĠSaw',
    'yer',
    'x',
    'p',
    'Ġwors',
    'ening',
    'Ġsc',
    'apego',
    'ĠAng',
    'le',
    'oth',
    'al',
    'Ġtro',
    've',
    'ĠSt',
    'y',
    'ĠN',
    'guyen',
    'mar',
    'ine',
    'ide',
    'on',
    'Dep',
    'ths',
    'Bl',
    'og',
    'ĠIll',
    'uminati',
    'Ġtract',
    's',
    'Ġorgan',
    'ise',
    'Ġo',
    'str',
    'F',
    's',
    'Ġlever',
    'aging',
    'ĠD',
    'aredevil',
    'as',
    'ar',
    'Ġl',
    'ang',
    'Ġex',
    'termin',
    'urs',
    'ions',
    'ĠRom',
    'o',
    'ãĤ¤',
    'ãĥĪ',
    'Ġcont',
    'ended',
    'Ġencounter',
    'ing',
    'ĠTable',
    't',
    'ĠAltern',
    'ate',
    'sk',
    'ill',
    'Ġswe',
    'ets',
    'Ġco',
    'hesive',
    'cap',
    'acity',
    'Ġrep',
    'ud',
    'Ġl',
    'izard',
    'ro',
    'o',
    'Ġpilgr',
    'ims',
    'ĠR',
    'uff',
    'ĠInstr',
    'ument',
    'ĠLog',
    'o',
    'uit',
    'ous',
    'E',
    'H',
    'Ġsales',
    'man',
    'Ġank',
    'les',
    'L',
    'ed',
    'ĠPat',
    'ty',
    'ud',
    'os',
    'Own',
    'er',
    'Ġdiscrep',
    'ancies',
    'k',
    'j',
    'M',
    'U',
    'Ġuncond',
    'itional',
    'Dragon',
    'Magazine',
    'i',
    'ard',
    'O',
    'ak',
    'ĠConvers',
    'ation',
    'be',
    'er',
    'ĠOs',
    'aka',
    'D',
    'elta',
    'us',
    'ky',
    'Ġsecret',
    'ion',
    'Ġpl',
    'aza',
    'Ġm',
    'ing',
    'Ġde',
    'pletion',
    'ĠM',
    'ous',
    'ĠI',
    'TS',
    'ĠH',
    'imal',
    'ĠFle',
    'ming',
    'Ġcyt',
    'ok',
    'ĠH',
    'ick',
    'Ġbat',
    'ters',
    'ĠInt',
    'ellectual',
    '6',
    '75',
    'Ã©',
    'r',
    'IS',
    'ION',
    'ĠQu',
    'entin',
    'ĠCh',
    'apters',
    'ih',
    'adi',
    'Ġco',
    'aster',
    'WAY',
    'S',
    'ĠL',
    'izard',
    'ĠY',
    'or',
    'and',
    'ering',
    'S',
    'kin',
    'ha',
    'ust',
    'ab',
    'by',
    'Ġportray',
    'ing',
    'Ġwield',
    'ed',
    'd',
    'ash',
    'Ġprop',
    'onent',
    'Ġr',
    'ipple',
    'Ġgrap',
    'hene',
    'Ġfly',
    'er',
    'Ġrec',
    'urrent',
    'Ġdev',
    'ils',
    'Ġwater',
    'fall',
    'æĺ',
    '¯',
    'go',
    'o',
    'Text',
    'Color',
    'Ġtam',
    'pering',
    'IV',
    'ES',
    'TR',
    'UMP',
    'ĠAb',
    'el',
    'ĠS',
    'AL',
    'ĠHend',
    'ricks',
    'ĠLu',
    'cius',
    'b',
    'ots',
    'Ġ40',
    '96',
    'IST',
    'ORY',
    'Gu',
    'est',
    'ĠN',
    'X',
    'in',
    'ant',
    'Ben',
    'z',
    'ĠLoad',
    'ed',
    'ĠCle',
    'ver',
    't',
    'reatment',
    'Ġta',
    'vern',
    'Ġ3',
    '39',
    'ĠT',
    'NT',
    'ific',
    'antly',
    'Tem',
    'perature',
    'F',
    'el',
    'Ġunder',
    'world',
    'ĠJud',
    'ges',
    'Ġ<',
    '+',
    'Ġst',
    'ump',
    'Ġoccup',
    'ancy',
    'Ġab',
    'er',
    'ĠF',
    'inder',
    ')',
    '",',
    'ĠN',
    'unes',
    'res',
    'et',
    'in',
    'et',
    'ect',
    'omy',
    'Ġwell',
    'ness',
    'ĠP',
    'eb',
    'quart',
    'ered',
    'and',
    'an',
    'Ġneg',
    'atives',
    'ĠTh',
    'iel',
    'ĠCl',
    'ip',
    'ĠL',
    'TD',
    'Ġbl',
    'ight',
    'Ġreperto',
    'ire',
    'K',
    'yle',
    'Ġqu',
    'er',
    'ĠC',
    'es',
    'Ġha',
    'pl',
    '98',
    '9',
    'ĠTh',
    'ames',
    'isc',
    'opal',
    'Des',
    'k',
    'ivari',
    'ate',
    'ĠEx',
    'cellence',
    'found',
    'ation',
    'Ġâ',
    'ĩ',
    'X',
    'i',
    'Ġmyster',
    'iously',
    'esty',
    'les',
    'Ġper',
    'ish',
    'ĠEng',
    'els',
    'ĠDE',
    'AD',
    '09',
    '0',
    '}}',
    '}',
    'ĠUn',
    'real',
    'Ġrest',
    'less',
    'ID',
    'ES',
    'orth',
    'odox',
    'ĠInter',
    'mediate',
    'Ġdin',
    'ners',
    'ĠTr',
    'out',
    'ĠSe',
    'ym',
    'ĠHall',
    's',
    'og',
    'ged',
    'Ġtraged',
    'ies',
    'Ġdid',
    'nt',
    '67',
    '6',
    'Ġail',
    'ments',
    'Ġobserv',
    'able',
    'ĠV',
    'ide',
    'ad',
    'apt',
    'ĠD',
    'usk',
    'Ġprofessional',
    'ism',
    'ĠPres',
    'cott',
    'ĠInd',
    'ies',
    'p',
    'ox',
    'ĠMe',
    'hran',
    'W',
    'ide',
    'Ġend',
    'emic',
    'ĠPar',
    'an',
    'B',
    'ird',
    'Ġped',
    'als',
    'ĠI',
    'U',
    'ĠAdam',
    'ant',
    'ĠH',
    'urt',
    'Ġcorrel',
    'ates',
    'urd',
    'en',
    'Ġspons',
    'oring',
    'cl',
    'imate',
    'ĠUnivers',
    'ities',
    'ĠK',
    'not',
    'enn',
    'es',
    'ĠDam',
    'ian',
    'ĠAx',
    'el',
    'S',
    'port',
    'Ġbar',
    'b',
    'ĠS',
    'no',
    'sh',
    'own',
    'ste',
    'en',
    'ud',
    'ence',
    'Ġnon',
    'violent',
    'Ġhom',
    'ophobia',
    'Ġbiom',
    'ass',
    'ĠDet',
    'ail',
    'Ġsrf',
    'N',
    'ĠT',
    'une',
    'accompan',
    'ied',
    'I',
    'ENCE',
    'Al',
    'bert',
    'ĠMong',
    'o',
    'z',
    'x',
    'ĠCer',
    'berus',
    'or',
    'bit',
    'c',
    'ens',
    'Ġsl',
    'ay',
    'SH',
    'ARE',
    'H',
    'Y',
    'Ġb',
    'rawl',
    'ĠPro',
    'be',
    'Ġnonex',
    'istent',
    'ĠClare',
    'nce',
    'ĠBlack',
    'burn',
    'Ġport',
    'als',
    'ĠR',
    'ita',
    'ĠRem',
    'ain',
    'ĠLe',
    'vant',
    'Ġtrick',
    'ed',
    'ĠF',
    'erry',
    'aver',
    'ing',
    'ĠStraw',
    'berry',
    'ĠAn',
    'swers',
    'Ġhorrend',
    'ous',
    'ĠA',
    'man',
    'Supp',
    'lement',
    'ĠT',
    'oad',
    'Ġpe',
    'eled',
    'Ġman',
    'oeuv',
    'ĠU',
    'zbek',
    'mond',
    's',
    'ĠH',
    'ector',
    'Ġ40',
    '2',
    'pe',
    'es',
    'fix',
    'es',
    'Ġd',
    'j',
    'Ġres',
    'umes',
    'Ġaccount',
    'ant',
    'Ġadvers',
    'ity',
    'Ġham',
    'pered',
    'ĠL',
    'arson',
    'Ġd',
    'oping',
    'part',
    's',
    'H',
    'ur',
    'Ġbe',
    'arded',
    'Ġy',
    'r',
    'ĠPlug',
    'in',
    'å¥',
    '³',
    'Ġ/',
    '**',
    'rol',
    'ley',
    'Ġwaters',
    'hed',
    'ĠSub',
    'mission',
    'if',
    'lower',
    'AS',
    'C',
    'Ġcho',
    'ir',
    'Ġsculpt',
    'ures',
    'm',
    'A',
    'incre',
    'asing',
    'ai',
    'i',
    'Ġsne',
    'akers',
    'Ġconfront',
    's',
    'ĠEle',
    'phant',
    'ĠEl',
    'ixir',
    'Ġrec',
    'al',
    'ĠT',
    'TL',
    'w',
    'idget',
    'ĠW',
    'ax',
    'ĠGr',
    'ayson',
    'Ġha',
    'irst',
    'Ġhumili',
    'ated',
    'ĠWAR',
    'N',
    'app',
    'iness',
    'ĠT',
    'TC',
    'F',
    'uel',
    'Ġpol',
    'io',
    'Ġcomplex',
    'es',
    'Ġbab',
    'e',
    'ĠX',
    'IV',
    'P',
    'F',
    ').',
    '[',
    'P',
    'arts',
    'Ġ4',
    '35',
    'M',
    'eg',
    'ĠY',
    'ards',
    'ĠAL',
    'P',
    'Ġy',
    'ells',
    'Ġprin',
    'ces',
    'Ġbull',
    'ies',
    'ĠCapital',
    'ism',
    'ex',
    'empt',
    'FA',
    'Q',
    'ĠSp',
    'onge',
    'ĠAl',
    'a',
    'Ġpleas',
    'antly',
    'Ġbu',
    'f',
    'Ġden',
    'ote',
    'Ġunp',
    'ublished',
    'Ġkne',
    'eling',
    'asc',
    'a',
    'Ġl',
    'apse',
    'al',
    'ien',
    '99',
    '4',
    'Ġrefere',
    'es',
    'ĠLaw',
    'yers',
    'S',
    'anta',
    'Ġpuzz',
    'ling',
    'ĠProm',
    'etheus',
    'ĠPh',
    'araoh',
    'ĠDel',
    'ay',
    'Ġfacilit',
    'ates',
    'ĠC',
    'ES',
    'Ġjew',
    'els',
    'Ġbook',
    'let',
    'ond',
    'ing',
    'Ġpolar',
    'ization',
    'ĠMor',
    'an',
    'ĠSal',
    'ad',
    'ĠS',
    'OS',
    'ĠAdv',
    'ice',
    'PH',
    'OTOS',
    'IC',
    'AN',
    'iat',
    'ures',
    'ex',
    'press',
    'ĠWonder',
    'land',
    'ĠC',
    'ODE',
    'ĠCL',
    'ASS',
    '9',
    '75',
    'Ġg',
    'rep',
    'ĠD',
    'iesel',
    'ĠGl',
    'ac',
    '!',
    '?"',
    'Ġr',
    'm',
    'o',
    'ine',
    'disc',
    'rimination',
    'ĠN',
    'urse',
    'm',
    'allow',
    'Ġv',
    'ortex',
    'ĠCons',
    'ortium',
    'Ġlarge',
    'Download',
    'stra',
    'ight',
    'augh',
    'lin',
    'G',
    'rad',
    'Ġpublic',
    'ized',
    'ĠW',
    'aves',
    'ĠRed',
    'd',
    'Ġfest',
    'ivities',
    'ĠM',
    'ane',
    'ar',
    'ov',
    'Ġfleet',
    'ing',
    'ĠDr',
    'unk',
    'ug',
    'en',
    'C',
    'ele',
    'Ġchromos',
    'omes',
    'ĠD',
    'OT',
    '-+-+',
    '-+-+',
    'Ġbus',
    'iest',
    'ĠBe',
    'aver',
    'Sy',
    'rian',
    'ĠK',
    'yr',
    'k',
    'as',
    'ĠCross',
    'Ref',
    '19',
    '50',
    '76',
    '01',
    'Ġrepe',
    'aling',
    'ĠWin',
    'ners',
    'ĠMac',
    'ro',
    'ĠD',
    'OD',
    'bl',
    'ance',
    'S',
    'ort',
    '64',
    '1',
    'Ġmet',
    're',
    'ĠD',
    'irk',
    'Ġgo',
    'ggles',
    'Ġdraw',
    'backs',
    'Ġcomplain',
    'ant',
    'Ġauthor',
    'izing',
    'Ġantit',
    'rust',
    'oper',
    'ated',
    'Ġm',
    'ah',
    'Ġexagger',
    'ation',
    'Am',
    'azing',
    'ĠSer',
    'aph',
    'Ġha',
    'ze',
    'w',
    'ow',
    'Ġextingu',
    'ished',
    'Ġcan',
    'yon',
    'ĠB',
    'osh',
    'Ġv',
    'ents',
    'Ġsc',
    'rape',
    'Cor',
    'rect',
    '4',
    '26',
    'Ġav',
    'g',
    'Dem',
    'and',
    'ĠâĪ',
    '¼',
    'Ġmicrobi',
    'ota',
    '"}',
    '],"',
    'ĠSt',
    'ev',
    'B',
    'io',
    'ĠPlan',
    'es',
    'Ġsuggest',
    'ive',
    'Ġdec',
    'ipher',
    'ĠRefuge',
    'e',
    'ĠKe',
    'jriwal',
    'ĠGreen',
    'peace',
    'Ġdecl',
    'ass',
    'ĠSound',
    'ers',
    'Ġth',
    'o',
    'Ġdec',
    'rypt',
    'Ġbr',
    'ushing',
    'ĠJane',
    'iro',
    'ip',
    'op',
    'S',
    'i',
    '8',
    '77',
    'ĠGeoff',
    'rey',
    'Ġc',
    'pu',
    'ĠHaz',
    'el',
    'Ġview',
    'points',
    'Ġcris',
    'py',
    'ĠNot',
    'ification',
    'Ġsold',
    'er',
    'ĠMod',
    'est',
    'ĠHem',
    'isphere',
    'Ġcass',
    'ette',
    'in',
    'cludes',
    'Ġident',
    'ifiers',
    'ĠC',
    'ALL',
    'in',
    'cent',
    'T',
    'odd',
    'ĠSwe',
    'ep',
    'Ġ3',
    '34',
    'b',
    'oss',
    'Ġsm',
    'ir',
    'gin',
    'x',
    'Ġtown',
    'ship',
    'Ġg',
    'rieving',
    'ĠMos',
    'que',
    'Net',
    'flix',
    'AS',
    'ED',
    'ĠMillenn',
    'ials',
    'oc',
    'om',
    '19',
    '67',
    'Ġbold',
    'ly',
    's',
    'leep',
    'Ġes',
    'che',
    'arij',
    'uana',
    'Ġsw',
    'irl',
    'ĠPen',
    'al',
    'Ġneglig',
    'ent',
    'ĠStephen',
    'son',
    'K',
    'ER',
    'ĠZ',
    'oro',
    'ris',
    'is',
    'Ġlocal',
    'ization',
    'ĠSeym',
    'our',
    'ĠAng',
    'lic',
    'red',
    'itation',
    'prot',
    'ection',
    'ĠPa',
    'ige',
    'Ġo',
    'mit',
    'ĠR',
    'ousse',
    'ĠT',
    'ub',
    'Ġinv',
    'itations',
    't',
    'ty',
    'Ġm',
    'oss',
    'ph',
    'ysical',
    'C',
    'redits',
    'Ġan',
    'archy',
    'Ġchild',
    'care',
    'Ġl',
    'ull',
    'ĠM',
    'ek',
    'ĠL',
    'anguages',
    'lat',
    'est',
    'ĠSan',
    'ford',
    'Ġus',
    'ability',
    'Ġdiff',
    'use',
    'ĠD',
    'ATA',
    'Ġsp',
    'rites',
    'ĠVeget',
    'a',
    'ĠProm',
    'otion',
    'ãĥ¼',
    'ãĤ¯',
    'rict',
    'ing',
    'z',
    'ee',
    'Tur',
    'kish',
    'ĠTD',
    's',
    'pro',
    'ven',
    '57',
    '1',
    'Ġsmug',
    'glers',
    '707',
    '10',
    'Ġreform',
    'ed',
    'ĠLo',
    'is',
    'Ġun',
    'fl',
    'ĠWITH',
    'OUT',
    'ĠReturn',
    'ing',
    'ann',
    'ie',
    'ĠTom',
    'as',
    'Fr',
    'anc',
    'ĠProf',
    'it',
    'ĠSER',
    'V',
    'ĠR',
    'umble',
    'ik',
    'uman',
    'es',
    'an',
    'Ġt',
    'esters',
    'Ġgad',
    'get',
    'Ġbrace',
    'let',
    'ĠF',
    'SA',
    'comp',
    'onent',
    'Ġparamed',
    'ics',
    'Ġj',
    'an',
    'ĠRem',
    'em',
    'ĠSk',
    'inner',
    'Ġl',
    'ov',
    'ĠQu',
    'ake',
    'rom',
    'a',
    'Ġfl',
    'ask',
    'Pr',
    'inc',
    'Ġover',
    'power',
    'Ġlod',
    'ging',
    'ĠK',
    'KK',
    'ret',
    'te',
    'Ġabsor',
    'bs',
    'w',
    'rote',
    'Ġ',
    ',"',
    'K',
    'ings',
    'ĠH',
    'ail',
    'ĠFall',
    'ing',
    'xt',
    'ap',
    'ĠHel',
    'ena',
    'ire',
    'ns',
    'L',
    'arry',
    'Ġpamph',
    'let',
    'ĠC',
    'PR',
    'G',
    'ro',
    'ĠHirosh',
    'ima',
    'Ġhol',
    'istic',
    '".',
    '[',
    'Ġdet',
    'achment',
    'Ġas',
    'pire',
    'Ġcompl',
    'icit',
    'ĠGreen',
    'wood',
    'Ġresp',
    'awn',
    'ĠSt',
    'upid',
    'ĠFin',
    'ished',
    'f',
    'al',
    'b',
    'ass',
    'Ġab',
    'hor',
    'Ġmock',
    'ery',
    'ĠFe',
    'ast',
    'VID',
    'EO',
    'Ġcon',
    'sec',
    'ĠHung',
    'ry',
    'P',
    'ull',
    'ĠH',
    'ust',
    'it',
    'ance',
    '?',
    'ãĢį',
    ')',
    '--',
    'ĠPar',
    'allel',
    'con',
    'v',
    '4',
    '69',
    'ha',
    'ar',
    'w',
    'ant',
    'P',
    'aper',
    'm',
    'ins',
    'ĠTor',
    'o',
    'ĠTR',
    'UMP',
    'ĠR',
    'ai',
    'D',
    'W',
    'ĠW',
    'icked',
    'ĠL',
    'ep',
    'Ġfun',
    'ky',
    'Ġdetrim',
    'ent',
    'ios',
    'is',
    'ache',
    'v',
    'Ġde',
    'grade',
    'im',
    'ilation',
    'Ġret',
    'ard',
    'Ġfrag',
    'mentation',
    'Ġcow',
    'boy',
    'ĠY',
    'PG',
    'ĠH',
    'AL',
    'Parent',
    's',
    'ĠS',
    'ieg',
    'ĠStra',
    'uss',
    'ĠRub',
    'ber',
    '×',
    'Ĳ',
    'Fr',
    'ag',
    'Ġp',
    't',
    'Ġoption',
    'ally',
    'ĠZ',
    'IP',
    'ĠTrans',
    'cript',
    'ĠD',
    'well',
    '88',
    '2',
    'M',
    'erc',
    'ĠM',
    'OT',
    'ãĥ¯',
    'ãĥ³',
    'Ġhun',
    'ts',
    'Ġexec',
    'utes',
    'In',
    'cludes',
    'Ġacid',
    'ic',
    'ĠRespons',
    'ibility',
    'ĠD',
    'umb',
    'we',
    'i',
    'And',
    'erson',
    'ĠJas',
    'per',
    'ight',
    'on',
    'abs',
    'olutely',
    'Ad',
    'ult',
    'Ġpl',
    'under',
    'Mor',
    'ning',
    'ĠT',
    'ours',
    'ĠD',
    'ane',
    'Î',
    'º',
    'ĠT',
    'EST',
    'ĠG',
    'ina',
    'Ġcan',
    'ine',
    'aw',
    'an',
    'Ġsocial',
    'ists',
    'ĠS',
    'oda',
    'Ġimp',
    'etus',
    'ĠSupplement',
    'ary',
    'oli',
    'ath',
    'ĠKinn',
    'ikuman',
    'mitted',
    'ly',
    'second',
    's',
    'Ġorganis',
    'ers',
    'Ġdocument',
    'aries',
    'Vari',
    'able',
    'GRE',
    'EN',
    'Ġres',
    'orts',
    'Ġbr',
    'agging',
    'Ġ3',
    '68',
    'Art',
    'ist',
    'w',
    'k',
    'bl',
    'ers',
    'Un',
    'common',
    'ĠRet',
    'rieved',
    'Ġhect',
    'ares',
    'Ġtox',
    'in',
    'r',
    'ank',
    'Ġfaith',
    's',
    'ĠG',
    'raphic',
    'Ġve',
    'c',
    'ĠL',
    'IA',
    'Af',
    'rican',
    'Ġard',
    'ent',
    'end',
    'iary',
    'L',
    'ake',
    'ĠD',
    'OS',
    'cient',
    'ious',
    'ĠOk',
    'awaru',
    'ĠAll',
    'y',
    'ĠTim',
    'eline',
    'D',
    'ash',
    'ĠI',
    'c',
    'contin',
    'ue',
    'Ġt',
    'idy',
    'Ġinstinct',
    'ively',
    'ĠP',
    'ossibly',
    'ĠOut',
    'door',
    'ĠWould',
    'n',
    'Ġl',
    'ich',
    'ĠBr',
    'ay',
    'ĠA',
    'X',
    'ĠÃ',
    'ī',
    'Ġ+',
    '#',
    '\\',
    "'",
    'Direct',
    'ory',
    'ab',
    'iding',
    'Ġf',
    'eral',
    'ic',
    'ative',
    'but',
    't',
    'Ġper',
    'verse',
    'S',
    'alt',
    'Ġwar',
    'ped',
    'Ġnin',
    'eteen',
    'Ġcabin',
    'ets',
    'Ġsrf',
    'Attach',
    'ĠSl',
    'oan',
    'Ġpower',
    'ing',
    'reg',
    'ation',
    'F',
    'light',
    'se',
    'vere',
    'Ġst',
    'ren',
    'Ġc',
    'og',
    'ap',
    'ache',
    'Ġâ',
    'Ŀ',
    'Ġcaf',
    'eteria',
    'p',
    'aces',
    'ĠGrim',
    'oire',
    'uton',
    'ium',
    'Ġr',
    'aining',
    'Ġcir',
    'cling',
    'Ġlineback',
    'ers',
    'c',
    'redit',
    'Ġrep',
    'atri',
    'ĠCam',
    'den',
    'lic',
    'ense',
    'Ġly',
    'ric',
    'Ġdescript',
    'or',
    'Ġval',
    'leys',
    'Ġre',
    'q',
    'Ġback',
    'stage',
    'ĠPro',
    'hibition',
    'ĠK',
    'et',
    'Op',
    'ening',
    'S',
    'ym',
    'æĸ',
    '¹',
    'Ġserv',
    'ings',
    'Ġoverse',
    'en',
    'Ġaster',
    'oids',
    'ĠMod',
    's',
    'ĠSpr',
    'inger',
    'ĠCont',
    'ainer',
    'è',
    '»',
    'ĠM',
    'ens',
    'Ġmult',
    'im',
    'Ġfire',
    'fighter',
    'pe',
    'c',
    'Ġchlor',
    'ine',
    'Ð',
    '¼',
    'end',
    'i',
    'Ġsp',
    'aring',
    'Ġpolyg',
    'amy',
    'ĠR',
    'N',
    'ĠP',
    'ell',
    'Ġt',
    'igers',
    'Ġflash',
    'y',
    'ĠMad',
    'ame',
    'S',
    'word',
    'Ġpref',
    'rontal',
    'Ġpre',
    'requisite',
    'uc',
    'a',
    'Ġw',
    'ifi',
    'Ġmiscon',
    'ception',
    'Ġharsh',
    'ly',
    'ĠStream',
    'ing',
    'ot',
    'om',
    'ĠGiul',
    'iani',
    'foot',
    'ed',
    'Ġtub',
    'ing',
    'ind',
    'ividual',
    'z',
    'ek',
    'n',
    'uclear',
    'm',
    'ol',
    'Ġright',
    'ful',
    '49',
    '3',
    'Ġspecial',
    'ization',
    'Ġpassion',
    'ately',
    'ĠVel',
    'ocity',
    'ĠAv',
    'ailability',
    'T',
    'enn',
    'Ġl',
    'atch',
    'ĠSome',
    'body',
    'Ġhel',
    'ium',
    'cl',
    'aw',
    'Ġdi',
    'pping',
    'XX',
    'X',
    'Ġinter',
    'personal',
    '7',
    '10',
    'Ġsub',
    'ter',
    'Ġbi',
    'ologists',
    'ĠLight',
    'ing',
    'Ġopt',
    'ic',
    'Ġden',
    'im',
    'end',
    'on',
    'ĠC',
    'orm',
    'Ġ3',
    '41',
    'ĠC',
    'oup',
    'Ġfear',
    'less',
    'Ġal',
    'ot',
    'ĠCliff',
    'ord',
    'ĠRun',
    'time',
    'ĠProv',
    'ision',
    'up',
    'dated',
    'lene',
    'ck',
    'Ġneur',
    'on',
    'Ġgrad',
    'ing',
    'ĠC',
    't',
    'sequ',
    'ence',
    'in',
    'ia',
    'con',
    'cept',
    'Ġro',
    'aring',
    'ri',
    'val',
    'ĠCaucas',
    'ian',
    'Ġmon',
    'og',
    'key',
    'es',
    'Ġappell',
    'ate',
    'Ġlia',
    'ison',
    'EStream',
    'Frame',
    'ĠPl',
    'um',
    '!',
    '.',
    'Ġsp',
    'herical',
    'Ġper',
    'ished',
    'Ġbl',
    'ot',
    'Ġben',
    'ches',
    'Ġ4',
    '11',
    'Ġpione',
    'ered',
    'Ġhur',
    'led',
    'Jenn',
    'ifer',
    'ĠYose',
    'mite',
    'Ch',
    'air',
    'Ġreef',
    's',
    'Ġelect',
    'or',
    'ĠAnt',
    'hem',
    '65',
    '2',
    'Ġun',
    'install',
    'Ġimp',
    'ede',
    'Ġbl',
    'inking',
    'Ġgot',
    'o',
    'Dec',
    're',
    'A',
    'ren',
    'Ġstabil',
    'ization',
    'ĠDis',
    'abled',
    'ĠYanuk',
    'ovych',
    'Ġoutlaw',
    'ed',
    'ĠVent',
    'ura',
    'ten',
    'ess',
    'Ġplant',
    'ation',
    'Ġy',
    'acht',
    'ĠHu',
    'awei',
    'Ġsol',
    'vent',
    'Ġgr',
    'acious',
    'Ġcur',
    'iously',
    'Ġcapac',
    'itor',
    'Ġc',
    'x',
    'ĠRef',
    'lex',
    'Ph',
    'ys',
    'ĠC',
    'f',
    'pt',
    'in',
    'cons',
    'ervative',
    'Ġinv',
    'ocation',
    'c',
    'our',
    'F',
    'N',
    'ĠNew',
    'ly',
    'H',
    'our',
    'As',
    'ian',
    'ĠLe',
    'ading',
    'ĠAer',
    'ospace',
    'An',
    'ne',
    'Ġpre',
    'natal',
    'Ġdeterior',
    'ating',
    'H',
    'CR',
    'ĠNorm',
    'andy',
    'ol',
    'ini',
    'ĠAm',
    'bro',
    '9',
    '10',
    'Ġset',
    'backs',
    'ĠT',
    'RE',
    'Ġs',
    'ig',
    'ĠSc',
    'ourge',
    '59',
    '7',
    '79',
    '8',
    'Game',
    'play',
    'Ġm',
    'sec',
    'M',
    'X',
    'Ġprice',
    'y',
    'ĠL',
    'LP',
    'aker',
    'u',
    'Ġover',
    'arching',
    'ĠB',
    'ale',
    'Ġworld',
    'ly',
    'Cl',
    'ark',
    'Ġscen',
    'ic',
    'Ġdisl',
    'iked',
    'ĠCont',
    'rolled',
    'T',
    'ickets',
    'ĠE',
    'W',
    'ab',
    'ies',
    'ĠPl',
    'enty',
    'Non',
    'etheless',
    'Ġart',
    'isan',
    'Trans',
    'fer',
    'ĠF',
    'amous',
    'Ġinf',
    'ield',
    'ble',
    'y',
    'Ġunres',
    'olved',
    'ĠML',
    'A',
    'ãĤ',
    'Ĥ',
    'Cor',
    'rection',
    'Ġdemocr',
    'at',
    'ĠMore',
    'no',
    'ro',
    'cal',
    'il',
    'ings',
    'Ġsail',
    'or',
    'Ġr',
    'ife',
    'h',
    'ung',
    'Ġtrop',
    'es',
    'Ġsn',
    'atched',
    'ĠL',
    'IN',
    'ĠB',
    'ib',
    'ES',
    'A',
    'ĠPre',
    'v',
    'ĠCam',
    'el',
    'run',
    'time',
    'Ġob',
    'noxious',
    '4',
    '37',
    'Ġsum',
    'mers',
    'Ġunexpl',
    'ained',
    'ĠWal',
    'ters',
    'cal',
    'iber',
    'Ġg',
    'ull',
    'ĠEnd',
    'urance',
    'ä½',
    'ľ',
    'Ġ3',
    '47',
    'Ir',
    'ish',
    'Ġaer',
    'obic',
    'Ġcr',
    'amped',
    'ĠHon',
    'olulu',
    'à',
    '©',
    'us',
    'erc',
    'ec',
    'ast',
    'AC',
    'Y',
    'ĠQu',
    'ery',
    'ãĤ¹',
    'ãĥĪ',
    'Bet',
    'a',
    'Ġsuscept',
    'ibility',
    'ĠSh',
    'iv',
    'ĠLim',
    'baugh',
    'ĠÃ',
    'ĸ',
    'ĠN',
    'XT',
    'ĠM',
    'uss',
    'ĠBrit',
    'ons',
    'ES',
    'CO',
    'EG',
    'IN',
    'Ġ%',
    '%',
    'Ġsec',
    'ession',
    'ĠPat',
    'ron',
    'ĠLu',
    'a',
    'n',
    'aires',
    'ĠJPM',
    'organ',
    'us',
    'b',
    'ocy',
    'te',
    'Ġcouncill',
    'ors',
    'ĠLi',
    'ang',
    'f',
    'arm',
    'Ġnerv',
    'ously',
    'Ġattract',
    'iveness',
    'ĠK',
    'ov',
    'j',
    'ump',
    'Pl',
    'ot',
    'Ġst',
    'ains',
    'ĠStat',
    'ue',
    'ĠApost',
    'les',
    'he',
    'ter',
    'ĠSUP',
    'PORT',
    'Ġoverwhel',
    'm',
    'Y',
    'ES',
    'Ġ29',
    '1',
    'd',
    'ensity',
    'Ġtra',
    'pping',
    'M',
    'it',
    'Ġf',
    'ide',
    'ĠPam',
    'ela',
    'atl',
    'antic',
    'Dam',
    'n',
    'Ġp',
    'ts',
    'OP',
    'A',
    'Ġserv',
    'icing',
    'Ġoverfl',
    'owing',
    'ul',
    'o',
    'ĠE',
    'rit',
    't',
    'icket',
    'light',
    'ing',
    'ĠH',
    'mm',
    'ãĥ¼',
    'ãĥ«',
    'im',
    'oto',
    'Ġchuck',
    'le',
    '4',
    '23',
    'ãģ',
    'ķ',
    'sh',
    'ape',
    'Ġque',
    'ues',
    'Ġanch',
    'ors',
    'ãĤ¼',
    'ãĤ¦ãĤ¹',
    'F',
    'er',
    'Ġaw',
    'oke',
    'Ġ6',
    '66',
    'h',
    'ands',
    'Ġdiver',
    'gence',
    'Ġ50',
    '5',
    'T',
    'ips',
    'Ġdep',
    'ot',
    'Ġske',
    'w',
    'ĠDel',
    'iver',
    'op',
    'ot',
    'Ġdiv',
    'ul',
    'ĠE',
    'B',
    'uns',
    'igned',
    'ĠUn',
    'i',
    'X',
    'box',
    'Ġfor',
    'ks',
    'Ġ7',
    '02',
    'å',
    '¯',
    'Ġpromot',
    'ers',
    'ĠV',
    'apor',
    'Ġlev',
    'ied',
    'sl',
    'ot',
    'Ġpig',
    'ment',
    'Ġcyl',
    'inders',
    'C',
    'RE',
    'Ġsn',
    'atch',
    'Ġperpet',
    'ually',
    'Ġl',
    'icking',
    'ĠFe',
    'et',
    'ĠKra',
    'ken',
    'ĠHold',
    'en',
    'ĠCLS',
    'ID',
    'm',
    'r',
    'Ġproject',
    'or',
    'Ġden',
    'otes',
    'Ġchap',
    'el',
    'ĠTor',
    'rent',
    'b',
    'ler',
    'R',
    'oute',
    'ĠDef',
    'endant',
    'ĠPublisher',
    's',
    'ĠM',
    'ales',
    'ĠInn',
    'ov',
    'ĠAg',
    'ility',
    'rit',
    'er',
    'ty',
    'mology',
    'st',
    'ores',
    'L',
    'ind',
    'Ġf',
    'olly',
    'ĠZur',
    'ich',
    'B',
    'le',
    'Ġnurt',
    'ure',
    'Ġcoast',
    'line',
    'uch',
    'in',
    'D',
    'omin',
    'Ġfri',
    'vol',
    'ĠCons',
    'olid',
    'res',
    'ults',
    'M',
    'J',
    'Ġphyl',
    'ogen',
    'Ġha',
    'uled',
    'ĠW',
    'iley',
    'ĠJess',
    'ie',
    'ĠPrep',
    'are',
    'ĠE',
    'ps',
    'Ġtreasure',
    'r',
    'I',
    'AS',
    'Ġcolon',
    'ists',
    'Ġin',
    'und',
    'ĠWW',
    'F',
    'ĠCon',
    'verted',
    '6',
    '000',
    'out',
    'side',
    'ĠApp',
    'earance',
    'ĠRel',
    'ic',
    'ĠM',
    'ister',
    's',
    'aw',
    'Ġresult',
    'ant',
    'Ġadject',
    'ive',
    'ĠLaure',
    'l',
    'ĠHind',
    'i',
    'b',
    'da',
    'Pe',
    'ace',
    'Ġreb',
    'irth',
    'Ġmembr',
    'anes',
    'Ġforward',
    'ing',
    'Ġcoll',
    'ided',
    'ĠCar',
    'olyn',
    'K',
    'ansas',
    '5',
    '99',
    'ĠSolid',
    'GoldMagikarp',
    'Be',
    'ck',
    'Ġstress',
    'ing',
    'ĠGo',
    'o',
    'ĠCooper',
    'ative',
    'Ġf',
    's',
    'ĠAr',
    'chie',
    'L',
    'iter',
    'ĠK',
    'lopp',
    'J',
    'erry',
    'Ġfoot',
    'wear',
    'War',
    'ren',
    'Ġsc',
    'ree',
    'h',
    'are',
    'Under',
    'standing',
    'P',
    'ed',
    'Ġanth',
    'ology',
    'ĠAnn',
    'ounce',
    'M',
    'ega',
    'Ġflu',
    'ent',
    'Ġbond',
    'age',
    'ĠDisc',
    'ount',
    'il',
    'ial',
    'C',
    'art',
    'ĠNight',
    'mares',
    'Sh',
    'am',
    'ĠB',
    'oll',
    'uss',
    'ie',
    'H',
    'ttp',
    'Atl',
    'anta',
    'Ġun',
    'recogn',
    'ĠB',
    'id',
    'Ġunder',
    'grad',
    'Ġforg',
    'iving',
    'ĠGl',
    'over',
    'AAAA',
    'AAAA',
    '4',
    '45',
    'V',
    'G',
    'pa',
    'io',
    'kill',
    'ers',
    'Ġrespons',
    'ibly',
    'Ġmobil',
    'ize',
    'Ġeffect',
    'ed',
    'ĠL',
    'umin',
    'Ġk',
    'ale',
    'Ġinfring',
    'ing',
    'ann',
    'ounced',
    'Ġf',
    'itt',
    'b',
    'atch',
    'ĠT',
    'ackle',
    'ĠL',
    'ime',
    'ĠAP',
    'P',
    'uke',
    'mia',
    'Ġrub',
    'y',
    'Ġex',
    'oner',
    'ĠCas',
    'ual',
    '0',
    '70',
    'Ġpel',
    'vic',
    'Ġautom',
    'ate',
    'ĠK',
    'ear',
    'ĠCoast',
    'al',
    'Ġcre',
    'ed',
    'Ġbored',
    'om',
    'ĠSt',
    'un',
    'ri',
    'ott',
    'Ĥ',
    'İ',
    'Ġregener',
    'ate',
    'Ġcomed',
    'ians',
    'ĠOP',
    'ER',
    'Sp',
    'ons',
    'id',
    'ium',
    'on',
    'is',
    'L',
    'ocated',
    '05',
    '7',
    'Ġsusp',
    'ense',
    'ĠD',
    'ating',
    'C',
    'ass',
    'Ġneoc',
    'ons',
    'ĠShin',
    'zo',
    'Ġaw',
    'oken',
    'ch',
    'rist',
    'ĠMess',
    'ages',
    'att',
    'led',
    'ĠSpr',
    'ay',
    'ĠSp',
    'ice',
    'C',
    'W',
    'Ġshield',
    'ing',
    'ĠG',
    'aul',
    'Am',
    'id',
    'Ġparam',
    'ilitary',
    'Ġmult',
    'if',
    'ĠTan',
    'ner',
    'il',
    'k',
    'Ġgodd',
    'amn',
    'g',
    'ements',
    'Ġbe',
    'friend',
    'm',
    'obi',
    'Ġ3',
    '88',
    'fold',
    'er',
    'acc',
    'a',
    'Ġins',
    'in',
    'g',
    'ap',
    'N',
    'ev',
    'fif',
    'th',
    'Ġpsychiat',
    'ry',
    'b',
    'anks',
    'TH',
    'IS',
    'Ġhar',
    'b',
    'ac',
    'qu',
    'Ġfac',
    'ade',
    'ĠPower',
    'Point',
    '80',
    '3',
    'Ġbl',
    'uff',
    'Sh',
    'ares',
    'Ġfavor',
    'ing',
    'El',
    'izabeth',
    'Ãį',
    'Ãį',
    'Ġr',
    'anger',
    '77',
    '2',
    'ĠAr',
    'che',
    'h',
    'ak',
    'ĠGen',
    'etics',
    'ĠF',
    'EMA',
    'Ġev',
    'olves',
    'Ġest',
    'e',
    'ĠP',
    'ets',
    'ĠM',
    'Ã©',
    'ĠInterest',
    'ing',
    'ĠCanter',
    'bury',
    'ch',
    'apter',
    'ĠStar',
    'fleet',
    'Sp',
    'anish',
    'Ġdraw',
    'back',
    'ĠNor',
    'wich',
    '9',
    '70',
    'n',
    'orth',
    'ag',
    'anda',
    'Ġtransform',
    'ative',
    'ram',
    'ids',
    'bi',
    'ology',
    'ad',
    'ay',
    'Ġpropag',
    'ation',
    'ĠGam',
    'ma',
    'ĠDen',
    'ise',
    'ĠCalcul',
    'ator',
    'ent',
    'imes',
    'ĠB',
    'ett',
    'Ġapp',
    'endix',
    'ĠHD',
    'D',
    'AK',
    'ING',
    'Ġst',
    'igmat',
    'Ġhol',
    'ster',
    'Ġord',
    'inarily',
    'Ch',
    'ance',
    'ĠCont',
    'rary',
    'Ġad',
    'hesive',
    'Ġgather',
    's',
    '6',
    '12',
    're',
    'au',
    'ony',
    'ms',
    'ew',
    'ays',
    'Ġindu',
    'ces',
    'Ġinterchange',
    'able',
    'se',
    'm',
    'Wh',
    'it',
    'Ġtr',
    'ance',
    'Ġincorpor',
    'ation',
    'ĠExt',
    'ras',
    'Fin',
    'ancial',
    'Ġawkward',
    'ly',
    'ĠStur',
    'geon',
    'ĠH',
    'Y',
    'Norm',
    'ally',
    'ĠEnd',
    'ing',
    'ĠAss',
    'ist',
    'enc',
    'rypted',
    'Ġsub',
    'jug',
    'Ġn',
    'os',
    'Ġfan',
    'atic',
    'C',
    'ub',
    'C',
    'U',
    '?"',
    '.',
    'Ġirre',
    'versible',
    'å',
    'Ĥ',
    '03',
    '1',
    'ĠH',
    'AR',
    'sp',
    'read',
    'ul',
    'ia',
    '=',
    '$',
    'Sc',
    'ope',
    'L',
    'ots',
    'Ġlif',
    'estyles',
    'ol',
    'on',
    'Ġf',
    'eds',
    'Ġcongrat',
    'ulate',
    'web',
    'kit',
    'Ġindist',
    'inguishable',
    'ĠSw',
    'ing',
    'Ġcommand',
    'ments',
    'qu',
    'ila',
    'ab',
    'ella',
    'm',
    'ethyl',
    'ann',
    'abin',
    'Ġo',
    'vere',
    'Ġlob',
    'ster',
    'ĠQU',
    'EST',
    'ĠCONT',
    'IN',
    'bern',
    'atorial',
    '::::',
    '::::',
    'ĠTra',
    've',
    'ĠSam',
    'oa',
    'AN',
    'I',
    '75',
    '2',
    'Ð',
    '´',
    'userc',
    'ontent',
    'ĠMod',
    'erate',
    'y',
    'eah',
    'ĠK',
    'itt',
    'Ġwe',
    'e',
    'Ġstuff',
    'ing',
    'ĠInter',
    'vention',
    'ĠD',
    'ign',
    'Ġware',
    'houses',
    'ĠF',
    'iji',
    'Ġpel',
    'lets',
    'Ġtake',
    'away',
    'ĠT',
    'ABLE',
    'ĠClass',
    'ical',
    'col',
    'lection',
    'Ġland',
    'fall',
    'ĠMus',
    'cle',
    'Ġsett',
    'les',
    'ĠAD',
    'V',
    'Ġ3',
    '44',
    'L',
    'aura',
    'Ġf',
    'ared',
    'ĠPart',
    'ial',
    '4',
    '36',
    'oss',
    'ibility',
    'ĠD',
    'aly',
    'ĠT',
    'arant',
    'ĠFu',
    'ji',
    'am',
    'l',
    'c',
    'ence',
    '55',
    '1',
    'ĠProced',
    'ures',
    'ĠO',
    'CD',
    'ĠU',
    'D',
    't',
    'in',
    'Q',
    'UI',
    'ach',
    'o',
    '4',
    '38',
    'Ġgl',
    'itches',
    'Ġenchant',
    'ment',
    'Ġcalcul',
    'ates',
    'IR',
    'O',
    'ĠH',
    'ua',
    'alys',
    'es',
    'ĠL',
    'ift',
    'um',
    'o',
    'Ġle',
    'apt',
    'Ġhypothes',
    'ized',
    'ĠGust',
    'av',
    'it',
    'ans',
    'VERS',
    'ION',
    'æ',
    'ł',
    'Rog',
    'er',
    'Ġr',
    'and',
    'ĠAd',
    'apter',
    'Ġ3',
    '31',
    'ĠPet',
    'ition',
    'k',
    'ies',
    'M',
    'ars',
    'Ġunder',
    'cut',
    'ze',
    'es',
    'ĠLy',
    'ons',
    'ĠDH',
    'CP',
    'Miss',
    'ing',
    'Ġretire',
    'es',
    'Ġins',
    'idious',
    'el',
    'i',
    '>',
    ')',
    '.',
    'ãĢį',
    'Ġfinal',
    'ists',
    'ĠA',
    'ure',
    'Ġacc',
    'user',
    'Ġwas',
    'tes',
    'ĠY',
    's',
    'ĠL',
    'ori',
    'Ġconstitu',
    'encies',
    'Ġsupp',
    'er',
    'Ġmay',
    'hem',
    'or',
    'ange',
    'Ġmis',
    'placed',
    'Ġmanager',
    'ial',
    'Ġex',
    'ce',
    'ĠCL',
    'I',
    'Ġprim',
    'al',
    'ĠL',
    'ent',
    'Cry',
    'stal',
    'h',
    'over',
    'ĠN',
    'TS',
    'end',
    'um',
    'Ġd',
    'w',
    'ĠAl',
    'c',
    'n',
    'ostic',
    'Ġpres',
    'erves',
    'ĠTs',
    'arnaev',
    'Ġtri',
    'pled',
    'rel',
    'ative',
    'Arc',
    'ade',
    'k',
    'illing',
    'ĠW',
    'EEK',
    'ĠH',
    'anna',
    'D',
    'ust',
    'Com',
    'pleted',
    'ģ',
    '«',
    'Ġappro',
    'ves',
    'ĠSur',
    'f',
    'ĠLuther',
    'an',
    'ven',
    'ants',
    'Ġrobber',
    'ies',
    'we',
    'ights',
    'soft',
    'ware',
    'at',
    'ana',
    'ug',
    'al',
    'Ġgrav',
    'y',
    'ĠC',
    'ance',
    'OLOG',
    'Y',
    'ly',
    'ak',
    'Ton',
    'ight',
    'Ġunve',
    'il',
    'Ġ19',
    '04',
    'ĠMin',
    'ion',
    'ent',
    'ious',
    'st',
    'ice',
    'pack',
    'ages',
    'ĠG',
    'EAR',
    'Ġg',
    'ol',
    'ĠHutch',
    'inson',
    'ĠProf',
    'ession',
    'ĠG',
    'UN',
    'ĠDiff',
    'erence',
    'ĠTsuk',
    'uyomi',
    'ĠLes',
    'bian',
    '6',
    '70',
    'Ġfug',
    'itive',
    'ĠPlan',
    'etary',
    '--------------------------------',
    '------------------------',
    'Ġacc',
    'rued',
    'Ġch',
    'icks',
    'Ġsto',
    'pp',
    'Ġblock',
    'ers',
    'C',
    'od',
    'Ġcomment',
    'ers',
    'ĠSomew',
    'here',
    'ĠPhot',
    'ographer',
    'the',
    'me',
    'Ġmay',
    'oral',
    'w',
    'u',
    'Ġanten',
    'nas',
    'Ġrev',
    'amped',
    'ĠSubject',
    's',
    'it',
    'Ã©',
    'im',
    'ura',
    'Ġentr',
    'ances',
    'liter',
    'ally',
    'Ġten',
    'ets',
    'ĠO',
    'MG',
    'ĠMP',
    'H',
    'ĠDon',
    'key',
    'ĠOff',
    'ense',
    'Ġ"',
    '+',
    'Sn',
    'ap',
    'ĠAF',
    'B',
    'Ġan',
    'imate',
    'ĠS',
    'od',
    'His',
    'panic',
    'Ġinconsist',
    'ency',
    'D',
    'b',
    'F',
    'Y',
    'Ex',
    'port',
    'Ġa',
    'pe',
    'Ġpear',
    'l',
    'ib',
    'el',
    'ĠPAC',
    's',
    'Ġ{',
    '\\',
    'Ġact',
    'u',
    'ĠHS',
    'BC',
    'camp',
    'us',
    'Ġpay',
    'off',
    'Ġde',
    'ities',
    'ĠN',
    'ato',
    'ou',
    'ple',
    'Ġcens',
    'ored',
    'ĠCl',
    'ojure',
    'Ġconf',
    'ounding',
    'en',
    'i',
    'Ġreck',
    'on',
    'op',
    'he',
    'Ġspot',
    'ting',
    'Ġsign',
    'ifies',
    'Ġprop',
    'el',
    'Ġfest',
    'ive',
    'S',
    'uggest',
    'Ġpled',
    'ging',
    'ĠB',
    'erman',
    'Ġrebell',
    'ious',
    'Ġovershadow',
    'ed',
    'Ġinfiltr',
    'ated',
    'j',
    'obs',
    '67',
    '2',
    'Ġscal',
    'able',
    'Ġdomin',
    'ion',
    'ĠNew',
    'foundland',
    'ĠMead',
    'ow',
    'Ġpart',
    'itions',
    'AM',
    'I',
    'Ġsupplement',
    'ary',
    'str',
    'ument',
    'Ġhair',
    'y',
    'Ġperpet',
    'uate',
    'Ġnuts',
    'hell',
    'ĠPot',
    'ato',
    'ĠHob',
    'bit',
    'Ġcur',
    'ses',
    'Flo',
    'at',
    'Ġquiet',
    'er',
    'Ġfuel',
    'ing',
    'Ġcaps',
    'ules',
    'ĠL',
    'ust',
    'ĠH',
    'aunted',
    'Exec',
    'utive',
    'Ġchild',
    'birth',
    'G',
    're',
    'Ġrad',
    'iant',
    'å',
    'İ',
    'Ġm',
    'alls',
    'Ġin',
    'ept',
    'ĠWarrant',
    'y',
    'Ġspect',
    'ator',
    'E',
    'h',
    't',
    'hens',
    'Ġculmin',
    'ating',
    'æ',
    '©',
    'ary',
    'a',
    'ãĤ',
    '®',
    'ilit',
    'arian',
    'ĠOR',
    'IG',
    'ĠSp',
    'ending',
    'pt',
    'ives',
    'ĠS',
    'iren',
    'ĠRec',
    'ording',
    'ay',
    'ne',
    'Ġv',
    'im',
    'Ġspr',
    'ang',
    'T',
    'ang',
    'ĠM',
    'FT',
    'mor',
    'ning',
    'ĠWe',
    'ed',
    'm',
    'peg',
    'cess',
    'ion',
    'ĠCh',
    'ung',
    '7',
    '30',
    'w',
    'arning',
    '56',
    '2',
    'handed',
    'ly',
    'P',
    'oor',
    'P',
    'olitics',
    ':',
    '#',
    'Ġp',
    'ian',
    'Ġfec',
    'es',
    'ĠDocument',
    'ation',
    'Ġban',
    'ished',
    'Ġ3',
    '99',
    'ĠAR',
    'C',
    'Ġhe',
    'inous',
    'J',
    'ake',
    'ĠAm',
    'ir',
    'way',
    'ne',
    'v',
    're',
    'os',
    'henko',
    'Ġnotebook',
    's',
    'Ġfound',
    'ational',
    'Ġmarvel',
    'ous',
    'ixt',
    'ape',
    'Ġwithdraw',
    'als',
    'Ġh',
    'orde',
    'ĠD',
    'habi',
    'is',
    'able',
    'ĠK',
    'D',
    'Ġcontag',
    'ious',
    'ĠD',
    'ip',
    'ĠAr',
    'rows',
    'Ġpronoun',
    's',
    'Ġmorph',
    'ine',
    'ĠB',
    'US',
    '68',
    '2',
    'Ġk',
    'osher',
    'fin',
    'ished',
    'ĠInstr',
    'uments',
    'Ġf',
    'used',
    'yd',
    'en',
    'ĠSal',
    'mon',
    'F',
    'ab',
    'aff',
    'ected',
    'K',
    'EN',
    'C',
    'ENT',
    'Dom',
    'ain',
    'Ġpoke',
    'mon',
    'ĠDr',
    'inking',
    'G',
    'rowing',
    'ĠInvestig',
    'ative',
    'ĠA',
    'ether',
    'em',
    'i',
    'Ġtabl',
    'oid',
    'Ġrep',
    'ro',
    'ĠNot',
    'withstanding',
    'ĠBers',
    'erker',
    'Ġdram',
    'as',
    'Ġclich',
    'Ã©',
    'Ġb',
    'ung',
    'ĠU',
    'RI',
    'ĠD',
    'os',
    '0',
    '44',
    'Ġpast',
    'ors',
    'Ġl',
    's',
    'Ġac',
    'rylic',
    'aun',
    'ts',
    'Ed',
    'ward',
    'Ġmajor',
    'ities',
    'B',
    'ang',
    'Ġfield',
    'ing',
    'ĠRepl',
    'acement',
    'ĠAl',
    'chemy',
    'pp',
    'ard',
    'ĠRome',
    'o',
    'ĠSan',
    'ct',
    'ĠLav',
    'rov',
    'ib',
    'ble',
    'Inst',
    'ruct',
    'Ġimp',
    'ractical',
    'ĠPlay',
    'boy',
    'ce',
    'phal',
    'Ġsw',
    'aps',
    'Ġk',
    'an',
    'ĠThe',
    'o',
    'Ġillust',
    'rating',
    'Ġdismant',
    'led',
    'ĠTrans',
    'gender',
    'ĠG',
    'uth',
    'UG',
    'H',
    'Ġtriumph',
    'ant',
    'Ġencomp',
    'ass',
    'Ġbook',
    'mark',
    'udd',
    'in',
    'j',
    'er',
    'Ġpred',
    'icate',
    'ES',
    'H',
    'Ġwhen',
    'ce',
    'ĠAB',
    'E',
    'Ġnon',
    'profits',
    'Se',
    'qu',
    'Ġdi',
    'abetic',
    'Ġp',
    'end',
    'Ġheart',
    'felt',
    'sh',
    'i',
    'Ġinter',
    'acts',
    'ĠTele',
    'com',
    'Ġbombard',
    'ment',
    'dep',
    'ending',
    'ĠLow',
    'ry',
    'ĠAd',
    'mission',
    'ĠBl',
    'ooming',
    'ust',
    'ration',
    'ene',
    'gger',
    'B',
    'rew',
    'Ġmol',
    'ten',
    'ĠNer',
    'd',
    'P',
    'IN',
    'âĸ',
    'Ģ',
    'ave',
    'ment',
    'Ġtou',
    'red',
    'Ġco',
    'efficients',
    'ĠTray',
    'von',
    'ans',
    'son',
    'Ġsand',
    'y',
    't',
    'old',
    'fl',
    'ows',
    'Ġpop',
    'ulous',
    'ĠT',
    'inder',
    'ĠBl',
    'iss',
    'R',
    'achel',
    'Min',
    'imum',
    'Ġcontest',
    'ant',
    'ĠRed',
    'uce',
    'ĠMor',
    'se',
    'ĠGrass',
    'ley',
    'ĠClick',
    'er',
    'Ġexp',
    'r',
    'Ġs',
    'incerity',
    'Ġmar',
    'qu',
    'Ġelic',
    'it',
    'ĠPro',
    'position',
    'ĠDemon',
    'ic',
    'Ġtac',
    'os',
    'G',
    'reek',
    'Ġpost',
    'war',
    'Ġin',
    'sofar',
    'ĠP',
    'ork',
    'Ġ35',
    '2',
    'doctor',
    'al',
    'walk',
    'ing',
    'Ġmid',
    'term',
    'ĠSam',
    'my',
    'sight',
    'ed',
    'ĠTR',
    'ANS',
    'ic',
    'i',
    'AL',
    'D',
    'ĠUS',
    'L',
    'ĠF',
    'ISA',
    'ĠAm',
    'pl',
    'ĠAlex',
    'andra',
    'ine',
    'lli',
    'Tr',
    'ain',
    'Ġsign',
    'ify',
    'ĠVers',
    'us',
    'Ġob',
    'fusc',
    'Ġk',
    'h',
    'Ġagg',
    'ro',
    'ĠRen',
    'ault',
    'Ġ3',
    '48',
    '5',
    '18',
    'ox',
    'icity',
    '0',
    '22',
    'ĠTw',
    'ist',
    'Ġgoof',
    'y',
    'D',
    'ynamic',
    'Ġbrief',
    'ings',
    'm',
    'ight',
    '8',
    '99',
    'Ġderog',
    'atory',
    'T',
    'ro',
    'Ġfor',
    'ging',
    'ĠKor',
    'an',
    'ĠMar',
    'ried',
    'ĠBuc',
    's',
    'Ġpal',
    'ate',
    'ĠCon',
    'version',
    'm',
    'able',
    '4',
    '13',
    'Ġ(',
    '_',
    'Ġs',
    'iph',
    'ĠN',
    'EO',
    'col',
    'lege',
    'Ġmarg',
    'inally',
    'Ġfl',
    'irt',
    'ĠTra',
    'ps',
    'ĠP',
    'ace',
    'é',
    '»Ĵ',
    'Ġgoalt',
    'ender',
    'Ġforb',
    'ids',
    'Ġcler',
    'ks',
    'ĠT',
    'ant',
    'ĠRobb',
    'ins',
    'ĠPrint',
    'ing',
    'Ġpremie',
    'red',
    'Ġmagn',
    'ification',
    'ĠT',
    'G',
    'ĠR',
    'ouse',
    'ĠM',
    'ock',
    'odynam',
    'ics',
    'Ġpre',
    'clude',
    'ism',
    'o',
    'ĠPul',
    'itzer',
    'Ġaval',
    'anche',
    'ĠK',
    'odi',
    'rib',
    'une',
    'ĠL',
    'ena',
    'Elect',
    'ric',
    'Ġref',
    'inery',
    'Ġend',
    'owed',
    'Ġcounsel',
    'ors',
    'Ġd',
    'olphin',
    'ĠM',
    'ith',
    'Ġarm',
    'oured',
    'hib',
    'ited',
    'Beg',
    'in',
    'ĠP',
    'W',
    'O',
    'il',
    'ĠV',
    'or',
    'ĠShar',
    'if',
    'ĠFraz',
    'ier',
    'est',
    'ate',
    'Ġj',
    'ams',
    'Pro',
    'xy',
    'Ġband',
    'its',
    'ĠPresbyter',
    'ian',
    'ĠPrem',
    'iere',
    't',
    'iny',
    'ĠCru',
    'el',
    'Test',
    'ing',
    'Ġhom',
    'er',
    'ĠV',
    'ERS',
    'ĠPro',
    'l',
    'ĠDep',
    'osit',
    'ĠCoff',
    'in',
    'Ġsemin',
    'ars',
    'Ġs',
    'ql',
    'ĠDef',
    'endants',
    'Altern',
    'atively',
    'ĠR',
    'ats',
    'ç',
    '«',
    'ethy',
    'st',
    "'",
    '>',
    'Ġiss',
    'uer',
    '58',
    '9',
    'Ġch',
    'aired',
    'ĠAccess',
    'ories',
    'man',
    'ent',
    'Ġmar',
    'row',
    'ĠPrim',
    'ordial',
    'C',
    'N',
    'Ġlimit',
    'less',
    'ĠCarn',
    'age',
    'Ġund',
    'rafted',
    'q',
    'v',
    'IN',
    'ESS',
    'on',
    'ew',
    'Ġco',
    'hesion',
    '98',
    '7',
    'Ġne',
    'cks',
    'Ġfootball',
    'er',
    'ĠG',
    'ER',
    'Ġdetect',
    'able',
    'ĠSupport',
    'ing',
    'ĠCS',
    'V',
    'oc',
    'ally',
    'k',
    'Hz',
    'Ġund',
    'e',
    'Ġsh',
    'one',
    'Ġbud',
    'ding',
    'tra',
    'k',
    'Stand',
    'ing',
    'ĠStar',
    'craft',
    'ĠKem',
    'p',
    'Ben',
    'ch',
    'Ġthw',
    'arted',
    'ĠGround',
    's',
    'ath',
    'i',
    'L',
    'isa',
    'Dial',
    'og',
    'ĠS',
    'X',
    'V',
    'ision',
    'Ġingen',
    'ious',
    'Ù',
    'Ĳ',
    'Ġfost',
    'ering',
    'ĠZ',
    'a',
    'ĠIn',
    'gram',
    'Ġ"',
    '@',
    'N',
    'aturally',
    '6',
    '16',
    '0',
    '35',
    'ĠF',
    'AC',
    'H',
    'mm',
    '55',
    '4',
    'Ġacceler',
    'ator',
    'ĠV',
    'end',
    'Ġsun',
    'screen',
    'Ġtuber',
    'culosis',
    'rav',
    'iolet',
    'ĠFunction',
    'al',
    'ĠEr',
    'rors',
    'ed',
    'ar',
    '19',
    '66',
    'ĠSpect',
    're',
    'ĠRec',
    'ipes',
    '88',
    '5',
    'ĠM',
    'ankind',
    'L',
    'iverpool',
    'Ġ|',
    '--',
    'Ġsubst',
    'itutes',
    'ĠX',
    'T',
    'w',
    'ired',
    'Ġinc',
    'o',
    'ĠAf',
    'gh',
    'E',
    'va',
    'ic',
    'c',
    'S',
    'ong',
    'K',
    'night',
    'Ġdilig',
    'ently',
    'ĠBroad',
    'cast',
    'A',
    'id',
    'Ġaf',
    'ar',
    'ĠH',
    'MS',
    'aton',
    'in',
    'ĠGr',
    'ateful',
    'Ġfire',
    'place',
    'ĠOm',
    'ni',
    'e',
    'uro',
    'ĠF',
    'RE',
    'ĠSh',
    'ib',
    'ĠDig',
    'est',
    't',
    'oggle',
    'Ġheads',
    'ets',
    'Ġdiff',
    'usion',
    'ĠSqu',
    'irrel',
    'ĠF',
    'N',
    'Ġdark',
    'ened',
    'out',
    'her',
    'Ġsleep',
    's',
    'ĠX',
    'er',
    'gun',
    's',
    'Ġset',
    'ups',
    'Ġpars',
    'ed',
    'Ġmamm',
    'oth',
    'ĠCur',
    'ious',
    'g',
    'ob',
    'ĠFitz',
    'patrick',
    'ĠEm',
    'il',
    'im',
    'ov',
    '........',
    '.....',
    'ĠB',
    'enny',
    'Second',
    'ly',
    'Ġheart',
    'y',
    'Ġcons',
    'on',
    'st',
    'ained',
    'Ġgal',
    'actic',
    'cl',
    'ave',
    'Ġplummet',
    'ed',
    'Ġp',
    'ests',
    'Ġsw',
    'at',
    'Ġrefer',
    'rals',
    'ĠLion',
    'el',
    'h',
    'oly',
    'Ġunder',
    'dog',
    'ĠSl',
    'ater',
    'ĠProv',
    'ide',
    'ĠAm',
    'ar',
    'ress',
    'or',
    'å',
    'Į',
    'ong',
    'a',
    'Ġtim',
    'id',
    'Ġp',
    'iety',
    'ĠD',
    'ek',
    'Ġsur',
    'ging',
    'az',
    'o',
    'Ġ6',
    '10',
    'Ġdes',
    'ks',
    'ĠSp',
    'okane',
    'ĠAn',
    'field',
    'Ġwars',
    'hips',
    'ĠCob',
    'ra',
    'Ġar',
    'ming',
    'clus',
    'ively',
    'ĠBad',
    'ge',
    'ag',
    'ascar',
    'ĠPR',
    'ESS',
    'ĠMcK',
    'enzie',
    'ĠFer',
    'dinand',
    'burn',
    'ing',
    'Af',
    'ee',
    'Ġtyr',
    'ann',
    'ĠI',
    'w',
    'ĠBo',
    'one',
    '100',
    '7',
    'ĠRe',
    'pt',
    'Ċ',
    'Âł',
    'Ġcar',
    'avan',
    'ĠD',
    'ill',
    'ĠBundes',
    'liga',
    'Ch',
    'uck',
    'Ġheal',
    'er',
    'ãĥ¼ãĥ',
    'Ĩ',
    'ĠH',
    'obby',
    'Ġneg',
    'ate',
    'Ġcrit',
    'iques',
    'section',
    'al',
    'mop',
    'olitan',
    'Ġd',
    'x',
    'Ġouts',
    'ourcing',
    'ĠC',
    'ipher',
    't',
    'ap',
    'Sh',
    'arp',
    'Ġup',
    'beat',
    'Ġhang',
    'ar',
    'Ġcru',
    'ising',
    'ĠNi',
    'agara',
    'Ġ3',
    '42',
    'ill',
    'us',
    'ĠS',
    'v',
    'Ġsubt',
    'itles',
    'Ġsqu',
    'ared',
    'Ġbook',
    'store',
    'Ġrevolution',
    'aries',
    'ĠCarl',
    'ton',
    'ab',
    'al',
    'Ut',
    'ah',
    'Ġdesp',
    'ise',
    'ĠU',
    'M',
    'cons',
    'ider',
    'aid',
    'o',
    'Ġc',
    'arts',
    'ĠT',
    'urtles',
    'Tr',
    'aining',
    'Ġhonor',
    'ary',
    'Â',
    '¢',
    'Ġtri',
    'angles',
    '4',
    '22',
    'Ġreprint',
    'ed',
    'Ġgrace',
    'ful',
    'ĠMong',
    'olia',
    'Ġdisrupt',
    'ions',
    'ĠB',
    'oh',
    'Ġ3',
    '49',
    'Ġdr',
    'ains',
    'Ġcons',
    'ulate',
    'Ġb',
    'ends',
    'Ġm',
    'afia',
    'ur',
    'on',
    'ĠF',
    'ulton',
    'm',
    'isc',
    'Ġren',
    'al',
    'Ġin',
    'action',
    'ck',
    'ing',
    'Ġphot',
    'ons',
    'Ġbru',
    'ised',
    'ĠC',
    'odes',
    'og',
    'i',
    'Ġn',
    'ests',
    'ĠLove',
    'ly',
    'ĠLib',
    're',
    'ĠD',
    'aryl',
    'Ġ#',
    '##',
    'S',
    'ys',
    '.',
    ',"',
    'Ġfree',
    'zes',
    'est',
    'ablishment',
    'and',
    'owski',
    'Ġcum',
    'bers',
    'ĠSt',
    'arg',
    'ĠBom',
    'bs',
    'Ġleg',
    'ions',
    'Ġhand',
    'writing',
    'Ġgr',
    'un',
    'ĠC',
    'ah',
    'sequ',
    'ent',
    'Ġm',
    'oth',
    'ĠMS',
    'M',
    'Ins',
    'ert',
    'F',
    'if',
    'Ġmot',
    'el',
    'Ġdex',
    'ter',
    'ĠB',
    'ild',
    'hearted',
    'ly',
    'Ġpro',
    'pe',
    'ĠText',
    'ure',
    'ĠJ',
    'unction',
    'ynt',
    'hesis',
    'oc',
    'ard',
    'ĠVer',
    'a',
    'ĠBar',
    'th',
    'ĠÎ¼',
    'g',
    'Ġl',
    'ashed',
    'Ġ35',
    '1',
    'ĠZ',
    'amb',
    'ĠSt',
    'aples',
    'ĠCort',
    'ex',
    'ĠCork',
    'er',
    'Ġcontinu',
    'um',
    'ĠWR',
    'ITE',
    'unt',
    'a',
    'rid',
    'or',
    'Ġde',
    'ems',
    '0',
    '33',
    'ĠG',
    'OLD',
    'p',
    'as',
    'Ġrep',
    'ressive',
    'ãĥĨ',
    'ãĤ£',
    'Ġbaff',
    'led',
    'Sc',
    'ar',
    'Ġc',
    'rave',
    'Ġ',
    '______',
    'Ġentrepreneurs',
    'hip',
    'ĠDirector',
    'ate',
    "Ġ'",
    '[',
    'Ġv',
    'ines',
    'Ġasc',
    'ended',
    'ĠGR',
    'OUP',
    'ĠGood',
    'bye',
    'Ġdo',
    'gged',
    'ãĥ´',
    'ãĤ¡',
    'Man',
    'ufact',
    'Ġunimagin',
    'able',
    'ri',
    'ots',
    'ier',
    'rez',
    'Ġrel',
    'ativity',
    'ĠCraft',
    'ing',
    'ra',
    'ught',
    'ud',
    'en',
    'c',
    'ookie',
    'Ġassass',
    'ins',
    'Ġdissatisf',
    'ied',
    'ac',
    'ci',
    'Ġcondu',
    'it',
    'Sp',
    'read',
    'ĠR',
    'ican',
    'n',
    'ice',
    'izz',
    'le',
    'Ġsc',
    'ares',
    'ĠWH',
    'Y',
    'ph',
    'ans',
    '5',
    '35',
    'Ġprot',
    'racted',
    'ĠKrist',
    'en',
    '5',
    '36',
    'ĠSc',
    'rib',
    'ĠNe',
    'h',
    'Ġtwent',
    'ies',
    'Ġpredic',
    'ament',
    'Ġhandc',
    'uffs',
    'Ġfruit',
    'ful',
    'ĠU',
    'L',
    'ĠLud',
    'wig',
    'Ġatt',
    'est',
    'ĠBre',
    'aker',
    'Ġbi',
    'ologically',
    'ĠDeal',
    'er',
    'Ġrenov',
    'ations',
    'f',
    'w',
    'ess',
    'en',
    'Al',
    'ice',
    'ĠHen',
    'ri',
    'Ġun',
    'ilaterally',
    'ĠS',
    'idd',
    'h',
    'ai',
    'ĠSt',
    'retch',
    'S',
    'ales',
    'Ġcumbers',
    'ome',
    'ĠJ',
    'avier',
    'Ġtrend',
    'y',
    'Ġrot',
    'ting',
    'ĠChall',
    'enges',
    'Ġscra',
    'ps',
    'Ġfac',
    'ets',
    'ĠVer',
    'onica',
    'ĠVer',
    'ge',
    'ĠS',
    'ana',
    'Al',
    'ien',
    'ĠR',
    'ih',
    'Ġrad',
    'ial',
    'ect',
    'ar',
    'Ġ6',
    '30',
    'cl',
    'i',
    'Mar',
    'ie',
    'Ġwild',
    'fire',
    'ĠCat',
    'o',
    'h',
    'ander',
    'Ġwait',
    'ress',
    'Ġch',
    'ops',
    'ĠS',
    'ECTION',
    'Ġblunt',
    'ly',
    'ĠCat',
    'alog',
    'n',
    'ian',
    'stud',
    'y',
    'Ġpat',
    'rolling',
    'ĠT',
    'enth',
    'nex',
    'us',
    'ĠN',
    'ON',
    'op',
    'sy',
    'Ġsc',
    'athing',
    's',
    'ie',
    'Ġdeterior',
    'ated',
    'V',
    'B',
    'Naz',
    'is',
    'Ġdep',
    'ictions',
    'Ġauthent',
    'icated',
    'ĠCon',
    'ce',
    'k',
    'rit',
    'Ġpromul',
    'g',
    'ĠL',
    'ONG',
    'U',
    'FC',
    'ĠVis',
    'itors',
    'ĠRec',
    'all',
    'Ġrehab',
    'ilit',
    'ĠSL',
    'I',
    'Ġglac',
    'ier',
    'ĠB',
    'ite',
    'Ġ50',
    '3',
    'Ġvom',
    'it',
    'Ġfer',
    'mented',
    'ĠKh',
    'alid',
    'Ġgrad',
    'ed',
    'ĠMag',
    'icka',
    'ĠIch',
    'igo',
    'power',
    'ful',
    'ic',
    'ators',
    '75',
    '3',
    'Ġsh',
    'rew',
    'Ġ35',
    '6',
    'Ġlegal',
    'izing',
    'Ġall',
    'otted',
    'ĠArch',
    'demon',
    'ith',
    'ing',
    'igg',
    'urat',
    'V',
    'OL',
    'Le',
    'od',
    'Ġo',
    'ily',
    'Ġindu',
    'cing',
    'Ġamy',
    'gdala',
    'Ġadm',
    'ins',
    'ĠAcqu',
    'isition',
    'C',
    'AN',
    'Ġsche',
    'matic',
    'Ġmo',
    'an',
    'ĠCamer',
    'oon',
    'Ġt',
    'ink',
    'Ġmer',
    'ry',
    'Ġbutter',
    'flies',
    'ĠGo',
    'ff',
    'Ġworks',
    'pace',
    'ĠCor',
    'ona',
    'Ġj',
    'avascript',
    'ĠD',
    'olphin',
    'ĠCant',
    'or',
    '4',
    '64',
    'to',
    'e',
    'AP',
    'S',
    'ĠAg',
    'ing',
    'Ġpadd',
    'ed',
    'ĠZ',
    'heng',
    'ĠHe',
    'ld',
    'Ġest',
    'ranged',
    'Ġ7',
    '70',
    '.',
    '}',
    'ĠDun',
    'ham',
    'Ġsm',
    'okes',
    'Ġcap',
    'itals',
    'und',
    'ai',
    'Sh',
    'in',
    'ĠFound',
    'ing',
    'Ġent',
    'itle',
    'Ġcenter',
    'piece',
    'D',
    'iscover',
    'Ġthere',
    'to',
    'al',
    'ert',
    'ĠN',
    'ou',
    'ĠAnaly',
    'st',
    'l',
    'c',
    'F',
    'H',
    'FI',
    'ELD',
    'ĠP',
    'OV',
    'gr',
    'ay',
    'Ġar',
    'cs',
    'ĠH',
    'OT',
    'Ġr',
    's',
    'Ġoblig',
    'atory',
    'ĠArchitect',
    's',
    'ĠS',
    'ven',
    'ĠF',
    'EC',
    '0',
    '200',
    'Christ',
    'mas',
    'ĠAlban',
    'ia',
    'rat',
    'om',
    '58',
    '7',
    'Ġhard',
    'ships',
    'Ġaut',
    'os',
    'ĠCharg',
    'es',
    'Ġap',
    'es',
    'Ġ3',
    '76',
    'wal',
    'let',
    'Ġintox',
    'ication',
    'Ġgobl',
    'in',
    'Ġ5',
    '70',
    '++++++++',
    '++++++++',
    'ĠYel',
    'p',
    'ĠMag',
    'netic',
    'ĠBr',
    'iggs',
    'R',
    'ail',
    'Ġspawn',
    's',
    'ĠW',
    'iggins',
    'Ġshowc',
    'ased',
    'Ġres',
    'orted',
    'ub',
    'en',
    'Ġwh',
    'ipping',
    'Ġim',
    'itate',
    'Ġdigest',
    'ion',
    'ĠUS',
    'PS',
    'ĠG',
    'est',
    'Ġye',
    'a',
    'ĠT',
    'ight',
    'ind',
    'al',
    'ic',
    'as',
    '`',
    '.',
    'C',
    'AST',
    "''",
    ';',
    'ĠF',
    'et',
    'opath',
    'ic',
    'In',
    'valid',
    'Ġregrett',
    'ed',
    'Ġbro',
    'ccoli',
    'ĠSc',
    'ores',
    'e',
    've',
    'Ġpost',
    'ings',
    'Ġaccum',
    'ulating',
    'Ġneed',
    'less',
    'elf',
    'th',
    'Ġmay',
    'ors',
    'Ġsc',
    'rib',
    'Ġanecd',
    'otes',
    'Ġbot',
    'ched',
    'ĠRib',
    'bon',
    'ĠConstant',
    'ine',
    'i',
    'uses',
    'ess',
    'es',
    'Ġdev',
    'ise',
    'Comp',
    'ared',
    'Ġp',
    'udding',
    'Ġg',
    'arg',
    'Ġev',
    'oke',
    '79',
    '7',
    'Ġdet',
    'ox',
    '9',
    '09',
    'ĠPie',
    'ces',
    'ĠMcC',
    'artney',
    'Ġmet',
    'ast',
    'ĠK',
    'rypt',
    'P',
    'OR',
    'Ġt',
    'ending',
    'ĠMerch',
    'ants',
    'Pro',
    'of',
    'ĠV',
    'arg',
    'ĠPort',
    'able',
    'ãĥ¼ãĥĨ',
    'ãĤ£',
    'B',
    'rain',
    '25',
    '00',
    'Ġfol',
    'iage',
    'Ø',
    '¹',
    'Ġment',
    'ors',
    'ĠA',
    'ires',
    'Ġminimal',
    'ist',
    'Ġing',
    'ested',
    'ĠTro',
    'jan',
    'ĠQ',
    'ian',
    'inv',
    'olved',
    '0',
    '27',
    'Ġer',
    'oded',
    'RA',
    'FT',
    'Ġbl',
    'urry',
    'M',
    'ob',
    'Ġbuff',
    'et',
    'ĠFn',
    'atic',
    'ae',
    'a',
    'KN',
    'OWN',
    'ĠIn',
    'it',
    's',
    'afety',
    'en',
    'um',
    'ACT',
    'ION',
    'ĠCrus',
    'her',
    'ĠD',
    'ates',
    'Ġ',
    '................',
    'c',
    'alling',
    'ak',
    'ov',
    'Ġvent',
    'ured',
    'Ġ5',
    '55',
    'au',
    'ga',
    'H',
    'art',
    'ĠA',
    'ero',
    'M',
    'AC',
    'Ġthin',
    'ly',
    'Ġar',
    'ra',
    'ST',
    'ATE',
    'ild',
    'e',
    'ĠJac',
    'qu',
    'ĠFem',
    'ales',
    'Ġthe',
    'orem',
    'Ġ3',
    '46',
    'Ġsmart',
    'est',
    'ĠPU',
    'BLIC',
    'ĠK',
    'ron',
    'ĠB',
    'its',
    'ĠV',
    'essel',
    'ĠTele',
    'phone',
    'Ġdec',
    'ap',
    'Ġadj',
    'unct',
    'ĠS',
    'EN',
    'mer',
    'ga',
    'Ġred',
    'acted',
    'Ġpre',
    'historic',
    'Ġexplan',
    'atory',
    'ĠRun',
    's',
    'ĠUtt',
    'ar',
    'ĠM',
    'anny',
    'ĠAUTH',
    'OR',
    'ĠUnle',
    'ashed',
    'ĠBow',
    'ling',
    'be',
    'ans',
    '79',
    '3',
    'Ġunivers',
    'es',
    'Ġsens',
    'it',
    'ĠK',
    'ung',
    're',
    'peat',
    'ctr',
    'l',
    'Ġp',
    'aced',
    'Ġfull',
    'er',
    'Cl',
    'ock',
    'Ġrec',
    'omb',
    'ĠF',
    'aul',
    'ĠB',
    'unker',
    'Ġpool',
    'ed',
    'Ġan',
    'a',
    'ĠM',
    'outh',
    'LL',
    'OW',
    'hum',
    'ane',
    'Ġbull',
    'do',
    'ĠMicha',
    'els',
    'f',
    'am',
    'Ġwreck',
    'ed',
    'Ġport',
    'rays',
    'ĠWh',
    'ale',
    'ĠH',
    'es',
    'Ġguess',
    'es',
    'ĠBrow',
    'se',
    'ĠL',
    'APD',
    'Ġconsequ',
    'ential',
    'ĠInn',
    'ocent',
    'ĠD',
    'RAG',
    'Ġtrans',
    'gress',
    'ĠO',
    'aks',
    'Ġtri',
    'via',
    'ĠRes',
    'on',
    'ĠA',
    'DS',
    '--',
    '+',
    'ĠT',
    'oll',
    'Ġgrasp',
    'ing',
    'ĠTHE',
    'M',
    'ĠT',
    'ags',
    'ĠCon',
    'clusion',
    'Ġpract',
    'icable',
    'Ġho',
    'op',
    'Ġunintention',
    'ally',
    'Ġign',
    'ite',
    'ĠM',
    'ov',
    'ur',
    'ized',
    'le',
    'hem',
    'Ter',
    'min',
    'Ġcolour',
    'ful',
    'ĠLin',
    'ear',
    'ĠEll',
    'ie',
    'G',
    'y',
    'Ġman',
    'power',
    'Ġj',
    's',
    'Ġem',
    'oji',
    'ĠSHAR',
    'ES',
    '_',
    '.',
    '0000',
    '7',
    'Ġsophistic',
    'ation',
    'Ġunders',
    'core',
    'Ġpract',
    'ise',
    'Ġbl',
    'ob',
    'op',
    'ens',
    'Uk',
    'raine',
    'Ke',
    'eping',
    'Y',
    'C',
    'J',
    'R',
    'ult',
    'imate',
    'Cl',
    'aim',
    'Ġautom',
    'obiles',
    '99',
    '3',
    'ste',
    'el',
    'Ġpart',
    'ing',
    'ĠL',
    'ank',
    '...',
    '?',
    'Ġ38',
    '5',
    'Ġremem',
    'brance',
    'Ġe',
    'ased',
    'Ġcov',
    'ari',
    'ĠS',
    'ind',
    'Effect',
    'ive',
    'Ġdisse',
    'mination',
    'ĠMo',
    'ose',
    'ĠCl',
    'apper',
    'br',
    'ates',
    'App',
    'ly',
    'Ġinv',
    'is',
    'Ġwors',
    'ened',
    'âĢĶ',
    '-',
    'Ġlegisl',
    'ator',
    'ĠL',
    'ol',
    'ĠRow',
    'e',
    'Ġdealers',
    'hip',
    'um',
    'ar',
    'id',
    'ences',
    'Ġinvestig',
    'ates',
    'Ġc',
    'ascade',
    'Ġbid',
    'der',
    'ĠB',
    'EN',
    'Iron',
    'ically',
    'Ġpres',
    'iding',
    'Ġd',
    'ing',
    'Ġcontrad',
    'icted',
    'Ġshut',
    's',
    'ĠF',
    'IX',
    'Ġ3',
    '66',
    'Dist',
    'rict',
    'Ġsin',
    'ful',
    'ĠChar',
    'isma',
    'o',
    'ops',
    'Ġtot',
    'ality',
    'Ġrest',
    'itution',
    'ĠOpt',
    'imus',
    'ĠD',
    'ah',
    'Ġcl',
    'ueless',
    'urn',
    'ed',
    'Ġnut',
    'rit',
    'Ġland',
    'owners',
    'Ġfl',
    'ushed',
    'Ġbroad',
    'en',
    'm',
    'ie',
    'Ġprint',
    'ln',
    'Ġn',
    'ig',
    'ĠCorp',
    'us',
    'J',
    'en',
    'Ġprot',
    'o',
    'ĠWik',
    'imedia',
    'ĠPal',
    'o',
    'C',
    'OR',
    'Ġstory',
    'lines',
    'Ġevangel',
    'icals',
    'ĠDar',
    'rell',
    'Ġrot',
    'or',
    'ĠH',
    'W',
    'sk',
    'illed',
    'ery',
    'l',
    'Ġbe',
    'gg',
    'ĠBl',
    'umenthal',
    'Ġwe',
    'aving',
    'Ġdown',
    'wards',
    'ĠJack',
    'et',
    'ĠANG',
    'EL',
    'Te',
    'chnology',
    'Ġes',
    'oteric',
    'alde',
    'hyde',
    'Ġfur',
    'iously',
    'Ġforeign',
    'er',
    'We',
    'ak',
    'CH',
    'O',
    'ĠH',
    'ound',
    'Exper',
    'ience',
    'ĠPlay',
    'station',
    'ĠM',
    'IA',
    'ĠU',
    'ng',
    'cl',
    'oth',
    'ag',
    'all',
    'Ġcal',
    'ming',
    'iz',
    'ens',
    'St',
    'ruct',
    'ĠW',
    'itches',
    'ĠCeleb',
    'ration',
    'Ġ........',
    '......',
    'pt',
    'roller',
    'ĠTC',
    'U',
    'Ġb',
    'unny',
    'ãĥ',
    'į',
    'ut',
    'orial',
    'Ġup',
    'scale',
    'ĠSt',
    'a',
    'ĠCol',
    'ossus',
    'Ġchlor',
    'ide',
    'ĠZ',
    'ac',
    'ĠRe',
    'asons',
    'ĠBrook',
    'ings',
    'ĠWH',
    'ITE',
    '][',
    '/',
    'ĠL',
    'ose',
    '9',
    '05',
    'Ġunders',
    'ide',
    'ern',
    'els',
    'Ġv',
    'ape',
    'do',
    'zen',
    'upp',
    'et',
    'ĠST',
    'OP',
    'mat',
    'ical',
    'ĠStat',
    'ements',
    'hed',
    'dar',
    'P',
    'AC',
    'Custom',
    'er',
    'Ġmem',
    'os',
    'ĠP',
    'J',
    'end',
    'ars',
    'ĠLim',
    'its',
    'l',
    'augh',
    'Ġstabil',
    'ized',
    'ĠALE',
    'C',
    'Y',
    'A',
    'Up',
    'grade',
    'al',
    'am',
    'Ġtechn',
    'o',
    'Ġan',
    'ew',
    'fore',
    'seen',
    'Ġcolleg',
    'iate',
    'ĠPy',
    'ro',
    'ĠD',
    'ism',
    'Ġfront',
    'line',
    'Ġammon',
    'ia',
    'I',
    'U',
    'Qu',
    'ite',
    'John',
    'ny',
    'ass',
    'in',
    'G',
    'OP',
    'ĠSt',
    'yles',
    'ĠSovere',
    'ign',
    'acter',
    'ial',
    '5',
    '49',
    'ĠR',
    'IP',
    'ĠL',
    'ists',
    'Ġ3',
    '64',
    'ĠRece',
    'p',
    's',
    'ocket',
    'ĠByr',
    'd',
    'ĠCand',
    'le',
    'An',
    'cient',
    'Ġappell',
    'ant',
    'en',
    'forcement',
    'ace',
    'a',
    'ans',
    'ki',
    'Ġold',
    's',
    '88',
    '6',
    'Ġsl',
    'urs',
    'Ġem',
    'pires',
    'Ġbuck',
    'le',
    'Ġalien',
    'ation',
    'ĠAber',
    'deen',
    'Ġunic',
    'orn',
    'Ġoverr',
    'iding',
    'ĠL',
    'X',
    'pp',
    'a',
    'Ġdesp',
    'ised',
    'ĠB',
    'ugs',
    'ĠB',
    'ST',
    'S',
    'outhern',
    '5',
    '33',
    'Ġhall',
    'mark',
    'ĠPost',
    'er',
    'Ġstem',
    'med',
    'Ġprincip',
    'als',
    'ĠT',
    'ECH',
    'ĠSand',
    'wich',
    'It',
    'aly',
    'Ġche',
    'esy',
    'ĠSet',
    'TextColor',
    'ĠProt',
    'ective',
    'ĠC',
    'ohn',
    'J',
    'O',
    'apt',
    'op',
    'Re',
    'ason',
    'Lead',
    'er',
    'ĠUnder',
    'stand',
    'ĠFr',
    'idays',
    'ĠContin',
    'uous',
    'Ġcl',
    'ipping',
    'ĠR',
    'ye',
    'Ġber',
    'th',
    'tim',
    'er',
    'ann',
    'is',
    're',
    'act',
    'Ġbuff',
    'alo',
    'ĠPar',
    'as',
    'Ġ6',
    '55',
    'Ġpres',
    'ided',
    'ĠSun',
    'rise',
    'Ġve',
    'ts',
    'Ġcl',
    'oves',
    'ĠMcC',
    'ull',
    'Stre',
    'ngth',
    'G',
    'AN',
    'Ġill',
    'iter',
    'ĠPric',
    'ing',
    'l',
    'Ã©',
    'Ġresist',
    'or',
    'Ġbr',
    'un',
    'ĠSuff',
    'olk',
    'Ñ',
    'ĭ',
    'ĠL',
    'iver',
    'Re',
    'leased',
    'Ġwhat',
    's',
    '8',
    '60',
    'ĠMe',
    'asures',
    'Ġden',
    'ouncing',
    'ĠRy',
    'zen',
    'Ġsou',
    'ven',
    'Ġcareg',
    'ivers',
    'ch',
    'ini',
    'ĠScar',
    'lett',
    'Ġt',
    'rough',
    'Cong',
    'ratulations',
    'Ġtax',
    'is',
    'ĠTrad',
    'ition',
    'j',
    'it',
    'Ġtable',
    'top',
    'Ġhither',
    'to',
    'Ġdis',
    'information',
    'off',
    'ensive',
    'h',
    'ra',
    'ĠDISTR',
    'ICT',
    'Ġcompl',
    'icate',
    'chen',
    'ko',
    'ĠRecon',
    'struction',
    'Ġpalp',
    'able',
    'Ġa',
    'usp',
    'Ġ4',
    '28',
    'Ġshowc',
    'ases',
    'ĠPublic',
    'ation',
    'know',
    'ledge',
    'inn',
    'on',
    '4',
    '19',
    'Ġretri',
    'eval',
    'and',
    'ers',
    'Ġref',
    'ute',
    'Ġinqu',
    'ired',
    'g',
    'ur',
    'Ġneg',
    'ativity',
    'Ġcons',
    'erve',
    'Ġafter',
    'life',
    'Ġpres',
    'upp',
    'ĠGill',
    'espie',
    'Ġm',
    't',
    'ĠD',
    'N',
    'T',
    'ap',
    'Ġper',
    'pend',
    'ĠS',
    'my',
    'does',
    'n',
    'Ġsp',
    'illing',
    'Ġhyp',
    'ers',
    'K',
    'ate',
    'Â®',
    ',',
    'ke',
    'pt',
    'ĠP',
    'owered',
    'Ġj',
    'a',
    'ĠK',
    'lux',
    'ard',
    'e',
    'ab',
    'an',
    'Ġ4',
    '44',
    'Ġflatt',
    'ened',
    'ĠImprove',
    'ments',
    'urg',
    'a',
    'ĠK',
    'und',
    'Ġins',
    'cribed',
    'Ġfac',
    'ult',
    'Ġunpre',
    'pared',
    'ĠCons',
    'umers',
    'Ġsatisf',
    'ies',
    'Ġpul',
    'monary',
    'Ġinf',
    'iltration',
    'Ġex',
    'ternally',
    'Ġcongrat',
    'ulations',
    'ag',
    'han',
    'Ġair',
    'liner',
    'Ġfl',
    'ung',
    'Ġfly',
    'ers',
    'G',
    'D',
    'Ġsnipp',
    'ets',
    'Ġrec',
    'ursive',
    'Ġmaster',
    'ing',
    'L',
    'ex',
    'Ġovert',
    'ly',
    'v',
    'g',
    'Ġluck',
    'ily',
    'Ġenc',
    'ro',
    'ĠLanc',
    'et',
    'ĠAbyss',
    'al',
    'function',
    'al',
    'Ġs',
    'ow',
    'Ġsqu',
    'id',
    'Ġnar',
    'ration',
    'Ġn',
    'aughty',
    'ĠHon',
    'our',
    'ĠSpart',
    'ans',
    'Ġsh',
    'atter',
    'ĠTac',
    'oma',
    'ĠCal',
    'ories',
    'ĠR',
    'aces',
    'Sub',
    'mit',
    'Ġpurpose',
    'fully',
    'w',
    'av',
    'ĠY',
    'ok',
    'F',
    'est',
    'ĠG',
    'err',
    'Met',
    'ro',
    'Ġit',
    'iner',
    'f',
    'amous',
    'Ġ"',
    '{',
    'in',
    'line',
    'was',
    'her',
    'Iss',
    'ue',
    'ĠCL',
    'IENT',
    'oz',
    'o',
    'Vers',
    'ions',
    '7',
    '25',
    'ĠGl',
    'ock',
    'Ġshield',
    'ed',
    'ĠPC',
    'R',
    'ENC',
    'Y',
    'ĠWe',
    'ld',
    'ĠSim',
    'pl',
    'Ġredirect',
    'ed',
    'ĠK',
    'ham',
    'Ġ(',
    '>',
    'Ġlab',
    'ou',
    'Ġdi',
    'apers',
    'ss',
    'l',
    'Ġcell',
    'ar',
    'organ',
    'isms',
    'ore',
    'sc',
    'ĠBer',
    'ks',
    'did',
    'n',
    'Sh',
    'ipping',
    'C',
    'hest',
    'Ġund',
    'one',
    'Ġmillion',
    'aire',
    'Ġc',
    'ords',
    'ĠYoung',
    'er',
    'appropri',
    'ately',
    'Ġsequ',
    'els',
    'u',
    've',
    'ant',
    'icipated',
    'Ġle',
    'wd',
    'ĠSh',
    'irt',
    'ĠDmit',
    'ry',
    'V',
    'eter',
    'Ġsl',
    'aying',
    'ĠY',
    'ar',
    'Ġcompl',
    'ication',
    'I',
    'owa',
    'ĠEric',
    'a',
    'ĠBL',
    'M',
    'g',
    'irlfriend',
    'b',
    'odied',
    '6',
    '26',
    '19',
    '63',
    'Ġintermedi',
    'ary',
    'Ġcons',
    'olation',
    'M',
    'ask',
    'ĠSi',
    'em',
    'ow',
    'an',
    'Beg',
    'inning',
    'Ġfix',
    'me',
    'Ġculmin',
    'ated',
    'Ġcon',
    'duc',
    'ĠVolunte',
    'er',
    'Ġpos',
    'itional',
    'Ġgre',
    'ets',
    'ĠDefin',
    'itions',
    'Ġthink',
    'er',
    'Ġingen',
    'uity',
    'Ġfresh',
    'men',
    'ĠMom',
    'ents',
    'Ġ35',
    '7',
    'ate',
    'urs',
    'ĠFed',
    'Ex',
    's',
    'g',
    '69',
    '4',
    'Ġdwind',
    'ling',
    'ĠBO',
    'X',
    'sel',
    'age',
    'Ġt',
    'mp',
    'Ġst',
    'en',
    'ĠS',
    'ut',
    'Ġneighbourhood',
    's',
    'Ġclass',
    'mate',
    'f',
    'ledged',
    'Ġleft',
    'ists',
    'Ġclim',
    'ates',
    'ATH',
    'ER',
    'ĠScy',
    'the',
    'ul',
    'iffe',
    'Ġs',
    'ag',
    'Ġho',
    'pped',
    'ĠF',
    't',
    'ĠE',
    'ck',
    'ĠC',
    'K',
    'ĠDo',
    'omsday',
    'k',
    'ids',
    'Ġgas',
    'ped',
    'Ġmon',
    'iker',
    'ĠL',
    'od',
    'ĠC',
    'FL',
    't',
    'ions',
    'r',
    'ums',
    'fol',
    'ios',
    'Ġm',
    'd',
    'Ġunc',
    'anny',
    'Ġtrans',
    'ports',
    'ĠLab',
    'rador',
    'Ġrail',
    'ways',
    'Ġappl',
    'iance',
    'ĠCTR',
    'L',
    'æ',
    'Ģ',
    'Pop',
    'ulation',
    'ĠConfeder',
    'acy',
    'Ġunb',
    'earable',
    'Ġdors',
    'al',
    'ĠIn',
    'form',
    'op',
    'ted',
    'ĠK',
    'ILL',
    'Mar',
    'x',
    'Ġhypoc',
    'ritical',
    'q',
    'us',
    'ĠN',
    'umerous',
    'ĠGeorg',
    'ian',
    'ĠAmbro',
    'se',
    'ĠL',
    'och',
    'Ġgu',
    'bernatorial',
    'ĠX',
    'eon',
    'ĠSupp',
    'orts',
    'ens',
    'er',
    'ee',
    'ly',
    'ĠAven',
    'ger',
    '19',
    '65',
    'Ar',
    'my',
    'Ġju',
    'xtap',
    'Ġcho',
    'pping',
    'ĠSpl',
    'ash',
    'ĠS',
    'ustainable',
    'ĠFin',
    'ch',
    'Ġ18',
    '61',
    'ict',
    'ive',
    'at',
    'meal',
    'ĠG',
    'ohan',
    'Ġlights',
    'aber',
    'ĠG',
    'PA',
    'ug',
    'u',
    'ĠRE',
    'PL',
    'vari',
    'able',
    'Ġher',
    'pes',
    'Ġdesert',
    's',
    'ac',
    'iously',
    'Ġsitu',
    'ational',
    'week',
    'ly',
    'ob',
    'l',
    'Ġtext',
    'ile',
    'ĠCorn',
    'wall',
    'Ġcontrace',
    'ptives',
    'ĠA',
    'ke',
    ']',
    '-',
    'ä¹',
    'ĭ',
    ':',
    ',',
    'ĠW',
    'em',
    'ĠB',
    'ihar',
    "Ġ'",
    '.',
    'Ġbe',
    're',
    'Ġanal',
    'ogue',
    'ĠCook',
    'ies',
    'Ġtake',
    'off',
    'Whe',
    'el',
    'Ġmaj',
    'estic',
    'Ġcomm',
    'uting',
    '0',
    '23',
    'ĠCor',
    'pse',
    'ass',
    'ment',
    'min',
    'i',
    'Ġgor',
    'illa',
    'ĠAl',
    'as',
    'ere',
    'e',
    'Ġacquaint',
    'ances',
    'ĠAd',
    'vantage',
    'Ġspirit',
    'ually',
    'Ġey',
    'ed',
    'pm',
    'wiki',
    'ĠE',
    'nder',
    'Ġtrans',
    'lucent',
    'Ġnight',
    'time',
    'ĠIM',
    'AGES',
    '5',
    '45',
    'ĠK',
    'amp',
    'ĠFre',
    'ak',
    'Ġ',
    'ig',
    'Port',
    'land',
    '4',
    '32',
    'ĠM',
    'ata',
    'Ġmar',
    'ines',
    'Ġh',
    'ors',
    'ater',
    'asu',
    'ĠAtt',
    'ribution',
    'Ġ--------',
    '-',
    'Ġk',
    'ins',
    'ĠBEL',
    'OW',
    '++',
    '+',
    'Ġre',
    'eling',
    'ol',
    'ed',
    'Ġcl',
    'utter',
    'ĠRel',
    'ative',
    'Ġ4',
    '27',
    'B',
    'US',
    'Ġa',
    'vert',
    'ĠChe',
    'ong',
    'ĠA',
    'ble',
    'ĠPry',
    'or',
    'Develop',
    'er',
    'Ġen',
    'cyclopedia',
    'ĠUSA',
    'F',
    'ĠG',
    'arry',
    'Sp',
    'ain',
    'Bl',
    'ocks',
    'Ġexp',
    'osition',
    'ĠGamer',
    'Gate',
    'W',
    'OR',
    'Ġstockp',
    'ile',
    'Ġclot',
    'hed',
    'ĠT',
    'one',
    'ĠR',
    'ue',
    't',
    'umblr',
    'Ġtreacher',
    'ous',
    'Ġf',
    'rying',
    'Ñ',
    'Į',
    'ĠS',
    'ph',
    'Ġrest',
    'raints',
    'Ġemb',
    'odies',
    'ĠG',
    'es',
    'S',
    'afety',
    'Ġnegoti',
    'ators',
    'min',
    'ing',
    'ĠAppalach',
    'ian',
    'L',
    'OS',
    'ĠJenn',
    'a',
    'Ġpass',
    'ers',
    'ç',
    'ĭ',
    'sn',
    'ap',
    'Ġshort',
    'en',
    'creat',
    'or',
    'Ġinn',
    'umerable',
    'uther',
    'land',
    '67',
    '4',
    'ĠW',
    'OM',
    'ĠAs',
    'cend',
    'ĠArm',
    'ory',
    'ĠTrans',
    'action',
    'K',
    'ick',
    'Ġsuit',
    'case',
    'day',
    'Name',
    'Ġwaste',
    'ful',
    'mar',
    'riage',
    'ĠMcC',
    'abe',
    'ite',
    'ch',
    'ĠO',
    'ss',
    'Cl',
    'osure',
    'ĠTreasure',
    'r',
    'Ġindec',
    'ent',
    'ĠD',
    'ull',
    'Ġresid',
    'ences',
    '19',
    '59',
    'ĠS',
    'ettlement',
    'Ham',
    'ilton',
    'Ġself',
    'ies',
    'ĠRank',
    'ing',
    'ĠBark',
    'ley',
    'ĠB',
    'ore',
    'ĠW',
    'CS',
    'ĠMar',
    'itime',
    'ĠH',
    'uh',
    'ĠForest',
    'ry',
    'Ġcultiv',
    'ating',
    'ĠBall',
    'ard',
    'Ġg',
    'arrison',
    'ĠSD',
    'L',
    '9',
    '30',
    'Ġnas',
    'cent',
    'Ġirresist',
    'ible',
    'Ġaw',
    'fully',
    '\\/',
    '\\/',
    'Ġequ',
    'ate',
    'Ġanthrop',
    'ology',
    'ĠSylv',
    'ia',
    'Ġintest',
    'ine',
    'Ġinnoc',
    'uous',
    'cess',
    'ive',
    'ag',
    'ra',
    'ĠMet',
    'roid',
    'G',
    'rant',
    '8',
    '55',
    'ģ',
    'ĸ',
    'Ġ"',
    '_',
    'ãĥĥ',
    'ãĥī',
    'Ġappra',
    'isal',
    'ĠFred',
    'dy',
    '04',
    '6',
    'Ġ40',
    '6',
    'Ġ18',
    '30',
    'Ġd',
    'ocking',
    'St',
    'atic',
    'Ġp',
    'ont',
    'ĠVolt',
    'age',
    'ĠSt',
    'ead',
    'ĠMort',
    'gage',
    'ĠJon',
    'ah',
    'Y',
    'L',
    'CLASS',
    'IFIED',
    'Ġas',
    'bestos',
    'nik',
    'ov',
    'Ġcoll',
    'agen',
    'ĠOrb',
    'ital',
    'P',
    'ocket',
    '7',
    '99',
    'Ġhy',
    'brids',
    'inc',
    'hes',
    'Ġinv',
    'oice',
    'und',
    'y',
    'Ġinequ',
    'alities',
    'T',
    'rend',
    'w',
    'ashed',
    'B',
    'ALL',
    'Ġluc',
    'id',
    'ĠComment',
    'ary',
    'Ġw',
    'itty',
    'Br',
    'andon',
    'Ġbru',
    'ising',
    'Ġ6',
    '20',
    'es',
    'cent',
    'box',
    'ing',
    'P',
    'OL',
    'Ġ3',
    '78',
    'R',
    'ect',
    'Ġlic',
    'ences',
    'ĠMcG',
    'ee',
    'p',
    'ressed',
    'D',
    'anny',
    'Ġj',
    'ammed',
    'ord',
    'inate',
    'Ġle',
    'th',
    'Ġdistingu',
    'ishes',
    'ĠYam',
    'aha',
    'IL',
    'S',
    'ĠH',
    'ume',
    'ĠC',
    'ategories',
    'Rober',
    'ts',
    'Ch',
    'art',
    'Ġbeet',
    'le',
    'ĠGra',
    'veyard',
    'Ġ($',
    ')',
    'o',
    'ÄŁ',
    'Ġtw',
    'ilight',
    'are',
    'lla',
    'á',
    '½',
    'Ġbooth',
    's',
    'ĠH',
    'HS',
    'ĠFeld',
    'man',
    'Ġexcav',
    'ation',
    'Ġphilosoph',
    'ies',
    'at',
    'ography',
    'ĠGar',
    'age',
    'te',
    'chnology',
    'Ġunfor',
    'gettable',
    'Ġver',
    'ifying',
    'Ġsubord',
    'inates',
    'E',
    'ls',
    'Ġne',
    'b',
    'G',
    'aming',
    'EN',
    'A',
    'ĠAchieve',
    'ment',
    'it',
    'ters',
    'ĠG',
    'abe',
    'Ġd',
    'umps',
    'for',
    'cer',
    'Ġpo',
    'ignant',
    'ĠM',
    'BA',
    'ĠHe',
    'idi',
    'ime',
    'i',
    'Ġm',
    'ages',
    'Ġliber',
    'ate',
    'Ġcircum',
    'cised',
    'ĠMer',
    'maid',
    'ĠMat',
    'th',
    't',
    'ogether',
    'ĠW',
    'ichita',
    'Ġstore',
    'front',
    'ĠAd',
    'in',
    'V',
    'II',
    'Four',
    'th',
    'Ġexplore',
    'rs',
    'W',
    'ER',
    'Not',
    'able',
    'Bro',
    'ok',
    'm',
    'ens',
    'F',
    'aith',
    '--------',
    '-',
    'ĠJ',
    'ou',
    '¬',
    '¼',
    'Ġpine',
    'apple',
    'Ġam',
    'alg',
    'el',
    'n',
    'ark',
    'able',
    'ĠãĤµ',
    'ãĥ¼ãĥĨãĤ£',
    'ĠãĤµãĥ¼ãĥĨãĤ£',
    'ãĥ¯ãĥ³',
    'Ġov',
    'arian',
    'ĠE',
    'choes',
    'Ġhairc',
    'ut',
    'Ġp',
    'av',
    'Ġch',
    'illed',
    'anas',
    'ia',
    'Ġsty',
    'led',
    'Ġd',
    'ab',
    'ni',
    'per',
    'Ġminister',
    'ial',
    'ĠD',
    'UP',
    'T',
    'an',
    'Ġsul',
    'ph',
    'ĠD',
    'eter',
    'ĠBo',
    'hem',
    'od',
    'an',
    'Ġeduc',
    'ator',
    'â',
    'ĵĺ',
    'sp',
    'ir',
    'Ch',
    'icken',
    'ĠE',
    'leanor',
    'Ġqu',
    'i',
    'Ġheav',
    'iest',
    'Ġgrasp',
    'ed',
    'U',
    'RA',
    'Ġcro',
    'oked',
    'Jess',
    'ica',
    'pro',
    'blem',
    'Ġpred',
    'etermined',
    'Ġman',
    'iac',
    'Ġbreath',
    's',
    'ĠLauder',
    'dale',
    'Ġh',
    'obbies',
    'y',
    'z',
    'Cr',
    'ime',
    'Ġcharism',
    'a',
    'd',
    'L',
    'Ġle',
    'aping',
    'Ġk',
    'ittens',
    'Ang',
    'elo',
    'ĠJ',
    'ACK',
    'ĠSu',
    'zanne',
    'Ġhal',
    'ting',
    'ENT',
    'ION',
    'Ġswall',
    'owing',
    'ĠEarthqu',
    'ake',
    'Ġeight',
    'eenth',
    'ĠN',
    'IC',
    'ĠIN',
    'F',
    'ĠCons',
    'cious',
    'Ġparticular',
    's',
    'circ',
    'le',
    '7',
    '40',
    'Ġbene',
    'volent',
    'Ġ7',
    '47',
    'Ġ4',
    '90',
    'Ġr',
    'undown',
    'ĠVal',
    'erie',
    'ĠB',
    'UR',
    'Ġcivil',
    'isation',
    'ĠS',
    'chn',
    'W',
    'B',
    'ot',
    'ide',
    'intern',
    'ational',
    'Ġj',
    'ohn',
    'Ġ19',
    '02',
    'Ġpe',
    'anuts',
    'Ġflav',
    'ored',
    'k',
    'us',
    'Ġro',
    'ared',
    'Ġcut',
    'off',
    'é',
    '£',
    'Ġorn',
    'ament',
    'Ġarchitect',
    'ures',
    'Ġ3',
    '69',
    'ol',
    'or',
    'ĠWild',
    'e',
    'ĠC',
    'RC',
    'ĠAdjust',
    'ed',
    'Ġprov',
    'oking',
    'land',
    'ish',
    'Ġrational',
    'ity',
    'Ġjust',
    'ifies',
    'Ġdisp',
    'el',
    'Ġa',
    'meric',
    'ĠPol',
    'es',
    'Ø',
    '©',
    'Ġen',
    'vis',
    'ĠD',
    'oodle',
    'ä½',
    '¿',
    'igs',
    'aw',
    'auld',
    'ron',
    'Techn',
    'ical',
    'T',
    'een',
    'up',
    'hem',
    'ĠX',
    'iang',
    'Ġdetract',
    'ors',
    'ĠZ',
    'i',
    'ĠJournal',
    'ists',
    'Ġconduc',
    'ive',
    'ĠVolunte',
    'ers',
    'Ġs',
    'd',
    'Know',
    'ing',
    'Ġtrans',
    'missions',
    'ĠPL',
    'AN',
    'ĠL',
    'IB',
    'Ġall',
    'uded',
    'Ġob',
    'e',
    'Ġd',
    'ope',
    'ĠGold',
    'stein',
    'Ġwavelength',
    's',
    'ĠDest',
    'ination',
    'nd',
    'a',
    'ug',
    'i',
    'Ġattent',
    'ive',
    'ĠLe',
    'an',
    'ral',
    'tar',
    'Ġman',
    'g',
    'mb',
    'uds',
    'ak',
    'ings',
    'b',
    'ender',
    'Ġacc',
    'ol',
    'Ġcraw',
    'led',
    'N',
    'OW',
    'Min',
    'nesota',
    'Ġflour',
    'ished',
    'ĠZ',
    'up',
    'ĠSuper',
    'visor',
    'ĠOliv',
    'ier',
    'Ex',
    'cellent',
    'Ġwid',
    'en',
    'D',
    'one',
    'Ġw',
    'ig',
    'Ġmiscon',
    'ceptions',
    'Cor',
    'p',
    'W',
    'an',
    'Ġvener',
    'able',
    'ĠNot',
    'ably',
    'ĠKling',
    'on',
    'an',
    'imate',
    'Bo',
    'ost',
    'ĠS',
    'AY',
    'miss',
    'ing',
    'ibli',
    'ography',
    'mel',
    'on',
    'Ġpay',
    'day',
    'Ø',
    '³',
    'bo',
    'le',
    'Ġve',
    'iled',
    'ĠAl',
    'phabet',
    'It',
    'alian',
    'Ġever',
    'lasting',
    'ĠR',
    'IS',
    'ĠC',
    'ree',
    'rom',
    'pt',
    'Ġh',
    'ating',
    'Ġgrin',
    'ning',
    'Ġge',
    'ographically',
    'OS',
    'H',
    'Ġwe',
    'eping',
    'ĠÂłĠÂłĠÂłĠÂł',
    'ĠÂłĠÂłĠÂłĠÂł',
    'Ġimpe',
    'cc',
    'Let',
    'ter',
    'Ġblo',
    'ated',
    'PL',
    'A',
    'ĠFe',
    'in',
    'Ġper',
    'sever',
    'Th',
    'under',
    'Ġa',
    'ur',
    'ĠR',
    'L',
    'Ġpit',
    'falls',
    'âĸ',
    'º',
    'Ġpredomin',
    'ant',
    'Ġ5',
    '25',
    '7',
    '18',
    'AP',
    'E',
    '7',
    '14',
    'Ġfarm',
    'land',
    'ĠQ',
    'iao',
    'Ġv',
    'iolet',
    'ĠBah',
    'amas',
    'Ġinflic',
    'ting',
    'ĠE',
    'fficiency',
    'Ġhome',
    'brew',
    'Ġundert',
    'ook',
    'Ġcur',
    'ly',
    'ĠHard',
    'ing',
    'man',
    'ia',
    '59',
    '6',
    'Ġtem',
    'pered',
    'Ġhar',
    'rowing',
    'ĠP',
    'ledge',
    'ĠFranken',
    'stein',
    'è',
    'ª',
    'M',
    'otion',
    'Ġpredict',
    'ably',
    'ĠExpl',
    'osion',
    'oc',
    'using',
    'er',
    'd',
    'col',
    'o',
    'FF',
    'ER',
    'Ġback',
    'field',
    'ĠV',
    'IDE',
    'ue',
    'bl',
    'N',
    'arr',
    'ĠArg',
    'ument',
    'Ġgen',
    'omic',
    'Ġbout',
    'ique',
    'Ġbatt',
    'ed',
    'ĠB',
    'inary',
    'Ġg',
    'amb',
    'ĠRh',
    'ythm',
    '67',
    '3',
    'Ġa',
    'float',
    'ĠOlymp',
    'ia',
    'Y',
    'ING',
    'Ġend',
    'if',
    'is',
    'in',
    'Ġwin',
    'ters',
    'Ġsc',
    'attering',
    'I',
    'v',
    'D',
    'istance',
    'Ġtr',
    'u',
    'ĠCom',
    'fort',
    'Ġne',
    'xus',
    'Ġair',
    'flow',
    'ĠByz',
    'antine',
    'p',
    'ayers',
    'con',
    'i',
    'ĠB',
    'etsy',
    'D',
    'eal',
    'ĠN',
    'ug',
    'ĠContin',
    'ent',
    'red',
    'ibly',
    'Ġoptim',
    'izing',
    'al',
    'beit',
    'Ġec',
    'static',
    'ĠPro',
    'to',
    'ç',
    '·',
    'iv',
    'ot',
    'âĸ',
    'Ħ',
    'em',
    'p',
    'rou',
    'nder',
    'Ġcl',
    'out',
    'ĠI',
    'ST',
    '66',
    '3',
    'ĠDoll',
    'ars',
    'ĠD',
    'AC',
    'Ġsubsc',
    'ribed',
    'Ġrehears',
    'al',
    'Ġam',
    'ps',
    'ĠSh',
    'ang',
    'es',
    'm',
    'Ġspr',
    'inkle',
    'Ġassail',
    'ant',
    'ĠO',
    'o',
    'ĠCoin',
    'base',
    'T',
    'act',
    'Ġret',
    'ina',
    'Ġn',
    'uns',
    'R',
    'ON',
    'att',
    'o',
    'Ġj',
    'ug',
    'ĠSV',
    'G',
    'Ġb',
    'ikini',
    'ĠFI',
    'LE',
    'ĠFound',
    'ers',
    'ep',
    'ort',
    'ĠK',
    'P',
    'Ġrest',
    'ores',
    'ĠTh',
    'ick',
    'Ġash',
    'ore',
    'Ġappro',
    'vals',
    'R',
    'ender',
    'M',
    'AG',
    'G',
    'raham',
    'ĠCort',
    'ana',
    'ãĥ³',
    'ãĤ¸',
    'ss',
    'h',
    'or',
    'ians',
    'ars',
    'ity',
    'ĠInsp',
    'ired',
    'u',
    'pper',
    'Ġsign',
    'alling',
    'Ġreb',
    'uke',
    'Ġfl',
    'ares',
    'Ġdownt',
    'ime',
    'Stud',
    'ies',
    'Ġstagn',
    'ation',
    'ĠSequ',
    'ence',
    'Ġgr',
    'unt',
    'Ġass',
    'ures',
    'ĠPL',
    'A',
    '59',
    '2',
    'Ġintra',
    'ven',
    'd',
    'epend',
    'Sus',
    'an',
    'ĠManz',
    'iel',
    'Man',
    'ia',
    'Cont',
    'ract',
    'Ġsl',
    'ams',
    'Ġcult',
    'ured',
    'Ġcred',
    'itor',
    'L',
    'IST',
    'ĠH',
    'UM',
    'ĠChatt',
    'anooga',
    'serv',
    'ed',
    'Ġclo',
    'aked',
    'ĠF',
    'TP',
    'p',
    'owder',
    'ĠSt',
    'ella',
    'uct',
    'ive',
    'Ġcheap',
    'ly',
    'ĠMU',
    'CH',
    'ĠGalile',
    'o',
    'Ġsu',
    'ites',
    'spe',
    'ech',
    'Ġdeliber',
    'ations',
    'ĠCh',
    'ips',
    '«',
    'ĺ',
    'Bal',
    'ance',
    'ĠWyn',
    'ne',
    'ĠAk',
    'ron',
    'Ass',
    'et',
    'Ġhon',
    'oured',
    'Ġed',
    'ged',
    'Like',
    'wise',
    'anim',
    'ous',
    'ĠW',
    'age',
    'ĠEz',
    'ek',
    'ad',
    'vertisement',
    'ĠRT',
    'X',
    'ĠM',
    'AD',
    'Ġmigr',
    'ating',
    'ĠS',
    'QU',
    'Ġ4',
    '75',
    'Ed',
    'ited',
    'Ġshorth',
    'and',
    'ĠBas',
    'ics',
    'Ġcro',
    'tch',
    'ĠEV',
    'EN',
    'Ġv',
    'm',
    'effic',
    'iency',
    'Ġcal',
    'ves',
    'ĠF',
    'rie',
    'ĠBrill',
    'iant',
    'Ġstri',
    'kers',
    'Ġrepent',
    'ance',
    'Ġarter',
    'ies',
    'r',
    'l',
    'B',
    'ed',
    'h',
    'ap',
    'Ġcrypt',
    'ography',
    'ĠSab',
    'res',
    'Ġ4',
    '14',
    'vi',
    'ks',
    'ih',
    'ara',
    'aps',
    'es',
    'T',
    'alking',
    'Ġintertw',
    'ined',
    'Ġdoc',
    'ks',
    'Ġalle',
    'le',
    'ĠArt',
    'ifact',
    'ĠH',
    'IM',
    't',
    'orn',
    'ç',
    'ķ',
    'Ġop',
    'acity',
    'ĠE',
    'ly',
    'os',
    'uke',
    'Ġn',
    'ipple',
    'Ġhand',
    'written',
    'ĠV',
    'K',
    'ĠChamber',
    'lain',
    'ĠLa',
    'os',
    'ig',
    'raph',
    'g',
    'row',
    'Ġtr',
    'illions',
    'Ġdescend',
    'ant',
    'ĠSail',
    'or',
    'as',
    'uring',
    'Ġce',
    'ilings',
    'ĠWare',
    'house',
    'f',
    'lying',
    'ĠGl',
    'ow',
    'Ġn',
    'ont',
    'Ġmiscar',
    'riage',
    'Ġrig',
    's',
    'Ġmin',
    'istries',
    'Ġelabor',
    'ated',
    'Ġdel',
    'usional',
    'ĠHum',
    'ane',
    'Ġ3',
    '79',
    'n',
    'ets',
    'Ġblack',
    'out',
    'add',
    'ers',
    'Ġn',
    'p',
    'ĠT',
    'ire',
    'ro',
    'sc',
    'Ġsub',
    'div',
    'Ġlink',
    'age',
    'Ġchron',
    'ological',
    'ĠHER',
    'O',
    'Ġres',
    'ettlement',
    'ĠVin',
    'yl',
    'Ġpast',
    'oral',
    'ĠMob',
    'il',
    'ĠBar',
    'bar',
    'Co',
    'oldown',
    'ĠF',
    'ritz',
    'c',
    'riminal',
    're',
    'pe',
    'Ġbell',
    'ig',
    'ĠBre',
    'ed',
    'Ġ4',
    '18',
    'Ġsem',
    'blance',
    'ij',
    'k',
    'Ġcur',
    'tail',
    'Ġclin',
    'ch',
    'cont',
    'ained',
    'ĠProm',
    'pt',
    'ast',
    'on',
    'Ġw',
    'i',
    'Ġpursu',
    'its',
    '5',
    '15',
    'ĠGl',
    'oss',
    'Ġfl',
    'ips',
    'Ġcoup',
    'ons',
    'Ġcl',
    'oning',
    'ĠLike',
    'ly',
    'Rem',
    'oved',
    'ĠQu',
    'artz',
    'r',
    'ices',
    'ĠSpe',
    'ars',
    'Ġp',
    'ious',
    'Ġdep',
    'reciation',
    'ĠD',
    'are',
    'oun',
    'ces',
    'am',
    'az',
    'O',
    'nt',
    'Ġp',
    'innacle',
    'd',
    'ocker',
    '0',
    '26',
    'ĠW',
    'yr',
    'ĠPro',
    'per',
    'Ë',
    'Ī',
    'n',
    'il',
    'By',
    'tes',
    'Ġseek',
    'er',
    't',
    'rial',
    'Ġunf',
    'olds',
    'ĠMar',
    'se',
    'Ġextravag',
    'ant',
    'ĠSurviv',
    'ors',
    'RED',
    'ACTED',
    'ĠSpeed',
    'way',
    'ĠCra',
    'igslist',
    'sub',
    'mit',
    'ĠGener',
    'ations',
    'Ġup',
    'holding',
    'Ġblood',
    'stream',
    'ĠMiss',
    'ions',
    'ĠL',
    'awn',
    'Ġlim',
    'bo',
    'ene',
    'i',
    'H',
    'uh',
    'ĠWild',
    'cats',
    'pre',
    'p',
    'ĠMark',
    'us',
    'ĠFor',
    'bidden',
    'rit',
    'ic',
    'IN',
    'O',
    'Ġexhib',
    'iting',
    'requ',
    'ent',
    'ch',
    'uk',
    'Ġhabit',
    'ual',
    'ĠComp',
    'atibility',
    'Dr',
    'ag',
    'RIP',
    'T',
    'uj',
    'ah',
    'GR',
    'OUND',
    'Ġdelinqu',
    'ent',
    'Ġburn',
    'er',
    'Ġcontempor',
    'aries',
    'Ġgimm',
    'ick',
    'load',
    's',
    'Ġno',
    'zzle',
    'p',
    'odcast',
    'ĠW',
    'ak',
    'ĠStat',
    'en',
    'ĠK',
    'uh',
    'ãģ',
    'ĵ',
    'inter',
    'rupted',
    'Ġinv',
    'incible',
    'ĠBurn',
    'ett',
    'cig',
    'arette',
    'ĠPeb',
    'ble',
    'ĠTem',
    'porary',
    'ĠMar',
    'ino',
    '58',
    '2',
    'Ġwast',
    'eland',
    'ident',
    'ly',
    'T',
    'x',
    'Ġr',
    'ite',
    'ĠPan',
    'asonic',
    'ĠM',
    'iddles',
    'ĠHort',
    'on',
    'ae',
    'us',
    'Ġc',
    'uring',
    'Ġm',
    'ats',
    'Ġadj',
    'ourn',
    'Ġfears',
    'ome',
    'pe',
    'z',
    'bo',
    'ats',
    'Ġpro',
    'pell',
    'Ġconflic',
    'ted',
    'ĠAng',
    'er',
    'Ġinsurg',
    'ent',
    'K',
    'arl',
    'Ġco',
    'ales',
    'Ġsouth',
    'western',
    'Ġdis',
    'su',
    'ĠO',
    'vert',
    '********',
    '****',
    'Ġbox',
    'ed',
    'ĠBr',
    'une',
    'aa',
    'a',
    'Ġgard',
    'ening',
    'ĠEng',
    'el',
    'tr',
    'acks',
    'Ġpur',
    'ified',
    'Ġplace',
    'holder',
    'ĠL',
    'ikes',
    'Ġd',
    'an',
    'G',
    'ab',
    'Ġe',
    'ct',
    'ĠF',
    'aw',
    'ĠEl',
    'iot',
    "Ġ'",
    ',',
    'otrop',
    'ic',
    'ĠRu',
    'in',
    'hed',
    'on',
    'Ġca',
    'ul',
    'Ġa',
    'ft',
    'ĠCad',
    'illac',
    'gh',
    'a',
    'ass',
    'ian',
    'ud',
    'eb',
    'ĠT',
    'ick',
    'Ġadjust',
    's',
    'AR',
    'GET',
    '5',
    '37',
    'isc',
    'he',
    'ant',
    'y',
    'ĠFried',
    'rich',
    'ĠBl',
    'izz',
    'ĠA',
    'OL',
    'Camp',
    'aign',
    'Ġmamm',
    'al',
    'ĠVe',
    'il',
    'ĠK',
    'ev',
    'ĠMaur',
    'it',
    'ĠDam',
    'ien',
    'N',
    'ation',
    'E',
    'astern',
    'Ġ{',
    ':',
    'Ġ=',
    '================================',
    'Ġstereotyp',
    'ical',
    'Ġatt',
    'ic',
    'ĠCy',
    'borg',
    'requ',
    'ire',
    'Ġaward',
    'ing',
    'ĠPap',
    'ua',
    'bt',
    'n',
    'b',
    'ent',
    'B',
    'oo',
    'Ġ(',
    '=',
    'ĠX',
    'ander',
    'ĠSomers',
    'et',
    'Ġcatch',
    'y',
    'Ġcert',
    'ify',
    'STR',
    'UCT',
    'Ġit',
    'al',
    'Ġt',
    'ides',
    'ĠBr',
    'ands',
    'G',
    'ray',
    'comp',
    'etitive',
    'Ġcur',
    'ator',
    'ĠD',
    'G',
    'omin',
    'ium',
    'ĠGM',
    'Os',
    'ci',
    'ating',
    'ĠCarm',
    'en',
    'ow',
    'ard',
    'Balt',
    'imore',
    'Ġr',
    'gb',
    'C',
    'u',
    'Ġwip',
    'es',
    'spe',
    'll',
    'IT',
    'NESS',
    'Ġsummar',
    'izes',
    'ĠRe',
    'vis',
    'Ġwhistlebl',
    'owers',
    'ĠBre',
    'ach',
    'Ġcro',
    'chet',
    'k',
    'os',
    'ews',
    'ki',
    'Ġrep',
    'et',
    'Ġcrim',
    'son',
    'ĠKar',
    'achi',
    'read',
    'able',
    'dim',
    'ension',
    'ĠI',
    'gor',
    'ild',
    'ed',
    'ĠZ',
    'ed',
    'ĠKe',
    'ane',
    'ĠCos',
    'metic',
    'DE',
    'P',
    'Ġretreat',
    'ing',
    'ĠU',
    'A',
    'ens',
    'ical',
    'Ġd',
    'usk',
    'ĠDick',
    'ens',
    'Ġaren',
    'as',
    'ĠPass',
    'age',
    'level',
    's',
    'Ġcur',
    'v',
    'P',
    'ope',
    'Ġch',
    'ores',
    'ĠEl',
    'ise',
    'ĠComp',
    'ass',
    'b',
    'ub',
    'Ġmamm',
    'alian',
    'ĠSans',
    'krit',
    'ĠAN',
    'C',
    'ĠCr',
    'ack',
    'Q',
    'ual',
    'L',
    'aun',
    'amp',
    'unk',
    'Ġlearn',
    'ers',
    'Ġglam',
    'orous',
    'Ġfur',
    'the',
    'erm',
    'ott',
    'c',
    'and',
    'Gener',
    'ic',
    'Ġnarr',
    'ated',
    'Ġdisorder',
    'ly',
    'ĠTrans',
    'actions',
    'ĠDet',
    'ention',
    'ĠR',
    'oku',
    'Ä',
    'į',
    'Ġunder',
    'statement',
    'ĠS',
    'aur',
    'ĠRodrig',
    'o',
    'ĠAS',
    'AP',
    'S',
    'in',
    'Ġre',
    'joice',
    'Method',
    's',
    'Ġelectro',
    'de',
    'Ġworsh',
    'ipped',
    'Ġid',
    'i',
    'ĠPhys',
    'icians',
    'Ġpop',
    'up',
    'Ġde',
    'ft',
    'ĠRem',
    'oval',
    'ĠBu',
    'enos',
    'ver',
    'bs',
    'Ġfun',
    'k',
    'ush',
    'a',
    'rict',
    'ion',
    'ore',
    'a',
    'ĠBang',
    'alore',
    'ĠKen',
    'obi',
    'zz',
    'i',
    'Ġnorm',
    'ative',
    'Ġgobl',
    'ins',
    'Ġcaf',
    'es',
    'ĠUN',
    'CLASSIFIED',
    'ĠF',
    'ired',
    'S',
    'IGN',
    'Ġs',
    'clerosis',
    'ĠV',
    'oter',
    'ĠSon',
    'ny',
    'ĠExt',
    'end',
    'ĠEV',
    's',
    'Ar',
    'senal',
    'Ġp',
    'si',
    'Ġwid',
    'est',
    'ĠT',
    'us',
    'Ġlo',
    'oms',
    'Ġjust',
    'ifying',
    'ĠGr',
    'anger',
    'è',
    '¯',
    'Ref',
    'er',
    '58',
    '3',
    'Ġflour',
    'ishing',
    'ab',
    're',
    'Ġr',
    'ave',
    'ĠCont',
    'ra',
    'Ġ18',
    '98',
    'Add',
    's',
    'Ġf',
    'ul',
    'ĠCo',
    'oke',
    'some',
    'one',
    '=',
    '#',
    '67',
    '1',
    'Ġy',
    'ak',
    'Ġar',
    'te',
    'ĠMis',
    'cellaneous',
    'ĠDet',
    'ection',
    'ĠCl',
    'ancy',
    'â',
    'ģ',
    'ass',
    'ies',
    'Ġval',
    'iant',
    'ĠFemin',
    'ist',
    'cor',
    'ruption',
    'V',
    'el',
    'P',
    'ear',
    'Ġsucc',
    'inct',
    'Ġquick',
    'est',
    'k',
    'w',
    'Ġsp',
    'itting',
    'ĠL',
    'ibraries',
    'åħ',
    'ī',
    'ant',
    'z',
    'D',
    'ad',
    'ĠSpec',
    'ifications',
    'rup',
    'ulous',
    'and',
    'r',
    'RES',
    'ULTS',
    'Ġsnow',
    'ball',
    'Ġpred',
    'is',
    'ĠB',
    'axter',
    'ĠNurs',
    'ing',
    'ĠCh',
    'aff',
    's',
    'we',
    'Ġout',
    'age',
    'Ġnest',
    'ing',
    'Ġnotor',
    'iety',
    'tr',
    'igger',
    'on',
    'ite',
    'j',
    'on',
    'Ġf',
    'ou',
    'ook',
    'ed',
    'ĠCelebr',
    'ity',
    're',
    'ality',
    'Ġfat',
    'ig',
    'Ġhug',
    'ging',
    'Ġbother',
    's',
    'ĠPan',
    'zer',
    'ĠCh',
    'andra',
    'fig',
    'ured',
    'Ġvol',
    'ts',
    'ĠCloud',
    's',
    'Ġfee',
    'ble',
    'ĠCur',
    've',
    'ĠAs',
    'us',
    '78',
    '6',
    'abs',
    'or',
    'ĠV',
    'ICE',
    'ĠH',
    'ess',
    'Ġmanufact',
    'ures',
    'Ġgri',
    'zz',
    'ĠPower',
    'ful',
    'ac',
    'id',
    'Ġsub',
    'sections',
    'ĠKrug',
    'man',
    'ĠAl',
    'ps',
    'is',
    'u',
    'Ġsequ',
    'est',
    'ĠUlt',
    'ron',
    'ĠT',
    'inker',
    'ĠGo',
    'ose',
    'Ġmism',
    'atch',
    'Att',
    'orney',
    'Ġmorph',
    'ology',
    'ĠSix',
    'ers',
    'ut',
    'tered',
    'ĠE',
    'LECT',
    'gr',
    'an',
    'Rus',
    'sell',
    'ĠG',
    'SL',
    'Ġfort',
    'night',
    'Ġ.',
    ')',
    'Ġapost',
    'le',
    'pr',
    'one',
    'el',
    'ist',
    'Unt',
    'itled',
    'ĠIm',
    'plementation',
    'ist',
    'ors',
    'Ġtank',
    'er',
    'Ġpl',
    'ush',
    'Ġattend',
    'ants',
    'ĠT',
    'ik',
    'ĠGreen',
    'wich',
    'ĠY',
    'on',
    'ĠSP',
    'L',
    'cell',
    's',
    'unt',
    'led',
    'S',
    'olution',
    'ĠQu',
    'Ã©',
    'Ġvac',
    'ated',
    'Ġupt',
    'ick',
    'ĠMer',
    'idian',
    'æ',
    'ĥ',
    'ĠDr',
    'ill',
    '9',
    '25',
    '58',
    '4',
    'Ġrenov',
    'ated',
    'ĠKub',
    'rick',
    'zy',
    'k',
    'Ġl',
    'ousy',
    'pp',
    'el',
    'ohyd',
    'rate',
    'ĠI',
    'zzy',
    'lesi',
    'astical',
    'CC',
    'C',
    'ĠAj',
    'ax',
    'Ġad',
    'apters',
    'ĠPetra',
    'eus',
    'Ġaffirm',
    'ation',
    'ĠST',
    'OR',
    'le',
    'ms',
    'ad',
    'oes',
    'ĠConstantin',
    'ople',
    'Ġp',
    'onies',
    'Ġl',
    'ighthouse',
    'Ġadherent',
    's',
    'ĠBre',
    'es',
    'omorph',
    'ic',
    'Fight',
    'ing',
    'Ġpl',
    'aster',
    'ĠP',
    'VC',
    'ĠOb',
    'st',
    'Ġdear',
    'ly',
    'ĠTo',
    'oth',
    'icks',
    'on',
    'Ġsh',
    'aming',
    'P',
    'lex',
    'A',
    'gg',
    'ĠâĢ¦',
    '"',
    'Ġsub',
    'reddits',
    'Ġpige',
    'on',
    'ĠResident',
    'ial',
    'ĠPass',
    'ing',
    'Ġl',
    'um',
    'ĠP',
    'ension',
    'Ġpessim',
    'istic',
    'Ġ4',
    '32',
    'z',
    'inski',
    'c',
    'ade',
    '0',
    '75',
    'Ġapolog',
    'ised',
    'iy',
    'ah',
    'Put',
    'ting',
    'Ġgloom',
    'y',
    'ĠLy',
    'me',
    '=-=-=-=-',
    '=-=-=-=-',
    'ĠT',
    'ome',
    'ĠPsych',
    'iatric',
    'ĠH',
    'IT',
    'c',
    'ms',
    'ap',
    'olog',
    'Ġbreak',
    'er',
    'Ġdeep',
    'en',
    'Ġtheor',
    'ist',
    'ĠHigh',
    'lands',
    'Ġb',
    'aker',
    'Ġst',
    'aples',
    'Ġinterf',
    'ered',
    'ĠAb',
    'ortion',
    'jo',
    'ined',
    'ch',
    'u',
    'Ġform',
    'ulate',
    'Ġvacc',
    'inations',
    'Ġban',
    'ter',
    'phe',
    'us',
    'Ġoutfield',
    'er',
    'ĠM',
    'eter',
    'Ġ#',
    '####',
    'Ġ18',
    '95',
    'Ġnarrow',
    'ing',
    'ĠST',
    'ORY',
    'f',
    'p',
    'ĠC',
    'ST',
    'ign',
    'ore',
    'Ġproclaim',
    'ing',
    'ĠR',
    'U',
    'ĠB',
    'ALL',
    'yn',
    'a',
    '65',
    '3',
    'Ġpos',
    'it',
    'P',
    'RE',
    '59',
    '4',
    'ĠRegist',
    'rar',
    'ĠPil',
    'grim',
    'ic',
    'io',
    'Ġpre',
    'tt',
    'Ġlif',
    'eless',
    'Ġ__',
    '_',
    'Ne',
    'igh',
    'ĠCh',
    'urches',
    'orn',
    'o',
    'Ġor',
    'cs',
    'Ġkind',
    'red',
    'ĠAud',
    'it',
    'Ġmillenn',
    'ial',
    'ĠPers',
    'ia',
    'g',
    'ravity',
    'ĠDis',
    'ability',
    'ĠD',
    'ARK',
    'W',
    's',
    'od',
    'on',
    'Ġgrand',
    'daughter',
    'ĠBro',
    'oke',
    'ĠA',
    'DA',
    'ER',
    'A',
    'Ġpick',
    'ups',
    'ĠWil',
    'kinson',
    'ĠSh',
    'ards',
    'ĠN',
    'K',
    'Ġexp',
    'el',
    'ĠKis',
    'lyak',
    'Ġj',
    'argon',
    'Ġpolar',
    'ized',
    'ian',
    'e',
    'Pub',
    'lisher',
    'Ġreb',
    'utt',
    'Ġapprehens',
    'ion',
    'ĠK',
    'essler',
    'Ġpr',
    'ism',
    'F',
    'UL',
    '19',
    '64',
    'ĠL',
    'oll',
    'ä',
    '¿',
    'le',
    'thal',
    'Å',
    'Ł',
    'Ġg',
    'hetto',
    'Ġb',
    'oulder',
    'ĠSlow',
    'ly',
    'ĠOsc',
    'ars',
    'ĠInst',
    'ruction',
    'ĠUl',
    'tr',
    'ĠM',
    'oe',
    'N',
    'ich',
    'ĠP',
    'ATH',
    '(',
    '*',
    'ĠRE',
    'LEASE',
    'un',
    'ing',
    'rou',
    'se',
    'en',
    'eg',
    'Ġre',
    'imb',
    'ĠDet',
    'ected',
    'Do',
    'S',
    'Ġster',
    'ling',
    'Ġaggreg',
    'ation',
    'ĠLone',
    'ly',
    'ĠAtt',
    'end',
    'hig',
    'her',
    'Ġairst',
    'rike',
    'ks',
    'on',
    'SE',
    'LECT',
    'Ġdef',
    'lation',
    'ĠHer',
    'rera',
    'C',
    'ole',
    'rit',
    'ch',
    'Ġadvis',
    'able',
    'F',
    'ax',
    'Ġwork',
    'around',
    'Ġp',
    'id',
    'mort',
    'em',
    'ers',
    'en',
    'Ġtyp',
    'o',
    'Ġal',
    'um',
    '78',
    '2',
    'ĠJam',
    'al',
    'script',
    's',
    'Ġcapt',
    'ives',
    'ĠPres',
    'ence',
    'ĠLie',
    'berman',
    'angel',
    'o',
    'Ġalcohol',
    'ism',
    'ass',
    'i',
    'Ġrec',
    'ite',
    'Ġgap',
    'ing',
    'Ġbask',
    'ets',
    'ĠG',
    'ou',
    'Brow',
    'ser',
    'ne',
    'au',
    'Ġcorrect',
    'ive',
    'und',
    'a',
    'sc',
    'oring',
    'ĠX',
    'D',
    'Ġfil',
    'ament',
    'Ġdeep',
    'ening',
    'ĠStain',
    'less',
    'Int',
    'eger',
    'Ġbu',
    'ggy',
    'Ġten',
    'ancy',
    'ĠMub',
    'arak',
    'Ġt',
    'uple',
    'ĠD',
    'roid',
    'ĠS',
    'itting',
    'Ġforfe',
    'it',
    'ĠRasm',
    'ussen',
    'ixt',
    'ies',
    'es',
    'i',
    'ĠKim',
    'mel',
    'Ġmetic',
    'ulously',
    'Ġap',
    'opt',
    'ĠS',
    'eller',
    '08',
    '8',
    'ec',
    'ake',
    'hem',
    'atically',
    'T',
    'N',
    'Ġmind',
    'less',
    'Ġdig',
    's',
    'ĠAcc',
    'ord',
    'ons',
    'ense',
    'em',
    'ing',
    'br',
    'ace',
    'Ġe',
    'Book',
    'ĠDist',
    'ribut',
    'ĠInvest',
    'ments',
    'w',
    't',
    ']',
    '),',
    'beh',
    'avior',
    '56',
    '3',
    'Ġbl',
    'inding',
    'ĠPro',
    'testers',
    'top',
    'ia',
    'Ġreb',
    'orn',
    'ĠKel',
    'vin',
    'ĠDo',
    'ver',
    'ĠD',
    'airy',
    'ĠOut',
    's',
    'Ġ[',
    '/',
    'Ï',
    'Ģ',
    'b',
    'p',
    'ĠVan',
    'ity',
    'ĠRec',
    'ap',
    'ĠHOU',
    'SE',
    'ĠF',
    'ACE',
    'Ġ4',
    '22',
    '69',
    '2',
    'ĠAnt',
    'ioch',
    'cook',
    'ed',
    'Ġcoll',
    'ide',
    'Ġa',
    'pr',
    'Ġsle',
    'eper',
    'ĠJar',
    'vis',
    'Ġalternative',
    'ly',
    'ĠLe',
    'aves',
    'ĠM',
    'aw',
    'Ġantiqu',
    'ity',
    'ĠAdin',
    'ida',
    'Ġab',
    'user',
    'PokÃ©',
    'mon',
    'Ġass',
    'orted',
    'ĠRev',
    'ision',
    'ĠP',
    'iano',
    'ĠG',
    'ideon',
    'O',
    'cean',
    'Ġsal',
    'on',
    'Ġbust',
    'ling',
    'ogn',
    'itive',
    'ĠRah',
    'man',
    'Ġwa',
    'iter',
    'Ġpres',
    'ets',
    'ĠO',
    'sh',
    'ĠG',
    'HC',
    'oper',
    'ator',
    'Ġrept',
    'iles',
    'Ġ4',
    '13',
    'ĠG',
    'arr',
    'ĠCh',
    'ak',
    'Ġhas',
    'hes',
    'Ġfail',
    'ings',
    'Ġfolk',
    'lore',
    'Ġab',
    'l',
    'ĠC',
    'ena',
    'ĠMac',
    'Arthur',
    'ĠCOUR',
    'T',
    'Ġperipher',
    'y',
    'app',
    'ers',
    'Ġreck',
    'oned',
    'ĠInf',
    'lu',
    'ĠC',
    'ET',
    'Ġ3',
    '72',
    'ĠDefin',
    'itive',
    'ass',
    'ault',
    '4',
    '21',
    'Ġreservoir',
    's',
    'Ġd',
    'ives',
    'ĠCo',
    'il',
    'DA',
    'Q',
    'Ġvivid',
    'ly',
    'ĠR',
    'J',
    'ĠBel',
    'lev',
    'Ġec',
    'lectic',
    'ĠShow',
    'down',
    'ĠK',
    'M',
    'ip',
    'ed',
    'reet',
    'ings',
    'ĠAs',
    'uka',
    'L',
    'iberal',
    'ĠÏ',
    'Ħ',
    'Ġbystand',
    'ers',
    'ĠGood',
    'win',
    'uk',
    'ong',
    'S',
    'it',
    'ĠT',
    'rem',
    'Ġcrim',
    'inally',
    'ĠCirc',
    'us',
    'ch',
    'rome',
    '88',
    '7',
    'Ġnan',
    'op',
    'ĠOb',
    'i',
    'ĠL',
    'OW',
    'o',
    'gh',
    'ĠAuth',
    'ors',
    'ob',
    'yl',
    'Ur',
    'ban',
    'Ġt',
    'i',
    'ĠWe',
    'ir',
    't',
    'rap',
    'ag',
    'y',
    'Ġparent',
    'heses',
    'Ġout',
    'numbered',
    'Ġcounter',
    'productive',
    'ĠTob',
    'ias',
    'ub',
    'is',
    'P',
    'arser',
    'ST',
    'AR',
    'Ġsyn',
    'aptic',
    'ĠG',
    'ears',
    'Ġh',
    'iber',
    'Ġdebunk',
    'ed',
    'Ġex',
    'alted',
    'aw',
    'atts',
    'H',
    'OU',
    'Ch',
    'urch',
    'ĠPix',
    'ie',
    'ĠU',
    'ri',
    'ĠForm',
    'ation',
    'ĠPred',
    'iction',
    'C',
    'EO',
    'Ġthro',
    'tt',
    'ĠBrit',
    'ann',
    'ĠMad',
    'agascar',
    'ë',
    'ĭ',
    'Ġbill',
    'boards',
    'ĠRPG',
    's',
    'ĠBe',
    'es',
    'complete',
    'ly',
    'F',
    'IL',
    'Ġdoes',
    'nt',
    'ĠGreen',
    'berg',
    're',
    'ys',
    'Ġsl',
    'ing',
    'Ġempt',
    'ied',
    'ĠPix',
    'ar',
    'ĠDh',
    'arma',
    'l',
    'uck',
    'ingu',
    'ished',
    'Ġend',
    'ot',
    'Ġbab',
    'ys',
    '05',
    '9',
    'che',
    'st',
    'r',
    'ats',
    'Ġr',
    'idden',
    'Ġbeet',
    'les',
    'Ġillum',
    'inating',
    'Ġfict',
    'itious',
    'ĠProv',
    'incial',
    'Ġ7',
    '68',
    'Ġshe',
    'pherd',
    'ĠR',
    'ender',
    'Ġ18',
    '96',
    'C',
    'rew',
    'Ġmold',
    'ed',
    'ĠXia',
    'omi',
    'ĠSp',
    'iral',
    'Ġdel',
    'im',
    'Ġorgan',
    'ising',
    'Ġho',
    'ops',
    'ĠBe',
    'i',
    'z',
    'hen',
    'Ġfuck',
    'in',
    'Ġdec',
    'ad',
    'Ġun',
    'biased',
    'am',
    'my',
    'sw',
    'ing',
    'Ġsmugg',
    'led',
    'Ġk',
    'ios',
    'ĠP',
    'ERSON',
    'ĠInquis',
    'itor',
    'Ġsnow',
    'y',
    'Ġscrap',
    'ing',
    'ĠBurg',
    'ess',
    'P',
    'tr',
    'ag',
    'ame',
    'R',
    'W',
    'Ġdro',
    'id',
    'ĠL',
    'ys',
    'ĠCass',
    'andra',
    'Jac',
    'ob',
    'Ġ35',
    '4',
    'Ġpast',
    'ure',
    'Ġfr',
    'anc',
    'ĠScot',
    'ch',
    'ĠEnd',
    's',
    'ĠI',
    'GF',
    'def',
    'inition',
    'Ġhyster',
    'ical',
    'ĠBrown',
    'e',
    '77',
    '1',
    'Ġmobil',
    'ization',
    'æ',
    'ķ',
    'iqu',
    'eness',
    'Th',
    'or',
    'Ġspear',
    'headed',
    'Ġembro',
    'iled',
    'Ġconject',
    'ure',
    'jud',
    'icial',
    'Ch',
    'oice',
    'Ġpaper',
    'back',
    'P',
    'ir',
    'Ġrec',
    'overs',
    'ĠSur',
    'ge',
    'ĠSh',
    'ogun',
    'ĠPed',
    'iatrics',
    'ãģ',
    'ł',
    'Ġsweep',
    's',
    'ĠLabor',
    'atories',
    'ĠP',
    'acks',
    'al',
    'us',
    'add',
    'in',
    'Ġhead',
    'lights',
    'g',
    'ra',
    'Ev',
    'idence',
    'COL',
    'OR',
    'Ad',
    'min',
    'Ĭ',
    '±',
    'Ġconco',
    'ct',
    's',
    'ufficient',
    'Ġun',
    'marked',
    'Ġrich',
    'ness',
    'Ġdiss',
    'ertation',
    'Ġseason',
    'ing',
    'Ġg',
    'ib',
    'ĠM',
    'ages',
    'un',
    'ctions',
    'ĠN',
    'id',
    'che',
    'at',
    'ĠTM',
    'Z',
    'c',
    'itizens',
    'ĠCatholic',
    'ism',
    'n',
    'b',
    'Ġdisemb',
    'ark',
    'ĠPROG',
    'RAM',
    'a',
    'ques',
    'Ty',
    'ler',
    'Or',
    'g',
    'ĠSl',
    'ay',
    'ĠN',
    'ero',
    'ĠTown',
    'send',
    'IN',
    'TON',
    'te',
    'le',
    'Ġmes',
    'mer',
    '9',
    '01',
    'Ġfire',
    'ball',
    'ev',
    'idence',
    'aff',
    'iliated',
    'ĠFrench',
    'man',
    'ĠAugust',
    'a',
    '0',
    '21',
    'Ġs',
    'led',
    'Ġre',
    'used',
    'ĠImmun',
    'ity',
    'Ġwrest',
    'le',
    'assemb',
    'led',
    'Mar',
    'ia',
    'Ġgun',
    'shots',
    'ĠBarb',
    'ie',
    'Ġcannabin',
    'oids',
    'ĠTo',
    'ast',
    'ĠK',
    'inder',
    'IR',
    'D',
    'Ġre',
    'juven',
    'Ġg',
    'ore',
    'Ġrupt',
    'ure',
    'Ġbre',
    'aching',
    'ĠCart',
    'oon',
    'Ġ4',
    '55',
    'ĠPale',
    'o',
    '6',
    '14',
    'Ġspe',
    'ars',
    'ĠAm',
    'es',
    'ab',
    'us',
    'Mad',
    'ison',
    'GR',
    'OUP',
    'Ġab',
    'orted',
    'y',
    'ah',
    'Ġfel',
    'on',
    'Ġcaus',
    'ation',
    'Ġprep',
    'aid',
    'Ġp',
    'itted',
    'op',
    'lan',
    'ĠShel',
    'ley',
    'ĠRus',
    'so',
    'ĠP',
    'agan',
    'Ġwill',
    'fully',
    'ĠCan',
    'aver',
    'und',
    'rum',
    'ĠSal',
    'ary',
    'ĠAr',
    'paio',
    'read',
    'er',
    'ĠR',
    'ational',
    'ĠOver',
    'se',
    'ĠCa',
    'uses',
    'Ġ*',
    '.',
    'Ġw',
    'ob',
    'Ke',
    'ith',
    'ĠCons',
    'ent',
    'man',
    'ac',
    '77',
    '3',
    '6',
    '23',
    'Ġfate',
    'ful',
    'et',
    'imes',
    'Ġspir',
    'ited',
    'ĠD',
    'ys',
    'Ġhe',
    'gemony',
    'Ġboy',
    'cot',
    'ĠEn',
    'rique',
    'em',
    'outh',
    'Ġtim',
    'elines',
    'ĠSah',
    'ara',
    'ĠRel',
    'ax',
    'ĠQuin',
    'cy',
    'ĠLess',
    'ons',
    'ĠE',
    'QU',
    'SE',
    'A',
    'N',
    'K',
    'ĠCost',
    'co',
    'Incre',
    'ase',
    'Ġmotiv',
    'ating',
    'ĠCh',
    'ong',
    'am',
    'aru',
    'ĠDiv',
    'ide',
    'Ġped',
    'igree',
    'ĠTasman',
    'ia',
    'ĠPrel',
    'ude',
    'L',
    'as',
    '9',
    '40',
    '57',
    '4',
    'Ġch',
    'au',
    'ĠSp',
    'iegel',
    'un',
    'ic',
    '--',
    '>',
    'ĠPhil',
    'ips',
    'ĠKaf',
    'ka',
    'Ġuphe',
    'aval',
    'Ġsent',
    'imental',
    'Ġsa',
    'x',
    'ĠAk',
    'ira',
    'ser',
    'ial',
    'Mat',
    'rix',
    'Ġelect',
    'ing',
    'Ġcomment',
    'er',
    'ĠNeb',
    'ula',
    'ple',
    'ts',
    'ĠNad',
    'u',
    'ĠAd',
    'ren',
    'Ġen',
    'shr',
    'ĠR',
    'AND',
    'fin',
    'ancial',
    'ĠCly',
    'de',
    'uther',
    'ford',
    'Ġsign',
    'age',
    'Ġde',
    'line',
    'Ġphosph',
    'ate',
    'rovers',
    'ial',
    'f',
    'ascist',
    'ĠV',
    'all',
    'ĠBeth',
    'lehem',
    'Ġfor',
    's',
    'Ġeng',
    'lish',
    'S',
    'olid',
    'N',
    'ature',
    'Ġv',
    'a',
    'ĠGu',
    'ests',
    'Ġtant',
    'al',
    'Ġauto',
    'immune',
    ';;;;;;;;',
    ';;;;',
    'ĠTot',
    'ally',
    'ĠO',
    'v',
    'Ġdef',
    'ences',
    'ĠCoc',
    'onut',
    'Ġtranqu',
    'il',
    'Ġpl',
    'oy',
    'Ġflav',
    'ours',
    'ĠFl',
    'ask',
    'ãĤ¨',
    'ãĥ«',
    'ĠWest',
    'on',
    'ĠVol',
    'vo',
    '8',
    '70',
    'Ġmicro',
    'phones',
    'ver',
    'bal',
    'R',
    'PG',
    'Ġi',
    'ii',
    ';',
    '}',
    '0',
    '28',
    'Ġhead',
    'lined',
    'Ġprim',
    'ed',
    'Ġho',
    'ard',
    'ĠSh',
    'ad',
    'ĠEN',
    'TER',
    'Ġtri',
    'angular',
    'Ġcap',
    'it',
    'l',
    'ik',
    'ĠAn',
    'cients',
    'Ġl',
    'ash',
    'Ġconv',
    'ol',
    'Ġcolon',
    'el',
    'en',
    'emy',
    'G',
    'ra',
    'Ġpub',
    's',
    'ut',
    'ters',
    'Ġassign',
    's',
    'ĠPen',
    'et',
    'ĠMon',
    'strous',
    'ĠBow',
    'en',
    'il',
    'ver',
    'H',
    'aunted',
    'ĠD',
    'ing',
    'start',
    'ed',
    'pl',
    'in',
    'Ġcontamin',
    'ants',
    'ĠDO',
    'E',
    'ff',
    'en',
    'ĠTechn',
    'ician',
    'R',
    'y',
    'Ġrob',
    'bers',
    'Ġhot',
    'line',
    'ĠGuard',
    'iola',
    'ĠKau',
    'fman',
    'row',
    'er',
    'ĠDres',
    'den',
    'ĠAl',
    'pine',
    'E',
    'lf',
    'Ġf',
    'mt',
    'ĠS',
    'ard',
    'urs',
    'es',
    'g',
    'pu',
    'Un',
    'ix',
    'Ġunequiv',
    'ocally',
    'ĠCitizens',
    'hip',
    'qu',
    'ad',
    'm',
    'ire',
    'ĠS',
    'weeney',
    'B',
    'attery',
    '6',
    '15',
    'Ġpanc',
    'akes',
    'Ġo',
    'ats',
    'M',
    'aps',
    'ĠCont',
    'rast',
    'mbuds',
    'man',
    'ĠE',
    'PS',
    'Ġsub',
    'committee',
    'Ġsour',
    'cing',
    'Ġs',
    'izing',
    'ĠBuff',
    'er',
    'ĠMand',
    'atory',
    'Ġmoder',
    'ates',
    'ĠPattern',
    's',
    'ĠCh',
    'ocobo',
    'ĠZ',
    'an',
    'ĠSTAT',
    'ES',
    'ĠJud',
    'ging',
    'ĠIn',
    'her',
    '*',
    ':',
    'Ġb',
    'il',
    'ĠY',
    'en',
    'Ġexh',
    'ilar',
    'oll',
    'ower',
    'z',
    'ers',
    'Ġsn',
    'ug',
    'max',
    'imum',
    'Ġdesp',
    'icable',
    'ĠP',
    'ACK',
    'ĠAn',
    'nex',
    'Ġsarcast',
    'ic',
    'Ġlate',
    'x',
    'Ġt',
    'amp',
    'ĠS',
    'ao',
    'b',
    'ah',
    'ĠRe',
    'verend',
    'ĠChin',
    'atown',
    'ĠA',
    'UT',
    'd',
    'ocumented',
    'ĠGA',
    'BA',
    'ĠCan',
    'aan',
    'ĠÙ',
    'ħ',
    'Ġgovern',
    's',
    'pre',
    'v',
    'E',
    'sc',
    'ĠEst',
    'imates',
    'OS',
    'P',
    'Ġendeav',
    'our',
    'ĠCl',
    'osing',
    'omet',
    'ime',
    'every',
    'one',
    'Ġwor',
    'sen',
    'Ġsc',
    'anners',
    'Ġdev',
    'iations',
    'ĠRobot',
    'ics',
    'ĠCom',
    'pton',
    'Ġsorce',
    'rer',
    'Ġend',
    'ogenous',
    'Ġem',
    'ulation',
    'ĠPier',
    'cing',
    'ĠA',
    'ph',
    'ĠS',
    'ocket',
    'Ġb',
    'ould',
    'ĠO',
    'U',
    'ĠBorder',
    'lands',
    'Ġ18',
    '63',
    'G',
    'ordon',
    'ĠW',
    'TO',
    'Ġrestrict',
    's',
    'Ġmosa',
    'ic',
    'Ġmel',
    'odies',
    'ç',
    'Ħ',
    'T',
    'ar',
    'Ġdis',
    'son',
    'ĠProv',
    'ides',
    'Ġ',
    '......',
    'b',
    'ek',
    'F',
    'IX',
    'Ġbro',
    'om',
    'ans',
    'hip',
    'Do',
    'ctors',
    'Ġner',
    'ds',
    'ĠReg',
    'ions',
    'na',
    'issance',
    'Ġmet',
    'e',
    'Ġcre',
    'pt',
    'pl',
    'ings',
    'Ġgirlfriend',
    's',
    'kn',
    'it',
    'ig',
    'ent',
    'ow',
    'e',
    'Ġus',
    'hered',
    'ĠB',
    'az',
    'M',
    'obil',
    '4',
    '34',
    'ĠPres',
    'ents',
    'orig',
    'in',
    'Ġins',
    'omnia',
    'ĠA',
    'ux',
    '4',
    '39',
    'ĠCh',
    'ili',
    'irs',
    'ch',
    'G',
    'AME',
    'Ġgest',
    'ation',
    'alg',
    'ia',
    'rom',
    'ising',
    '$',
    ',',
    'c',
    'row',
    'ĠIn',
    'spection',
    'at',
    'omic',
    'Rel',
    'ations',
    'J',
    'OHN',
    'rom',
    'an',
    'ĠClock',
    'work',
    'ĠBak',
    'r',
    'm',
    'one',
    'M',
    'ET',
    'Ġthirst',
    'y',
    'Ġb',
    'c',
    'Ġfacult',
    'ies',
    'R',
    'um',
    'Ġnu',
    'ance',
    'ĠD',
    'arius',
    'ple',
    'ting',
    'fter',
    's',
    'etch',
    'up',
    'Reg',
    'istration',
    'ĠK',
    'E',
    'R',
    'ah',
    'Ġpref',
    'erential',
    'ĠL',
    'ash',
    'ĠH',
    'H',
    'Val',
    'id',
    'ĠN',
    'AV',
    'Ġstar',
    've',
    'ĠG',
    'ong',
    'z',
    'ynski',
    'ĠAct',
    'ress',
    'Ġw',
    'ik',
    'Ġun',
    'accompanied',
    'lv',
    'l',
    'Br',
    'ide',
    'AD',
    'S',
    'ĠCommand',
    'o',
    'ĠVaugh',
    'n',
    'Wal',
    'let',
    'Ġho',
    'pping',
    'ĠV',
    'ie',
    'Ġcave',
    'ats',
    'Ġal',
    'as',
    'if',
    'led',
    'ab',
    'use',
    '66',
    '1',
    'Ġib',
    'n',
    'Ġg',
    'ul',
    'Ġrob',
    'bing',
    't',
    'il',
    'IL',
    'A',
    'Ġmit',
    'igating',
    'Ġapt',
    'ly',
    'Ġty',
    'rant',
    'Ġmid',
    'day',
    'ĠGil',
    'more',
    'ĠDe',
    'cker',
    'ĠÂ§',
    'Â§',
    'part',
    'ial',
    'Ex',
    'actly',
    'Ġphen',
    'otype',
    'Ġ[+',
    ']',
    'ĠP',
    'lex',
    'ĠI',
    'ps',
    'vers',
    'ions',
    'Ġe',
    'book',
    'Ġch',
    'ic',
    'g',
    'ross',
    '":"',
    '"},{"',
    'ĠSur',
    'prisingly',
    'M',
    'organ',
    'Ġresid',
    'ues',
    'ĠConf',
    'ederation',
    'in',
    'feld',
    'Ġl',
    'yr',
    'mod',
    'erate',
    'Ġperpend',
    'icular',
    'V',
    'K',
    'Ġsynchron',
    'ized',
    'Ġrefres',
    'hed',
    'Ġad',
    'ore',
    'ĠTor',
    'ment',
    'ol',
    'ina',
    'Ġ26',
    '00',
    'Item',
    'Tracker',
    'Ġp',
    'ies',
    'ĠF',
    'AT',
    'ĠR',
    'HP',
    '0',
    '48',
    'ĠRES',
    'P',
    'ĠB',
    'J',
    'all',
    'ows',
    'P',
    'and',
    'Ġunw',
    'elcome',
    'ĠV',
    'oc',
    'ĠBast',
    'ard',
    'ĠO',
    'W',
    'ĠL',
    'AR',
    'ĠHeal',
    'er',
    'Environment',
    'al',
    'ĠKen',
    'yan',
    'ĠTr',
    'ance',
    'ĠP',
    'ats',
    'Ġali',
    'ases',
    'ĠGar',
    'field',
    'Ġcampaign',
    'er',
    'Ġadvance',
    'ments',
    'ĠOkin',
    'awa',
    'ĠC',
    'oh',
    'ows',
    'ky',
    'Ġstar',
    'ved',
    'Ġsize',
    'able',
    'Ġ:',
    '-)',
    'Ġm',
    'RNA',
    'Ġsusp',
    'ensions',
    'ist',
    'ar',
    'Scot',
    'land',
    'Pr',
    'in',
    '--------------------------------',
    '----------------',
    'Ġ50',
    '2',
    'Ġteasp',
    'oons',
    'Ġ10',
    '50',
    'Ġcoerc',
    'ive',
    'ĠMason',
    'ic',
    'edd',
    'ed',
    'ĠPass',
    'enger',
    'Ġl',
    'att',
    'Ġbr',
    'aces',
    'ĠSt',
    'eal',
    'ĠNY',
    'T',
    'ĠK',
    'ats',
    'ĠCel',
    'est',
    'ae',
    'z',
    'T',
    'u',
    'ĠCoul',
    'ter',
    'ðŁ',
    'ĺ',
    'Fl',
    'ickr',
    'ĠWil',
    'mington',
    'ith',
    's',
    '++',
    ';',
    'Ġv',
    'ending',
    'Ġneg',
    'ro',
    'ĠPh',
    'i',
    'ĠYellow',
    'stone',
    'Call',
    'back',
    'Ġsh',
    'ampoo',
    'ĠSh',
    'ades',
    'w',
    'at',
    'Ġsuper',
    'human',
    'Ġridic',
    'uled',
    'Ġhol',
    'iest',
    'om',
    'bo',
    'Ġintern',
    's',
    'Ġh',
    'one',
    'ĠPar',
    'agu',
    'UR',
    'I',
    'Ġd',
    'angling',
    'ãĤ',
    '»',
    'so',
    'v',
    'ict',
    'ional',
    'av',
    'ailability',
    'Ġrev',
    'ocation',
    'Ġd',
    'ow',
    'in',
    'ic',
    'ĠTHE',
    'IR',
    'Ġis',
    'o',
    'Ġout',
    'ings',
    'ĠLeth',
    'al',
    'Ġ)',
    '))',
    'Ġinacc',
    'ur',
    'Ġout',
    'landish',
    'Ġan',
    'us',
    'let',
    'ico',
    'id',
    'on',
    'l',
    'ol',
    'Ġun',
    'regulated',
    'Ġsuccumb',
    'ed',
    'Ġc',
    'uff',
    'ĠWast',
    'eland',
    'let',
    'al',
    'Ġsub',
    'str',
    'Ġcoff',
    'ers',
    'Ġautom',
    'akers',
    'ov',
    'i',
    'ĠX',
    'ue',
    'ĠDayton',
    'a',
    'Ġjar',
    'ring',
    'Ġf',
    'umes',
    'Ġdisband',
    'ed',
    'z',
    'ik',
    'itt',
    'on',
    'Ġstriking',
    'ly',
    'Ġsp',
    'ores',
    'Ad',
    'apter',
    '.)',
    ':',
    'ĠLynd',
    'on',
    'ival',
    'ry',
    'Ġor',
    'ally',
    'Ġtumult',
    'uous',
    'Ġdisple',
    'asure',
    'Ġcon',
    'es',
    'or',
    'rect',
    'Ġappe',
    'ase',
    'Ġder',
    'by',
    'ĠTrip',
    'oli',
    'ĠAl',
    'ess',
    'Ġp',
    'oked',
    'ĠGu',
    'ilty',
    'v',
    'P',
    'En',
    'ough',
    'Ġorig',
    'inals',
    '6',
    '99',
    'Ġrabb',
    'i',
    'Ġproverb',
    'ial',
    'Ġpostp',
    'one',
    'el',
    'ope',
    'ĠMist',
    'y',
    'Ġstaff',
    'ed',
    'ĠUn',
    'employment',
    'redit',
    'ary',
    'Ġdilig',
    'ent',
    're',
    'comm',
    'me',
    'asures',
    'as',
    'in',
    '8',
    '25',
    'Ġpond',
    's',
    'Ġmm',
    'ol',
    'ĠS',
    'AR',
    'ĠC',
    'ARE',
    'Ġ3',
    '71',
    'Ġclen',
    'ched',
    'ĠCors',
    'air',
    'Ġcaric',
    'ature',
    'z',
    'n',
    'att',
    'ach',
    'ĠSch',
    'ro',
    'spe',
    'ak',
    'p',
    'ainted',
    'ĠS',
    'uc',
    'ĠE',
    'NT',
    'Ġcell',
    'ul',
    'ĠP',
    'aid',
    'di',
    'agn',
    'WH',
    'ERE',
    'Ġtext',
    'ed',
    'B',
    'arn',
    'Ġret',
    'racted',
    'ĠRe',
    'ferred',
    'S',
    'av',
    'Ġup',
    'keep',
    'Ġwork',
    'places',
    'ĠTok',
    'ens',
    'Ġampl',
    'ify',
    'cl',
    'inical',
    'Ġmult',
    'ic',
    'mber',
    'g',
    'Ġconvol',
    'uted',
    'Reg',
    'ion',
    '5',
    '65',
    'ĠTop',
    'ic',
    'Ġsn',
    'ail',
    'Ġsal',
    'ine',
    'Ġins',
    'urrection',
    'ĠPet',
    'r',
    'f',
    'orts',
    'B',
    'AT',
    'ĠNav',
    'ajo',
    'Ġrud',
    'imentary',
    'ĠLak',
    'sh',
    'OND',
    'ON',
    'Me',
    'asure',
    'Ġtransform',
    'er',
    'ĠGodd',
    'ard',
    'Ġcoinc',
    'ides',
    'ir',
    'in',
    'R',
    'ex',
    'ĠB',
    'ok',
    'qu',
    'it',
    'Ġshotgun',
    's',
    'Ġprolet',
    'arian',
    'Ġsc',
    'orp',
    'ĠAd',
    'a',
    '5',
    '14',
    'Ġsl',
    'ander',
    'record',
    'ed',
    'Ġemb',
    'ell',
    'ris',
    'ome',
    'Ġapolog',
    'izing',
    'ĠMul',
    'cair',
    'ĠGib',
    'raltar',
    'Cl',
    'a',
    'Ġall',
    'ot',
    'ĠAtt',
    'ention',
    'Ġ4',
    '33',
    'le',
    'ave',
    'Ġwh',
    'ine',
    'ĠIss',
    'a',
    'ĠFa',
    'ust',
    'ĠBar',
    'ron',
    'hen',
    'y',
    'Ġvictim',
    'ized',
    'J',
    'ews',
    'Ġnurt',
    'uring',
    'ett',
    'el',
    'W',
    'inged',
    'ĠSub',
    'tle',
    'Ġflavor',
    'ful',
    'ĠRep',
    's',
    'eng',
    'ed',
    'call',
    'back',
    'Ġdirection',
    'al',
    'Ġcl',
    'asp',
    'ĠDirect',
    'ions',
    'plan',
    'et',
    'icult',
    'ure',
    'Hel',
    'per',
    'ic',
    'ion',
    'ac',
    'ia',
    'Ġç',
    '¥ŀ',
    'Ġsur',
    'ges',
    'Ġcan',
    'oe',
    'ĠPrem',
    'iership',
    'be',
    'en',
    'Ġdef',
    'ied',
    'ĠTro',
    'oper',
    'Ġtrip',
    'od',
    'Ġgas',
    'p',
    'ĠE',
    'uph',
    'ĠAd',
    's',
    'vern',
    'ight',
    'high',
    'ly',
    'R',
    'ole',
    'Ġent',
    'angled',
    'ĠZe',
    'it',
    '6',
    '18',
    'ĠRust',
    'y',
    'Ġhaven',
    's',
    'ĠVaugh',
    'an',
    'HA',
    'EL',
    'ĠSER',
    'VICE',
    '/',
    ',',
    'Ġstr',
    'icken',
    'Ġdel',
    'usions',
    'Ġb',
    'is',
    'ĠH',
    'af',
    'Ġgrat',
    'ification',
    'Ġent',
    'icing',
    'UN',
    'CH',
    'Ad',
    'ams',
    'ĠOL',
    'ED',
    'ĠBeet',
    'le',
    'Ġ18',
    '99',
    'ĠSO',
    'FTWARE',
    'ateg',
    'or',
    'V',
    'L',
    'ĠTot',
    'em',
    'ĠG',
    'ators',
    'AT',
    'URES',
    'Ġimped',
    'ance',
    'Reg',
    'istered',
    'ĠC',
    'ary',
    'ĠAer',
    'ial',
    'on',
    'ne',
    'en',
    'ium',
    'Ġd',
    'red',
    'ĠBe',
    'g',
    'Ġconcurrent',
    'ly',
    'Ġsuper',
    'power',
    'ĠX',
    'an',
    'j',
    'ew',
    'imes',
    'ter',
    'ĠDick',
    'inson',
    'âĶ',
    'ģ',
    'F',
    'la',
    'Ġp',
    'ree',
    'ĠRoll',
    'ins',
    '©',
    '¶æ',
    'Ġden',
    'omination',
    'ĠL',
    'ana',
    '5',
    '16',
    'Ġinc',
    'iting',
    'sc',
    'ribed',
    'j',
    'uries',
    'ĠWond',
    'ers',
    'app',
    'roximately',
    'Ġsusp',
    'ending',
    'Ġmountain',
    'ous',
    'ĠL',
    'augh',
    'oid',
    'al',
    'N',
    's',
    'Det',
    'ect',
    ')',
    '=',
    'ĠL',
    'uthor',
    'ĠSchwarz',
    'enegger',
    'ĠMull',
    'er',
    'ĠDev',
    'i',
    'ec',
    'ycle',
    'J',
    'ar',
    '6',
    '13',
    'ĠL',
    'ongh',
    'B',
    'ah',
    'ĠSP',
    'ORTS',
    'n',
    'w',
    'Ġref',
    'inement',
    'Ġwater',
    'ways',
    'Ġd',
    'iner',
    'Bl',
    'ade',
    '68',
    '3',
    'F',
    'ac',
    'Ġinitial',
    's',
    'Ġro',
    'g',
    'Ġparan',
    'ormal',
    'B',
    'UT',
    'Ġ[',
    '(',
    'ĠSw',
    'anson',
    'ĠM',
    'esh',
    'âĸ',
    '¬',
    'Impro',
    've',
    'ĠRad',
    'iation',
    'ĠEst',
    'her',
    'ĠE',
    'sk',
    'ĠA',
    'ly',
    'ik',
    'y',
    'Ġir',
    'rad',
    'ĠBuck',
    'ingham',
    'Ġref',
    'ill',
    'Ġ.',
    '_',
    'Re',
    'pe',
    'CON',
    'CLUS',
    'Ġdifferent',
    'iated',
    'Ġchi',
    'rop',
    'ĠAt',
    'kins',
    'Pat',
    'tern',
    'Ġexc',
    'ise',
    'Ġcab',
    'al',
    'N',
    'SA',
    'ĠST',
    'A',
    'ĠS',
    'IL',
    'ĠPar',
    'aly',
    'Ġr',
    'ye',
    'ĠHow',
    'ell',
    'ĠCount',
    'down',
    'ness',
    'es',
    'alys',
    'ed',
    'Ġres',
    'ize',
    'ãĤ',
    '½',
    'Ġbudget',
    'ary',
    'ĠStr',
    'as',
    'w',
    'ang',
    'Ġap',
    'iece',
    'Ġprecinct',
    's',
    'Ġpe',
    'ach',
    'Ġsky',
    'line',
    'Ġ35',
    '3',
    'pop',
    'ular',
    'App',
    'earances',
    'ĠMechan',
    'ics',
    'ĠDev',
    'Online',
    'S',
    'ullivan',
    'Z',
    'en',
    'Ġp',
    'u',
    'op',
    'olis',
    '5',
    '44',
    'Ġde',
    'form',
    'Ġcounter',
    'act',
    'ĠL',
    'ange',
    'Ġ4',
    '17',
    'Con',
    'sole',
    '77',
    '4',
    'Ġnodd',
    'ing',
    'Ġpopul',
    'ism',
    'Ġhe',
    'p',
    'Ġcoun',
    'selling',
    'compl',
    'iance',
    'U',
    'FF',
    'Ġunden',
    'iably',
    'Ġrail',
    'ing',
    'ĠHor',
    'owitz',
    'ĠSim',
    'one',
    'ĠBung',
    'ie',
    'Ġa',
    'k',
    'ĠTal',
    'ks',
    'x',
    'ff',
    'fl',
    'ake',
    'Cr',
    'ash',
    'Ġsweat',
    'y',
    'Ġban',
    'quet',
    'ĠOFF',
    'IC',
    'Ġinvent',
    'ive',
    'Ġastron',
    'omer',
    'ĠStam',
    'ford',
    'ĠSc',
    'are',
    'ĠGRE',
    'EN',
    'olic',
    'ited',
    'Ġr',
    'usher',
    'Ġcent',
    'rist',
    'ight',
    'ing',
    'Ġsub',
    'class',
    'Ġdis',
    'av',
    'Ġdef',
    'und',
    'ĠN',
    'anto',
    'oci',
    'ate',
    'm',
    'ast',
    'Ġpac',
    'if',
    'Ġm',
    'end',
    'e',
    'ers',
    'imm',
    'igration',
    'ESS',
    'ION',
    'Ġnumber',
    'ing',
    'Ġlaugh',
    'able',
    'ĠEnd',
    'ed',
    'v',
    'iation',
    'em',
    'ark',
    'P',
    'itt',
    'Ġmetic',
    'ulous',
    'ĠL',
    'F',
    'Ġcongrat',
    'ulated',
    'ĠBir',
    'ch',
    'Ġsway',
    'ed',
    'Ġsemif',
    'inals',
    'Ġhum',
    'ankind',
    'm',
    'atter',
    'ĠEqu',
    'ip',
    'opa',
    'usal',
    'S',
    'aid',
    'ĠLay',
    'out',
    'Ġvo',
    'icing',
    'Ġth',
    'ug',
    'Ġporn',
    'ographic',
    'I',
    'PS',
    'Ġmo',
    'aning',
    'Ġgriev',
    'ance',
    'Ġconf',
    'essions',
    'esc',
    'al',
    'TEXT',
    'URE',
    'Aut',
    'hent',
    'os',
    'aurus',
    'P',
    'urchase',
    'Ġreleg',
    'ation',
    'al',
    'ter',
    'ĠÂł',
    'Âł',
    'Ġr',
    'iddled',
    'Ġo',
    'gre',
    'ĠLow',
    'ell',
    'Occ',
    'up',
    'E',
    'at',
    'ĠHy',
    'der',
    'ĠAdvis',
    'er',
    'Com',
    'merce',
    'H',
    'unt',
    'ĠOr',
    'th',
    'ĠComp',
    'etitive',
    'ĠCL',
    'A',
    'CD',
    'C',
    'Ġsal',
    'ads',
    'F',
    'le',
    'Ġindustrial',
    'ized',
    '`',
    ',',
    'ĠO',
    'WN',
    'Ġbec',
    'k',
    'ĠPart',
    'icularly',
    'oub',
    't',
    'Ġm',
    'M',
    'ĠHuss',
    'ain',
    'ĠChen',
    'nai',
    'Ġ9',
    '20',
    'Ġappoint',
    'ing',
    'ĠCull',
    'en',
    ',,,,',
    ',,,,',
    'Ġp',
    'ores',
    'ver',
    'ified',
    'Ġbi',
    'ochemical',
    'em',
    'ate',
    'Ġcoward',
    'ly',
    'ĠHels',
    'inki',
    'ĠEthiop',
    'ian',
    'S',
    'OURCE',
    'ER',
    'C',
    'est',
    'ro',
    'Ġbi',
    'otech',
    'ĠS',
    'our',
    'Ġbrew',
    'er',
    'Bloom',
    'berg',
    'Ġintens',
    'ify',
    'Gl',
    'ass',
    'an',
    'co',
    'ĠF',
    'DR',
    'gre',
    'SQL',
    'ĠF',
    'ires',
    '©¶æ',
    '¥µ',
    'ec',
    'o',
    '100',
    '1',
    'ĠHom',
    'eless',
    'Ġinstant',
    'aneous',
    'ĠH',
    'aste',
    'ig',
    'el',
    'D',
    'iamond',
    'Ġp',
    'aving',
    'Ġland',
    'fill',
    'Ġd',
    'ads',
    'h',
    'oun',
    ':',
    ']',
    'Ġinc',
    'endiary',
    'ĠLiving',
    'ston',
    'ĠHil',
    'bert',
    'ĠChe',
    'cks',
    'st',
    'yles',
    'in',
    'ators',
    'ĠCl',
    'ive',
    'ph',
    'rine',
    'Ġchimpan',
    'zees',
    'Ġp',
    'all',
    'ĠJ',
    'M',
    'ĠAad',
    'haar',
    'ð',
    'Ŀ',
    'Ġachie',
    'vable',
    'dis',
    'abled',
    'P',
    'ET',
    'OOOO',
    'OOOO',
    'M',
    'ot',
    'Ġint',
    'angible',
    'Ġbal',
    'let',
    'ĠWe',
    'bs',
    'ĠEst',
    'imated',
    'Effect',
    's',
    'Ġb',
    'ailed',
    'Josh',
    'ua',
    'Ġturb',
    'ulence',
    'Ġoccup',
    'ant',
    'ĠDay',
    'light',
    'Ġ36',
    '1',
    'me',
    'et',
    'Ġstat',
    'ically',
    'Ġon',
    'look',
    'Ġk',
    'i',
    'il',
    'legal',
    'Ġvel',
    'vet',
    'Ġdehyd',
    'ration',
    'Ġacqu',
    'ies',
    'ĠRe',
    'z',
    'ak',
    'ura',
    'ĠU',
    'pton',
    'at',
    'ro',
    'Ġincomp',
    'rehensible',
    'Ġback',
    'door',
    'ĠRh',
    'ino',
    '7',
    '27',
    'Ġmath',
    's',
    ')',
    '+',
    'Ġhe',
    'resy',
    'Ġd',
    'f',
    'ĠRoc',
    'he',
    'ĠL',
    'ydia',
    'Ġpanc',
    'reat',
    're',
    'ply',
    'arre',
    'll',
    'Ġsolicit',
    'ation',
    'Ġcirc',
    'adian',
    'BI',
    'P',
    'Ġfor',
    'ay',
    'Ġcrypt',
    'ic',
    'iz',
    'u',
    'ime',
    'o',
    'ĠTom',
    'ato',
    'ĠH',
    'oms',
    'ex',
    'amination',
    'Ġqu',
    'arry',
    'ĠVal',
    'iant',
    'ĠJer',
    'icho',
    'ĠIN',
    'CLUD',
    'Ġ18',
    '40',
    '5',
    '19',
    'Ġres',
    'ists',
    'Ġsnap',
    'shots',
    'ĠSp',
    'ur',
    'ĠAnt',
    'iqu',
    'Log',
    'in',
    'Ġbest',
    'selling',
    'Ġant',
    'ic',
    'ĠS',
    'utherland',
    'ãĤ¢',
    'ãĥ«',
    'Ġ~',
    '/',
    'ĠP',
    'arm',
    'è',
    'ĥ',
    'P',
    'ages',
    'int',
    'ensity',
    'Ġimm',
    'obil',
    'Ġ18',
    '65',
    'zz',
    'o',
    'Ġn',
    'ifty',
    'Ġf',
    'entanyl',
    'ĠPres',
    'ervation',
    'op',
    'hen',
    'Ġd',
    'arts',
    'ĠD',
    'inosaur',
    'po',
    'inters',
    'ĠR',
    'ite',
    's',
    'uggest',
    'aware',
    'ness',
    'ĠSher',
    'idan',
    'Ġst',
    'ances',
    'Ġsor',
    'cery',
    'Ġper',
    'jury',
    'ĠNik',
    'ola',
    'ie',
    'ver',
    'Ġf',
    'iance',
    'ĠJordan',
    'ian',
    'ĠBall',
    'oon',
    'Ġn',
    'ab',
    'Ġk',
    'b',
    'Ġhuman',
    'ities',
    'ĠTan',
    'aka',
    'hill',
    'ary',
    'Ġconsult',
    'ancy',
    'ĠZ',
    'ub',
    'Ġrem',
    'ission',
    'Ġconf',
    'id',
    'CH',
    'Q',
    'ĠF',
    'ug',
    'Ġimpro',
    'vis',
    'Y',
    'ep',
    '/',
    '_',
    'Ġunwilling',
    'ness',
    'Ġport',
    'folios',
    '05',
    '5',
    'ĠInstruct',
    'or',
    'aim',
    'an',
    'Ġclaim',
    'ants',
    'M',
    'bps',
    'ĠBy',
    'e',
    're',
    'ceived',
    'T',
    'weet',
    'Ġind',
    'emn',
    'ri',
    'z',
    'am',
    'ara',
    'N',
    'at',
    'Ġeval',
    'uates',
    'ĠL',
    'ur',
    'ep',
    'ad',
    'FO',
    'X',
    'ĠTh',
    'ro',
    'Ġrust',
    'y',
    'Ġbed',
    'rock',
    'ĠOp',
    'rah',
    'J',
    'B',
    'Ġmanip',
    'ulative',
    'Ġwill',
    'ful',
    'Ġrel',
    'apse',
    'Ġext',
    'ant',
    'The',
    'me',
    'S',
    'ensor',
    'ĠSt',
    'ability',
    'go',
    'vern',
    'Ġpo',
    'ppy',
    'Ġkn',
    'ack',
    'Ġins',
    'ulated',
    'ĠT',
    'ile',
    'ĠExt',
    'rem',
    'Ġunt',
    'old',
    'Ġconver',
    'ge',
    'Ġref',
    'uel',
    'ig',
    'roup',
    'Ġdistort',
    'ions',
    'Ġrav',
    'aged',
    'Ġmechan',
    'ically',
    'ĠRe',
    'illy',
    'ĠN',
    'ose',
    'ĠIncarn',
    'ation',
    'ĠBeck',
    'y',
    'abb',
    'ling',
    'Ġt',
    'aco',
    'Ġr',
    'ake',
    'Ġmelanch',
    'oly',
    'Ġillust',
    'rious',
    'ĠDart',
    'mouth',
    'Gu',
    'ide',
    'ĠR',
    'azer',
    'ĠBen',
    'z',
    'Ult',
    'imate',
    'ĠSur',
    'prise',
    'Ġpage',
    'ant',
    'off',
    'er',
    'Who',
    'ever',
    'Ġw',
    'iser',
    'Ġchem',
    'ist',
    'ĠHE',
    'LL',
    'ĠBul',
    'k',
    'Ġpl',
    'utonium',
    'ĠCO',
    'VER',
    'Ö',
    '¼',
    'f',
    'ailed',
    'Ġtire',
    'lessly',
    'Ġinf',
    'ertility',
    'ĠTr',
    'ident',
    'ĠShow',
    'time',
    'ĠC',
    'iv',
    'V',
    'ice',
    'requ',
    'ires',
    'itt',
    'ance',
    'Ġun',
    'controlled',
    'interest',
    'ing',
    '56',
    '1',
    'Ġinnov',
    'ate',
    'ateg',
    'ic',
    'L',
    'ie',
    'ĠS',
    'elling',
    'U',
    'l',
    'Ġsav',
    'ior',
    'ĠT',
    'osh',
    'Ġsw',
    'ast',
    'P',
    'ASS',
    'Ġr',
    'ink',
    'Ġcard',
    'io',
    'ĠI',
    'ro',
    'ud',
    'i',
    'Ġv',
    'antage',
    'Ġv',
    'ans',
    'ĠNi',
    'Ã±o',
    '+',
    '=',
    'Ġpropag',
    'ate',
    '<',
    '?',
    'Ġmethod',
    'ological',
    '204',
    '39',
    'Ġtrig',
    'lycer',
    'Ġing',
    'rained',
    'ĠAn',
    'notations',
    'arr',
    'anted',
    '6',
    '17',
    'ĠS',
    'odium',
    'ĠA',
    'AC',
    'techn',
    'ical',
    'mult',
    'ipl',
    'Ġ3',
    '73',
    'å',
    'ĭ',
    'Ġdec',
    'isively',
    'Ġboost',
    'ers',
    'Ġdessert',
    's',
    'ĠGren',
    'ade',
    'Ġtest',
    'ifying',
    'ĠSc',
    'ully',
    'ID',
    's',
    'Ġlock',
    'down',
    'ĠSc',
    'her',
    'ĠR',
    'Ã©',
    'ĠWhit',
    'man',
    'ĠRams',
    'ay',
    'rem',
    'ote',
    'Ġh',
    'ikers',
    'ĠHy',
    'undai',
    'Ġcons',
    'cientious',
    'Ġcler',
    'ics',
    'ĠSiber',
    'ian',
    'ut',
    'i',
    'is',
    'bury',
    'Ġrel',
    'ayed',
    'Ġqu',
    'artz',
    'ĠC',
    'BI',
    'seek',
    'ers',
    'ull',
    'a',
    'Ġweld',
    'ing',
    'ĠSh',
    'al',
    'ble',
    'acher',
    'T',
    'ai',
    'ĠSam',
    'son',
    'Ġt',
    'umble',
    'ĠInvest',
    'or',
    'Ġsub',
    'contract',
    'ĠShin',
    'ra',
    'ow',
    'icz',
    'j',
    'andro',
    'd',
    'ad',
    'Ġtermin',
    'ating',
    'ĠNe',
    'ural',
    'ä»',
    '£',
    'Ġleak',
    'age',
    'ĠMid',
    'lands',
    'ĠCaucas',
    'us',
    'í',
    'ķ',
    'c',
    'it',
    'll',
    'an',
    'iv',
    'ably',
    'ĠAlb',
    'ion',
    'Ġ4',
    '57',
    'Ġregist',
    'rations',
    'Ġcomr',
    'ade',
    'Ġclip',
    'board',
    '0',
    '47',
    'Ġdiscour',
    'aging',
    'ĠO',
    'ops',
    'Ad',
    'apt',
    'Ġem',
    'path',
    'n',
    'v',
    'ĠPR',
    'OT',
    'ĠDon',
    'n',
    'ĠP',
    'ax',
    'ĠB',
    'ayer',
    't',
    'is',
    'Squ',
    'are',
    'Ġfoot',
    'prints',
    'part',
    'icip',
    'ĠChile',
    'an',
    'B',
    'rend',
    'ind',
    'ucing',
    'M',
    'agn',
    'Ġclub',
    'house',
    'ĠMagn',
    'um',
    'Ġenc',
    'amp',
    'ĠEth',
    'nic',
    'uch',
    'a',
    'ere',
    'y',
    'Ġw',
    'atered',
    'ĠCal',
    'ais',
    'Ġcomplex',
    'ion',
    'Ġsect',
    's',
    'Ġren',
    'ters',
    'Ġbr',
    'as',
    'oÄŁ',
    'an',
    'Time',
    'out',
    'Man',
    'agement',
    'Ġinf',
    'ographic',
    'P',
    'okemon',
    'Cl',
    'ar',
    'Ġloc',
    'ality',
    'Ġfl',
    'ora',
    'as',
    'el',
    'P',
    'ont',
    'Ġpop',
    'ulate',
    'ĠO',
    'ng',
    'Ġsubs',
    'istence',
    'Ġa',
    'uctions',
    'ĠMcA',
    'uliffe',
    'ĠL',
    'OOK',
    'br',
    'inger',
    'Ġtit',
    'an',
    'Ġmanif',
    'old',
    'ĠâĹ',
    'ı',
    'Ġcalibr',
    'ated',
    'Ġcal',
    'iphate',
    'ĠSH',
    'E',
    'ĠCommission',
    'ers',
    'ce',
    'ivable',
    'j',
    'c',
    'W',
    'inner',
    '5',
    '24',
    'Ġcond',
    'one',
    'Other',
    'wise',
    'Ġp',
    'iling',
    'Ġem',
    'body',
    'ĠCrime',
    'an',
    'ut',
    'ics',
    'ĠEx',
    'hibition',
    'Ġ4',
    '26',
    'e',
    'ering',
    'Ġv',
    'ying',
    'ĠH',
    'UGE',
    '*',
    '=-',
    'Ġprin',
    'cipled',
    'à',
    '¦',
    'Ġquir',
    'ks',
    'ĠEdit',
    'ors',
    'put',
    'ing',
    'G',
    'ES',
    'ĠF',
    'TA',
    'à¤',
    '¾',
    'add',
    'on',
    'ĠH',
    'AM',
    'ĠFrie',
    'za',
    'W',
    'oman',
    '.',
    '$',
    'Ġc',
    'rib',
    'ĠHer',
    'od',
    'Ġtim',
    'ers',
    'ĠSp',
    'aces',
    'ĠMac',
    'intosh',
    'at',
    'aka',
    'Ġgl',
    'ide',
    'Ġsmell',
    'ing',
    'ĠB',
    'AL',
    'Ġun',
    'su',
    'Ġcond',
    'os',
    'Ġbicy',
    'cl',
    'ĠRev',
    'ival',
    '55',
    '3',
    'Ġjugg',
    'ling',
    'H',
    'ug',
    'ĠKardash',
    'ian',
    'ĠBalk',
    'ans',
    'mult',
    'iple',
    'Ġnutrit',
    'ious',
    'oc',
    'ry',
    '19',
    '00',
    'Ġinteg',
    'rates',
    'Ġad',
    'joining',
    'ĠF',
    'older',
    'roll',
    'ment',
    'ven',
    'ient',
    'Ġu',
    'ber',
    'y',
    'i',
    'Ġwh',
    'iff',
    'ĠJu',
    'ven',
    'ĠB',
    'orough',
    'net',
    'te',
    'Ġb',
    'ilingual',
    'ĠSp',
    'arks',
    'ph',
    'thal',
    'man',
    'ufact',
    'Ġt',
    'outing',
    'ĠPH',
    'I',
    'Ke',
    'efe',
    'Rew',
    'ard',
    'Ġinf',
    'all',
    'ĠTem',
    'per',
    'typ',
    'ically',
    'ĠNik',
    'ol',
    'Ġregular',
    's',
    'Ġpseud',
    'onym',
    'Ġexhib',
    'itions',
    'Ġbl',
    'aster',
    'Ġ40',
    '9',
    'w',
    'arming',
    'Ġrever',
    'ber',
    'Ġrecip',
    'rocal',
    'Ġ6',
    '70',
    'ip',
    'ient',
    'b',
    'ett',
    'ĠBe',
    'gins',
    'Ġit',
    'ching',
    'ĠPh',
    'ar',
    'Ass',
    'uming',
    'Ġem',
    'itting',
    'ĠML',
    'G',
    'Ġbirth',
    'place',
    'Ġt',
    'aunt',
    'ĠL',
    'uffy',
    'ĠAm',
    'it',
    'Ġcir',
    'cled',
    'ĠN',
    'ost',
    'enn',
    'ett',
    'Ġde',
    'forestation',
    'ĠHist',
    'orically',
    'ĠEvery',
    'day',
    'Ġovert',
    'ake',
    '79',
    '2',
    'Ġn',
    'un',
    'ĠLuc',
    'ia',
    'Ġaccompan',
    'ies',
    'ĠSe',
    'eking',
    'ĠTr',
    'ash',
    'an',
    'ism',
    'R',
    'ogue',
    'Ġnorth',
    'western',
    'ĠSupplement',
    'al',
    'ĠNY',
    'U',
    'ĠF',
    'RI',
    'ĠSat',
    'isf',
    'x',
    'es',
    '5',
    '17',
    'Ġreass',
    'ured',
    'Ġspor',
    'adic',
    'Ġ7',
    '01',
    'Ġmed',
    'ial',
    'Ġcannabin',
    'oid',
    'Ġbarbar',
    'ic',
    'Ġep',
    'is',
    'ĠExplos',
    'ive',
    'ĠD',
    'ough',
    'Ġuns',
    'olved',
    'Support',
    'ed',
    'Ġacknowled',
    'gment',
    'sp',
    'awn',
    'Ġkit',
    'chens',
    'Ġ-',
    '=',
    'talk',
    'ing',
    'ic',
    'ist',
    'ĠPeg',
    'asus',
    'ĠPS',
    'U',
    'Ġphot',
    'on',
    'ĠAuthent',
    'ication',
    'R',
    'G',
    '@#',
    '&',
    '76',
    '2',
    'ĠCl',
    'air',
    'Ġdi',
    'aper',
    'Ġbr',
    'ist',
    'ĠProsecut',
    'ors',
    'ĠJ',
    'em',
    '6',
    '28',
    'ĠEvery',
    'where',
    'ĠJean',
    'ne',
    'equ',
    'ality',
    'ãĥ©',
    'ãĥ³',
    'object',
    's',
    'ĠPel',
    'icans',
    'Ġ39',
    '2',
    'Ġbl',
    'u',
    'b',
    'ys',
    'ĠA',
    'go',
    'Ġinstruction',
    'al',
    'Ġdiscrim',
    'inating',
    'ĠTR',
    'AN',
    'ĠCorn',
    'el',
    'ag',
    'os',
    'Ġty',
    're',
    'Ġas',
    'piration',
    'ĠBrid',
    'gewater',
    '":',
    '-',
    '!',
    '".',
    'ĠEn',
    's',
    'ĠCoc',
    'o',
    'P',
    'ie',
    'Ġdet',
    'ach',
    'ĠC',
    'ouch',
    'Ġphys',
    'ique',
    'ĠOccup',
    'ations',
    'osc',
    'opic',
    'en',
    'ough',
    'B',
    'uzz',
    'App',
    'earance',
    'Y',
    'P',
    'Ġrac',
    'er',
    'Ġcompl',
    'icity',
    'r',
    'pm',
    'T',
    'oy',
    'Ġinterrupt',
    's',
    'ĠCat',
    'alyst',
    'Ġut',
    'ilitarian',
    'imp',
    'act',
    'Ġsp',
    'aghetti',
    'Ġp',
    'orous',
    'Ġeste',
    'emed',
    'Ġinc',
    'iner',
    'ĠI',
    'OC',
    '7',
    '48',
    'Ġesp',
    'resso',
    'ĠSm',
    'ile',
    'abil',
    'ia',
    '6',
    '35',
    'Ġmathematic',
    'ian',
    'Ġ4',
    '24',
    'ĠK',
    'L',
    'ĠH',
    'IP',
    'Ġover',
    'heard',
    'ĠT',
    'ud',
    'ĠT',
    'ec',
    'Ġqu',
    'izz',
    'Ġfl',
    'attering',
    'Ġcon',
    'n',
    'âĢ',
    'İ',
    'Ġatt',
    'aches',
    'ĠR',
    'OS',
    'ĠAC',
    'S',
    'Ġt',
    'cp',
    'ĠSh',
    'ame',
    'sk',
    'ip',
    'res',
    'pected',
    'ĠTrin',
    'idad',
    'gr',
    'ain',
    'Ġfooth',
    'old',
    'ĠUnch',
    'arted',
    'ĠJul',
    'io',
    'z',
    'l',
    'av',
    'ored',
    'ĠAn',
    'xiety',
    'er',
    'rors',
    'ĠCent',
    'auri',
    'its',
    'ch',
    'D',
    'addy',
    'Ġclutch',
    'ing',
    'ĠIm',
    'plement',
    'ĠGut',
    'ierrez',
    'Ġ7',
    '60',
    'Ġtele',
    'portation',
    'end',
    'ra',
    'Ġrevers',
    'ible',
    'st',
    'ros',
    'Ad',
    'venture',
    '08',
    '3',
    'Ġliber',
    'ating',
    'Ġas',
    'phalt',
    'ĠSp',
    'end',
    'AR',
    'DS',
    'im',
    'sy',
    'PR',
    'ES',
    'ĠEmer',
    'ging',
    'Ġwild',
    'fires',
    'Ġtechn',
    'ologically',
    'Ġem',
    'its',
    'ĠART',
    'ICLE',
    'Ġirregular',
    'ities',
    'Ġcher',
    'ish',
    'çī',
    'Ī',
    'Ġst',
    'ink',
    'ĠR',
    'ost',
    'Econom',
    'ic',
    'Ġcough',
    'ing',
    'ĠMcC',
    'ann',
    'pro',
    'perties',
    'ilant',
    'ro',
    'Ġreneg',
    'oti',
    'Trans',
    'lation',
    'Ġin',
    'quest',
    'ĠGra',
    'pe',
    'oot',
    'ers',
    'gu',
    'i',
    'ĠSwords',
    'man',
    'ace',
    'ae',
    'h',
    'itting',
    'Ġr',
    'c',
    'Ġexert',
    'ed',
    'ĠS',
    'AP',
    'it',
    'ent',
    'Ġperil',
    'ous',
    'Ġobsc',
    'urity',
    'Ġassass',
    'inate',
    'Ġab',
    'original',
    'Ġresc',
    'uing',
    'ĠSh',
    'attered',
    'lock',
    'ing',
    'all',
    'ion',
    'Ch',
    'anging',
    'ĠHar',
    'rington',
    'ĠB',
    'ord',
    'ĠAfgh',
    'ans',
    'Jam',
    'ie',
    'aret',
    'z',
    'ĠAugust',
    'us',
    'Ġ38',
    '6',
    '8',
    '30',
    'Ġj',
    'og',
    'ok',
    'ingly',
    'Tr',
    'igger',
    'ĠH',
    'OR',
    'Stat',
    'istics',
    'Ġviewers',
    'hip',
    'Ġadd',
    'itives',
    'h',
    'ur',
    'Ġmaxim',
    'izing',
    'ĠR',
    'ove',
    'ĠLou',
    'ie',
    'ĠBuck',
    'et',
    'ĠCHR',
    'IST',
    'ou',
    'sel',
    'Ġstre',
    'aks',
    'ir',
    'ted',
    'Ġt',
    'ert',
    'Ġcolonial',
    'ism',
    'Ġbur',
    'ying',
    'y',
    'k',
    'Cond',
    'ition',
    'ĠDPR',
    'K',
    'By',
    'Id',
    '75',
    '1',
    'âĹ',
    '¼',
    'Ġwor',
    'risome',
    'Ġvoc',
    'ational',
    'sl',
    'ice',
    'Ġsa',
    'ils',
    'ĠCorrection',
    'al',
    '95',
    '4',
    'Ġt',
    'ul',
    'K',
    'id',
    'l',
    'uster',
    'Ġfam',
    'ilial',
    'ĠSp',
    'it',
    'ĠEp',
    'iscopal',
    'Specific',
    'ally',
    'ĠVol',
    'cano',
    'run',
    's',
    'q',
    's',
    'Ġve',
    'tted',
    'Ġcram',
    'med',
    't',
    'rop',
    'here',
    'r',
    'Thank',
    'fully',
    'Ġper',
    'cussion',
    'Ġor',
    'anges',
    'Ġround',
    'up',
    'Ġ4',
    '99',
    'x',
    'ious',
    'Char',
    'acters',
    'ĠZion',
    'ism',
    'ĠR',
    'ao',
    'ÃĽ',
    'ÃĽ',
    'W',
    'F',
    'Ġunintention',
    'al',
    'ONE',
    'Y',
    'Gr',
    'ab',
    'Com',
    'mercial',
    'Ġglut',
    'amate',
    'ĠMcK',
    'enna',
    'ru',
    'ciating',
    'ning',
    'ton',
    'ih',
    'u',
    'Ch',
    'an',
    'ĠSw',
    'ap',
    'Ġleaf',
    'lets',
    'Ġfunction',
    'ally',
    'er',
    'ous',
    'F',
    'arm',
    'Ġcal',
    'oric',
    'ĠLiter',
    'ally',
    'con',
    'cert',
    'Ġshe',
    'nan',
    'Ġrep',
    'aid',
    'ey',
    'es',
    'Ġbas',
    'hing',
    'ĠG',
    'orge',
    'Ġcollabor',
    'ations',
    'Ġun',
    'account',
    'itch',
    'ie',
    'Ġteam',
    'work',
    'pp',
    'elin',
    'Ġpip',
    'ing',
    'Ġmin',
    'ced',
    'Ġd',
    'iam',
    'ri',
    'eg',
    'Ġmasc',
    'ara',
    'Ġsuck',
    'er',
    'ĠMo',
    'ons',
    'App',
    's',
    'ĠPe',
    'ck',
    'Ġper',
    'v',
    'ĠFl',
    'oat',
    'o',
    'ley',
    'ĠN',
    'ish',
    'im',
    'ize',
    'Ġarom',
    'atic',
    'u',
    'in',
    'end',
    'ish',
    '!',
    '/',
    'ĠB',
    'icycle',
    'ĠAS',
    'IC',
    'ile',
    'ged',
    'ĠQuad',
    'ro',
    'ios',
    'yn',
    'Ġlock',
    'out',
    'ĠW',
    'ink',
    'SP',
    'EC',
    'Attempt',
    's',
    'Ġseed',
    'ed',
    'red',
    'o',
    'ias',
    'is',
    'Ġsn',
    'ag',
    'ãĥķ',
    'ãĤ©',
    'ãĤ',
    '¶',
    'Ġground',
    'ing',
    'Ġrelie',
    'ver',
    'Ġfrivol',
    'ous',
    'ĠG',
    'ifts',
    'ĠF',
    'aces',
    'Es',
    'pecially',
    'Ġmicrobi',
    'ome',
    'im',
    'ag',
    'ĠSch',
    'l',
    'ĠP',
    'les',
    'ĠBle',
    'ach',
    'ĠIr',
    'win',
    'ĠE',
    'aton',
    'ĠDisc',
    'iple',
    'Ġmultipl',
    'ication',
    'Ġcoer',
    'ced',
    'Ġ4',
    '19',
    'st',
    'h',
    'E',
    'vil',
    'B',
    'omb',
    'Ġex',
    'orc',
    'Ġstag',
    'gered',
    'L',
    'ESS',
    'Ġinert',
    'ia',
    'ĠED',
    'IT',
    'Ġgo',
    'b',
    'Tr',
    'aditional',
    'Ġclass',
    'y',
    'Lear',
    'y',
    'ĠP',
    'AGE',
    'yr',
    's',
    'Ġtrans',
    'porter',
    'Ġmat',
    'ured',
    'Ġhij',
    'ab',
    'Ġbi',
    'ome',
    'Where',
    'as',
    'Ġex',
    'termination',
    'ĠT',
    'ues',
    'ĠT',
    'akeru',
    'ĠAud',
    'rey',
    'er',
    'ial',
    'ĠAd',
    'en',
    'aff',
    'les',
    'Ġnarciss',
    'istic',
    'ĠB',
    'aird',
    'UT',
    'F',
    'I',
    're',
    'ĠCon',
    'nie',
    'Ch',
    'amp',
    'Ġwhis',
    'pering',
    'ĠH',
    'att',
    'D',
    'K',
    'Ġdis',
    'infect',
    'Ġdeduct',
    'ed',
    'Ġpart',
    'ake',
    'Ġdown',
    'grade',
    'ĠEs',
    'ports',
    'ĠContin',
    'uing',
    'Ġdemocr',
    'atically',
    'icro',
    'bial',
    'itt',
    'a',
    'Ġlim',
    'estone',
    'Ġexempt',
    'ed',
    'ĠFren',
    'zy',
    'H',
    'erm',
    '7',
    '28',
    'Ġfled',
    'gling',
    'Met',
    'a',
    '765',
    '61',
    '69',
    '3',
    '%',
    ':',
    'w',
    'ake',
    '5',
    '26',
    'ĠDis',
    'cipline',
    'Ġvirgin',
    'ity',
    'ĠLeg',
    'ions',
    'ĠFrank',
    'ie',
    'int',
    'ent',
    'Ġrest',
    'rooms',
    'ĠRou',
    'ter',
    'da',
    'q',
    'Ġobjection',
    'able',
    'âĨ',
    'ĳ',
    'w',
    'ark',
    'ĠRah',
    'ul',
    'g',
    'ain',
    'activ',
    'ation',
    'abs',
    'olute',
    'ĠAccess',
    'ed',
    'Ġ24',
    '00',
    'ogg',
    'les',
    'Ġsecond',
    'ly',
    'ĠDEF',
    'ENSE',
    'Ġpost',
    'age',
    'wra',
    'pper',
    'sh',
    'arp',
    '7',
    '29',
    'Ġcommun',
    'icates',
    'Ġadd',
    'on',
    'ĠMil',
    'itia',
    'H',
    'ong',
    'Ġsl',
    'umped',
    'ĠJP',
    'EG',
    'ĠI',
    'car',
    'ad',
    'ish',
    '68',
    '1',
    'Ġmaj',
    'esty',
    'ĠWolf',
    'gang',
    'ĠEl',
    'astic',
    'u',
    'per',
    'Ġv',
    'iz',
    'Ġunconscious',
    'ly',
    'ĠST',
    'D',
    'ĠS',
    'ass',
    'Ġflower',
    'ing',
    'ĠHel',
    'ic',
    'ĠDra',
    'per',
    'ĠAm',
    'ateur',
    'Ġman',
    'ure',
    'Ġdis',
    'ingen',
    'ĠLe',
    'i',
    'br',
    'ing',
    '9',
    '49',
    'Ġinhib',
    'ited',
    'Ġhead',
    'quartered',
    'Ġen',
    'igmatic',
    'ï¿½ï¿½',
    'ï¿½',
    'Ġred',
    'ress',
    'R',
    'H',
    'Ġratt',
    'led',
    'Ġd',
    'iction',
    'l',
    'io',
    'ĠT',
    'BA',
    'ĠSN',
    'AP',
    'C',
    'alling',
    'Ġfasc',
    'ists',
    'ĠD',
    'ove',
    'iew',
    'icz',
    '0',
    '36',
    'Ġco',
    'asts',
    'ĠR',
    'ect',
    'Ġ)',
    ']',
    'L',
    'ot',
    '6',
    '29',
    'ĠS',
    'EM',
    'ĠPeters',
    'en',
    'ĠExpl',
    'ain',
    'ĠBo',
    'ards',
    'ĠBe',
    'zos',
    'ĠJ',
    'ournals',
    'Ġ20',
    '24',
    'p',
    'arser',
    'Ġmist',
    'rust',
    'Ġgr',
    'ate',
    'ĠL',
    'ocked',
    'bo',
    'a',
    'S',
    'aint',
    'g',
    'aming',
    'Ġvow',
    'el',
    'in',
    'ately',
    'bl',
    'ow',
    'All',
    'ah',
    'Ġun',
    'matched',
    'Ġb',
    'ordering',
    'ĠExp',
    'end',
    'n',
    'r',
    'Or',
    'acle',
    'rou',
    'ch',
    'Ġcont',
    'iguous',
    'ac',
    'us',
    'Ġdist',
    'raught',
    '58',
    '1',
    'Ġanat',
    'omical',
    'O',
    'X',
    'ap',
    'ixel',
    '8',
    '33',
    'ĠPL',
    'US',
    'Ġres',
    'usc',
    'Ġab',
    'iding',
    '57',
    '3',
    'Ġvac',
    'ancies',
    'Em',
    'ily',
    'Ġhyp',
    'othal',
    'ĠWer',
    'ner',
    'ĠWe',
    'e',
    'ĠDJ',
    's',
    '5',
    '13',
    'Ġwitch',
    'craft',
    'Ġac',
    'upuncture',
    'ent',
    'ary',
    'benef',
    'it',
    'Product',
    's',
    'ĠP',
    'SP',
    'ĠMP',
    'G',
    'ĠJ',
    'inn',
    'ĠJ',
    'arrett',
    'Ġ4',
    '45',
    'ĠIm',
    'aging',
    'ĠP',
    'yth',
    'Fin',
    'ish',
    'Ġte',
    'x',
    'Ġjuven',
    'iles',
    'Ġhero',
    'ism',
    'Ġdoubt',
    'less',
    'ĠA',
    'ki',
    'ĠT',
    'end',
    'ĠPatri',
    'arch',
    'Ġbit',
    'ters',
    'ĠTele',
    'communications',
    'it',
    'atively',
    'ag',
    'na',
    'Ġr',
    'g',
    'ĠS',
    'OLD',
    'Ġcomp',
    'ulsion',
    'ĠN',
    'asa',
    'ĠKath',
    'ryn',
    'Ġmillion',
    'aires',
    'Ġintrins',
    'ically',
    'Ġbolst',
    'ered',
    'time',
    'out',
    'fl',
    'o',
    'Ġtut',
    'or',
    'p',
    'our',
    'Stat',
    'ement',
    'Ġ{',
    '*',
    'ĠRud',
    'olph',
    'ĠKimber',
    'ly',
    'rog',
    'ens',
    'adi',
    'q',
    ']',
    '+',
    'Ġindign',
    'ation',
    'Ġfract',
    'uring',
    'ĠRe',
    'leases',
    'ĠGr',
    'ain',
    'pro',
    'tein',
    'L',
    'ago',
    'Ġvac',
    'ations',
    'Ġboot',
    'ed',
    'ĠTH',
    'REE',
    'ĠH',
    'G',
    'oresc',
    'ence',
    'Ġt',
    'f',
    'Ġso',
    'ar',
    'iosyn',
    'cr',
    'Ġgl',
    'ances',
    'ĠSp',
    'oon',
    'ĠJ',
    'ury',
    'ĠCow',
    'boy',
    'Ġcreat',
    'ively',
    'Hig',
    'her',
    'Ġsolic',
    'itor',
    'Ġhaw',
    'k',
    'ac',
    'io',
    '89',
    '6',
    'Ġsuperf',
    'lu',
    'Ġbombs',
    'hell',
    'ct',
    'ure',
    'Ġbroker',
    'age',
    'Ġraid',
    'ing',
    'Ġf',
    'rench',
    'Ġang',
    'led',
    'Trans',
    'action',
    'ĠGen',
    'ocide',
    'u',
    'pe',
    'ĠHait',
    'ian',
    '57',
    '2',
    '!',
    ':',
    'Ġunwitting',
    'ly',
    'iter',
    'ator',
    'sc',
    'roll',
    'Ġtall',
    'ied',
    'Ġbi',
    'omedical',
    'ĠC',
    'ARD',
    'Ġe',
    'uphem',
    'Ġbrain',
    'storm',
    'a',
    'quin',
    'K',
    'o',
    'Mic',
    'helle',
    'ĠR',
    'unes',
    'ĠBall',
    'istic',
    'ud',
    'ers',
    'Ġmod',
    'esty',
    'ĠiP',
    'ads',
    'ĠEzek',
    'iel',
    'Y',
    'E',
    'Ġstars',
    'hip',
    'Ġpower',
    'fully',
    'Ġper',
    'l',
    'ĠSh',
    'ade',
    'ĠQu',
    'art',
    'ĠE',
    'EG',
    'Ġfisher',
    'man',
    'OS',
    'ED',
    'ĠTyp',
    'ical',
    'df',
    'x',
    'Ġmes',
    'hes',
    'Ġet',
    'ched',
    'worth',
    'iness',
    'Ġtopp',
    'led',
    'Ġ3',
    '96',
    'or',
    'ius',
    'We',
    'iss',
    'Ġmy',
    'sql',
    'ĠVal',
    'halla',
    'Ù',
    'Ĵ',
    'le',
    'asing',
    'Ġrec',
    'omp',
    'rap',
    'nel',
    'S',
    'el',
    '04',
    '3',
    'Ġder',
    'ailed',
    'ĠGu',
    'ides',
    'IR',
    'T',
    'Ġde',
    'human',
    'ĠBritt',
    'any',
    '"',
    '))',
    'Ġex',
    'claim',
    'Ġb',
    'alk',
    'Ġ8',
    '40',
    'CLA',
    'IM',
    'int',
    'el',
    'L',
    'AB',
    'Ġpe',
    'gged',
    'Ġast',
    'roph',
    'sm',
    'oking',
    'Ġrig',
    'ging',
    'Ġfix',
    'ation',
    'Ġcat',
    'apult',
    'ins',
    'ide',
    'ĠC',
    'ascade',
    'ĠBolshe',
    'vik',
    'G',
    'aza',
    'Dep',
    'th',
    'Ġloud',
    'spe',
    'Ġalmond',
    's',
    'me',
    'yer',
    'l',
    'eness',
    'j',
    'en',
    'f',
    'resh',
    'Ġunbeat',
    'en',
    'ĠSqu',
    'id',
    'ĠPres',
    'umably',
    'Tim',
    'er',
    'B',
    'W',
    'Ġro',
    'sters',
    'Ġell',
    'ipt',
    'ĠHar',
    'riet',
    'dat',
    'abase',
    'ĠMut',
    'ual',
    'ĠComm',
    'odore',
    'uk',
    'ed',
    'kn',
    'ife',
    'ĠCOMM',
    'UN',
    'h',
    'ya',
    'Ġmel',
    'ts',
    'arch',
    'ives',
    'Ġrat',
    'ification',
    'Ġmultip',
    'lying',
    'Ġinter',
    'oper',
    'Ġasc',
    'ert',
    'w',
    'ings',
    'ver',
    'ting',
    'ĠScorp',
    'ion',
    'ay',
    'e',
    'ĠPorts',
    'mouth',
    'ĠM',
    'TA',
    'n',
    'it',
    'iaz',
    'ep',
    'Ġqu',
    'arantine',
    'Ġslides',
    'how',
    'Ġcent',
    'imeters',
    'Ġsyn',
    'opsis',
    'Ġsp',
    'ate',
    'th',
    'irst',
    'Ġnom',
    'inating',
    'ĠMel',
    'vin',
    'Pre',
    'view',
    'Ġthro',
    'b',
    'Ġgener',
    'ational',
    'ĠRad',
    'ius',
    'rest',
    'ling',
    'put',
    'able',
    'aw',
    'ar',
    'N',
    'ECT',
    'Ġunlaw',
    'fully',
    'ĠRevel',
    'ations',
    'Wik',
    'ipedia',
    'sur',
    'v',
    'Ġeye',
    'ing',
    'ij',
    'n',
    'ĠF',
    'W',
    'Ġbr',
    'unt',
    'Ġinter',
    'stellar',
    'Ġcl',
    'itor',
    'ĠCroat',
    'ian',
    'ĠCh',
    'ic',
    'ev',
    'a',
    'ĠDis',
    'app',
    'ĠA',
    'kin',
    'iner',
    'ies',
    'd',
    'ust',
    'Interest',
    'ed',
    'Ġgen',
    'esis',
    'ĠE',
    'ucl',
    'Ã¶',
    'n',
    'p',
    'icking',
    'Ġmut',
    'ated',
    'Ġdisappro',
    've',
    'ĠHD',
    'L',
    'Ġ6',
    '25',
    'Ì',
    '¶',
    'c',
    'ancer',
    'Ġsqu',
    'ats',
    'Ġle',
    'vers',
    'Disc',
    'uss',
    '=',
    ']',
    'D',
    'ex',
    'ĠVIDE',
    'OS',
    'A',
    'UD',
    'Ġtrans',
    'act',
    'ĠKin',
    'ect',
    'ĠK',
    'uala',
    'ĠC',
    'yp',
    '7',
    '47',
    'Ġsh',
    'attering',
    'Ġarsen',
    'ic',
    'ĠInt',
    'ake',
    'ĠAngel',
    'o',
    'ĠQu',
    'it',
    'ĠK',
    'he',
    'Ġ18',
    '93',
    'M',
    'aker',
    '0',
    '29',
    'ĠPain',
    'ting',
    'Dis',
    'able',
    '9',
    '16',
    'Ġanal',
    'ges',
    'Ġtact',
    'ile',
    'Ġprop',
    'hes',
    'Ġd',
    'iced',
    'ĠTravel',
    's',
    'ĠHe',
    'ader',
    'ĠClub',
    's',
    'Ass',
    'istant',
    'Ġinc',
    'rim',
    'Ġd',
    'ips',
    'Ġcruc',
    'ifix',
    'ĠShan',
    'ahan',
    'ĠInter',
    'pret',
    'Ġ40',
    '90',
    'al',
    'ogy',
    'abb',
    'a',
    'Ġsimul',
    'ac',
    'hus',
    'band',
    'S',
    'IM',
    'Ġrecy',
    'cle',
    'uc',
    'er',
    'ed',
    'ged',
    'Ġre',
    'naissance',
    'ĠBomb',
    'ay',
    'Cath',
    'olic',
    'ĠL',
    'INE',
    'ĠCl',
    'othing',
    're',
    'ports',
    'Ġpl',
    'aus',
    'Ġd',
    'ag',
    'ĠM',
    'ace',
    'Z',
    'I',
    'Ġintr',
    'uder',
    'ĠVeter',
    'inary',
    'g',
    'ru',
    'Ġsne',
    'aky',
    'ĠS',
    'ie',
    'ĠC',
    'innamon',
    'P',
    'OSE',
    'Ġcou',
    'rier',
    'ĠC',
    'NS',
    'Ġemanc',
    'ipation',
    's',
    'it',
    'Ġplay',
    'through',
    'ĠFac',
    'ilities',
    'v',
    'irt',
    'ĠG',
    'auntlet',
    'Thom',
    'pson',
    'Ġunbeliev',
    'ably',
    'Param',
    'eters',
    'Ġst',
    'itching',
    'ign',
    'e',
    'ĠTH',
    'ESE',
    'Priv',
    'acy',
    'Ġshenan',
    'igans',
    'Ġvit',
    'ri',
    'ĠVal',
    'id',
    '59',
    '1',
    'Ń',
    '·',
    'ĠProt',
    'otype',
    'ink',
    'a',
    'SC',
    'P',
    'ĠT',
    'id',
    'è',
    'Ī',
    'old',
    'ed',
    'Ġindividual',
    'ity',
    'Ġbark',
    'ing',
    'Ġm',
    'ars',
    'ĠW',
    'D',
    'Ġ8',
    '20',
    'Ġt',
    'ir',
    'Ġsl',
    'apping',
    'Ġdisgr',
    'untled',
    'ĠAng',
    'ola',
    'ri',
    'us',
    'ĠTorn',
    'ado',
    'ĠTh',
    'urs',
    'Ġcapt',
    'cha',
    'Ġang',
    'st',
    'ĠP',
    'og',
    'ĠAssass',
    'ins',
    'ĠAd',
    'idas',
    'Ġjoy',
    'ful',
    'Ġwh',
    'ining',
    'Emer',
    'gency',
    'Ġphosph',
    'orus',
    'Ġatt',
    'rition',
    'oph',
    'on',
    'ĠTimber',
    'wolves',
    'ĠJ',
    'ah',
    'ĠBr',
    'inging',
    'ĠW',
    'ad',
    'ĠEn',
    'sure',
    'oh',
    'l',
    'ĠX',
    'ie',
    'omm',
    'el',
    'c',
    'mp',
    'Ġz',
    'ipper',
    'Ġrel',
    'at',
    'ĠCor',
    'ridor',
    'm',
    'ilo',
    'T',
    'ING',
    'Av',
    'g',
    'Ġcro',
    'pped',
    ']',
    '}',
    'Ġr',
    'aged',
    'ĠLump',
    'ur',
    'ĠGuer',
    'rero',
    'our',
    'ke',
    'N',
    'ut',
    'Ġoff',
    'sets',
    'og',
    'lu',
    'dr',
    'm',
    'Ġmort',
    'als',
    'lat',
    'able',
    'Ġdismiss',
    'ive',
    'ä¸',
    'ī',
    'Ġthro',
    'ats',
    'Ġchips',
    'et',
    'ĠSpot',
    'light',
    'Catal',
    'og',
    'art',
    'ist',
    'G',
    'b',
    'Ġch',
    'illy',
    'Ġst',
    'oked',
    'Ġ3',
    '74',
    'W',
    'ard',
    'L',
    'atin',
    'Ġf',
    'iasco',
    'Ġble',
    'ach',
    'Ġb',
    'rav',
    'Enh',
    'anced',
    'Ġin',
    'oc',
    'ĠFior',
    'ina',
    '_',
    '>',
    'Ġle',
    'ukemia',
    'Ġel',
    'uc',
    'Ġannoun',
    'cer',
    'ĠLith',
    'uan',
    'ĠArm',
    'ageddon',
    'å',
    'ĩ',
    'Len',
    'in',
    'ĠR',
    'uk',
    'Ġpe',
    'pp',
    'ĠRom',
    'antic',
    'ĠP',
    'IT',
    'ĠInter',
    'stellar',
    'ĠAt',
    'kinson',
    'R',
    'aid',
    'J',
    's',
    'Go',
    'al',
    'C',
    'ourse',
    'Ġvan',
    'ishing',
    'es',
    'ley',
    'ĠR',
    'ounds',
    'Els',
    'a',
    '59',
    '3',
    'Ġredund',
    'ancy',
    'ĠST',
    'AND',
    'Ġprop',
    'hetic',
    'Ġhabit',
    'able',
    'ry',
    'u',
    'Ġfaint',
    'ly',
    'M',
    'ODE',
    'Ġfl',
    'anked',
    'IR',
    'C',
    'Aw',
    'esome',
    'Ġsp',
    'urious',
    'ĠZ',
    'ah',
    'ĠMS',
    'G',
    'Ġsh',
    'ading',
    'Ġmotiv',
    'ational',
    'ĠSant',
    'ana',
    'ĠS',
    'PR',
    'Ġexc',
    'ruciating',
    'om',
    'ial',
    'ĠM',
    'iko',
    'ĠLe',
    'opard',
    'A',
    'byss',
    'Ġ[',
    '|',
    'd',
    'irty',
    'Ġbath',
    's',
    'Ġdem',
    'oral',
    'and',
    're',
    'P',
    'B',
    'Ġun',
    'ification',
    'Ġsac',
    'rament',
    'Ġ[',
    '&',
    'Ġpric',
    'eless',
    'Ġgel',
    'atin',
    'Ġeman',
    'ating',
    'ĠAll',
    'aah',
    '98',
    '6',
    'Ġout',
    'burst',
    'Ġer',
    'as',
    'ĠX',
    'VI',
    'ĠSP',
    'I',
    'O',
    'tt',
    'ĠLaz',
    'arus',
    'PL',
    'IED',
    'F',
    'lying',
    'blog',
    's',
    'W',
    'isconsin',
    'R',
    'aven',
    'Ġreb',
    'ate',
    'Ġcreep',
    's',
    'ĠSp',
    'an',
    'ĠPain',
    'ter',
    'ĠKir',
    'a',
    'ĠAm',
    'os',
    'ĠCor',
    'vette',
    'Cons',
    'umer',
    'ĠRec',
    'over',
    'ck',
    'i',
    'Ġpes',
    'ky',
    'ĠIn',
    'vention',
    'Compan',
    'ies',
    'Ġchalleng',
    'ers',
    'ad',
    'emic',
    'ĠUkrain',
    'ians',
    'ĠNeuro',
    'log',
    'ĠFors',
    'aken',
    'Ġent',
    'rants',
    'Ġemb',
    'attled',
    'Ġdef',
    'unct',
    'ĠGlac',
    'ier',
    'Ġpo',
    'isons',
    'ĠH',
    'orses',
    'm',
    'akes',
    'ĠD',
    'irt',
    'Ġ4',
    '23',
    'hh',
    'h',
    'ĠTrans',
    'formation',
    'QUI',
    'RE',
    '................',
    '..',
    'Ġtrave',
    'ller',
    'ĠSe',
    'xy',
    'ĠK',
    'ern',
    'ip',
    'olar',
    'Ġransom',
    'ware',
    'oooooooo',
    'oooooooo',
    'E',
    'c',
    'rub',
    'y',
    'Prof',
    'essional',
    'ĠOut',
    'break',
    'arg',
    'ument',
    'G',
    'rey',
    'ĠFif',
    'a',
    'ĠCH',
    'O',
    'ĠFOR',
    'M',
    'ĠAm',
    'trak',
    '-',
    '[',
    'Ġcr',
    'adle',
    'Ġantioxid',
    'ants',
    'ãģ®å',
    '®',
    '7',
    '36',
    'ĠNAS',
    'L',
    'ĠContribut',
    'ions',
    'Ind',
    'iana',
    'ĠST',
    'EP',
    'C',
    'SS',
    'Ġsal',
    'ient',
    'Ġall',
    'ocations',
    'yr',
    'ights',
    'Ġm',
    'ashed',
    'ĠCut',
    'ter',
    'Sex',
    'ual',
    'Ġp',
    'ounded',
    'Ġfan',
    'base',
    'Ġc',
    'asc',
    'ĠTrans',
    'parency',
    'Ġanaly',
    'tic',
    'ĠSummon',
    'er',
    '×',
    'ŀ',
    'ĠAD',
    'C',
    'det',
    'ail',
    'Ġvan',
    'quished',
    'Ġcr',
    'abs',
    'ar',
    'ie',
    'Dest',
    'roy',
    'ĠS',
    'ack',
    'Ġtrans',
    'istor',
    'Al',
    'abama',
    'ĠK',
    'oen',
    'ĠFisher',
    'ies',
    'c',
    'one',
    'Ġannex',
    'ed',
    'ĠM',
    'GM',
    'es',
    'a',
    'Ġf',
    'aked',
    'ĠCong',
    'ratulations',
    'Ġhind',
    'ered',
    'Ġcorrection',
    'al',
    'ĠI',
    'TV',
    'lee',
    've',
    'Ġin',
    'appropriately',
    'lic',
    'ks',
    'Ġtresp',
    'ass',
    'Ġp',
    'aws',
    'Ġnegoti',
    'ator',
    'ĠChrist',
    'ensen',
    'lim',
    'its',
    'ĠDian',
    'ne',
    'Ġeleg',
    'ance',
    'ĠContract',
    's',
    'an',
    'ke',
    'Ob',
    'j',
    'Ġvigil',
    'ance',
    'Ġcast',
    'les',
    'ĠN',
    'AD',
    'ĠHol',
    'o',
    'Ġemph',
    'atically',
    'ĠTit',
    'us',
    'ĠServ',
    'ing',
    'ĠRich',
    'ie',
    'ĠP',
    'igs',
    '5',
    '68',
    'Ġanim',
    'osity',
    'ĠAtt',
    'ributes',
    'ĠU',
    'riel',
    'M',
    'Q',
    'my',
    'ra',
    'ĠApplic',
    'ant',
    'Ġpsychiat',
    'rists',
    'ĠV',
    'ij',
    'ĠAb',
    'by',
    'ag',
    'ree',
    'P',
    'ush',
    'Ġk',
    'Wh',
    'hib',
    'a',
    'Ġinc',
    'ite',
    'ĠWe',
    'asley',
    'ĠTax',
    'i',
    'minist',
    'ic',
    'hy',
    'per',
    'ĠF',
    'arn',
    'Ġ6',
    '01',
    'ĠNation',
    'wide',
    'F',
    'ake',
    '95',
    '2',
    'Ġma',
    'ize',
    'Ġinteract',
    'ed',
    'Ġtransition',
    'ed',
    'Ġparas',
    'itic',
    'Ġharm',
    'onic',
    'Ġdec',
    'aying',
    'Ġbas',
    'eless',
    'ns',
    'ics',
    'Ġtrans',
    'pired',
    'Ġabund',
    'antly',
    'ĠFore',
    'nsic',
    'Ġtread',
    'mill',
    'ĠJ',
    'av',
    'ab',
    'and',
    'Ġssh',
    'd',
    'Ġfront',
    'man',
    'ĠJak',
    'arta',
    'oll',
    'er',
    'dro',
    'ps',
    'ĠSERV',
    'ICES',
    'rompt',
    'u',
    'oph',
    'ical',
    'h',
    'ospital',
    'bled',
    'on',
    '6',
    '45',
    'Ġmid',
    'range',
    'ĠEV',
    'ENT',
    'cul',
    'ated',
    'raw',
    'led',
    'Ġper',
    'ched',
    'Ġover',
    'board',
    'ĠPe',
    'el',
    'ĠP',
    'wr',
    'ĠCar',
    'th',
    'ĠCOM',
    'PLE',
    'co',
    'e',
    'sh',
    'all',
    'Ġdeter',
    'rence',
    'M',
    'ETHOD',
    'ĠAbs',
    'ent',
    'M',
    'EN',
    'Ġs',
    'ill',
    'ĠLE',
    'VEL',
    'Y',
    'ork',
    'Ġsin',
    'ners',
    'ĠOP',
    'EC',
    'ĠN',
    'ur',
    'ĠDesign',
    's',
    'se',
    'lection',
    'Ġunw',
    'orthy',
    'CH',
    'A',
    'Ġstreng',
    'thens',
    '88',
    '3',
    'ed',
    'ly',
    'Ġslic',
    'ing',
    'Ġmal',
    'nutrition',
    'Ġfilm',
    'making',
    'ĠPol',
    'k',
    'ur',
    'ated',
    'Ġ4',
    '21',
    'bre',
    'akers',
    "!'",
    '"',
    'Ġwet',
    'lands',
    'ĠDisc',
    'rimination',
    'Ġallow',
    'able',
    'Ġste',
    'ered',
    'ĠSic',
    'ily',
    'S',
    'AM',
    'Ġmust',
    'ache',
    'Ġm',
    'ids',
    'Ġcl',
    'ipped',
    'Ġcirc',
    'ulate',
    'Ġbr',
    'ittle',
    'ĠBuild',
    'ings',
    'ra',
    'ised',
    'ĠRound',
    'up',
    'Ġwealth',
    'ier',
    'Ġoverw',
    'rite',
    'Ġover',
    'powered',
    'ĠGerr',
    'ard',
    's',
    'ites',
    'PD',
    'ATED',
    'Ġacute',
    'ly',
    'ĠGam',
    'ble',
    'Ġp',
    'im',
    'ĠK',
    'us',
    'Typ',
    'ically',
    'De',
    'ploy',
    'ĠMoroc',
    'can',
    'p',
    'otion',
    'com',
    'be',
    'Ġvigil',
    'ante',
    'Ġ36',
    '3',
    'St',
    'ew',
    'ĠB',
    'agg',
    'Ġres',
    'ided',
    'ĠSp',
    'o',
    'Ġrem',
    'nant',
    'Ġempt',
    'iness',
    'br',
    'ainer',
    'Ġout',
    'patient',
    'pri',
    'ority',
    'Ġle',
    'ptin',
    'ĠPay',
    'ton',
    'ĠGle',
    'aming',
    'ĠS',
    'hed',
    'ĠPol',
    'o',
    'ĠMormon',
    'ism',
    'rest',
    'ricted',
    'arl',
    'ane',
    'w',
    'x',
    'Ġcreat',
    'ine',
    'ĠAn',
    'on',
    'ĠST',
    'UD',
    'ĠJ',
    'UL',
    'ĠT',
    'ee',
    '5',
    '28',
    '08',
    '9',
    'Ġhat',
    'ched',
    'Dis',
    'patch',
    'ĠCompos',
    'ite',
    'Ġ45',
    '1',
    'p',
    'uff',
    'ĠX',
    'COM',
    'ĠOr',
    'n',
    'ĠTH',
    'ANK',
    'END',
    'ED',
    'ĠAshe',
    'ville',
    'ĠÃ',
    'ľ',
    'Ġman',
    'go',
    'ĠS',
    'lightly',
    'world',
    'ly',
    'ĠW',
    'ander',
    'ĠExp',
    'and',
    'ĠCh',
    'r',
    'M',
    'ist',
    'Ġorthodox',
    'y',
    'ĠUN',
    'ESCO',
    'reg',
    'ate',
    'Else',
    'where',
    'k',
    'ie',
    'ir',
    'led',
    'Ġtopp',
    'le',
    'Ġadopt',
    'ive',
    'ĠLeg',
    's',
    'd',
    'ress',
    'ĠS',
    'agan',
    'b',
    'are',
    'ĠGl',
    'ou',
    'Cr',
    'unch',
    'Ġhelp',
    'ers',
    'Ġchron',
    'ically',
    'ĠH',
    'uma',
    '1',
    '0000',
    'Ġaccommod',
    'ating',
    'äº',
    'Ķ',
    'Ġwrink',
    'les',
    'Ġdod',
    'ged',
    'four',
    'th',
    'Ġpre',
    'con',
    'Ġcompress',
    'or',
    'ĠK',
    'are',
    'Ġev',
    'ict',
    'ĠWar',
    'wick',
    'im',
    'ar',
    'Ġmodern',
    'ization',
    'Ġband',
    'wagon',
    'Ġref',
    'uted',
    'Ġnet',
    'ted',
    'ĠNa',
    'ples',
    'ĠGen',
    'ie',
    'per',
    'ors',
    'Ġfield',
    'ed',
    'Ġde',
    're',
    'ĠPar',
    'ables',
    'le',
    'es',
    'Ġtr',
    'out',
    'asp',
    'ers',
    'Ġn',
    'ihil',
    'Ġhapp',
    'iest',
    'Ġflo',
    'ppy',
    'ĠLo',
    'ft',
    'ĠHe',
    'ard',
    'Ġun',
    'ison',
    'Ġl',
    'ug',
    'ĠRed',
    'mond',
    'class',
    'ic',
    'Supp',
    'orters',
    'SH',
    'IP',
    'G',
    'MT',
    'Ġfue',
    'lled',
    'ç',
    'Ĳ',
    'Ġd',
    'd',
    'ĠEmin',
    'em',
    'Ġ18',
    '97',
    'NY',
    'SE',
    'Ġsecret',
    'aries',
    'ĠF',
    'IA',
    'ĠCanaver',
    'al',
    'F',
    'avorite',
    'Ġp',
    'omp',
    'Ġdetain',
    'ee',
    'ers',
    'hip',
    'aim',
    'on',
    'i',
    'our',
    'ĠA',
    'pex',
    'Ġplant',
    'ations',
    'am',
    'ia',
    'ac',
    'ion',
    'R',
    'ust',
    'Ġtow',
    'ed',
    'ĠTru',
    'ly',
    '5',
    '77',
    'Ġshel',
    'tered',
    'r',
    'ider',
    'W',
    'o',
    'Ġl',
    'air',
    'ĠInt',
    'elligent',
    'impro',
    've',
    'm',
    'atically',
    'Ġet',
    'iquette',
    'ad',
    'ra',
    'all',
    'o',
    'ĠJun',
    'o',
    'any',
    'thing',
    'ĠStru',
    'ggle',
    'ĠPred',
    'ict',
    'ĠGr',
    'imes',
    'ĠAMER',
    'ICA',
    'ct',
    'x',
    'ĠSit',
    'uation',
    'W',
    'OOD',
    'Ġsol',
    'uble',
    'me',
    'ier',
    'Ġintoler',
    'able',
    'ang',
    'ering',
    'Ġun',
    'interrupted',
    'Ġtool',
    'tip',
    'Ġinterrog',
    'ated',
    'Ġgun',
    'ned',
    'ĠSne',
    'ak',
    'æŃ',
    '¦',
    'Ġt',
    'ether',
    'Ġcr',
    'umble',
    'L',
    'ens',
    'Ġclust',
    'ered',
    'ĠSy',
    'l',
    'ĠHas',
    'an',
    'Ġdystop',
    'ian',
    'w',
    'ana',
    'Ġjoy',
    'stick',
    'ĠTh',
    'ib',
    'amm',
    'u',
    'Tom',
    'orrow',
    '5',
    '46',
    'Ġoverc',
    'ame',
    'Ġminim',
    'ized',
    'cept',
    'or',
    'Run',
    'ner',
    'ENG',
    'TH',
    'ĠBrend',
    'a',
    'ĠAchieve',
    'ments',
    'Ġtor',
    'ches',
    'Ġrapp',
    'ort',
    'ĠInvestig',
    'ator',
    'ĠHand',
    'ling',
    'rel',
    'ation',
    'g',
    'rey',
    '8',
    '15',
    'Ġk',
    'cal',
    'ĠComm',
    'ands',
    'd',
    'q',
    'Ġcur',
    'ls',
    'Ġbe',
    'arer',
    'Ġcyn',
    'icism',
    'it',
    'ri',
    'ĠUse',
    'ful',
    'B',
    'ee',
    'D',
    'CS',
    'Ġab',
    'ras',
    'P',
    'ract',
    'BIL',
    'ITIES',
    '7',
    '12',
    'Ġdebug',
    'ger',
    'Ġdebt',
    'or',
    'ĠL',
    'ia',
    'ĠK',
    'ers',
    'Ġexacerb',
    'ate',
    'ĠSt',
    'acy',
    'ĠB',
    'land',
    'ĠSc',
    'enes',
    'Ġbranch',
    'ing',
    'âĸĪâĸĪâĸĪâĸĪ',
    'âĸĪâĸĪâĸĪâĸĪ',
    'ape',
    'ake',
    'Ġs',
    'alsa',
    'Ġmish',
    'and',
    'ĠKon',
    'ami',
    'ĠN',
    'ib',
    'Ġanecd',
    'ote',
    'Ġagree',
    'able',
    'Ï',
    'ī',
    'ĠNath',
    'aniel',
    'ĠHe',
    'isman',
    'ĠB',
    'eware',
    'Ġ18',
    '86',
    'spect',
    'ive',
    '69',
    '1',
    '5',
    '22',
    'Ġinhib',
    'its',
    'Ġhas',
    'hing',
    'Ġ18',
    '89',
    'å°',
    'Ĩ',
    'v',
    'ich',
    'P',
    'ure',
    'Ġsolid',
    'ly',
    'Ġaspir',
    'in',
    'im',
    'aru',
    'Ġstreet',
    'car',
    'ĠU',
    'CS',
    'ĠJ',
    'udd',
    'Ġflash',
    'backs',
    'p',
    'ins',
    'Ġ14',
    '40',
    'ĠUN',
    'HCR',
    'ĠSym',
    'ptoms',
    'T',
    'IT',
    '5',
    '38',
    'F',
    'ra',
    '%',
    ');',
    'Ġo',
    'oz',
    'Ġcur',
    'few',
    'Ġcal',
    'med',
    'Ġparticip',
    'ates',
    'Te',
    'X',
    'Ġnons',
    'ensical',
    'Ġfull',
    'back',
    'ĠDe',
    'L',
    'mon',
    'key',
    'h',
    'ari',
    'Ġmetabol',
    'ites',
    'Ġloot',
    'ed',
    'ĠAL',
    'WAYS',
    'ĠB',
    'CC',
    'L',
    't',
    'oc',
    'het',
    'B',
    'one',
    'Ġveto',
    'ed',
    'Ġg',
    'cc',
    'ĠCL',
    'ICK',
    'Ġ18',
    '88',
    's',
    'af',
    'Ġstiff',
    'ness',
    'Ġlow',
    'ly',
    'ĠGe',
    'h',
    'vers',
    'on',
    'ors',
    'et',
    'Ġun',
    'foreseen',
    'Ġan',
    'esthesia',
    'ĠOpt',
    'ical',
    'Ġrecon',
    'structed',
    'ĠT',
    'up',
    'sh',
    'ows',
    'NEW',
    'S',
    'ĠNewsp',
    'aper',
    'ĠA',
    'SA',
    'ter',
    'a',
    'N',
    'umbers',
    'Ġinexpl',
    'icable',
    '×',
    'ĳ',
    'Ġhard',
    'ness',
    'unt',
    'arily',
    'ĠA',
    'cer',
    'grad',
    'ient',
    'ARD',
    'IS',
    'Ġwood',
    'land',
    'Ġmetaph',
    'ors',
    'ĠWem',
    'bley',
    'ĠPa',
    'vel',
    'phil',
    'is',
    'Ġre',
    'writing',
    'Ġpercept',
    'ual',
    'Ġ10',
    '70',
    'worm',
    's',
    'ĠDown',
    's',
    'Ġunsur',
    'prisingly',
    'Ġtag',
    'ging',
    'fl',
    'ame',
    'Ġlit',
    'res',
    'Ġboun',
    'ces',
    'ĠB',
    'abe',
    'sh',
    'ut',
    'Ġoverd',
    'oses',
    'ĠShe',
    'ila',
    'ĠCh',
    'au',
    'ĠBl',
    'ess',
    'Capt',
    'ure',
    'ĠSign',
    'ificant',
    'ĠSc',
    'ion',
    'Ġ38',
    '9',
    'ĠMc',
    'H',
    'ĠTitan',
    'ium',
    'ĠMe',
    'al',
    'amed',
    'a',
    'ag',
    'ents',
    'agg',
    'ressive',
    'B',
    'illy',
    '76',
    '3',
    'ĠS',
    'aying',
    'DER',
    'R',
    'it',
    'one',
    'Coll',
    'ins',
    'B',
    'ound',
    'Ġbol',
    'ted',
    'ĠDM',
    'CA',
    '95',
    '3',
    'Ġun',
    'iqueness',
    'Ġep',
    'igen',
    'un',
    'ci',
    'ant',
    'am',
    'Ġreck',
    'oning',
    'ch',
    'airs',
    'OG',
    'R',
    'ĠSen',
    'egal',
    'Ġ18',
    '62',
    're',
    'levant',
    'ĠÂ',
    '¯',
    'Ġpharm',
    'acies',
    'ĠG',
    'eral',
    'v',
    'ier',
    'Y',
    'an',
    'OR',
    'PG',
    'Ġrab',
    'id',
    'b',
    'ending',
    'ĠUN',
    'ITED',
    'Ġ4',
    '65',
    'As',
    'sembly',
    'Ġwe',
    'ep',
    'Ġbe',
    'hest',
    'ĠMother',
    's',
    'ĠJ',
    'ace',
    'h',
    'id',
    'Ġwh',
    'irlwind',
    'ĠUN',
    'IVERS',
    'Ġut',
    'opian',
    'Ġkidn',
    'ap',
    'Ph',
    'ilipp',
    'K',
    'in',
    '89',
    '3',
    'Ġlivest',
    'ream',
    'ĠM',
    'ISS',
    'Ġsub',
    'versive',
    'ĠTechn',
    'iques',
    'ĠJUST',
    'ICE',
    'ĠB',
    'ASE',
    'Ġ38',
    '7',
    'Ġassail',
    'ants',
    'ĠHard',
    'core',
    'Ġsprink',
    'led',
    'ĠP',
    'se',
    'é',
    'ļ',
    'print',
    'ed',
    'ĠH',
    'au',
    'OR',
    'GE',
    'ĠT',
    'OUR',
    'Ġl',
    'aced',
    'Ġit',
    'ch',
    'G',
    'iving',
    'Ġport',
    'ed',
    '78',
    '1',
    '////////////////',
    '////////////////',
    'bre',
    'eding',
    'Ġlog',
    'ger',
    'ĠH',
    'OL',
    'inn',
    'ie',
    'First',
    'ly',
    'Ġembry',
    'onic',
    'Ġdeleg',
    'ated',
    'p',
    'ai',
    'O',
    'IL',
    'Ġcentr',
    'ally',
    'ĠR',
    'x',
    'ĠSc',
    'outing',
    'D',
    'utch',
    'Ġhe',
    'reditary',
    'ĠCru',
    'iser',
    's',
    'at',
    '5',
    '29',
    'ĠMar',
    'riott',
    'other',
    'mal',
    'Ġprohib',
    'itions',
    'E',
    'arn',
    'ĠSt',
    'ab',
    'ĠColleg',
    'es',
    'ĠBel',
    'ief',
    'st',
    'retched',
    'ĠL',
    'H',
    'ĠEntity',
    'Item',
    'C',
    'IA',
    'Ġun',
    'rem',
    'Ġlaure',
    'ate',
    'Ġdenomin',
    'ations',
    'sum',
    'mary',
    'h',
    'ler',
    'S',
    'pect',
    'ĠK',
    'laus',
    'ĠBe',
    'ans',
    'Ġins',
    'ur',
    'ĠPA',
    'X',
    'Ġfield',
    'er',
    'ĠV',
    'et',
    'ĠSp',
    'arrow',
    'z',
    'ie',
    'ĠS',
    'Q',
    'ĠMond',
    'ays',
    'ĠOff',
    'line',
    'ĠLer',
    'ner',
    'ĠExt',
    'ensions',
    'Ire',
    'land',
    'Ġpatron',
    'age',
    'Ġcontrast',
    'ed',
    'ĠMan',
    'ia',
    'h',
    'irt',
    'Mos',
    'cow',
    'Ġcondem',
    'ns',
    'ĠAn',
    'ge',
    'Ġcomp',
    'osing',
    'ĠPe',
    'pe',
    'ĠP',
    'addock',
    'Ġheter',
    'ogeneity',
    'Ġide',
    'ologically',
    'Ġf',
    'ishes',
    'Ġcur',
    'sing',
    'ĠR',
    'utherford',
    'ĠFlo',
    'ating',
    'ĠAm',
    'elia',
    'Te',
    'a',
    'Syn',
    'opsis',
    'Ġstun',
    'ts',
    'Ġbe',
    'ad',
    'Ġstock',
    'ing',
    'ĠM',
    'ILL',
    'ob',
    'ook',
    'mass',
    'ive',
    '\\',
    '<',
    'Ġh',
    'ump',
    'ĠPref',
    'erences',
    'Engine',
    'Debug',
    'ge',
    'ist',
    'ĠNiet',
    'o',
    'ome',
    'ver',
    'ish',
    'y',
    'eval',
    'uate',
    'col',
    'onial',
    'Altern',
    'ative',
    'ĠGo',
    'Pro',
    'ĠV',
    'ortex',
    'ĠNET',
    'WORK',
    'ans',
    'ky',
    'Sec',
    'ure',
    'ĠTh',
    'rust',
    'Sn',
    'ake',
    'Ġparcel',
    's',
    'Ġsam',
    'urai',
    'Ġactress',
    'es',
    'N',
    'ap',
    'M',
    'F',
    'ifer',
    'ation',
    'Be',
    'er',
    '5',
    '23',
    'ĠI',
    'ly',
    'oint',
    'ment',
    'P',
    'ing',
    'Ġstri',
    'ped',
    'ĠMell',
    'on',
    'oss',
    'ession',
    'Ġneut',
    'ron',
    'end',
    'ium',
    'Ġa',
    'ph',
    'ĠFlav',
    'oring',
    'Ġ38',
    '3',
    'Ġrespons',
    'iveness',
    'ĠJ',
    'indal',
    'ĠHitch',
    'cock',
    'Den',
    'ver',
    'ĠDRAG',
    'ON',
    'sm',
    'anship',
    'ĠDu',
    'pl',
    'Ġs',
    'ly',
    'Ġweb',
    'cam',
    'ĠTw',
    'ain',
    'ĠDar',
    'ling',
    'ili',
    'ate',
    'cons',
    'umer',
    'D',
    'IT',
    'Ġnames',
    'ake',
    'Ġun',
    'orthodox',
    'Ġfun',
    'er',
    'ĠPL',
    'oS',
    'ĠCONTR',
    'OL',
    'ozy',
    'g',
    'ogl',
    'obin',
    'F',
    'ACE',
    'ER',
    'G',
    'ĠD',
    'ia',
    'ĠF',
    'iesta',
    'ce',
    'le',
    '0',
    '34',
    'Ġencl',
    'ave',
    'âĸ¬',
    'âĸ¬',
    'on',
    'ement',
    'al',
    'ist',
    'M',
    'and',
    'Ġhome',
    'grown',
    'ĠF',
    'ancy',
    'Ġconcept',
    'ions',
    'ĠCont',
    'ains',
    'ure',
    'en',
    'Ġreiter',
    'ate',
    'Ġme',
    'ager',
    'Ġinstall',
    'ments',
    'Sp',
    'awn',
    '6',
    '27',
    'Ġphot',
    'oc',
    'ĠCab',
    'rera',
    'ĠRos',
    'enthal',
    'ĠLans',
    'ing',
    'is',
    'ner',
    'Ġinvest',
    's',
    'ĠUFO',
    's',
    'EX',
    'P',
    'Hard',
    'ware',
    'Ġtr',
    'agically',
    'Ġconced',
    'es',
    'ie',
    'ft',
    'ch',
    'am',
    'bor',
    'gh',
    'ĠSch',
    'r',
    'ĠMel',
    'anie',
    'ĠH',
    'oy',
    'Ġvisit',
    'ation',
    'Ġid',
    'iosyncr',
    'Ġfract',
    'ions',
    'Ġfore',
    'skin',
    'ob',
    'os',
    'Ġpo',
    'aching',
    'ĠVI',
    'EW',
    'Ġstimul',
    'ates',
    'ĠG',
    'ork',
    'can',
    'on',
    'M',
    'IC',
    'ĠNem',
    'esis',
    'ĠInd',
    'ra',
    'ĠDM',
    'V',
    'Ġ5',
    '29',
    'Ġinspect',
    'ing',
    'Ġgrand',
    'ma',
    'ĠW',
    'hedon',
    'ĠSh',
    'ant',
    'ĠP',
    'urg',
    'ik',
    'an',
    'ĠT',
    'eg',
    'ĠCL',
    'R',
    'z',
    'ac',
    'Vict',
    'oria',
    'ĠVer',
    'ify',
    'ion',
    'ics',
    'Ġpart',
    'ying',
    'ĠM',
    'ou',
    'col',
    'our',
    'Ġtestim',
    'onies',
    'l',
    'ations',
    'Ġpress',
    'uring',
    'hi',
    'ro',
    'ac',
    'ers',
    'Ġf',
    'id',
    'ang',
    'ler',
    'ĠCS',
    'I',
    'Ġhere',
    'after',
    'Ġdiss',
    'idents',
    'report',
    'ing',
    'iph',
    'any',
    'che',
    'v',
    'Ġsol',
    'itude',
    'Ġl',
    'obe',
    'Ġind',
    'is',
    'Ġcred',
    'ential',
    're',
    'cent',
    'ad',
    'ult',
    'ĠNir',
    'vana',
    'ĠFranch',
    'ise',
    'L',
    'ayer',
    'H',
    'yp',
    'ĠBerks',
    'hire',
    'Ġwill',
    's',
    't',
    'if',
    'Ġtot',
    'em',
    'ĠJud',
    'ah',
    'rep',
    'air',
    'Inst',
    'ant',
    '5',
    '48',
    'Ġemb',
    'assies',
    'Ġbott',
    'leneck',
    'Ġb',
    'ount',
    'Ġtyp',
    'ew',
    'ĠAl',
    'vin',
    'j',
    'ing',
    'im',
    'ilar',
    'R',
    'ush',
    'Ġbr',
    'im',
    'ĠHEL',
    'P',
    'A',
    'im',
    ']',
    "'",
    'Ġpass',
    'ively',
    'Ġbound',
    'ed',
    'ĠR',
    'ated',
    'Ġcriminal',
    'ity',
    'Ġbiom',
    'ark',
    'Ġdisp',
    'atcher',
    'ĠTow',
    'ards',
    'Ġ+',
    '++',
    'right',
    'eous',
    'f',
    'rog',
    'ĠP',
    'anc',
    'C',
    'arter',
    '0',
    '32',
    'æ©',
    'Ł',
    'Ġult',
    'raviolet',
    'ĠLic',
    'ensed',
    'ĠT',
    'ata',
    'ĠBl',
    'essing',
    'ĠG',
    'AM',
    'Ġchem',
    'ically',
    'ĠSe',
    'af',
    'ĠRE',
    'LE',
    'ĠMerc',
    'enary',
    'capital',
    'ist',
    'Ġform',
    'ulations',
    'Ġann',
    'ihilation',
    'ĠVer',
    'b',
    'ĠAr',
    'gon',
    'Ġun',
    'loaded',
    'Ġmorp',
    'hed',
    'Ġconqu',
    'ering',
    'back',
    'er',
    'I',
    'ELD',
    'Ġtheft',
    's',
    'Ġfront',
    'runner',
    'ĠRoy',
    'ale',
    'ĠFund',
    'amental',
    'el',
    'ight',
    'C',
    'hip',
    'necess',
    'ary',
    'ay',
    'n',
    'ĠSl',
    'ip',
    'Ġ4',
    '48',
    'cern',
    'ed',
    'P',
    'ause',
    'Ġshock',
    'ingly',
    'ĠAB',
    'V',
    'Ġcomp',
    'osure',
    '7',
    '33',
    'ĠMotors',
    'port',
    'ah',
    'ime',
    'Mur',
    'ray',
    'M',
    'ach',
    'Ġgr',
    'ids',
    'Ġdeb',
    'ian',
    'Ġfurther',
    'more',
    'Ġdexter',
    'ity',
    'ĠCollect',
    'ions',
    'os',
    'lov',
    'il',
    'age',
    'b',
    'j',
    'ĠMont',
    'eneg',
    'Ġstrut',
    'Connector',
    'Ġmassac',
    'res',
    'Ġbrief',
    's',
    'fet',
    'ched',
    'uv',
    'ian',
    'ol',
    'ition',
    'Fail',
    'ure',
    'emon',
    'ic',
    'Ġfl',
    'ared',
    'Ġclaim',
    'ant',
    'Ġc',
    'ures',
    'Ġgive',
    'aways',
    'ĠSubst',
    'ance',
    'al',
    'ions',
    'Ġcr',
    'inge',
    'ĠK',
    'ul',
    'Ġarist',
    'ocracy',
    'ĠUl',
    'ster',
    'ol',
    'ated',
    'h',
    'ousing',
    'ĠM',
    'IS',
    'Ġgl',
    'ared',
    'ĠWil',
    'helm',
    'ne',
    'eds',
    'lam',
    'bda',
    'build',
    'ers',
    'ĠV',
    'IS',
    'Ġradi',
    'ator',
    'ĠGhost',
    'busters',
    'Ġ4',
    '36',
    'act',
    'ual',
    'Ġher',
    'ds',
    'Ã§',
    'a',
    'watch',
    'ing',
    'Ġcounter',
    'ing',
    'Ch',
    'arge',
    'Ġchar',
    'red',
    'Ġwar',
    'heads',
    'Ġiod',
    'ine',
    'ĠM',
    'acy',
    '04',
    '1',
    'Ġdepart',
    'ures',
    'ĠS',
    'ins',
    'Ġdy',
    'ed',
    'ĠConcept',
    's',
    'g',
    'ado',
    '7',
    '13',
    'Ġquot',
    'ations',
    'Ġg',
    'ist',
    'ĠChrist',
    'y',
    'Ġant',
    'igen',
    'ĠHem',
    'p',
    'ĠD',
    'rawn',
    'ĠB',
    'arg',
    'ez',
    'vous',
    'Ġp',
    'aternity',
    'Ġar',
    'du',
    'ĠAnch',
    'orage',
    'ĠR',
    'ik',
    'Ġover',
    'loaded',
    'ĠUs',
    'ername',
    'ĠTam',
    'my',
    'ĠN',
    'au',
    'ĠCell',
    'ular',
    'Ġw',
    'aning',
    'Ġrod',
    'ent',
    'ĠWor',
    'cester',
    'il',
    'ts',
    'ĠT',
    'ad',
    'Ġdwell',
    'ings',
    'Ġbull',
    'ish',
    '4',
    '31',
    'Ġretali',
    'ate',
    'Ġmig',
    'raine',
    'ĠChev',
    'ron',
    'CH',
    'ECK',
    'Ġdon',
    'key',
    'c',
    'rim',
    'SP',
    'A',
    'ĠAn',
    'alog',
    'Ġmarqu',
    'ee',
    'ĠHa',
    'as',
    'B',
    'ir',
    'ĠGD',
    'DR',
    'ĠDownload',
    's',
    'Ġwill',
    'power',
    'ĠFor',
    'th',
    'ĠRecord',
    'ed',
    'Ġimp',
    'ossibility',
    'ĠLog',
    'ged',
    'ĠFr',
    'anks',
    'ĠR',
    'att',
    'in',
    'itions',
    'Ġclean',
    'ers',
    'Ġsore',
    'ly',
    'Ġflick',
    'ering',
    'ĠEx',
    'amination',
    'c',
    'atching',
    'allow',
    'een',
    'Ms',
    'g',
    'Ġdun',
    'no',
    'F',
    'a',
    'Ġdys',
    'ph',
    'c',
    'razy',
    ".'",
    "'.",
    'Ġmain',
    'line',
    'Ġc',
    's',
    'Ġp',
    'tr',
    'ĠW',
    'ally',
    'ig',
    'un',
    '95',
    '1',
    'ĠBig',
    'foot',
    'f',
    'ights',
    'Ġretrie',
    'ving',
    'J',
    'r',
    'Ġdupl',
    'ication',
    'ĠExpl',
    'an',
    'Ġrel',
    'ational',
    'Ġqu',
    'aint',
    'Ġbisc',
    'uits',
    'Ġad',
    'o',
    'Ġsh',
    'udder',
    'Ġantid',
    'ote',
    'blood',
    'ed',
    'ks',
    'h',
    'Ġsa',
    'uces',
    'Ġrein',
    'vest',
    'Ġdispens',
    'ary',
    'ĠD',
    'iver',
    'Ġ9',
    '000',
    'stud',
    'ent',
    'Ġin',
    'separ',
    'esc',
    'ap',
    'Ġtodd',
    'lers',
    'ĠGP',
    'IO',
    'ĠAss',
    'ignment',
    'head',
    'ers',
    'Ġlack',
    'luster',
    'Ġab',
    'ack',
    '95',
    '6',
    'Ġtool',
    'bar',
    '7',
    '45',
    'Ġo',
    'ust',
    'Ġcontempl',
    'ation',
    'ĠPRES',
    'IDENT',
    'Ġ4',
    '58',
    '====',
    '==',
    'Ġguarantee',
    'ing',
    'ĠHe',
    'ist',
    'ĠCann',
    'es',
    'Ļ',
    '½',
    'Ġcollabor',
    'ator',
    'ĠAm',
    'p',
    'Ġg',
    'ou',
    'ĠSH',
    'ALL',
    'st',
    'ories',
    '78',
    '3',
    'Ġmobil',
    'ized',
    'Ġbro',
    'od',
    'ĠL',
    'U',
    'ĠðŁ',
    'ĳ',
    'Ġref',
    'in',
    'ĠAnthrop',
    'ology',
    'v',
    'ind',
    'ill',
    'i',
    'Ġwarrant',
    'ies',
    'ĠB',
    'abel',
    'Ġsw',
    'ath',
    'Ġc',
    'aches',
    'Ġantagon',
    'ists',
    'art',
    'ifacts',
    'Ġhot',
    'ly',
    'ĠSt',
    'arts',
    'ĠG',
    'Ã¶',
    'z',
    'ag',
    '!!',
    '!!!',
    'Ġsc',
    'ourge',
    'Ġcons',
    'piring',
    'ru',
    'its',
    're',
    'verse',
    'ĠShe',
    'en',
    'ĠJes',
    'uit',
    'ĠGiov',
    'anni',
    'ad',
    'ies',
    'Ġbutt',
    'ocks',
    'ear',
    'cher',
    'ac',
    'an',
    'Ġvolley',
    'ball',
    'Ġshroud',
    'ed',
    'Ġscore',
    'board',
    'b',
    'ats',
    'ĠI',
    'PM',
    'Ġass',
    'es',
    'Ġde',
    'regulation',
    'ĠTe',
    'legram',
    'ĠReb',
    'oot',
    'Ġ7',
    '000',
    'ĠCan',
    'ary',
    'Ġk',
    'ernels',
    'ĠFranÃ§',
    'ois',
    'ĠD',
    'uff',
    'ĠP',
    'on',
    'ĠLe',
    'ica',
    'ĠGar',
    'min',
    'Ġor',
    'phans',
    'ĠClaud',
    'ia',
    'Ġcal',
    'endars',
    'ĠLe',
    'ilan',
    'ent',
    'o',
    'R',
    'ocket',
    'Ġbr',
    'unch',
    'ĠHaw',
    'king',
    'ain',
    'ers',
    'Ġsens',
    'ibilities',
    'Ġk',
    'W',
    'ĠK',
    'and',
    'Ġre',
    'claimed',
    'Ġinteresting',
    'ly',
    '×',
    '©',
    'rom',
    'y',
    'J',
    'M',
    'ĠEnhance',
    'ment',
    'b',
    'ush',
    'Sk',
    'ip',
    'Ġrapp',
    'ers',
    'Ġg',
    'azing',
    'p',
    'edia',
    'ath',
    'lon',
    'Rev',
    'olution',
    'Ġsn',
    'ipers',
    'Ġre',
    'verted',
    'Ġconglomer',
    'ate',
    'T',
    'erry',
    '79',
    '4',
    'Ġhars',
    'her',
    'Ġdes',
    'olate',
    'ĠHit',
    'man',
    'Comm',
    'ission',
    'Ġ(',
    '/',
    'âĢ¦',
    '."',
    'Com',
    'par',
    'Ġampl',
    'ification',
    'om',
    'inated',
    'Ġreg',
    'ress',
    'ĠColl',
    'ider',
    'Ġinform',
    'ants',
    'Ġg',
    'azed',
];
